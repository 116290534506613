import * as React from 'react'
import { Form } from '@patternfly/react-core'
import { GenericTextArea } from '../../../components/GenericTextArea'
import { confermaPolizza, getCampagneAssicurative, getPolizzaById } from 'src/processes/assicurazioni/Assicurazione'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import { PATH_TO_BO_POLIZZE_ELENCO } from 'src/app/utils/RoutesConstants'
import { Label } from 'design-react-kit'
import { AppUtils } from 'src/app/utils/FormUtils'
import { inputRequiredValidation } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormConfermaPolizza  extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: "",
            dateTo: "",
            polizzaIndividuale: false,
            errorFlag: false,
            isLoaded: false,
        };
      }



    async componentDidMount(): Promise<void> {
        var parametriConferma = {
            "idPolizza": this.props.idPolizza.toString(),
        }

        await getCampagneAssicurative().then(async (response: any)=> {
            await this.setState({elencoCampagneAssicurative : response});
        });

        await getPolizzaById(parametriConferma.idPolizza).then(async (response: any)=> {

            let periodoValDaDb = "";
            let periodoValADb = "";

            if(response.associatoConConaf === 1) {
                periodoValDaDb = response.periodoValDaConaf;
                periodoValADb = response.periodoValAConaf;
            }
              else {
                periodoValDaDb = response.periodoValDaAltri;
                periodoValADb = response.periodoValAAltri;
            }

            periodoValDaDb = periodoValDaDb.split("T")[0];
            periodoValADb = periodoValADb.split("T")[0];

            await this.setState({dateFrom : periodoValDaDb, dateTo : periodoValADb})

            if(response.associatoConAltri === 1)
                this.setState({polizzaIndividuale: true})

          }).catch(() => {
                    setTimeout(() => {
                        this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                    }, 3000);
        })
        this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger', isLoaded : true })
    }

    handleSubmit = async event => {
        var requiredFields = [
            { value: event.target.idNoteConferma.value, label: 'Note di conferma' },
            { value: event.target.idDataInizioPolizza.value, label: 'Data inizio validità' },
            { value: event.target.idDataFinePolizza.value, label: 'Data fine validità' }
        ]
        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        } else {
            // let elencoCampagneAssicurative = this.state.elencoCampagneAssicurative;

            let noteConferma = event.target.idNoteConferma.value;
            let dateFrom = event.target.idDataInizioPolizza.value;
            let dateTo = event.target.idDataFinePolizza.value;
            let dateFromParsed;
            await this.setState({isLoaded:false});

            try{
                dateFromParsed = new Date(dateFrom);
            }catch(err){
                event.preventDefault()
                await this.showModal(true, "Errore","Data inizio validità non corretta");
                await this.setState({isLoaded:true});
                await this.setState({errorFlag:true});
                return false;
            }

            let dateToParsed;

            try {
                dateToParsed = new Date(dateTo);
            }
            catch(err) {
                event.preventDefault()
                await this.showModal(true, "Errore","Data fine validità non corretta");
                await this.setState({isLoaded:true});
                await this.setState({errorFlag:true});
                return false;
            }

            if(dateFromParsed>dateToParsed) {
                event.preventDefault()
                let errorMessage = "Data inzio validità " + AppUtils.itDate(dateFromParsed);
                errorMessage += " successiva alla data di fine validità " +  AppUtils.itDate(dateToParsed);

                await this.showModal(true, "Errore",errorMessage);
                await this.setState({isLoaded:true});
                await this.setState({errorFlag:true});
                return false;
            }

            var parametriConferma = {
                "idPolizza": this.props.idPolizza.toString(),
                "note": noteConferma ? noteConferma : "",
                "dateFrom" : dateFrom,
                "dateTo" : dateTo,
            }
            event.preventDefault();

            try {
                event.preventDefault();
                this.setState({dateFrom : parametriConferma.dateFrom, dateTo : parametriConferma.dateTo})
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })

                await confermaPolizza(parametriConferma).then(response=>{
                })
                event.preventDefault();
                await this.setState({errorFlag: false})
                this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
                await this.showModal(true, "Messaggio","Polizza confermata");
                await this.setState({isLoaded:true})
                return false;
            }
            catch(err: any) {
                    event.preventDefault();
                    await this.setState({errorFlag: true})
                    await this.showModal(true, "Errore",err.message);
                    return false;
            }
        }
    }

    closeModal = () => {
        this.setState({modalOpen:false});
        if(this.state.errorFlag === false) window.location.href = PATH_TO_BO_POLIZZE_ELENCO;
    }

    showModal = async (booleanValue, title, text) => {
        await this.setState({
            modalOpen:booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
    }
    render() {
        return (
            <div>
                {this.state.isLoaded ? <Form id='idConfermaPolizza' onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className={isCurrentMobileDevice() ? 'col-12' : 'col-4' } >
                            <Label>Data inizio validità</Label>
                            <div className='d-flex'>
                                <GenericTextInput isDisabled={this.state.polizzaIndividuale} type={'date'} id={'idDataInizioPolizza'} onChange={(event) => this.setState({dateFrom: event.target.value})} placeholder={'Selezionare data'} isRequired defaultValue={this.state.dateFrom} />
                                <GenericTextInput isDisabled type={'time'} id={'idTimeInizio'} value={"00:00"} />
                            </div>
                        </div>
                        <div className={isCurrentMobileDevice() ? 'col-12' : 'col-4 offset-1' } >
                            <Label>Data fine validità</Label>
                            <div className='d-flex'>
                                <GenericTextInput isDisabled={this.state.polizzaIndividuale} type={'date'} id={'idDataFinePolizza'} onChange={(event) => this.setState({dateTo: event.target.value})} placeholder={'Selezionare data'} isRequired defaultValue={this.state.dateTo} />
                                <GenericTextInput isDisabled type={'time'} id={'idTimeFine'} value={"23:59"} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <GenericTextArea className={isCurrentMobileDevice() ? 'col-12' : 'w-50' } id={"idNoteConferma"} label={"Note di conferma"} name={"idNoteConferma"} placeholder={"Inserire note di conferma"} ariaLabel={'textarea-idNoteConferma'} isRequired />
                        </div>
                    </div>

                <GenericModal
                          title={this.state.modalTitle}
                          text={this.state.modalText}
                          modalOpen={this.state.modalOpen}
                          closeButton={this.closeModal}
                  />

                </Form> : <GenericSpinner/>}


            </div>
        )
    }
}


export declare interface GeneralFormConfermaPolizzaProps {
    messaggio?: any;
    idPolizza?: any;
    polizza?:any;
    periodoValDa?:any;
    periodoValA?:any;
}
