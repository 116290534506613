import { FormGroup, Label } from 'design-react-kit'
import React from 'react'
import GenericCard from '../../../components/GenericCard'
import GenericCheckbox from '../../../components/GenericCheckbox'
import GenericDataPicker from '../../../components/GenericDatePicker'
import GenericInput from '../../../components/GenericInput'
import GenericUploadFront from '../../../components/GenericUpload'
import { getComuniByProvinciaMsga } from 'src/processes/Comuni'
import { getAllProvince } from 'src/processes/Province'
import GenericSelect from '../../../components/GenericSelect'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getCittadinanze } from 'src/processes/Cittadinanze'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getFilesByIdIscrizioneFo } from 'src/processes/ProcessoIscrizione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'
import { isEmptyValue } from 'src/utilities/utility'
import { getSoggettoByIdIscrizione } from 'src/processes/Soggetto'

const statoCompilazione = 1
const statoRichiestaIntegrazioni = 3
const statoIscritto = 4
const statoSospeso = 7

export default class DatiAnagrafici extends React.Component<DatiAnagraficiProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      processoIscrizione: props.processoIscrizione,
      fields: props.data,
      province: [],
      listaCittadinanze: [],
      listaComuni: [],
      isLoaded: false,
      cittadinanzaItaliana: true,
      message: '',
      filesIscrizione: [],
      provinciaSelected: false,
      cittadinanzaSelected: false,
    }
  }

  async componentDidMount(): Promise<void> {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    if (!this.props.visualizzaFlag && this.state.fields.idIscrizione) {
      var filesIscrizione = await getFilesByIdIscrizioneFo(this.state.fields['idIscrizione'])
      if (filesIscrizione?.status === 200) {
        if (filesIscrizione?.data[0]?.idAttachment && this.state.fields.uploadFiles) {
          this.state.fields.uploadFiles['idAttachment'] = filesIscrizione?.data[0]?.idAttachment
        }
        this.setState({ filesIscrizione: filesIscrizione.data })
      }
    }

    if (isEmptyValue(this.state.fields.genere)) {
      getSoggettoByIdIscrizione(this.state.fields['idIscrizione']).then((response: any) => {
        this.state.fields.genere = response.data.sesso
      })
    }

    if (this.state.isLoaded === false && !this.props.visualizzaFlag) {
      await getAllProvince().then((response: any) => {
        var province = [{ label: '', value: '' }]

        response.data.map((provincia, id) => {
          province[id] = {
            label: provincia['denominazioneUnitaTerritorialeSovracomunale'],
            value: provincia['siglaAutomobilistica'],
          }
        })

        let sortedProvince = province.sort((p1, p2) => (p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0))

        this.setState({ province: sortedProvince })
      })

      await getCittadinanze().then((response: any) => {
        var cittadinanze = [{ label: '', value: '' }]

        if (response)
          response.data.map((cittadinanza, id) => {
            cittadinanze[id] = {
              label: cittadinanza['denominazioneIt'],
              value: cittadinanza['idCittadinanza'],
            }
          })
        this.setState({ listaCittadinanze: cittadinanze, isLoaded: true })
      })
      if (this.props.data) {
        this.setState({ fields: this.props.data })
      }
    }

    if (this.props.modificaDatiFlag || this.props.visualizzaFlag) {
      if (this.props.data) {
        this.setState({ fields: this.props.data })
      }
      this.setState({ isLoaded: true })
    }

    // Se la cittadinanza non è presente nei fields imposto i boolean per gestire di default Italia
    if (this.state.fields?.cittadinanza?.value === null || this.state.fields?.cittadinanza?.value === undefined) {
      this.setState({ cittadinanzaItaliana: true })
      this.setState({ cittadinanzaSelected: false })
    }

    // Se la cittadinanza è diversa da Italia gestisco i boolean per lo Stato Estero
    if (
      this.state.fields?.cittadinanza?.value !== null &&
      this.state.fields?.cittadinanza?.value !== '' &&
      this.state.fields?.cittadinanza?.value !== 'Italia'
    ) {
      this.state.fields['provinciaNascita'] = { label: 'Stato Estero', value: 'EEP' }
      this.setState({ cittadinanzaItaliana: false })
      this.setState({ provinciaSelected: false })
      this.setState({ cittadinanzaSelected: false })
    }

    // Se la cittadinanza è Italia
    if (this.state.fields?.cittadinanza?.value === 'Italia') {
      this.setState({ cittadinanzaItaliana: true })
      this.setState({ cittadinanzaSelected: true })
    }

    // Se la provincia è impostata
    let isProvinciaSaved =
      this.state.fields?.provinciaNascita?.label !== null &&
      this.state.fields?.provinciaNascita?.label != undefined &&
      this.state.fields?.provinciaNascita?.label !== ''
    this.setState({ provinciaSelected: isProvinciaSaved })
    if (isProvinciaSaved) {
      await getComuniByProvinciaMsga(this.state.fields?.provinciaNascita?.label).then(async (comuni) => {
        this.setState({ listaComuni: comuni, isLoaded: true, provinciaSelected: true })
      })
    }

    // Se la città di nascita è impostata
    let isComuneSaved =
      this.state.fields?.cittaNascita?.label !== null && this.state.fields?.cittaNascita?.label != undefined && this.state.fields?.cittaNascita?.label !== ''
    if (isComuneSaved) {
      if (this.state.fields?.provinciaNascita?.label !== 'Stato Estero')
        this.state.fields['cittaNascita'] = { label: this.state.fields['cittaNascita'], value: this.state.fields['cittaNascita'] }
      this.setState({ cittadinanzaItaliana: true })
      this.setState({ cittadinanzaSelected: true })
      this.setState({ provinciaSelected: true })
    }

    if (
      this.state.fields?.provinciaNascita?.value !== null &&
      this.state.fields?.provinciaNascita?.value !== '' &&
      this.state.fields?.provinciaNascita?.label === 'Stato Estero'
    ) {
      this.state.fields['provinciaNascita'] = { label: 'Stato Estero', value: 'EEP' }
      this.setState({ provinciaSelected: false })
      this.setState({ cittaNascita: '-' })
    }
    this.setState({ isLoaded: true })
  }

  render() {
    const { fields } = this.state
    const { tabNumber } = this.props

    /******************** Acquisizione dati da Token *****************/
    const keyData = TokenStore.getInstance().getDecodedTokenJWT()
    let fiscalNumber = keyData.fiscalNumber
    let given_name = keyData.given_name.toUpperCase()
    let family_name = keyData.family_name.toUpperCase()
    let genere = keyData.spid_gender
    let dataNascita = keyData.spid_dateOfBirth

    fields['nome'] = given_name
    fields['cognome'] = family_name
    fields['codiceFiscale'] = fiscalNumber
    fields['genere'] = genere
    fields['dataNascita'] = dataNascita

    /** Funzione per aggiornare i campi del form */
    const setDataItem = async (e: any, type: any, field: any) => {
      let fields = this.state.fields

      if (type === 'select') {
        if (field === 'cittadinanza') {
          if (e.label === 'Italia') {
            this.state.fields['provinciaNascita'] = null
            this.state.fields['cittaNascita'] = null
            this.setState({ cittadinanzaItaliana: true })
            this.setState({ provinciaSelected: false })
          } else {
            this.state.fields['provinciaNascita'] = { label: 'Stato Estero', value: 'EEP' }
            this.state.fields['cittaNascita'] = null
            this.setState({ cittadinanzaItaliana: false })
            this.setState({ provinciaSelected: false })
          }
          this.setState({ cittadinanzaSelected: true })
        }
        if (field === 'provinciaNascita') {
          if (e.label === 'Stato Estero') {
            this.state.fields['provinciaNascita'] = { label: 'Stato Estero', value: 'EEP' }
            this.state.fields['cittaNascita'] = null
          } else {
            const prov = e.label
            this.setState({ provinciaSelected: false })
            this.state.fields['cittaNascita'] = null
            getComuniByProvinciaMsga(prov).then(async (comuni) => {
              this.setState({ listaComuni: comuni, provinciaSelected: true })
            })
          }
        }
        fields[field] = { label: e.label, value: e.value }
      } else if (type === 'checkbox') {
        fields[field] = e.target.checked
      } else {
        fields[field] = e.target.value
      }
      this.setState({ fields: this.state.fields, isLoaded: true })
      this.props.getSelectedValue(fields)
    }

    const getDatiUploadFile = (fileData) => {
      fields['uploadFiles']?.push(fileData)
      fields['uploadFiles']?.map((e, id) => {
        if (e.tipologiaAllegato === fileData.tipologiaAllegato || e.codiceTipoAllegato === fileData.tipologiaAllegato) {
          if (e.idAttachment && !e.cancellato) {
            e['cancellato'] = true
          } else if (e.idAttachment && e.cancellato) {
            if (id < fields['uploadFiles'].length - 1) {
              e['cancellato'] = true
            }
          } else if (!e.idAttachment && id < fields['uploadFiles'].length - 1) {
            //Se id attachment non presente ma non è cancellato
            e['cancellato'] = true
          }
        } else {
          return e
        }
      })
      this.props.getSelectedValue(fields)
    }

    /********************************* TO PERFORM Dati provenienti dallo Spid ****************/
    const SpidData = () => {
      return (
        <>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput type={'text'} placeholder="nome" key="nome" id="nome" name="nome" label="Nome **" isDisabled defaultValue={given_name} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput
              type={'text'}
              placeholder="cognome"
              key="cognome"
              id="cognome"
              name="cognome"
              label="Cognome **"
              isDisabled
              defaultValue={family_name}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput type={'text'} placeholder="cf" key="cf" id="cf" name="cf" label="Codice fiscale **" isDisabled defaultValue={fiscalNumber} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput type={'text'} placeholder="genere" key="genere" id="genere" name="genere" label="Sesso **" isDisabled defaultValue={genere} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput
              type={'date'}
              placeholder="dataNascita"
              id="dataNascita"
              key="dataNascita"
              name="dataNascita"
              label="Data di nascita **"
              isDisabled
              defaultValue={dataNascita}
            />
          </div>
        </>
      )
    }

    /********************************* Form Iscrizione ************************************/
    const fotoIOT = this.state.filesIscrizione?.filter((e) => e.codiceTipoAllegato === 'foto_iot')[0]?.filename
    const idAttachment = this.state.filesIscrizione?.filter((e) => e.codiceTipoAllegato === 'foto_iot')[0]?.idAttachment
    const formIscrizione = [
      <GenericCard
        key={'datiAnagraficiIscrizione'}
        cardHeader="Dati Anagrafici"
        cardBody={
          <div id="datiAnagraficiIscrizione" className="row">
            <SpidData key={'spidIscrizione'}></SpidData>

            <div className={'row'}>
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <GenericSelect
                  key="cittadinanza"
                  elementId="cittadinanza"
                  defaultOptions={this.state.listaCittadinanze}
                  defaultValue={
                    this.state.fields['cittadinanza']?.label
                      ? this.state.fields['cittadinanza']
                      : this.props.reiscrizioneFlag
                      ? this.state.fields['cittadinanza']
                      : null
                  }
                  errorMessageAlert={this.props.message}
                  isRequired
                  currentStep={tabNumber}
                  isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  onChange={(e) => {
                    setDataItem(e, 'select', 'cittadinanza')
                  }}
                  label="Cittadinanza *"
                  placeholder="Selezionare cittadinanza"
                />
              </div>

              <div className={isCurrentMobileDevice() ? null : 'col-6'} hidden={this.state.cittadinanzaItaliana}>
                <FormGroup label={'scadenza_permesso_soggiorno'}>
                  <GenericDataPicker
                    label="Data di scadenza del permesso di soggiorno"
                    placeholder="Selezionare data di scadenza"
                    key="scadenza_permesso_soggiorno"
                    onChange={(e) => setDataItem(e, 'text', 'scadenza_permesso_soggiorno')}
                    defaultValue={
                      this.state.fields['scadenza_permesso_soggiorno']
                        ? this.state.fields['scadenza_permesso_soggiorno']
                        : this.props.reiscrizioneFlag
                        ? this.state.fields['scadenza_permesso_soggiorno']
                        : null
                    }
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <FormGroup label={'provinciaNascita'} hidden={!this.state.cittadinanzaItaliana}>
                  {this.state.cittadinanzaSelected ? (
                    <GenericSelect
                      key={'provinciaNascita'}
                      elementId="provinciaNascita"
                      onChange={(e) => setDataItem(e, 'select', 'provinciaNascita')}
                      label="Provincia di nascita *"
                      placeholder="Selezionare provincia di nascita"
                      defaultOptions={this.state.province}
                      errorMessageAlert={this.props.message}
                      isRequired={this.state.cittadinanzaItaliana}
                      currentStep={tabNumber}
                      defaultValue={this.state.fields?.provinciaNascita ? this.state.fields?.provinciaNascita : undefined}
                      isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                    />
                  ) : null}
                </FormGroup>
              </div>

              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <FormGroup label={'cittaNascita'}>
                  {/*controllo se la provincia = stato estero */}
                  {!this.state.cittadinanzaItaliana || this.state.fields?.provinciaNascita?.label === 'Stato Estero' ? (
                    <GenericInput
                      type={'text'}
                      key="cittaNascita"
                      id="cittaNascita"
                      isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                      defaultValue={this.state.fields?.cittaNascita ? this.state.fields?.cittaNascita : undefined}
                      errorMessageAlert={this.props.message}
                      isRequired
                      currentStep={tabNumber}
                      onChange={(e) => setDataItem(e, 'input', 'cittaNascita')}
                      label="Città di nascita *"
                      placeholder="Inserire la città di nascita"
                    />
                  ) : this.state.provinciaSelected ? (
                    <GenericSelect
                      key="cittaNascita"
                      elementId="cittaNascita"
                      isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                      defaultOptions={this.state.listaComuni}
                      defaultValue={
                        this.state.fields?.cittaNascita ? [{ label: this.state.fields['cittaNascita'], value: this.state.fields['cittaNascita'] }] : undefined
                      }
                      errorMessageAlert={this.props.message}
                      isRequired
                      currentStep={tabNumber}
                      onChange={(e) => setDataItem(e, 'select', 'cittaNascita')}
                      label="Città di nascita *"
                      placeholder="Inserire la città di nascita"
                    />
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <Label key="foto">Foto *</Label>
              </div>
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <FormGroup label={'Foto'}>
                  <GenericUploadFront
                    name={'Foto'}
                    value={fotoIOT ?? ''}
                    idAttachment={idAttachment}
                    datiAllegato={getDatiUploadFile}
                    id={'foto'}
                    acceptedExtensionFile={['jpg', 'png']}
                    tipologiaAllegato={'foto_iot'}
                    key="foto"
                    errorMessageAlert={this.props.message}
                    currentStep={tabNumber}
                    isRequired
                    isDisabled={
                      this.props.statoIscrizione !== statoCompilazione &&
                      this.props.statoIscrizione !== statoRichiestaIntegrazioni &&
                      this.props.statoIscrizione !== statoIscritto &&
                      this.props.statoIscrizione !== statoSospeso
                    }
                    hideResetFile={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <GenericCheckbox
                title={'Diritti civili'}
                id="dirittiCivili"
                key={'dirittiCivili'}
                defaultChecked={this.state.fields['dirittiCivili']}
                onChange={(e) => setDataItem(e, 'checkbox', 'dirittiCivili')}
                label={'* Dichiaro di godere dei diritti civili.'}
                errorMessageAlert={this.props.message}
                isRequired
                currentStep={tabNumber}
                isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
              />

              <GenericCheckbox
                title={'Condotta'}
                id="condotta"
                key={'condotta'}
                defaultChecked={this.state.fields['condotta']}
                onChange={(e) => setDataItem(e, 'checkbox', 'condotta')}
                label={'* Dichiaro di essere di specchiata condotta morale.'}
                errorMessageAlert={this.props.message}
                isRequired
                currentStep={tabNumber}
                isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
              />

              <GenericCheckbox
                title={'Condanna'}
                id="condanna"
                key={'condanna'}
                defaultChecked={this.state.fields['condanna']}
                onChange={(e) => setDataItem(e, 'checkbox', 'condanna')}
                label={"* Dichiaro di non aver riportato condanna che, a norma del presente ordinamento, comportino la radiazione dall'Albo."}
                errorMessageAlert={this.props.message}
                isRequired
                currentStep={tabNumber}
                isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
              />

              <GenericCheckbox
                title={'Privacy'}
                id="privacy"
                key={'privacy'}
                defaultChecked={this.state.fields['privacy']}
                onChange={(e) => setDataItem(e, 'checkbox', 'privacy')}
                label={
                  '* Dichiaro di essere informato, ai sensi e per gli effetti dell’art. 13 del D.Lgs. 196/2003 e all’art. 13 GDPR 679/16, che i dati personali raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa.'
                }
                errorMessageAlert={this.props.message}
                isRequired
                currentStep={tabNumber}
                isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
              />
            </div>
          </div>
        }
      ></GenericCard>,
    ]

    /********************************* Form Visualizza **************************************/
    const formVisualizza = [
      <GenericCard
        key={'datiAnagraficiModifica'}
        cardHeader="Dati Anagrafici"
        cardBody={
          <div key="formModifica" id="formModifica" className="row">
            <SpidData key={'spidIscrizione'}></SpidData>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <FormGroup label={'cittadinanza'}>
                <GenericInput
                  type={'text'}
                  id="cittadinanza"
                  name={'cittadinanza'}
                  label="Cittadinanza"
                  isDisabled
                  placeholder={' '}
                  currentStep={tabNumber}
                  defaultValue={this.state.fields['cittadinanza'] && this.state.fields['cittadinanza'].label ? this.state.fields['cittadinanza'].label : '-'}
                />
              </FormGroup>
            </div>
            {this.state.fields['cittadinanza'] && this.state.fields['cittadinanza']?.label !== 'Italia' ? (
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <FormGroup label={'scadenza_permesso_soggiorno'}>
                  <GenericInput
                    type="date"
                    isDisabled
                    label="Data di scadenza del permesso di soggiorno"
                    placeholder=" "
                    currentStep={tabNumber}
                    onChange={(e) => setDataItem(e, 'text', 'scadenza_permesso_soggiorno')}
                    defaultValue={this.state.fields['scadenza_permesso_soggiorno'] ? this.state.fields['scadenza_permesso_soggiorno'] : '-'}
                  />
                </FormGroup>
              </div>
            ) : null}
            {this.state.fields['cittadinanza'] && this.state.fields['cittadinanza']?.label === 'Italia' ? (
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <FormGroup label={'provinciaNascita'}>
                  <GenericInput
                    type={'text'}
                    isDisabled
                    id="provinciaNascita"
                    name={'Provincia di nascita'}
                    label="Provincia di nascita"
                    placeholder={' '}
                    currentStep={tabNumber}
                    defaultValue={
                      this.state.fields['provinciaNascita']?.label
                        ? this.state.fields['provinciaNascita']?.label
                        : this.state.fields['provinciaNascita']
                        ? this.state.fields['provinciaNascita']
                        : '-'
                    }
                  />
                </FormGroup>
              </div>
            ) : null}
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <FormGroup label={'cittaNascita'}>
                <GenericInput
                  type={'text'}
                  id="cittaNascita"
                  name={'cittaNascita'}
                  isDisabled
                  placeholder={' '}
                  currentStep={tabNumber}
                  label="Città di nascita"
                  defaultValue={
                    this.state.fields['cittaNascita'] && this.state.fields['cittaNascita']?.label
                      ? this.state.fields['cittaNascita'].label
                      : this.state.fields['cittaNascita'] && !this.state.fields['cittaNascita']?.label
                      ? this.state.fields['cittaNascita']
                      : '-'
                  }
                />
              </FormGroup>
            </div>
            <div>
              <br></br>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <Label>
                  <b>Dati documento di riconoscimento</b>
                </Label>
              </div>
            </div>
            <br></br>
            <br></br>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <FormGroup label={'tipoDocumento'}>
                <GenericInput
                  type={'text'}
                  id="tipoDocumento"
                  name={'tipoDocumento'}
                  isDisabled
                  placeholder={' '}
                  currentStep={tabNumber}
                  label="Tipo di documento"
                  defaultValue={
                    this.state.fields['tipoDocumento'] && this.state.fields['tipoDocumento']?.label ? this.state.fields['tipoDocumento'].label : '-'
                  }
                />
              </FormGroup>
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <FormGroup label={'numeroDocumento'}>
                <GenericInput
                  type={'text'}
                  id="numeroDocumento"
                  name={'numeroDocumento'}
                  isDisabled
                  placeholder={' '}
                  currentStep={tabNumber}
                  label="Numero documento"
                  defaultValue={this.state.fields['numeroDocumento'] ? this.state.fields['numeroDocumento'] : '-'}
                />
              </FormGroup>
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <FormGroup label={'emessoDa'}>
                <GenericInput
                  type={'text'}
                  id="emessoDa"
                  name={'emessoDa'}
                  isDisabled
                  placeholder={' '}
                  currentStep={tabNumber}
                  label="Emesso da"
                  defaultValue={this.state.fields['emessoDa'] ? this.state.fields['emessoDa'] : '-'}
                />
              </FormGroup>
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <FormGroup label={'emessoIl'}>
                <GenericInput
                  type={'date'}
                  id="emessoIl"
                  name={'emessoIl'}
                  isDisabled
                  placeholder={' '}
                  currentStep={tabNumber}
                  label="Data emissione"
                  defaultValue={this.state.fields['emessoIl'] ? this.state.fields['emessoIl'] : '-'}
                />
              </FormGroup>
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <FormGroup label={'scadenzaIl'}>
                <GenericInput
                  type={'date'}
                  id="scadenzaIl"
                  name={'scadenzaIl'}
                  isDisabled
                  placeholder={' '}
                  currentStep={tabNumber}
                  label="Data scadenza"
                  defaultValue={this.state.fields['scadenzaIl'] ? this.state.fields['scadenzaIl'] : '-'}
                />
              </FormGroup>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <GenericCheckbox
                id="dirittiCivili"
                key={'dirittiCivili'}
                defaultChecked={this.state.fields['dirittiCivili']}
                label={'* Dichiaro di godere dei diritti civili.'}
                isDisabled
              />
              <GenericCheckbox
                id="condotta"
                key={'condotta'}
                defaultChecked={this.state.fields['condotta']}
                label={'* Dichiaro di essere di specchiata condotta morale.'}
                isDisabled
              />
              <GenericCheckbox
                id="condanna"
                key={'condanna'}
                defaultChecked={this.state.fields['condanna']}
                label={"* Dichiaro di non aver riportato condanna che, a norma del presente ordinamento, comportino la radiazione dall'Albo."}
                isDisabled
              />
              <GenericCheckbox
                id="privacy"
                key={'privacy'}
                defaultChecked={this.state.fields['privacy']}
                label={
                  '* Dichiaro di essere informato, ai sensi e per gli effetti dell’art. 13 del D.Lgs. 196/2003 e all’art. 13 GDPR 679/16, che i dati personali raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa.'
                }
                isDisabled
              />
            </div>
          </div>
        }
      ></GenericCard>,
    ]

    if (this.state.isLoaded) return <>{this.props.modificaDatiFlag ? formIscrizione : this.props.visualizzaFlag ? formVisualizza : formIscrizione}</>
    else
      return (
        <>
          <GenericSpinner />
        </>
      )
  }
}

export declare interface DatiAnagraficiProps {
  data?: any
  processoIscrizione?: any
  activeTab?: any
  tabClick?: any
  modificaDatiFlag?: any
  reiscrizioneFlag?: any
  visualizzaFlag?: any
  prevData?: any
  prevClicked?: any
  message?: any
  getSelectedValue?: any
  tabNumber?: number
  isModifica?: any
  statoIscrizione?: any
}
