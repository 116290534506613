import React, { useEffect, useMemo } from 'react'
import { Form } from 'design-react-kit'
import useProvince from 'src/hooks/useProvince'
import { getValueAtPath } from 'src/utilities/utility'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import { TIPOLOGIE_ISCRIZIONI_OPTIONS } from 'src/formazione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormAnagrafica: React.FunctionComponent<FormDatiSedeLegaleProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = ''
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const tipologie = TIPOLOGIE_ISCRIZIONI_OPTIONS

  const provincia = getValueAtPath(data, `provincia_res`)?.value
  const comune = getValueAtPath(data, `comune_res`)?.value
  const nazione = getValueAtPath(data, `nazione_res`)?.value

  const provincia_nasc = getValueAtPath(data, `provincia_nasc`)?.value
  const nazione_nasc = getValueAtPath(data, `nazione_nasc`)?.value
  const { province, comuni, nazioni } = useProvince(provincia, comune, () => {
    handleInput(`comune_res`, '')
  })

  useEffect(() => {
    if (nazione) {
      if (nazione !== 'Italia') {
        handleInput(`provincia_res`, { label: 'Stato Estero', value: 'EEP' })
        handleInput(`comune_res`, { label: 'Stato Estero', value: 'EEP' })
      } else if (nazione === 'Italia' && provincia === 'EEP') {
        handleInput(`provincia_res`, '')
        handleInput(`comune_res`, '')
      }
    }
  }, [provincia, comune, nazione])

  useEffect(() => {
    if (nazione_nasc) {
      if (nazione_nasc !== 'Italia') {
        handleInput(`provincia_nasc`, { label: 'Stato Estero', value: 'EEP' })
      } else if (nazione_nasc === 'Italia' && provincia_nasc === 'EEP') {
        handleInput(`provincia_nasc`, '')
      }
    }
  }, [provincia_nasc, nazione_nasc])

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="nome" type="text" label="Nome" suffix="*" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="cognome" type="text" label="Cognome" suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="cod_fisc" type="text" label="Codice Fiscale" suffix="*" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="luogo_nasc" type="text" label="Nato a" suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="data_nasc" type="date" label="Data di nascita" suffix="*" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericSelect}
              id={'nazione_nasc'}
              placeholder="Seleziona Nazione"
              label="Nazione di nascita"
              suffix="*"
              defaultOptions={nazioni}
              data={data}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericSelect}
              id={'provincia_nasc'}
              placeholder="Seleziona Provincia"
              label="Provincia di nascita"
              suffix="*"
              defaultOptions={province}
              data={data}
              errors={errors}
              isDisabled={data?.nazione_nasc?.label !== 'Italia' || viewOnly}
            />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="indirizzo_res" type="text" label="Indirizzo di residenza" suffix="*" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="civico_res" type="text" label="N. civico" suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput as={GenericInput} id="cap_res" type="text" label="CAP" suffix="*" data={data} errors={errors} />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericSelect}
              id={'nazione_res'}
              placeholder="Seleziona Nazione"
              label="Nazione di residenza"
              suffix="*"
              defaultOptions={nazioni}
              data={data}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericSelect}
              id={'provincia_res'}
              placeholder="Seleziona Provincia"
              label="Provincia di residenza"
              suffix="*"
              defaultOptions={province}
              data={data}
              errors={errors}
              isDisabled={data?.nazione_res?.label !== 'Italia' || viewOnly}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericSelect}
              id={'comune_res'}
              placeholder="Seleziona Comune"
              label="Comune di residenza"
              suffix="*"
              defaultOptions={comuni}
              data={data}
              errors={errors}
              isDisabled={data?.nazione_res?.label !== 'Italia' || viewOnly}
            />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <WrpInput
              as={GenericInput}
              id="is_iscritto_ordine"
              type="checkbox"
              label="Iscritto ad un ordine professionale"
              suffix=""
              data={data}
              errors={errors}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            {data?.is_iscritto_ordine && (
              <WrpInput as={GenericInput} id="dati_iscr_ordine" type="text" label="Iscritto all'ordine di" suffix="*" data={data} errors={errors} />
            )}
          </div>
        </div>
        <div className="row">
          <WrpInput
            as={GenericSelect}
            id={'tipologia_iscrizione'}
            placeholder="Seleziona tipo di iscrizione"
            label="Tipo di iscrizione richiesta"
            suffix="*"
            defaultOptions={tipologie}
            data={data}
            errors={errors}
          />
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiSedeLegaleProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormAnagrafica
