import styled from 'styled-components'

export const StyledPage = styled.div`
  .topbar-selector, .pof-detail .card-body{
      display:flex;
      align-items:center;
      justify-content:space-between;
    
    .form-group{
      margin-bottom:0px;
    }
  }
  .card-title{
    font-size:18px;
  }
  
  .list-pof{
    padding-top:40px;
  }
  
  .pof-detail{
    .card-body{
       padding:10px 30px 0px 30px;
    }
  }

`
