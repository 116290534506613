import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice()
export class FormUbicazione extends React.Component<GeneralFormUbicazioneProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      returnedObject: props.returnedObject[0],
      messaggio: props.messaggio,
    }
  }
  render() {
    const { returnedObject, messaggio, provincia } = this.state
    return (
      <>
        {returnedObject === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={!isMobileDivice ? 'col-6' : 'col-12'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idIndirizzo'}
                    label={'Indirizzo'}
                    value={returnedObject.indirizzoResidenza ? returnedObject.indirizzoResidenza : '-'}
                    isReadOnly
                  />
                </div>
                <div className={!isMobileDivice ? 'col-6' : 'col-12'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCitta'}
                    label={'Città'}
                    value={returnedObject.citta ? returnedObject.citta : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={!isMobileDivice ? 'col-6' : 'col-12'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idProvincia'}
                    label={'Provincia'}
                    value={returnedObject.provincia ? returnedObject.provincia : '-'}
                    isReadOnly
                  />
                </div>
                <div className={!isMobileDivice ? 'col-6' : 'col-12'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCap'}
                    label={'Cap'}
                    value={returnedObject.capResidenza ? returnedObject.capResidenza : '-'}
                    isReadOnly
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormUbicazioneProps {
  returnedObject?: any
  provincia?: string
  messaggio?: string
}
