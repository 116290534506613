import React from 'react'
import { Tab, Tabs, TabTitleText } from '@patternfly/react-core'
import { GenericSpinner } from 'src/backoffice/app/components'

class GenericHorizontalTab extends React.Component<GeneralHorizontalTab, any> {
    constructor(props) {
        super(props);
        this.state = {
            activeTabKey: 0
        };
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    handleTabClick(event, tabIndex) {
        this.setState({
            activeTabKey: tabIndex
        });
        if (this.props.onTabClick !== undefined)
            this.props.onTabClick(event)

        if (this.props.currentTab !== undefined)
            this.props.currentTab(tabIndex)
    }

    render() {
        return (
            <Tabs
                unmountOnExit
                activeKey={this.state.activeTabKey}
                onSelect={this.handleTabClick}
                role="region"
                isFilled
            >
                {this.props.contents.map((content, id) => (
                    <Tab eventKey={content.id} key={id} title={<TabTitleText><strong>{content.title}</strong></TabTitleText>}>
                        {this.props.isLoaded ? content.body : <GenericSpinner/>}
                    </Tab>
                ))}
            </Tabs>
        );
    }
}

export default GenericHorizontalTab;

export declare interface GeneralHorizontalTab {
    /**
     * Si passa la lista [{id, title, body}]
     */
    contents?: any;
    onTabClick?: any;
    /**
     * È la props che restituisce l'index del tab corrente
     */
    currentTab?: any;
    isLoaded?: boolean;
}
