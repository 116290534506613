import {
  AvatarContainer,
  AvatarIcon,
  Button,
  Callout,
  CalloutText,
  CalloutTitle,
  Icon,
  Label,
  Table
} from 'design-react-kit'
import React, { Fragment } from 'react'
import GenericCard from '../../../components/GenericCard'
import { Title } from '@patternfly/react-core'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getPolizzaById } from 'src/processes/assicurazioni/Assicurazione'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getSoggettoByIdIscrizioneFO } from 'src/processes/Soggetto'
import { AppUtils, AppUtils as U } from 'src/app/utils/FormUtils'
import { columnsTabellaUno } from 'src/processes/assicurazioni/CostantiPolizze'
import { RicercaTabellaUnoByIdDTO } from '@parsec/mspo'
import { Prestazione } from 'src/model/Prestazione'
import { VocePrestazione } from 'src/model/VocePrestazione'
import {
  getTabellaUnoById,
  recuperoTabellaUnoDefinitivaAssociataAllaPolizza
} from 'src/processes/assicurazioni/TabellaUno'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import GenericInput from 'src/frontend/app/components/GenericInput'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { getIscrizioneByIdFO } from 'src/processes/ProcessoIscrizione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export default class Riepilogo extends React.Component<any, any>{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      tabClick: props.tabClick,
      prevData: props.prevData,
      polizza: {},
      visualizzaFlag : props.visualizzaFlag,
      modificaDatiFlag: props.modificaDatiFlag,
      errors: {},
      isLoaded : false,
      message: "",
      utenteSTP: false,
      mySelection: [],
      tabellaUnoNotPresentFlag: false,
    };

  }

  async componentDidMount(): Promise<void> {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    this.setState({isLoaded: false})
    let polizza = this.state.polizza;

    if(this.props.storicoFlag) {
            polizza = this.props.data;
            let response = this.props.data;

            polizza.operaStudioAssociato = U.dn(response.operaStudioAssociato);
            polizza.altrePolizze = U.dn(response.altrePolizze);
            polizza.idCategoriaDue = {label: (response.categoria === 2) ? 'Si' : 'No', value: (response.categoria === 2) ? 'Si' : 'No'};
            polizza.maternita = U.dn(response.maternita);
            polizza.paternita = U.dn(response.paternita);
            polizza.paternita = U.dn(response.paternita);
            polizza['dataRichiesta'] = response.dataRichiesta;
            polizza['idIscrizione'] = response.idIscrizione;
            polizza['categoria'] = response.categoria;

            if(response.associatoConConaf === 1) {
              polizza['vrcConaf'] = (response.vrcConaf)
              polizza['vopConaf'] = (response.vopConaf) //? response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
              polizza['fasciaPremio'] = response.fasciaPremioConaf;
              polizza['premio'] = response.premioConaf.toFixed(2).toString().replace('.', ',');
              polizza['massimale'] = response.massimaleConaf.toFixed(2).toString().replace('.', ',');
              polizza['valoreRischio'] = response.valoreRischioConaf;
              this.setState({polizzaCollettiva: true})
            }

            if(response.associatoConAltri === 1) {
              polizza['vrcConaf'] = (response.vrcConaf) //? response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
              polizza['vopConaf'] = (response.vopConaf) //? response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
              polizza['premio'] = response.premioAltri.toFixed(2).toString().replace('.', ',');
              polizza['fasciaPremio'] = response.fasciaPremioConaf;
              polizza['massimale'] = response.massimaleAltri.toFixed(2).toString().replace('.', ',');
              polizza['valoreRischio'] = response.valoreRischioAltri;
              this.setState({polizzaIndividuale: true})
            }

            this.props.getSelectedValue(response, 'polizza')
            await this.setState({polizza:polizza, isLoaded: true});

    }  else {

        if(this.props.modificaDatiFlag) {
            polizza['idPolizza'] = this.props.tabellaUno.idPolizza;
            this.setState({polizza: this.props.data})
        }
        if(this.props.visualizzaFlag) {
          if(polizza['idPolizza']) polizza['idPolizza'] = this.props.data.id;
          if(polizza['id']) polizza['idPolizza'] = this.props.data.id;
          this.setState({polizza: this.props.data})
        }
        else if(this.props.prevData && this.props.prevData.id) {
              polizza['idPolizza'] = this.props.prevData.id;
              this.setState({polizza: this.props.prevData})
            }
            else {
              polizza['idPolizza'] = this.props.data.id;
        }

        await getPolizzaById(this.props.data.id).then(async (response: any)=> {

                if(response.idIscrizione) {
                  await getIscrizioneByIdFO(response.idIscrizione, null).then((response: any) => {
                    if(response.data.iotDTO.numeroIscrizioneOrdine) {
                      this.setState({numeroIscrizione: response.data.iotDTO.numeroIscrizioneOrdine})
                    }
                  })
                }
                //casting da numerale a dropdown
                polizza.operaStudioAssociato = U.dn(response.operaStudioAssociato);
                polizza.altrePolizze = U.dn(response.altrePolizze);
                polizza.idCategoriaDue = {label: (response.categoria === 2) ? 'Si' : 'No', value: (response.categoria === 2) ? 'Si' : 'No'};
                polizza.maternita = U.dn(response.maternita);
                polizza.paternita = U.dn(response.paternita);
                polizza.paternita = U.dn(response.paternita);
                polizza['idPolizza'] = this.props.data.id;

                polizza.privacy = (response.privacy === 1) ? true : false;
                polizza.haLettoCircostanza = (response.haLettoCircostanza === 1) ? true : false;
                polizza['dataRichiesta'] = response.dataRichiesta;
                polizza['idIscrizione'] = response.idIscrizione;
                polizza['categoria'] = response.categoria;

                if(response.associatoConConaf === 1) {
                  polizza['vrcConaf'] = (response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') )
                  polizza['vopConaf'] = (response.vopConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') ) //? response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
                  polizza['fasciaPremio'] = response.fasciaPremioConaf;
                  polizza['premio'] = response.premioConaf.toFixed(2).toString().replace('.', ',');
                  polizza['massimale'] = response.massimaleConaf.toFixed(2).toString().replace('.', ',');
                  polizza['valoreRischio'] = response.valoreRischioConaf;
                  this.setState({polizzaCollettiva: true})
                }

                if(response.associatoConAltri === 1) {
                  polizza['vrcConaf'] = (response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') ) //? response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
                  polizza['vopConaf'] = (response.vopConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') ) //? response.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 0;
                  polizza['premio'] = response.premioAltri.toFixed(2).toString().replace('.', ',');
                  polizza['fasciaPremio'] = response.fasciaPremioConaf;
                  polizza['massimale'] = response.massimaleAltri.toFixed(2).toString().replace('.', ',');
                  polizza['valoreRischio'] = response.valoreRischioAltri;
                  this.setState({polizzaIndividuale: true})
                }

                //Get tabella uno compilata
                let idTabella;

                if(this.props.tabellaUno) {

                  if(this.props.tabellaUno.id) idTabella = this.props.tabellaUno.id
                  if(this.props.tabellaUno.idTabellaUno) idTabella = this.props.tabellaUno.idTabellaUno
                }
                await this.getTabella(idTabella);

                await this.setState({polizza: polizza, isLoaded: true});

        }).catch((err) => {
                    setTimeout(() => {
                            let errorObject = {
                              message: err.message,
                              typeMessage: "danger"
                            }
                            this.props.getSelectedValue("message", errorObject)
                            this.setState({ message: err.message, typeMessage: 'danger' })
                    }, 3000);
        })
    }

    if(TokenStore.getInstance().isCurrentAccountSTP() === true) {

      //chiamata al servizio 'getSoggettoByIdIscrizione' che restituisce i dati del soggetto a partire dall'idIscrizione
      await getSoggettoByIdIscrizioneFO(this.props.data.idIscrizione).then((response: any) => {
        this.state.polizza.nome = response.data.ragioneSociale
        this.state.polizza.cognome ="";
        this.state.polizza.codiceFiscale = response.data.partitaIva;
        this.setState({iniziali: response.data.ragioneSociale.charAt(0) + response.data.ragioneSociale.charAt(1)});
      }).catch((err) => {
            setTimeout(() => {
                let errorObject = {
                  message: err.message,
                  typeMessage: "danger"
                }
                this.props.getSelectedValue("message", errorObject)
                this.setState({ message: err.message, typeMessage: 'danger' })
            }, 3000);
      })
      this.setState({utenteSTP: true})
    } else {
      const keyData = TokenStore.getInstance().getDecodedTokenJWT();
      polizza.nome = keyData.given_name;
      polizza.cognome = keyData.family_name;
      polizza.codiceFiscale = keyData.fiscalNumber;
      this.setState({polizza:polizza})
      this.setState({iniziali: keyData.given_name.charAt(0)+keyData.family_name.charAt(0)});
      this.setState({utenteSTP: false})
    }

  }

  getTabella = async (tabellaId) => {

    let aree: any[] = [];

    /** Richiamare servizio per recuperare una tabella uno by ID */
    let tabellaUnoByID: RicercaTabellaUnoByIdDTO = {
      idCompilazioneTabellaRischio: tabellaId
    }

    if(tabellaId !== undefined) {

      getTabellaUnoById(tabellaUnoByID)
        .then(async (response: any)=> {
            this.state.mySelection.splice(0,this.state.mySelection.length);
            await this.setState({isLoaded: false, tabellaUnoNotPresentFlag: false})
            await this.setState({tabellaUnoSelezionata: response, sbloccaTabella: response.sbloccaTabella})

            let polizza = this.state.polizza;
            let tabella = response;
            if(polizza['idTabellaUno']) polizza['idTabellaUno'] = tabellaId;
            if(tabella.idTabellaUno) tabella.idTabellaUno = tabellaId;

            await this.setState({ polizza });
            this.props.getSelectedValue(tabella, "tabellaUno")

            await response.elencoVociPrestazione.map(async (item)=> {
                    //Creazione area delle prestazioni
                    let area: Prestazione = {
                      id: parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                      title: item.vocePrestazione.area,
                      isExpanded: true,
                      data: [
                        {
                          "id": "codicePrestazione",
                          "value": parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                          "expandedValue": parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                          "type": "label",
                          "disabled": true,
                          "width": 1
                        },
                        {
                          "id": "areaPrestazione",
                          "value": item.vocePrestazione.area,
                          "expandedValue": item.vocePrestazione.area,
                          "type": "label",
                          "disabled": true,
                          "width": 100
                        },
                        {
                          "id": "codiceParametri",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "codiceCompetenza",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "attivitaProf",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "compenso",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "nPrestazioni",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "classeRischio",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreRischio",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreOpera",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreOperaTotale",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        }
                      ],
                      children: []
                    }
                    await aree.push(area)
                  })
                  await aree.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0);
                  const tmp = new Map()
                  aree = await aree.reduce((acc, e) => {
                    if(tmp.has(e.id)) {
                      if (!tmp.get(e.id).includes(e.jobid)) {
                        acc.push(e)
                        tmp.set(e.id, [...tmp.get(e.id), e.jobid])
                      }
                    } else {
                      acc.push(e)
                      tmp.set(e.id, [e.jobid])
                    }
                    return acc
                  }, [])

                  aree.map(async (area)=> {
                        let childItems: any = [];
                        await response.elencoVociPrestazione.map(async (vocePrestazioneItem)=> {

                              if(vocePrestazioneItem.vocePrestazione.area === area.title) {
                                ;
                                let child: VocePrestazione = {
                                  id: vocePrestazioneItem.idCompilazioneTabellaRischioDettaglio,
                                  ordinamento: vocePrestazioneItem.vocePrestazione.ordinamento,
                                  data: [
                                    {
                                      "id": "codicePrestazione",
                                      "value": vocePrestazioneItem.vocePrestazione.codicePrestazione,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 1
                                    },
                                    {
                                      "id": "areaPrestazione",
                                      "value": vocePrestazioneItem.vocePrestazione.prestazione,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 100
                                    },
                                    {
                                      "id": "codiceParametri",
                                      "value": vocePrestazioneItem.vocePrestazione.codiceParametri,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "codiceCompetenza",
                                      "value": vocePrestazioneItem.vocePrestazione.codiceCompetenza,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "attivitaProf",
                                      "value": vocePrestazioneItem.attivitaFormativa === 1 ? true: false,
                                      "type": "checkbox",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "compenso",
                                      "value": (vocePrestazioneItem.compensoPrestazione).toFixed(2),
                                      "type": "label-currency",
                                      "disabled": true,
                                      "width": 30,
                                      "valid": true,
                                    },
                                    {
                                      "id": "nPrestazioni",
                                      "value": vocePrestazioneItem.numeroPrestazioni,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30,
                                      "valid": true,
                                    },
                                    {
                                      "id": "classeRischio",
                                      "value": (vocePrestazioneItem.vocePrestazione.classeRischio).toFixed(2),
                                      "type": "label-it-number",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "valoreRischio",
                                      "value": (vocePrestazioneItem.numeroPrestazioni * vocePrestazioneItem.compensoPrestazione * vocePrestazioneItem.vocePrestazione.classeRischio).toFixed(2),
                                      "type": "label-it-number",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "valoreOpera",
                                      "value": (vocePrestazioneItem.valoreOpera).toFixed(2),
                                      "type": "label-currency",
                                      "disabled": true,
                                      "width": 80,
                                      "valid": true,
                                    },
                                    {
                                      "id": "valoreOperaTotale",
                                      "value": (vocePrestazioneItem.valoreOpera * vocePrestazioneItem.numeroPrestazioni).toFixed(2),
                                      "type": "label-currency",
                                      "disabled": true,
                                      "width": 80
                                    }
                                  ],
                                }
                                await childItems.push(child)
                              }
                        })

                        area.children = childItems;

                        childItems = await childItems.reduce((acc, e) => {
                          if(tmp.has(e.title)) {
                            if (!tmp.get(e.title).includes(e.jobid)) {
                              acc.push(e)
                              tmp.set(e.title, [...tmp.get(e.title), e.title])
                            }
                          } else {
                            acc.push(e)
                            tmp.set(e.title, [e.jobid])
                          }
                          return acc
                        }, [])


                        await this.setVociDaTabella(area);
                  });
                  await this.setState({isLoaded: true})

        }).catch((err) => {
              setTimeout(() => {
                    let errorObject = {
                      message: err.message,
                      typeMessage: "danger"
                    }
                    this.props.getSelectedValue("message", errorObject)
                    this.setState({ message: err.message, typeMessage: 'danger' })
            }, 3000);
        })
    } else {
      await this.setState({isLoaded: true, tabellaUnoNotPresentFlag: true})
    }

  }

  setVociDaTabella = async (area: any) => {
      this.setState({isLoaded: false});

      let currentSelection = this.state.mySelection;
      currentSelection.push(area);
      this.setState({mySelection: currentSelection, isLoaded: true});
  }

  scaricaTabellaUno = () => {

    const doc = new jsPDF({
          orientation: 'l', // landscape
          unit: 'pt', // points, pixels won't work properly
          format: "a4" // set needed dimensions for any element
    });
    doc.text("Tabella Uno Compilata", 350, 30);
    doc.setFontSize(10)
    let currentTime  = new Date().toLocaleString('it-IT',
        {'weekday': 'long', 'month': '2-digit', 'year': 'numeric', 'day': '2-digit', 'hour':'2-digit','minute':'2-digit','second':'2-digit'});
    doc.text("Download "+currentTime, 620, 30);
    doc.setFont('helvetica', 'normal')
    doc.text("Nominativo: "+this.state.polizza.nome + " " + this.state.polizza.cognome , 40, 50 );
    doc.text("CF: "+this.state.polizza.codiceFiscale, 40, 65 );
    doc.text("N. Iscrizione: "+this.state.polizza.idIscrizione, 40, 80 );
    doc.text("Data richiesta della polizza: "+this.props.data.dataCreazioneRichiesta.split('T')[0], 40, 95 );
    doc.text("VRC: "+this.state.polizza.vrcConaf, 650, 80 );
    doc.text("VOP: "+this.state.polizza.vopConaf, 650, 95 );
    autoTable(doc, { startY: 110, html: '#my-table' })
    doc.save('Tabella_Uno.pdf')

  }

  scaricaCsvTabellaUno = async () => {
    let datiPrestazioni = "";
    await recuperoTabellaUnoDefinitivaAssociataAllaPolizza(this.state.polizza.idPolizza).then(async (responseTabella: any)=>{
      //Definisco le colonne del file csv
      let columnsCsv = ["Codice", "Area", "Prestazione","Cod.Parametri","Cod.Competenza","Attivita di Formatore","Compenso","N. Prestazioni","Classe Rischio","Valore Rischio","ValoreOperaProg","ValoreOperaTotale"]
      datiPrestazioni = datiPrestazioni + columnsCsv.join(";");

      if(responseTabella && responseTabella.elencoVociPrestazione) {
          responseTabella.elencoVociPrestazione = responseTabella.elencoVociPrestazione.sort((p1, p2) => (p1.codicePrestazione > p2.codicePrestazione) ? 1 : (p1.codicePrestazione < p2.codicePrestazione) ? -1 : 0);

          responseTabella.elencoVociPrestazione.map(prestazione=>{
              //Costruisce il csv
              datiPrestazioni = datiPrestazioni + "\n"+prestazione.vocePrestazione.codicePrestazione.toString() + ";"   //Codice
              datiPrestazioni = datiPrestazioni + prestazione.vocePrestazione.area.replace(";","") + ";"                //Area
              datiPrestazioni = datiPrestazioni + prestazione.vocePrestazione.prestazione.replace(";","") + ";"         //Prestazione
              datiPrestazioni = datiPrestazioni + prestazione.vocePrestazione.codiceParametri.replace(";","") + ";"     //Cod Parametri
              datiPrestazioni = datiPrestazioni + prestazione.vocePrestazione.codiceCompetenza.replace(";","") + ";"    //Cod Competenza
              datiPrestazioni = datiPrestazioni + (prestazione.attivitaFormativa === 1 ? "SI" : "NO") + ";"                                   //Attivita Formatore
              datiPrestazioni = datiPrestazioni + prestazione.compensoPrestazione.toString() + ";"                      //Compenso
              datiPrestazioni = datiPrestazioni + prestazione.numeroPrestazioni.toString() + ";"                        //N Prestazioni
              datiPrestazioni = datiPrestazioni + prestazione.vocePrestazione.classeRischio.toString() + ";"            //ClasseRischio
              datiPrestazioni = datiPrestazioni + (prestazione.numeroPrestazioni * prestazione.compensoPrestazione * prestazione.vocePrestazione.classeRischio) + ";"     //ValoreRischio
              datiPrestazioni = datiPrestazioni + ((prestazione.valoreOpera).toString()) + ";"     //ValoreOpera
              datiPrestazioni = datiPrestazioni + (prestazione.valoreOpera * prestazione.numeroPrestazioni).toString() + ";"    //ValoreOperaTotale                       //ValoreOpera
          });
      }
    }).catch((err) => {
      this.setState({ message: err.message, typeMessage: "danger" })
      // throw new Error("Errore durante il caricamento dei dati " + err);
    })

    const blob = window.URL.createObjectURL(
      new Blob( [datiPrestazioni], { type: "text/csv" } )
    );

    const fileNameCSV = "Csv_Tabella_Uno" + moment(Date.now()).format("YYYYMMDDHHmmss") + ".csv";
    saveAs(blob, fileNameCSV);
  }


  render() {


    /********************************* Form Tabella ************************************/
    const formDatiPolizza = [
      <div id="formPolizza" key={"formDatiPolizza"} hidden={(!this.state.polizza)}>
          <GenericCard key={'tabellaUno'} cardHeader={
                        <div className='justify-content-between d-flex'>
                        <p>{'Tabella 1'}</p>
                        </div>
                      }
                      cardBody={
                        <div className="row">
                            <div className={isCurrentMobileDevice() ? "p-2" : "col-2"}>
                                  <AvatarContainer>
                                      <AvatarIcon color='orange' size='xl'>
                                        <p aria-hidden='true'>{this.state.iniziali}</p>
                                      </AvatarIcon>
                                  </AvatarContainer>
                            </div>
                            <div className={isCurrentMobileDevice() ? "p-2" : "col-4"}>
                                  {this.state.utenteSTP && <div><Label><h3 style={{fontSize:'20px'}}>{this.state.polizza?.nome}</h3></Label></div>}

                                  {!this.state.utenteSTP && <div><Label><h3 style={{fontSize:'20px'}}>{this.state.polizza?.nome+" "+this.state.polizza?.cognome}</h3></Label></div>}
                                  <div><Label><h6 style={{fontSize:'14px'}}>Iscrizione {(this.state.numeroIscrizione) ? this.state.numeroIscrizione : "-"}</h6></Label></div>
                                  <div><Label><h6 style={{fontSize:'14px'}}>Data richiesta: {(this.props.data.dataCreazioneRichiesta) ? this.props.data.dataCreazioneRichiesta.split('T')[0] : "Oggi"}</h6></Label></div>
                                  <div><Label><h6 style={{fontSize:'14px'}}>{this.state.polizza?.codiceFiscale}</h6></Label></div>
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                                <Callout color="note">
                                  <CalloutTitle><Title headingLevel={"h4"}>VRC</Title></CalloutTitle>
                                  <CalloutText bigText>€ {(this.state.polizza.vrcConaf) ? this.state.polizza.vrcConaf : "0"}</CalloutText>
                                </Callout>
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                                <Callout color="danger">
                                  <CalloutTitle><Title headingLevel={"h4"}>VOP</Title></CalloutTitle>
                                  <CalloutText bigText>€ {(this.state.polizza.vopConaf) ? this.state.polizza.vopConaf  : "0"}</CalloutText>
                                </Callout>
                            </div>
                        </div>

                      }></GenericCard>
          <GenericCard key={'riepilogo'} cardHeader={
                        <div className='justify-content-between d-flex'>
                        <p>{'Riepilogo'}</p>
                        </div>
                      }
                      cardBody={
                        <div className="row">
                            <div className="col-12 my-2 label-style">
                              <Label><h6 style={{fontSize:'16px'}}>Categoria: </h6><span className="data-category">{(this.state.polizza.categoria) ? this.state.polizza.categoria : "Non disponibile"}</span></Label>
                            </div>
                            <div className="col-12 my-2 label-style">
                              <Label><h6 style={{fontSize:'16px'}}>Valore della fascia corrispondente: </h6><span className="data-category">{(this.state.polizza.fasciaPremio) ? this.state.polizza.fasciaPremio : "Non disponibile"}</span></Label>
                            </div>
                            <div className="col-12 my-2 label-style">
                              <Label><h6 style={{fontSize:'16px'}}>Range fascia corrispondente: </h6><span className="data-category">{(this.state.polizza.valoreRischio) ? this.state.polizza.valoreRischio+" € ": "Non disponibile"}</span></Label>
                            </div>

                            {
                              /*
                              hidden={this.state.polizza.associatoConAltri === 1}
                              */
                            }

                            <div className="col-12 my-2 label-style" hidden={this.state.polizza.associatoConAltri === 1}>
                              <Label><h6 style={{fontSize:'16px'}}>Importo: </h6><span className="data-category"> {(this.state.polizza.premio !== undefined) ? ("€ "+this.state.polizza.premio+"") : "Non disponibile"}</span></Label>
                            </div>
                            <div className="col-12 my-2 label-style" hidden={this.state.polizza.associatoConAltri === 1}>
                              <Label><h6 style={{fontSize:'16px'}}>Massimale corrispondente: </h6><span className="data-category">{(this.state.polizza.massimale) ? ("€ "+this.state.polizza.massimale+"") : "Non disponibile"}</span></Label>
                            </div>
                        </div>
          }></GenericCard>

      </div>
    ];

    if(this.state.isLoaded)
    return(
        <div    key={"formDatiPolizza"}>
         {formDatiPolizza}
          <div id="tabellaUnoCompilata" hidden={this.props.storicoFlag || this.state.tabellaUnoNotPresentFlag}>
            <GenericCard key={'tabellaMySelection'}
                  cardHeader={
                            <div className='justify-content-between d-flex'>
                              <p>{'Tabella Uno Compilata'}</p>
                              <div hidden={this.props.storicoFlag || this.state.tabellaUnoNotPresentFlag} style={{textAlign: 'center'}}>
                                <Button onClick={this.scaricaTabellaUno}>
                                      <Icon icon={'it-download'} size='sm' /> PDF
                                </Button>
                                <Button onClick={this.scaricaCsvTabellaUno}>
                                          <Icon icon={'it-download'} size='sm' /> CSV
                                </Button>
                              </div>
                            </div>
                  }
                  cardBody={
                    <Table hidden={isCurrentMobileDevice()} id="my-table" aria-label="Sort table" className={"policy-table"}>
                        <thead>
                            <tr>
                                {columnsTabellaUno?.map((column) => column.name)?.map((column:any, index:any) => {
                                  return (
                                    <th style={{fontSize:'initial'}} key={index}>{column}</th>
                                  )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.mySelection?.map((row:any, rowIndex:any) => (
                          <Fragment key={this.state.mySelection[rowIndex].id}>
                            <tr className='table-primary' key={this.state.mySelection[rowIndex].id} style={{lineHeight: 'normal'}}>
                              <th style={{fontSize:'initial'}}>
                                <div className="row">
                                      <div style={{width: "100px"}} className="col-1">
                                          <Icon color="primary" size='sm' icon={'it-expand'} />{'  '+row.id}
                                      </div>
                                </div>
                              </th>

                              {this.state.mySelection[rowIndex].data?.map((intestazione:any, indexIntestazione) => {
                                    if(indexIntestazione > 0) {
                                      if(this.state.mySelection[rowIndex].isExpanded) {
                                        return (<th style={{fontSize:'initial'}} key={indexIntestazione}>{intestazione.expandedValue}</th>)
                                      }
                                      else {
                                        return (<th style={{fontSize:'initial'}} key={indexIntestazione}>{intestazione.value}</th>)
                                      }
                                    }
                                    else return null
                                })
                              }
                            </tr>
                            {(this.state.mySelection[rowIndex].isExpanded) ? (
                                  this.state.mySelection[rowIndex].children?.map((child:any, childIndex) => {

                                    return (
                                    <tr key={child.id} hidden={row.isExpanded === false} style={{color:'black'}}>
                                              {child.data.map((childData:any, childDataIndex) => {
                                                  return (
                                                    <td key={childDataIndex} width={childData.width} style={{lineHeight: 'normal', minHeight:'20px', fontSize:'initial'}}>
                                                    { (childData.type === 'input' && false)
                                                        ? <GenericInput type='number' min={0} max={this.props.max} isDisabled={childData.disabled} key={childDataIndex} id={childData.id} defaultValue={childData.value} />
                                                        : (childData.type === 'checkbox') ?
                                                          <div style={{marginTop:'5px'}}>
                                                            <Label style={{marginTop:'5px'}}>{childData.value === true ? 'SI' : 'NO'}</Label>
                                                          </div>
                                                        : (childData.type === 'label-currency') ? <Label style={{marginTop:'5px'}}>{AppUtils.currency(childData.value)}</Label>
                                                        : (childData.type === 'label-it-number') ? <Label style={{marginTop:'5px'}}>{AppUtils.itNumber(childData.value)}</Label>
                                                        : <Label style={{marginTop:'5px'}}>{childData.value}</Label>
                                                    }
                                                  </td>
                                                  )
                                              })}

                                          </tr>
                                    )
                                  })

                            ) : (<div key={"space"}></div>)}
                          </Fragment>
                        ))}

                        </tbody>
                    </Table>
                  }>
            </GenericCard>
          </div>




        </div>
    )
    else return (<div key={"spinner"}><GenericSpinner></GenericSpinner></div>)
    }
}

export declare interface RiepilogoProps {
  data?: any;
  polizza?: any;
  activeTab?: any;
  tabClick?: any;
  visualizzaFlag?: any;
  aumentoMassimaleFlag?: any;
  modificaDatiFlag?: any;
  getData?:any;
  prevData?:any;
  getSelectedValue?:any;
  tabNumber?: any;
  message?: any;
  idTabella?: any;
  storicoFlag?: any;
  mySelection?: any;

}

