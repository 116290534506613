import React, { useMemo } from 'react'
import { Form } from 'design-react-kit'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'

const FormDatiDichiarazioni: React.FunctionComponent<FormDatiDichiarazioniProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiDichiarazioni'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const assenzaSentenze =
    'Assenza di sentenza di condanna passata in giudicato per reati gravi in danno dello Stato o della Comunità che incidono sulla moralità professionale, nonché per reati di partecipazione a un’organizzazione criminale, associazione di tipo mafioso L.575/65 (e successive modifiche e integrazioni), di corruzione, di frode, di riciclaggio'
  const assenzaFallimento =
    'Assenza di stato di fallimento, di liquidazione coatta, di concordato preventivo, e di procedimenti per la dichiarazione di una di tali situazioni'
  const rispettoNormative =
    'Rispetto delle normative del lavoro dei dipendenti e collaboratori\n- Normativa vigente relativamente agli obblighi pagamento previdenziali e assicurativi;\n- Disposizioni normative e contrattuali, ivi comprese quelle in materia di sicurezza, per il personale dipendente e non;\n- norme sul diritto al lavoro dei disabili CCNL di riferimento'
  const formatore = 'Il responsabile della formazione ricopre per l’Agenzia Formativa anche il ruolo di Formatore'
  const isRespFormFormatore =
    "Il responsabile della formazione ha un rapporto di collaborazione professionale e/o di rapporto di lavoro dipendente a qualsiasi titolo per l'intera durata dell'accreditamento"
  const hasRespFormConflitti = 'Il responsabile della formazione non ha conflitti di interesse rispetto alle funzioni affidate'
  const nuovaCostituzione = 'Il soggetto è di nuova costituzione'
  const flagImpegnoProdDoc =
    'Si impegna a produrre la documentazione entro 30 giorni dalla relativa approvazione o deposito. Accetta fin da ora che l’accreditamento viene rilasciato sotto condizione'
  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="assenzaSentenze" type="checkbox" label={assenzaSentenze} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="assenzaFallimento" type="checkbox" label={assenzaFallimento} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="rispettoNormative" type="checkbox" label={rispettoNormative} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="formatore" type="checkbox" label={formatore} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="isRespFormFormatore" type="checkbox" label={isRespFormFormatore} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="hasRespFormConflitti" type="checkbox" label={hasRespFormConflitti} suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <WrpInput as={GenericInput} id="massimalePolizza" type="number" label="Massimale polizza assicurativa" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="tipoPolizza" type="text" label="Tipologia di rischio della polizza" suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <WrpInput as={GenericInput} id="polizzaValidaDa" type="date" label="Polizza valida dal" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="polizzaValidaA" type="date" label="Polizza valida al" suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="nuovaCostituzione" type="checkbox" label={nuovaCostituzione} data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={GenericInput} id="flagImpegnoProdDoc" type="checkbox" label={flagImpegnoProdDoc}  data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <WrpInput
              as={GenericInput}
              id="fatturatoFormazione"
              type="number"
              label="fatturato relativo alla formazione"
              suffix="*"
              data={data}
              errors={errors}
            />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiDichiarazioniProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiDichiarazioni
