import { CardReadMore, CardText, Col, Icon, Row } from 'design-react-kit'
import * as React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { PATH_TO_FO_ELENCO_EVENTI_CATALOGO, PATH_TO_RICERCA_ALBO_UNICO } from 'src/app/utils/RoutesConstants'
import { goTo } from 'src/utilities/utility'

class Home extends React.Component {

  render() {
    return (
      <div className="section section-muted pb-5 pt-0">
        <div className="section section-background-header py-5">
          <Row>
          <Col xs='12' lg='6'>
              {/* Start card */}
              <Card spacing className='card-bg card-big border-bottom-card'>
                <div className='flag-icon'></div>
                <div className='etichetta'>
                  <Icon icon='it-settings' />
                  <span>ALBO UNICO</span>
                </div>
                <CardBody>
                  <div className='top-icon'>
                    <Icon icon='it-card' />
                  </div>
                  <CardTitle tag='h5'>Ricerca albo unico</CardTitle>
                  <CardText>
                    Ricerca gli utenti iscritti all'albo e le STP censite nel sistema
                  </CardText>
                  <CardReadMore iconName='it-arrow-right' text='Vai alla ricerca' onClick={() => goTo(PATH_TO_RICERCA_ALBO_UNICO)} />
                </CardBody>
              </Card>
              {/* end card */}
            </Col>
            <Col xs='12' lg='6'>
              {/* Start card */}
              <Card spacing className='card-bg card-big border-bottom-card'>
                <div className='flag-icon'></div>
                <div className='etichetta'>
                  <Icon icon='it-settings' />
                  <span>EVENTI FORMATIVI</span>
                </div>
                <CardBody>
                  <div className='top-icon'>
                    <Icon icon='it-card' />
                  </div>
                  <CardTitle tag='h5'>Eventi formativi del catalogo CONAF</CardTitle>
                  <CardText>
                    Ricerca gli eventi formativi del catalogo CONAF censiti nel sistema
                  </CardText>
                  <CardReadMore iconName='it-arrow-right' text='Vai agli eventi' onClick={() => goTo(PATH_TO_FO_ELENCO_EVENTI_CATALOGO)}  />
                </CardBody>
              </Card>
              {/* end card */}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
};

export default Home;
