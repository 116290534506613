

export default {
  nr_prot_ordine:{
    'isRequired': true,
    'isNumber': true,
    'min': {
      'check': 1,
      'error': 'Devi inserire un numero maggiore di 1'
    }
  },
  data_prot_ordine:{
    'isRequired': true,
    isDate:{
      format:"YYYY-MM-DD"
    }
  },
  delibera_prot_ordine:{
    isRequired:true
  },
  nr_iscr_destinatari_pf: {
    'isRequired': true,
    'isNumber': true,
    'min': {
      'check': 0,
      'error': 'Inserisci un numero'
    }
  },
  analisi_fabb_formativi: {
    'isRequired': true
  },
  strategia_obiettivi: {
    'isRequired': true
  },
  organiz_e_prior_eventi: {
    'isRequired': true
  },
  budget_prev_bilancio:{
    'isRequired': true,
    'isNumber': true,
    'min': {
      'check': 1,
      'error': 'Devi inserire un numero maggiore di 1'
    }
  },
  si_impegna_rendere_evidenti_costi_standard:{
    'isRequired': true,
  },
  si_impegna_non_sup_costi_standard:{
    'isRequired': true,
  },
  vis_delibera:{
    'isRequired': true,
  },
  check_ccfp:{
    '_custom':{
      'check':(code,rule,data)=>{
        return data.pFormativo2016List && data.pFormativo2016List.length>0;
      }
    }
  },
  'regex': [
    {
      regex: /previCostiAttFormativaList\.[0-9]+\.tot_cfp/,
      rules: {
        'isRequired': true,
        'isNumber': true
      }
    },
    {
      regex: /previCostiAttFormativaList\.[0-9]+\.nr_pratecip_prev/,
      rules: {
        'isRequired': true,
        'isNumber': true
      }
    },
    {
      regex: /pFormativo2016List\.[0-9]+\.tot_cfp/,
      rules: {
        'isRequired': true,
        'isNumber': true
      }
    },


  ]
}