import { Form, PageSection, Title } from '@patternfly/react-core'
import React from 'react'
import {
  PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA,
  PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA
} from 'src/app/utils/RoutesConstants'
import GenericCard from '../../components/GenericCard'
import { GenericButton, GenericSpinner, GenericTextInput } from '../../components'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { convertUrlParamsToObject } from 'src/utilities/utility'
import { getDefaultAxiosConfig, getTrasferimentoOrdineResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const STATI = {
  2: 'INVIATA',
  3: 'VALUTAZIONE',
  4: 'CHIUSA',
  5: 'RESPINTA',
}

class VisualizzaRichTrasferimentoOrdine extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      idTrasferimentoOrdine: undefined,
      message: '',
      typeMessage: 'default',
    }
  }

  async componentDidMount() {
    //recupero gli eventuali parametri dall'url
    var url = window.location.search?.split('?')[1]
    var urlParams = convertUrlParamsToObject(url)

    //recupero l'idTrasferimentoOrdine dall'url
    var idTrasferimentoOrdine = urlParams?.idTrasferimentoOrdine

    this.setState({ isLoaded: false, idTrasferimentoOrdine: idTrasferimentoOrdine })

    const param = {
      idTrasferimentoOrdine: idTrasferimentoOrdine,
    }

    await getTrasferimentoOrdineResourceApi()
      .mswfTrasferimentoOrdineFindByIdTrasferimentoPost(param, getDefaultAxiosConfig())
      .then(async (response) => {
        if (response && response?.data && response?.data?.httpStatus !== 200) throw new Error(response.data?.error)
        else {
          this.setState({ datiTrasfOrdine: response?.data?.returnedObject })
        }
      })
      .catch((err) => {
        setTimeout(() => {
          this.setState({ message: "C'è stato un errore durante il recupero dei dati: " + err, typeMessage: 'danger' })
        }, 1000)
      })

    this.setState({ isLoaded: true })
  }

  goToListaRichTrasferimento = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    })
  }

  render() {
    const { isLoaded, datiTrasfOrdine, typeMessage, message } = this.state

    return isLoaded ? (
      <PageSection>
        <div className="container register-container">
          <GenericBreadCrumb
            paths={[
              { label: 'Anagrafica', link: '/' },
              { label: 'Lista richieste di trasferimento ordine', link: PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA },
              { label: 'Visualizza', link: PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA },
            ]}
          />
          <br></br>
          <Title headingLevel="h1" size="4xl">
            Visualizza richiesta di trasferimento ordine
          </Title>
          <GenericAlert color={typeMessage} label={message} hidden={message === ''}></GenericAlert>
          <GenericCard
            header={'Dati trasferimento ordine'}
            body={
              <Form>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <h2>
                      <b>Dati anagrafici</b>
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="nome"
                      type="text"
                      label="Nome"
                      defaultValue={datiTrasfOrdine?.nome ? datiTrasfOrdine.nome : '-'}
                      isReadOnly
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="cognome"
                      type="text"
                      label="Cognome"
                      defaultValue={datiTrasfOrdine?.cognome ? datiTrasfOrdine.cognome : '-'}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      id="cf"
                      type="text"
                      label="Codice fiscale"
                      defaultValue={datiTrasfOrdine?.codFis ? datiTrasfOrdine.codFis : '-'}
                      isReadOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <h2>
                      <b>Dati residenziali e contatti</b>
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="provinciaResidenza"
                      type="text"
                      label="Provincia di residenza"
                      defaultValue={datiTrasfOrdine?.provinciaResidenza ? datiTrasfOrdine.provinciaResidenza : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="comuneResidenza"
                      type="text"
                      label="Comune di residenza"
                      defaultValue={datiTrasfOrdine?.comuneResidenza ? datiTrasfOrdine.comuneResidenza : '-'}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="indirizzoResidenza"
                      type="text"
                      label="Indirizzo di residenza"
                      defaultValue={datiTrasfOrdine?.indirizzoResidenza ? datiTrasfOrdine.indirizzoResidenza : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="civicoResidenza"
                      type="text"
                      label="Civico"
                      defaultValue={datiTrasfOrdine?.civicoResidenza ? datiTrasfOrdine.civicoResidenza : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-2'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="capResidenza"
                      type="text"
                      label="CAP"
                      defaultValue={datiTrasfOrdine?.capResidenza ? datiTrasfOrdine.capResidenza : '-'}
                    />
                  </div>
                </div>
                <div className="row" hidden={datiTrasfOrdine?.stessaSede}>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <h2>
                      <b>Dati sede legale studio</b>
                    </h2>
                  </div>
                </div>
                <div className="row" hidden={datiTrasfOrdine?.stessaSede}>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="provinciaSl"
                      type="text"
                      label="Provincia sede legale"
                      defaultValue={datiTrasfOrdine?.provinciaSL ? datiTrasfOrdine.provinciaSL : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="comuneSl"
                      type="text"
                      label="Comune sede legale"
                      defaultValue={datiTrasfOrdine?.comuneSL ? datiTrasfOrdine.comuneSL : '-'}
                    />
                  </div>
                </div>
                <div className="row" hidden={datiTrasfOrdine?.stessaSede}>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="indirizzoSl"
                      type="text"
                      label="Indirizzo sede legale"
                      defaultValue={datiTrasfOrdine?.indirizzoSL ? datiTrasfOrdine.indirizzoSL : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="civicoSl"
                      type="text"
                      label="Civico"
                      defaultValue={datiTrasfOrdine?.civicoSL ? datiTrasfOrdine.civicoSL : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-2'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="capSl"
                      type="text"
                      label="CAP"
                      defaultValue={datiTrasfOrdine?.capSL ? datiTrasfOrdine.capSL : '-'}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <h2>
                      <b>Dati richiesta trasferimento ordine</b>
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="stato"
                      type="text"
                      label="Stato richiesta"
                      defaultValue={datiTrasfOrdine?.stato ? STATI[datiTrasfOrdine.stato] : '-'}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="motivo"
                      type="text"
                      label="Motivo trasferimento ordine"
                      defaultValue={datiTrasfOrdine?.motivoRichiestaTrasferimento ? datiTrasfOrdine.motivoRichiestaTrasferimento : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="data"
                      type="date"
                      label="Data richiesta trasferimento ordine"
                      defaultValue={datiTrasfOrdine?.dataTrasferimentoOrdine ? datiTrasfOrdine.dataTrasferimentoOrdine : null}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="Da"
                      type="text"
                      label="Ordine di origine"
                      defaultValue={datiTrasfOrdine?.trasferimentoOrdineDa ? datiTrasfOrdine.trasferimentoOrdineDa : '-'}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericTextInput
                      className="w-75"
                      isReadOnly
                      id="A"
                      type="text"
                      label="Ordine richiesto"
                      defaultValue={datiTrasfOrdine?.trasferimentoOrdineA ? datiTrasfOrdine.trasferimentoOrdineA : '-'}
                    />
                  </div>
                </div>
              </Form>
            }
          ></GenericCard>
          <div className="row">
            <div className="col-1">
              <GenericButton label={'INDIETRO'} color={'secondary'} onClick={() => this.goToListaRichTrasferimento(PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA)} />
            </div>
          </div>
          <br></br>
        </div>
      </PageSection>
    ) : (
      <GenericSpinner></GenericSpinner>
    )
  }
}

export default VisualizzaRichTrasferimentoOrdine
