import { Form, FormGroup, Label } from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getClassiDiLaurea, getTipiDiLaurea, getTitoli } from 'src/processes/Laurea'
import { getOrdiniBySigla } from 'src/processes/Ordini'
import { getFilesByIdIscrizioneFo } from 'src/processes/ProcessoIscrizione'
import { getSiglaProvinciaByNome } from 'src/processes/Province'
import { getSezioni } from 'src/processes/Sezioni'
import GenericCard from '../../../components/GenericCard'
import GenericCheckbox from '../../../components/GenericCheckbox'
import GenericDataPicker from '../../../components/GenericDatePicker'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import GenericUploadFront from '../../../components/GenericUpload'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const statoCompilazione = 1
const statoRichiestaIntegrazioni = 3
const statoRigettato = 6

export default class AccessoProfessione extends React.Component<AccessoProfessioneProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      visualizzaFlag: (props.visualizzaFlag) ? props.visualizzaFlag : false,
      fields: (props.prevData && props.prevClicked) ? props.prevData[0] : props?.data,
      listaOrdini: [],
      listaTitoli: [],
      tipoLaureaSelected: true,
      flagSvuotaOrdine: false
    };
  }

  getOrdiniFunction = async () => {
    /*************************** GET Lista ordini territoriali *********************************/

    let flagSedeLegale = 0;
    let provinciaSedeLegale: any;
    let provinciaResidenza: any;

    if (this.props.data.sedeLegaleStudio && this.props.data.sedeLegaleStudio.label === 'Si') flagSedeLegale = 1;

    /** Chiamata al servizio per ottenere la sigla della provincia */
    if (this.props.data.provinciaResidenza && this.props.data?.provinciaResidenza?.label !== 'Stato Estero') {
        await getSiglaProvinciaByNome(this.props.data.provinciaResidenza.label).then(response => {
          provinciaResidenza = response.data.siglaAutomobilistica
        })
    } else {
      provinciaResidenza = 'EEP'
    }

    if (this.props.data.provinciaStudio &&
      this.props.data?.provinciaStudio?.label !== 'Stato Estero' &&
      this.props.data?.provinciaStudio?.label !== null) {
      await getSiglaProvinciaByNome(this.props.data.provinciaStudio.label).then(response => {
        provinciaSedeLegale = response.data.siglaAutomobilistica
      })
    } else {
      provinciaSedeLegale = 'EEP'
    }

    let tipoSoggetto = "PF";  /** Persona fisica */
    if ((provinciaSedeLegale !== 'EEP' && provinciaSedeLegale !== '' && provinciaSedeLegale !== undefined)
      || (provinciaResidenza !== 'EEP' && provinciaResidenza !== '' && provinciaResidenza !== undefined)) {
      getOrdiniBySigla(flagSedeLegale, provinciaSedeLegale, provinciaResidenza, tipoSoggetto).then((response: any) => {
        var ordiniTerritoriali = [{ label: '', value: '' }];
        if (response) response.data.map((ordine, id) => {

          ordiniTerritoriali[id] = {
            label: ordine['denominazioneGruppo'],
            value: ordine['codiceProvinciaResidenza'],
          };
        })
        this.setState({ listaOrdini: ordiniTerritoriali, flagSvuotaOrdine: false })
      })
    }
  }

  componentWillReceiveProps(props) {
    if(props.flagSvuotaOrdine === true){
      if(props.statoIscrizione === statoCompilazione)
        this.state.fields["idOrdine"] = undefined

      this.setState({flagSvuotaOrdine: true})
      this.getOrdiniFunction()
      this.props.getFlagSvuotaOrdine(false)
    }
  }

  async componentDidMount(): Promise<void> {
    this.getOrdiniFunction()
    this.setState({ isLoaded: false })
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (!this.props.visualizzaFlag) {
      if (this.props.data) {
        let data = this.props.data;
        this.setState({ fields: data })
      }
    }
    else if ((this.props.visualizzaFlag) && this.props.data) {
      var filesIscrizione = await getFilesByIdIscrizioneFo(this.state.fields['idIscrizione'])
      if (filesIscrizione?.status === 200) {
        if (filesIscrizione?.data[0]?.idAttachment && this.state.fields.uploadFiles) {
          this.state.fields.uploadFiles["idAttachment"] = filesIscrizione?.data[0]?.idAttachment
        }
        await this.setState({ filesIscrizione: filesIscrizione.data })
      }
      if (this.props.data)
        this.setState({ fields: this.props.data })
    }

    var sezioniResponse
    var titoliResponse
    var tipiDiLaureaResponse
    var classiDiLaureaResponse

    /*************************** GET Lista sezioni *********************************/
    sezioniResponse = await getSezioni()
    var sezioni = [{ label: '', value: '', codice: 0 }];
    if (sezioniResponse && sezioniResponse.status === 200) sezioniResponse.data.map((tipo, id) => {
      sezioni[id] = {
        label: tipo['descrizione'],
        value: tipo['idSezione'],
        codice: tipo['codiceSezione'],
      };
    })
    this.setState({ listaSezioni: sezioni })

    /*************************** GET Lista titoli *********************************/
    titoliResponse = await getTitoli()
    var titoliLaurea: any = [{ label: '', value: '', sezione: '', id: 0 }];

    if (titoliResponse && titoliResponse.status === 200) titoliResponse.data.map((titolo, id) => {
      titoliLaurea[id] = {
        label: titolo['titoloEsteso'],
        value: titolo['idTitolo'],
        sezione: titolo['sezione'],
        id: titolo['idTitolo']
      };
    })
    this.setState({ listaTitoliGrezza: titoliLaurea })

    /*************************** GET Lista tipi di laurea *********************************/
    tipiDiLaureaResponse = await getTipiDiLaurea()
    var tipiLaurea = [{ label: '', value: '', sezione: '' }];
    if (tipiDiLaureaResponse && tipiDiLaureaResponse.status === 200) tipiDiLaureaResponse.data.map((tipo, id) => {
      tipiLaurea[id] = {
        label: tipo['tipoLaurea'],
        value: tipo['idTipoLaurea'],
        sezione: tipo['sezione']
      };
    })
    this.setState({ listaTipoDiLaureaGrezza: tipiLaurea })

    /*************************** GET Lista classi di laurea *********************************/
    classiDiLaureaResponse = await getClassiDiLaurea()
    var classiLaurea = [{ label: '', value: '', sezioneARichiedibile: '', sezioneBRichiedibile: '', tipoLaurea: '', diplomaVO: '', dm270: '', dm509: '' }];
    if (classiDiLaureaResponse && classiDiLaureaResponse.status === 200) classiDiLaureaResponse.data.map((classe, id) => {
      classiLaurea[id] = {
        label: classe['descrizione'],
        value: classe['idLaurea'],
        sezioneARichiedibile: classe['sezioneARichiedibile'],
        sezioneBRichiedibile: classe['sezioneBRichiedibile'],
        tipoLaurea: classe['tipologiaLaurea'],
        diplomaVO: classe['diplomaLaureaVo'],
        dm270: classe['dm2702004'],
        dm509: classe['dm5091999'],
      };

    })

    this.setState({ listaClassiLaureaGrezza: classiLaurea.filter(classe => (classe.sezioneARichiedibile !== '0' && classe.sezioneBRichiedibile !== '0')) });
    this.setListe(titoliLaurea, classiLaurea, tipiLaurea)

    this.setState({ isLoaded: true })
  }

  setListe = async (titoliLaurea, classiLaurea, tipiLaurea) => {
    /** Filtraggio della lista dei titoli in base alla sezione selezionata */
    this.setState({ listaTitoli: titoliLaurea?.filter(titolo => titolo.sezione === this.state.fields['sezioneRichiesta']?.codice) });
    /** Filtraggio della lista dei tipi di laurea in base alla sezione selezionata */

    /** In base alla sezione selezionata vengono filtrate le tipologie di abilitazione e le classi di laurea*/
    switch (this.state.fields['sezioneRichiesta']?.codice) {
      case 'A': {
        this.setState({ listaTipoDiLaurea: tipiLaurea?.filter(tipo => tipo.sezione === this.state.fields['sezioneRichiesta']?.codice) });
        await this.setState({ listaClassiLaurea: classiLaurea?.filter(classe => classe.sezioneARichiedibile === 1) });
        await this.setState({ listaAbilitazioni: titoliLaurea })
        if (this.state.fields['titoloRichiesto'] && this.state.fields['tipoLaurea']) {
          /** Filtraggio della lista delle classi di laurea in base al tipo di laurea selezionato */
          await this.setState({ listaClassiLaurea: classiLaurea?.filter(classe => classe.tipoLaurea === this.state.fields['tipoLaurea'].value) });
        }
        break;
      }
      case 'B': {
        this.setState({ listaTipoDiLaurea: tipiLaurea });
        await this.setState({ listaClassiLaureaGrezza: classiLaurea?.filter(classe => classe.sezioneBRichiedibile === 1) });
        await this.setState({ listaAbilitazioni: titoliLaurea })

        if (this.state.fields['titoloRichiesto']) {

            /** Filtraggio della lista delle classi di laurea in base al tipo di laurea selezionato */
            await this.setState({ listaClassiLaurea: classiLaurea?.filter(classe => classe.tipoLaurea === this.state.fields['tipoLaurea'].value) });
            if (this.state.fields['titoloRichiesto']?.label === 'Biotecnologo agrario') {
              await this.setState({listaTipoDiLaurea : tipiLaurea.filter(tipoLaurea => tipoLaurea.label.includes('Laurea (L)'))});
              await this.setState({ listaClassiLaurea: classiLaurea.filter(classe => (classe.label.search("Biotecnologie") !== -1)) });
            }
            else if (this.state.fields['tipoLaurea']?.value === 4) {
              this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm509 === 1 && classe.diplomaVO === 0) });
            } else if (this.state.fields['tipoLaurea']?.value === 5) {
              this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm270 === 1 && classe.diplomaVO === 0) });
            } else if (this.state.fields['tipoLaurea']?.value === 2) {
              this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm509 === 1 && classe.diplomaVO === 0) });
            } else if (this.state.fields['tipoLaurea']?.value === 3) {
              this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm270 === 1 && classe.diplomaVO === 0) });
            }
        }
        break;
      }
    }
  }

  render() {

    const { tabNumber } = this.props;
    const { fields } = this.state;

    const getDatiAllegato = (fileData) => {
      fields["uploadFiles"]?.push(fileData);
      fields["uploadFiles"]?.map((e, id) => {
        if((e.tipologiaAllegato === fileData.tipologiaAllegato || e.codiceTipoAllegato === fileData.tipologiaAllegato)){
          if(e.idAttachment && !e.cancellato){
            e["cancellato"] = true
          }else if(e.idAttachment && e.cancellato){
            if(id < fields["uploadFiles"].length-1){
              e["cancellato"] = true
            }
          }else if(!e.idAttachment && id < fields["uploadFiles"].length-1){ //Se id attachment non presente ma non è cancellato
            e["cancellato"] = true
          }
        }else{
          return e
        }
      })
      this.props.getSelectedValue(fields)
    }

    const setDataItem = async (e: any, type: any, field: any) => {
      if (field === 'sezioneRichiesta') {
        this.getOrdiniFunction();
        this.state.fields['tipoLaurea'] = this.state.fields['titoloRichiesto'] = null;
      }
      if (field === "tipoLaurea") {
        await this.setState({ tipoLaureaSelected: false })

        if (this.state.tipoLaureaSelected === false) {
          this.setState({ tipoLaureaSelected: true })
          this.state.fields.classeLaurea = undefined
        }
      }
      let fields = this.state.fields;
      if (type === 'select') {

        if (field === 'idOrdine') {

          fields[field] = { label: e.label, value: e.value };
        }
        else if (field === 'sezioneRichiesta') {
          fields[field] = { label: e.label, value: e.value, codice: e.codice };
        }
        else if (field === 'titoloRichiesto') {
          fields[field] = { label: e.label, value: e.value, sezione: e.sezione };
        }
        else fields[field] = { label: e.label, value: e.value };
      }
      else if (type === 'checkbox') {
        fields[field] = e.target.checked;
        if (field === 'decretoRiconoscimentoTitoloEstero') {
          this.setState({ decretoRiconoscimentoTitoloEstero: e.target.checked })
        }
      }
      else {
        fields[field] = e.target.value;
      }

      this.setState({ fields });
      if (this.state.fields['sezioneRichiesta']) {
        /** Filtraggio della lista dei titoli in base alla sezione selezionata */
        this.setState({ listaTitoli: this.state.listaTitoliGrezza?.filter(titolo => titolo.sezione === this.state.fields['sezioneRichiesta'].codice) });
        /** Filtraggio della lista dei tipi di laurea in base alla sezione selezionata */

        /** In base alla sezione selezionata vengono filtrate le tipologie di abilitazione e le classi di laurea*/
        switch (this.state.fields['sezioneRichiesta'].codice) {
          case 'A': {
            this.setState({ listaTipoDiLaurea: this.state.listaTipoDiLaureaGrezza.filter(tipo => tipo.sezione === this.state.fields['sezioneRichiesta'].codice) });
            await this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.sezioneARichiedibile === 1) });
            await this.setState({ listaAbilitazioni: this.state.listaTitoli })
            if (this.state.fields['titoloRichiesto'] && this.state.fields['tipoLaurea']) {
              /** Filtraggio della lista delle classi di laurea in base al tipo di laurea selezionato */
              await this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.tipoLaurea === this.state.fields['tipoLaurea'].value) });
            }
            break;
          }
          case 'B': {
            var listaTipoDiLaurea : any = []
            this.state.listaTipoDiLaureaGrezza.map((tipoLaurea) => {
              if(tipoLaurea.value === 1 || tipoLaurea.value === 2 || tipoLaurea.value === 3)
              {
                listaTipoDiLaurea.push(tipoLaurea)
              }
            })
            this.setState({ listaTipoDiLaurea });
            await this.setState({ listaClassiLaureaGrezza: this.state.listaClassiLaureaGrezza.filter(classe => classe.sezioneBRichiedibile === 1) });
            await this.setState({ listaAbilitazioni: this.state.listaTitoliGrezza })

            if (this.state.fields['titoloRichiesto']) {

                /** Filtraggio della lista delle classi di laurea in base al tipo di laurea selezionato */
                await this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.tipoLaurea === this.state.fields['tipoLaurea']?.value) });
                if (this.state.fields['titoloRichiesto'].label === 'Biotecnologo agrario') {
                  await this.setState({listaTipoDiLaurea : this.state.listaTipoDiLaureaGrezza.filter(tipoLaurea => tipoLaurea.label.includes('Laurea (L)'))});
                  await this.setState({ listaClassiLaurea: this.state.listaClassiLaurea.filter(classe => (classe.label.search("Biotecnologie") !== -1)) });
                }
                else if (this.state.fields['tipoLaurea']?.value === 4) {
                  this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm509 === 1 && classe.diplomaVO === 0) });
                } else if (this.state.fields['tipoLaurea']?.value === 5) {
                  this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm270 === 1 && classe.diplomaVO === 0) });
                } else if (this.state.fields['tipoLaurea']?.value === 2) {
                  this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm509 === 1 && classe.diplomaVO === 0) });
                } else if (this.state.fields['tipoLaurea']?.value === 3) {
                  this.setState({ listaClassiLaurea: this.state.listaClassiLaureaGrezza.filter(classe => classe.label.search("Biotecnologie") === -1 && classe.dm270 === 1 && classe.diplomaVO === 0) });
                }
            }
            break;
          }
        }
      }

      this.props.getSelectedValue(fields)

    }


    /********************************* Form Iscrizione ************************************/
    let isDecretoChecked = this.state.fields?.decretoRiconoscimentoTitoloEstero
    const fileName = this.state.fields?.uploadFiles?.filter(e => e.codiceTipoAllegato === "decreto_titolo_estero_iot")[0]?.filename
    const idAttachment = this.state.fields?.uploadFiles?.filter(e => e.codiceTipoAllegato === "decreto_titolo_estero_iot")[0]?.idAttachment
    const formIscrizione = [
      <GenericCard key={'accessoProfessioneIscrizione'} cardHeader="Dati di accesso alla professione" cardBody={
        <Form id="formIscrizione" className="row">
          <div>

            <div className="row">

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"sezioneRichiesta"} >
                  <GenericSelect label='Sezione *' elementId={"idSezioneRichiestaAccessoProfessione"} placeholder='Sezione A / Sezione B' defaultValue={(this.state.fields?.sezioneRichiesta && this.state.fields?.sezioneRichiesta.label !== null) ? this.state.fields?.sezioneRichiesta : null}
                    onChange={(e) => setDataItem(e, 'select', 'sezioneRichiesta')}
                    defaultOptions={this.state.listaSezioni} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>

            {this.state.flagSvuotaOrdine !== true ?
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={!this.state.fields['sezioneRichiesta']}>
                <FormGroup label={"idOrdine"}>
                  <GenericSelect elementId={"idOrdineTerritorialeAccessoProfessione"} label='Ordine territoriale *' defaultValue={(this.state.fields?.idOrdine && this.state.fields?.idOrdine?.label !== "") ? this.state.fields?.idOrdine : null} placeholder='Selezionare ordine' onChange={(e) => { setDataItem(e, 'select', 'idOrdine') }}
                    defaultOptions={this.state.listaOrdini} errorMessageAlert={this.props.message} isRequired={this.state.fields['sezioneRichiesta']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div> : null
             }

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"titoloRichiesto"} hidden={!this.state.fields['sezioneRichiesta']} >
                  <GenericSelect label='Titolo richiesto *' elementId={"idTitoloRichiestoAccessoProfessione"} placeholder='Selezionare titolo' defaultValue={(this.state.fields?.titoloRichiesto && this.state.fields?.titoloRichiesto.label !== null) ? this.state.fields['titoloRichiesto'] : null}
                    onChange={(e) => setDataItem(e, 'select', 'titoloRichiesto')}
                    defaultOptions={this.state.listaTitoli} errorMessageAlert={this.props.message} isRequired={this.state.fields['sezioneRichiesta']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>

            </div>

            <div className="row p-2">
              <GenericCheckbox id="decretoRiconoscimentoTitoloEstero" key={'decretoRiconoscimentoTitoloEstero'} defaultChecked={this.state.fields['decretoRiconoscimentoTitoloEstero']}
                onChange={(e) => setDataItem(e, 'checkbox', 'decretoRiconoscimentoTitoloEstero')} label={"Titolo Estero"}
                isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
            </div>

            <div className="row" hidden={isDecretoChecked}>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"tipoLaurea"} hidden={!this.state.fields['titoloRichiesto']}>
                  <GenericSelect label='Tipo di laurea *' elementId={"idTipoLaureaAccessoProfessione"} placeholder='Selezionare il tipo di laurea' defaultValue={(this.state.fields['tipoLaurea']) ? this.state.fields['tipoLaurea'] : null}
                    onChange={(e) => setDataItem(e, 'select', 'tipoLaurea')} defaultOptions={this.state.listaTipoDiLaurea} errorMessageAlert={this.props.message} isRequired={!isDecretoChecked && this.state.fields['titoloRichiesto']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>

              {this.state.tipoLaureaSelected ?
                <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <FormGroup label={"classeLaurea"} hidden={(this.state.fields['tipoLaurea'] && this.state.fields['tipoLaurea'].value === 6) || (!this.state.fields['tipoLaurea'])} >
                    <GenericSelect label='Classe di laurea *' elementId={"idClasseDilaureaAccessoProfessione"} placeholder='Selezionare la classe di laurea' defaultValue={(this.state.fields['classeLaurea']) ? this.state.fields['classeLaurea'] : null}
                      onChange={(e) => setDataItem(e, 'select', 'classeLaurea')} defaultOptions={(this.state.listaClassiLaurea) ? this.state.listaClassiLaurea : null} errorMessageAlert={this.props.message} isRequired={!isDecretoChecked && this.state.fields['tipoLaurea']} currentStep={tabNumber}
                      isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                  </FormGroup>
                </div> : null}

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"corsoLaurea"} hidden={!this.state.fields['tipoLaurea']}>
                  <GenericInput label='Corso di laurea *' placeholder='Inserire il corso di laurea' id={"idCorsoDiLaureaAccessoProfessione"}
                    defaultValue={this.state.fields?.corsoLaurea ? this.state.fields?.corsoLaurea : null}
                    onChange={(e) => setDataItem(e, 'text', 'corsoLaurea')} type='text' errorMessageAlert={this.props.message} isRequired={!isDecretoChecked && this.state.fields['tipoLaurea']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"annoLaurea"} hidden={!this.state.fields['tipoLaurea']}>
                  <GenericInput id={"idAnnoDiLaurea"} label='Anno di laurea *' placeholder="Inserire l'anno di laurea" defaultValue={(this.state.fields['annoLaurea']) ? this.state.fields['annoLaurea'] : null}
                    onChange={(e) => setDataItem(e, 'text', 'annoLaurea')} type='anno' min={1900} max={2100} errorMessageAlert={this.props.message} isRequired={!isDecretoChecked && this.state.fields['tipoLaurea']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"universita"} hidden={!this.state.fields['tipoLaurea']}>
                  <GenericInput id={"idUniversità"} label='Università *' placeholder="Inserire nome università" defaultValue={(this.state.fields['universita']) ? this.state.fields['universita'] : null}
                    onChange={(e) => setDataItem(e, 'text', 'universita')} type='text' errorMessageAlert={this.props.message} isRequired={!isDecretoChecked && this.state.fields['tipoLaurea']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>

            </div>

            <div className="row" hidden={!isDecretoChecked}>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <Label>Decreto riconoscimento Titolo Estero *</Label>
              </div>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"decretoTitolo"} >
                  <GenericUploadFront name={"Decreto riconoscimento titolo estero"} value={fileName} idAttachment={idAttachment} datiAllegato={e => getDatiAllegato(e)} tipologiaAllegato={"decreto_titolo_estero_iot"} acceptedExtensionFile={["pdf"]} key="decretoTitolo" id={"decretoTitolo"} isHidden={this.state.fields['decretoTitolo']} errorMessageAlert={this.props.message} isRequired={isDecretoChecked} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} hideResetFile={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato}
                  />
                </FormGroup>
              </div>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"data"}>
                  <GenericDataPicker id={"idDataRiconoscimentoTitolo"} label='Data riconoscimento titolo *' placeholder='Selezionare data' key="data"
                    onChange={(e) => setDataItem(e, 'text', 'dataRiconoscimentoTitolo')} defaultValue={(this.state.fields.dataRiconoscimentoTitolo) ? this.state.fields.dataRiconoscimentoTitolo : null} errorMessageAlert={this.props.message} isRequired={isDecretoChecked} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>

            </div>

            <div className="row" hidden={!this.state.fields['titoloRichiesto']}>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"tipologiaAbilitazione"} >
                  <GenericSelect elementId={"idTipologiaAbilitazione"} label='Tipologia abilitazione *' placeholder='Selezionare tipologia di abilitazione' defaultValue={(this.state.fields['tipologiaAbilitazione']) ? this.state.fields['tipologiaAbilitazione'] : null}
                    onChange={(e) => setDataItem(e, 'select', 'tipologiaAbilitazione')} defaultOptions={this.state.listaAbilitazioni} errorMessageAlert={this.props.message} isRequired={this.state.fields['titoloRichiesto']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"annoAbilitazione"} >
                  <GenericInput id={"idAnnoAbilitazione"} label='Anno abilitazione *' placeholder="Inserire l'anno di abilitazione" defaultValue={(this.state.fields['annoAbilitazione']) ? this.state.fields['annoAbilitazione'] : null}
                    onChange={(e) => setDataItem(e, 'text', 'annoAbilitazione')} type='anno' errorMessageAlert={this.props.message} isRequired={this.state.fields['titoloRichiesto']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>

            </div>

            <div className="row" hidden={!this.state.fields['tipologiaAbilitazione']}>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"ateneoAbilitazione"} >
                  <GenericInput id={"idAteneoAbilitazione"} label='Ateneo abilitazione *' placeholder="Inserire l'ateneo di abilitazione" defaultValue={(this.state.fields['ateneoAbilitazione']) ? this.state.fields['ateneoAbilitazione'] : null}
                    onChange={(e) => setDataItem(e, 'text', 'ateneoAbilitazione')} type='text' errorMessageAlert={this.props.message} isRequired={this.state.fields['tipologiaAbilitazione']} currentStep={tabNumber}
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row" hidden={!this.state.fields['tipologiaAbilitazione']}>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"decretoRiconoscimentoQualificaProfessionale"} >
                  <GenericInput id="idDecretoRiconoscimentoQualificaProfessionale" label='Decreto riconoscimento qualifica professionale' placeholder="Riconoscimento della qual. professionale" defaultValue={(this.state.fields['decretoRiconoscimentoQualificaProfessionale']) ? this.state.fields['decretoRiconoscimentoQualificaProfessionale'] : null}
                    onChange={(e) => setDataItem(e, 'text', 'decretoRiconoscimentoQualificaProfessionale')} type='text'
                    isDisabled={this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni}
                  />
                </FormGroup>
              </div>

              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <FormGroup label={"dataRiconoscimento"}>
                  <GenericDataPicker id={"idDataRiconoscimentoQualificaProfessionale"} label='Data riconoscimento qualifica professionale ' placeholder='Selezionare data' key="dataRiconoscimento"
                    onChange={(e) => setDataItem(e, 'text', 'dataRiconoscimento')} defaultValue={(this.state.fields['dataRiconoscimento']) ? this.state.fields['dataRiconoscimento'] : null}
                    isDisabled={this.state.cittadinanzaItaliana || this.props.statoIscrizione !== statoCompilazione && this.props.statoIscrizione !== statoRigettato && this.props.statoIscrizione !== statoRichiestaIntegrazioni} />
                </FormGroup>
              </div>
            </div>
          </div>
        </Form>
      }></GenericCard>

    ];

    /********************************* Form Visualizza ************************************/
    const formVisualizza = [
      <GenericCard key={'accessoProfessioneVisualizza'} cardHeader="Dati di accesso alla professione" cardBody={
        <Form id="formVisualizza">
          <div key='form-container'>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} id="idOrdine" name={'idOrdine'} label='Ordine territoriale' placeholder={' '}
                  defaultValue={(this.state.fields['idOrdine'] && this.state.fields['idOrdine'].label) ? this.state.fields['idOrdine'].label : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} id="sezioneRichiesta" name={'sezioneRichiesta'} label='Sezione' placeholder={' '}
                  defaultValue={(this.state.fields['sezioneRichiesta'] && this.state.fields['sezioneRichiesta'].label) ? this.state.fields['sezioneRichiesta'].label : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} id="titoloRichiesto" name={'titoloRichiesto'} label='Titolo richiesto' placeholder={' '}
                  defaultValue={(this.state.fields['titoloRichiesto'] && this.state.fields['titoloRichiesto'].label) ? this.state.fields['titoloRichiesto'].label : '-'} isDisabled />
              </div>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={this.state.fields['flagAbilitazioneConDecretoEstero'] && this.state.fields['flagAbilitazioneConDecretoEstero'].label !== 'No'}>
                <GenericInput type={'text'} id="tipoLaurea" name={'tipoLaurea'} label='Tipo di laurea' placeholder={' '}
                  defaultValue={(this.state.fields['tipoLaurea'] && this.state.fields['tipoLaurea'].label) ? (this.state.fields['tipoLaurea'].label) : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={this.state.fields['flagAbilitazioneConDecretoEstero'] && this.state.fields['flagAbilitazioneConDecretoEstero'].label !== 'No'}>
                <GenericInput type={'text'} id="classeLaurea" name={'classeLaurea'} label='Classe di laurea' placeholder={' '}
                  defaultValue={(this.state.fields['classeLaurea'] && this.state.fields['classeLaurea'].label) ? (this.state.fields['classeLaurea'].label) : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={this.state.fields['flagAbilitazioneConDecretoEstero'] && this.state.fields['flagAbilitazioneConDecretoEstero'].label !== 'No'}>
                <GenericInput type={'text'} id="corsoLaurea" name={'corsoLaurea'} label='Corso di laurea' placeholder={' '}
                  defaultValue={(this.state.fields['corsoLaurea']) ? this.state.fields['corsoLaurea'] : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={this.state.fields['flagAbilitazioneConDecretoEstero'] && this.state.fields['flagAbilitazioneConDecretoEstero'].label !== 'No'}>
                <GenericInput type={'text'} id="annoLaurea" name={'annoLaurea'} label='Anno di laurea' placeholder={' '}
                  defaultValue={(this.state.fields['annoLaurea']) ? this.state.fields['annoLaurea'] : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={this.state.fields['flagAbilitazioneConDecretoEstero'] && this.state.fields['flagAbilitazioneConDecretoEstero'].label !== 'No'}>
                <GenericInput type={'text'} id="universita" name={'universita'} label='Università' placeholder={' '}
                  defaultValue={(this.state.fields['universita']) ? this.state.fields['universita'] : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={this.state.fields['flagAbilitazioneConDecretoEstero'] && this.state.fields['flagAbilitazioneConDecretoEstero'].label !== 'Si'}>
                <GenericInput type={'date'} id="data" name={'data'} label='Data riconoscimento titolo (in alternativa ai dati sulla laurea)' placeholder={' '}
                  defaultValue={(this.state.fields['dataRiconoscimentoTitolo']) ? this.state.fields['dataRiconoscimentoTitolo'] : '-'} isDisabled />
              </div>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} id="tipologiaAbilitazione" name={'tipologiaAbilitazione'} label='Tipologia abilitazione' placeholder={' '}
                  defaultValue={(this.state.fields['tipologiaAbilitazione']) ? (this.state.fields['tipologiaAbilitazione'].label) : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} id="annoAbilitazione" name={'annoAbilitazione'} label='Anno abilitazione' placeholder={' '}
                  defaultValue={(this.state.fields['annoAbilitazione']) ? (this.state.fields['annoAbilitazione']) : '-'} isDisabled />
              </div>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} id="ateneoAbilitazione" name={'ateneoAbilitazione'} label='Ateneo abilitazione' placeholder={' '}
                  defaultValue={(this.state.fields['ateneoAbilitazione']) ? (this.state.fields['ateneoAbilitazione']) : '-'} isDisabled />
              </div>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} id="decretoRiconoscimentoQualificaProfessionale" name={'decretoRiconoscimentoQualificaProfessionale'} label='Decreto riconoscimento qualifica professionale' placeholder={' '}
                  defaultValue={(this.state.fields['decretoRiconoscimentoQualificaProfessionale']) ? (this.state.fields['decretoRiconoscimentoQualificaProfessionale']) : '-'} isDisabled />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'date'} id="dataRiconoscimento" name={'dataRiconoscimento'} label='Data riconoscimento qualifica professionale ' placeholder={' '}
                  defaultValue={(this.state.fields['dataRiconoscimento']) ? (this.state.fields['dataRiconoscimento']) : '-'} isDisabled />
              </div>
            </div>
          </div>

        </Form>
      }></GenericCard>
    ]


    return (
      <>{(this.state.isLoaded) ? (this.props.visualizzaFlag) ? (formVisualizza) : (formIscrizione) : <GenericSpinner />}</>
    )
  }
}

export declare interface AccessoProfessioneProps {
  activeTab?: any;
  tabClick?: any;
  data?: any;
  prevData?: any;
  trueItaly?: any;
  prevClicked?: any
  processoIscrizione?: any;
  visualizzaFlag?: any;
  message?: any;
  getSelectedValue?: any;
  tabNumber?: number;
  statoIscrizione?: number;
  flagSvuotaOrdine?: boolean;
  getFlagSvuotaOrdine?: any;
}
