import * as React from 'react'
import { PageSection } from '@patternfly/react-core'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTitle from '../../components/GenericTitle'
import GenericAlert from '../../components/GenericAlert'
import { PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE } from 'src/app/utils/RoutesConstants'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getDefaultAxiosConfig, getTrasferimentoOrdineResourceApi } from 'src/app/utils/ManagerRestGateway'
import { FormRichiestaTrasfOrdine } from './formTrasferimentoOrdine/FormRichiestaTrasfOrdine'
import { GenericSpinner } from 'src/backoffice/app/components'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const title = 'Trasferimento ordine'

var breadcrumbOptions = [
    { label: "Anagrafica", link: '#' },
    { label: "Trasferimento ordine", link: PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE }
]

const legenda = ([
    { title: 'Legenda', Size: 'h2' },
    { title: '* Campo obbligatorio', Size: 'p' },
])

const listLegenda =
    [
        <div key={'legenda'} className="Card">
            {legenda.map((title, i, Size) => {
                return (<GenericTitle key={i} title={legenda[i].title} Size={legenda[i].Size} />)
            })}
        </div>
    ]
/* -------------------------------------------------------------------------------------------------- */

class RichiestaTrasferimentoOrdine extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default",
            isLoaded: false,
            errorNuova: false,
            inCorso: false
        };
    }

    async componentDidMount() {
        this.setState({ errorNuova: false, inCorso: false, isLoaded: false })

        const param: any = {
            "codiceFiscale": TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber
        }

        await getTrasferimentoOrdineResourceApi().mswfTrasferimentoOrdineNuovaPost(param, getDefaultAxiosConfig()).then((response) => {
            if (response && response.data && response.data.httpStatus === 200) {
                this.setState({ idSoggetto: response.data?.returnedObject?.idSoggetto, idIscrizione: response.data?.returnedObject?.idIscrizione, datiResidenza: response.data?.returnedObject?.soggettoCompilazioneTrasferimentoOrdineDTO, datiSedeLegale: response.data?.returnedObject?.sedeLegaleTrasferimentoOrdineDTO, flagSedeLegaleDiversaDaResidenza: response?.data?.returnedObject?.flagSedeLegaleDiversoDaProvinciaResidenza })
            }

            else if (response && response.data && response.data?.httpStatus === 409) {
                return this.setState({ inCorso: true })
            }

            else {
                this.setState({ errorNuova: true })
                throw new Error(response.data?.error)
            }
        }).catch((e) => {
            this.setState({ message: "C'è stato un problema durante il recupero dei dati: " + e, typeMessage: "danger" })
        })

        this.setState({ isLoaded: true })
    }

    submitMessageResult = (alert: any) => {
        const element = document.getElementById("pageSectionId");
        if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        this.setState({ message: alert.messaggio, typeMessage: alert.type })
        if (alert.type === 'success') {
            setTimeout(async () => { window.location.reload() }, 1000);
        }
    }

    render() {
        const { typeMessage, message, datiResidenza, datiSedeLegale, flagSedeLegaleDiversaDaResidenza, isLoaded, idSoggetto, idIscrizione, inCorso, errorNuova } = this.state
        if (inCorso) {
            return (
                <PageSection>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={[
                            { label: "Anagrafica", link: "/" },
                            { label: "Trasferimento ordine", link: PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE },
                        ]} />
                        <h1>Trasferimento ordine</h1>
                        <h2>Richiesta in attesa di approvazione</h2>
                    </div>
                </PageSection>
            )
        }
        else {
            if (isLoaded) {
                if (errorNuova === true) {
                    return (
                        <PageSection>
                            <div className='container register-container'>
                                <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                                <div><h1>{title}</h1></div>
                                <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                            </div>
                        </PageSection>
                    )
                }
                else {
                    return (
                        <>
                            <PageSection id='pageSectionId'>
                                <div className='container register-container'>
                                    <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                                    <div><h1>{title}</h1></div>
                                    <>
                                        <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                                        <GenericCard cardHeader={'Compila i seguenti campi'} cardBody={<FormRichiestaTrasfOrdine idSoggetto={idSoggetto} idIscrizione={idIscrizione} datiResidenza={datiResidenza} datiSedeLegale={datiSedeLegale} flagSedeLegaleDiversaDaResidenza={flagSedeLegaleDiversaDaResidenza} messaggio={this.submitMessageResult} />} /><br></br>
                                        <div className='row'>
                                            <div className='col-1'>
                                                <GenericButton form={"idRichTrasfOrdine"} type={"submit"} label={"Invia"} color={"primary"} isDisabled={typeMessage === 'success'} />
                                            </div>
                                        </div>
                                        <div>{listLegenda}</div>
                                    </>
                                </div>
                            </PageSection>
                        </>
                    );
                }
            }
            else {
                return (
                    <GenericSpinner />
                )
            }

        }
    }
};

export default RichiestaTrasferimentoOrdine;
