import React, { useEffect, useMemo, useState } from 'react'
import { Form, Icon, Label } from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericUpload from 'src/frontend/app/components/Form/Upload'
import GenericInput from 'src/frontend/app/components/Form/Input'
import styled from 'styled-components'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import { AppCostants } from 'src/app/utils/AppConstant'
import { agenziaToModuloMapper, ottieniAllegato } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import { getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'

const UploadRowStyled = styled.div`
  display: flex;
  margin: 50px 0;
  .uploader-label {
    flex: 3;
  }
  .uploader-files {
    flex: 5;
  }
`
const FormDatiAllegati: React.FunctionComponent<FormDatiAllegatiProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const [scelta, setScelta] = useState<any>(
    viewOnly ? 
      (data && data.datiAllegati && data.datiAllegati.tipoAllegato ? 
        (data.datiAllegati.tipoAllegato === 'PDF' ? 
          { label: 'PDF', value: 0 } : 
          { label: 'url', value: 1 }
        ) :
        {}
      ) :
      {}
  );
  const objKey = 'datiAllegati'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])
  const [dato, setDato] = useState()
  const [guidModuloAgenziaPrecompilato, setGuidModuloAgenziaPrecompilato] = useState('')

  const fromData = data?.datiAllegati?.tipoAllegato
  useEffect(() => {
    if (!viewOnly) {
      setScelta({
        label: fromData,
        value: fromData === 'PDF' ? 0 : 1,
      })
    }
  }, [viewOnly, fromData])

  const generaModuloPrecompilato = async (nomefile, url, tipologia) => {
    const _data: any = {
      fileUrl: url,
      type: tipologia,
      keyValuePairs: agenziaToModuloMapper(data, nomefile),
    }

    getFormazioneResourceApi()
      .msfoFormazioneGeneraModuloAgenziaPrecompilatoPost(_data)
      .then((res) => {
        ottieniAllegato(res.data, nomefile)
        setGuidModuloAgenziaPrecompilato(res.data)
      })
  }

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <GenericSelect
          isDisabled={viewOnly}
          id="scelta"
          label="Tipo di allegato*"
          value={scelta}
          defaultValue="---"
          defaultOptions={[
            {
              label: 'PDF',
              value: 0,
            },
            {
              label: 'url',
              value: 1,
            },
          ]}
          onChange={(a, s) => {
            handleInput('datiAllegati.tipoAllegato', s.label)
            handleInput('datiAllegati.urlAllegato', '')
            setScelta(s)
          }}
        />
        {scelta && scelta?.value === 1 && (
          <WrpInput as={GenericInput} id="urlAllegato" type="text" label="Url dell'ultimo numero della rivista" suffix="*" data={data} errors={errors} />
        )}
        <UploadRowStyled>
          <div className="uploader-label">
            <Label for={'datiAllegati.moduloAccreditamento'}>
              <strong>Modulo accreditamento *</strong>
            </Label>
          </div>
          <div className="uploader-files">
            <GenericUpload
              isMulti={false}
              id={'moduloAccreditamento'}
              tipologiaAllegato={'modulo_accreditamento_rivista'}
              extensions={['pdf']}
              accept={['application/pdf']}
              data={data.datiAllegati}
              isDisabled={viewOnly}
              onInit={onInitInput}
              onChange={(cod, val) => {
                handleInput('datiAllegati.' + cod, val)
              }}
            />
          </div>
          <GenericInput type={'hidden'} id={'check_uploads_moduloAccreditamento'} onInit={onInitInput} />
        </UploadRowStyled>
        
        <div className="row p-2 ms-4" id="lista-moduli">
          <Label>Moduli da compilare</Label>
          <div className="col-4 mt-3">
            <a href={`#lista-moduli`} onClick={() => generaModuloPrecompilato('Mod_20RIVISTE.pdf', `${AppCostants.PUBLIC_SHARE}/formazione/Mod_20RIVISTE.pdf`, 'modulo_accreditamento_rivista')}>
              <Icon icon="it-file" /> Modulo accreditamento rivista
            </a>
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiAllegatiProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiAllegati
