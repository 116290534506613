import { Form, Nav, NavLink, TabContent, TabPane } from 'design-react-kit'
import React from 'react'
import { Link } from 'react-router-dom'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getHome } from 'src/utilities/utility'
import GenericAlert from './GenericAlert'
import GenericButton from './GenericButton'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();
class GenericTab extends React.Component<GeneralTabProps, any>{
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.activeTab,
            listNavLink: props.listNavLink,
            listBodyTab: props.listBodyTab,
            tabClick: props.tabClick,
            onClick: props.onClick,
            listLength: props.listNavLink.length,
        };
    }

    render() {
        const { listNavLink } = this.state;
        return (
            <>
                <div className="row">
                    <div className={!isMobileDivice ? "col-2" : "col"} hidden={this.props.hidden}>
                        <Nav className="nav nav-tabs nav-tabs-vertical" id="nav-vertical-tab-ico" role="tablist" aria-orientation="vertical">
                            {listNavLink.map((item) => {
                                return (
                                    <NavLink disabled={this.props.activeTab !== item.id && !this.props.isClickable}
                                        className="nav-link" id={"linkId" + item.id}
                                        onClick={(e) => { if (this.props.isClickable) this.props.tabClick(item.id) }}
                                        active={this.props.activeTab === item.id} data-bs-toggle="tab"
                                        key={item.id}
                                        aria-controls={"#contentTab" + item.id}>
                                        <span hidden={this.props.hideNumbers}>{(item.id)}</span> {item.label}
                                    </NavLink>
                                )
                            })}
                        </Nav>
                    </div>

                    <div className={!isMobileDivice ? "col-10" : "col-12"}>
                        <Form onSubmit={this.props.onSubmit} ref={this.props.formRef}>

                            <TabContent className="tab-content mb-3" id={this.props.id}>
                                <GenericAlert id={"pageSectionId"} hidden={this.props.hiddenAlert} label={this.props.labelAlert} color={this.props.alertTypeMessage}></GenericAlert>
                                <>
                                    {this.props?.listBodyTab ? this.props?.listBodyTab?.map((item, index) => {
                                        return (

                                            <TabPane key={index} hidden={this.props.activeTab !== item.id} className="tab-pane p-3" id={item.id} role="tabpanel" aria-labelledby={"linkId" + item.id}>
                                                {item.body}</TabPane>
                                        )
                                    }) : <GenericSpinner/>}
                                    {(this.props.activeTab === this.props.listNavLink.length) ? (
                                        <>
                                            <div className="row" hidden={this.props.hideStepButtons}>
                                                    <div className={!isMobileDivice ? 'col-10 p-4' :'col-12 p-4'}>
                                                        <GenericButton key={"prec"} label="Precedente" onClickEvent={this.props.previousStep} color={'secondary'} />{'  '}
                                                    </div>
                                                {!this.props.hideHome &&(
                                                  <div className={!isMobileDivice ? 'col-2 p-4' :'col-12 p-4'}>
                                                      <Link key={"home"} to={this.props.homeLink ? this.props.homeLink : getHome()} color={'secondary'} >Torna alla home</Link>
                                                  </div>
                                                )}

                                                </div>
                                        </>
                                    ) : (
                                        (this.props.activeTab === 1) ?
                                            (<>
                                                <div className="row" hidden={this.props.hidden || this.props.hideStepButtons}>
                                                <div className={!isMobileDivice ? 'col-10 p-4' :'col-12 p-4'}>
                                                        <GenericButton type={"button"} key={"prec"} isDisabled label="Precedente" onClickEvent={this.props.previousStep} color={'secondary'} />{'  '}
                                                        <GenericButton type="submit" key={"succ"} label="Successivo" color={'primary'} />
                                                    </div>
                                                    {!this.props.hideHome &&(
                                                      <div className={!isMobileDivice ? 'col-2 p-4' :'col-12 p-4'}>
                                                          <Link key={"home"} to={this.props.homeLink ? this.props.homeLink : getHome()} color={'secondary'} >Torna alla home</Link>
                                                      </div>
                                                    )}
                                                </div>

                                            </>
                                            ) : (
                                                <>

                                                    <div className="row" hidden={this.props.hidden || this.props.hideStepButtons}>
                                                    <div className={!isMobileDivice ? 'col-10 p-4' :'col-12 p-4'}>
                                                            <GenericButton type={"button"} key={"prec"} label="Precedente" onClickEvent={this.props.previousStep} color={'secondary'} />{'  '}
                                                            <GenericButton type="submit" key={"succ"} label="Successivo"color={'primary'} />
                                                        </div>
                                                      {!this.props.hideHome &&(
                                                        <div className={!isMobileDivice ? 'col-2 p-4' :'col-12 p-4'}>
                                                            <Link key={"home"} to={this.props.homeLink ? this.props.homeLink : getHome()} color={'secondary'} >Torna alla home</Link>
                                                        </div>














                                                      )}

                                            </div>
                                                   </>
                                            )
                                    )}
                                </>
                            </TabContent>


                        </Form>
                    </div>
                </div>
            </>
        );
    }


}

export declare interface GeneralTabProps {
    listNavLink?: any;
    listBodyTab?: any;
    activeTab?: number;
    tabClick?: any;
    isClickable?: any;
    id?: string;
    onSubmit?: any;
    /**
     * @description Controlla se il submit è avvenuto con successo
    */
    isSubmitted?: boolean;
    /**
     * @description id riferito all'alert
    */
    idAlert?: string
    /**
     * @description flag che nasconde o mostra l'alert
    */
    hiddenAlert?: boolean
    /**
     * @description messaggio da inserire nell'alert
    */
    labelAlert?: string
    /**
     * @description typo di messaggio
     * @param danger
     * @param success
     * @param info
     * @param warning
    */
    alertTypeMessage?: string
    /**
     * @description permette di andare indietro nelle tab
    */
    previousStep?: any;

    /**
     * @description Link per la home
    */
    homeLink?: any;
    hidden?: boolean;

    formRef?: any;

    hideHome?: boolean
    hideStepButtons?: boolean
    hideNumbers?: boolean
}


export default GenericTab;
