export const STATI_ESONERO = {
  IN_COMPILAZIONE: 0,
  INVIATA: 1,
  APPROVATA: 2,
  RIFIUTATA: 3,
  INTEGRAZIONE: 4,
}

export const LABEL_STATI = {
  [STATI_ESONERO.IN_COMPILAZIONE]: 'In compilazione',
  [STATI_ESONERO.INVIATA]: 'In valutazione',
  [STATI_ESONERO.APPROVATA]: 'Approvata',
  [STATI_ESONERO.RIFIUTATA]: 'Rigettata',
  [STATI_ESONERO.INTEGRAZIONE]: 'Richiesta Integrazione',
}

//TODO: implementare le tipologie di esonero tramite DB
/**
 * Il value corrisponde all'id della tipologia di esonero,
 * i valori sono allineati prendendo in considerazione anche i dati migrati dal vecchi DB, quindi non vanno modificati!
 */
export const MOTIVAZIONI_ESONERO = [
  {
    label: 'Maternità/paternità',
    value: '1',
  },
  {
    label: 'Malattia/infortunio',
    value: '2',
  },
  {
    label: 'Cause di forza maggiore',
    value: '5',
  },
  {
    label: 'Attività nel solo interesse dello stato',
    value: '7',
  },
  {
    label: 'Malattia di un parente',
    value: '9',
  },
  {
    label: 'Calamità naturali',
    value: '10',
  },
  {
    label: 'Non esercito la professione, neanche occasionalmente',
    value: '11',
  },
]
