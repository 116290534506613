import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Header,
  HeaderBrand,
  HeaderContent,
  HeaderRightZone,
  Icon,
  LinkList,
  LinkListItem,
  UncontrolledDropdown
} from 'design-react-kit'
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import {
  PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO,
  PATH_TO_FO_ANAG_SELEZIONA_PROFILO
} from 'src/app/utils/RoutesConstants'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { setSessionItem } from 'src/app/utils/Storage'
import { initialize } from 'src'
import { getHome, goTo } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'
import conafLogo from '../images/conaf-logo.png'

const post_to_url = function (path, params, method) {
  method = method || 'post'

  var form = document.createElement('form')

  //Move the submit function to another variable so that it doesn't get overwritten.
  form._submit_function_ = form.submit

  form.setAttribute('method', method)
  form.setAttribute('action', path)

  for (var key in params) {
    var hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', key)
    hiddenField.setAttribute('value', params[key])
    form.appendChild(hiddenField)
  }

  document.body.appendChild(form)
  form._submit_function_() //Call the renamed function.
}

export function logout() {
  let keycloak = TokenStore.getInstance().getKeycloak()
  let tokenHint = keycloak.idToken
  keycloak.clearToken()
  localStorage.clear()
  sessionStorage.clear()
  let logoutRedirect = '' + process.env.REACT_APP_LOGOUT_REDIRECT_URI
  logoutRedirect = encodeURI(logoutRedirect)
  //keycloak.logout({"redirectUri":logoutRedirect});
  let baseLogoutPath = process.env.REACT_APP_AUTH_SERVER_URL
  post_to_url(
    baseLogoutPath + '/realms/sidaf/protocol/openid-connect/logout',
    {
      post_logout_redirect_uri: logoutRedirect,
      id_token_hint: tokenHint + '',
      client_id: 'sidaf',
    },
    'post'
  )
  //window.location.reload()
  sessionStorage.clear()
  localStorage.clear()
}

class GenericHeaderSlim extends React.Component<GeneralHeaderSlimProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      userLogged: props.userLogged,
      current_account: 1,
      err: '',
      keycloak: props.keycloak,
      path: props.path,
      collapseOpen: false,
      menu: [],
      operatoreSTP: false,
      isLoading: false,
      profilo: '',
      isMobile: window.innerWidth <= 1000,
    }
    this.handleResize = this.handleResize.bind(this)
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    if (TokenStore.getInstance().getKeycloak() && TokenStore.getInstance().getKeycloak() !== '' && TokenStore.getInstance().getKeycloak().authenticated) {
      this.setState({ isLoading: true })
      var myMenu: any = []
      myMenu.splice(0, 0, {
        id: '1',
        descrizione: 'logout',
        type: 'logout',
        name: 'Logout',
        url: '',
      })
      myMenu.splice(0, 0, {
        id: '0',
        descrizione: 'cambio-profilo',
        type: 'cambio-profilo',
        name: TokenStore.getInstance().hasAtLeastOneProfile() ? 'Seleziona profilo' : 'Richiedi nuovo profilo',
        url: TokenStore.getInstance().hasAtLeastOneProfile() ? PATH_TO_FO_ANAG_SELEZIONA_PROFILO : PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO,
      })
      this.setState({ menu: myMenu })

      this.setState({
        profilo: await TokenStore.getInstance().getDenominazioneOfCurrentAccount(),
        isLoading: false,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth <= 1000 })
  }

  /**
   * Quando si clicca sul bottone per accedere il login
   * setto il click del tasto su true e chiamo l'initialize del login
   */
  loginAccess = () => {
    setSessionItem('isLoginButtonClicked', true)
    initialize()
  }

  render() {
    const { isMobile } = this.state

    if (TokenStore.getInstance().getKeycloak() && TokenStore.getInstance().getKeycloak() !== '' && TokenStore.getInstance().getKeycloak().authenticated) {
      const { userLogged } = this.state

      /**
       * Permette di switchare la voce di menu.
       * @param event
       * @param id
       */
      const switchMenu = async (event, id) => {
        // Se ho cliccato su "Seleziona profilo" vado in quella pagina
        if (event.target.textContent.startsWith('Seleziona profilo')) {
          window.history.pushState(TokenStore.getInstance().getCurrentAccount(), '', PATH_TO_FO_ANAG_SELEZIONA_PROFILO)
          window.location.reload()
          return
        }

        // Se ho cliccato su "Richiedi nuovo profilo" vado in quella pagina
        if (event.target.textContent.startsWith('Richiedi nuovo profilo')) {
          window.history.pushState(TokenStore.getInstance().getCurrentAccount(), '', PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO)
          window.location.reload()
          return
        }

        // Se ho cliccato su "Logout"
        let current_account = event.currentTarget.id
        this.setState({ current_account })
        let testForLogout = this.state.menu[current_account]

        if (testForLogout.type === 'logout') {
          logout()
        }
      }

      const expanded = {
        'aria-expanded': true,
      }

      return (
        <>
          {!isCurrentMobileDevice() && !isMobile ? (
            <Header theme="" type="slim">
              <HeaderContent>
                <HeaderBrand
                  responsive
                  onClick={() => {
                    goTo(getHome())
                  }}
                >
                  CONSIGLIO NAZIONALE DEI DOTTORI AGRONOMI E DEI DOTTORI FORESTALI
                </HeaderBrand>
                <HeaderRightZone>
                  <UncontrolledDropdown nav tag="div">
                    <DropdownToggle caret nav>
                      ITA
                      <Icon icon="it-expand" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <div className="row">
                        <div className="col-12">
                          <LinkList>
                            <LinkListItem href="">
                              <span>ITA</span>
                            </LinkListItem>
                            {/* <LinkListItem href="">
                                                        <span>ENG</span>
                                                    </LinkListItem> */}
                          </LinkList>
                        </div>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {this.state.profilo === undefined ? (
                    <GenericSpinner />
                  ) : (
                    <a href={PATH_TO_FO_ANAG_SELEZIONA_PROFILO}>
                      <span>{this.state.profilo !== '' ? this.state.profilo : ''}</span>
                    </a>
                  )}

                  <UncontrolledDropdown nav tag="div">
                    <DropdownToggle caret nav>
                      <Button style={{ height: 'auto', width: '260px' }} className="btn-icon" color="primary">
                        <span style={{ height: '30px', width: '30px', float: 'left' }} className="col-2 rounded-icon">
                          <Icon size="xs" color="primary" icon="it-user" />
                        </span>
                        <span className="col-10" style={{ float: 'right' }}>
                          {userLogged ? userLogged : "Accedi all'area riservata"}{' '}
                        </span>
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu style={{ zIndex: 1000 }}>
                      <div className="row">
                        <div className="col-12">
                          <LinkList>
                            {this.state.menu.map((item, id) => (
                              <LinkListItem
                                className="right-icon"
                                onClick={(e) => switchMenu(e, id)}
                                {...(this.state.collapseOpen ? expanded : {})}
                                key={item.id}
                                id={item.id}
                              >
                                <span>{item.name}</span>
                              </LinkListItem>
                            ))}
                          </LinkList>
                        </div>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </HeaderRightZone>
              </HeaderContent>
            </Header>
          ) : (
            <Header theme="" type="slim">
              <HeaderContent>
                <HeaderBrand responsive>
                  <img
                    onClick={() => {
                      goTo(getHome())
                    }}
                    src={conafLogo}
                    width={'100%'}
                    alt="conaf-logo"
                  />
                </HeaderBrand>
                <UncontrolledDropdown nav tag="div">
                  <DropdownToggle caret nav>
                    <Button style={{ height: 'auto', width: '260px' }} className="btn-icon" color="primary">
                      <span style={{ height: '30px', width: '30px', float: 'left' }} className="col-2 rounded-icon">
                        <Icon size="xs" color="primary" icon="it-user" />
                      </span>
                      <span className="col-10" style={{ float: 'right' }}>
                        {userLogged ? userLogged : "Accedi all'area riservata"}{' '}
                      </span>
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu style={{ zIndex: 1000 }}>
                    <div className="row">
                      <div className="col-12">
                        <LinkList>
                          {this.state.menu.map((item, id) => (
                            <LinkListItem
                              className="right-icon"
                              onClick={(e) => switchMenu(e, id)}
                              {...(this.state.collapseOpen ? expanded : {})}
                              key={item.id}
                              id={item.id}
                            >
                              <span>{item.name}</span>
                            </LinkListItem>
                          ))}
                        </LinkList>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderContent>
            </Header>
          )}
        </>
      )
    } else {
      return (
        <>
          {!isCurrentMobileDevice() && !isMobile ? (
            <Header theme="" type="slim">
              <HeaderContent>
                <HeaderBrand
                  responsive
                  onClick={() => {
                    goTo(getHome())
                  }}
                >
                  CONSIGLIO NAZIONALE DEI DOTTORI AGRONOMI E DEI DOTTORI FORESTALI
                </HeaderBrand>
                <HeaderRightZone>
                  <UncontrolledDropdown nav tag="div">
                    <DropdownToggle caret nav>
                      ITA
                      <Icon icon="it-expand" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <div className="row">
                        <div className="col-12">
                          <LinkList>
                            <LinkListItem href="">
                              <span>ITA</span>
                            </LinkListItem>
                          </LinkList>
                        </div>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav tag="div">
                    <DropdownToggle caret nav>
                      <Button style={{ height: 'auto', width: '260px' }} className="btn-icon" color="primary" onClick={() => this.loginAccess()}>
                        <span style={{ height: '30px', width: '30px', float: 'left' }} className="col-2 rounded-icon">
                          <Icon size="xs" color="primary" icon="it-user" />
                        </span>
                        <span className="col-10" style={{ float: 'right' }}>
                          {"Accedi all'area riservata"}{' '}
                        </span>
                      </Button>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </HeaderRightZone>
              </HeaderContent>
            </Header>
          ) : (
            <Header theme="" type="slim">
              <HeaderContent>
                <HeaderBrand type="slim" responsive>
                  <img
                    onClick={() => {
                      goTo(getHome())
                    }}
                    src={conafLogo}
                    width={'100%'}
                    alt="conaf-logo"
                  />
                </HeaderBrand>
                <UncontrolledDropdown nav tag="div">
                  <DropdownToggle caret nav>
                    <Button style={{ height: 'auto', width: '260px' }} className="btn-icon" color="primary" onClick={() => this.loginAccess()}>
                      <span style={{ height: '30px', width: '30px', float: 'left' }} className="col-2 rounded-icon">
                        <Icon size="xs" color="primary" icon="it-user" />
                      </span>
                      <span className="col-10" style={{ float: 'right' }}>
                        {'Accedi'}{' '}
                      </span>
                    </Button>
                  </DropdownToggle>
                </UncontrolledDropdown>
              </HeaderContent>
            </Header>
          )}
        </>
      )
    }
  }
}

export declare interface GeneralHeaderSlimProps {
  userLogged?: any
}

export default GenericHeaderSlim
