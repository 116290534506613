import * as React from 'react'
import { Form, Tooltip } from '@patternfly/react-core'
import GenericTextInput from '../../../components/GenericTextInput'
import { checkAnno, convertUrlParamsToObject, inputRequiredValidation } from 'src/utilities/utility'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import GenericCheckbox from 'src/frontend/app/components/GenericCheckbox'
import {
  registraPagamentoQuotaAnnualeMassivo,
  registraPagamentoQuotaAnnualeSingolo
} from 'src/processes/PagamentoQuotaAnnuale'
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { RO_GEST_QUOT_ASSO, RO_GEST_UTEN_ODAF } from 'src/app/utils/RoutesConstants'
import { getSoggettoByIdIscrizione } from 'src/processes/Soggetto'
import { getIscrizioneByIdBO } from 'src/processes/ProcessoIscrizione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormRegistrazionePagamento extends React.Component<GeneralFormRegistrazionePagamentoProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      isCsvChecked: false,
    }
  }

  componentDidMount() {
    //recupero gli eventuali parametri dall'url
    var url = window.location.search?.split('?')[1]
    var urlParams = convertUrlParamsToObject(url)
    //caso 1: Sono un operatore dell'ordine territoriale e ho il ruolo 'RO_GEST_QUOT_ASSO'.
    //Seleziono "registrazione pagamento" da una riga dell'elenco iscritti e mi porto dietro "idIscrizione" e "cf"
    if (TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_QUOT_ASSO]) && urlParams?.idIscrizione) {
      this.setState({ isCsvChecked: false })

      //recupero l'idIscrizione dall'url
      var idIscrizione = urlParams?.idIscrizione
      //recupero il cf dall'url
      var cf = urlParams?.cf
      this.setState({ idIscrizione: idIscrizione, cf: cf })

      //chiamata al servizio 'getSoggettoByIdIscrizione' che restituisce i dati del soggetto a partire dall'idIscrizione
      getSoggettoByIdIscrizione(idIscrizione).then((response: any) => {
        this.setState({ idSoggetto: response.data.idSoggetto })
      })

      //chiamata al servizio 'getIscrizioneByIdBO' che restituisce i dati dell'iscrizione a partire dall'idIscrizione
      getIscrizioneByIdBO(idIscrizione, '').then((response: any) => {
        this.setState({ flagEsonerato: response.data.iscrizioneOrdineTerritorialeDTO.flagEsoneratoPagamentoQuotaIscrizione })
      })
    }

    //caso 2: Sono un operatore dell'ordine territoriale e ho il ruolo 'RO_GEST_UTEN_ODAF'.
    //Seleziono "registra pagamento" dal menu "anagrafica"
    else if (TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_UTEN_ODAF])) {
      this.setState({ isCsvChecked: true, isFromLinkMenu: true })
    }

    //altri casi: non ho i ruoli per registrare pagamenti massivo/singolo, pertanto non posso accedere alla funzionalità
    else {
      return this.props.messaggio({ messaggio: 'Account non gestito', type: 'danger' })
    }
  }

  //onChange csv checkbox
  handleChange = (event, type) => {
    switch (type) {
      case 'checkbox':
        if (event.target.checked === true) this.setState({ isCsvChecked: true })
        else this.setState({ isCsvChecked: false })

        break

      case 'file':
        this.props.messaggio({ messaggio: '', type: 'default' })
        this.setState({ fileCsv: event.target?.files[0] })
        break
    }
  }

  handleSubmit = async (event) => {
    /*---------------------------------------------- Validazione campi obbligatori -------------------------------------------------*/
    var requiredFields = [
      this.state.isCsvChecked ? { value: event.target.idFileCsv.value, label: 'File CSV' } : null,
      !this.state.isCsvChecked
        ? checkAnno(event.target.idAnnoRiferimento.value)
          ? { value: event.target.idAnnoRiferimento.value, label: 'Anno di riferimento' }
          : { value: '', label: 'Anno di riferimento' }
        : null,
      !this.state.isCsvChecked && this.state.flagEsonerato !== 1 ? { value: event.target.idDataPagamento.value, label: 'Data pagamento' } : null,
      !this.state.isCsvChecked ? { value: event.target.idDataFineVali.value, label: 'Data fine validità pagamento' } : null,
    ]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
      /*------------------------------------------------------------------------------------------------------------------------------*/
      //Registrazione Pagamento quota annuale Massivo
      if (this.state.isCsvChecked) {
        var error: any = []
        try {
          event.preventDefault()
          //chiamata al servizio registraPagamentoQuotaAnnualeMassivo
          error = await registraPagamentoQuotaAnnualeMassivo(this.state.fileCsv)
          if (error.data.length > 0)
            return this.props.messaggio({
              messaggio:
                'La registrazione del pagamento non è andata a buon fine per i seguenti codici fiscali: ' + error.data.toString().replaceAll(',', ', '),
              type: 'danger',
            })
          else return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
        } catch (err) {
          return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
        }
      }

      //Registrazione Pagamento quota annuale singolo
      else {
        var dtoQuotaAnnuale = {
          idIscrizione: this.state.idIscrizione,
          idSoggetto: this.state.idSoggetto,
          cf: this.state.cf,
          annoRiferimento: event.target.idAnnoRiferimento.value ? event.target.idAnnoRiferimento.value : '',
          dataPagamento: event.target.idDataPagamento.value ? event.target.idDataPagamento.value : null,
          dataFineValiditaPagamento: event.target.idDataFineVali.value ? event.target.idDataFineVali.value : null,
        }
        try {
          event.preventDefault()
          //chiamata al servizio registraPagamentoQuotaAnnualeSingolo
          await registraPagamentoQuotaAnnualeSingolo(dtoQuotaAnnuale)
          return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
        } catch (err) {
          return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
        }
      }
    }
  }

  render() {
    const { isCsvChecked, cf, flagEsonerato, isFromLinkMenu } = this.state
    return (
      <div>
        <Form id="idRegistrazionePagamento" onSubmit={this.handleSubmit}>
          {/* checkbox visibile solo se l'utente possiede il ruolo "RO_GEST_UTEN_ODAF" oppure isFromLinkMenu è true (la pagina è stata chiamata dal link presente sotto menu anagrafica) */}
          <div className="row" hidden={isFromLinkMenu === true || !TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_UTEN_ODAF])}>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericCheckbox id="idCsv" key={'csv'} defaultChecked={isCsvChecked} onChange={(e) => this.handleChange(e, 'checkbox')} label={'Carica CSV'} />
            </div>
          </div>
          {/*---------------------------------------------------------- Campi visibili solo se è flaggata la checkbox ------------------------------------------------------*/}
          <div className="row" hidden={!isCsvChecked}>
            {/*Campo obbligatorio solo se la checkbox è 'true'*/}
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <br></br>
              <GenericUploadFront
                label={
                  <>
                    File CSV *{' '}
                    <Tooltip
                      content={
                        'I valori da riportare nel CSV sono i seguenti: Codice Fiscale, anno di riferimento, data pagamento, data fine validità pagamento. Ogni valore deve essere separato da “;”. Il formato delle date dovrà essere “gg/mm/aaaa”. Il formato dell’anno è a quattro cifre “aaaa”. Non sono ammessi spazi bianchi.'
                      }
                    >
                      <InfoCircleIcon />
                    </Tooltip>
                  </>
                }
                id={'idFileCsv'}
                acceptedExtensionFile={['csv']}
                isRequired={isCsvChecked}
                onChange={(e) => this.handleChange(e, 'file')}
              />
            </div>
          </div>
          {/*-------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          {/*-------------------------------------------------------- Campi visibili solo se NON è flaggata la checkbox ----------------------------------------------------*/}
          <div className="row" hidden={isCsvChecked}>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput type={'text'} className="w-75" id={'idCf'} label={'Codice fiscale'} value={cf} isDisabled />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'anno'}
                className="w-75"
                id={'idAnnoRiferimento'}
                label={'Anno di riferimento'}
                placeholder={'Inserire anno'}
                isRequired
              />
            </div>
          </div>
          <div className="row" hidden={isCsvChecked}>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput type={'date'} className="w-75" id={'idDataPagamento'} label={'Data pagamento'} isRequired={flagEsonerato !== 1} />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput type={'date'} className="w-75" id={'idDataFineVali'} label={'Data fine validità pagamento'} isRequired />
            </div>
          </div>
          {/*-------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormRegistrazionePagamentoProps {
  messaggio?: any
}
