import React from 'react'

class GenericTitle extends React.Component<GeneralTitleProps, any> {
    constructor(props) {
        super(props);
        this.state = {
           title: props.title,
           Size: props.Size
        };
    }

    render(){
        const { Size, title } = this.state;
        return (
          <Size>{title}</Size>
        )
    }
}

export declare interface GeneralTitleProps {
    Size?: any;
    title?: any;
}

export default GenericTitle;
