import moment from 'moment'

export const isInvalidAllegato = (allegato: any, motivazione: any) => {
  if (motivazione?.value === '11') return false
  if (!allegato && motivazione?.value !== '11') return true
  if (Array.isArray(allegato)) return allegato?.filter((v) => v)?.length === 0
  return !allegato?.allegatoDTO?.contentBase64
}

export default {
  motivazione: {
    isRequired: true,
  },
  data_inizio: {
    isRequired: true,
  },
  data_fine: {
    isRequired: true,
    _custom: {
      check: (code, rule, data) => {
        if (data.data_inizio) {
          return moment(data.data_inizio).diff(data.data_fine) <= 0
        } else {
          return moment().diff(data.data_fine) <= 0
        }
      },
    },
  },
  // allegato: {
  //     'isRequired': true,
  //     'isNotEmpty': true
  // }
  check_uploads: {
    _custom: {
      check: (code, rule, data) => {
        return !isInvalidAllegato(data?.allegato, data?.motivazione)
      },
    },
  },
}
