import styled from 'styled-components'


export const FormTopbar = styled.div`
  display:flex;
  align-items:center;
  justify-content: space-between;
`

export const Separator = styled.div` 
  height: 30px;
`;
