import React, { useCallback, useState } from 'react'
import { Label } from 'design-react-kit'
import FormSubmit, { STATUS } from '../../../../../frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { STATI_EVENTO_CATALOGO_VALUES } from 'src/formazione'
import { useHistory } from 'react-router-dom'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'

const FormApprovazione: React.FunctionComponent<FormApprovazioneProps> = ({ id }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()
  const history = useHistory()

  const submit = useCallback(
    async (e) => {
      try {
        // TODO EVENTO CATALOGO API check
        setStatus(STATUS.LOADING)
        const nome = TokenStore.getInstance().getSpidData().nome
        const cognome = TokenStore.getInstance().getSpidData().cognome

        await getFormazioneResourceApi().msfoFormazioneAggiornaEventoACatalogoPatch(
          {
            idcorso: id,
            data_accettazione: new Date().toISOString(),
            accreditato: STATI_EVENTO_CATALOGO_VALUES.ACCREDITATO,
            accreditatoda: nome.substring(0, 1) + "." + cognome,
          } as any,
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id]
  )

  return (
    <FormSubmit title="Approvazione evento" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Label>Per approvare l'evento, effettuare le necessarie modifiche e cliccare "Conferma".</Label>
    </FormSubmit>
  )
}

interface FormApprovazioneProps {
  id: any
}

export default FormApprovazione
