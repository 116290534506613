import * as React from 'react'
import { Form } from '@patternfly/react-core'
import { GenericTextArea } from '../../../components/GenericTextArea'
import { valutaIscrizioneSTP } from 'src/processes/ProcessoIscrizione'
import { inputRequiredValidation } from 'src/utilities/utility'

export class FormRichiediIntegrazioniSTP extends React.Component<GeneralFormRichiediIntegrazioniSTPProps, any> {
    handleSubmit = async event => {
         /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
         var requiredFields = [
            { value: event.target.idIntegrazioniSTP.value, label: 'Integrazioni richieste' },
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*----------------------------------------------------------------------------------------------------------------------------*/
       else {
            //chiamata al servizio per richiesta integrazioni iscrizione STP
            var esitoValutazione = "richiesta_integrazioni"
            var idIscrizione = this.props.idIscrizione
            var idSoggetto = this.props.idSoggetto
            var parametriRichiestaIntegrazioniSTP = {
                integrazioniRichieste: event.target.idIntegrazioniSTP.value ? event.target.idIntegrazioniSTP.value : ""
            }
            try {
                event.preventDefault();
                await valutaIscrizioneSTP(esitoValutazione, idIscrizione, idSoggetto, parametriRichiestaIntegrazioniSTP, [])

                return this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
            }
            catch (err) {
                return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
            }
        }
    }

    render() {
        return (
            <div>
                <Form id='idRichiediIntegrazioniSTP' onSubmit={this.handleSubmit}>
                    <GenericTextArea className='w-50' id={"idIntegrazioniSTP"} label={"Integrazioni richieste"} name={"integrazioniRichieste"} placeholder={"Inserire integrazioni"} ariaLabel={'textarea-integrazionIRichieste'} isRequired />
                </Form>
            </div>
        )
    }
}

export declare interface GeneralFormRichiediIntegrazioniSTPProps {
    messaggio?: any;
    idIscrizione?: any;
    idSoggetto?: any;
}
