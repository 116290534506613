import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormResidenzaContatti extends React.Component<GeneralFormResidenzaContattiProps, any> {
  render() {
    const { messaggio, soggetto } = this.props
    return (
      <>
        {soggetto === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idProvinciaResidenza'}
                    label={'Provincia di residenza'}
                    value={this.props.provinciaResidenza}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idComuneResidenza'}
                    label={'Comune di residenza'}
                    value={soggetto.comuneResidenza}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idIndirizzoResidenza'}
                    label={'Indirizzo (via)'}
                    value={soggetto.indirizzoResidenza}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idCivicoResidenza'} label={'Numero civico'} value={soggetto.numeroCivico} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                  <GenericTextInput type={'text'} className="w-50" id={'idCapResidenza'} label={'CAP'} value={soggetto.capResidenza} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'email'} className="w-75" id={'idEmail'} label={'E-mail ordinaria'} value={soggetto.emailOrdinaria} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'email'} className="w-75" id={'idPec'} label={'PEC'} value={soggetto.pec} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'tel'} className="w-75" id={'idTel'} label={'Numero di telefono'} value={soggetto.telefono} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'tel'} className="w-75" id={'idAltroTel'} label={'Altro telefono'} value={soggetto.altroTelefono} isReadOnly />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormResidenzaContattiProps {
  soggetto?: any
  provinciaResidenza?: any
  messaggio?: any
}
