export const PDFLinks = {
    
    // Polizze
    DICHIARAZIONE_SINISTRO_CIRCOSTANZA_PREGRESSA: '/polizze/16_Modulo%20sinistri_circostanze_9.11.pdf',
    NORMATIVA_POLIZZE_RIFERIMENTO: '/polizze/DPR_137_7_AGOSTO_2012.pdf',
    LINEE_GUIDA_POLIZZE: '/polizze/2_Linee_guida_Polizza_RC_professionale_collettiva_CONAF_2024.pdf',
    VADEMECUM_POLIZZE: '/polizze/1_VADEMECUM_ASSICURATI_CONAF_IT00024030EO20A_09_08_24.pdf',
    REGOLAMENTO_POLIZZA_COLLETTIVA: '/polizze/3_Regolamento%20Polizza%20collettiva%20CONAF_agg.%202021_9.2.3.1.pdf',

    // Anagrafica
    DICHIARAZIONE_SOSTITUTIVA_PAGAMENTO_BOLLO: '/iot/DichiarazioneSostitutivaDiPagamentoBollo.pdf'
  };