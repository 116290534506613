import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericTextArea from 'src/backoffice/app/components/GenericTextArea'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();

export class FormContatti extends React.Component<GeneralFormContattiProps, any> {
    constructor(props) {
        super(props)
        this.state = {
            returnedObject: props.returnedObject[0],
            messaggio: props.messaggio
        }
    }
    render() {
        const { returnedObject, messaggio } = this.state
        return (
            <>
                {(returnedObject === '' && messaggio === '') ?
                    (
                        <GenericSpinner />
                    )
                    : (messaggio !== '' ? (
                        <EmptyState variant="full">
                            <EmptyStateIcon icon={ExclamationTriangleIcon} />
                            <EmptyStateBody>
                                Non è stato possibile recuperare i dati. Contattare l'assistenza.
                            </EmptyStateBody>
                        </EmptyState>
                    ) : (
                        <div>
                            <Form>
                                <div className='row'>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idTelefono'} label={"Telefono"} value={returnedObject.telefono ? returnedObject.telefono : '-'} isReadOnly />
                                    </div>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idFax'} label={"Fax"} value={returnedObject.fax ? returnedObject.fax : '-'} isReadOnly />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idEmail'} label={"E-mail"} value={returnedObject.emailOrdinaria ? returnedObject.emailOrdinaria : '-'} isReadOnly />
                                    </div>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idPec'} label={"PEC"} value={returnedObject.pec ? returnedObject.pec : '-'} isReadOnly />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idSito'} label={"Sito web"} value={returnedObject.sitoWeb ? returnedObject.sitoWeb : '-'} isReadOnly />
                                    </div>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextArea type={'text'} className='w-75' id={'idOrariUffici'} label={"Orari apertura uffici"} value={returnedObject.orarioAperturaUffici ? returnedObject.orarioAperturaUffici : '-'}  isReadOnly />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )
                    )}
            </>
        )
    }
}

export declare interface GeneralFormContattiProps {
    returnedObject?: any,
    messaggio?: string
}

