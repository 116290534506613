import { Form, Label } from 'design-react-kit'
import * as React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericCheckbox from 'src/frontend/app/components/GenericCheckbox'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'
import { getBigIntToConvertToBoolean } from 'src/utilities/utility'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const flagAutocertificazione = '* Dichiaro sotto la mia responsabilità penale che i dati dichiarati corrispondono al vero.'
const falgPercSocetaria =
  '* Dichiaro che la % dei soci iscritti all’albo dei Dottori Agronomi e Dottori Forestali è superiore ai 2/3 sia per il numero di soci sia per la partecipazione al capitale sociale.'
const flagComprensioneAdempimenti = '* Dichiaro di aver letto e compreso gli adempimenti degli iscritti come riportati nel seguente link.'
const statoCompilazione = 1
/* -------------------------------------------------------------------------------------------------- */

export class FormAllegatiDichiarazioniStpFO extends React.Component<GeneralFormAllegatiDichiarazioniStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      dataLoaded: false,
    }
  }

  componentDidMount() {
    var descrizioneAllegato = this.props.listaFiles?.filter((e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'allegato_stp')[0]
      ?.descrizioneBreve
    var fileNameCertificatoRegistroImprese = this.props.listaFiles?.filter(
      (e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'certificato_registro_imprese'
    )[0]?.filename
    var fileNameAllegato = this.props.listaFiles?.filter((e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'allegato_stp')[0]
      ?.filename
    var idAttachmentCertificatoRegistroImprese = this.props.listaFiles?.filter(
      (e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'certificato_registro_imprese'
    )[0]?.idAttachment
    var idAttachmentAllegato = this.props.listaFiles?.filter((e) => (e.codiceTipoAllegato ? e.codiceTipoAllegato : e.tipologiaAllegato) === 'allegato_stp')[0]
      ?.idAttachment
    var iscrizione = this.props.datiIscrizione?.iscrizioneOrdineTerritorialeDTO
    var soggetto = this.props.datiIscrizione?.soggettoDTO
    this.setState({
      descrizioneBreveAllegato: descrizioneAllegato,
      fileNameCertificatoRegistroImprese: fileNameCertificatoRegistroImprese,
      fileNameAllegato: fileNameAllegato,
      idAttachmentCertificatoRegistroImprese: idAttachmentCertificatoRegistroImprese,
      idAttachmentAllegato: idAttachmentAllegato,
      iscrizione: iscrizione,
      soggetto: soggetto,
      dataLoaded: true,
    })
  }

  handleChange = (event, fieldId, type) => {
    if (type === 'checkbox' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.checked })
    else if (type === 'file' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.value })
    else if (type === 'input' && fieldId === 'idDescrizioneAllegato') {
      this.props.formData({ id: fieldId, value: event.target.value })
    }
  }

  render() {
    const { tabNumber, statoIscrizione } = this.props
    const {
      fileNameCertificatoRegistroImprese,
      fileNameAllegato,
      idAttachmentCertificatoRegistroImprese,
      idAttachmentAllegato,
      dataLoaded,
      descrizioneBreveAllegato,
      iscrizione,
      soggetto,
    } = this.state

    const getDatiCerificatoRegistroImprese = (fileData) => {
      this.props.fileCertificatoRegistroImprese(fileData)
    }

    const getDatiAllegatoStp = (fileData) => {
      this.props.fileAllegatoStp(fileData)
    }

    return (
      <>
        {dataLoaded === true ? (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <Label for="idCertificatoIscrRegistroImprese">
                    <strong>Certificato di iscrizione nel registro imprese*</strong>
                  </Label>
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericUploadFront
                    name={'Certificato di iscrizione nel registro imprese'}
                    value={fileNameCertificatoRegistroImprese}
                    idAttachment={idAttachmentCertificatoRegistroImprese}
                    datiAllegato={getDatiCerificatoRegistroImprese}
                    tipologiaAllegato={'certificato_registro_imprese'}
                    acceptedExtensionFile={['pdf']}
                    id={'idCertificatoIscrRegistroImprese'}
                    errorMessageAlert={this.props.message}
                    onChange={(e) => this.handleChange(e, 'idCertificatoIscrRegistroImprese', 'file')}
                    currentStep={tabNumber}
                    isRequired
                  />
                </div>
              </div>
              <div className="row">
                <Label for="idFileAllegato">
                  <strong>
                    Atto costitutivo e statuto in copia autentica oppure per la STP società semplice una dichiarazione autenticata del socio professionista cui
                    spetti l’amministrazione della società *
                  </strong>
                </Label>
              </div>
              <br></br>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericUploadFront
                    name={'Allegato'}
                    descrizioneBreve={descrizioneBreveAllegato}
                    value={fileNameAllegato}
                    idAttachment={idAttachmentAllegato}
                    datiAllegato={getDatiAllegatoStp}
                    tipologiaAllegato={'allegato_stp'}
                    acceptedExtensionFile={['pdf']}
                    id={'idFileAllegato'}
                    errorMessageAlert={this.props.message}
                    onChange={(e) => this.handleChange(e, 'idFileAllegato', 'file')}
                    currentStep={tabNumber}
                    isRequired
                    isDisabled={statoIscrizione !== statoCompilazione}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-5'}>
                  <GenericInput
                    defaultValue={descrizioneBreveAllegato}
                    type={'text'}
                    id={'idDescrizioneAllegato'}
                    placeholder={'Inserire descrizione allegato'}
                    label={'Descrizione allegato'}
                    onChange={(e) => this.handleChange(e, 'idDescrizioneAllegato', 'input')}
                    isDisabled={statoIscrizione !== statoCompilazione}
                  />
                </div>
              </div>
              {/* {
                        allegati.map((item, id) => {
                            return (
                                <div className='row' key={id}>
                                    <div className='col-6'>
                                        <GenericUploadFront acceptedExtensionFile={['pdf']} id={"idFileAllegato" + id} itemIdDeleted={"idFileAllegato" + itemIdDeleted} errorMessageAlert={this.props.message} onChange={(e) => this.handleChangeAllegato(e, "idFileAllegato" + id, id)} currentStep={tabNumber} isRequired />
                                    </div>
                                    <div className='col-5'>
                                        <GenericInput type={'text'} id={'idDescrizioneAllegato' + id} placeholder={"Inserire descrizione allegato"} itemIdDeleted={"idDescrizioneAllegato" + itemIdDeleted} label={"Descrizione allegato"} errorMessageAlert={this.props.message} onChange={(e) => this.handleChangeAllegato(e, "idDescrizioneAllegato" + id, id)} currentStep={tabNumber} />
                                    </div>
                                    <div className='col-1' hidden={id === 0}>
                                        <GenericButton type="button" size="xs" onClickEvent={() => this.eliminaAllegato(allegati, id)} children={<Icon size="sm" style={{ padding: '1px', marginTop: '13px' }} color='danger' icon="it-delete" />} />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='col-6 mb-5'>
                        <GenericButton size="xs" type="button" color="primary" label="Aggiungi allegato" onClickEvent={() => this.aggiungiAllegato(allegati)} />
                    </div> */}
              <div>
                <GenericCheckbox
                  title={'Autocertificazione'}
                  defaultChecked={getBigIntToConvertToBoolean(soggetto?.socFlagAutocertificazioneDati)}
                  errorMessageAlert={this.props.message}
                  id={'idSocFlagAutocertificazioneDati'}
                  label={flagAutocertificazione}
                  onChange={(e) => this.handleChange(e, 'idSocFlagAutocertificazioneDati', 'checkbox')}
                  currentStep={tabNumber}
                  isRequired
                />
                <GenericCheckbox
                  title={'Certificazione % societaria'}
                  defaultChecked={getBigIntToConvertToBoolean(iscrizione?.flagPercentualeSocietaria)}
                  errorMessageAlert={this.props.message}
                  id={'idFlagPercentualeSocietaria'}
                  label={falgPercSocetaria}
                  onChange={(e) => this.handleChange(e, 'idFlagPercentualeSocietaria', 'checkbox')}
                  currentStep={tabNumber}
                  isRequired
                />
                <GenericCheckbox
                  title={'Comprensione adempimenti'}
                  defaultChecked={getBigIntToConvertToBoolean(soggetto?.comprensioneAdempimenti)}
                  errorMessageAlert={this.props.message}
                  id={'idComprensioneAdempimenti'}
                  label={flagComprensioneAdempimenti}
                  onChange={(e) => this.handleChange(e, 'idComprensioneAdempimenti', 'checkbox')}
                  currentStep={tabNumber}
                  isRequired
                />
              </div>
            </Form>
          </div>
        ) : (
          <GenericSpinner />
        )}
      </>
    )
  }
}

export declare interface GeneralFormAllegatiDichiarazioniStpFOProps {
  message?: any
  tabNumber?: number
  formData?: any
  fileCertificatoRegistroImprese?: any
  fileAllegatoStp?: any
  isModifica?: boolean
  datiIscrizione?: any
  listaFiles?: any
  statoIscrizione?: number
}
