import React from 'react'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import { useAgenziaFormativa } from 'src/hooks/useReqOptions'

const FormDatiPianoFormativo: React.FunctionComponent<FormDatiPianoFormativoProps> = ({
                                                                                        title,
                                                                                        data,
                                                                                        handleInput,
                                                                                        onInitInput,
                                                                                        errors,
                                                                                        isDisabled
                                                                                      }) => {

  const listError = errors || {}
  const isAgenziaForm = !!useAgenziaFormativa();
  if(isAgenziaForm){data.delibera_prot_ordine = "-----"}    // const creata per nascondere campo delibera per agenzia formativa , dato non obbligatorio per AF. hotfix (349483 - 1160))

  return (
    <GenericCard cardHeader={title} className={'form-dati-piano'}>
      <div className={'form-section'}>
        <div className={'row'}>
          <div className='col-3'>
            <GenericInput
              id='nr_prot_ordine'
              type={'number'}
              placeholder={'Numero'}
              label={'Numero*'}
              value={data.nr_prot_ordine}
              onChange={handleInput}
              onInit={onInitInput}
              isDisabled={isDisabled}
              errorMessage={listError.nr_prot_ordine}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className={'col-3'}>
            <GenericInput
              id='data_prot_ordine'
              type={'date'}
              placeholder={'Data'}
              label={'Data*'}
              value={data.data_prot_ordine}
              onChange={handleInput}
              onInit={onInitInput}
              isDisabled={isDisabled}
              errorMessage={listError.data_prot_ordine}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className={'col-6'}>
            <GenericInput
              id='delibera_prot_ordine'
              type={'text'}
              placeholder={'Delibera'}
              label={'Delibera*'}
              value={data.delibera_prot_ordine}
              onChange={handleInput}
              onInit={onInitInput}
              isDisabled={isDisabled}
              isHidden={isAgenziaForm}
              errorMessage={listError.delibera_prot_ordine}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <GenericInput
            id='nr_iscr_destinatari_pf'
            type={'number'}
            placeholder={'Numero Iscritti'}
            label={'Numero Iscritti*'}
            value={data.nr_iscr_destinatari_pf}
            onChange={handleInput}
            onInit={onInitInput(0)}
            isDisabled={isDisabled}
            errorMessage={listError.nr_iscr_destinatari_pf}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TextArea
            id='analisi_fabb_formativi'
            placeholder={'Analisi dei fabbisogni formativi degli iscritti'}
            label={'Analisi dei fabbisogni formativi degli iscritti*'}
            value={data.analisi_fabb_formativi}
            errorMessage={listError.analisi_fabb_formativi}
            onChange={handleInput}
            isDisabled={isDisabled}
            onInit={onInitInput}
            labelBottom={true}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TextArea
            id='strategia_obiettivi'
            placeholder={'Strategia ed Obiettivi'}
            label={'Strategia ed Obiettivi*'}
            value={data.strategia_obiettivi}
            onChange={handleInput}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError.strategia_obiettivi}
            labelBottom={true}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TextArea
            id='organiz_e_prior_eventi'
            placeholder={'Organizzazione e priorità degli eventi'}
            label={'Organizzazione e priorità degli eventi*'}
            value={data.organiz_e_prior_eventi}
            onChange={handleInput}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError.organiz_e_prior_eventi}
            labelBottom={true}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TextArea
            id='integr_descr_gen_pform'
            placeholder={'Note Aggiuntive'}
            label={'Note Aggiuntive'}
            value={data.integr_descr_gen_pform}
            onChange={handleInput}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError.integr_descr_gen_pform}
            labelBottom={true}
          />
        </div>
      </div>
    </GenericCard>
  )
}

interface FormDatiPianoFormativoProps {
  data: any;
  errors?: any;
  title: string;
  handleInput?: any
  onInitInput?: any
  isDisabled?: boolean
}

export default FormDatiPianoFormativo
