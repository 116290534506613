import React, { useMemo } from 'react'
import { Form } from 'design-react-kit'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'

const FormDatiAgenzia: React.FunctionComponent<GeneralFormDatiAgenziaProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiAnagrafici'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-6">
            <WrpInput as={GenericInput} id="ragioneSociale" type="text" label="Ragione Sociale" suffix="*" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <WrpInput as={GenericInput} id="codiceFiscale" type="text" label="Codice fiscale" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="piva" type="text" label="P. IVA" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="email" type="text" label="Email" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="pec" type="text" label="Pec" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="telefono" type="text" label="Telefono" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="fax" type="text" label="Fax" data={data} errors={errors} />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface GeneralFormDatiAgenziaProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiAgenzia
