import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import { FormRichiediIntegrazioniPolizza } from './formPolizze/FormRichiediIntegPolizza'
import {
  PATH_TO_BO_POLIZZE_ELENCO,
  PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI
} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

class RichiediIntegrazioniPolizza extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      typeMessage: "default",
      idPolizza: ""
    };
  }
  goToElencoPolizze = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    });
  }
  componentDidMount() {

    if (this.props.history.location.state === undefined) {
    //   this.goToElencoPolizze(PATH_TO_BO_POLIZZE_ELENCO)
    }

    else {
      var idPolizza = this.props.history.location.state.idPolizza
      this.setState({ idPolizza: idPolizza })

    }
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById("pageSectionId");
    if (element)
      element.scrollIntoView({ behavior: 'smooth' });
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
  }

  render() {
    const { message, typeMessage, idPolizza, idSoggetto } = this.state
    return (
      <>
        <div className='container-fluid p-5'>
              <PageSection id='pageSectionId'>
                <GenericBreadCrumb paths={[{ label: "Polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Elenco polizze", link: PATH_TO_BO_POLIZZE_ELENCO }, { label: "Richiedi Integrazione", link: PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI }]} /><br></br>
                <Title headingLevel="h1" size="4xl">Richiesta integrazione</Title><br></br>
                <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                <GenericCard className="cardStyle" isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormRichiediIntegrazioniPolizza messaggio={this.submitMessageResult} idPolizza={idPolizza} idSoggetto={idSoggetto}/></>} /><br></br>
                <div className='row'>
                  <div className={isCurrentMobileDevice() ? 'col-6' : 'col-1' }>
                    <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => this.goToElencoPolizze(PATH_TO_BO_POLIZZE_ELENCO)} />
                  </div>
                  <div className={isCurrentMobileDevice() ? 'col-6' : 'col-1' }>
                    <GenericButton form={"idRichiediPolizza"} type={"submit"} label={"CONFERMA"} color={"primary"}/>
                  </div>
                </div>
              </PageSection>
              <PageSection className='pt-5'>
                <div>
                  <Title headingLevel='h3'>Legenda</Title>
                  <p>* Campo obbligatorio</p>
                </div>
              </PageSection>
        </div>
      </>
    );
  }
};

export default RichiediIntegrazioniPolizza ;
