import { Label } from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericButton from '../../../components/GenericButton'
import GenericCard from '../../../components/GenericCard'

export default class Invio extends React.Component<InvioProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      modificaDatiFlag: props.modificaDatiFlag,
      iscrizioneGiaInviata: props.iscrizioneGiaInviata,
      visualizzaFlag: props.visualizzaFlag,
      fields: (props.prevData && props.prevClicked) ? props.prevData[0] : (props.data) ? props.data : {},
      isLoaded: false,
    };
  }

  async componentDidMount(): Promise<void> {
    if(this.state.modificaDatiFlag === false && this.state.visualizzaFlag === false && this.state.reiscrizioneFlag === false ) {
       if(this.props.data) {
         let data = this.props.data;
         this.setState({fields: data})
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    if(this.props.data) {
      let data = this.props.data;
      this.setState({fields: data})
    }
    this.setState({isLoaded: true})
  }

  render() {
    let informazioniLabel = (this.state.iscrizioneGiaInviata) ? "Per confermare e inviare la modifica della domanda cliccare su \"Invia modifica\"" : "Per confermare e inviare la domanda cliccare su \"Invia iscrizione\"";
    let pulsanteLabel = (this.state.iscrizioneGiaInviata) ? "Invia modifica" : "Invia iscrizione";
    if (this.state.isLoaded)
      return (
        <>
          <GenericCard cardHeader={pulsanteLabel} cardBody={
            <div>
              <div>
                <Label>{informazioniLabel}</Label>
              </div>
              <div className='col-lg-8 offset-lg-4 p-4'>
                <GenericButton label={pulsanteLabel} onClickEvent={() => this.props.inviaIscrizione(this.state.fields)} color={'primary'} />
              </div>
            </div>
            }>
          </GenericCard>
        </>
      )
    else return (<><GenericSpinner /></>)
  }
}

export declare interface InvioProps {
  activeTab?: any;
  tabClick?: any;
  data?:any;
  prevData?:any;
  prevClicked?:any;
  processoIscrizione?: any;
  annullaClick?: any;
  modificaDatiFlag?: any;
  visualizzaFlag?: any;
  message?: any;
  inviaIscrizione?: any;
  tabNumber?: number;
  iscrizioneGiaInviata?: any;
}
