import React, { useEffect, useState } from 'react'
import { Checkbox, PageSection, Spinner, Title } from '@patternfly/react-core'

import { generateRequestFilter, generateRequestSort } from 'src/app/utils/ApiUtils'
import { GenericAsyncTableData, RequestData } from 'src/backoffice/app/components/GenericAsyncTableData'
import {
  PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP,
  PATH_TO_BO_CONCEDI_DEROGA,
  PATH_TO_BO_LISTA_ISCRITTI_ORDINE
} from 'src/app/utils/RoutesConstants'

import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { getAllEnti, TIPO_ENTE } from 'src/model/EnteFormazione'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { Button } from 'design-react-kit'

const Spacer = () => (
  <>
    <br></br>
  </>
)

let lastRequest = null

const ListaIscrittiOrdine: React.FunctionComponent = (props: any) => {
  const myPath = {
    label: 'Ripartizione dei CFP e concessione proroghe',
    link: PATH_TO_BO_LISTA_ISCRITTI_ORDINE,
  }
  const [enti, setListEnti] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<{ [key: string]: boolean }>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [dirty, setDirty] = useState<boolean>(false)

  useEffect(() => {
    getAllEnti()
      .then((res) => {
        setListEnti(res)
      })
      .catch(console.error)
  }, [])

  const gettableData = async (request) => {
    try {
      const { data } = await getFormazioneResourceApi().msfoFormazioneListaIscrittiOrdineFilterableGet(
        generateRequestFilter(request.filters),
        request.pageSize,
        request.currentPage,
        generateRequestSort(request.sortColumn, request.sortDirection),
        getDefaultAxiosConfig()
      )
      return {
        items: data.items || [],
        total: data.count || 0,
      }
    } catch (e: any) {
      return {
        items: [],
        total: 0,
      }
    }
  }

  const loadData = async (request: RequestData) => {
    lastRequest = request
    return await gettableData(request)
  }

  const filters: any = [
    { label: 'Cognome', field: 'cognome', type: 'input', operator: 'LIKE' },
    { label: 'Nome', field: 'nome', type: 'input', operator: 'LIKE' },
    { label: 'Codice Fiscale', field: 'cf', type: 'input', operator: 'LIKE' },
  ]

  const goToRipartizioneAutomaticaCFP = () => {
    props.history.push(PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP)
  }

  const selezionaTutti = async () => {
    setLoading(true)
    const { data } = await getFormazioneResourceApi().msfoFormazioneListaIscrittiOrdineFilterableGet(
      generateRequestFilter(lastRequest.filters),
      -1,
      -1,
      generateRequestSort(lastRequest.sortColumn, lastRequest.sortDirection),
      getDefaultAxiosConfig()
    )

    for (const item of data.items) {
      selectedUsers[item.cf] = true
    }
    setSelectedUsers(selectedUsers)
    setLoading(false)
  }

  const deselezionaTutti = async () => {
    setLoading(true)
    setSelectedUsers({})

    setTimeout(() => {
      setLoading(false)
    }, 10)
  }

  const renderSelectColumn = (row) => {
    const checked = !!selectedUsers[row.cf]
    return (
      <Checkbox
        key={'select-' + row.id_soggetto}
        id={'select-' + row.id_soggetto}
        onClick={() => {
          selectedUsers[row.cf] = !checked
          setSelectedUsers(selectedUsers)
          setDirty(!dirty)
        }}
        checked={checked}
      />
    )
  }

  const concediDerogaMultipla = async () => {
    const cf_list = Object.keys(selectedUsers).join(',')
    props.history.push(PATH_TO_BO_CONCEDI_DEROGA + '?cf=' + cf_list)
  }

  if (TokenStore.getInstance().isCurrentAccountCONAF()) {
    filters.push({
      label: 'Ordine di appartenenza',
      field: 'ordine_territoriale_competente',
      type: 'select',
      options: Object.keys(enti)
        .filter((code) => enti[code].tipo === TIPO_ENTE.ORDINE)
        .map((code) => {
          return {
            label: enti[code].nome,
            value: code,
          }
        }),
    })
  }

  let oneSelected = false
  for (const key in selectedUsers) {
    if (selectedUsers[key]) {
      oneSelected = true
      break
    }
  }

  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />
        <div className="row">
          {!oneSelected && (
            <div className="col-6">
              <Button
                color="primary"
                onClick={() => {
                  selezionaTutti()
                }}
              >
                Seleziona tutti
              </Button>
            </div>
          )}
          {oneSelected && (
            <div className="col-6">
              <Button
                color="primary"
                onClick={() => {
                  deselezionaTutti()
                }}
              >
                Deseleziona tutti
              </Button>
            </div>
          )}
          <div className="col-3">
            <Button
              color="primary"
              onClick={() => {
                goToRipartizioneAutomaticaCFP()
              }}
            >
              Ripartizione CFP
            </Button>
          </div>
          <div className="col-3">
            <Button
              color="primary"
              disabled={!oneSelected}
              onClick={() => {
                concediDerogaMultipla()
              }}
            >
              Concedi proroga
            </Button>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <GenericAsyncTableData
              tableKey={'lista.assegnazione.cfp'}
              emptyMessage={'Nessun iscritto trovato'}
              // TODO check fields for iscritti all'ordine
              filters={filters}
              columns={[
                { label: 'Seleziona', field: '', isSortable: false, render: renderSelectColumn },
                { label: 'Cognome', field: 'cognome', isSortable: true },
                { label: 'Nome', field: 'nome', isSortable: true },
                { label: 'Codice Fiscale', field: 'cf', isSortable: true },
                { label: 'Ordine di appartenenza', field: 'ordine_territoriale_competente', isSortable: true },
              ]}
              getRows={loadData}
            />
          </>
        )}
      </div>
    </PageSection>
  )
}

export default ListaIscrittiOrdine
