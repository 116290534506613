import cubejs, { ResultSet } from '@cubejs-client/core'
import { getCubesResourceApi, getDefaultAxiosConfig } from 'src/app/utils/ManagerRestGateway'

export const CUBEJS_API_URL = String(process.env.REACT_APP_CUBE_API_URL);
const jsonata = require('jsonata');



/**
 * GET cubejs api interface
 * @returns
 *
 */
export function scube(){
    //cubejs api token promise
    let apiTokenPromise;

    const cubejsApi = cubejs(
        () => {
          if (!apiTokenPromise) {
            apiTokenPromise = getCubesResourceApi().msgaCubesTokenPost("",getDefaultAxiosConfig())
            .then((r) => {
                //;
                //ha to be check control of token
                //r.data["token"];
                return r.data["token"];
            } )
            .catch((r) => {
               let er = r;
               return er;
            })
            ;

          }
          return apiTokenPromise;
        },
        { apiUrl: CUBEJS_API_URL }
      );
      return cubejsApi
}

export function cquery(query,pivot,callback){
    scube().load(query).then(async (data: ResultSet) => {
        //;
        try{
            const dtdata = await jsonata(pivot).evaluate(data.rawData());
            callback(dtdata);
        }catch(e){
            callback([]);
        }

      })
}


