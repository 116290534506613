import isMobile from 'ismobilejs'


/**
 * @description Consente di capire se il dispositivo è mobile o meno
 * @returns boolean true/false
 */
export function isCurrentMobileDevice() {
    if (isMobile(window.navigator).any === true)
        return true
    else
        return false
}
