import { FormGroup, Input, Label } from 'design-react-kit'
import React from 'react'
import { isEmptyCheckbox } from 'src/utilities/utility'

class GenericCheckbox extends React.Component<GeneralCheckboxProps, any>{
    constructor(props) {
        super(props);
        this.state = {
            isInvalid: undefined,
            errorMessage: ""
        }
    }

    messageAlertContentError = { hasError: true, id: this.props.id, isReadonly: false, isDisabled: this.props.isDisabled, stepTab: this.props.currentStep, value: this.props.defaultChecked, label: this.props.title  }
    messageAlertContentSuccess = { hasError: false, id: this.props.id, isReadonly: false, isDisabled: this.props.isDisabled, stepTab: this.props.currentStep, value: this.props.defaultChecked, label: this.props.title  }

    componentDidMount(): void {
        if (this.props.errorMessageAlert !== undefined) {
            if (this.props.isRequired && !this.props.defaultChecked) {
                this.props.errorMessageAlert(this.messageAlertContentError)
            } else {
                this.props.errorMessageAlert(this.messageAlertContentSuccess)
            }
            if (this.props.defaultChecked)
                this.props.errorMessageAlert(this.messageAlertContentSuccess)
        }
    }

    validationForm = (event): void => {
        this.setState({ event: event.target.checked })
        this.messageAlertContentError['value'] = event.target.checked
        this.messageAlertContentSuccess['value'] = event.target.checked
        if (this.props.errorMessageAlert !== undefined)
            if (isEmptyCheckbox(event.target) && this.props.isRequired) {
                this.setState({ isInvalid: true, errorMessage: "Campo obbligatorio" })
                this.props.errorMessageAlert(this.messageAlertContentError)
            } else {
                this.setState({ isInvalid: false, errorMessage: "" })
                this.setState({ isInvalid: false })
                this.props.errorMessageAlert(this.messageAlertContentSuccess)
            }
    }

    componentDidUpdate(prevProps: Readonly<GeneralCheckboxProps>): void {
        if (this.props.itemIdDeleted !== prevProps.itemIdDeleted)
            this.props.errorMessageAlert({ hasError: false, id: this.props.itemIdDeleted, isReadonly: false, isDisabled: false, stepTab: this.props.currentStep, value: this.props.defaultChecked, label: this.props.title  })

        if (this.props.isRequired !== prevProps.isRequired)
            this.componentDidMount()
    }

    componentWillUnmount(): void {
        this.componentDidMount()
    }

    render() {
        const { isInvalid, errorMessage } = this.state
        return (
            <>
                <small hidden={!isInvalid} className={"text-danger"}>{errorMessage}</small>
                <FormGroup onChange={this.validationForm} label={this.props.id} check hidden={this.props.isHidden}>
                    <Input id={this.props.id}
                        type='checkbox'
                        defaultChecked={this.props.defaultChecked}
                        onChange={this.props.onChange}
                        disabled={this.props.isDisabled}
                        readOnly={this.props.isReadOnly}
                        checked={this.props.isChecked}
                        onLoad={this.props.onLoad}
                        title={this.props.title}
                    />
                    <Label className={this.props.className} for={this.props.id} check color="primary">{this.props.label} </Label>
                </FormGroup>
            </>
        );
    }
}

export declare interface GeneralCheckboxProps {
    label?: any;
    isDisabled?: boolean;
    defaultValue?: boolean;
    defaultChecked?: boolean;
    onChange?: any;
    id?: any;
    isChecked?: boolean;
    className?: string;
    isRequired?: boolean;
    errorMessageAlert?: any;
    isHidden?: boolean;
    currentStep?: number;
    isReadOnly?: boolean;
    itemIdDeleted?: any;
    onLoad?: any;
    title?: string;
}

export default GenericCheckbox;
