import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();

export class FormDatiGeneraliOrdineFederazione extends React.Component<GeneralFormDatiGeneraliOrdineFederazioneProps, any> {
    constructor(props) {
        super(props)
        this.state = {
            returnedObject: props.returnedObject[0],
            messaggio: props.messaggio
        }
    }
    render() {
        const { returnedObject, messaggio } = this.state
        return (
            <>
                {(returnedObject === '' && messaggio === '') ?
                    (
                        <GenericSpinner />
                    )
                    : (messaggio !== '' ? (
                        <EmptyState variant="full">
                            <EmptyStateIcon icon={ExclamationTriangleIcon} />
                            <EmptyStateBody>
                                Non è stato possibile recuperare i dati. Contattare l'assistenza.
                            </EmptyStateBody>
                        </EmptyState>
                    ) : (
                        <div>
                            <Form>
                                <div className='row'>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idDenominazione'} label={"Denominazione"} value={returnedObject.denominazioneGruppo ? returnedObject.denominazioneGruppo : "-"} isReadOnly />
                                    </div>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idCF'} label={"Codice fiscale"} value={returnedObject.cf ? returnedObject.cf : "-"} isReadOnly />
                                    </div>
                                    <div className={!isMobileDivice? 'col-6' : 'col-12' }>
                                        <GenericTextInput type={'text'} className='w-75' id={'idPIVA'} label={"Partiva IVA"} value={returnedObject.partitaIva ? returnedObject.partitaIva : "-"} isReadOnly />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )
                    )}
            </>
        )
    }
}

export declare interface GeneralFormDatiGeneraliOrdineFederazioneProps {
    returnedObject?: any
    messaggio?: string
}

