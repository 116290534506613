import React from 'react'
import { Select as DefaultSelect } from 'design-react-kit'
import styled from 'styled-components'
import InputGroup from './InputGroup'

const SelectStyled = styled.div`
  width: 100%;

  div[class$='menu'] {
    z-index: 400;
  }

  div[class$='container'] {
    width: 100%;
  }

  div[class$='ValueContainer'] {
    padding-right: 80px;
  }

  div[class$='singleValue'] {
    max-width: 90%;
  }

  .select-label {
    top: -30px;
  }

  .select-input {
    flex-grow: 1;
  }
`

class GenericSelect extends React.Component<GeneralSelectProps, any> {
  isMount = false

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.isMount = true
    if (this.props.onInit) {
      this.props.onInit(this)
    }
  }

  componentWillUnmount() {
    this.isMount = false
  }

  onChange = (value) => {
    if (this.props.onChange) {
      const code = this.props.name ? this.props.name : this.props.id
      this.props.onChange(code, value)
    }
  }

  render() {
    const classSelect = 'select-input ' + (this.props.className || '')
    return (
      <SelectStyled className={this.props.className}>
        <InputGroup {...this.props} type="select" classWrap={this.props.isMulti ? 'multi' : 'single'}>
          <DefaultSelect
            className={this.props.className}
            isOptionSelected={this.props.isOptionSelected}
            inputId={this.props.elementId}
            id={this.props.id}
            name={this.props.name}
            onChange={this.onChange}
            defaultValue={this.props.defaultValue}
            placeholder={this.props.placeholder}
            isDisabled={this.props.isDisabled}
            isClearable={this.props.isClearable}
            isSearchable={this.props.isSearchable}
            isMulti={this.props.isMulti}
            value={this.props.value}
            inputValue={this.props.inputValue}
            options={this.props.defaultOptions}
            controlShouldRenderValue
          />
        </InputGroup>
      </SelectStyled>
    )
  }
}

export declare interface GeneralSelectProps {
  elementId?: any
  name?: any
  placeholder?: any
  value?: any
  inputValue?: any
  defaultValue?: any
  ariaLabel?: any
  label?: any
  defaultOptions?: any
  isDisabled?: any
  isClearable?: any
  isSearchable?: any
  isMulti?: any
  className?: any
  onChange?: any
  id?: string
  isOptionSelected?: any
  isRequired?: boolean
  errorMessageAlert?: any
  isHidden?: boolean
  modificaDatiFlag?: boolean
  currentStep?: any
  /**
   * Contiene l'id dell'elemento da rendere non obbligatorio in fase di validazione una volta eliminato
   * Inserire l'id dell'elemento seguito dall'id dell'elemento selezionato
   * Es. se l'id elemento è uguale a {pippo + id} --> scrivere itemIdDeleted={"pippo" + idElementoEliminato}
   */
  itemIdDeleted?: any
  maxLabelLength?: number

  errorMessage?: string
  onInit?: any
}

export default GenericSelect
