import { Title } from '@patternfly/react-core'
import React, { useState } from 'react'
import { Button } from 'design-react-kit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import styled from 'styled-components'
import { FlexDiv } from '../pages/formazione/DettaglioRichiestaEsonero'
import close from '../../images/x.png'
import { LABEL_STATI } from 'src/model/Esoneri'

export const RigaStilizzata = styled.tr`
  padding: 15px 20px;
`

export const ColonnaStilizzata = styled.td`
  padding: 0px 10px;
  max-width: 160px;
`
const HistoryModal: React.FunctionComponent<HistoryModalProps> = (props) => {
  const [historyData, setHistoryData] = useState<any>([])
  const [isMounted, setIsMounted] = useState<boolean>(false)

  const ModalBackground = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
    left: 0;
  `

  const ModalContent = styled.div`
    position: relative;
    top: 15%;
    z-index: 6;
    max-width: 90vw;
    max-height: 400px;
    overflow-y: auto;
    deisplay: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 24px;
  `

  const ModalTable = styled.div`
    display: grid;
    grid-template-columns: repeat(${props.tableHead.length}, minmax(0, 1fr));
    gap: 8px;
  `

  const loadData = async () => {
    const dati = await getFormazioneResourceApi().msfoFormazioneStoricoEsoneriGet(undefined, props.id, 1, -1, undefined, getDefaultAxiosConfig())
    setIsMounted(true)
    setHistoryData(dati.data.items ? dati.data.items : [])
  }

  !isMounted && loadData()

  return (
    <>
      <ModalBackground hidden={!props.isModalOpen}>
        <ModalContent>
          <FlexDiv>
            <Title headingLevel={'h2'} style={{ marginBottom: 32 }}>
              Storico richiesta
            </Title>
            <Button
              onClick={() => {
                props.toggleModal()
              }}
            >
              <img src={close} alt="" />
            </Button>
          </FlexDiv>
          <ModalTable>
            {props.tableHead.map((colonna) => {
              return <p>{colonna}</p>
            })}
            {historyData.length && (
              <>
                {historyData.map((dato) => {
                  const columns = props.dataFields.map((campo, index) => {
                    if (index === 3) {
                      return <p>{LABEL_STATI[dato[campo]]}</p>
                    } else {
                      return <p>{dato[campo]}</p>
                    }
                  })

                  // columns.push(
                  //   <Button
                  //     hidden={!props.isButtonActive}
                  //     onClick={() => {
                  //       props.setHistoricData(dato)
                  //     }}
                  //   >
                  //     Import
                  //   </Button>
                  // )
                  return columns
                })}
              </>
            )}
          </ModalTable>
        </ModalContent>
      </ModalBackground>
    </>
  )
}

export declare interface HistoryModalProps {
  isModalOpen: boolean
  toggleModal: () => void
  setHistoricData: (dato) => any
  id: any
  tableHead: string[]
  isButtonActive: boolean
  dataFields: string[]
}

export default HistoryModal
