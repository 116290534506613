import * as React from 'react'
import GenericHeaderCenter from 'src/frontend/app/components/GenericHeaderCenter'
import GenericHeaderNav from 'src/frontend/app/components/GenericHeaderNav'
import GenericHeaderSlim from 'src/frontend/app/components/GenericHeaderSlim'
import AppRouter from './AppRouter'
import GenericFooter from 'src/frontend/app/components/GenericFooter'

const AppWithoutLogin: React.FunctionComponent = () => {
  
  const [modalOpen, setModalOpen] = React.useState(true);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  
  /**
   * Ritorna l'header e la pagina iniziale
   */
  return <>
    <GenericHeaderSlim />
    <GenericHeaderCenter />
    <GenericHeaderNav />
    {/* <GenericModal
        title={"Messaggio informativo"}
        text={"Si informa che per il giorno XX/XX/XXXX la piattaforma SIDAF sarà in manutenzione. Ci scusiamo per eventuali disagi."}
        modalOpen={modalOpen}
        closeButton={closeModal}
    /> */}
    <AppRouter/>
    <GenericFooter/>
  </>

};

export default AppWithoutLogin;
