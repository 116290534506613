import React from 'react'
import GenericCard from '../../../components/GenericCard'
import GenericInput from '../../../components/GenericInput'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'
import { formatDays } from 'src/backoffice/app/utils/utils'

export default class DatiIscrizione extends React.Component<DatiIscrizioneProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      fields: {},
    }
  }
  componentDidMount(): void {
    const { data, visualizzaFlag } = this.props
    if (visualizzaFlag && data) {
      const anzianitaSezioneA = formatDays(data['anzianitaSezioneA'])
      const anzianitaSezioneB = formatDays(data['anzianitaSezioneB'])
      const anzianitaTot = formatDays(Number(data['anzianitaSezioneA']) + Number(data['anzianitaSezioneB']))

      this.setState({
        fields: data,
        isLoaded: true,
        anzianitaSezioneA: anzianitaSezioneA,
        anzianitaSezioneB: anzianitaSezioneB,
        anzianitaTot: anzianitaTot,
      })
    }
  }
  render() {
    /*************************** Form di Visualizzazione ************************/

    const formVisualizza = [
      <GenericCard
        key="datiIscrizione"
        cardHeader="Dati Iscrizione"
        cardBody={
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="numeroIscrizione"
                name={'numeroIscrizione'}
                defaultValue={this.props.data['numeroIscrizione'] ? this.props.data['numeroIscrizione'] : '-'}
                label="Numero Iscrizione"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'date'}
                isDisabled
                id="dataIscrizione"
                name="dataIscrizione"
                defaultValue={this.props.data['dataIscrizione'] ? this.props.data['dataIscrizione'] : '-'}
                label="Data iscrizione"
                placeholder="data iscrizione"
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="deliberaIscrizione"
                name={'deliberaIscrizione'}
                defaultValue={this.props.data['deliberaIscrizione'] ? this.props.data['deliberaIscrizione'] : '-'}
                label="Delibera Iscrizione"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="numeroTimbro"
                name={'numeroTimbro'}
                defaultValue={this.props.data['numeroTimbro'] ? this.props.data['numeroTimbro'] : '-'}
                label="Numero Timbro"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'date'}
                isDisabled
                id="dataInizValiTimbro"
                name={'dataInizValiTimbro'}
                defaultValue={this.props.data['dataInizValiTimbro'] ? this.props.data['dataInizValiTimbro'] : '-'}
                label="Data inizio validità timbro"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'date'}
                isDisabled
                id="dataFineValiTimbro"
                name={'dataFineValiTimbro'}
                defaultValue={
                  this.props.data['dataFineValiTimbro'] && this.props.data['dataFineValiTimbro'] !== '9999-12-31' ? this.props.data['dataFineValiTimbro'] : '-'
                }
                label="Data fine validità timbro"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="numeroMartello"
                name={'numeroMartello'}
                defaultValue={this.props.data['numeroMartello'] ? this.props.data['numeroMartello'] : '-'}
                label="Numero Martello"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'date'}
                isDisabled
                id="dataInizioValiMartello"
                name={'dataInizioValiMartello'}
                defaultValue={this.props.data['dataInizioValiMartello'] ? this.props.data['dataInizioValiMartello'] : '-'}
                label="Data inizio validità martello"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'date'}
                isDisabled
                id="dataFineValiMartello"
                name={'dataFineValiMartello'}
                defaultValue={
                  this.props.data['dataFineValiMartello'] && this.props.data['dataFineValiMartello'] !== '9999-12-31'
                    ? this.props.data['dataFineValiMartello']
                    : '-'
                }
                label="Data fine validità martello"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="esoneroPagaQuotaOrdine"
                name={'esoneroPagaQuotaOrdine'}
                defaultValue={this.props.data['esoneroPagaQuotaOrdine'] ? this.props.data['esoneroPagaQuotaOrdine'].label : '-'}
                label="Esonero Pagamento"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="anzianita"
                name={'anzianita'}
                defaultValue={this.state.anzianitaTot}
                label="Anzianità"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'} hidden={this.props.data.sezioneRichiesta?.codice === 'B'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="anzianitaSezioneA"
                name={'anzianitaSezioneA'}
                defaultValue={this.state.anzianitaSezioneA}
                label="Anzianità Sezione A (anni)"
                placeholder=" "
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'} hidden={this.props.data.sezioneRichiesta?.codice === 'A'}>
              <GenericInput
                type={'text'}
                isDisabled
                id="anzianitaSezioneB"
                name={'anzianitaSezioneB'}
                defaultValue={this.state.anzianitaSezioneB}
                label="Anzianità Sezione B (anni)"
                placeholder=" "
              />
            </div>
          </div>
        }
      ></GenericCard>,
    ]

    return <>{this.props.visualizzaFlag && this.state.isLoaded ? formVisualizza : null}</>
  }
}

export declare interface DatiIscrizioneProps {
  activeTab?: any
  tabClick?: any
  visualizzaFlag?: any
  data?: any
  tabNumber?: any
}
