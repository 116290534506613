import React from 'react'
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListGroup,
  DescriptionListTerm
} from '@patternfly/react-core'

class GenericDescriptionList extends React.Component<GeneralDescriptionListProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      isHidden: props.isHidden
    };
  }

  render(){
    const { isHidden } = this.state;
    return(

  <DescriptionList isAutoColumnWidths columnModifier={{default: "3Col"}} hidden={isHidden}>
    {this.props.items?.map((item, index)=>{return(
    <DescriptionListGroup key={index}>
        <DescriptionListTerm>{item.label}</DescriptionListTerm>
        <DescriptionListDescription>{item.value}</DescriptionListDescription>
    </DescriptionListGroup>)})
    }
  </DescriptionList>
    );
  }
}

export declare interface GeneralDescriptionListProps {
  items?: any;
  isHidden?: any;
}

export default GenericDescriptionList;
