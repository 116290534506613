import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { getAreeProfessionali, getSdaf, getTipologieAttivita } from 'src/hooks/useReqOptions'

import { getAllProvince } from 'src/processes/Province'
import { getComuniByProvinciaMsga } from 'src/processes/Comuni'
import { getBooleanToConvertToBigInt, getValueAtPath } from 'src/utilities/utility'
import {
  ALLEGATI_AGENZIA_FILTERED,
  ALLEGATI_AGENZIA_PROROGA,
  AMBITI_TERRITORIALI_OPTION_FROM_VALUES,
  attivitaDisponibili,
  MODALITA_DISPONIBILI_OPTION_FROM_VALUES,
  TIPI_TITOLO_DI_STUDIO,
  TIPOLOGIA_EVENTO_OPTION_FROM_VALUES,
  TIPOLOGIE_ACCREDITAMENTO_OPTION_FROM_VALUES,
  TIPOLOGIE_ISCRIZIONI_OPTIONS
} from 'src/formazione'
import moment from 'moment'
import { Riga } from 'src/backoffice/app/pages/formazione/CreditiFormativi'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

const toDateFormat = (date) => {
  if (!date) return null
  return moment(date).format('YYYY-MM-DD')
}

// TODO do it better
const provinciaMap: { [key: string]: any } = {}
const fillProvince = async () => {
  try {
    const { data } = await getAllProvince()
    for (const provincia of data || []) {
      const prov: any = provincia
      provinciaMap[prov['denominazioneUnitaTerritorialeSovracomunale']] = prov['siglaAutomobilistica']
    }
  } catch (err) {
    console.error(err)
  }
}

export const requestMapperFormatore = async (data: any, stato: any) => {
  const formatore = {
    id: data?.id || undefined,
    stato: stato,
    nome: data?.nome || '',
    cognome: data?.cognome || '',
    cod_fisc: data?.cod_fisc || '',
    luogo_nas: data?.luogo_nasc || '',
    data_nas: data?.data_nasc || '',
    nazione_nasc: data?.nazione_nasc?.value || '',
    provincia_nasc: data?.provincia_nasc?.label || '',
    indirizzo_res: data?.indirizzo_res || '',
    civico_res: data?.civico_res || '',
    cap_res: data?.cap_res || '',
    nazione_res: data?.nazione_res?.value || '',
    provincia_res: data?.provincia_res?.label || '',
    comune_res: data?.comune_res?.label || '',
    is_iscritto_ordine: getBooleanToConvertToBigInt(data?.is_iscritto_ordine) || 0,
    dati_iscriz_ordine: data?.dati_iscr_ordine || '',
    tipologia_iscrizione: data?.tipologia_iscrizione?.label || '',
    titolo_accademico: data?.titolo_accademico || '',
    titolo_professionale: data?.titolo_professionale || '',
    altro_titolo: data?.altro_titolo || '',
    possesso_diploma: getBooleanToConvertToBigInt(data?.possesso_diploma) || 0,
    tipo_diploma: data?.tipo_diploma || '',
    possesso_laurea: getBooleanToConvertToBigInt(data?.possesso_laurea) || 0,
    tipo_laurea: data?.tipo_laurea || '',
    possesso_laurea_magistrale: getBooleanToConvertToBigInt(data?.possesso_laurea_magistrale) || 0,
    tipo_laurea_magistrale: data?.tipo_laurea_magistrale || '',
  }

  const info = {
    attivita_svolte: !data?.att_formative
      ? []
      : data?.att_formative.map((att) => {
          return {
            sdaf_riferimento: att?.sdaf?.id || '',
            att_form_tipo: att?.tipo || '',
            att_form_sdaf_da: att?.svolto_da || '',
            att_form_sdaf_a: att?.svolto_a || '',
            att_form_ente_erog: att?.ente_erogante || '',
            att_form_descr: att?.desc_attivita || '',
          }
        }),
    esperienze_professionali: !data?.esp_prof
      ? []
      : data?.esp_prof.map((esp) => {
          return {
            sdaf_riferimento: esp?.sdaf?.id || '',
            esp_prof_lavoro: esp?.lavoro || '',
            esp_prof_sdaf_da: esp?.da || '',
            esp_prof_sdaf_a: esp?.al || '',
            esp_prof_committente: esp?.committente || '',
            esp_prof_descr: esp?.desc_attivita || '',
          }
        }),
    esperienze_didattica: !data?.esp_didattica
      ? []
      : data?.esp_didattica.map((esp) => {
          return {
            sdaf_riferimento: esp?.sdaf?.id || '',
            esp_didattica_docenza: esp?.docenza || '',
            esp_didattica_sdaf_da: esp?.da || '',
            esp_didattica_sdaf_a: esp?.al || '',
            esp_didattica_committente: esp?.committente || '',
            esp_didattica_descr_docenza: esp?.desc_attivita || '',
          }
        }),
    esperienze_scientifiche: !data?.esp_scientifica
      ? []
      : data?.esp_scientifica?.map((esp) => {
          return {
            sdaf_riferimento: esp?.sdaf?.id || '',
            esp_scienti_descr: esp?.desc_attivita || '',
            esp_scienti_sdaf_da: esp?.da || '',
            esp_scienti_sdaf_a: esp?.al || '',
            esp_scienti_committente: esp?.committente || '',
            esp_scienti_descr_att_ricerca: esp?.ricerca || '',
          }
        }),
    titoli: !data?.titoli
      ? []
      : data?.titoli?.map((titolo) => {
          return {
            sdaf_riferimento: titolo?.sdaf?.id || '',
            titoli_info: titolo?.titolo || '',
            titoli_tipo: titolo?.tipo?.value || 0,
            titoli_cons_sdaf_data_da: titolo?.conseg_da || '',
            titoli_cons_sdad_data_a: titolo?.conseg_a || '',
          }
        }),
  }

  return {
    formatore: formatore,
    formatoreInfo: info,
  }
}

const isSdafPresente = (sdafs, sdaf) => {
  return sdafs.find((findSdaf) => {
    return findSdaf.value == sdaf.id
  })
}

export const responseMapperFormatore = async (data: any, tipo?: any) => {
  const { formatore, formatoreInfo, logoAllegato } = data || {}

  const tipi_titoli = TIPI_TITOLO_DI_STUDIO
  const sdafs = await getSdaf()

  const sdafDaApprovare = []

  let ogg = {
    ...formatore,
    logoAllegato,
    titoli: formatoreInfo?.titoli?.map((titolo) => {
      const sdaf = sdafs.find((sdaf) => sdaf.id == titolo?.sdaf_riferimento)
      if (!isSdafPresente(sdafDaApprovare, sdaf)) {
        sdafDaApprovare.push({
          value: sdaf.id,
          label: sdaf.label,
        } as never)
      }

      return {
        conseg_a: titolo?.titoli_cons_sdad_data_a,
        conseg_da: titolo?.titoli_cons_sdad_data_a,
        tipo: tipi_titoli.find((tipo) => titolo?.titoli_tipo === tipo.value),
        titolo: titolo?.titoli_info,
        sdaf: sdaf,
      }
    }),
    att_formative: formatoreInfo?.attivita_svolte?.map((att) => {
      const sdaf = sdafs.find((sdaf) => sdaf.id == att?.sdaf_riferimento)
      if (!isSdafPresente(sdafDaApprovare, sdaf)) {
        sdafDaApprovare.push({
          value: sdaf.id,
          label: sdaf.label,
        } as never)
      }

      return {
        desc_attivita: att?.att_form_descr,
        ente_erogante: att?.att_form_ente_erog,
        svolto_a: att?.att_form_sdaf_a,
        svolto_da: att?.att_form_sdaf_da,
        tipo: att?.att_form_tipo,
        sdaf: sdafs.find((sdaf) => sdaf.id == att?.sdaf_riferimento),
      }
    }),
    esp_prof: formatoreInfo?.esperienze_professionali?.map((esp) => {
      const sdaf = sdafs.find((sdaf) => sdaf.id == esp?.sdaf_riferimento)
      if (!isSdafPresente(sdafDaApprovare, sdaf)) {
        sdafDaApprovare.push({
          value: sdaf.id,
          label: sdaf.label,
        } as never)
      }

      return {
        da: esp?.esp_prof_sdaf_da,
        al: esp?.esp_prof_sdaf_a,
        committente: esp?.esp_prof_committente,
        lavoro: esp?.esp_prof_lavoro,
        desc_attivita: esp?.esp_prof_descr,
        sdaf: sdafs.find((sdaf) => sdaf.id == esp?.sdaf_riferimento),
      }
    }),
    esp_didattica: formatoreInfo?.esperienze_didattica?.map((esp) => {
      const sdaf = sdafs.find((sdaf) => sdaf.id == esp?.sdaf_riferimento)
      if (!isSdafPresente(sdafDaApprovare, sdaf)) {
        sdafDaApprovare.push({
          value: sdaf.id,
          label: sdaf.label,
        } as never)
      }

      return {
        da: esp?.esp_didattica_sdaf_da,
        al: esp?.esp_didattica_sdaf_a,
        committente: esp?.esp_didattica_committente,
        docenza: esp?.esp_didattica_docenza,
        desc_attivita: esp?.esp_didattica_descr_docenza,
        sdaf: sdafs.find((sdaf) => sdaf.id == esp?.sdaf_riferimento),
      }
    }),
    esp_scientifica: formatoreInfo?.esperienze_scientifiche?.map((esp) => {
      const sdaf = sdafs.find((sdaf) => sdaf.id == esp?.sdaf_riferimento)
      if (!isSdafPresente(sdafDaApprovare, sdaf)) {
        sdafDaApprovare.push({
          value: sdaf.id,
          label: sdaf.label,
        } as never)
      }

      return {
        da: esp?.esp_scienti_sdaf_da,
        al: esp?.esp_scienti_sdaf_a,
        committente: esp?.esp_scienti_committente,
        ricerca: esp?.esp_scienti_descr_att_ricerca,
        desc_attivita: esp?.esp_scienti_descr,
        sdaf: sdafs.find((sdaf) => sdaf.id == esp?.sdaf_riferimento),
      }
    }),
  }

  ogg.data_nasc = ogg.data_nas
  ogg.luogo_nasc = ogg.luogo_nas
  ogg.provincia_nasc = { label: ogg.provincia_nasc, value: ogg.provincia_nasc }
  ogg.provincia_res = { label: ogg.provincia_res, value: ogg.provincia_res }
  ogg.nazione_res = { label: ogg.nazione_res, value: ogg.nazione_res }
  ogg.nazione_nasc = { label: ogg.nazione_nasc, value: ogg.nazione_nasc }
  ogg.comune_res = { label: ogg.comune_res, value: ogg.comune_res }
  ogg.dati_iscr_ordine = ogg.dati_iscriz_ordine
  ogg.tipologia_iscrizione = TIPOLOGIE_ISCRIZIONI_OPTIONS.find((opt) => opt.label === ogg.tipologia_iscrizione)
  ogg.sdafDaApprovare = sdafDaApprovare

  return ogg
}

export const agenziaToModuloMapper = (data: any, file: string) => {
  const {
    datiAnagrafici,
    datiSedeLegale,
    datiRappresentanteLegale,
    datiResponsabileAF,
    datiAccreditamento,
    datiDichiarazioni,
    datiComitatoTecnico,
    datiAllegati,
    tipoAgForm,
  } = data || {}

  let fields: any = []

  switch (file) {
    case `Mod_20RIVISTE.pdf`:
      fields = [
        {
          label: `Il sottoscritto`,
          value: `${datiRappresentanteLegale?.nome} ${datiRappresentanteLegale?.cognome}`,
        },
        {
          label: `codice fiscale`,
          value: `${datiRappresentanteLegale?.codiceFiscale}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale?.luogoNascita ? datiRappresentanteLegale?.luogoNascita : 'EEP'}`,
        },
        {
          label: `Comune`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale?.dataNascita}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `residente`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Ragione sociale`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `codice fiscale_2`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `sede legale`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `Comune_2`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Telefono`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `Fax`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_2`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        {
          label: `CODICE ISSN`,
          value: `${datiAnagrafici?.cod_issn ? datiAnagrafici?.cod_issn : ''}`,
        },
        {
          label: `Comitato 1`,
          value: `${datiComitatoTecnico?.comitato[0]?.titolo ? datiComitatoTecnico?.comitato[0]?.titolo : ''} ${
            datiComitatoTecnico?.comitato[0]?.nome_cognome ? datiComitatoTecnico?.comitato[0]?.nome_cognome : ''
          }`,
        },
        {
          label: `Comitato 2`,
          value: `${datiComitatoTecnico?.comitato[1]?.titolo ? datiComitatoTecnico?.comitato[1]?.titolo : ''} ${
            datiComitatoTecnico?.comitato[1]?.nome_cognome ? datiComitatoTecnico?.comitato[1]?.nome_cognome : ''
          }`,
        },
        {
          label: `Comitato 3`,
          value: `${datiComitatoTecnico?.comitato[2]?.titolo ? datiComitatoTecnico?.comitato[2]?.titolo : ''} ${
            datiComitatoTecnico?.comitato[2]?.nome_cognome ? datiComitatoTecnico?.comitato[2]?.nome_cognome : ''
          }`,
        },
        {
          label: `Comitato 4`,
          value: `${datiComitatoTecnico?.comitato[3]?.titolo ? datiComitatoTecnico?.comitato[3]?.titolo : ''} ${
            datiComitatoTecnico?.comitato[3]?.nome_cognome ? datiComitatoTecnico?.comitato[3]?.nome_cognome : ''
          }`,
        },
        {
          label: `Comitato 5`,
          value: `${datiComitatoTecnico?.comitato[4]?.titolo ? datiComitatoTecnico?.comitato[4]?.titolo : ''} ${
            datiComitatoTecnico?.comitato[4]?.nome_cognome ? datiComitatoTecnico?.comitato[4]?.nome_cognome : ''
          }`,
        },
        {
          label: `Data`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
        {
          label: `${datiAccreditamento?.settoriDisciplinari?.cod + '  ' + datiAccreditamento?.settoriDisciplinari?.titolo.replace(/  /g, ' ')}`.replace(
            /,/g,
            ''
          ),
          value: `On`,
        },
        {
          label: `${datiAccreditamento?.ambitoPubblicazione?.label}`,
          value: `On`,
        },
      ]
      break
    case `MOD_C.pdf`:
      fields = [
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale.nome} ${datiRappresentanteLegale.cognome}`,
        },
        {
          label: `undefined_2`,
          value: `${datiRappresentanteLegale.codiceFiscale ? datiRappresentanteLegale.codiceFiscale : ''}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale.comune?.value ? datiRappresentanteLegale.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined_3`,
          value: `${datiRappresentanteLegale.dataNascita ? datiRappresentanteLegale.dataNascita : ''}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale.nazione?.value ? datiRappresentanteLegale.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale.provincia?.value ? datiRappresentanteLegale.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_4`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `undefined_5`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_6`,
          value: ``,
        },
        {
          label: `Legale rappresentante di`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `undefined_7`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `undefined_8`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `undefined_9`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_10`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `undefined_11`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_12`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        // {
        //   label: `Casella di controllo32`,
        //   value: `Sì`,
        // },
        // {
        //   label: `Casella di controllo33`,
        //   value: `Sì`,
        // },
        {
          label: `Testo34`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
      ]
      break
    case `MOD_F.pdf`:
      fields = [
        {
          label: `Il sottoscritto`,
          value: `${datiRappresentanteLegale.nome} ${datiRappresentanteLegale.cognome}`,
        },
        {
          label: `codice fiscale`,
          value: `${datiRappresentanteLegale.codiceFiscale ? datiRappresentanteLegale.codiceFiscale : ''}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale.comune?.value ? datiRappresentanteLegale.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale.dataNascita ? datiRappresentanteLegale.dataNascita : ''}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale.nazione?.value ? datiRappresentanteLegale.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale.provincia?.value ? datiRappresentanteLegale.provincia?.value : 'EEP'}`,
        },
        {
          label: `residente`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `Comune`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Ragione sociale`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `codice fiscale_2`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `sede legale`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `Comune_2`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Telefono`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `Fax`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_2`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        // {
        //   label: `Pulsante di scelta8`,
        //   value: `Pulsante di scelta8`,
        // },
        {
          label: `Testo9`,
          value: ``,
        },
        {
          label: `Testo10`,
          value: ``,
        },
        {
          label: `Testo11`,
          value: ``,
        },
        {
          label: `Testo12`,
          value: ``,
        },
        {
          label: `Testo13`,
          value: ``,
        },
        {
          label: `Testo14`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
        // {
        //   label: `Casella di controllo15`,
        //   value: `Casella di controllo15`,
        // },
        // {
        //   label: `Casella di controllo16`,
        //   value: `Casella di controllo16`,
        // },
        // {
        //   label: `Casella di controllo17`,
        //   value: `Casella di controllo17`,
        // },
      ]
      break
    case `MOD_G.pdf`:
      fields = [
        {
          label: `Idoneità della polizza`,
          value: `${datiRappresentanteLegale.nome} ${datiRappresentanteLegale.cognome}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale.codiceFiscale ? datiRappresentanteLegale.codiceFiscale : ''}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale.comune?.value ? datiRappresentanteLegale.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined_2`,
          value: `${datiRappresentanteLegale.dataNascita ? datiRappresentanteLegale.dataNascita : ''}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale.nazione?.value ? datiRappresentanteLegale.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale.provincia?.value ? datiRappresentanteLegale.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_3`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `undefined_4`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_5`,
          value: ``,
        },
        {
          label: `di`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `undefined_6`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `undefined_7`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `undefined_8`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_9`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `Fax`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_10`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        {
          label: `IL MASSIMALE DI POLIZZA È`,
          value: ``,
        },
        {
          label: `undefined_11`,
          value: ``,
        },
        {
          label: `IL PERIODO DI VALIDITA DELLA POLIZZA È`,
          value: ``,
        },
        {
          label: `Il sottoscritto autorizza il trattamento dei dati contenuti nella presente domanda ai sensi del Dgls 1962003`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
      ]
      break
    case `MOD_D_Complesso.pdf`:
      fields = [
        {
          label: `Il sottoscritto`,
          value: `${datiRappresentanteLegale?.nome} ${datiRappresentanteLegale?.cognome}`,
        },
        {
          label: `codice fiscale`,
          value: `${datiRappresentanteLegale?.codiceFiscale}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale?.dataNascita}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_2`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `undefined_3`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Ragione sociale`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `codice fiscale_2`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `undefined_4`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `undefined_5`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_6`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `undefined_7`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_8`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        {
          label: `Data`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
        // {
        //   label: `Pulsante di scelta1`,
        //   value: `Sì`,
        // },
        // {
        //   label: `Casella di controllo2`,
        //   value: `Sì`,
        // },
        // {
        //   label: `Casella di controllo3`,
        //   value: `Sì`,
        // },
      ]
      break
    case `MOD_D_Semplice.pdf`:
      fields = [
        {
          label: `Il sottoscritto`,
          value: `${datiRappresentanteLegale?.nome} ${datiRappresentanteLegale?.cognome}`,
        },
        {
          label: `codice fiscale`,
          value: `${datiRappresentanteLegale?.codiceFiscale}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale?.dataNascita}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_2`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `undefined_3`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Ragione sociale`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `codice fiscale_2`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `undefined_4`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `undefined_5`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_6`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `undefined_7`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_8`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        {
          label: `Testo7`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
        // {
        //   label: `Pulsante di scelta1`,
        //   value: `Pulsante di scelta1`,
        // },
        // {
        //   label: `Casella di controllo2`,
        //   value: `Casella di controllo2`,
        // },
        // {
        //   label: `Casella di controllo3`,
        //   value: `Casella di controllo3`,
        // },
      ]
      break
    case `MOD_D_Totale.pdf`:
      fields = [
        {
          label: `Il sottoscritto`,
          value: `${datiRappresentanteLegale?.nome} ${datiRappresentanteLegale?.cognome}`,
        },
        {
          label: `codice fiscale`,
          value: `${datiRappresentanteLegale?.codiceFiscale}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale?.dataNascita}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_2`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `undefined_3`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Ragione sociale`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `codice fiscale_2`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `undefined_4`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `undefined_5`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `undefined_6`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `undefined_7`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_8`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        {
          label: `Data`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
        // {
        //   label: `Pulsante di scelta1`,
        //   value: `Pulsante di scelta1`,
        // },
        // {
        //   label: `Casella di controllo2`,
        //   value: `Casella di controllo2`,
        // },
        // {
        //   label: `Casella di controllo3`,
        //   value: `Casella di controllo3`,
        // },
      ]
      break
    case `MOD_E_Totale.pdf`:
    case `MOD_E_Complesso.pdf`:
      fields = [
        {
          label: `Il sottoscritto`,
          value: `${datiRappresentanteLegale?.nome} ${datiRappresentanteLegale?.cognome}`,
        },
        {
          label: `codice fiscale`,
          value: `${datiRappresentanteLegale?.codiceFiscale}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale?.dataNascita}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `residente`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `Comune`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Ragione`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `codice fiscale_2`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `sede legale`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `Comune_2`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Telefono`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `undefined_2`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_3`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        // {
        //   label: `Pulsante di scelta18`,
        //   value: `Pulsante di scelta18`,
        // },
        // {
        //   label: `Casella di controllo19`,
        //   value: `Casella di controllo19`,
        // },
        // {
        //   label: `Casella di controllo20`,
        //   value: `Casella di controllo20`,
        // },
        {
          label: `Testo21`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
      ]
      break
    case `MOD_E_Semplice.pdf`:
      fields = [
        {
          label: `Il sottoscritto`,
          value: `${datiRappresentanteLegale?.nome} ${datiRappresentanteLegale?.cognome}`,
        },
        {
          label: `codice fiscale`,
          value: `${datiRappresentanteLegale?.codiceFiscale}`,
        },
        {
          label: `natoa a`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `undefined`,
          value: `${datiRappresentanteLegale?.dataNascita}`,
        },
        {
          label: `Nazione`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `residente`,
          value: `${datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : ''}`,
        },
        {
          label: `N`,
          value: ``,
        },
        {
          label: `Cap`,
          value: `${datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : ''}`,
        },
        {
          label: `Comune`,
          value: `${datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_2`,
          value: `${datiRappresentanteLegale?.nazione?.value ? datiRappresentanteLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_2`,
          value: `${datiRappresentanteLegale?.provincia?.value ? datiRappresentanteLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Ragione`,
          value: `${datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : ''}`,
        },
        {
          label: `codice fiscale_2`,
          value: `${datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : ''}`,
        },
        {
          label: `PIva`,
          value: `${datiAnagrafici?.piva ? datiAnagrafici?.piva : ''}`,
        },
        {
          label: `sede legale`,
          value: `${datiSedeLegale?.indirizzo ? datiSedeLegale?.indirizzo : ''}`,
        },
        {
          label: `N_2`,
          value: ``,
        },
        {
          label: `Cap_2`,
          value: `${datiSedeLegale?.cap ? datiSedeLegale?.cap : ''}`,
        },
        {
          label: `Comune_2`,
          value: `${datiSedeLegale?.comune?.value ? datiSedeLegale?.comune?.value : 'EEP'}`,
        },
        {
          label: `Nazione_3`,
          value: `${datiSedeLegale?.nazione?.value ? datiSedeLegale?.nazione?.value : ''}`,
        },
        {
          label: `Prov_3`,
          value: `${datiSedeLegale?.provincia?.value ? datiSedeLegale?.provincia?.value : 'EEP'}`,
        },
        {
          label: `Telefono`,
          value: `${datiAnagrafici?.telefono ? datiAnagrafici?.telefono : ''}`,
        },
        {
          label: `undefined_2`,
          value: `${datiAnagrafici?.fax ? datiAnagrafici?.fax : ''}`,
        },
        {
          label: `undefined_3`,
          value: `${datiAnagrafici?.pec ? datiAnagrafici?.pec : ''}`,
        },
        {
          label: `email`,
          value: `${datiAnagrafici?.email ? datiAnagrafici?.email : ''}`,
        },
        // {
        //   label: `Pulsante di scelta22`,
        //   value: `Pulsante di scelta22`,
        // },
        // {
        //   label: `Casella di controllo23`,
        //   value: `Casella di controllo23`,
        // },
        // {
        //   label: `Casella di controllo24`,
        //   value: `Casella di controllo24`,
        // },
        {
          label: `Testo25`,
          value: `${moment().format('DD/MM/YYYY')}`,
        },
      ]
      break
    default:
      return []
  }
  return fields
}

export const requestMapperAgenzia = async (data: any, tipo?: any) => {
  await fillProvince()
  const {
    formatori,
    datiAnagrafici,
    datiSedeLegale,
    datiRappresentanteLegale,
    datiResponsabileAF,
    datiAccreditamento,
    datiDichiarazioni,
    datiComitatoTecnico,
    datiAllegati,
    tipoAgForm,
  } = data || {}

  const obj: any = {
    formatori: formatori,
    datiEnteEsterno:
      tipoAgForm === 3 || tipo === 3
        ? {
            dataInizio: datiSedeLegale?.dataInizio,
            dataFine: datiSedeLegale?.dataFine,
            cf: datiSedeLegale?.codiceFiscaleGestore,
          }
        : undefined,
    datiRivista:
      tipoAgForm === 2 || tipo === 2
        ? {
            cod_ISSN: datiAnagrafici ? datiAnagrafici?.cod_issn : '',
            datiAccreditamento: datiAccreditamento,
            comitato: datiComitatoTecnico && datiComitatoTecnico.comitato && datiComitatoTecnico.comitato.length > 0 ? datiComitatoTecnico?.comitato : null,
            datiDichiarazioni: {
              pianoEditoriale: datiDichiarazioni?.pianoEditoriale,
              sostenibilita: datiDichiarazioni?.sostenibilita,
              spazioRivista: datiDichiarazioni?.spazioRivista,
            },
            datiAllegato: datiAllegati,
          }
        : undefined,
    datiAccreditamento: {
      integrazioni: datiAccreditamento?.integrazioni,
      tipo_ag_form: 1,
      rag_soc: datiAnagrafici?.ragioneSociale ? datiAnagrafici?.ragioneSociale : '',
      cod_fisc: datiAnagrafici?.codiceFiscale ? datiAnagrafici?.codiceFiscale : '',
      piva: datiAnagrafici?.piva,
      email: datiAnagrafici?.email,
      pec: datiAnagrafici?.pec,
      telefono: datiAnagrafici?.telefono,
      fax: datiAnagrafici?.fax,
      cod_issn: '',

      sl_provincia: provinciaMap[datiSedeLegale?.provincia?.value],
      sl_comune: datiSedeLegale?.comune?.value,
      sl_indirizzo: datiSedeLegale?.indirizzo,
      sl_cap: datiSedeLegale?.cap,

      lr_nome: datiRappresentanteLegale?.nome,
      lr_cognome: datiRappresentanteLegale?.cognome,
      lr_codice_fiscale: datiRappresentanteLegale?.codiceFiscale,
      lr_luogo_nas: datiRappresentanteLegale?.luogoNascita,
      lr_comu_nas: datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP',
      lr_prov_nas: provinciaMap[datiRappresentanteLegale?.provincia?.value],
      lr_data_nas: datiRappresentanteLegale?.dataNascita || null,
      lr_nazione: datiRappresentanteLegale?.nazione?.value,
      lr_sesso: '',
      lr_comu_res: datiRappresentanteLegale?.comune?.value ? datiRappresentanteLegale?.comune?.value : 'EEP',
      lr_prov_res: provinciaMap[datiRappresentanteLegale?.provincia?.value],
      lr_ind_res: datiRappresentanteLegale?.indirizzo ? datiRappresentanteLegale?.indirizzo : '',
      lr_cap_res: datiRappresentanteLegale?.cap ? datiRappresentanteLegale?.cap : '',

      // TODO manca Responsabile AF
      tipo_accr: datiAccreditamento?.tipoAccreditamento?.value || 0,
      ambito_territoriale: datiAccreditamento?.ambitoTerritoriale?.value || 0,
      // TODO FEDE check (sul pdf deve essere una select tre fad in situ e mista)
      // f_att_situ: [MODALITA_DISPONIBILI_VALUES.IN_SITU, MODALITA_DISPONIBILI_VALUES.MISTA].includes(data?.acc_modalita?.value) ? 1 : 0,
      // f_att_fad: [MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA].includes(
      //   data?.acc_modalita?.value
      // )
      //   ? 1
      //   : 0,

      assenza_sentenze: datiDichiarazioni?.assenzaSentenze ? 1 : 0,
      assenza_proc_fallimentari: datiDichiarazioni?.assenzaFallimento ? 1 : 0,
      risp_norm_lavoro: datiDichiarazioni?.rispettoNormative ? 1 : 0,
      is_formatore: datiDichiarazioni?.formatore ? 1 : 0,
      is_resp_form_formatore: datiDichiarazioni?.isRespFormFormatore ? 1 : 0,
      has_resp_form_conflitti: datiDichiarazioni?.hasRespFormConflitti ? 1 : 0,

      massimale_polizza: datiDichiarazioni?.massimalePolizza,
      tipo_rischio_polizza: datiDichiarazioni?.tipoPolizza,
      polizza_val_da: datiDichiarazioni?.polizzaValidaDa || null,
      polizza_val_a: datiDichiarazioni?.polizzaValidaA || null,
      is_nuova_costituzione: datiDichiarazioni?.nuovaCostituzione ? 1 : 0,
      flag_impegno_prod_doc: datiDichiarazioni?.flagImpegnoProdDoc ? 1 : 0,
      fatturato_formazione: datiDichiarazioni?.fatturatoFormazione,

      doc_copia_vers_tariffa: '',
      doc_all_mod_a_g: '',
      doc_bilanci_appr: '',
      doc_richiesta_uff: '',
      doc_all_rivista: '',
      doc_all_mod_f_shf1_f2: '',
      note_acc_rifiuto: '',
      note_acc_rifiuto_min: '',
    },
    datiTipologiaAccreditamento: {
      settori: [datiAccreditamento?.settoriDisciplinari].flat(),
      aree: tipoAgForm === 2 ? [] : [datiAccreditamento?.areePrestazioni].flat(),
      attivita: tipoAgForm === 2 ? [] : [datiAccreditamento?.tipoAttivita].flat(),
      modalita: [datiAccreditamento?.modalita].flat(),
    },
    datiReponsabileAttivitaFormativa: {
      nome: datiResponsabileAF?.nome,
      cognome: datiResponsabileAF?.cognome,
      cf: datiResponsabileAF?.codiceFiscale,
      data_nascita: datiResponsabileAF?.dataNascita || moment().format('YYYY-MM-DD'),
      luogo_nascita: datiResponsabileAF?.luogoNascita,
      nazione: datiResponsabileAF?.nazione?.value,
      comune: datiResponsabileAF?.comune?.value,
      provincia: provinciaMap[datiResponsabileAF?.provincia?.value],
      cap: datiResponsabileAF?.cap,
      indirizzo: datiResponsabileAF?.indirizzo,
      email: datiResponsabileAF?.email,
      pec: datiResponsabileAF?.pec,
      cf_formatore_allegato: datiResponsabileAF?.cfFormatoreAllegato,
    },
    allegati: ALLEGATI_AGENZIA_FILTERED(data, 'allegati')?.flatMap((a) => {
      const value = getValueAtPath(data, a?.id)
      let file = value
      // TODO remove, for now send only the first file if isMulti
      // if (Array.isArray(value)) file = value?.[0]
      // delete file.key
      return file
    }),
  }
  return obj
}

export const responseMapperAgenzia = async (data: any, isIntegrazioniMode?: boolean) => {
  const {
    datiAccreditamento: accreditamento,
    datiReponsabileAttivitaFormativa: aformativa,
    datiTipologiaAccreditamento: taccreditamento,
    datiEnteEsterno: enteEsterno,
    datiRivista: rivista,
    allegatiRecuperati,
    logoAllegato,
    formatoriRecuperati,
  } = data || {}

  const { data: province } = await getAllProvince()

  const formatori = []

  if (accreditamento.tipo_ag_form === 1 && formatoriRecuperati && formatoriRecuperati.length > 0) {
    for (const formobj of formatoriRecuperati) {
      const obj = await responseMapperFormatore(formobj)

      const { id, nome, cognome, titoli, att_formative, esp_prof, esp_didattica, esp_scientifica, cod_fisc } = obj

      const formatore = {
        id: id,
        nome: nome,
        cognome: cognome,
        cod_fisc,
        titoli: titoli,
        att_formative: att_formative,
        esp_prof: esp_prof,
        esp_didattica: esp_didattica,
        esp_scientifica: esp_scientifica,
        hasRespFormConflitti: true,
      }

      formatori.push(formatore)
    }
  }

  const datiAnagrafici: any = {
    cod_issn: accreditamento?.cod_issn,
    ragioneSociale: accreditamento?.rag_soc,
    codiceFiscale: accreditamento?.cod_fisc,
    piva: accreditamento?.piva,
    email: accreditamento?.email,
    pec: accreditamento?.pec,
    telefono: accreditamento?.telefono,
    fax: accreditamento?.fax,
  }

  const datiEnteEsterno: any = enteEsterno
  const datiSedeLegale: any = {
    indirizzo: accreditamento?.sl_indirizzo,
    cap: accreditamento?.sl_cap,
    ...datiEnteEsterno,
    cod_issn: rivista ? rivista?.codIssn : '',
  }

  const datiComitatoTecnico = rivista ? rivista?.comitato : { comitato: [] }

  const datiAllegati = rivista ? rivista.datiAllegato : {}

  const datiRappresentanteLegale: any = {
    nome: accreditamento?.lr_nome,
    cognome: accreditamento?.lr_cognome,
    codiceFiscale: accreditamento?.lr_codice_fiscale,
    luogoNascita: accreditamento?.lr_luogo_nas,
    dataNascita: accreditamento?.lr_data_nas,
    nazione: {
      value: accreditamento?.lr_nazione,
      label: accreditamento?.lr_nazione,
    },
    indirizzo: accreditamento?.lr_ind_res,
    cap: accreditamento?.lr_cap_res,
  }
  const datiResponsabileAF: any = {
    nome: aformativa?.nome,
    cognome: aformativa?.cognome,
    codiceFiscale: aformativa?.cf,
    dataNascita: aformativa?.data_nascita,
    luogoNascita: aformativa?.luogo_nascita,
    nazione: {
      value: aformativa?.nazione,
      label: aformativa?.nazione,
    },
    cap: aformativa?.cap,
    indirizzo: aformativa?.indirizzo,
    email: aformativa?.email,
    pec: aformativa?.pec,
  }
  let datiAccreditamento: any = {
    integrazione: accreditamento?.integrazione,
    tipoAccreditamento: TIPOLOGIE_ACCREDITAMENTO_OPTION_FROM_VALUES[accreditamento?.tipo_accr],
    ambitoTerritoriale: AMBITI_TERRITORIALI_OPTION_FROM_VALUES[accreditamento?.ambito_territoriale],
    dataFineProroga: accreditamento?.dataFineProroga,
    noteProroga: accreditamento?.noteProroga,
    allegatoProroga: accreditamento?.allegatoProroga,
    idNaz: accreditamento?.id_naz,
  }
  let datiDichiarazioni: any = {
    assenzaSentenze: !!accreditamento?.assenza_sentenze,
    assenzaFallimento: !!accreditamento?.assenza_proc_fallimentari,
    rispettoNormative: !!accreditamento?.risp_norm_lavoro,
    formatore: !!accreditamento?.is_formatore,
    isRespFormFormatore: !!accreditamento?.is_resp_form_formatore,
    hasRespFormConflitti: !!accreditamento?.has_resp_form_conflitti,
    massimalePolizza: accreditamento?.massimale_polizza,
    tipoPolizza: accreditamento?.tipo_rischio_polizza,
    polizzaValidaDa: toDateFormat(accreditamento?.polizza_val_da),
    polizzaValidaA: toDateFormat(accreditamento?.polizza_val_a),
    nuovaCostituzione: !!accreditamento?.is_nuova_costituzione,
    flagImpegnoProdDoc: !!accreditamento?.flag_impegno_prod_doc,
    fatturatoFormazione: accreditamento?.fatturato_formazione,
    pianoEditoriale: 1,
    sostenibilita: 1,
    spazioRivista: 1,
  }

  if (accreditamento.tipo_ag_form === 2) {
    datiAccreditamento = { ...datiAccreditamento, ...rivista.datiAccreditamento }
  }

  // if (accreditamento?.f_att_situ && accreditamento?.f_att_fad) {
  //   datiAccreditamento.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[MODALITA_DISPONIBILI_VALUES.MISTA]
  // } else if (datiAccreditamento?.f_att_situ) {
  //   datiAccreditamento.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[MODALITA_DISPONIBILI_VALUES.IN_SITU]
  // } else if (datiAccreditamento?.f_att_fad) {
  //   datiAccreditamento.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO]
  // }

  datiAccreditamento.modalita = taccreditamento?.modalita ? taccreditamento?.modalita[0] : { value: 'IN_SITU', label: 'In Situ' }

  if (accreditamento.tipo_ag_form === 2) {
    datiAccreditamento = { ...datiAccreditamento, ...rivista.datiAccreditamento }
    const item = taccreditamento?.settori?.[0] || {}
    datiAccreditamento.settoriDisciplinari = {
      ...item,
      label: item?.titolo,
      value: item?.cod,
    }
  } else {
    if (taccreditamento?.settori?.length > 1) {
      datiAccreditamento.settoriDisciplinari = taccreditamento?.settori?.flatMap((item) => {
        if (!item) return null
        item.label = item?.cod + ' - ' + item?.titolo
        item.value = item?.cod
        return item
      })
    } else {
      const item = taccreditamento?.settori?.[0] || {}
      datiAccreditamento.settoriDisciplinari = {
        ...item,
        label: item?.cod + ' - ' + item?.titolo,
        value: item?.cod,
      }
    }
  }

  if (taccreditamento?.attivita?.length > 1) {
    datiAccreditamento.tipoAttivita = taccreditamento?.attivita?.flatMap((item) => {
      if (!item) return null
      item.label = item?.titolo
      item.value = item?.cod
      return item
    })
  } else {
    const item = taccreditamento?.attivita?.[0] || {}
    datiAccreditamento.tipoAttivita = {
      ...item,
      label: item?.titolo,
      value: item?.cod,
    }
  }

  if (taccreditamento?.aree?.length > 1) {
    datiAccreditamento.areePrestazioni = taccreditamento?.aree?.flatMap((item) => {
      if (!item) return null
      item.label = item?.titolo
      item.value = item?.cod
      return item
    })
  } else {
    const item = taccreditamento?.aree?.[0] || {}
    datiAccreditamento.areePrestazioni = {
      ...item,
      label: item?.titolo,
      value: item?.cod,
    }
  }

  try {
    const sl_provincia =
      accreditamento?.sl_provincia === 'EEP'
        ? {
            denominazioneUnitaTerritorialeSovracomunale: 'Stato Estero',
          }
        : province?.find((item) => item?.siglaAutomobilistica === accreditamento?.sl_provincia)
    if (sl_provincia?.denominazioneUnitaTerritorialeSovracomunale) {
      const sl_comuni = await getComuniByProvinciaMsga(sl_provincia?.denominazioneUnitaTerritorialeSovracomunale)
      datiSedeLegale.provincia = {
        label: sl_provincia?.denominazioneUnitaTerritorialeSovracomunale,
        value: accreditamento?.sl_provincia === 'EEP' ? 'EEP' : sl_provincia?.denominazioneUnitaTerritorialeSovracomunale,
      }
      datiSedeLegale.comune = sl_comuni?.find((item) => item.value.toLowerCase() === accreditamento?.sl_comune.toLowerCase())
    }

    const rapp_provincia = province?.find((item) => item?.siglaAutomobilistica === accreditamento?.lr_prov_res)
    if (rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale) {
      const rapp_comuni = await getComuniByProvinciaMsga(rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale)
      datiRappresentanteLegale.provincia = {
        label: rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale,
        value: rapp_provincia?.denominazioneUnitaTerritorialeSovracomunale,
      }
      datiRappresentanteLegale.comune = rapp_comuni?.find((item) => item.value.toLowerCase() === accreditamento?.lr_comu_res.toLowerCase())
    }

    const res_provincia = province?.find((item) => item?.siglaAutomobilistica === aformativa?.provincia)
    if (res_provincia?.denominazioneUnitaTerritorialeSovracomunale) {
      const res_comuni = await getComuniByProvinciaMsga(res_provincia?.denominazioneUnitaTerritorialeSovracomunale)
      datiResponsabileAF.provincia = {
        label: res_provincia?.denominazioneUnitaTerritorialeSovracomunale,
        value: res_provincia?.denominazioneUnitaTerritorialeSovracomunale,
      }
      datiResponsabileAF.comune = res_comuni?.find((item) => item.value === aformativa?.comune)
    }
  } catch (e) {
    console.error(e)
  }

  const obj: any = {
    formatori,
    datiAnagrafici,
    datiSedeLegale,
    datiRappresentanteLegale,
    datiResponsabileAF,
    datiAccreditamento,
    datiDichiarazioni,
    datiEnteEsterno,
    logoAllegato,
    datiComitatoTecnico: {
      comitato: datiComitatoTecnico,
    },
    datiAllegati: {
      tipoAllegato: datiAllegati ? datiAllegati.tipoAllegato : 'PDF',
      urlAllegato: datiAllegati ? datiAllegati.urlAllegato : '',
      moduloRecuperato: datiAllegati ? datiAllegati.moduloRecuperato : '',
      moduloAccreditamento: {
        ...datiAllegati.moduloRecuperato,
        allegatoDTO: {
          filename: datiAllegati.moduloRecuperato ? datiAllegati.moduloRecuperato.titoloDocumento : '',
        },
      },
    },
  }

  const allegati = Object.assign(
    {},
    ...ALLEGATI_AGENZIA_FILTERED(obj, 'allegati', data.datiAccreditamento.stato === 3 && isIntegrazioniMode)?.map((a) => {
      const key = a?.id?.replace('allegati.', '')
      const files = allegatiRecuperati?.filter((item) => item?.tipologiaAllegato === a?.type)
      return {
        [key]: files?.map((item) => ({
          ...item,
          allegatoDTO: {
            filename: item?.titoloDocumento,
          },
        })),
      }
    })
  )

  const allegatiProroga = Object.assign(
    {},
    ...ALLEGATI_AGENZIA_PROROGA(obj, 'allegati')?.map((a) => {
      const key = a?.id?.replace('allegati.', '')
      const files = allegatiRecuperati?.filter((item) => item?.tipologiaAllegato === a?.type)
      return {
        [key]: files?.map((item) => ({
          ...item,
          allegatoDTO: {
            filename: item?.titoloDocumento,
          },
        })),
      }
    })
  )

  obj.allegati = allegati
  obj.stato = accreditamento.stato
  obj.datiAccreditamento.allegatoProroga = allegatiProroga

  return obj
}

export const requestMapperEvento = () => {}
export const responseMapperEvento = async (data, isClonazione) => {
  const areeProfessionali = await getAreeProfessionali()
  const listaSDAF = await getSdaf()

  const obj = { ...data }
  if (obj?.evento?.nome_corso) {
    obj.nomeCorso = obj.evento.nome_corso
  }

  // se modalita è FAD deve essere considerato come FAD_SINCRONO
  if (obj?.modalita.value === 'FAD') {
    obj.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES['FAD_SINCRONO']
  } else {
    obj.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[obj?.evento?.modalita]
  }

  obj.totOreCFU = obj.evento?.tot_ore_cfu
  obj.piattaformaFAD = obj.evento?.piattaforma_fad
  obj.partecipazioneConAbbonamento = obj?.evento?.part_con_abb
  obj.partecipazioneSenzaAbbonamento = obj?.evento?.part_senza_abb
  obj.quotaAbbonato = obj?.evento?.quota_abbonamento
  obj.quotaNonAbbonati = obj?.evento?.quota_non_abbonati
  obj.luogo = obj?.evento?.presso ? obj?.evento?.presso : obj?.evento?.localita

  obj.maxPRemoto = obj?.maxPRemoto || 1
  obj.maxPSitu = obj?.maxPSitu || 1

  if (obj?.tipologiaAttivita) {
    obj.tipologiaAttivita = { ...obj?.tipologiaAttivita, label: obj?.tipologiaAttivita?.titolo, value: obj?.tipologiaAttivita?.cod }
  }

  obj.codAttivita = attivitaDisponibili?.find((item) => item?.value == obj?.evento?.cod_attivita)
  for (const sdaf of listaSDAF) {
    if (sdaf.id == obj?.evento?.settore) {
      obj.settore = sdaf
    }
  }
  if (obj?.codCompetenza) {
    obj.codCompetenza = { label: `${obj.codCompetenza.cod} - ${obj.codCompetenza.descrizione} - ${obj.codCompetenza.norma_rif}`, value: obj.codCompetenza.cod }
  }
  if (obj?.areaProfessionale) {
    obj.areaProfessionale = areeProfessionali?.find((item) => item?.id == obj?.areaProfessionale?.id)
  }
  obj.areaProfessionale2 = []
  if (obj?.evento?.id_prestazione_professionale) {
    const ids = obj.evento.id_prestazione_professionale.split(',')
    for (const item of areeProfessionali) {
      for (const id of ids) {
        if (id == `${item.id}` && (!obj || !obj.areaProfessionale || id !== `${obj.areaProfessionale.id}`)) {
          obj.areaProfessionale2.push(item)
          break
        }
      }
    }
  }

  obj.locandina = obj?.formazioneAllegatoDTO?.titoloDocumento
    ? {
        ...(obj?.formazioneAllegatoDTO || {}),
        allegatoDTO: {
          filename: obj?.formazioneAllegatoDTO?.titoloDocumento,
        },
      }
    : (obj.locandina = obj?.formazioneExtAllegatiDTO?.titoloDocumento
        ? {
            ...(obj?.formazioneExtAllegatiDTO || {}),
            allegatoDTO: {
              filename: obj?.formazioneExtAllegatiDTO?.titoloDocumento,
            },
          }
        : null)

  obj.interventi = data?.interventiRecuperati?.map((x) => ({
    titTema: x?.tit_tema,
    dalle: isClonazione ? null : x?.dalleTime,
    alle: isClonazione ? null : x?.alle,
    titRelatore: x?.tit_relatore,
    relatore: {
      cf: '',
      nome: x?.nome,
      cognome: x?.cognome,
      email: x?.email,
    },
  }))

  if (isClonazione) {
    obj.dataInizio = null
    obj.dataFine = null
    obj.dataChiusuraIscrizione = null
    obj.crediti = null
    obj.totOre = null
    obj.totOreCFU = null
  }

  return obj
}

export const requestMapperEventoInd = () => {}
export const responseMapperEventoInd = async (data) => {
  const sdaf = await getSdaf()
  const tipologieAttivita = await getTipologieAttivita()

  const obj = { ...data }
  obj.nomeCorso = obj?.attivitaFormativaExt?.nome_corso
  obj.modalita = MODALITA_DISPONIBILI_OPTION_FROM_VALUES[obj?.attivitaFormativaExt?.modalita]
  const settoreDisciplinare = sdaf?.find((s) => s.id == obj?.attivitaFormativaExt?.settore)
  if (settoreDisciplinare) {
    obj.settore = { ...settoreDisciplinare, label: `${settoreDisciplinare?.cod || ''} - ${settoreDisciplinare?.titolo || ''}`, value: settoreDisciplinare?.cod }
  }
  obj.soggettoErogante = obj?.attivitaFormativaExt?.sogg_erogante
  obj.totOre = obj?.attivitaFormativaExt?.durata
  obj.crediti = obj?.attivitaFormativaExt?.cfu
  obj.regione = obj?.attivitaFormativaExt?.regione
  obj.sede = obj?.attivitaFormativaExt?.presso
  obj.descrizione = obj?.attivitaFormativaExt?.descr_corso
  obj.tipologiaEvento = TIPOLOGIA_EVENTO_OPTION_FROM_VALUES[obj?.attivitaFormativaExt?.tipologia_evento]

  const tipologiaAttivita = tipologieAttivita?.find((t) => t.id == obj?.attivitaFormativaExt?.tipo_corso)
  if (tipologiaAttivita) {
    obj.tipologiaAttivita = { ...tipologiaAttivita, label: tipologiaAttivita?.titolo, value: tipologiaAttivita?.cod }
  }
  obj.note = obj?.attivitaFormativaExt?.note_richiedente

  obj.locandina = obj?.formazioneAllegatoDTO?.titoloDocumento
    ? {
        ...(obj?.formazioneAllegatoDTO || {}),
        allegatoDTO: {
          filename: obj?.formazioneAllegatoDTO?.titoloDocumento,
        },
      }
    : null
  obj.attestato = obj?.attestatoDTO?.titoloDocumento
    ? {
        ...(obj?.attestatoDTO || {}),
        allegatoDTO: {
          filename: obj?.attestatoDTO?.titoloDocumento,
        },
      }
    : null
  return obj
}

const toSnakeCase = (data) => {
  const obj = {}
  for (let key in data) {
    const newKey = key.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`)
    obj[newKey] = data[key]
  }
  return obj
}

const toCamelCase = (data) => {
  const obj = {}
  for (let key in data) {
    const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
    obj[newKey] = data[key]
  }
  return obj
}

export const ottieniAllegato = async (idAllegato?: any, name?: string) => {
  try {
    const param = { idAttachment: idAllegato }
    let { data } = await getFormazioneResourceApi().msfoFormazioneGetAllegatoPost(param, getDefaultAxiosConfig())
    if (!data['content']) {
      throw new Error('File non presente per la polizza selezionata')
    }

    let linkSource = 'data:application/pdf;base64,' + data['content']
    const downloadLink = document.createElement('a')
    const fileName = data.name + '.pdf'
    downloadLink.href = linkSource
    downloadLink.download = name ? name : fileName
    downloadLink.click()

    let pdfAsDataUri: any = 'data:application/pdf;base64,' + data['data']['content']
    window.open(pdfAsDataUri, '_blank')
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero dell'allegato " + err)
  }
}

export const exportPDF = async (
  listaEventi: Riga,
  nome: string,
  cognome: string,
  cf: string,
  triennioSelezionato: string,
  CFPValidi: number,
  CFPMeta: number
) => {
  const doc = new jsPDF({
    orientation: 'l', // landscape
    unit: 'pt', // points, pixels won't work properly
    format: 'a4', // set needed dimensions for any element
  })
  doc.text('Riepilogo Crediti Formativi', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' })
  doc.setFontSize(12)
  doc.setFont('helvetica', 'normal')
  doc.text('Nome e cognome: ' + nome + ' ' + cognome, 40, 60)
  doc.text('Codice Fiscale: ' + cf, 40, 75)
  doc.text('Triennio: ' + triennioSelezionato, 40, 90)
  doc.text('Totale CFP validi: ' + CFPValidi + '. Di cui ' + CFPMeta + ' metaprofessionali', 40, 105)
  autoTable(doc, {
    startY: 120,
    html: '#riepilogoCreditiFormativi',
    styles: {
      halign: 'center',
      valign: 'middle',
    },
    headStyles: {
      fillColor: [0, 102, 204],
    },
    rowPageBreak: 'avoid',
    columns: [
      { header: 'SDAF', dataKey: 'settore' },
      { header: 'Tipologie Attività', dataKey: 'tipo_corso' },
      { header: 'Data inizio', dataKey: 'dataInizio' },
      { header: 'Data fine', dataKey: 'dataFine' },
      { header: 'Titolo Evento', dataKey: 'nome_corso' },
      { header: 'Anno', dataKey: 'anno_calc_cfp' },
      { header: 'Tipo CFP', dataKey: 'cod_attivita' },
      { header: 'CFP Riconosciuti', dataKey: 'cfp_riconosciuti' },
      { header: 'CFP validi per gli obblighi formativi', dataKey: 'cfp_validi' },
      { header: 'Organizzatore', dataKey: 'organizzatore' },
    ],
  })
  doc.save(`Riepilogo_Crediti_Formativi_${cf}_${triennioSelezionato}.pdf`)
}
