import React from 'react'

class GenericFormLabel extends React.Component<GeneralInputProps, any>{
    constructor(props) {
        super(props);
    }

    render() {
        const classNameValue="value "+(this.props.className?this.props.className:"");
        return (
            <div className={"form-group form-label"}>
                <div className={classNameValue}>{this.props.value}</div>
                <label className="active">{this.props.label}</label>
            </div>
        );
    }
}

export declare interface GeneralInputProps {
    label?: any;
    value?: any;

    className?:string;
}

export default GenericFormLabel;
