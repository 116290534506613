import React from 'react'
import GenericTitle from 'src/frontend/app/components/GenericTitle'

export enum TipoRichiestaPolizza {
    A = "AUMENTO MASSIMALE",
    P = "PRIMA ADESIONE",
    C = "INDIVIDUALE",
    R = "RINNOVO",
    N = "NUOVA ADESIONE"    
}


export enum StatiPolizza{
    _ = "NON DECODIFICATO",
    _0 = "IN COMPILAZIONE",
    _1 = "INVIATA",
    _2 = "APPROVATA",
    _3 = "RIFIUTATA",
    _4 = "RICHIESTA INTEGRAZIONE",
    _5 = "ARCHIVIATA",
    _6 = "CANCELLATA",
}

export const legenda =([
    {title: 'Legenda', Size:'h2'},
    {title: '* Campo obbligatorio', Size:'p'},
    {title: '** Campo non modificabile compilato automaticamente', Size:'p'},
])

export const listLegenda =
[
  <div key={'legenda'} className="Card">
    {legenda.map((title, i) => {
    return (<GenericTitle key={i} title={legenda[i].title} Size={legenda[i].Size}/>)
      })}
  </div>
]

export enum CodiciColonnaFiltrabiliPolizza{
  _tipologia_polizza = "_tipologia_polizza",
  _data_invio_richiesta = "_data_invio_richiesta",
  _sigla_provincia = "_sigla_provincia",
  _nominativo = "_nominativo",
  _tipo_utente = "_tipo_utente",
  _premio = "_premio",
  _massimale = "_massimale",
  _fascia = "_fascia",
  _validita_dal = "_validita_dal",
  _validita_al = "_validita_al",
  _stato = "_stato",
  _id_polizza = "_id_polizza",
  _data_creazione = "_data_creazione",
  _data_conferma = "_data_conferma",
  _id_iscrizione = "_id_iscrizione",
  _pivacf = "_pivacf",
  _progressivo = "_progressivo"
}

export const filterableColums =  [
{ filterParamValue: "", columnIndex: 0, columnCode :  CodiciColonnaFiltrabiliPolizza._tipologia_polizza}, //Tipologia polizza
{ filterParamValue: "", columnIndex: 1, columnCode :  CodiciColonnaFiltrabiliPolizza._data_invio_richiesta }, //Data invio richiesta
{ filterParamValue: "", columnIndex: 2, columnCode :  CodiciColonnaFiltrabiliPolizza._sigla_provincia }, //Sigla provincia ordine (LE, BA, ..)
{ filterParamValue: "", columnIndex: 3, columnCode :  CodiciColonnaFiltrabiliPolizza._nominativo }, //Nominativo
{ filterParamValue: "", columnIndex: 4, columnCode :  CodiciColonnaFiltrabiliPolizza._tipo_utente }, //Tipo utente
{ filterParamValue: "", columnIndex: 5, columnCode :  CodiciColonnaFiltrabiliPolizza._fascia }, //Fascia
{ filterParamValue: "", columnIndex: 6, columnCode :  CodiciColonnaFiltrabiliPolizza._premio }, //Premio
{ filterParamValue: "", columnIndex: 7, columnCode :  CodiciColonnaFiltrabiliPolizza._massimale }, //Massimale
{ filterParamValue: "", columnIndex: 8, columnCode :  CodiciColonnaFiltrabiliPolizza._validita_dal }, //Validità dal
{ filterParamValue: "", columnIndex: 9, columnCode :  CodiciColonnaFiltrabiliPolizza._validita_al}, //Validità al
{ filterParamValue: "DA VALUTARE", columnIndex: 10, columnCode :  CodiciColonnaFiltrabiliPolizza._stato },   // Stato
{ filterParamValue: "", columnIndex: 11, columnCode :  CodiciColonnaFiltrabiliPolizza._id_polizza }, //idPolizza
{ filterParamValue: "", columnIndex: 12, columnCode :  CodiciColonnaFiltrabiliPolizza._data_creazione }, //Data creazione
{ filterParamValue: "", columnIndex: 13, columnCode :  CodiciColonnaFiltrabiliPolizza._data_conferma }, //Data conferma
{ filterParamValue: "", columnIndex: 14, columnCode :  CodiciColonnaFiltrabiliPolizza._id_iscrizione }, //idIscrizione
{ filterParamValue: "", columnIndex: 15, columnCode :  CodiciColonnaFiltrabiliPolizza._pivacf }, //Piva/CF
{ filterParamValue: "", columnIndex: 16, columnCode :  CodiciColonnaFiltrabiliPolizza._progressivo }, //Progressivo
]

export const filterableColumsAll =  [
  { filterParamValue: "", columnIndex: 0, columnCode :  CodiciColonnaFiltrabiliPolizza._tipologia_polizza}, //Tipologia polizza
  { filterParamValue: "", columnIndex: 1, columnCode :  CodiciColonnaFiltrabiliPolizza._data_invio_richiesta }, //Data invio richiesta
  { filterParamValue: "", columnIndex: 2, columnCode :  CodiciColonnaFiltrabiliPolizza._sigla_provincia }, //Sigla provincia ordine (LE, BA, ..)
  { filterParamValue: "", columnIndex: 3, columnCode :  CodiciColonnaFiltrabiliPolizza._nominativo }, //Nominativo
  { filterParamValue: "", columnIndex: 4, columnCode :  CodiciColonnaFiltrabiliPolizza._tipo_utente }, //Tipo utente
  { filterParamValue: "", columnIndex: 5, columnCode :  CodiciColonnaFiltrabiliPolizza._fascia }, //Fascia
  { filterParamValue: "", columnIndex: 6, columnCode :  CodiciColonnaFiltrabiliPolizza._premio }, //Premio
  { filterParamValue: "", columnIndex: 7, columnCode :  CodiciColonnaFiltrabiliPolizza._massimale }, //Massimale
  { filterParamValue: "", columnIndex: 8, columnCode :  CodiciColonnaFiltrabiliPolizza._validita_dal }, //Validità dal
  { filterParamValue: "", columnIndex: 9, columnCode :  CodiciColonnaFiltrabiliPolizza._validita_al}, //Validità al
  { filterParamValue: "TUTTI GLI STATI", columnIndex: 10, columnCode :  CodiciColonnaFiltrabiliPolizza._stato },   // Stato
  { filterParamValue: "", columnIndex: 11, columnCode :  CodiciColonnaFiltrabiliPolizza._id_polizza }, //idPolizza
  { filterParamValue: "", columnIndex: 12, columnCode :  CodiciColonnaFiltrabiliPolizza._data_creazione }, //Data creazione
  { filterParamValue: "", columnIndex: 13, columnCode :  CodiciColonnaFiltrabiliPolizza._data_conferma }, //Data conferma
  { filterParamValue: "", columnIndex: 14, columnCode :  CodiciColonnaFiltrabiliPolizza._id_iscrizione }, //idIscrizione
  { filterParamValue: "", columnIndex: 15, columnCode :  CodiciColonnaFiltrabiliPolizza._pivacf }, //Piva/CF
  { filterParamValue: "", columnIndex: 16, columnCode :  CodiciColonnaFiltrabiliPolizza._progressivo }, //Progressivo
]

export const dbListaStati = [
  {idStato: '0', descrizioneStato: 'COMPILAZIONE' },
  {idStato: '1', descrizioneStato: 'DA VALUTARE' },
  {idStato: '2', descrizioneStato: 'CONFERMATA' },
  {idStato: '3', descrizioneStato: 'RIFIUTATA' },
  {idStato: '4', descrizioneStato: 'RICHIESTA INTEGRAZIONE' },
  {idStato: '5', descrizioneStato: 'ARCHIVIATA' },
  {idStato: '6', descrizioneStato: 'CANCELLATA' }
]


export const listaTipologiaUtenteVisualizzata = [
  {id: 'PF', descrizione: 'Persona Fisica' },
  {id: 'PG', descrizione: 'Persona Giuridica' }
]

export const listaStatiVisualizzata = [
  {idStato: '0', descrizioneStato: 'COMPILAZIONE' },
  {idStato: '0', descrizioneStato: 'COMPILAZIONE (Aum. Massimale)' },
  {idStato: '1', descrizioneStato: 'DA VALUTARE' },
  {idStato: '1', descrizioneStato: 'DA VALUTARE (Aum. Massimale)' },
  {idStato: '2', descrizioneStato: 'CONFERMATA' },
  {idStato: '2', descrizioneStato: 'CONFERMATA (Aum. Massimale)' },
  {idStato: '3', descrizioneStato: 'RIFIUTATA' },
  {idStato: '3', descrizioneStato: 'RIFIUTATA (Aum. Massimale)' },
  {idStato: '4', descrizioneStato: 'RICHIESTA INTEGRAZIONE' },
  {idStato: '4', descrizioneStato: 'RICHIESTA INTEGRAZIONE (Aum. Massimale)' },
  {idStato: '5', descrizioneStato: 'ARCHIVIATA' },
  {idStato: '5', descrizioneStato: 'ARCHIVIATA (Aum. Massimale)' },
  {idStato: '6', descrizioneStato: 'CANCELLATA' },
  {idStato: '6', descrizioneStato: 'CANCELLATA (Aum. Massimale)' },
]

export const linkTabCollettiva = [
    {id:1,label:'Dati Informativi'},
    {id:2,label:'Tabella 1'},
    {id:3,label:'Riepilogo'},
    {id:4,label:'Pagamento'},
    {id:5,label:'Invio'},
]

export const linkTabCollettivaVisualizzazione = [
  {id:1,label:'Dati Informativi'},
  {id:2,label:'Tabella 1'},
  {id:3,label:'Riepilogo'},
  {id:4,label:'Pagamento'},
]

export const linkTabIndividuale = [
    {id:1,label:'Dati Informativi'},
    {id:2,label:'Tabella 1'},
    {id:3,label:'Riepilogo'},
    {id:4,label:'Dati polizza'},
    {id:5,label:'Invio'},
  ]

export const linkTabIndividualeVisualizzazione = [
    {id:1,label:'Dati Informativi'},
    {id:2,label:'Tabella 1'},
    {id:3,label:'Riepilogo'},
    {id:4,label:'Dati polizza'},
]

 export const columnsTabellaUno = [
    {id:1, name: "Codice"},
    {id:2, name: "Area/Prestazione"},
    {id:3, name: "Cod. Parametri"},
    {id:4, name: "Cod. Competenza"},
    {id:5, name: "Attività di formatore"},
    {id:6, name: "Compenso (€)"},
    {id:7, name: "N. Prestazioni"},
    {id:8, name: "Classe rischio (%)"},
    {id:9, name: "Valore rischio"},
    {id:10, name: "Valore opera prog. (€)"},
    {id:11, name: "Totale Valore opera (€)"},
  ]

  export const columnsNamesTabellaUno = [
    "Codice",
    "Area/Prestazione",
    "Cod. Parametri",
    "Cod. Competenza",
    "Attività di formatore",
    "Compenso (€)",
    "N. Prestazioni",
    "Classe rischio (%)",
    "Valore rischio",
    "Valore opera prog. (€)",
    "Totale Valore opera (€)",
  ]

  export const listaBaseCalcoloTabellaUno = [
    { label: "In base all'attività dell'ultimo esercizio " + (new Date().getFullYear() - 1), value: 0},
    { label: "In base alla previsione dell'attività dell'anno in corso "+ new Date().getFullYear(), value: 1},
    { label: "In base alla previsione dell'anno successivo " + (new Date().getFullYear() + 1), value: 2},
  ]

  export const listaAnniRiferimentoTabellaUno = [
    { label: "Nuova compilazione", value: "nuovaCompilazione"}
  ]

  export const messaggiErrorePolizzaIndividuale = {}

  export enum MessaggiErroreUtente {
    campi_obbligatori = "Compila tutti i campi obbligatori",
    errore_recupero_dati = "C'è stato un errore durante il recupero dei dati",
    errore_file = "C'è stato un errore durante il caricamento del file",
    tabella_uno_non_compilata = "Tabella uno invalida o non compilata correttamente",
    individuale_no_copertura_danni_patrimoniali =  "La polizza non copre i danni patrimoniali",
    individuale_no_retroattivita = "La polizza non ha retroattività illimitata",
    individuale_no_copertura_postuma_decennale = "La polizza non prevede copertura decennale postuma",
    individuale_valore_rischio_inferiore = "La polizza ha un valore di rischio non idoneo, in quanto inferiore a quello calcolato",
    individuale_valore_massimale_inferiore = "La polizza ha un massimale non idoneo, in quanto inferiore del valore dell'opera calcolato"
}

export enum AvvisiUtenteBo {
    avviso_cancellazione_p_individuale = "Si sta cancellando una polizza individuale già confermata. Sicuro di voler procedere?",
    avviso_cancellazione_p_collettiva = "Si sta cancellando una polizza con certificato già emesso. Sicuro di voler procedere?",
    avviso_cancellazione_p_collettiva_massimale = "Si sta cancellando una polizza con aumento massimale e certificato già emesso. Sicuro di voler procedere?"
}

export enum TestiLabel{
    testo_dichiarazione_circostanza = "* Per circostanza si intende qualsiasi manifestazione dell'intenzione di avanzare una richiesta di risarcimento nei confronti dell'Assicurato; qualsiasi rilievo o contestazione diretti, scritti, espressi, riguardanti la condotta dell'Assicurato, da cui possa trarne origine una richiesta di risarcimento; qualsiasi atto o fatto di cui l'Assicurato sia a conoscenza e che potrebbe ragionevolmente dare luogo a una richiesta di risarcimento nei suoi confronti",
    testo_privacy = "* Dichiaro di essere informato, ai sensi e per gli effetti dell’art. 13 del D.Lgs. 196/2003 e all’art. 13 GDPR 679/16, che i dati personali raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa.",
    testo_vademecum = "* Il Professionista dichiara di aver preso visione del regolamento, del vademecum e delle linee guida",
    testo_presavisione_dpr = "* Il Professionista dichiara di aver preso visione della normativa di riferimento ai sensi dell'art. 5 del DPR 137/2012"
  }
