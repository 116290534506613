/* eslint-disable array-callback-return */
import * as React from 'react'
import { Form } from '@patternfly/react-core'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getPolizzaById, recuperaAllegato } from 'src/processes/assicurazioni/Assicurazione'
import { Label } from 'design-react-kit'
import GenericCard from 'src/backoffice/app/components/GenericCard'
import ListaAllegatiAssicurazioni from 'src/backoffice/app/components/Assicurazioni/ListaAllegatiAssicurazioni'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDatiPagamento extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            polizza: props.polizza,
            visualizzaFlag:props.visualizzaFlag,
            fields: {
              operaStudioAssociato: null,
              codiceStudioAssociato: "",
              ragioneSocialeStudioAssociato: "",
              listaStudiAssociati: [{key:0, codiceStudioAssociato: "",ragioneSocialeStudioAssociato: ""}]
            },
            isLoaded : false,
          };
    }

    async componentDidMount(): Promise<void> {

        let idPolizza;
        if(this.props.polizza.id) {
            this.setState({fields : this.props.polizza})
            idPolizza = this.props.polizza.id;
        }
        if(this.props.data.idPolizza) idPolizza = this.props.data.idPolizza;
        await getPolizzaById(idPolizza).then(async (response: any)=> {
                let fields = this.state.fields;
                this.props.getSelectedValue(fields)

        }).catch((err) => {
                  setTimeout(() => {
                      this.setState({ message: err.message, typeMessage: 'danger' })
                  }, 3000);
        })
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        this.setState({isLoaded: true})

      }


    //setta l'allegato logicamente cancellato
    //finchè non confermo al next successivo
    //l'allegato non viene effettivamente rimosso dal db
    rimuoviAllegato = async(idAllegato?:any) => {
        this.state.polizza.assicurazioneAllegati.filter(a => a.idAllegato === idAllegato)
        .map(a =>( a.cancellato = 1))
    }
      getCertificato = async (row?:any) => {
        await this.setState({ selectedRow: row })
        await getPolizzaById(this.state.polizza.id).then(async (response: any)=> {
            response.assicurazioneAllegati.map((allegato) =>{
                this.setState({idAllegato : allegato.idAllegato})
            })
            let allegati = response.assicurazioneAllegati.sort( (p1, p2) => (p1.dataInserimento > p2.dataInserimento && p1.tipologiaAllegato === "polizze_copia_pagamento") ? -1 : (p1.dataInserimento < p2.dataInserimento && p1.tipologiaAllegato === "polizze_copia_pagamento") ? 1 : 0);
            if(allegati && allegati[0].cancellato === 0 ) {
                await recuperaAllegato(this.state.polizza.id, allegati[0].idAllegato).then(response => {
                        if(response["content"] === undefined || response["content"] === null || response["content"] === ""){
                            this.showModal(true, "Attenzione","File non presente per la polizza selezionata");
                        }
                        else {
                            let linkSource = "data:application/pdf;base64,"+response["content"];
                            const downloadLink = document.createElement("a");
                            const fileName =response.name+".pdf";
                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                        }
                        let pdfAsDataUri :any = "data:application/pdf;base64,"+response["data"]["content"];
                        window.open(pdfAsDataUri,"_blank");
                })
                .catch((err) => {
                    this.setState({ message: err.message, typeMessage: "danger" })
                  })
            }
            else this.showModal(true, "Attenzione","Al momento non è possibile recuperare il documento. Ci scusiamo per il disagio. Riprovi in un secondo momento.");

        }).catch((err) => {
            setTimeout(() => {
                this.setState({ message: err.message, typeMessage: 'danger' })
            }, 3000);
        })
        }

    closeModal = () => {
            this.setState({modalOpen:false});
    }

    showModal = async (booleanValue, title, text) => {
            await this.setState({
                modalOpen:booleanValue,
                modalTitle: title,
                modalText:text,
                isLoaded: true,
            });
    }


    render() {

        return (
            <>
               {this.state.isLoaded ?
                <div>
              <Form id="formPagamento" className="row" hidden={(!this.state.fields)}>
              <h2 className='stepTitle'>Dati Pagamento</h2>
                <GenericCard className="cardStyle" key={'pagamentoPolizza'} header="Pagamento" body={
                <div key={'pagamentoPolizzaDivVis'}>
                    <div className="row">
                        <div className={isCurrentMobileDevice() ? null : "col-6"} hidden>
                            <Label color={'text-danger'}>Copia del pagamento *</Label>
                        </div>
                        <div className="row">
                                <ListaAllegatiAssicurazioni
                                    polizza={this.state.polizza}
                                    nomeAllegato={"copiaPagamentoPolizza"}
                                    tipologiaAllegato={["polizze_copia_pagamento","AssicurazioniCopiaPagamento"]}
                                    visualizzaFlag={true}
                                    datiPagamento={true}
                                    note={true}
                                />
                        </div>
                    </div>

                    </div>
                }></GenericCard>
                <GenericModal
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    modalOpen={this.state.modalOpen}
                    closeButton={this.closeModal}
                />
                </Form>
                   </div> : <GenericSpinner/>}
            </>
        )
    }
}

export declare interface GeneralFormDatiPagamentoProps {
    data?: any;
    polizza?: any;
    visualizzaFlag?: any;
    getSelectedValue?:any;
    tabNumber?: any;
    message?: any;
    tabellaUno?: any;
}
