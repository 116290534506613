import { getAumentoMassimaleResourceApi, getDefaultAxiosConfig } from 'src/app/utils/ManagerRestGateway'
import { ErrorStore } from 'src/errorBoundary/ErrorStore'

/**
 * POST Creazione aumento massimale
 * @returns
 */
export async function creaAumentoMassimale(idPolizza: any) {
  try {
    let param = {
      "param": idPolizza
    }
    let response = await getAumentoMassimaleResourceApi().mspoCreazioneAumentoMassimalePost(param, getDefaultAxiosConfig());
//    return response;
    return ErrorStore.getInstance().checkResponse(response);

  } catch (err: any) {
    ;
    if(err?.response?.data?.message){
      throw new Error("Errore durante la creazione dell'aumento del massimale " + err?.response?.data?.message);
    }else{
      let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, 500)
      throw await new Error(errorMessage.message);
    }


  }
}



