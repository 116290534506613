import { Form, Icon } from 'design-react-kit'
import * as React from 'react'
import GenericButton from 'src/frontend/app/components/GenericButton'
import GenericInput from 'src/frontend/app/components/GenericInput'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormElencoSociIscrittiAltriOrdiniStpFO extends React.Component<GeneralFormElencoSociIscrittiAltriOrdiniStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = { soci: props.composizioneSocietaria }
  }

  componentDidMount(): void {
    this.props.soci(this.state.soci)
  }

  handleChangeSocio = async (event, fieldId, id) => {
    let updatedSoci = this.state.soci.map((socio, i) => {
      if (i === id) {
        if (event.target.id === 'idNomeSocio' + id) {
          return { ...socio, isSocio: 1, idSocietaPG: this.props.idSocieta, nome: event.target.value }
        } else if (event.target.id === 'idCognomeSocio' + id) {
          return { ...socio, isSocio: 1, idSocietaPG: this.props.idSocieta, cognome: event.target.value }
        } else if (event.target.id === 'idTitoloSocio' + id) {
          return { ...socio, isSocio: 1, idSocietaPG: this.props.idSocieta, titProf: event.target.value }
        }
      } else return socio
    })
    await this.setState({ soci: updatedSoci })
    this.props.soci(updatedSoci)
  }

  //metodo che aggiunge un elemento nell'array soci
  aggiungiSocio = (soci: any) => {
    soci?.push({ idSoggettoPF: 0, idSocietaPG: null, isSocio: 1, isLegaleRappresentante: null, isDipendenteIscritto: null, nome: '', cognome: '', titProf: '' })
    this.setState({ soci: soci })
  }

  //metodo che elimina l'elemento dell'array soci
  eliminaSocio = async (soci: any, id: any) => {
    soci[id] = { ...soci[id], isSocio: 1, cancellato: true }
    await this.setState({ soci })
    this.props.soci(this.state.soci)
  }

  render() {
    const { soci } = this.state
    const { tabNumber, message } = this.props

    return (
      <div>
        {/*--------------------------------------------------------------------------- FORM PER ISCRIZIONE STP ----------------------------------------------------------------------------*/}
        <Form>
          {soci.map((socio, id) => {
            return socio.cancellato !== true ? (
              <div className="row" key={id}>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericInput
                    defaultValue={socio.nome}
                    type={'text'}
                    id={'idNomeSocio' + id}
                    placeholder={'Inserire nome socio'}
                    label={'Nome del socio non iscritto'}
                    onChange={(e) => this.handleChangeSocio(e, 'idNomeSocio' + id, id)}
                    errorMessageAlert={message}
                    currentStep={tabNumber}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericInput
                    defaultValue={socio.cognome}
                    type={'text'}
                    id={'idCognomeSocio' + id}
                    placeholder={'Inserire cognome socio'}
                    label={'Cognome del socio non iscritto'}
                    onChange={(e) => this.handleChangeSocio(e, 'idCognomeSocio' + id, id)}
                    errorMessageAlert={message}
                    currentStep={tabNumber}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                  <GenericInput
                    defaultValue={socio.titProf}
                    type={'text'}
                    id={'idTitoloSocio' + id}
                    placeholder={'Inserire titolo socio'}
                    label={'Titolo del socio non iscritto'}
                    onChange={(e) => this.handleChangeSocio(e, 'idTitoloSocio' + id, id)}
                    errorMessageAlert={message}
                    currentStep={tabNumber}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-1'}>
                  <GenericButton
                    size="xs"
                    type="button"
                    color="tertiary"
                    onClickEvent={() => this.eliminaSocio(soci, id)}
                    children={<Icon size="sm" className="mt-3" color="danger" icon="it-delete" />}
                  />
                </div>
              </div>
            ) : null
          })}
          <div className="col-6">
            <GenericButton size="xs" type="button" color="primary" label="Aggiungi socio" onClickEvent={() => this.aggiungiSocio(soci)} />
          </div>
        </Form>
        {/*-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      </div>
    )
  }
}

export declare interface GeneralFormElencoSociIscrittiAltriOrdiniStpFOProps {
  message?: any
  tabNumber?: any
  soci?: any
  idSocieta?: any
  isVisualizza?: boolean
  isModifica?: boolean
  datiIscrizione?: any
  composizioneSocietaria?: any
}
