import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import useFrontOffice from 'src/hooks/useFrontOffice'
import { PageSection, Title } from '@patternfly/react-core'
import { Label } from 'design-react-kit'
import styled from 'styled-components'

import {
  PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE,
  PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI,
  PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE,
  PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI
} from 'src/app/utils/RoutesConstants'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'

import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { responseMapperEventoInd } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import formValidationRules from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneIndividuale/FormRules'
import { convertBooleans } from 'src/utilities/utility'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { DETAILS_ACTIONS, STATI_EVENTO_NON_A_CATALOGO_VALUES } from 'src/formazione'
import GenericWizard from 'src/backoffice/app/components/GenericWizard'

// Form components
import FormDatiEvento from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneIndividuale/FormDatiEvento'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'

// Form azioni
import FormIntegrazione from 'src/backoffice/app/pages/formazione/formEventiIndividuali/FormIntegrazione'
import FormApprovazione from 'src/backoffice/app/pages/formazione/formEventiIndividuali/FormApprovazione'
import FormRigetto from 'src/backoffice/app/pages/formazione/formEventiIndividuali/FormRigetto'
import GenericCard from 'src/frontend/app/components/GenericCard'
import FormDatiIscritto from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneIndividuale/FormDatiIscritto'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`

const Spacer = () => (
  <>
    <br></br>
  </>
)
const basePath = { label: 'Formazione', link: '/backoffice/formazione' }

const myPath = {
  label: 'Evento non a Catalogo / Individuale',
  link: PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE,
}

const DettaglioAgenzia: React.FunctionComponent = (props) => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI : PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE : PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE
  const basePath = { label: 'Elenco Eventi non a Catalogo / Individuali', link: pathToElenco }
  const myPath = {
    label: 'Evento non a Catalogo / Individuale',
    link: pathToDettaglio,
  }

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const modalita = searchParams.get('modalita')
  const viewOnly = modalita !== DETAILS_ACTIONS.MODIFICA
  const [stato, setStato] = useState<any>()

  // Submit state
  const [editStatus, setEditStatus] = useState<any>()
  const [editMessage, setEditMessage] = useState<any>()
  const [isInCompilazione, setIsInCompilazione] = useState<boolean>(false)

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  useEffect(() => {
    const doRequest = async (id) => {
      const { data } = (await getFormazioneResourceApi().msfoFormazioneEventoNonACatalogoGet(id, getDefaultAxiosConfig())) as any
      const _data = await responseMapperEventoInd(data)
      if (/^\d{1,3}$/.test(_data.totOre) && _data.totOre) {
        _data.totOre += ":00";
      }
      setData(_data)
      setStato(_data?.attivitaFormativaExt?.stato)
    }
    doRequest(id)
  }, [id])

  useEffect(() => {
    setIsInCompilazione(stato === 0)
  }, [stato])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({ tab, cmp })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onBack = () => {
    history.replace(pathToElenco)
  }

  const isValid = useCallback(
    (toIndex) => {
      for (let index = 0; index <= toIndex; index++) {
        const inputs = getTabActiveFormId(refForm, index)
        const isValid = validateFormData(formData, formValidationRules, inputs)
        if (!isValid) return false
      }
      return true
    },
    [formData]
  )

  const onSubmit = useCallback(
    async (noChangeState?: boolean) => {
      try {
        if (!isValid(0)) {
          setEditStatus(STATUS.FAILURE)
          setEditMessage('Controllare i dati inseriti')
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return null
        }

        const _data = convertBooleans(formData.data)
        _data.idCorso = id
        if (!noChangeState) {
          _data.stato = 1
        } else {
          _data.stato = stato ? stato : 0
        }
        setEditStatus(STATUS.LOADING)
        if (modalita === DETAILS_ACTIONS.MODIFICA ){
          if (!_data.tipologiaAttivita) {
            _data.crediti = 0
          } else {
            if (_data.tipologiaAttivita.um === 'H') {
              const totOre = _data.totOre
              if(_data.tipologiaAttivita.cod_macro_area != 'MREL'){
              if (totOre && totOre.indexOf(':') > 0) {
                const ore = parseInt(totOre.split(':')[0])
                const minuti = parseInt(totOre.split(':')[1])
                _data.crediti = ((ore + minuti / 60) / _data.tipologiaAttivita.ore_evt_battute) * _data.tipologiaAttivita.max_cfp_evt
              } else {
                _data.crediti = _data.tipologiaAttivita.max_cfp_evt
              }

              if (_data.max) {
                _data.crediti = _data.crediti > _data.max ? _data.max : _data.crediti
              }
            }
            else{
              const ore = parseInt(totOre.split(':')[0])
              const minuti = parseInt(totOre.split(':')[1])
              if(minuti == 0){
                _data.crediti =  ore * _data.tipologiaAttivita.ore_evt_battute * _data.tipologiaAttivita.max_cfp_evt
              }
              else{
                _data.crediti =  (ore + 1) * _data.tipologiaAttivita.ore_evt_battute * _data.tipologiaAttivita.max_cfp_evt
              }
            }
            } else {
              if (_data.tipologiaAttivita.cod === 'AO' || _data.tipologiaAttivita.cod === 'AF') {
                _data.crediti = 0.25
              }
            }
          }

          _data.crediti = Math.round(_data.crediti * 1000) / 1000
      }

        await getFormazioneResourceApi().msfoFormazioneAggiornaEventoNonACatalogoCompletePost(_data, getDefaultAxiosConfig())
        setEditStatus(STATUS.SUCCESS)
        setEditMessage('Dati salvati con successo')
        setTimeout(() => {
          onBack()
        }, 500)
      } catch (e) {
        setEditStatus(STATUS.FAILURE)
        setEditMessage('Impossibile salvare i dati')
      }
      return null
    },
    [id, formData]
  )

  const forms = [
    <FormDatiEvento title="Dati Evento" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiIscritto title="Dati Iscritto" data={data} />
  ];

  const steps = [
    {
      id: 0,
      name: 'Dati Evento',
      component: forms[0],
    },
    {
      id: 1,
      name: 'Dati Iscritto',
      component: forms[1],
    },
  ]

  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        {modalita === DETAILS_ACTIONS.APPROVA && <FormApprovazione id={id} data={data} />}
        {modalita === DETAILS_ACTIONS.RICHIESTA_INTEG && <FormIntegrazione id={id} />}
        {modalita === DETAILS_ACTIONS.RIGETTA && <FormRigetto id={id} />}
        {modalita === DETAILS_ACTIONS.DETTAGLIO && stato === STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA_INTEG && (
              <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
                <Title headingLevel="h3">{data.attivitaFormativaExt.note_conferma}</Title>
              </GenericCard>
            )}
        {modalita === DETAILS_ACTIONS.DETTAGLIO && stato === STATI_EVENTO_NON_A_CATALOGO_VALUES.RIFIUTATO && (
             <GenericCard cardHeader={<Title headingLevel="h2">L'evento è stato rigettato:</Title>}>
              <Title headingLevel="h3">{data.attivitaFormativaExt.note_conferma}</Title>
             </GenericCard>
            )}
        {modalita === DETAILS_ACTIONS.MODIFICA ? (
          <>
            {data?.attivitaFormativaExt?.stato === STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA_INTEG && (
              <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
                <Title headingLevel="h3">{data.attivitaFormativaExt.note_conferma}</Title>
              </GenericCard>
            )}
            <FormSubmit
              title="Conferma modifica"
              buttonLabel="Modifica"
              alertMessage={editMessage}
              status={editStatus}
              onSubmit={() => {
                onSubmit(isInCompilazione)
              }}
            >
              <Label>Per modificare l'evento cliccare "Modifica".</Label>
            </FormSubmit>
            {isInCompilazione && (
              <FormSubmit
                title="Conferma invio"
                buttonLabel="Invia"
                alertMessage={editMessage}
                status={editStatus}
                onSubmit={() => {
                  onSubmit()
                }}
              >
                <Label>Per inviare l'evento cliccare "Invia".</Label>
              </FormSubmit>
            )}
            {forms}
            <LinkStyled>
              <Link to={pathToElenco}>Torna alla lista</Link>
            </LinkStyled>
          </>
        ) : (
          <GenericWizard
            steps={steps}
            backButtonText={'PRECEDENTE'}
            cancelButtonText={'Torna alla lista'}
            onClickNext={null}
            onClickCancel={onBack}
            height="auto"
            footer={<span></span>}
          />
        )}
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </PageSection>
  )
}

export default DettaglioAgenzia
