import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import {
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA
} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { convertUrlParamsToObject } from 'src/utilities/utility'
import { FormAccettaCambioSezione } from './formCambioSezione/FormAccettaCambioSezione'

class AccettaCambioSezione extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default",
        };
    }

    goToCambioSezione = (redirectLink: any) => {
        this.props.history.push({
            pathname: redirectLink,
        });
    }

    componentDidMount() {
        //recupero gli eventuali parametri dall'url
        var url = window.location.search?.split("?")[1]
        var urlParams = convertUrlParamsToObject(url)

        //recupero l'idIscrizione dall'url
        var idCambioSezione = urlParams?.idCambioSezione
        var idIscrizione = urlParams?.idIscrizione

        this.setState({ idCambioSezione: idCambioSezione, idIscrizione: idIscrizione })
    }

    submitMessageResult = (alert: any) => {
        const element = document.getElementById("pageSectionId");
        if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        this.setState({ message: alert.messaggio, typeMessage: alert.type })
        if (alert.type === 'success') {
            setTimeout(async () => {
                this.goToCambioSezione(PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA)
            }, 1000);
        }
    }

    render() {
        const { message, typeMessage, idIscrizione, idCambioSezione } = this.state
        return (
            <>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Elenco richieste cambio sezione", link: PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA }, { label: "Accetta", link: PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA }]} /><br></br>
                        <Title headingLevel="h1" size="4xl">Accetta cambio sezione</Title><br></br>
                        <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                        <GenericCard isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormAccettaCambioSezione messaggio={this.submitMessageResult} idIscrizione={idIscrizione} idCambioSezione={idCambioSezione} /></>} /><br></br>
                        <div className='row'>
                            <div className='col-1'>
                                <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => this.goToCambioSezione(PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA)} />
                            </div>
                            <div className='col-1'>
                                <GenericButton form={"idAccettaCambioSezione"} type={"submit"} label={"CONFERMA"} color={"primary"} isDisabled={typeMessage === 'success'} />
                            </div>
                        </div>
                    </div>
                </PageSection>
                <br></br>
                <PageSection>
                    <div className='container register-container'>
                        <Title headingLevel='h3'>Legenda</Title>
                        <p>* Campo obbligatorio</p>
                    </div>
                </PageSection>
            </>
        );
    }
};

export default AccettaCambioSezione;
