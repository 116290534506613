import * as React from 'react'
import { BackofficeLayout } from 'src/backoffice/app/BackofficeLayout/BackofficeLayout'
import GenericHeaderCenter from 'src/frontend/app/components/GenericHeaderCenter'
import GenericHeaderNav from 'src/frontend/app/components/GenericHeaderNav'
import GenericHeaderSlim from 'src/frontend/app/components/GenericHeaderSlim'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import AppRouter from './AppRouter'
import GenericFooter from 'src/frontend/app/components/GenericFooter'

const AppLayout: React.FunctionComponent = () => {

  /**
   * Se l'utente sta operando nel frontoffice visualizza i componenti in un modo.
   */
  if (TokenStore.getInstance().isFrontOffice()) {
    const name = TokenStore.getInstance().getDecodedTokenJWT().name.toUpperCase();
    return <>
      <GenericHeaderSlim userLogged={name} />
      <GenericHeaderCenter/>
      <GenericHeaderNav/>
      <AppRouter/>
      <GenericFooter/>
    </>
  }

  /**
   * Se l'utente sta operando nel frontoffice visualizza i componenti inserendo
   * in un componente BackofficeLayout che fornisce un css diverso per i form.
   */
  else {
    const name = TokenStore.getInstance().getDecodedTokenJWT().name.toUpperCase();
    return <>
      <BackofficeLayout>
        <>
          <GenericHeaderSlim userLogged={name} />
          <GenericHeaderCenter/>
          <GenericHeaderNav/>
          <AppRouter/>
          <GenericFooter/>

        </>
      </BackofficeLayout>
    </>
  }
};

export default AppLayout;
