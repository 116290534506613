import React, { useCallback, useRef, useState } from 'react'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'

import { Form } from 'design-react-kit'
import FormSubmit, { STATUS } from '../../../../../frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericSelect from 'src/frontend/app/components/Form/Select'

import { createFormData, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { useHistory } from 'react-router-dom'

const FormIscrizione: React.FunctionComponent<FormIscrizioneProps> = ({ id, propsData }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)
  const history = useHistory()
  const rules = {
    modalita: {
      isRequired: propsData?.modalita?.value === 'MISTA' ? true : false,
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const submit = useCallback(
    async (e) => {
      try {
        if(propsData?.modalita?.value === 'MISTA') {
          const inputs = ['modalita']
          const valid = validateFormData(formData, rules, inputs)
          if (!valid) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            return
          }
        }
        const data = formData?.data

        // TODO retrieve info soggetto in order to pass name, surname ... ?
        const token = TokenStore.getInstance()

        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneInserisciIscrittoPost(
          {
            idcorso: id,
            modalita: data?.modalita?.value,
          } as any,
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati, è stato raggiunto il limite di iscrizioni')
      }
    },
    [id, data]
  )
  return (
    <FormSubmit title="Richiesta iscrizione evento" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      {propsData?.modalita?.value === 'MISTA' && (
        <Form>
          <GenericSelect
            id="modalita"
            placeholder={'Selezione Modalità'}
            label={'Modalità*'}
            value={data?.modalita}
            defaultOptions={[
              { value: 'IN_SITU', label: 'In Situ' },
              { value: 'FAD', label: 'FAD' },
            ]}
            errorMessage={errors?.modalita}
            onInit={onInitInput(0)}
            onChange={handleInput}
          />
        </Form>
      )}
    </FormSubmit>
  )
}

interface FormIscrizioneProps {
  id: any
  propsData?: any
}

export default FormIscrizione
