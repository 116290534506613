import React, { useRef, useState } from 'react'
import { PofStatusFormProps } from './PofStatusForm'
import { Alert, Button, Modal, ModalBody, ModalFooter, Spinner } from 'design-react-kit'
import { createFormData, getActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import GenericInput from 'src/frontend/app/components/Form/Input'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import { aggiornaDatiHeadPiano, STATI } from 'src/model/PianiFormativi'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormValidazione: React.FunctionComponent<any> = ({ pof, newState, confirmMessage, onBack, onConfirm }) => {
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({
    suggerimenti: pof.suggerimenti,
  })
  const [errors, setErrors] = useState<any>({})
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const formData = createFormData(data, setData, errors, setErrors)

  const rules = {
    nr_prot: {
      isRequired: true,
      isNumber: true,
      min: {
        check: 1,
        error: 'Devi inserire un numero maggiore di 1',
      },
    },
    data_prot: {
      isRequired: true,
      isDate: true,
    },
    delibera_prot: {
      isRequired: true,
    },
    nr_prot_ordine: {
      isRequired: true,
    },
  }
  if (newState !== STATI.ACCETTATA) {
    rules['suggerimenti'] = {
      isRequired: true,
    }
  }

  const onInitInput = (cmp) => {
    refForm.current.push({ cmp: cmp })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const handleConfirm = () => {
    const isValid = validateFormData(formData, rules, getActiveFormId(refForm))
    if (isValid) {
      setShowConfirm(true)
    }
  }

  const title = {
    [STATI.ACCETTATA]: `Accetta ${pof.nr_invio > 1 ? `la variante del piano ` : `il piano`} formativo`,
    [STATI.RIFIUTATA]: `Rigetta ${pof.nr_invio > 1 ? `la variante del piano ` : `il piano`} formativo`,
    [STATI.RICHIESTA_INTEGRAZIONI]: 'Richiedi integrazioni',
  }
  const sTitle = {
    [STATI.ACCETTATA]: 'Note',
    [STATI.RIFIUTATA]: 'Motivo di rigetto **',
    [STATI.RICHIESTA_INTEGRAZIONI]: 'Integrazioni **',
  }

  return (
    <div className={' register-container'}>
      <h3 className={'title-form'}>{title[newState]}</h3>
      {newState != STATI.RICHIESTA_INTEGRAZIONI && (
        <>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericInput
                id="nr_prot"
                type={'number'}
                placeholder={'Numero protocollo'}
                label={'Numero protocollo**'}
                value={data.nr_prot}
                errorMessage={errors.nr_prot}
                onChange={handleInput}
                onInit={onInitInput}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-4'}>
              <GenericInput
                id="data_prot"
                type={'date'}
                placeholder={'Data Delibera'}
                label={'Data Delibera**'}
                value={data.data_prot}
                errorMessage={errors.data_prot}
                onChange={handleInput}
                onInit={onInitInput}
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-12'}>
              <TextArea
                id="delibera_prot"
                label={'Riferimento delibera  **'}
                value={data.delibera_prot}
                rows={5}
                onChange={handleInput}
                errorMessage={errors['delibera_prot']}
                onInit={onInitInput}
                labelBottom={true}
              />
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className={isCurrentMobileDevice() ? null : 'col-24'}>
          <TextArea
            id="suggerimenti"
            label={sTitle[newState]}
            value={data.suggerimenti}
            rows={5}
            onChange={handleInput}
            errorMessage={errors['suggerimenti']}
            onInit={onInitInput}
            labelBottom={true}
          />
        </div>
      </div>

      <div className="text-right actions">
        <Button color={'secondary'} onClick={onBack}>
          Annulla
        </Button>

        <Button color={'primary'} onClick={handleConfirm}>
          Conferma
        </Button>
      </div>
      <Modal isOpen={showConfirm} toggle={() => setShowConfirm(false)} centered>
        <ModalBody>
          <p>{confirmMessage}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowConfirm(false)}>
            Annulla
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setShowConfirm(false)
              onConfirm(data)
            }}
          >
            Conferma
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export const STATE_FORM = {
  APPROVA: 1,
  RIGETTA: 2,
  RICHIEDI_INFO: 3,
  SEGNALA: 4,
}

const FormVerificaPof: React.FunctionComponent<PofStatusFormProps> = ({ pof, onUpdate, startAction }) => {
  const [state, setState] = useState<number>(startAction > 0 && startAction < 4 ? startAction : 0)
  const [error, setError] = useState<any>(null)

  const onConfirm = async (data, stato) => {
    const oldState = state
    setState(10)
    setError('')
    try {
      data.stato = stato
      await aggiornaDatiHeadPiano(pof.id, data)
      onUpdate()
    } catch (e) {
      setError('Impossibile salvare i dati')
      setState(oldState)
    }
  }
  return (
    <>
      {state === 0 && (
        <div className={'row-actions'}>
          <Button color={'warning'} onClick={() => setState(3)}>
            Richiedi Integrazioni
          </Button>
          <Button color={'danger'} onClick={() => setState(2)}>
            Rigetta
          </Button>
          <Button color={'primary'} onClick={() => setState(1)}>
            Approva
          </Button>
        </div>
      )}
      {state === 1 && (
        <FormValidazione
          pof={pof}
          newState={STATI.ACCETTATA}
          confirmMessage={"Confermi l'approvazione del piano formativo?"}
          onBack={() => setState(0)}
          onConfirm={(data) => onConfirm(data, STATI.ACCETTATA)}
        />
      )}
      {state === 2 && (
        <FormValidazione
          pof={pof}
          newState={STATI.RIFIUTATA}
          confirmMessage={'Confermi il rigetto del piano formativo?'}
          onBack={() => setState(0)}
          onConfirm={(data) => onConfirm(data, STATI.RIFIUTATA)}
        />
      )}
      {state === 3 && (
        <FormValidazione
          pof={pof}
          newState={STATI.RICHIESTA_INTEGRAZIONI}
          confirmMessage={'Confermi la richiesta di integrazioni?'}
          onBack={() => setState(0)}
          onConfirm={(data) => onConfirm(data, STATI.RICHIESTA_INTEGRAZIONI)}
        />
      )}
      {state === 10 && (
        <div className={'saving-data'}>
          <Spinner active={true} label={''} />
          <p>Salvataggio dati in corso</p>
        </div>
      )}
      {error && (
        <div className={'pt-4'}>
          <Alert color={'danger'}>{error}</Alert>
        </div>
      )}
    </>
  )
}

export default FormVerificaPof
