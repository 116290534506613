'use strict';

var utils = require('../../../utils/utils');
var BaseXform = require('../base-xform');

var UnderlineXform = module.exports = function(model) {
  this.model = model;
};

UnderlineXform.Attributes = {
  single: {},
  double: { val: 'double' },
  singleAccounting: { val: 'singleAccounting' },
  doubleAccounting: { val: 'doubleAccounting' }
};

utils.inherits(UnderlineXform, BaseXform, {

  get tag() { return 'u'; },

  render: function(xmlStream, model) {
    model = model || this.model;

    if (model === true) {
      xmlStream.leafNode('u');
    } else {
      var attr = UnderlineXform.Attributes[model];
      if (attr) {
        xmlStream.leafNode('u', attr);
      }
    }
  },

  parseOpen: function(node) {
    if (node.name === 'u') {
      this.model = node.attributes.val || true;
    }
  },
  parseText: function() {
  },
  parseClose: function() {
    return false;
  }
});
