/*
 * Utility per gestire dati salvati nella sessione o nello storage dati
 */


export const setSessionItem = (code:string,value:any)=>{
  if(window.sessionStorage){
    window.sessionStorage.setItem(code,JSON.stringify(value));
  }
}

export const getSessionItem = (code):any=>{
   if(window.sessionStorage){
     let value = window.sessionStorage.getItem(code);
     if(value){
        return JSON.parse(value);
     }
   }
   return null;
}



