import React from 'react'
import GenericTitle from 'src/frontend/app/components/GenericTitle'

export const legenda =([
    {title: 'Legenda', Size:'h2'},
    {title: '* Campo obbligatorio', Size:'p'},
    {title: '** Campo non modificabile compilato automaticamente', Size:'p'},
])

export const listLegenda =
[
  <div key={'legenda'} className="Card">
    {legenda.map((title, i) => {
    return (<GenericTitle key={i} title={legenda[i].title} Size={legenda[i].Size}/>)
      })}
  </div>
]

export const legendaCampiObbligatori =([
  {title: 'Legenda', Size:'h2'},
  {title: '* Campo obbligatorio', Size:'p'},
])

export const listLegendaCampiObbligatori =
[
<div key={'legenda'} className="Card">
  {legendaCampiObbligatori.map((title, i) => {
  return (<GenericTitle key={i} title={legendaCampiObbligatori[i].title} Size={legendaCampiObbligatori[i].Size}/>)
    })}
</div>
]
