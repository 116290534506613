import * as React from 'react'
import { Form } from '@patternfly/react-core'
import { GenericTextArea } from '../../../components/GenericTextArea'
import { richiestaIntegrazione } from 'src/processes/assicurazioni/Assicurazione'
import GenericSelect from 'src/backoffice/app/components/GenericSelect'
import { PATH_TO_BO_POLIZZE_ELENCO } from 'src/app/utils/RoutesConstants'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import { GenericSpinner } from 'src/backoffice/app/components'
import { inputRequiredValidation } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const yes_no_options = [
    { label: "Si", value: "Si"},
    { label: "No", value: "No"}
]

export class FormRichiediIntegrazioniPolizza extends React.Component<GeneralFormRichiediPolizzaProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: "",
            dateTo: "",
            polizzaIndividuale: false,
            errorFlag: false,

        };
      }

    async componentDidMount(): Promise<void> {
        this.setState({ isLoaded : true })
    }

    handleSubmit = async event => {
        var requiredFields = [
            { value: event.target.idMotivoRichiestaIntPolizza.value, label: 'Motivo' },
            { value: event.target.sbloccaTabella.value, label: 'Sblocca Tabella 1' }
        ]
        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        else {
            await this.setState({isLoaded:false});

            var idPolizza = this.props.idPolizza
            var parametriRichiesta = {
                idPolizza: idPolizza,
                sbloccaTabella: (event.target.sbloccaTabella.value === "No") ? 0 : 1,
                note: event.target.idMotivoRichiestaIntPolizza.value ? event.target.idMotivoRichiestaIntPolizza.value : "",
            }
            event.preventDefault();
            try {
                event.preventDefault();
                await richiestaIntegrazione(parametriRichiesta).then(response=>{
                })
                await this.setState({errorFlag: false})
                this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
                await this.showModal(true, "Messaggio","Richesta integrazione inviata");
                await this.setState({isLoaded:true})

                return false;
            }
            catch (err:any) {
                event.preventDefault();
                await this.setState({errorFlag: true})
                await this.showModal(true, "Errore",err.message);
                return false;
            }
        }
    }

    closeModal = () => {
        this.setState({modalOpen:false});
        if(this.state.errorFlag === false) window.location.href = PATH_TO_BO_POLIZZE_ELENCO;
    }

    showModal = async (booleanValue, title, text) => {
        await this.setState({
            modalOpen:booleanValue,
            modalTitle: title,
            modalText:text,
            isLoaded: true,
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.isLoaded ?
                        <>
                            <Form id='idRichiediPolizza' onSubmit={this.handleSubmit}>
                                <div className="row p-2">
                                    <GenericTextArea className={isCurrentMobileDevice() ? 'col-12' : 'w-50' } id={"idMotivoRichiestaIntPolizza"} label={"Motivo"} name={"idMotivoRichiestaInt"} placeholder={"Integrazioni richieste"} ariaLabel={'textarea-idMotivoRichiestaInt'} isRequired />
                                </div>
                                <div className="row p-2">
                                    <GenericSelect className={isCurrentMobileDevice() ? 'col-12' : 'w-25' } list={yes_no_options} label={"Sblocca Tabella 1"} id={'sbloccaTabella'} placeholder={'Selezionare una opzione'} isRequired />
                                </div>
                                <GenericModal
                                    title={this.state.modalTitle}
                                    text={this.state.modalText}
                                    modalOpen={this.state.modalOpen}
                                    closeButton={this.closeModal}
                            />
                            </Form>
                        </>
                    : <GenericSpinner/>
                }

            </div>
        )
    }
}

export declare interface GeneralFormRichiediPolizzaProps {
    messaggio?: any;
    idPolizza?: any;
    idSoggetto?: any;
}
