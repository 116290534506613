import './App.css'
import '../frontend/style/style.scss'
import '../frontend/style/type.scss'
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css'
import 'typeface-titillium-web'
import 'typeface-roboto-mono'
import 'typeface-lora'
import React, { FunctionComponent } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import type Keycloak from 'keycloak-js'
import type KeycloakAdminClient from '@keycloak/keycloak-admin-client'
import { AdminClient } from 'src/keycloak/context/auth/AdminClient'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import AppLayout from './AppLayout'
import { ErrorStore } from 'src/errorBoundary/ErrorStore'

export const mainPageContentId = "kc-main-content-page-container";

export type AdminClientProps = {
  keycloak: Keycloak;
  adminClient: KeycloakAdminClient;
};

const AppContexts: FunctionComponent<AdminClientProps> = ({
  keycloak,
  adminClient,
}) => (
  <Router>
    <AdminClient.Provider value={{ keycloak, adminClient }}>
      <AppLayout/>
    </AdminClient.Provider>
  </Router>
);

export const App = ({ keycloak, adminClient }: AdminClientProps) => {
  TokenStore.initialize(keycloak.token, keycloak, adminClient);
  ErrorStore.initialize(ErrorStore.getInstance().getSessionTitleError(), ErrorStore.getInstance().getSessionMessageError(), ErrorStore.getInstance().getSessionBackUrlError(), ErrorStore.getInstance().getSessionRestCall());

  // else ErrorStore.initialize();
  return (
    <AppContexts keycloak={keycloak} adminClient={adminClient}/>
    );
};

