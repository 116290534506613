import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {
  convertFlagLiberaProfPubblicaAmministrazioneToString,
  getBigIntToConvertToStringSiNo
} from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormProfessione extends React.Component<GeneralFormProfessioneProps, any> {
  render() {
    const { messaggio, iscrizione } = this.props
    return (
      <>
        {iscrizione === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idDipStato'}
                    label={'Dipendente dello Stato o di altra pubblica amministrazione¹'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagDipendentePubblicaAmministrazione)}
                    isReadOnly
                  />
                </div>
                {/*--------- Campo visibile solo se il flag Dipendente dello Stato o di altra pubblica amministrazione è "Si" ---------*/}
                <div className={isCurrentMobileDevice() ? null : 'col-6'} hidden={iscrizione.flagDipendentePubblicaAmministrazione !== 1}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idOrdinamentoAmministrazione'}
                    label={'L’ordinamento della amministrazione e di appertenenza consente/prevede l’esercizio della libera professione'}
                    value={convertFlagLiberaProfPubblicaAmministrazioneToString(iscrizione.flagLiberaProfPubblicaAmministrazione)}
                    isReadOnly
                  />
                </div>
                {/*--------------------------------------------------------------------------------------------------------------------*/}
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idLiberoProfessionista'}
                    label={'Libero Professionista'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagLiberoProfessionista)}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idProfessionistaFormaAssociata'}
                    label={'Professionista in forma associata'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagLiberoProfessionistaInFormaAssociata)}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idSocioSTP'}
                    label={'Socio di STP'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagSocioStp)}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idDipendenteSTP'}
                    label={'Dipendente di STP'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagDipendenteStp)}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idDipendentePrivato'}
                    label={'Dipendente privato'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagDipendentePrivato)}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idNonEsercente'}
                    label={'Non Esercente l’attività professionale di cui all’art 2 dell’ordinamento'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagIncompatibilitaEsercenteAttivitaProfessionale)}
                    isReadOnly
                  />
                </div>
              </div>
              <div>
                <br></br>
                <small>
                  (¹) Ai i sensi dell’art. 3, comma 4, l. n. 3/1976, “I dottori agronomi ed i dottori forestali dipendenti dello Stato o di altra pubblica
                  amministrazione possono, a loro richiesta, essere iscritti all’albo. Nei casi in cui, secondo gli ordinamenti loro applicabili, è vietato di
                  norma l’esercizio della libera professione, l’iscrizione avviene con annotazione a margine attestante il loro stato giuridico-professionale.
                  Questi iscritti non possono esercitare la libera professione, salvi i casi previsti dagli ordinamenti loro applicabili
                </small>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormProfessioneProps {
  iscrizione?: any
  messaggio?: any
}
