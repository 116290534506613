import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PageSection } from '@patternfly/react-core'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericInput from 'src/frontend/app/components/Form/Input'
import { getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'

import PofStatusForm from './FormPof/PofStatusForm'
import { DettagliPofStyled } from './FormPof/styles'
import { PATH_TO_BO_ELENCO_POF, PATH_TO_FO_ELENCO_POF } from 'src/app/utils/RoutesConstants'
import { STATE_FORM } from './FormPof/FormVerificaPof'
import FormPOF from '../../../../frontend/app/pages/iscrizioneAlboEF/FormPOF'
import { FormTopbar } from '../../../../frontend/app/components/Theme'
import { Button } from 'design-react-kit'
import { getNomeEnteFormazione } from 'src/model/PianiFormativi'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'

export const ACTION_RICHIEDI_INFO = STATE_FORM.RICHIEDI_INFO
export const ACTION_RIGETTA = STATE_FORM.RIGETTA
export const ACTION_APPROVA = STATE_FORM.APPROVA
export const ACTION_SEGNALA = STATE_FORM.SEGNALA

const DettagliPOF: React.FunctionComponent = (props) => {
  const location = useLocation<{ data: any }>()
  const history = useHistory()
  const [pofData, setPofData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [version, setVersion] = useState<number>(0)
  const [startAction, setStartAction] = useState<number>(0)

  const Spacer = () => (
    <>
      <br></br>
    </>
  )

  useEffect(() => {
    const loadData = async (id) => {
      setIsLoading(true)
      try {
        const { data } = await getFormazioneResourceApi().msfoFormazioneHeadPformativoGet(id)
        setPofData({
          ...data.headPFormativo,
          pFormativo2016List: data.pFormativo2016List,
          previCostiAttFormativaList: data.previCostiAttFormativaList,
        })
      } catch (e) {
        history.push(PATH_TO_BO_ELENCO_POF)
      }
      setIsLoading(false)
    }
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id')
    const action = searchParams.get('action')
    if (action) {
      setStartAction(parseInt(action))
    }
    if (id) {
      loadData(id)
    }
  }, [location, version])

  const onBack = () => {
    history.push(PATH_TO_BO_ELENCO_POF)
  }


  const isSegnalato = pofData?.stato === 98 || pofData?.stato === 5

  return (
    <PageSection>
      <div className="container">
        <GenericBreadCrumb paths={[{ label: 'Piani Formativi', link: PATH_TO_BO_ELENCO_POF }, { label: 'Dettagli piano formativo' }]} />
        <FormTopbar>
          <h1>Piano Formativo</h1>
          <div className={'actions'}>
            <Button onClick={() => history.push(TokenStore.getInstance().isCurrentAccountCONAF() ? PATH_TO_BO_ELENCO_POF : PATH_TO_FO_ELENCO_POF)}>
              Torna alla lista
            </Button>
          </div>
        </FormTopbar>

        {isLoading && <GenericSpinner />}
        {!isLoading && pofData && (
          <DettagliPofStyled>
            <GenericCard cardHeader={`${pofData.nr_invio > 1 ? `Variante piano ` : `Piano`} formativo anno ${pofData.anno} - ${getNomeEnteFormazione(pofData)}`}>
              {pofData.resp_formazione && <GenericInput isDisabled={true} label={'Responsabile Formazione'} value={pofData.resp_formazione} type={'text'} />}

              {isSegnalato && (
                <>
                  <h2>POF segnalato - Motivazione:</h2>
                  <Spacer /> <h3>{pofData?.integr_descr_gen_pform}</h3>
                </>
              )}
              <PofStatusForm startAction={startAction} pof={pofData} onUpdate={() => setVersion(version + 1)} />
            </GenericCard>

            <FormPOF saving={false} data={pofData} setData={setPofData} isDisabled={true} />
          </DettagliPofStyled>
        )}
      </div>
    </PageSection>
  )
}

export default DettagliPOF
