import { Form } from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import { getCodiciAteco } from 'src/processes/CodiciAteco'
import { getFormeGiuridiche } from 'src/processes/FormeGiuridiche'
import { getOrdineBySiglaProvinciaFO } from 'src/processes/Ordini'
import { getCodiceAtecoByIdFo, getFormaGiuridicaByIdFo } from 'src/processes/ProcessoIscrizione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDatiGeneraliStpFO extends React.Component<GeneralFormDatiGeneraliStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      listaCodiciAteco: [],
      listaFormeGiuridiche: [],
      listaOrdiniTerritoriali: [],
      dataLoaded: false,
    }
  }

  async componentDidMount() {
    //chiamata al servizio che restituisce la forma giuridica per id
    var idFormaGiuridica = this.props.datiIscrizione.soggettoDTO.formaGiuridica
    if (idFormaGiuridica && idFormaGiuridica !== null) {
      await getFormaGiuridicaByIdFo(idFormaGiuridica)
        .then((response: any) => {
          var formaGiuridica = { label: '', value: '' }
          formaGiuridica = {
            label: response.data.descrizione,
            value: response.data.id,
          }
          this.setState({ formaGiuridica })
        })
        .catch(() => {
          this.setState({ dataLoaded: true })
        })
    }

    //chiamata al servizio che restituisce il codice ateco per id
    var idCodiceAteco = this.props.datiIscrizione.soggettoDTO.codAteco
    if (idCodiceAteco && idCodiceAteco !== null) {
      await getCodiceAtecoByIdFo(idCodiceAteco)
        .then((response: any) => {
          var codiceAteco = { label: '', value: '' }
          codiceAteco = {
            label: response.data.cod + ' (' + response.data.descr + ')',
            value: response.data.cod,
          }
          this.setState({ codiceAteco })
        })
        .catch(() => {
          this.setState({ dataLoaded: true })
        })
    }

    //chiamata al servizio che restituisce la lista delle forme giuridiche
    getFormeGiuridiche()
      .then((response: any) => {
        var formeGiuridiche = [{ label: '', value: '' }]

        if (response)
          response.data.map((formaGiuridica, id) => {
            return (formeGiuridiche[id] = {
              label: formaGiuridica.descrizione,
              value: formaGiuridica.id,
            })
          })
        this.setState({ listaFormeGiuridiche: formeGiuridiche })
      })
      .catch(() => {
        this.setState({ dataLoaded: true })
      })

    //chiamata al servizio che restituisce la lista dei codici ATECO
    getCodiciAteco()
      .then((response: any) => {
        var codiciAteco = [{ label: '', value: '' }]

        if (response)
          response.data.map((codiceAteco, id) => {
            return (codiciAteco[id] = {
              label: codiceAteco.cod + ' (' + codiceAteco.descr + ')',
              value: codiceAteco.cod,
            })
          })
        this.setState({ listaCodiciAteco: codiciAteco })
      })
      .catch(() => {
        this.setState({ dataLoaded: true })
      })

    var provinciaSedeLegale = this.props.provinciaSedeLegale && this.props.provinciaSedeLegale !== '' ? this.props.provinciaSedeLegale.value : ''
    await getOrdineBySiglaProvinciaFO(provinciaSedeLegale)
      .then((response: any) => {
        this.setState({ listaOrdiniTerritoriali: [{ label: response.data.denominazioneGruppo, value: response.data.codiceProvinciaResidenza }] })
      })
      .catch(() => {
        this.setState({ dataLoaded: true })
      })

    if (this.props.provinciaSedeLegale) this.setState({ provinciaSedeLegaleSelected: true, svuotaOrdineTerritoriale: false })

    var soggetto = this.props.datiIscrizione?.soggettoDTO
    var formaGiuridicaSelected = { label: soggetto?.formaGiuridica, value: soggetto?.formaGiuridica }
    var codiceAtecoSelected = this.props.codiceAtecoSelected

    var ordineTerritoriale = this.props.ordineTerritoriale

    this.setState({
      dataLoaded: true,
      formaGiuridicaSelected: formaGiuridicaSelected,
      codiceAtecoSelected: codiceAtecoSelected,
      ordineTerritoriale: ordineTerritoriale,
      soggetto: soggetto,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.provinciaSedeLegale !== prevProps.provinciaSedeLegale) {
      this.setState({ provinciaSedeLegaleSelected: false, svuotaOrdineTerritoriale: false })

      //chiamata al servizio 'getOrdineBySiglaProvincia' che restituisce l'ordine territoriale a partire dalla sigla della provincia della sede legale
      getOrdineBySiglaProvinciaFO(this.props.provinciaSedeLegale).then((response: any) => {
        this.setState({ provinciaSedeLegaleSelected: true, svuotaOrdineTerritoriale: true })
        this.setState({ listaOrdiniTerritoriali: [{ label: response.data.denominazioneGruppo, value: response.data.codiceProvinciaResidenza }] })
      })
    }
  }

  handleChange = (event, fieldId, type) => {
    if (type === 'input' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.value })
    else if (type === 'checkbox' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.checked })
    else if (type === 'file' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.value })
    else if (type === 'select' && fieldId === 'idFormaGiuridica') this.props.formData({ id: fieldId, value: event.value })
    else if (type === 'select' && fieldId === 'idCodAteco') this.props.formData({ id: fieldId, value: event.value })
    else if (type === 'select' && fieldId === 'idOrdineTerritorialeCompetente') this.props.formData({ id: fieldId, value: event.value })
  }

  render() {
    const {
      listaCodiciAteco,
      listaFormeGiuridiche,
      listaOrdiniTerritoriali,
      soggetto,
      codiceAteco,
      ordineTerritoriale,
      svuotaOrdineTerritoriale,
      dataLoaded,
      formaGiuridica,
    } = this.state
    const { tabNumber, provinciaSedeLegale, flagReiscrizione } = this.props

    return (
      <>
        {dataLoaded === true ? (
          <div>
            {/*----------------------------------------------------------------------- FORM PER ISCRIZIONE STP -------------------------------------------------------------------*/}
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.ragioneSociale}
                    errorMessageAlert={this.props.message}
                    type={'text'}
                    id={'idRagioneSociale'}
                    placeholder={'Ragione sociale'}
                    label={'Ragione sociale¹ *'}
                    onChange={(e) => this.handleChange(e, 'idRagioneSociale', 'input')}
                    currentStep={tabNumber}
                    isRequired
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    defaultValue={flagReiscrizione === 1 ? null : formaGiuridica?.label && formaGiuridica?.label !== '' ? formaGiuridica : null}
                    errorMessageAlert={this.props.message}
                    elementId={'idFormaGiuridica'}
                    placeholder={'Selezionare forma giuridica'}
                    label={'Forma giuridica*'}
                    onChange={(e) => this.handleChange(e, 'idFormaGiuridica', 'select')}
                    currentStep={tabNumber}
                    defaultOptions={listaFormeGiuridiche}
                    isRequired
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.codiceFiscaleAzienda}
                    errorMessageAlert={this.props.message}
                    type={'cf'}
                    id={'idCodiceFiscaleAzienda'}
                    placeholder={'Codice fiscale azienda'}
                    label={'Codice fiscale azienda*'}
                    onChange={(e) => this.handleChange(e, 'idCodiceFiscaleAzienda', 'input')}
                    currentStep={tabNumber}
                    isRequired
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.partitaIva}
                    errorMessageAlert={this.props.message}
                    type={'pIva'}
                    id={'idPartitaIva'}
                    placeholder={'Partita IVA'}
                    label={'Partita IVA*'}
                    onChange={(e) => this.handleChange(e, 'idPartitaIva', 'input')}
                    currentStep={tabNumber}
                    isRequired
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericSelect
                    defaultValue={flagReiscrizione === 1 ? null : codiceAteco}
                    errorMessageAlert={this.props.message}
                    elementId={'idCodAteco'}
                    placeholder={'Selezionare codice ATECO'}
                    label={'Codice ATECO*'}
                    defaultOptions={listaCodiciAteco}
                    onChange={(e) => this.handleChange(e, 'idCodAteco', 'select')}
                    currentStep={tabNumber}
                    isSearchable
                    isRequired
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.numeroRea}
                    errorMessageAlert={this.props.message}
                    type={'text'}
                    id={'idNumeroRea'}
                    placeholder={'Numero REA'}
                    label={'Numero REA*'}
                    onChange={(e) => this.handleChange(e, 'idNumeroRea', 'input')}
                    currentStep={tabNumber}
                    isRequired
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.codiceSezioneSpecialeProfessionisti}
                    errorMessageAlert={this.props.message}
                    type={'text'}
                    id={'idCodiceSezioneSpecialeProfessionisti'}
                    placeholder={'Codice sezione speciale professionisti'}
                    label={'Codice sezione speciale professionisti*'}
                    onChange={(e) => this.handleChange(e, 'idCodiceSezioneSpecialeProfessionisti', 'input')}
                    currentStep={tabNumber}
                    isRequired
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.dataIscrCciaa}
                    type={'date'}
                    id={'idDataIscrCciaa'}
                    placeholder={'Data iscrizione CCIAA'}
                    label={'Data iscrizione CCIAA'}
                    onChange={(e) => this.handleChange(e, 'idDataIscrCciaa', 'input')}
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericInput
                    defaultValue={flagReiscrizione === 1 ? null : soggetto?.comuCciaa}
                    type={'text'}
                    id={'idComuCciaa'}
                    placeholder={'Città CCIAA'}
                    label={'Città CCIAA'}
                    onChange={(e) => this.handleChange(e, 'idComuCciaa', 'input')}
                  />
                </div>
              </div>
              {this.state.provinciaSedeLegaleSelected ? (
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericSelect
                      defaultValue={!svuotaOrdineTerritoriale && ordineTerritoriale ? ordineTerritoriale : undefined}
                      errorMessageAlert={this.props.message}
                      elementId={'idOrdineTerritorialeCompetente'}
                      placeholder={'Ordine territoriale richiesto'}
                      label={'Ordine territoriale richiesto*'}
                      defaultOptions={listaOrdiniTerritoriali}
                      isDisabled={!provinciaSedeLegale}
                      onChange={(e) => this.handleChange(e, 'idOrdineTerritorialeCompetente', 'select')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                </div>
              ) : null}
            </Form>
            {/*-------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
          </div>
        ) : (
          <GenericSpinner />
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiGeneraliStpFOProps {
  message?: any
  tabNumber?: number
  provinciaSedeLegale?: any
  formData?: any
  isVisualizza?: boolean
  isModifica?: boolean
  datiIscrizione?: any
  codiceAtecoSelected?: any
  ordineTerritoriale?: any
  flagReiscrizione?: any
}
