import { Alert } from 'design-react-kit'
import React from 'react'

class GenericAlert extends React.Component<GeneralAlertProps, any>{
    render() {
        return (
          <Alert id={this.props.id} color={this.props.color} hidden={this.props.hidden}>
           {this.props.label}
          </Alert>
        );
      }
    }

    export declare interface GeneralAlertProps {
        label?: any;
        /**
         * @description Indica il tipo di messaggio
         * @param danger
         * @param success
         * @param info
         * @param warning
         */
        color?: string;
        hidden?: boolean;
        id?: any;
    }

export default GenericAlert;
