import { QuoteOrdiniDTO } from '@parsec/msga'
import { getAnagrafeResourcesApi, getDefaultAxiosConfig } from 'src/app/utils/ManagerRestGateway'

/**
 * POST Salvataggio dati quota iscrizione
 * @returns
 *
 */
export async function comunicaQuotaIscrizione(quoteOrdiniDTO: any) {
  try {
    const params: QuoteOrdiniDTO = {
      "categoria": quoteOrdiniDTO.categoria,
      "quotaAssociativa": quoteOrdiniDTO.quotaAssociativa,
      "anno": quoteOrdiniDTO.anno,
      "codiceGruppo": quoteOrdiniDTO.codiceGruppo
    }

    const response = await getAnagrafeResourcesApi().msgaAnagrafeSaveQuotaAssociativaOrdinePost(params, getDefaultAxiosConfig());
    return response;
  } catch (err: any) {

    console.error("Errore durante la chiamata per il salvataggio della quota di iscrizione " + err);
    throw new Error("Errore durante la chiamata per il salvataggio della quota di iscrizione " + err);

  }
}
