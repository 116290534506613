import * as React from 'react'
import '../../../../../backoffice/style/style.scss'
import { Form, Title } from '@patternfly/react-core'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericCard from 'src/backoffice/app/components/GenericCard'
import { conversioneIntToSiNo } from 'src/utilities/utility'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { AvatarContainer, AvatarIcon, Callout, CalloutText, CalloutTitle } from 'design-react-kit'
import { getPolizzaById, getPolizzaStoricaById } from 'src/processes/assicurazioni/Assicurazione'
import { AppUtils } from 'src/app/utils/FormUtils'
import { getIscrizioneByIdBO } from 'src/processes/ProcessoIscrizione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormRiepilogo extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: props.activeTab,
            tabClick: props.tabClick,
            prevData: props.prevData,
            polizza: props.polizza,
            visualizzaFlag : props.visualizzaFlag,
            modificaDatiFlag:props.modificaDatiFlag,
            fields: {
              operaStudioAssociato: null,
              codiceStudioAssociato: "",
              ragioneSocialeStudioAssociato: "",
              iniziali: "MR",
            },
            errors: {},
            isLoaded : false,
        }
    }

    async componentDidMount(): Promise<void> {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        let name = this.props.name
        let lastname = this.props.lastname
        let initials = this.props.name.charAt(0).toUpperCase()+this.props.lastname.charAt(0).toUpperCase()
        this.setState({name:name, lastname:lastname, initials:initials})
        this.setState({isLoaded: false})
          //Dati del token e come prendere le info...
        const keyData = TokenStore.getInstance().getDecodedTokenJWT();
        this.state.fields.nome = keyData.given_name;
        this.state.fields.cognome = keyData.family_name;
        this.state.fields.codiceFiscale = keyData.fiscalNumber;
        this.state.fields.genere = keyData.gender;
        this.state.fields.iniziali = keyData.given_name.charAt(0)+keyData.family_name.charAt(0);
        this.state.fields.dataNascita = "01-01-1990";
        let fields = this.state.fields;
        fields['idPolizza'] = this.props.data.id;
        this.setState({fields: this.props.data})
        if(!this.props.storicoFlag) {

            await getPolizzaById(fields['idPolizza']).then(async (response: any)=> {

                    fields.codiceFiscale = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;
                    fields.operaStudioAssociato = {label: (response.operaStudioAssociato) ? 'Si' : 'No', value: (response.operaStudioAssociato) ? 'Si' : 'No'};
                    fields.altrePolizze = {label: conversioneIntToSiNo(response.numeroPolizaAltri), value: conversioneIntToSiNo(response.numeroPolizaAltri)};
                    fields.idCategoriaDue = {label: (response.categoria === 2) ? 'Si' : 'No', value: (response.categoria === 2) ? 'Si' : 'No'};
                    fields.maternita = {label: conversioneIntToSiNo(response.maternita), value: conversioneIntToSiNo(response.maternita)};
                    fields.paternita = {label: conversioneIntToSiNo(response.paternita), value: conversioneIntToSiNo(response.paternita)};
                    fields.privacy = (response.privacy === 1) ? true : false;
                    fields.haLettoCircostanza = (response.haLettoCircostanza === 1) ? true : false;

                    fields['dataRichiesta'] = response.dataRichiesta;
                    fields['idIscrizione'] = response.idIscrizione;
                    fields['categoria'] = response.categoria;

                    if(response.associatoConConaf === 1){

                      fields['vrcConaf'] = response.vrcConaf;
                      fields['vopConaf'] = response.vopConaf;
                      fields['fasciaPremio'] = response.fasciaPremioConaf;
                      fields['premio'] = response.premioConaf;
                      fields['massimale'] = response.massimaleConaf;
                      fields['valoreRischio'] = response.valoreRischioConaf;
                      this.setState({polizzaCollettiva: true})
                    }

                    if(response.associatoConAltri === 1){
                      fields['vrcConaf'] = response.vrcConaf;
                      fields['vopConaf'] = response.vopConaf;
                      fields['premio'] = response.premioAltri;
                      fields['fasciaPremio'] = response.fasciaPremioConaf;
                      fields['massimale'] = response.massimaleAltri;
                      fields['valoreRischio'] = response.valoreRischioAltri;
                      this.setState({polizzaIndividuale: true})
                    }
                    await this.setState({polizza: response,fields:fields, isLoaded: true});
                    this.props.getSelectedValue(fields);

            }).catch(() => {
                            setTimeout(() => {
                                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                            }, 3000);
            })
        } else {

            await getPolizzaStoricaById(fields['idPolizza']).then(async (response: any)=> {

                    fields.codiceFiscale = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;
                    fields.operaStudioAssociato = {label: (response.operaStudioAssociato) ? 'Si' : 'No', value: (response.operaStudioAssociato) ? 'Si' : 'No'};
                    fields.altrePolizze = {label: conversioneIntToSiNo(response.numeroPolizaAltri), value: conversioneIntToSiNo(response.numeroPolizaAltri)};
                    fields.idCategoriaDue = {label: (response.categoria === 2) ? 'Si' : 'No', value: (response.categoria === 2) ? 'Si' : 'No'};
                    fields.maternita = {label: conversioneIntToSiNo(response.maternita), value: conversioneIntToSiNo(response.maternita)};
                    fields.paternita = {label: conversioneIntToSiNo(response.paternita), value: conversioneIntToSiNo(response.paternita)};
                    fields.privacy = (response.privacy === 1) ? true : false;
                    fields.haLettoCircostanza = (response.haLettoCircostanza === 1) ? true : false;

                    fields['dataRichiesta'] = response.dataRichiesta;
                    fields['idIscrizione'] = response.idIscrizione;
                    fields['categoria'] = response.categoria;

                    if(response.associatoConConaf === 1){

                    fields['vrcConaf'] = response.vrcConaf;
                    fields['vopConaf'] = response.vopConaf;
                    fields['fasciaPremio'] = response.fasciaPremioConaf;
                    fields['premio'] = response.premioConaf;
                    fields['massimale'] = response.massimaleConaf;
                    fields['valoreRischio'] = response.valoreRischioConaf;
                    this.setState({polizzaCollettiva: true})
                    }

                    if(response.associatoConAltri === 1){
                    fields['vrcConaf'] = response.vrcConaf;
                    fields['vopConaf'] = response.vopConaf;
                    fields['premio'] = response.premioAltri;
                    fields['fasciaPremio'] = response.fasciaPremioConaf;
                    fields['massimale'] = response.massimaleAltri;
                    fields['valoreRischio'] = response.valoreRischioAltri;
                    this.setState({polizzaIndividuale: true})
                    }
                    await this.setState({polizza: response,fields:fields, isLoaded: true});
                    this.props.getSelectedValue(fields);

            }).catch(() => {
                            setTimeout(() => {
                                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                            }, 3000);
            })
        }
        if (fields['idIscrizione']) {
            if(fields['idIscrizione']) {

                await getIscrizioneByIdBO(fields['idIscrizione'], null).then(async (response: any) => {
                if(response.data.iotDTO.numeroIscrizioneOrdine) {
                    await this.setState({numeroIscrizione: response.data.iotDTO.numeroIscrizioneOrdine})
                }
                })
            }
        }
    }

    render() {
        return (
            <>
                {this.state.isLoaded ?
                <div>
                <Form id="formPolizza" key={"formDatiPolizza"} className="row" hidden={(!this.state.polizza)}>
                    <GenericCard className="cardStyle" key={'tabellaUno'} header={
                    <div className='justify-content-between d-flex'>
                    <p>{'Tabella 1'}</p>
                    </div>
                    }
                    body={
                    <div className="row">
                        <div className={isCurrentMobileDevice() ? "p-2" : "col-2"}>
                                <AvatarContainer>
                                    <AvatarIcon color='orange' size='xl'>
                                    <p aria-hidden='true'>{this.state.initials}</p>
                                    </AvatarIcon>
                                </AvatarContainer>
                        </div>
                        <div className={isCurrentMobileDevice() ? "p-2" : "col-4"}>
                            <div><h2>{this.state.name+" "+this.state.lastname}</h2></div>
                            <div><h6>N. Polizza {(this.state.fields.idPolizza) ? this.state.fields.idPolizza : " Non disponibile"}</h6></div>
                            <div><h6>Iscrizione {(this.state.numeroIscrizione) ? this.state.numeroIscrizione : "-"}</h6></div>
                            <div><h6>Data richiesta: {(this.state.fields.dataRichiesta) ? this.state.fields.dataRichiesta.split('T')[0] : "Oggi"}</h6></div>
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                            <Callout color="note">
                              <CalloutTitle><Title headingLevel={"h4"}>VRC</Title></CalloutTitle>
                              <CalloutText bigText>{(this.state.fields.vrcConaf) ? AppUtils.itNumber(this.state.fields.vrcConaf)+"" : AppUtils.itNumber(0)}</CalloutText>
                            </Callout>
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                            <Callout color="danger">
                              <CalloutTitle><Title headingLevel={"h4"}>VOP</Title></CalloutTitle>
                              <CalloutText bigText>{(this.state.fields.vopConaf) ? AppUtils.currency(this.state.fields.vopConaf)+"" : AppUtils.currency(0)}</CalloutText>
                            </Callout>
                        </div>
                    </div>

                    }></GenericCard>
                <GenericCard className="cardStyle" key={'tabellaTwo'} header={
                   <div className='justify-content-between d-flex'>
                        <p>{'Riepilogo'}</p>
                    </div>
                    }
                    body={
                        <div className="row d-block">
                        <div className="col-12 my-2 label-style d-flex">
                        <h6 style={{fontSize:'16px'}}>Categoria: </h6><span className="data-category">{(this.state.fields.categoria) ? this.state.fields.categoria : "Non disponibile"}</span>
                        </div>
                        <div className="col-12 my-2 label-style d-flex">
                            <h6 style={{fontSize:'16px'}}>Valore della fascia corrispondente: </h6><span className="data-category">{(this.state.fields.fasciaPremio) ? this.state.fields.fasciaPremio : "Non disponibile"}</span>
                        </div>
                        <div className="col-12 my-2 label-style d-flex">
                            <h6 style={{fontSize:'16px'}}>Range fascia corrispondente: </h6><span className="data-category">{(this.state.fields.valoreRischio) ? "€ "+this.state.fields.valoreRischio: "Non disponibile"}</span>
                        </div>
                        <div className="col-12 my-2 label-style d-flex">
                            <h6 style={{fontSize:'16px'}}>Importo: </h6><span className="data-category">{(this.state.fields.premio !== undefined) ? ("€ "+this.state.fields.premio+",00") : "0"}</span>
                        </div>
                        <div className="col-12 my-2 label-style d-flex">
                            <h6 style={{fontSize:'16px'}}>Massimale corrispondente: </h6><span className="data-category">{(this.state.fields.massimale) ? ("€ "+this.state.fields.massimale+",00") : "Non disponibile"}</span>
                        </div>
                    </div>

                    }></GenericCard>
                </Form>
            </div> :
            <GenericSpinner/>}
            </>
        )
    }
}

export declare interface GeneralFormRiepilogoProps {
    data?: any;
    polizza?: any;
    activeTab?: any;
    tabClick?: any;
    visualizzaFlag?: any;
    aumentoMassimaleFlag?: any;
    modificaDatiFlag?: any;
    getData?:any;
    prevData?:any;
    getSelectedValue?:any;
    tabNumber?: any;
    message?: any;
    idTabella?: any;
}

