import React, { useCallback, useRef, useState } from 'react'
import { Form } from 'design-react-kit'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { STATI_AGENZIE_VALUES } from 'src/formazione'
import GenericInput from 'src/frontend/app/components/Form/Input'
import { createFormData, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { useHistory } from 'react-router-dom'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormApprovazioneMinisteriale: React.FunctionComponent<FormApprovazioneMinisterialeProps> = ({ id }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    note_acc_rifiuto: {
      isRequired: true,
    },
    data_delibera_conaf: {
      isRequired: true,
    },
    note_acc_rifiuto_min: {
      isRequired: true,
    },
    data_protocollo_min: {
      isRequired: true,
    },
    data_accettazione: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const submit = useCallback(
    async (e) => {
      try {
        // TODO AGENZIA API check
        const inputs = ['note_acc_rifiuto', 'data_delibera_conaf', 'note_acc_rifiuto_min', 'data_protocollo_min', 'data_accettazione']
        const valid = validateFormData(formData, rules, inputs)
        const data = formData?.data
        if (!valid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }

        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneAccAgenziaFormativaPatch(
          {
            ...data,
            id,
            stato: STATI_AGENZIE_VALUES.ACCREDITATA,
          },
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id, data]
  )

  return (
    <FormSubmit title="Approvazione ministeriale" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Form>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput
              id="note_acc_rifiuto"
              type={'text'}
              placeholder={'Delibera di approvazione CONAF'}
              label={'Delibera di approvazione CONAF**'}
              value={data?.note_acc_rifiuto}
              errorMessage={errors?.note_acc_rifiuto}
              onInit={onInitInput(0)}
              onChange={handleInput}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput
              id="data_delibera_conaf"
              type={'date'}
              placeholder={'Data delibera'}
              label={'Data delibera**'}
              value={data?.data_delibera_conaf}
              errorMessage={errors?.data_delibera_conaf}
              onInit={onInitInput(0)}
              onChange={handleInput}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput
              id="note_acc_rifiuto_min"
              type={'text'}
              placeholder={'Protocollo parere ministero'}
              label={'Protocollo parere ministero**'}
              value={data?.note_acc_rifiuto_min}
              errorMessage={errors?.note_acc_rifiuto_min}
              onInit={onInitInput(0)}
              onChange={handleInput}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput
              id="data_protocollo_min"
              type={'date'}
              placeholder={'Data protocollo'}
              label={'Data protocollo**'}
              value={data?.data_protocollo_min}
              errorMessage={errors?.data_protocollo_min}
              onInit={onInitInput(0)}
              onChange={handleInput}
            />
          </div>
          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
            <GenericInput
              id="data_accettazione"
              type={'date'}
              placeholder={'Data accreditamento'}
              label={'Data accreditamento**'}
              value={data?.data_accettazione}
              errorMessage={errors?.data_accettazione}
              onInit={onInitInput(0)}
              onChange={handleInput}
            />
          </div>
        </div>
      </Form>
    </FormSubmit>
  )
}

interface FormApprovazioneMinisterialeProps {
  id: any
}

export default FormApprovazioneMinisteriale
