/* eslint-disable array-callback-return */
import React from 'react'
import { FormGroup, FormSelect, FormSelectOption } from '@patternfly/react-core'

class GenericSelect extends React.Component<GeneralSelectProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      validated: 'default',
      invalidText: '',
      id: props.id,
      list: props.list,
      isDisabled: props.isDisabled,
      placeholder: props.placeholder,
      isHidden: props.isHidden,
      isRequired: props.isRequired,
      value: props.value,
      isReadOnly: props.isReadOnly,
      label: props.label,
      className: props.className,
    }
  }

  handleSelectChange = (value) => {
    this.setState({ value: value, validated: 'default', invalidText: '' })

    if (value === '' && this.props.isRequired) {
      this.setState({ validated: 'error', invalidText: 'Campo obbligatorio' })
    }

    if (this.props.onChange !== undefined) this.props.onChange(value)
  }

  render() {
    const { id, placeholder, isRequired, value, validated, invalidText, isReadOnly, label, className } = this.state
    return (
      <FormGroup helperTextInvalid={invalidText} validated={validated} label={label} isRequired={isRequired}>
        <FormSelect
          className={className}
          id={id}
          placeholder={placeholder}
          isDisabled={this.props.isDisabled}
          hidden={this.props.isHidden}
          required={isRequired}
          value={value}
          onClick={this.props.onClick}
          onChange={(e) => {
            this.handleSelectChange(e)
          }}
          validated={validated}
          readOnly={isReadOnly}
        >
          {this.props.list?.map((option, index) => (
            <FormSelectOption key={index} value={option.value} label={option.label} isDisabled={option.disabled} readOnly={isReadOnly}></FormSelectOption>
          ))}
        </FormSelect>
      </FormGroup>
    )
  }
}

export declare interface GeneralSelectProps {
  list?: any
  isDisabled?: any
  placeholder?: string
  isHidden?: boolean
  id?: any
  isRequired?: boolean
  isReadOnly?: boolean
  value?: any
  onChange?: any
  label?: string
  onClick?: any
  className?: any
}

export default GenericSelect
