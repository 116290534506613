import { Table } from 'design-react-kit'
import React from 'react'

class GenericTableCompact extends React.Component<GeneralTableCompactProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            columns: props.columns,
            rows: props.rows,
            style: props.style
        };
    }

    render() {
        const { rows, columns, style } = this.props
        return (
            <Table size='sm' style={style}>
                <thead>
                    <tr>
                        {columns?.map((col) => (
                            <th scope='col'>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        )
    }

}

export declare interface GeneralTableCompactProps {
    columns?: any;
    rows?: any;
    style?: any;
}

export default GenericTableCompact;
