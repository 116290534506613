export const FILTER_OPERATORS = {
  EQUAL: 'EQUAL',
  LT: 'LT',
  GT: 'GT',
  LTE: 'LTE',
  GTE: 'GTE',
  LIKE: 'LIKE'
}


interface Filter {
  field: string;
  value: any;
  operator?: string;
}


export const generateRequestFilter = (filters: Filter[]) => {
  if(filters.length===0) return undefined;
  const f =  filters.map(f => {
    const v = (typeof f.value=== "object" && f.value['value'])?f.value['value']:f.value
    return f.field + '::' + (f.operator||FILTER_OPERATORS.EQUAL) + '::' + v;
  });
  return f;
}

export const generateRequestSort = (sortBy,sortDirection, nullPrecedence ?: any)=>{
  if(sortBy!==undefined && sortBy!=="" && !nullPrecedence){
    return [sortBy+"::"+(sortDirection==="asc"?"ASC":"DESC")];
  }
  else if (sortBy!==undefined && sortBy!=="" && nullPrecedence){
    return [sortBy+"::"+(sortDirection==="asc"?"ASC":"DESCNULL")];
  }
  return undefined;
}