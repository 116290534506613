import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericWizard from '../../components/GenericWizard'
import GenericCard from '../../components/GenericCard'
import { FormDatiAnagrafici } from './formIscrizione/FormDatiAnagrafici'
import { FormDatiAccessoProfessione } from './formIscrizione/FormDatiAccessoProfessione'
import { FormResidenzaContatti } from './formIscrizione/FormResidenzaContatti'
import { FormSedeLegale } from './formIscrizione/FormSedeLegale'
import { FormProfessione } from './formIscrizione/FormProfessione'
import { FormIscrizioneEPAP } from './formIscrizione/FormIscrizioneEPAP'
import { FormDatiIscrizione } from './formIscrizione/FormDatiIscrizione'
import { FormFile } from './formIscrizione/FormFile'
import { getClasseLaureaByCodice, getTipoLaureaByCodice, getTitoloByCodiceBO } from 'src/processes/Laurea'
import { getProvinciaBySiglaBO } from 'src/processes/Province'
import { getSezioneByCodice } from 'src/processes/Sezioni'
import { getFilesByIdIscrizioneBo, getIscrizioneByIdBO } from 'src/processes/ProcessoIscrizione'
import GenericChips from 'src/frontend/app/components/GenericChips'
import {
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO
} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { convertUrlParamsToObject } from 'src/utilities/utility'

class VisualizzaDatiIscritto extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            typeMessage: "default",
            soggetto: "",
            iscrizione: "",
            documentoRiconoscimento: "",
            message: "",
            listaFiles: []
        }
    }

    componentDidMount() {
        //recupero gli eventuali parametri dall'url
        var url = window.location.search?.split("?")[1]
        var urlParams = convertUrlParamsToObject(url)

        //recupero l'idIscrizione dall'url
        var idIscrizione = urlParams?.idIscrizione

        this.setState({ idIscrizione: idIscrizione })

        const tipologiaIscrizione = 'iscritto_all_ordine'

        //chiamata al servizio  'getIscrizioneByIdBO' che restituisce i dati dell'iscrizione a partire dall'idIscrizione e tipologia_iscrizione
        getIscrizioneByIdBO(idIscrizione, tipologiaIscrizione).then((response: any) => {
            this.setState({ iscrizione: response.data.iscrizioneOrdineTerritorialeDTO, soggetto: response.data.soggettoDTO, documentoRiconoscimento: response.data.soggettoDocumentoDTO })

            //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia di nascita a partire dalla sigla
            var siglaProvinciaNascita = response.data.soggettoDTO.codiceProvinciaNascita
            if (siglaProvinciaNascita) {
                //controllo se la provincia è stato estero
                if (siglaProvinciaNascita === 'EEP') {
                    this.setState({ provinciaNascita: 'Stato estero' })
                }
                else {
                    getProvinciaBySiglaBO(siglaProvinciaNascita).then((response: any) => {
                        this.setState({ provinciaNascita: response.data.denominazioneUnitaTerritorialeSovracomunale })
                    })
                }
            }

            //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia di residenza a partire dalla Sigla
            var siglaProvinciaResidenza = response.data.soggettoDTO.codiceProvinciaResidenza
            if (siglaProvinciaResidenza) {
                if (siglaProvinciaResidenza === 'EEP') {
                    this.setState({ provinciaResidenza: 'Stato estero' })
                }
                else {
                    getProvinciaBySiglaBO(siglaProvinciaResidenza).then((response: any) => {
                        this.setState({ provinciaResidenza: response.data.denominazioneUnitaTerritorialeSovracomunale })
                    })
                }
            }

            //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia della sede legale a partire dalla Sigla
            var sedeLegale = response.data.soggettoDTO.elencoSedi.filter((sede) => sede['idIscrizione'].toString() === idIscrizione)
            response.data.soggettoDTO.elencoSedi = sedeLegale

            this.setState({
                soggetto: response.data.soggettoDTO
            })

            var siglaProvinciaSedeLegale = sedeLegale[0]?.slProvinciaStudio

            if (siglaProvinciaSedeLegale && siglaProvinciaSedeLegale.length > 0)
                getProvinciaBySiglaBO(siglaProvinciaSedeLegale).then((response: any) => {
                    this.setState({ provinciaSedeLegale: response.data.denominazioneUnitaTerritorialeSovracomunale })
                })

            //chiamata al servizio 'getSezioneByCodice' che restituisce la sezione richiesta a partire dal codice sezione
            var codiceSezioneRichiesta = response.data.iscrizioneOrdineTerritorialeDTO.codiceSezioneRichiesta
            if (codiceSezioneRichiesta)
                getSezioneByCodice(codiceSezioneRichiesta).then((response: any) => {
                    this.setState({ sezione: response.data.descrizione })
                })

            //chiamata al servizio 'getTitoloByCodice' che restituisce il titolo richiesto a partire dal codice
            var codiceTitoloRichiesto = response.data.iscrizioneOrdineTerritorialeDTO.codiceTitoloRichiesto
            if (codiceTitoloRichiesto)
                getTitoloByCodiceBO(codiceTitoloRichiesto).then((response: any) => {
                    this.setState({ titoloRichiesto: response.data.titoloEsteso })
                })

            //chiamata al servizio 'getTitoloByCodice' che restituisce la tipologia abilitazione estesa a partire dal codice
            var codiceTipologiaAbilitazione = response.data.iscrizioneOrdineTerritorialeDTO.tipologiaAbilitazione
            if (codiceTipologiaAbilitazione)
                getTitoloByCodiceBO(codiceTipologiaAbilitazione).then((response: any) => {
                    this.setState({ tipologiaAbilitazioneEstesa: response.data.titoloEsteso })
                })

            //chiamata al servizio 'getTipoLaureaByCodice' che restituisce il tipo di laurea a partire dal codice
            var codiceTipoLaurea = response.data.iscrizioneOrdineTerritorialeDTO.codiceTipoLaurea

            if (codiceTipoLaurea)
                getTipoLaureaByCodice(codiceTipoLaurea).then((response: any) => {

                    this.setState({ tipoLaurea: response.data.tipoLaurea })
                })

            //chiamata al servizio 'getClasseLaureaByCodice' che restituisce la classe di laurea a partire dal codice
            var codiceClasseLaurea = response.data.iscrizioneOrdineTerritorialeDTO.codiceClasseLaurea
            if (codiceClasseLaurea)
                getClasseLaureaByCodice(codiceClasseLaurea).then((response: any) => {

                    this.setState({ classeLaurea: response.data.descrizione })
                })
        }).catch(() => {
            setTimeout(() => {
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
            }, 1000);
        })

        //chiamata al servizio 'getFilesByIdIscrizione' che restituisce tutti i file di un'iscrizione
        if (idIscrizione)
            getFilesByIdIscrizioneBo(idIscrizione).then((response) => {
                this.setState({ listaFiles: response.data })
            })
    }

    goToElencoIscritti = (redirectLink: any) => {
        this.props.history.push({
            pathname: redirectLink,
        });
    }

    render() {
        const { message, typeMessage, soggetto, documentoRiconoscimento, iscrizione, provinciaNascita, titoloRichiesto, tipologiaAbilitazioneEstesa, tipoLaurea, classeLaurea, provinciaResidenza, provinciaSedeLegale, sezione, listaFiles } = this.state

        const steps = [
            { name: 'Dati anagrafici', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Dati anagrafici'} body={<><FormDatiAnagrafici messaggio={message} soggetto={soggetto} iscrizione={iscrizione} provinciaNascita={provinciaNascita} documentoRiconoscimento={documentoRiconoscimento} /></>} /> },
            { name: 'Residenza e Contatti', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Dati residenziali e contatti'} body={<><FormResidenzaContatti messaggio={message} soggetto={soggetto} provinciaResidenza={provinciaResidenza} /></>} /> },
            { name: 'Sede legale', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Dati sede legale studio'} body={<><FormSedeLegale soggetto={this.state.soggetto} iscrizione={iscrizione} messaggio={this.state.message} provinciaSedeLegale={provinciaSedeLegale} /></>} /> },
            { name: 'Dati accesso professione', component: <GenericCard isExpandibleCard={true} isExpanded={true} header={'Dati di accesso alla professione'} body={<><FormDatiAccessoProfessione messaggio={message} soggetto={soggetto} iscrizione={iscrizione} sezione={sezione} titoloRichiesto={titoloRichiesto} tipologiaAbilitazioneEstesa={tipologiaAbilitazioneEstesa} tipoLaurea={tipoLaurea} classeLaurea={classeLaurea} /></>} /> },
            { name: 'Professione', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Dati sulla professione'} body={<><FormProfessione iscrizione={iscrizione} messaggio={this.state.message} /></>} /> },
            { name: 'Iscrizione EPAP', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Dati iscrizione EPAP'} body={<><FormIscrizioneEPAP iscrizione={iscrizione} messaggio={this.state.message} /></>} /> },
            { name: 'Dati iscrizione', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Dati di iscrizione'} body={<><FormDatiIscrizione soggetto={this.state.soggetto} iscrizione={iscrizione} messaggio={this.state.message} /></>} /> },
            { name: 'File', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'File'} body={<><FormFile listaFiles={listaFiles} messaggio={this.state.message} /></>} /> }
        ];
        return (
            <>
                <PageSection>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI }, { label: "Visualizza dati iscritto", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO }]} /><br></br>
                        <Title headingLevel="h1" size="4xl">Visualizza dati iscritto</Title>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <GenericChips color={'default'} label={'Stato iscrizione: ' + (iscrizione.codificaStati ? iscrizione.codificaStati?.decoDescrizione.toUpperCase() : "-")} />
                        </div>
                        <GenericAlert label={this.state.message} hidden={message === ""} color={typeMessage}></GenericAlert>
                        <GenericWizard steps={steps} backButtonText={'PRECEDENTE'} nextButtonText={'SUCCESSIVO'} cancelButtonText={'Annulla'} onClickCancel={() => this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)} height='auto' /><br></br>
                    </div>
                </PageSection>
            </>
        );
    }
};

export default VisualizzaDatiIscritto;
