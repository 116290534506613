import React from 'react'
import { FormGroup, TextInput } from '@patternfly/react-core'
import { checkAnno, checkValuta, isOnlyNumbers } from 'src/utilities/utility'

class GenericTextInput extends React.Component<GeneralTextInputProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      defaultValue: props.defaultValue,
      invalidText: '',
      validated: 'default',
      placeholder: props.placeholder,
      isDisabled: props.isDisabled,
      isHidden: props.isHidden,
      isRequired: props.isRequired,
      id: props.id,
      type: props.type,
      className: props.className,
      ariaLabel: props.ariaLabel,
      isReadOnly: props.isReadOnly,
      label: props.label,
      name: props.name,
      style: props.style
    };
  }

  handleTextInputChange = value => {
    this.setState({ value: value, validated: 'default', invalidText: '' })

    if ((value === "") && this.props.isRequired) {
      this.setState({ validated: 'error', invalidText: 'Campo obbligatorio' });
    }

    else if (this.props.type === 'only-number') {
      if (!isOnlyNumbers(value) && (value !== '')) {
        this.setState({ validated: 'error', invalidText: 'Carattere non ammesso' });
      }
    }

    else if (this.props.type === 'anno') {
      if (!checkAnno(value) && (value !== '')) {
        this.setState({ validated: 'error', invalidText: 'Anno non valido' });
      }

      if (!isOnlyNumbers(value) && (value !== '')) {
        this.setState({ validated: 'error', invalidText: 'Carattere non ammesso' });
      }
    }
    else if (this.props.type === 'valuta') {
      if (!checkValuta(value) && (value !== '')) {
        this.setState({ validated: 'error', invalidText: 'Importo non valido' });
      }
    }

    this.props.onChange(value)
  };

  render() {
    const { placeholder, id, type, className, validated, invalidText, isReadOnly, name, label, classNameForm, style } = this.state;
    return (
      <FormGroup helperTextInvalid={invalidText} validated={validated} label={label} isRequired={this.props.isRequired} hidden={this.props.isHidden}>
        <TextInput width={this.props.width} className={className} defaultValue={this.props.defaultValue} style={style} name={name} isReadOnly={isReadOnly} id={id} value={this.props.value} type={type}
          onChange={(e) => { this.handleTextInputChange(e); this.props.onChange(e) }}
          placeholder={placeholder} isDisabled={this.props.isDisabled} hidden={this.props.isHidden} isRequired={this.props.isRequired} validated={validated}

        /> {this.props.icon}
      </FormGroup>
    );
  }
}

export declare interface GeneralTextInputProps {
  value?: any;
  defaultValue?: any;
  placeholder?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
  id?: any;
  type: any;
  className?: string;
  isReadOnly?: boolean;
  onChange?: any;
  name?: string;
  label?: any;
  style?: any;
  width?: number;
  icon?: any;
}

export default GenericTextInput;
