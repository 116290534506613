import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Label } from 'design-react-kit'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from 'src/frontend/app/components/GenericTab'

import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { requestMapperFormatore } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'

// Form components
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import FormAnagrafica from './FormAnagrafica'
import formValidationRules from './FormRules'
import FormDichiarazioni from './FormDichiarazioni'
import FormAltriTitoli from './FormAltriTitoli'
import FormAttivitaFormative from './FormAttivitaFormative'
import FormEsperienzaProfessionale from './FormEsperienzaProfessionale'
import FormEsperienzaDidattica from './FormEsperienzaDidattica'
import FormEsperienzaScientifica from './FormEsperienzaScientifica'
import { GenericSpinner } from 'src/backoffice/app/components'
import { STATI_ESONERO } from 'src/model/Esoneri'
import { PageSection, Title } from '@patternfly/react-core'

const title = 'Creazione Formatore'
const listNavLink = [
  { id: 1, label: 'Dati Anagrafici' },
  { id: 2, label: 'Dichiarazioni' },
  { id: 3, label: 'Altri titoli' },
  { id: 4, label: 'Attività formative svolte' },
  { id: 5, label: 'Esperienza professionale' },
  // { id: 6, label: 'Dati Formatori' },
  { id: 6, label: 'Esperienza didattica' },
  { id: 7, label: 'Esperienza scientifica' },
  { id: 8, label: 'Conferma' },
]

const breadcrumbOptions = [
  { label: 'Scelta categoria', link: '/' },
  { label: 'Iscrizione Formatore', link: '/' },
]
const FormFormatori: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const tab = searchParams.get('tab')

  // Tab component state
  const [activeTab, setActiveTab] = useState(parseInt(tab || '1'))

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  const [isLoading, setIsLoading] = useState(false)
  const [hasId, setHasId] = useState('')

  // Submit state
  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  const [reqStatusSave, setReqStatusSave] = useState<any>()
  const [reqMessageSave, setReqMessageSave] = useState<any>()

  const [message, setMessage] = useState<any>('')

  const saveData = async () => {
    try {
      setIsLoading(true)
      setReqStatusSave(STATUS.LOADING)

      const { data } = formData || {}
      const _data = await requestMapperFormatore(data, STATI_ESONERO.IN_COMPILAZIONE)

      if (hasId && hasId.length > 0) {
        _data.formatore.id = hasId
        await getFormazioneResourceApi().msfoFormazioneAggiornaFormatorePut(_data as any, getDefaultAxiosConfig())
      } else {
        const { data: respData } = await getFormazioneResourceApi().msfoFormazioneInserisciFormatorePut(_data, getDefaultAxiosConfig())
        setHasId(respData?.id as string)
      }

      setReqMessageSave('Richiesta inviata con successo')
      setMessage('')
      setReqStatusSave(STATUS.SUCCESS)
      if (parseInt(tab) >= 8) {
        setTimeout(() => {
          history.goBack()
        }, 500)
      }
      setActiveTab((tab) => tab + 1)
      // localStorage.removeItem(`${id}`)
    } catch (e) {

      setMessage('Errore nella richiesta')
      setReqStatusSave(STATUS.FAILURE)
      setReqMessageSave("Errore durante l'invio della richiesta")
    }
    setIsLoading(false)
    return null
  }

  // update tab in search params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', `${activeTab}`)
    history.replace({ search: searchParams.toString() })
  }, [activeTab])

  // retrieve data from api
  useEffect(() => {
    const retrieveData = async () => {
      const { data } = await getFormazioneResourceApi().msfoFormazioneDettaglioAccAgenziaFormativaGet(id as any, getDefaultAxiosConfig())
      setData(data)
    }

    // before the api retrieve the data from local storage
    const dataString = localStorage.getItem(`${id}`)
    const _data = dataString ? JSON.parse(dataString) : {}

    setData(_data)
    // if (id) retrieveData().catch(console.error)
  }, [id])

  // save data in local storage
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem(`${id}`, JSON.stringify(data))
    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [id, data])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({
      tab: tab,
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onNextStep = useCallback(
    (e) => {
      e.preventDefault()
      const inputs = getTabActiveFormId(refForm, activeTab - 1)
      const isValid = validateFormData(formData, formValidationRules, inputs)
      if (!isValid) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      } else {
        saveData()
      }
    },
    [activeTab, formData]
  )

  const previousStep = () => {
    if (reqStatus === STATUS.LOADING) {
      return
    }
    setReqStatus(null)
    setReqMessage(null)
    setActiveTab((tab) => tab - 1)
  }

  const onSubmit = useCallback(async () => {
    try {
      setReqStatus(STATUS.LOADING)

      const { data } = formData || {}
      const _data = await requestMapperFormatore(data, STATI_ESONERO.INVIATA)
      _data.formatore.id = hasId

      await getFormazioneResourceApi().msfoFormazioneAggiornaFormatorePut(_data, getDefaultAxiosConfig())
      // localStorage.removeItem(`${id}`)
      setReqStatus(STATUS.SUCCESS)
      setReqMessage('Richiesta inviata con successo')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (e) {
      setReqStatus(STATUS.FAILURE)
      setReqMessage("Errore durante l'invio della richiesta")
    }
    return null
  }, [id, formData])

  return (
    <>
      {isLoading ? (
        <GenericSpinner></GenericSpinner>
      ) : (
        <div className="container register-container">
          <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
          <div>
            <h1>{title}</h1>
          </div>
          <GenericTab
            hiddenAlert={message === ''}
            labelAlert={message}
            alertTypeMessage="danger"
            isSubmitted={false}
            onSubmit={onNextStep}
            previousStep={previousStep}
            activeTab={activeTab}
            listNavLink={listNavLink}
            listBodyTab={[
              {
                id: 1,
                body: <FormAnagrafica title="Dati Anagrafici" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} />,
              },
              {
                id: 2,
                body: <FormDichiarazioni title="Dichiarazioni" data={data} errors={errors} onInitInput={onInitInput(1)} handleInput={handleInput} />,
              },
              {
                id: 3,
                body: <FormAltriTitoli title="Altri titoli" data={data} errors={errors} onInitInput={onInitInput(2)} handleInput={handleInput} />,
              },
              {
                id: 4,
                body: (
                  <FormAttivitaFormative title="Attività formative svolte" data={data} errors={errors} onInitInput={onInitInput(3)} handleInput={handleInput} />
                ),
              },
              {
                id: 5,
                body: (
                  <FormEsperienzaProfessionale
                    title="Esperienza professionale"
                    data={data}
                    errors={errors}
                    onInitInput={onInitInput(4)}
                    handleInput={handleInput}
                  />
                ),
              },
              {
                id: 6,
                body: (
                  <FormEsperienzaDidattica title="Esperienza didattica" data={data} errors={errors} onInitInput={onInitInput(5)} handleInput={handleInput} />
                ),
              },
              {
                id: 7,
                body: <FormEsperienzaScientifica title="Allegati" data={data} errors={errors} onInitInput={onInitInput(6)} handleInput={handleInput} />,
              },
              {
                id: 8,
                body: (
                  <>
                    <FormSubmit title="Invia richiesta" buttonLabel="Invia Richiesta" alertMessage={reqMessage} status={reqStatus} onSubmit={onSubmit}>
                      <Label>Per confermare e inviare la domanda cliccare "Invia Richiesta".</Label>
                    </FormSubmit>
                    <FormSubmit title="Salva Richiesta" buttonLabel="Salva richiesta" alertMessage={reqMessageSave} status={null} onSubmit={saveData}>
                      <Label>Per salvare la domanda senza inviare la richiesta cliccare "Salva Richiesta".</Label>
                    </FormSubmit>
                  </>
                ),
              },
            ]}
          />
          <PageSection>
            <div className="container register-container">
              <Title headingLevel="h3">Legenda</Title>
              <p>* Campo obbligatorio</p>
              <p>** Campo non modificabile compilato automaticamente</p>
            </div>
          </PageSection>
        </div>
      )}
    </>
  )
}

export default FormFormatori
