import * as React from 'react'
import 'src/backoffice/app/app.css'
import '@patternfly/react-core/dist/styles/base.css'

export interface IAppLayout {
  children: React.ReactNode;
}

const BackofficeLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  return (<>{children}</>);
};

export { BackofficeLayout };
