import { getDefaultAxiosConfig, getFormazioneResourceApi } from '../app/utils/ManagerRestGateway'


export const TIPO_ENTE={
  CONAF:"CONAF",
  ORDINE:"o",
  FEDERAZIONE:"f",
  AGENZIA:"af",
  ENTEESTERNO:"ee"
}

export const LABEL_ENTI = {
  [TIPO_ENTE.CONAF]:'CONAF',
  [TIPO_ENTE.ORDINE]:'Ordine',
  [TIPO_ENTE.FEDERAZIONE]:'Federazione Regionale',
  [TIPO_ENTE.AGENZIA]:'Agenzia formativa',
  [TIPO_ENTE.ENTEESTERNO]:'Ente esterno',
}

export declare interface AgenziaInfo {
  nome:string;
  codice:string;
  tipo:string;
  tipoAgForm:number;
}

export declare interface AgenzieMap{
  [key:string]:AgenziaInfo
}

export const getNomeEnteFormazione = (a: any):string=>{
  switch (a.tipoEnte){
    case TIPO_ENTE.ORDINE:
      return a['soggettoFormazioneDTO']? a['soggettoFormazioneDTO'].denominazioneGruppo || "" :"";
    case TIPO_ENTE.FEDERAZIONE:
      return a['soggettoFormazioneDTO']? a['soggettoFormazioneDTO'].denominazioneGruppo || "" :"";
    case TIPO_ENTE.AGENZIA:
      return a.agenziaFormativa?a.agenziaFormativa['rag_soc'] || "" :""
    default:
      return "";
  }
}

export const getAllEnti = async ():Promise<AgenzieMap>=>{
  const enti: AgenzieMap = {};

  try {
    const { data:loadedEnti } = await getFormazioneResourceApi().msfoFormazioneListaEntiDiFormazioneSimpleGet(getDefaultAxiosConfig());
      loadedEnti.forEach((e) => {
        const code = e['codice_ente'];
        if (code) {
          enti[code] = {
            nome: e['name'] || "",
            codice: e['codice_ente'] || "",
            tipo: e['tipo_ag_form'] === 3 ? 'ee' : (e['tipo_ente'] || ""),
            tipoAgForm: e['tipo_ag_form'] || "",
          }
        }
      })
  }
  catch (e){
  }
  return enti;
}
