import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericTable from '../components/GenericTable'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {
  PATH_TO_BO_ANAG_ELENCO_OPERATORI,
  PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI
} from 'src/app/utils/RoutesConstants'
import { elencoOperatoriList } from 'src/Query/ElencoOperatoriFilterQuery'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { elencoOperatoriSTPList } from 'src/Query/ElencoOperatoriSTPFilterQuery'

// Dati della GenericTable
const columnsNames = ['Codice fiscale', 'Cognome/Nome/Ragione sociale'];

const columnsTypes = [
  {
    dropdownColumnID: [0, 1],
    inputColumnID: []
  }
];

class ElencoOperatori extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedRow: {},
      rowsNames: [],
      listaStati: [],
      elencoIscrittiFilterParams: null,
      itemsPageCount: 10,
      filterAndPaginationData: "",
      hideConferma: true,
      hideCancella: true,
      hideRichiediInfo: true,
      hideRigetta: true,
      hideRegistrazionePagamento: true,
      hideModificaIscrizione: true,
    };
  }

  specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
  genericGroup = TokenStore.getInstance().getCurrentAccount().accounts[this.specificGroup]?.genericGroup

  nestedDataInFilter: any = []
  async componentDidMount() {
    this.setState({ isLoading: true });

    await elencoOperatoriList(undefined, this.state.itemsPageCount, this.state.listaStati, 1).then((response: any) => {
      var operatori = JSON.parse(response)
      this.nestedDataInFilter = [[], [{ label: ["Persona Fisica"] }, { label: ["Persona Giuridica"] }], [], [], this.state.listaStati.map(stato => ({ label: [stato.descrizioneStato] }))]
      var filterAndPaginationData = { pageCount: operatori.pageCount, itemsPageCount: operatori.size, totalPageCount: operatori.totalCount, listaStati: this.state.listaStati, nestedDataInFilter: this.nestedDataInFilter }
      this.setState({ rowsNames: operatori.content, filterAndPaginationData: filterAndPaginationData })
      this.setState({ isLoading: false });
    }).catch((err) => {
      //ErrorStore.getInstance().showAlert("Errore durante la get degli iscritti all'ordine. " + err);
      //throw new Error("Errore durante la get degli iscritti all'ordine " + err);
      this.setState({ isLoading: false });
      throw new Error(err);
    })
  }

  loadingFunction = () => {
    this.componentDidMount()
  };

  onSelectRow = async (row) => {
    await this.setState({ selectedRow: row })
  };

  render() {
    const { isLoading, filterAndPaginationData, rowsNames } = this.state;

    const dropdownActionList = [
      { title: 'Gestione utenze', onClick: (e) => onSelectAction(e, PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI), itemKey: 'gestioneUtenze'}
    ]

    /**
     * Seleziona l'evento nella dropdown e reindirizza verso il link indicato
     * @param event
     * @param link
     * @param linkSTP
     */
    const onSelectAction = (event, link, linkSTP?) => {
      const title = event.target.textContent
      this.props.history.push({
        pathname: link,
        state: { action: title, row: this.state.selectedRow, column: columnsNames }
      });
    }

    return (
      <PageSection>
        <div className='container register-container'>
          <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Elenco operatori", link: PATH_TO_BO_ANAG_ELENCO_OPERATORI }]} /><br></br>
          <Title headingLevel="h1" size="4xl">Elenco operatori</Title><br></br>
          {
            <div>
                <GenericTable
                  hideCsv={true}
                  tipoElenco={'Operatori'}
                  methodNameCall={this.genericGroup === "STP" ? elencoOperatoriSTPList : elencoOperatoriList}
                  columns={columnsNames}
                  rows={rowsNames}
                  hideActionColumn={false}
                  columnsTypes={columnsTypes}
                  isEditableTable={false}
                  filterAndPaginationData={filterAndPaginationData}
                  perPageNumber={this.state.itemsPageCount}
                  onSelect={(e) => this.onSelectRow(e)}
                  actions={[{ label: "Aggiorna", function: '' }]}
                  dropdownList={dropdownActionList}
                ></GenericTable>
                <br></br>
              </div>
          }
        </div>
      </PageSection>
    );
  }
}
export default ElencoOperatori;
