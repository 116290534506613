import { Form } from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import { getComuniByProvinciaMsga } from 'src/processes/Comuni'
import { getAllProvince } from 'src/processes/Province'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormUbicazioneContattiStpFO extends React.Component<GeneralFormUbicazioneContattiStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      listaCittaSedeLegale: [],
      listaCittaSedeOperativa: [],
      dataLoaded: false,
    }
  }

  async componentDidMount() {
    //chiamata al servizio che restituisce tutte le province, ordinate in ordine alfabetico
    await getAllProvince()
      .then(async (response: any) => {
        var province = [{ label: '', value: '' }]

        response.data.map((provincia, id) => {
          return (province[id] = {
            label: provincia.denominazioneUnitaTerritorialeSovracomunale,
            value: provincia.siglaAutomobilistica,
          })
        })

        let sortedProvince = province.sort((p1, p2) => (p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0))

        this.setState({ province: sortedProvince })
      })
      .catch(() => {
        this.setState({ dataLoaded: true })
      })

    var provinciaSedeLegale =
      this.props.provinciaSedeLegale !== '' && this.props.provinciaSedeLegale !== null && this.props.provinciaSedeLegale
        ? this.props.provinciaSedeLegale?.label
        : null

    await getComuniByProvinciaMsga(provinciaSedeLegale)
      .then(async (comuni) => {
        this.setState({
          listaCittaSedeLegale: comuni,
          provinciaSedeLegaleSelected: true,
        })
        this.props.getProvinciaSedeLegale(this.props.provinciaSedeLegale)
      })
      .catch(() => {
        this.setState({ dataLoaded: true })
      })

    await getComuniByProvinciaMsga(this.props.provinciaSedeOperativa?.label)
      .then(async (comuni) => {
        this.setState({
          listaCittaSedeOperativa: comuni,
          provinciaSedeOperativaSelected: true,
        })
      })
      .catch(() => {
        this.setState({ dataLoaded: true })
      })

    var soggetto = this.props.datiIscrizione?.soggettoDTO
    var iot = this.props.datiIscrizione?.iscrizioneOrdineTerritorialeDTO
    var elencoSedi = soggetto?.elencoSedi.find((sede) => sede.idIscrizione === iot.idIscrizione)
    var cittaSedeLegale = elencoSedi.slCittaStudio
    var cittaSedeOperativa = elencoSedi.sopCittaStudio
    if (cittaSedeLegale && cittaSedeOperativa) {
      this.setState({ svuotaCittaSedeLegale: false })
      this.setState({ svuotaCittaSedeOperativa: false })
    }

    var cittaSedeLegaleSelect = cittaSedeLegale ? { label: cittaSedeLegale, value: cittaSedeLegale } : undefined
    var cittaSedeOperativaSelect = cittaSedeOperativa ? { label: cittaSedeOperativa, value: cittaSedeOperativa } : undefined
    var provinciaSedeLegaleSelect = this.props.provinciaSedeLegale
    var provinciaSedeOperativaSelect = this.props.provinciaSedeOperativa

    this.setState({
      dataLoaded: true,
      soggetto: soggetto,
      cittaSedeLegale: cittaSedeLegaleSelect,
      cittaSedeOperativa: cittaSedeOperativaSelect,
      provinciaSedeLegale: provinciaSedeLegaleSelect,
      provinciaSedeOperativa: provinciaSedeOperativaSelect,
    })
  }

  handleChangeProvinciaSedeLegale = (event, fieldId) => {
    this.setState({ provinciaSedeLegaleSelected: false, svuotaCittaSedeLegale: false })
    getComuniByProvinciaMsga(event.label).then(async (comuni) => {
      this.setState({
        listaCittaSedeLegale: comuni,
        provinciaSedeLegaleSelected: true,
        svuotaCittaSedeLegale: true,
      })
      this.props.getProvinciaSedeLegale(event.value)
    })
    this.props.formData({ id: fieldId, value: event.value })
  }

  handleChangeProvinciaSedeOperativa = (event, fieldId) => {
    this.setState({ provinciaSedeOperativaSelected: false, svuotaCittaSedeOperativa: false })
    getComuniByProvinciaMsga(event.label).then(async (comuni) => {
      this.setState({
        listaCittaSedeOperativa: comuni,
        provinciaSedeOperativaSelected: true,
        svuotaCittaSedeOperativa: true,
      })
    })
    this.props.formData({ id: fieldId, value: event.value })
  }

  handleChange = (event, fieldId, type) => {
    if (type === 'input' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.value })
    else if (type === 'checkbox' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.checked })
    else if (type === 'file' && event.target.id === fieldId) this.props.formData({ id: fieldId, value: event.target.value })
    else if (type === 'select' && fieldId === 'idSlCittaStudio') this.props.formData({ id: fieldId, value: event.value })
    else if (type === 'select' && fieldId === 'idSopCittaStudio') this.props.formData({ id: fieldId, value: event.value })
  }

  render() {
    const {
      listaCittaSedeLegale,
      listaCittaSedeOperativa,
      province,
      dataLoaded,
      soggetto,
      cittaSedeLegale,
      cittaSedeOperativa,
      provinciaSedeLegale,
      provinciaSedeOperativa,
      svuotaCittaSedeLegale,
      svuotaCittaSedeOperativa,
    } = this.state
    const { tabNumber } = this.props
    const iot = this.props.datiIscrizione?.iscrizioneOrdineTerritorialeDTO
    const elencoSedi = soggetto?.elencoSedi.find((sede) => sede.idIscrizione === iot.idIscrizione)

    return (
      <>
        {dataLoaded === true ? (
          <div>
            <Form>
              {/*----------------------------------------------------------------- FORM PER ISCRIZIONE STP ---------------------------------------------------------------*/}
              <div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.slIndirizzoStudio}
                      errorMessageAlert={this.props.message}
                      type={'text'}
                      id={'idSlIndirizzoStudio'}
                      placeholder={'Indirizzo sede legale'}
                      label={'Indirizzo sede legale*'}
                      onChange={(e) => this.handleChange(e, 'idSlIndirizzoStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericSelect
                      key={'keyProvinciaSedeLegale'}
                      defaultValue={provinciaSedeLegale}
                      errorMessageAlert={this.props.message}
                      elementId="idSlProvinciaStudio"
                      placeholder="Selezionare provincia sede legale"
                      label="Provincia sede legale*"
                      defaultOptions={province}
                      currentStep={tabNumber}
                      onChange={(e) => this.handleChangeProvinciaSedeLegale(e, 'idSlProvinciaStudio')}
                      isSearchable
                      isRequired
                    />
                  </div>
                </div>
                <div className="row">
                  {this.state.provinciaSedeLegaleSelected ? (
                    <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                      <GenericSelect
                        defaultValue={!svuotaCittaSedeLegale ? cittaSedeLegale : null}
                        errorMessageAlert={this.props.message}
                        elementId={'idSlCittaStudio'}
                        placeholder={'Selezionare città sede legale'}
                        label={'Città sede legale*'}
                        defaultOptions={listaCittaSedeLegale}
                        onChange={(e) => this.handleChange(e, 'idSlCittaStudio', 'select')}
                        currentStep={tabNumber}
                        isSearchable
                        isRequired
                      />
                    </div>
                  ) : null}
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.slCapStudio}
                      errorMessageAlert={this.props.message}
                      type={'cap'}
                      id={'idSlCapStudio'}
                      placeholder={'CAP sede legale'}
                      label={'CAP sede legale*'}
                      onChange={(e) => this.handleChange(e, 'idSlCapStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.slTelefonoStudio}
                      errorMessageAlert={this.props.message}
                      type={'tel'}
                      id={'idSlTelefonoStudio'}
                      placeholder={'Telefono sede legale'}
                      label={'Telefono sede legale*'}
                      onChange={(e) => this.handleChange(e, 'idSlTelefonoStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.slFaxStudio}
                      type={'fax'}
                      id={'idSlFaxStudio'}
                      placeholder={'Fax sede legale'}
                      label={'Fax sede legale'}
                      onChange={(e) => this.handleChange(e, 'idSlFaxStudio', 'input')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.slMailStudio}
                      errorMessageAlert={this.props.message}
                      type={'email'}
                      id={'idSlMailStudio'}
                      placeholder={'Mail sede legale'}
                      label={'Mail sede legale*'}
                      onChange={(e) => this.handleChange(e, 'idSlMailStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.slPecStudio}
                      errorMessageAlert={this.props.message}
                      type={'email'}
                      id={'idSlPecStudio'}
                      placeholder={'PEC sede legale'}
                      label={'PEC sede legale*'}
                      onChange={(e) => this.handleChange(e, 'idSlPecStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.sopIndirizzoStudio}
                      errorMessageAlert={this.props.message}
                      type={'text'}
                      id={'idSopIndirizzoStudio'}
                      placeholder={'Indirizzo sede operativa'}
                      label={'Indirizzo sede operativa*'}
                      onChange={(e) => this.handleChange(e, 'idSopIndirizzoStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericSelect
                      defaultValue={provinciaSedeOperativa}
                      errorMessageAlert={this.props.message}
                      elementId={'idSopProvinciaStudio'}
                      placeholder={'Selezionare provincia sede operativa'}
                      label={'Provincia sede operativa*'}
                      defaultOptions={province}
                      onChange={(e) => this.handleChangeProvinciaSedeOperativa(e, 'idSopProvinciaStudio')}
                      currentStep={tabNumber}
                      isSearchable
                      isRequired
                    />
                  </div>
                </div>
                <div className="row">
                  {this.state.provinciaSedeOperativaSelected ? (
                    <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                      <GenericSelect
                        defaultValue={!svuotaCittaSedeOperativa ? cittaSedeOperativa : null}
                        errorMessageAlert={this.props.message}
                        elementId={'idSopCittaStudio'}
                        placeholder={'Selezionare città sede operativa'}
                        label={'Città sede operativa*'}
                        defaultOptions={listaCittaSedeOperativa}
                        onChange={(e) => this.handleChange(e, 'idSopCittaStudio', 'select')}
                        currentStep={tabNumber}
                        isSearchable
                        isRequired
                      />
                    </div>
                  ) : null}
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.sopCapStudio}
                      errorMessageAlert={this.props.message}
                      type={'cap'}
                      id={'idSopCapStudio'}
                      placeholder={'CAP sede operativa'}
                      label={'CAP sede operativa*'}
                      onChange={(e) => this.handleChange(e, 'idSopCapStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.sopTelefonoStudio}
                      errorMessageAlert={this.props.message}
                      type={'tel'}
                      id={'idSopTelefonoStudio'}
                      placeholder={'Telefono sede operativa'}
                      label={'Telefono sede operativa*'}
                      onChange={(e) => this.handleChange(e, 'idSopTelefonoStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.sopFaxStudio}
                      type={'fax'}
                      id={'idSopFaxStudio'}
                      placeholder={'Fax sede operativa'}
                      label={'Fax sede operativa'}
                      onChange={(e) => this.handleChange(e, 'idSopFaxStudio', 'input')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.sopMailStudio}
                      errorMessageAlert={this.props.message}
                      type={'email'}
                      id={'idSopMailStudio'}
                      placeholder={'Mail sede operativa'}
                      label={'Mail sede operativa*'}
                      onChange={(e) => this.handleChange(e, 'idSopMailStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.sopPecStudio}
                      errorMessageAlert={this.props.message}
                      type={'email'}
                      id={'idSopPecStudio'}
                      placeholder={'PEC sede operativa'}
                      label={'PEC sede operativa*'}
                      onChange={(e) => this.handleChange(e, 'idSopPecStudio', 'input')}
                      currentStep={tabNumber}
                      isRequired
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.sitoInternet}
                      type={'text'}
                      id={'idSitoInternet'}
                      placeholder={'Sito internet'}
                      label={'Sito internet'}
                      onChange={(e) => this.handleChange(e, 'idSitoInternet', 'input')}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.facebook}
                      type={'text'}
                      id={'idFacebook'}
                      placeholder={'Facebook'}
                      label={'Facebook'}
                      onChange={(e) => this.handleChange(e, 'idFacebook', 'input')}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                    <GenericInput
                      defaultValue={elencoSedi?.twitter}
                      type={'text'}
                      id={'idTwitter'}
                      placeholder={'Twitter'}
                      label={'Twitter'}
                      onChange={(e) => this.handleChange(e, 'idTwitter', 'input')}
                    />
                  </div>
                </div>
              </div>
              {/*---------------------------------------------------------------------------------------------------------------------------------------------------------*/}
            </Form>
          </div>
        ) : (
          <GenericSpinner />
        )}
      </>
    )
  }
}

export declare interface GeneralFormUbicazioneContattiStpFOProps {
  tabNumber?: number
  message?: any
  getProvinciaSedeLegale?: any
  formData?: any
  isVisualizza?: boolean
  isModifica?: boolean
  datiIscrizione?: any
  provinciaSedeLegale?: any
  provinciaSedeOperativa?: any
}
