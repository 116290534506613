import { SiglaProvinciaDTO, StringParam } from '@parsec/msga'
import {
  getAnagrafeResourcesApi,
  getDefaultAxiosConfig,
  getIscrizioneOrdineResourceApi
} from 'src/app/utils/ManagerRestGateway'

/**
 * GET Elenco Province
 * @returns
 *
 */
export async function getAllProvince() {
  try {
    let response = await getAnagrafeResourcesApi().msgaAnagrafeFoProvinceGet();

    return response;
  } catch (err:any) {

    console.error("Errore durante la chiamata per il recupero delle province. ");
    throw new Error("Errore durante la chiamata per il recupero delle province. " );

  }
}

/**
 * GET della provincia by sigla BO
 * @returns
 * @param sigla
 */
export async function getProvinciaBySiglaBO(sigla: string) {
  try {
    var provBySigla: StringParam = {
      "param": sigla
    }
    let response = await getAnagrafeResourcesApi().msgaAnagrafeBoProvinciaBySiglaAutomobilisticaPost(provBySigla, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    console.error(". Errore durante la chiamata per il recupero della provincia. ");
    throw new Error(". Errore durante la chiamata per il recupero della provincia. ");
  }
}

/**
 * GET della provincia by sigla FO
 * @returns
 * @param sigla
 */
export async function getProvinciaBySiglaFO(sigla: string) {
  try {
    var provBySigla: StringParam = {
      "param": sigla
    }
    let response = await getAnagrafeResourcesApi().msgaAnagrafeFoProvinciaBySiglaAutomobilisticaPost(provBySigla, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    console.error("Errore durante la chiamata per il recupero della provincia. ");
    throw new Error("Errore durante la chiamata per il recupero della provincia. ");
  }
}

/**
 * GET della sigla della provincia by nome
 * @returns
 * @param nomeProvincia
 */
 export async function getSiglaProvinciaByNome(nome: string) {
  try {
    var nomeDTO: SiglaProvinciaDTO = {
      "nomeProvincia": nome
    }
    let response = await  getIscrizioneOrdineResourceApi().msgaIotGetSiglaProvinciaByNomePost(nomeDTO, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero della provincia. ");
    throw new Error("Errore durante la chiamata per il recupero della provincia. ");
 }
}
