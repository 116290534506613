/* eslint-disable react/no-direct-mutation-state */
import * as React from 'react'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from '../../components/GenericTab'
import Invio from './form/Invio'
import DatiInformativi from './form/DatiInformativi'
import TabellaUno from './form/TabellaUno'
import Riepilogo from './form/Riepilogo'
import PagamentoPolizza from './form/PagamentoPolizza'
import {
  aggiornaPolizza,
  convertPolizzaFromIndividualeToCollettiva,
  getPolizzaById,
  getPolizzaOriginariaAumentoById,
  inviaPolizza,
  uploadAllegatoPolizza
} from 'src/processes/assicurazioni/Assicurazione'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {
  inviaTabellaUno,
  recuperoTabellaUnoDefinitivaAssociataAllaPolizza
} from 'src/processes/assicurazioni/TabellaUno'
import InserimentoDatiPolizza from './form/InserimentoDatiPolizza'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import GenericChips from '../../components/GenericChips'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {
  PATH_TO_FO_ASSICURAZIONI,
  PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA,
  PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA
} from 'src/app/utils/RoutesConstants'
import PolizzeData from 'src/backoffice/app/components/Assicurazioni/PolizzeData'
import GenericDebug from 'src/backoffice/app/components/GenericDebug'
import { AppCostants } from 'src/app/utils/AppConstant'
import {
  linkTabCollettiva,
  linkTabIndividuale,
  listLegenda,
  MessaggiErroreUtente
} from 'src/processes/assicurazioni/CostantiPolizze'
import { AppUtils as U } from 'src/app/utils/FormUtils'
import GenericTitle from '../../components/GenericTitle'
import GenericAccordionCard from '../../components/GenericAccordionCard'
import GenericAlert from '../../components/GenericAlert'
import { getSoggettoByIdIscrizioneFO } from 'src/processes/Soggetto'
import { inputRequiredValidation } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'
import { ModalVariant } from '@patternfly/react-core'

var breadcrumbOptions = [
  { label: "Assicurazioni  /  Lista richieste", link: PATH_TO_FO_ASSICURAZIONI },
  { label: "Compilazione Polizza", link: PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA },
  { label: "Dati Informativi", link: PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA },
]

class CompilazionePolizza extends React.Component<any , any>{
    constructor(props) {
      super(props);
      this.state = {
          activeTab: 1,
          idIscrizione: 1,
          message:"",
          typeMessage: "default",
          isSubmitted:false,
          fields: {},
          errors: [],
          isLoaded: false,
          polizzaCollettiva: false,
          polizzaIndividuale:false,
          polizza: {},
          tabellaUno: {},
          checkEmpty:true,
          title: "Compilazione Polizza" ,
      };
  }


  async componentDidMount(): Promise<void> {

    this.setState({ message: "", typeMessage: 'default' })
    if(this.props.location.state?.polizza !== undefined && this.props.location.state.polizza.id) {
      let id = this.props.location.state.polizza.id;
      getPolizzaById(id).then(async (responsePolizza: any)=> {

        await this.setState({polizza: responsePolizza, isLoaded: true});
        if(responsePolizza.associatoConConaf === 1) {
          this.setState({polizzaCollettiva: true})
        }
        else if(responsePolizza.associatoConAltri === 1) {
          await this.setState({polizzaIndividuale: true})

        }
        if(responsePolizza.tipoRichiesta === "A") {
          this.setState({title: "Aumento Massimale Polizza"})
          breadcrumbOptions = [
            { label: "Assicurazioni  /  Lista richieste", link: PATH_TO_FO_ASSICURAZIONI },
            { label: "Aumenta massimale", link: PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA },
            { label: "Dati Informativi", link: PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA },
          ]
        }
        if(TokenStore.getInstance().isCurrentAccountSTP() === true) {
            //chiamata al servizio 'getSoggettoByIdIscrizione' che restituisce i dati del soggetto a partire dall'idIscrizione
            await getSoggettoByIdIscrizioneFO(responsePolizza.idIscrizione).then((response: any) => {
              // const keyData = TokenStore.getInstance().getDecodedTokenJWT();
              this.setState({codiceFiscale: response.data.partitaIva, utenteSTP: true})
            })
        }
        else {
            const keyData = TokenStore.getInstance().getDecodedTokenJWT();
            this.setState({codiceFiscale: keyData.fiscalNumber, utenteSTP: false})
        }
        await recuperoTabellaUnoDefinitivaAssociataAllaPolizza(this.props.location.state.polizza.id).then(async (responseTabella: any)=> {

          await this.setState({isLoaded: false, tabellaUno: responseTabella})
          let fields = this.state.fields;
          fields = responsePolizza;
          if(responseTabella !== null && responseTabella.id) {
            fields['idTabellaUno'] = responseTabella.id;
            await this.setState({ idTabella: responseTabella.id});
          }

          await this.setState({ fields: fields, polizza: fields, isLoaded: true });


        })
        .catch(async (err) => {
          this.setState({ message: err.message, typeMessage: 'danger' })
          await this.setState({isLoaded: false})
        })
      })
    }
    else {
      window.location.href = PATH_TO_FO_ASSICURAZIONI
    }
  }

  //Ottiene tutti i valori delle select riguardanti la sezione Pagamento
  getSelectValues = async (fields: any, type: any) => {
    if(type === 'mySelection') {
      if(fields.length > 0)
      this.setState({checkEmpty : true})
      else this.setState({checkEmpty : false})

    }
    else if(type === 'tabellaUno') {
      this.setState({tabellaUno: fields})
    }
    else if(type === 'polizza') {
      this.setState({polizza: fields})
    }
    else if(type === 'pagamento') {
      this.state.polizza.pagamento = fields;
    }
    else if(type === 'message') {
      this.setState({message: fields.message, typeMessage: fields.typeMessage})
    }
    else {
      await this.setState({fields: fields, data: fields})
    }
    this.setState({isLoaded: true})
  }

  //Ottiene tutti i valori delle select riguardanti la sezione Pagamento
  getData = async () => {
    return this.state.fields;
  }

  inviaPolizza = async (polizza: any) => {

    /************************** Chiamata per l'invio finale ******************************/
    let inviaPolizzaDTO = {
      "idPolizza": polizza.id,
      "note": null,
    }
    await inviaPolizza(inviaPolizzaDTO)
      .then((response: any) => {
        this.showModal(true, "Inviata con successo","La tua richiesta N. " + polizza.id + " è stata inviata con successo ed è in attesa di valutazione!", false);
        this.setState({ message: "", typeMessage: 'danger' })

      }).catch((err) => {
        this.showModal(true, "Errore.","Si è verificato un errore durante l'invio. Riprova in un secondo momento.", false);
      })
  }

  //Procedi allo step precedente
  previousStep = async () => {

    let copiaPagamento: any = this.state.polizza.copiaPagamentoPolizza
    let fileMaternitaPaternita: any = this.state.polizza.fileMaternitaPaternita

    await getPolizzaById(this.state.polizza.id).then(async (responsePolizza: any)=> {
      if(this.state.activeTab === 2){
        if(fileMaternitaPaternita !== undefined){
          this.setState({ activeTab: this.state.activeTab - 1})
          if(fileMaternitaPaternita[0].tipologiaAllegato === "polizze_dichiarazione_maternita_paternita"){
            this.setState({prevFileMPUploaded : true})
          }
        }
      }
        if(this.state.activeTab === 5){
        if(copiaPagamento !== undefined){
          this.setState({ activeTab: this.state.activeTab - 1})
          if(copiaPagamento[0].tipologiaAllegato === "polizze_copia_pagamento"){
            this.setState({prevFileUploaded : true})
          }
        }
      }
      if(this.state?.tabellaUno && this.state?.tabellaUno?.idTabellaUno) this.state.tabellaUno.id = this.state.tabellaUno.idTabellaUno
      breadcrumbOptions.pop()
      await this.setState({ activeTab: this.state.activeTab - 1, polizza: responsePolizza, isLoaded:true})
    })

  }

  //Procedi allo step successivo
  nextStep = async () => {

    await getPolizzaById(this.state.polizza.id).then(async (responsePolizza: any)=> {

      this.state.polizza.codiceFiscale = this.state.codiceFiscale;
      await this.getSelectValues(responsePolizza, 'polizza')
      let copiaPagamento: any = this.state.polizza.copiaPagamentoPolizza
      let fileMaternitaPaternita: any = this.state.polizza.fileMaternitaPaternita
      let currentPolizza: any = this.state.polizza;

      let message = "";
      await this.setState({polizzaCollettiva: true, data: responsePolizza, prevData: responsePolizza})

      let linkTab = linkTabCollettiva;

      //link tab
      if(this.state.polizza.associatoConAltri === 1) {
        this.setState({polizzaCollettiva: false})
        linkTab = linkTabIndividuale
      }
      //se lo stato è minore della lunghezza della tab entra qui
      if(this.state.activeTab <= linkTab.length) {
        switch(this.state.activeTab) {
            case 1:
                if(this.state.activeTab === 1 && fileMaternitaPaternita && fileMaternitaPaternita[0]["idAllegato"] === undefined) {
                          await uploadAllegatoPolizza(this.state.polizza.id, fileMaternitaPaternita[0]).then((response)=>{
                              fileMaternitaPaternita[0]["idAllegato"] = response.idAllegato;
                              this.setState({idAllegato : response.idAllegato})
                            })
                            .catch((err) => {
                                message = err.message
                                this.setState({ message: err.message, typeMessage: 'danger' });
                                return this.state.message;
                            })
                }

                await aggiornaPolizza(this.state.codiceFiscale, currentPolizza)
                            .then(async (response: any) => {
                                this.setState({polizza: response})
                                this.getSelectValues(response, 'polizza')
                            }).catch((err) => {
                                message = err.message

                                this.setState({ message: err.message, typeMessage: 'danger' });
                                return this.state.message;
                            })
                break;

            case 2:
                 //setta lo stato della tabella uno
                this.setState({tabellaUno: this.state.tabellaUno})
                let param = {
                  "param":  this.state.tabellaUno.idTabellaUno
                }
                //recupera i dati della tabella 1
                await inviaTabellaUno(param).then(async (response: any) => {
                      let polizza = this.state.polizza;
                      polizza['idPolizza'] = this.state.polizza.id
                      polizza['idTabella'] = this.state.tabellaUno.idTabellaUno
                      let tabellaUno = this.state.tabellaUno
                      tabellaUno['id'] = this.state.polizza.idTabellaUno
                      await getPolizzaById(this.state.polizza.id).then(async (responsePolizza: any)=> {
                        this.setState({polizza: responsePolizza})
                        this.getSelectValues(responsePolizza, 'polizza')
                      })
                }).catch((err: any) => {
                      setTimeout(async () => {

                        message = err.message
                        await this.setState({ message: err.message, typeMessage: 'danger' })

                        return this.state.message;
                      }, 3000);
                })
                break;
            case 3:
                break;
            case 4:
                if(this.state.activeTab === 4 && this.state.polizza.associatoConConaf === 1) {
                    //aggiorna l'allegato copia pagamento...ma non va fatto anche per le individuali
                    if(copiaPagamento && this.state.polizza.associatoConConaf === 1) {
                          if(this.state.polizza.notePagamento !== "" && this.state.polizza.notePagamento !== undefined && this.state.polizza.notePagamento !== null) {
                            copiaPagamento[0].descrizioneBreve = this.state.polizza.notePagamento;
                          }
                          await uploadAllegatoPolizza(this.state.polizza.id, copiaPagamento[0]).then((response)=>{
                            copiaPagamento[0]["idAllegato"] = response.idAllegato;
                            this.setState({idAllegato : response.idAllegato})
                          })
                          .catch((err) => {
                              message = err.message

                              this.setState({ message: err, typeMessage: 'danger' });
                              return this.state.message;
                          })
                    }
                }

                if(copiaPagamento && copiaPagamento[0]["idAllegato"] !== null) {
                  //Significa che ho caricato un file. In questo caso devo prelevare la lista degli allegati aggiornata
                  //E se ho modificato i dati pagamento salvarli in quell'allegato.
                  await getPolizzaById(this.state.polizza.id).then(async (responsePolizza: any)=> {
                    this.setState({polizza: responsePolizza})
                    this.getSelectValues(responsePolizza, 'polizza')
                    let allegatoFound = responsePolizza?.assicurazioneAllegati.filter(
                      allegato =>
                          allegato.idAllegato === copiaPagamento[0]["idAllegato"]
                      )[0]
                    if(allegatoFound && copiaPagamento[0]["poPagamenti"]) {
                      allegatoFound['poPagamenti'] = copiaPagamento[0]["poPagamenti"]
                    }
                    this.state.polizza.assicurazioneAllegati = responsePolizza.assicurazioneAllegati;
                  })
                }
                await aggiornaPolizza(this.state.codiceFiscale, this.state.polizza)
                    .then(async (response: any) => {
                        this.setState({polizza: response})
                        this.getSelectValues(response, 'polizza')
                    }).catch(async (err) => {
                      message = err.message
                      await this.setState({ message: err.message, typeMessage: 'danger'});
                      //this.previousStep()

                      return this.state.message;
                    })
                break;
            case 5:
                break;

        }

        if(responsePolizza.tipoRichiesta === "A") {
          await breadcrumbOptions.push({label:linkTab[this.state.activeTab].label,link: PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA})
        }
        else {
          await breadcrumbOptions.push({label:linkTab[this.state.activeTab].label,link: PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA})
        }
        if(message === "")
          await this.setState({ activeTab: this.state.activeTab + 1, polizza:responsePolizza, isLoaded:true, message: message})
      }
    })
  }

  hasFormErrors = (alert) => {

    var findItem = this.state.errors.find(a => a.id === alert.id)
    var errorID = this.state.errors.indexOf(findItem)
    if (findItem) {
      if (alert.isHidden)
        this.state.errors[errorID] = { id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab, value: alert.value, label: alert.label }
      else
        this.state.errors[errorID] = { error: alert.hasError, id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab, value: alert.value, label: alert.label }
    } else {
      this.state.errors.push({ error: alert.hasError, id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab, value: alert.value, label: alert.label })
    }
  }


  checkEmptyTabella = async (value) => {

    this.setState({checkEmpty: value})

  }

  closeModal = () => {
    this.setState({modalOpen:false});
    if(this.state.activeTab === 5)
      window.location.href = PATH_TO_FO_ASSICURAZIONI
  }

  changeFromIndividualeToCollettiva = async () => {
    this.setState({modalOpen:false});

    await convertPolizzaFromIndividualeToCollettiva(this.state.polizza.id)
    .then((response: any) => {
      this.showModal(true, "Operazione avvenuta con successo","La tua richiesta N. " + response.id + " è stata convertita in una polizza collettiva ed è in fase di compilazione!", false);
      this.setState({activeTab: 5})
    }).catch((err) => {
        this.showModal(true, "Errore.","Si è verificato un errore durante l'invio. Non è stato possibile eseguire la conversione. Ricorda che se è già presente una polizza collettiva non è possibile crearne una nuova. In tal caso si consiglia di modificare manualmente i valori non validi per l'attuale polizza individuale.", false);
    })
  }

  /*  Questa funzione permette di settare le props da mandare in input al modal alert
  *   in particolare si può scegliere il tipo di alert in base al makeChoice:
  *   - OK    -> Visualizzazione di un messaggio
  *   - SI/NO -> L'utente effettua una scelta
  */
  showModal = async (booleanValue, title, text, makeChoice) => {

    //al loading false o true ricarica la pagina

    //await this.setState({isLoaded: false});
    //check rischio conaf
    //Se il valore di vrcConaf > valoreRischioAltri l'utente può scegliere se cambiare la propria polizza da individuale a collettiva
    //oppure modificare il valore di rischio

    //check massimale
    //Se il valore di vopConaf > massimaleAltri l'utente può scegliere se cambiare la propria polizza da individuale a collettiva
    //oppure modificare il massimale

    if(makeChoice === true) {
        text = text + ". In questo caso è possibile scegliere se cambiare la propria polizza da individuale a collettiva oppure modificare il valore e rendere la polizza idonea. Scegliere SI se si vuole convertire la polizza da individuale a collettiva."
    }

    await this.setState({
        modalOpen: booleanValue,
        makeChoice: makeChoice,
        modalTitle: title,
        modalText:text,
        //isLoaded: true,
    });


  }

  render() {

    const {activeTab, isSubmitted, typeMessage, message, title} = this.state;

    /* Contiene gli avvisi provenienti da backoffice - inseriti dall'operatore */
    const AvvisiBO = () => {
        return (
          <>
              <div className="row" hidden={!this.state.polizza.noteRifiuto || (this.state.polizza.stato !== 3 && this.state.polizza.stato !==4 )} >
                      Note
                      <GenericAlert key="noteRifiuto" id="noteRifiuto" color={'danger'} label={this.state.polizza.noteRifiuto} />
              </div>
          </>
        )
    }
    const onSubmit = async (event) => {
      var errorMessage = { message: MessaggiErroreUtente.campi_obbligatori, typeMessage: "danger" }
      event.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
      var errorRequired: any = inputRequiredValidation(this.state.errors.filter(e => !e.isDisabled && !e.isHidden && e.stepTab === this.state.activeTab) || this.state.errors.length === 0)
      if (errorRequired) {
        event.preventDefault()
        return this.setState({ message: errorRequired.messaggio, typeMessage: errorRequired.type })
      }
      else
      {
        this.setState({ message: '', typeMessage: 'default' })
        if(this.state.activeTab === 1){
            //rifattorizzare validazione in presenza di aumento di massimale
            //e verificare la presenza dei nuovi campi
           //se è un aumento di massimale vado a verificare che l'utente ha flaggato la dichiarazione
           if(this.state.polizza.tipoRichiesta === "A"){
              if(this.state.polizza && this.state.polizza.sinistri && this.state.polizza.sinistriRicevutoLiberatoriaCompagnia && this.state.polizza.sinistri.label === "Si" && this.state.polizza.sinistriRicevutoLiberatoriaCompagnia.label === "No" ) {
                /*
                  Il sistema blocca la procedura qualora l'iscritto dichiari di avere sinistri ma non dichiari di essere in possesso dell'autorizzazione della compagnia.
                */
                //await this.showModal(true, "Attenzione","Compila tutti i campi obbligatori: Se si dichiara di avere richieste di sinistro è necessario dichiarare di aver ricevuto la liberatoria dalla compagnia.", false);
                await this.setState({ message: "Se si dichiara di avere richieste di sinistro è necessario dichiarare di aver ricevuto la liberatoria dalla compagnia.", typeMessage: 'danger' })
                return this.state.message;
              }

             if(
              this.state.polizza.haLettoCircostanza === 0 ||
              this.state.polizza.haLettoCircostanza === false ||
              this.state.polizza.haLettoCircostanza === null
             ){
              await this.setState(errorMessage)
              return this.state.message;
             }
           }
          //verifica privacy
          if (
              this.state.polizza.privacy === null ||
              this.state.polizza.privacy === false ||
              this.state.polizza.privacy === 0 )
            {
              await this.setState(errorMessage)
              return this.state.message;
            }

            //verifica normativa di riferimento
          if (
              this.state.polizza.haLettoNormativa === null ||
              this.state.polizza.haLettoNormativa === false ||
              this.state.polizza.haLettoNormativa === 0 )
            {
              await this.setState(errorMessage)
              return this.state.message;
            }

             //verifica vademecum di riferimento
          if (
              this.state.polizza.haLettoVademecum === null ||
              this.state.polizza.haLettoVademecum === false ||
              this.state.polizza.haLettoVademecum === 0 )
            {
              await this.setState(errorMessage)
              return this.state.message;
            }
          else if(this.state.polizza && this.state.polizza.idCategoriaDue && this.state.polizza.idCategoriaDue.label === "Si" && this.state.polizza.categoriaCalculated === 1  && (((this.state.polizza.maternita === null && this.state.polizza.paternita === null)) || (this.state.polizza.maternita.label === 'No' && this.state.polizza.paternita.label === 'No') || ((this.state.polizza.maternita.label === 'Si' && this.state.polizza.paternita.label === 'Si')) )) {
                //await this.setState({ message: "Inserire tutti i campi obbligatori", typeMessage: 'danger' })
                await this.showModal(true, "Attenzione","Se si sceglie la Categoria 2 è necessario selezionare Maternità o Paternità (e non entrambi).", false);
                return this.state.message;
            }
          else if(((this.state.polizza.idCategoriaDue && this.state.polizza.idCategoriaDue.label==="Si") || this.state.polizza.categoria === 2) && ( this.state.polizza.assicurazioneAllegati.filter(
              allegato =>
                  allegato.cancellato === 0 &&
                  allegato.tipologiaAllegato === "polizze_dichiarazione_maternita_paternita"
              ).length === 0 && this.state.polizza?.fileMaternitaPaternita === undefined) && (this.state.polizza.maternita.label === 'Si' || this.state.polizza.paternita.label === 'Si')) {
              await this.setState({ message: "Compila tutti i campi obbligatori: Certificato Maternità / Paternità", typeMessage: 'danger' })
              await this.showModal(true,"Attenzione", "Si prega di caricare il file", false);
              return this.state.message;
            }
          else this.nextStep()
        }

        else if(this.state.activeTab === 2){
          if(this.state.checkEmpty === false){
            await this.setState({ message: "Compila tutti i campi obbligatori: Tabella 1", typeMessage: 'danger' })
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            event.preventDefault();
          } else this.nextStep()
        }
        if(this.state.activeTab === 3 && (this.state.errors.find(e => e.error === true && !e.isDisabled && e.stepTab === this.state.activeTab) || this.state.errors.length === 0)) {

          this.setState(errorMessage)
        }  else {

            if(this.state.activeTab === 3 && this.state.polizza.associatoConConaf === 1 ) {

              await getPolizzaById(this.state.polizza.id).then(async (responsePolizza: any)=> {

                if(responsePolizza.premioConaf > 0){
                  this.setState({gratuita : false})
                }  else this.setState({gratuita : true})

                if(responsePolizza.tipoRichiesta === 'A'){
                  await getPolizzaOriginariaAumentoById(this.state.polizza.idPolizzaAumMassimale).then(async (responsePoOriginaria: any)=> {

                    if(responsePoOriginaria.massimaleConaf >= responsePolizza.massimaleConaf) {
                        await this.setState({ message: "Tabella uno non compilata correttamente", typeMessage: 'danger' })
                        await this.showModal(true, "Attenzione.","Tabella uno non compilata correttamente. Non è possibile confermare una tabella uno in cui il massimale di partenza sia maggiore del massimale dell'aumento del massimale che si sta compilando.", false);
                        return this.state.message;
                      } else {
                        this.setState({ message: "", typeMessage: 'default' })
                        this.nextStep()
                       }

                  });
                } else {
                  this.setState({ message: "", typeMessage: 'default' })
                  this.nextStep()
                 }
              })

            }
            else if(this.state.activeTab === 3 && this.state.polizza.associatoConAltri === 1 ) {
              this.setState({ message: "", typeMessage: 'default' })
              this.nextStep()
             }
        }

        //controlli formali e logici del passo 4
      if(this.state.activeTab === 4 &&  this.state.polizza.associatoConAltri === 1 ){
              let error = "false";
              let errorMessage = "";
              let makeChoice = false;
              this.setState({ message: undefined, typeMessage: 'danger' })

              //parametri di rischio
              const valoreRischioAltri = Number(this.state.polizza.valoreRischioAltri);
              const massimaleAltri = Number(this.state.polizza.massimaleAltri);
              const vrcConaf = Number(this.state.polizza.vrcConaf);
              const vopConaf = Number(this.state.polizza.vopConaf);

              //copertura danni patrimoniali
              if(U.nd(this.state.polizza.danniPatrimoniali) !== 1 && error === "false"){
                errorMessage = MessaggiErroreUtente.individuale_no_copertura_danni_patrimoniali;
                error = "true";
              }

              //copertura retroattivita
              if(U.nd(this.state.polizza.retroattivitaIllimitata) !== 1 && error === "false"){
                errorMessage = MessaggiErroreUtente.individuale_no_retroattivita;
                error = "true";
              }

              //copertura postuma decennale
              if(U.nd(this.state.polizza.coperturaPostuma) !== 1 && error === "false"){
                errorMessage = MessaggiErroreUtente.individuale_no_copertura_postuma_decennale;
                error = "true";
              }

              //check rischio conaf
              //Se il valore di vrcConaf > valoreRischioAltri l'utente può scegliere se cambiare la propria polizza da individuale a collettiva
              //oppure modificare il valore di rischio
              if(vrcConaf > valoreRischioAltri && error === "false"){
                errorMessage = MessaggiErroreUtente.individuale_valore_rischio_inferiore;
                makeChoice = true;  //Questo flag consente di inserire una scelta Si/No nel messaggio di alert
                error = "true";
              }

              //check massimale
              //Se il valore di vopConaf > massimaleAltri l'utente può scegliere se cambiare la propria polizza da individuale a collettiva
              //oppure modificare il massimale
              if(vopConaf > massimaleAltri && error === "false"){
                errorMessage = MessaggiErroreUtente.individuale_valore_massimale_inferiore;
                makeChoice = true;
                error = "true";
              }

              if(error === "true"){
                event.preventDefault();
                await this.setState({ message: errorMessage, typeMessage: 'danger' })
                await this.showModal(true,"Attenzione", errorMessage, makeChoice);

                return this.state.message;
              }
              else {

                await this.setState({ message: "", typeMessage: 'default' })
                if (this.state.errors.find(e => e.error === true && !e.isDisabled && e.stepTab === this.state.activeTab) || this.state.errors.length === 0) {
                    await this.setState(errorMessage)
                }
                else {
                  this.nextStep()
                }
              }
        }
        if(this.state.activeTab === 4 && this.state.polizza.associatoConConaf === 1) {
          if( this.state.polizza.assicurazioneAllegati.filter(
            allegato =>
                allegato.cancellato === 0 &&
                allegato.tipologiaAllegato === "polizze_copia_pagamento"
            ).length === 0 && (this.state.polizza?.copiaPagamentoPolizza === undefined || (this.state.polizza?.copiaPagamentoPolizza && this.state.polizza?.copiaPagamentoPolizza.length === 0)) && this.state.polizza.premioConaf > 0) {

            await this.setState({ message: "Compila tutti i campi obbligatori: Copia del pagamento", typeMessage: 'danger' })
            await this.showModal(true,"Attenzione", "Si prega di caricare il file", false);

            return this.state.message;
          }
          // if(this.state.polizza?.assicurazioneAllegati.filter(
          //   allegato =>
          //   (allegato.poPagamenti === null || (allegato.poPagamenti.banca === undefined || isEmptyValue(allegato.poPagamenti?.banca)) || (allegato.poPagamenti.ibanCode === undefined || isEmptyValue(allegato.poPagamenti?.ibanCode))) &&
          //   allegato.tipologiaAllegato === "polizze_copia_pagamento"
          //   )[0] || ((this.state.polizza?.copiaPagamentoPolizza !== undefined && (this.state.polizza?.copiaPagamentoPolizza && this.state.polizza?.copiaPagamentoPolizza.filter(
          //     allegato =>
          //     (allegato.poPagamenti === null || isEmptyValue(allegato.poPagamenti?.banca) ||  isEmptyValue(allegato.poPagamenti?.ibanCode)) &&
          //     allegato.tipologiaAllegato === "polizze_copia_pagamento"
          //     )[0]))))
          // {

          //     await this.setState({ message: "Inserire tutti i campi obbligatori", typeMessage: 'danger' })
          //     return this.state.message;
          // }

          if (this.state.errors.find(e => e.error === true && !e.isDisabled && e.stepTab === this.state.activeTab) || this.state.errors.length === 0) {
            this.setState(errorMessage)
          } else this.nextStep()
        }
        if (this.state.errors.find(e => e.error === true && !e.isDisabled && e.stepTab === this.state.activeTab) || this.state.errors.length === 0) {

            await this.setState(errorMessage)
        }
        else {
            if (this.state.activeTab === 1 && this.state.checkEmpty === false){
            } else {
              if(this.state.polizzaCollettiva === true){
                if(this.state.polizza !== null){
                  this.setState({polizza: this.state.polizza});
                }
                event.preventDefault();
              }
            }
          }
        }
    }

    const listBodyTabColl =
    /** Lista del contenuto dei Tab per la compilazione della polizza */
    [
        {
          id:1,
          body: (this.state.activeTab === 1) ? <DatiInformativi key={'1'} modificaDatiFlag={true} visualizzaFlag={false} isRequired={this.props.isRequired} data={this.state.polizza} prevFileMPUploaded={this.state.prevFileMPUploaded} fileExists={this.state.fileExists} idAllegato={this.state.idAllegato} prevData={this.state.polizza} polizza={this.state.polizza} getSelectedValue={this.getSelectValues} tabNumber={1} message={this.hasFormErrors}/> : null
        },
        {
          id:2,
          body: (this.state.activeTab === 2) ? <TabellaUno key={'2'} modificaDatiFlag={true} checkEmpty={this.checkEmptyTabella} visualizzaFlag={false} polizza={this.state.polizza} data={this.state.polizza} getSelectedValue={this.getSelectValues} tabellaUno={this.state.tabellaUno} tabNumber={2} message={this.hasFormErrors}/> : null
        },
        {
          id:3,
          body: (this.state.activeTab === 3) ? <Riepilogo key={'3'} modificaDatiFlag={true} visualizzaFlag={false}  polizza={this.state.polizza}  data={this.state.polizza} prevData={(this.state.prevClicked) ? this.state.polizza : null} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={3} /> : null
        },
        {
          id:4,
          body: (this.state.activeTab === 4) ? <PagamentoPolizza key={'4'} prevData={this.state.polizza} gratuita={this.state.gratuita} polizza={this.state.polizza} prevFileUploaded={this.state.prevFileUploaded} idAllegato={this.state.idAllegato} tabellaUno={this.state.tabellaUno} modificaDatiFlag={true} visualizzaFlag={false} data={this.state.polizza} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={4} message={this.hasFormErrors} /> : null
        },
        {
          id:5,
          body: (this.state.activeTab === 5) ? <Invio key={'5'} modificaDatiFlag={true} visualizzaFlag={false}   getSelectedValue={this.getSelectValues} data={this.state.polizza} activeTab={activeTab} inviaPolizza={this.inviaPolizza} tabNumber={5} /> : null
        }
    ];

  const listBodyTabInd =
  /** Lista del contenuto dei Tab per la compilazione della polizza */
  [
    {
      id:1,
      body: (this.state.activeTab === 1) ? <DatiInformativi key={'1'} prevFileMPUploaded={this.state.prevFileMPUploaded} idAllegato={this.state.idAllegato} modificaDatiFlag={true} visualizzaFlag={false} data={this.state.polizza} polizza={this.state.polizza} prevData={this.state.polizza} getSelectedValue={this.getSelectValues} tabellaUno={this.state.tabellaUno} tabNumber={1} message={this.hasFormErrors}/> : null
    },
    {
      id:2,
      body: (this.state.activeTab === 2) ? <TabellaUno key={'2'} modificaDatiFlag={true} checkEmpty={this.checkEmptyTabella} visualizzaFlag={false} data={this.state.polizza} prevData={this.state.polizza} getSelectedValue={this.getSelectValues} tabellaUno={this.state.tabellaUno} tabNumber={2} message={this.hasFormErrors}/> : null
    },
    {
      id:3,
      body: (this.state.activeTab === 3) ? <Riepilogo key={'3'} modificaDatiFlag={true} visualizzaFlag={false} data={this.state.polizza} prevData={(this.state.prevClicked) ? this.state.polizza : null} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={3} /> : null
    },
    {
      id:4,
      body: (this.state.activeTab === 4) ? <InserimentoDatiPolizza key={'4'} modificaDatiFlag={true} visualizzaFlag={false} data={this.state.polizza} prevData={this.state.polizza} polizza={this.state.polizza} tabellaUno={this.state.tabellaUno} getSelectedValue={this.getSelectValues} activeTab={activeTab} tabNumber={4} message={this.hasFormErrors} /> : null
    },
    {
      id:5,
      body: (this.state.activeTab === 5) ? <Invio key={'5'} modificaDatiFlag={true} visualizzaFlag={false} getSelectedValue={this.getSelectValues} data={this.state.polizza} activeTab={activeTab} inviaPolizza={this.inviaPolizza} tabNumber={5} /> : null
    },

  ];


    return(
      <div key={"CompilazionePolizza"} className={isCurrentMobileDevice() ? 'container register-container-zero-padding' : 'container-fluid register-container p-4'}>
      <div className={isCurrentMobileDevice() ? null : 'row'}>

        <div className='col-11'>
          {
            (this.state.isLoaded) ?
            <div>
                <GenericBreadCrumb key="genericTab" paths={breadcrumbOptions} />

                  <div className={isCurrentMobileDevice() ? null : "d-flex justify-content-between ipi-title"} hidden={this.state.selectedPolizza === undefined}>
                          <GenericTitle Size="h1" title={title + " " +(this.state.polizza.associatoConConaf ? "Collettiva" : "Individuale")}></GenericTitle>
                          <div className={isCurrentMobileDevice() ? "row" : null} ><GenericChips label={"Polizza "+ (this.state.polizza.associatoConConaf ? "Collettiva" : "Individuale" )+ " " + this.state.polizza.id}></GenericChips></div>
                  </div>
                  {  AppCostants.isDebug() === true &&
                  <>
                    <GenericDebug cardHeader={<h4>DEBUG</h4>} cardBody={
                                          <PolizzeData
                                              fields={this.state.fields}
                                              polizza={this.state.polizza}
                                              polizzaInCompilazione={this.state.polizza}
                                              intestatarioPolizza={this.state.utente}
                                              comunicantePolizza={undefined}
                                              decodeToken={false}
                                          />
                                        } />
                    </>
                  }

                  <div hidden={this.state.polizza.stato !== 4}>
                    <GenericAccordionCard cardHeader={<h4>Note</h4>} cardBody={<AvvisiBO></AvvisiBO>} /><br></br>
                  </div>

                  <div className="justify-content-center">

                    {this.state.polizzaIndividuale === true ? <GenericTab
                              isClickable={true}
                              key={'genericTabIscrizione'}
                              hiddenAlert={message === ""}
                              alertTypeMessage={typeMessage}
                              labelAlert={message}
                              isSubmitted={isSubmitted}
                              onSubmit={onSubmit}
                              listBodyTab={listBodyTabInd}
                              listNavLink={linkTabIndividuale}
                              activeTab={activeTab}
                              previousStep={this.previousStep}
                              homeLink={PATH_TO_FO_ASSICURAZIONI}
                            ></GenericTab> :

                            <GenericTab
                              isClickable={true}
                              key={'genericTabIscrizione'}
                              hiddenAlert={message === ""}
                              alertTypeMessage={typeMessage}
                              labelAlert={message}
                              isSubmitted={isSubmitted}
                              onSubmit={onSubmit}
                              listBodyTab={listBodyTabColl}
                              listNavLink={linkTabCollettiva}
                              activeTab={activeTab}
                              previousStep={this.previousStep}
                              homeLink={PATH_TO_FO_ASSICURAZIONI}
                            ></GenericTab>}
                            <GenericModal
                                  title={this.state.modalTitle}
                                  text={this.state.modalText}
                                  modalOpen={this.state.modalOpen}
                                  closeButton={this.closeModal}
                                  makeChoice={this.state.makeChoice}
                                  choiceAction={this.changeFromIndividualeToCollettiva}
                                  size={isCurrentMobileDevice() ? ModalVariant.small : ModalVariant.large}
                            />
                      <div>{listLegenda}</div>
                  </div>

                </div>
            : <GenericSpinner></GenericSpinner>
          }
        </div>
      </div>
      </div>
    )
  }
}
export declare interface InserisciProps {
  activeTab?: any;
  idIscrizione?: any;
  visualizzaFlag?:any;
  aumentoMassimaleFlag?:any;
  modificaDatiFlag?:any;
  checkEmpty?:any;
}

export default CompilazionePolizza;
