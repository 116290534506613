import { Form, Icon } from 'design-react-kit'
import * as React from 'react'
import GenericButton from 'src/frontend/app/components/GenericButton'
import GenericInput from 'src/frontend/app/components/GenericInput'
import { getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizione } from 'src/processes/Soggetto'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormElencoDipendentiIscrittiAlboStpFO extends React.Component<GeneralFormElencoDipendentiIscrittiAlboStpFOProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      dipendenti: props.composizioneSocietaria,
    }
  }

  componentDidMount(): void {
    this.props.dipendenti(this.state.dipendenti)
  }

  //metodo che dato il cf di un dipendente iscritto all'albo recupera il rispettivo nome, cognome e titolo
  handleChangeCodiceFiscale = async (event, id) => {
    var codiceFiscaleDigitato = event.target.value
    var tipoAnagrafe = 'PF'
    var tipologiaIscrizione = 1 //iscritto all'ordine

    let initializedDipendenti = this.state.dipendenti.map((dipendente, i) => {
      if (i === id)
        return {
          idSoggettoPF: null,
          idSocietaPG: null,
          isSocio: 0,
          isLegaleRappresentante: null,
          isDipendenteIscritto: 1,
          nome: '',
          cognome: '',
          cf: '',
          titProf: '',
        }
      else return dipendente
    })
    this.setState({ dipendenti: initializedDipendenti })

    // Verifico che il dipendente non sia già stato inserito
    var dipendenteFound = false
    this.state.dipendenti.map((dipendente) => {
      if (
        !dipendenteFound &&
        dipendente.cf.toUpperCase() === codiceFiscaleDigitato.toUpperCase() &&
        (dipendente.cancellato === undefined || dipendente.cancellato === false)
      )
        dipendenteFound = true
    })

    if (!dipendenteFound) {
      //chiamata al servizio 'getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizione' che restituisce i dati del soggetto a partire dal codice fiscale, tipo anagrafe e tipologia iscrizione
      getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizione(codiceFiscaleDigitato.toUpperCase(), tipoAnagrafe, tipologiaIscrizione)
        .then(async (responseSoggetto: any) => {
          var idDipendente
          if (responseSoggetto.data && responseSoggetto.data.error) throw new Error(responseSoggetto.data.error)
          // Verifico se ho già il codice fiscale nella mappa dei dipendenti
          this.state.dipendenti.map((dipendente, i) => {
            if (codiceFiscaleDigitato.toUpperCase() === dipendente.cf) idDipendente = i

            if (idDipendente !== i) {
              idDipendente = id
            }
          })

          this.state.dipendenti[idDipendente] = {
            idSoggettoPF: responseSoggetto.data.returnedObject.idSoggetto,
            idSocietaPG: this.props.idSocieta,
            isSocio: 0,
            isLegaleRappresentante: 0,
            isDipendenteIscritto: 1,
            nome: responseSoggetto.data.returnedObject.nome,
            cognome: responseSoggetto.data.returnedObject.cognome,
            cf: codiceFiscaleDigitato,
            titProf: responseSoggetto.data.returnedObject.titoloRichiesto,
          }
          this.props.dipendenti(this.state.dipendenti)
        })
        .catch((err) => {
          console.error('Errore durante la getSoggettoByCfTipoAnagrafeAndTipoIscrizione: Dipendente non trovato ', err)
        })
    }
  }

  //metodo che aggiunge un elemento nell'array dipendenti
  aggiungiDipendente = (dipendenti: any) => {
    dipendenti?.push({
      idSoggettoPF: null,
      idSocietaPG: null,
      isSocio: 0,
      isLegaleRappresentante: null,
      isDipendenteIscritto: 1,
      nome: '',
      cognome: '',
      cf: '',
      titProf: '',
    })
    this.setState({ dipendenti: dipendenti })
  }

  //metodo che elimina l'elemento dell'array dipendenti
  eliminaDipendente = async (dipendenti: any, id: any) => {
    dipendenti[id] = { ...dipendenti[id], isSocio: 0, isDipendenteIscritto: 1, cancellato: true }
    await this.setState({ dipendenti })
    this.props.dipendenti(this.state.dipendenti)
  }

  render() {
    const { dipendenti } = this.state
    const { isVisualizza } = this.props
    return (
      <div>
        {/*--------------------------------------------------------------------------- FORM PER ISCRIZIONE STP ----------------------------------------------------------------------------*/}
        <Form>
          <div hidden={isVisualizza}>
            {dipendenti.map((dipendente, id) => {
              return dipendente.cancellato !== true ? (
                <div className="row" key={id}>
                  <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                    <GenericInput
                      defaultValue={dipendente.cf}
                      type={'cf'}
                      id={'idCodiceFiscaleDipendente' + id}
                      placeholder={'Inserire codice fiscale'}
                      label={'Codice fiscale del dipendente'}
                      onChange={(e) => this.handleChangeCodiceFiscale(e, id)}
                      errorMessageAlert={this.props.message}
                      isReadOnly={dipendente.cf}
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-4'} hidden={!dipendente.cf}>
                    <GenericInput
                      type={'text'}
                      id={'idNomeCognomeDipendente' + id}
                      label={'Nome e cognome del dipendente'}
                      value={dipendente.nome + ' ' + dipendente.cognome}
                      errorMessageAlert={this.props.message}
                      isReadOnly
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-3'} hidden={!dipendente.cf}>
                    <GenericInput
                      type={'text'}
                      id={'idTitolo' + id}
                      label={'Titolo del dipendente'}
                      value={dipendente.titProf ? dipendente.titProf : '-'}
                      errorMessageAlert={this.props.message}
                      isReadOnly
                    />
                  </div>
                  <div className={isCurrentMobileDevice() ? null : 'col-1'}>
                    <GenericButton
                      size="xs"
                      type="button"
                      color="tertiary"
                      onClickEvent={() => this.eliminaDipendente(dipendenti, id)}
                      children={<Icon size="sm" className="mt-3" color="danger" icon="it-delete" />}
                    />
                  </div>
                </div>
              ) : null
            })}
            <div className="col-6">
              <GenericButton size="xs" type="button" color="primary" label="Aggiungi dipendente" onClickEvent={() => this.aggiungiDipendente(dipendenti)} />
            </div>
          </div>
        </Form>
        {/*-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
      </div>
    )
  }
}

export declare interface GeneralFormElencoDipendentiIscrittiAlboStpFOProps {
  message?: any
  tabNumber?: any
  dipendenti?: any
  idSocieta?: any
  isVisualizza?: boolean
  isModifica?: boolean
  datiIscrizione?: any
  composizioneSocietaria?: any
}
