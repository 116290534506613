import {
  SoggettoByCfAnagrafeTipoIscrizioneDTO,
  SoggettoByCfTipoAnagrafeDTO,
  SoggettoByIdAndTipologiaDTO,
  StringParam
} from '@parsec/msga'
import { getAnagrafeResourcesApi, getDefaultAxiosConfig } from 'src/app/utils/ManagerRestGateway'

/**
 * GET dei dati del soggetto by CF
 * @returns
 * @param cf
 */
export async function getSoggettoByCf(cf: string) {
  try {
    var param = {
      "param": cf
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFindSoggettoPost(param, getDefaultAxiosConfig());
    return response;
  } catch (err: any) {
    // TODO - Gestire errore
    // let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err.message, 500)
    // throw await new Error(errorMessage.title + errorMessage.message);
    console.error("Errore durante la chiamata msgaAnagrafeFindSoggettoPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFindSoggettoPost - " + err);
  }
}

/**
 * GET dei dati del soggetto by CF e tipo anagrafe
 * @returns
 * @param cf
 * @param tipoAnagrafe
 */
export async function getSoggettoByCfAndTipoAnagrafeFO(cf: string, tipoAnagrafe: string) {
  try {
    var params: SoggettoByCfTipoAnagrafeDTO = {
      "cf": cf,
      "tipoAnagrafe": tipoAnagrafe
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoFindSoggettoByCfAndTipoAnagrafePost(params, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    //TODO - Gestire meglio l'errore
    console.error("Errore durante la chiamata msgaAnagrafeFindSoggettoByCfAndTipoAnagrafePost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFindSoggettoByCfAndTipoAnagrafePost - " + err);
  }
}

/**
 * GET dei dati del soggetto by CF e tipo anagrafe
 * @returns
 * @param cf
 * @param tipoAnagrafe
 */
export async function getSoggettoByCfAndTipoAnagrafeBO(cf: string, tipoAnagrafe: string) {
  try {
    var params: SoggettoByCfTipoAnagrafeDTO = {
      "cf": cf,
      "tipoAnagrafe": tipoAnagrafe
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoFindSoggettoByCfAndTipoAnagrafePost(params, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeFindSoggettoByCfAndTipoAnagrafePost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFindSoggettoByCfAndTipoAnagrafePost - " + err);
  }
}

/**
 * GET dei dati del soggetto by CF, tipo anagrafe e tipo iscrizione
 * @returns
 * @param cf
 * @param tipoAnagrafe
 * @param tipologiaIscrizione
 */
export async function getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizione(cf: string, tipoAnagrafe: string, tipologiaIscrizione: number) {
    
  try {
    var params: SoggettoByCfAnagrafeTipoIscrizioneDTO = {
      "cf": cf,
      "tipoAnagrafe": tipoAnagrafe,
      "tipologiaIscrizione": tipologiaIscrizione,
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetSoggettoByCfAnagrafeTipoIscrizionePost(params, getDefaultAxiosConfig());
    // return ErrorStore.getInstance().checkResponseReturnResponse(response);
    return response;


  } catch (err) {
    //TODO - Gestire meglio l'errore
    console.error("Errore durante la chiamata msgaAnagrafeFoGetSoggettoByCfAnagrafeTipoIscrizionePost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFoGetSoggettoByCfAnagrafeTipoIscrizionePost - " + err);
  }
}

/**
 * GET dei dati del soggetto by CF, tipo anagrafe e tipo iscrizione
 * @returns
 * @param cf
 * @param tipoAnagrafe
 * @param tipologiaIscrizione
 */
export async function getSoggettoIscrittoByCfTipoAnagrafeTipoIscrizioneBO(cf: string, tipoAnagrafe: string, tipologiaIscrizione: number) {
  
  try {
    var params: SoggettoByCfAnagrafeTipoIscrizioneDTO = {
      "cf": cf,
      "tipoAnagrafe": tipoAnagrafe,
      "tipologiaIscrizione": tipologiaIscrizione,
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoGetSoggettoByCfAnagrafeTipoIscrizionePost(params, getDefaultAxiosConfig());
    // return ErrorStore.getInstance().checkResponseReturnResponse(response);
    return response;


  } catch (err) {
    //TODO - Gestire meglio l'errore
    console.error("Errore durante la chiamata msgaAnagrafeFoGetSoggettoByCfAnagrafeTipoIscrizionePost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFoGetSoggettoByCfAnagrafeTipoIscrizionePost - " + err);
  }
}

/*
DEPRECATA NON CHIARO IL CONTESTO DI UTILIZZO SE FO O BO
*/
/**
 * GET dei dati del soggetto by idIscrizione
 * @returns
 * @param idIscrizione
 */
export async function getSoggettoByIdIscrizione(idIscrizione: number) {
  try {
    var param = {
      "idIscrizione": idIscrizione
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoFindSoggettoByIdIscrizionePost(param, getDefaultAxiosConfig());
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err) {
    //TODO - Gestire meglio l'errore
    console.error("Errore durante il recupero del soggetto - msgaAnagrafeBoFindSoggettoByIdIscrizionePost")
    throw await new Error("Errore durante il recupero del soggetto - msgaAnagrafeBoFindSoggettoByIdIscrizionePost");

  }
}

/**
 * GET dei dati del soggetto by idIscrizione backoffice
 * @returns
 * @param idIscrizione
 */
export async function getSoggettoByIdIscrizioneBO(idIscrizione: number) {
  try {
    var param = {
      "idIscrizione": idIscrizione
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoFindSoggettoByIdIscrizionePost(param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    //TODO - Gestire errore
    console.error("Errore durante la chiamata msgaAnagrafeFindSoggettoByIdIscrizionePost - " + err)
    throw new Error("Errore durante la chiamata msgaAnagrafeFindSoggettoByIdIscrizionePost - " + err);
  }
}

/**
 * GET dei dati del soggetto by idIscrizione
 * @returns
 * @param idIscrizione
 */
export async function getSoggettoByIdIscrizioneFO(idIscrizione: number) {
  try {
    var param = {
      "idIscrizione": idIscrizione
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoFindSoggettoByIdIscrizionePost(param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    //TODO - Gestire errore
    console.error("Errore durante la chiamata msgaAnagrafeFindSoggettoByIdIscrizionePost - " + err)
    throw new Error("Errore durante la chiamata msgaAnagrafeFindSoggettoByIdIscrizionePost - " + err);
  }
}

/**
 * POST dei dati dell'iscrizione attiva all'ordine territoriale di una persona fisica attiva del soggetto CF
 * @returns
 * @param cf (nel caso di persona fisica)
 */
export async function getIscrizioneOrdineByCfFO(cf: string) {
  try {
    var param = {
      "cf_piva": cf,
      "tipologiaIscrizione": 1
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetIscrizionePersonaFisicaIscrittaByCodiceFiscalePost(
        param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante il recupero dell'iscrizione all'ordine per " + cf + "" + err);
    throw new Error("Errore durante il recupero dell'iscrizione all'ordine per " + cf + "" + err);
  }
}
/**
 * POST dei dati dell'iscrizione attiva all'ordine territoriale di una persona fisica attiva del soggetto CF - Backoffice
 * @returns
 * @param cf (nel caso di persona fisica)
 */
export async function getIscrizioneOrdineByCfBO(cf: string) {
  try {
    var param = {
      "cf_piva": cf,
      "tipologiaIscrizione": 1
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoGetIscrizionePersonaFisicaIscrittaByCodiceFiscalePost(
        param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante il recupero dell'iscrizione all'ordine per " + cf + "" + err);
    throw new Error("Errore durante il recupero dell'iscrizione all'ordine per " + cf + "" + err);
  }
}

/**
 * POST dei dati dell'iscrizione attiva all'ordine territoriale di una STP attiva per partita IVA del soggetto
 * @returns
 * @param cf (nel caso di persona fisica)
 */
export async function GetIscrizioneStpIscrittaByPartitaIvaPost(piva: string) {
  try {
    var param = {
      "cf_piva": piva,
      "tipologiaIscrizione": 10
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetIscrizioneStpIscrittaByPartitaIvaPost(
        param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeFoGetIscrizioneStpIscrittaByPartitaIvaPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFoGetIscrizioneStpIscrittaByPartitaIvaPost - " + err);
  }
}

/**
 * POST ricerca iscritti all'albo per persone fisiche
 * Non serve passare il token perchè la chiamata è per l'utente non autenticato
 * @param nome
 * @param cognome
 * @param cf
 * @param ordineTerritorialeCompetente
 * @param numeroIscrizioneOrdine
 * @returns
 */
export async function ricercaAlboUnicoPF(nome: string, cognome: string, cf: string, ordineTerritorialeCompetente: string, numeroIscrizioneOrdine: string ) {
  try {
    var param = {
      nome: nome,
      cognome: cognome,
      cf: cf,
      ordineTerritorialeCompetente: ordineTerritorialeCompetente,
      numeroIscrizioneOrdine: numeroIscrizioneOrdine
    }
    let response = await getAnagrafeResourcesApi().msgaAnagrafeRicercaIscrittiAlboPost(param);
    return response;
  } catch (err) {

    console.error("Errore durante la chiamata per la ricerca Albo Unico per persona fisica - " + err);
    throw new Error("Errore durante la chiamata per la ricerca Albo Unico per persona fisica - " + err);
  }
}

/**
 * POST ricerca iscritti all'albo per persone giuridiche
 * Non serve passare il token perchè la chiamata è per l'utente non autenticato
 * @param ragioneSociale
 * @param pIva
 * @param cf
 * @param ordineTerritorialeCompetente
 * @param numeroIscrizioneOrdine
 * @returns
 */
export async function ricercaAlboUnicoPG(ragioneSociale: string, pIva: string, cf: string, ordineTerritorialeCompetente: string, numeroIscrizioneOrdine: string ) {
  try {
    var param = {
        ragioneSociale: ragioneSociale,
        partitaIva: pIva,
        cf: cf,
        ordineTerritorialeCompetente: ordineTerritorialeCompetente,
        numeroIscrizioneOrdine: numeroIscrizioneOrdine
    }
    let response = await getAnagrafeResourcesApi().msgaAnagrafeRicercaIscrizioniStpPost(param);

    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per la ricerca Albo Unico per persona giuridica - " + err);
    throw new Error("Errore durante la chiamata per la ricerca Albo Unico per persona giuridica - " + err);
  }
}

/*
 * POST restituisce piva e codiceFiscaleAzienda di una STP attiva per id del soggetto
 * @param idSoggetto
 * @returns
 */
export async function findSTPByPIva(pIva: string) {
  try {
    var param: StringParam = {
      param: pIva
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFindSTPByPIvaPost(
      param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeFindSTPByPIvaPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFindSTPByPIvaPost - " + err);
  }
}

/*
 * Recupera la lista di federazioni e ordini associati
 * @returns
 */
export async function getListaFederazioniOrdini() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeListaFederazioniOrdiniPost(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeListaFederazioniOrdiniPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeListaFederazioniOrdiniPost - " + err);
  }
}

/*
 * Recupera i dati del soggetto a partire dal codice gruppo
 * @param codicegruppo
 * @returns
 */
export async function getDatiOrdineFederazioneByCodiceGruppo(codicegruppo: string) {
  try {
    var param: StringParam = {
      param: codicegruppo
    }

    const response = await getAnagrafeResourcesApi().msgaAnagrafeGetSoggettoByCodiceGruppoPost(param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeGetSoggettoByCodiceGruppoPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeGetSoggettoByCodiceGruppoPost - " + err);
  }
}

/*
 * Verifica se l'iscrizione è radiata
 * @param idSoggetto: idSoggetto,
 * @param tipologiaIscrizione: tipologiaIscrizione
 * @returns
 */
export async function verificaStatoIscrizioneRadiataFO(idSoggetto: number, tipologiaIscrizione: number) {
  try {
    var param: SoggettoByIdAndTipologiaDTO = {
      idSoggetto: idSoggetto,
      tipologiaIscrizione: tipologiaIscrizione
    }

    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoVerificaStatoIscrizioneRadiataPost(param, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeFoVerificaStatoIscrizioneRadiataPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFoVerificaStatoIscrizioneRadiataPost - " + err);
  }
}

/**
 * GET dei dati del soggetto by CF, tipo anagrafe e tipo iscrizione
 * @returns
 * @param cf
 * @param tipoAnagrafe
 * @param tipologiaIscrizione
 */
export async function getElencoIscrittiCSV(filterCsv: any) {
  try {

    const response = await getAnagrafeResourcesApi().msgaAnagrafeCsvElencoIscrittiBOPost(filterCsv, getDefaultAxiosConfig());
    return response;


  } catch (err) {

    console.error("Errore durante la chiamata getElenccoIscrittiCSV - " + err);
    throw new Error("Errore durante la chiamata getElenccoIscrittiCSV - " + err);
  }
}
