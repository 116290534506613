export default {
    tipo: {
        'isRequired': true
    },
    sdaf: {
        'isRequired': true
    },
    svolto_da: {
        'isRequired': true
    },
    svolto_a: {
        'isRequired': true
    },
    ente_erogante: {
        'isRequired': true
    },
    desc_attivita: {
        'isRequired': true
    },
}
