import * as React from 'react'
import { PageSection } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import { PATH_TO_BO_CONCEDI_DEROGA, PATH_TO_BO_LISTA_ISCRITTI_ORDINE } from 'src/app/utils/RoutesConstants'
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit'
import { createFormData, handleChangeFormData } from 'src/app/utils/form-utils'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericModal from '../../components/GenericModal'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const title = 'Concedi Proroga'
export const TRIENNI_OPTIONS = [
    {label: "2023-2025", value: 674, firstyear: 2023, lastYear: 2025},
    {label: "2020-2022", value: 673, firstyear: 2020, lastYear: 2022},
    {label: "2017-2019", value: 672, firstyear: 2017, lastYear: 2019},
    {label: "2013-2016", value: 671, firstyear: 2013, lastYear: 2016},
    {label: "2010-2012", value: 670, firstyear: 2010, lastYear: 2012},
    {label: "2009", value: 669, firstyear: 2009, lastYear: 2009}]
const rules = {
    triennio: {
      isRequired: true,
    },
    concessa_al: {
        isRequired: true,
        _custom: {
            check: (code, rule, data) => {
              const refYear = new Date(data.concessa_al).getFullYear();
              if(data?.triennio && data.triennio?.lastYear) {
                return data.triennio.lastYear < refYear && refYear < 3000
              }
              return false
            }
          }
    }
}

const Spacer = () => (
    <>
      <br></br>
    </>
  )

const ConcediDeroga: React.FunctionComponent<any> = (props) => {
    const refForm = React.useRef<any>([])
    const [data, setData] = React.useState<any>({})
    const [showModal, setShowModal] = React.useState<any>({open: false, message: "", ok: false})
    const [showConfirm, setShowConfirm] = React.useState<boolean>(false)
    const [errors, setErrors] = React.useState<any>({})
    const formData = createFormData(data, setData, errors, setErrors)

        const onInitInput = (cmp, tab) => {
            refForm.current.push({
                tab: 0,
                cmp: cmp,
            })
        }

        const handleInput = (cod:string, val:string) => {
            handleChangeFormData(formData, cod, val, rules)
            if(cod === "triennio" )
            {
                handleChangeFormData (formData, "concessa_al", null, rules)
            }
        }

        const concediDerogaUtente = async (triennio, data, user) => {
            if(triennio && data) {
                let messaggio = {head: "Errore alla concessione della proroga.", data: []}
                let ok = false
                try {
                    if(user.length === 1)
                    {
                        const result = await getFormazioneResourceApi().msfoFormazioneCreaDerogaPost({cf: user[0], triennio: triennio.value, data_fine_deroga: data} as any, getDefaultAxiosConfig()) as any
                        messaggio.head = "Resoconto proroghe: " + result.data.message
                    } else {

                        const { data:derogheList }: any = await getFormazioneResourceApi().msfoFormazioneCreaDerogaMultiplaPost({cfList: user, triennio: triennio.value, dataFineDeroga: data} as any, getDefaultAxiosConfig())
                        messaggio.head = "Resoconto proroghe: "
                        derogheList.forEach((deroga, indice) => messaggio.data.push((deroga.status === 0 ? user[indice] + ": " : "") + deroga.message + "."));
                        ok = true
                    }
                    ok = true
                } catch (e:any) {
                }
                setShowModal({open: true, message: messaggio, ok: ok})
            }
        }

        const onCloseModal = () => {
            if(showModal.ok) props.history.push(PATH_TO_BO_LISTA_ISCRITTI_ORDINE)
            setShowModal({open: false})
        }


        const searchParams = new URLSearchParams(window.location.search)
        const user = searchParams.get('cf').split(",")
        const breadcrumbOptions = [{ label: "Lista iscritti ordine", link: PATH_TO_BO_LISTA_ISCRITTI_ORDINE }, {label: 'Concedi proroga', link: PATH_TO_BO_CONCEDI_DEROGA}]
        return (
            <>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                        <div><h1>{title}</h1></div>
                        <GenericCard cardHeader={'Concessione proroga'}>
                            <Form>
                                <div className="row">
                                    <div className="col-6">
                                        <GenericSelect
                                        id="triennio"
                                        label="Triennio di destinazione dei CFP"
                                        defaultOptions={TRIENNI_OPTIONS}
                                        value={data.triennio}
                                        onChange={handleInput}
                                        onInit={onInitInput}
                                        errorMessage={errors?.triennio}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <GenericInput
                                        isDisabled={!data.triennio}
                                        id="concessa_al"
                                        type="date"
                                        label="Proroga formativa concessa al"
                                        suffix="*"
                                        value={data.concessa_al}
                                        onChange={handleInput}
                                        onInit={onInitInput}
                                        errorMessage={errors?.concessa_al}
                                        onKeyDown={() => {}}
                                        />
                                    </div>
                                </div>
                            </Form>
                            <Button onClick={() => setShowConfirm(true)} disabled={!(data.triennio && data.concessa_al)} color="primary">Concedi proroga</Button>

                            <GenericModal
                                title={'Confermare operazione'}
                                text={'L\'azione è irreversibile, procedere?'}
                                modalOpen={showConfirm}
                                closeButton={() => {
                                    setShowConfirm(false)
                                }}
                                makeChoice={true}
                                choiceAction={()=>{
                                    concediDerogaUtente(data.triennio, data.concessa_al, user)
                                    setShowConfirm(false)
                                }}
                            />


                            {showModal.open &&
                                <Modal fade centered isOpen={showModal.open} toggle={onCloseModal}>
                                    <ModalHeader style={{fontSize: "25px"}} id="isDerogaConcessa">Concessione proroga</ModalHeader>
                                    <ModalBody>
                                        <p>{showModal.message.head}</p>
                                        <Spacer/>
                                        {showModal.message.data.map((dato) => <div><p>{dato}</p><Spacer/></div>)}
                                    </ModalBody>
                                    <ModalFooter>
                                    <Button color="primary" onClick={onCloseModal}>
                                        OK
                                    </Button>
                                    </ModalFooter>
                                </Modal>
                            }
                        </GenericCard>
                    </div>
                </PageSection>
            </>
        );
};

export default ConcediDeroga;
