import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import useFrontOffice from 'src/hooks/useFrontOffice'
import { Button, PageSection, Title } from '@patternfly/react-core'
import { Label } from 'design-react-kit'
import styled from 'styled-components'

import {
  PATH_TO_BO_DETTAGLIO_RIVISTA,
  PATH_TO_BO_ELENCO_RIVISTE,
  PATH_TO_FO_DETTAGLIO_RIVISTA,
  PATH_TO_FO_ELENCO_RIVISTE,
  RO_GEST_FORM_CONAF
} from 'src/app/utils/RoutesConstants'
import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import formValidationRules from 'src/backoffice/app/pages/formazione/formRiviste/FormRules'
import { requestMapperAgenzia, responseMapperAgenzia } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import { DETAILS_ACTIONS, STATI_AGENZIE_OPTIONS, STATI_AGENZIE_VALUES } from 'src/formazione'
import GenericWizard from 'src/backoffice/app/components/GenericWizard'

// Form components
import FormDatiSedeLegale from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiSedeLegale'
import FormDatiRappresentanteLegale
  from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiRappresentanteLegale'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'

// Form azioni
import FormIntegrazione from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormIntegrazione'
import FormApprovazioneFormale from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneFormale'
import FormApprovazioneMerito from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneMerito'
import FormApprovazioneMinisteriale
  from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneMinisteriale'
import FormRigetto from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormRigetto'

import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'

import FormDatiComitatoTecnico from './formRiviste/FormDatiComitatoTecnico'
import FormDatiAccreditamento from './formRiviste/FormDatiAccreditamento'
import FormDatiDichiarazioni from './formRiviste/FormDatiDichiarazioni'
import FormDatiAgenzia from './formRiviste/FormDatiAgenzia'
import FormDatiAllegati from './formRiviste/FormDatiAllegati'
import GenericCard from 'src/frontend/app/components/GenericCard'
import { GenericSpinner } from '../../components'
import { ottieniAllegato } from './formEntiEsterni/formUtils'
import GenericInput from 'src/frontend/app/components/Form/Input'
import moment from 'moment'
import { FlexDiv } from './DettaglioRichiestaEsonero'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`
const Spacer = () => (
  <>
    <br></br>
  </>
)

const DettaglioRivista: React.FunctionComponent = (props) => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_RIVISTE : PATH_TO_BO_ELENCO_RIVISTE
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_RIVISTA : PATH_TO_BO_DETTAGLIO_RIVISTA
  const basePath = { label: 'Elenco Riviste', link: pathToElenco }
  const myPath = {
    label: 'Dettaglio Rivista',
    link: pathToDettaglio,
  }

  const MyDiv = FlexDiv

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [stato, setStato] = useState<any>()
  const modalita = searchParams.get('modalita')
  const viewOnly = modalita !== DETAILS_ACTIONS.MODIFICA

  const [dataFineRinnovo, setDataFineRinnovo] = useState('')
  const [dataFineRinnovoErrors, setdataFineRinnovoErrors] = useState('')

  // Submit state
  const [editStatus, setEditStatus] = useState<any>()
  const [editMessage, setEditMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  useEffect(() => {
    const doRequest = async (id) => {
      const { data: resp } = await getFormazioneResourceApi().msfoFormazioneDettaglioAccAgenziaFormativaGet(id, getDefaultAxiosConfig())
      const obj = await responseMapperAgenzia(resp)

      setData(obj)
      setStato(resp?.datiAccreditamento?.stato)
    }
    doRequest(id)
  }, [id])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({ tab, cmp })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onBack = () => {
    history.replace(pathToElenco)
  }

  const isValid = useCallback(
    (toIndex) => {
      for (let index = 0; index <= toIndex; index++) {
        const inputs = getTabActiveFormId(refForm, index)
        const isValid = validateFormData(formData, formValidationRules, inputs)
        if (!isValid) return false
      }
      return true
    },
    [formData]
  )

  const onSubmit = useCallback(
    async (e) => {
      try {
        // TODO change to include allegati
        setdataFineRinnovoErrors('')

        if (
          stato !== STATI_AGENZIE_VALUES.ACCREDITATA ||
          (stato === STATI_AGENZIE_VALUES.ACCREDITATA && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])) ||
          (stato === STATI_AGENZIE_VALUES.ACCREDITATA && dataFineRinnovo !== '' && !TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF]))
        ) {
          if (!isValid(5)) {
            setEditStatus(STATUS.FAILURE)
            setEditMessage('Controllare i dati inseriti')
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            return null
          }
          setEditStatus(STATUS.LOADING)

          formData.data.tipoAgForm = 2

          const _data = await requestMapperAgenzia(formData.data)
          _data.datiAccreditamento.id = id
          _data.datiAccreditamento.tipo_ag_form = 2
          if (!(TokenStore.getInstance().isCurrentAccountCONAF() && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF]))) {
            _data.datiAccreditamento.stato = 1
          }
          // TODO REMOVE
          if (
            stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
            !(TokenStore.getInstance().isCurrentAccountCONAF() && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF]))
          ) {
            _data.datiAccreditamento.data_rinnovo = moment().format('YYYY-MM-DD')
            _data.datiAccreditamento.data_fine = dataFineRinnovo
          }
          delete _data.allegati

          await getFormazioneResourceApi().msfoFormazioneAggiornaAgenziaFormativaPost(_data, getDefaultAxiosConfig())

          setEditStatus(STATUS.SUCCESS)
          setEditMessage('Dati salvati con successo')
          setTimeout(() => {
            onBack()
          }, 500)
        } else {
          setdataFineRinnovoErrors('Dato mancante')
        }
      } catch (e) {
        setEditStatus(STATUS.FAILURE)
        setEditMessage('Impossibile salvare i dati')
      }
      return null
    },
    [formData, id]
  )

  const forms = [
    <FormDatiAgenzia
      title="Dati Anagrafici"
      data={data}
      errors={errors}
      onInitInput={onInitInput(0)}
      handleInput={handleInput}
      viewOnly={viewOnly}
      isModifica={!viewOnly}
    />,
    <FormDatiSedeLegale title="Sede Legale" data={data} errors={errors} onInitInput={onInitInput(1)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiRappresentanteLegale
      title="Rappresentante Legale"
      data={data}
      errors={errors}
      onInitInput={onInitInput(2)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormDatiAccreditamento
      title="Dati Accreditamento"
      data={data}
      errors={errors}
      onInitInput={onInitInput(3)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormDatiComitatoTecnico
      title="Dati comitato tecnico"
      data={data}
      errors={errors}
      onInitInput={onInitInput(4)}
      handleInput={handleInput}
      viewOnly={viewOnly}
    />,
    <FormDatiDichiarazioni title="Dichiarazioni" data={data} errors={errors} onInitInput={onInitInput(5)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiAllegati title="Allegati" data={data} errors={errors} onInitInput={onInitInput(6)} handleInput={handleInput} viewOnly={viewOnly} />,
  ]
  const steps = [
    {
      id: 0,
      name: 'Dati Anagrafici',
      component: forms[0],
    },
    {
      id: 1,
      name: 'Sede Legale',
      component: forms[1],
    },
    {
      id: 2,
      name: 'Rappresentante Legale',
      component: forms[2],
    },
    {
      id: 3,
      name: 'Dati Accreditamento',
      component: forms[3],
    },
    {
      id: 4,
      name: 'Dati comitato tecnico',
      component: forms[4],
    },
    {
      id: 5,
      name: 'Dichiarazioni',
      component: forms[5],
    },
    {
      id: 6,
      name: 'Allegati',
      component: forms[6],
    },
  ]

  if (!data || !data.datiAccreditamento) {
    return (
      <>
        <GenericSpinner size="lg" />
        Download in corso...
      </>
    )
  }

  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>

        <MyDiv>
          {data && data.logoAllegato && data.logoAllegato.idAllegato && stato === STATI_AGENZIE_VALUES.ACCREDITATA && (
            <Button
              variant="primary"
              onClick={() => {
                let idAllegato: string | null = data.logoAllegato.idAllegato

                if (idAllegato) {
                  ottieniAllegato(idAllegato)
                } else {
                  console.error('IDALLEGATO MISSING')
                }
              }}
            >
              Scarica logo
            </Button>
          )}

          {<p>Stato : {STATI_AGENZIE_OPTIONS.find((opt) => opt.value === stato)?.label}</p>}
        </MyDiv>

        <Spacer />

        {modalita === DETAILS_ACTIONS.RICHIESTA_INTEG && <FormIntegrazione id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_FORMALE && <FormApprovazioneFormale id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_MERITO && <FormApprovazioneMerito id={id} tipo={2} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_MINISTERIALE && <FormApprovazioneMinisteriale id={id} />}
        {modalita === DETAILS_ACTIONS.RIGETTA && <FormRigetto id={id} stato={stato} tipo={2} />}
        {modalita === DETAILS_ACTIONS.MODIFICA ? (
          <>
            {stato === STATI_AGENZIE_VALUES.INTEGRAZIONE && (
              <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
                <Title headingLevel="h3">{data.datiAccreditamento.integrazione}</Title>
              </GenericCard>
            )}
            {stato === STATI_AGENZIE_VALUES.ACCREDITATA && !TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF]) ? (
              <FormSubmit title="Conferma rinnovo" buttonLabel="Rinnova" alertMessage={editMessage} status={editStatus} onSubmit={onSubmit}>
                <Label>Per rinnovare l'evento cliccare "Rinnova".</Label>
                <GenericInput
                  id="data_fine_accr"
                  type="date"
                  label="Data di fine accreditamento"
                  suffix="**"
                  value={dataFineRinnovo}
                  onChange={(cod, val) => {
                    setDataFineRinnovo(val)
                  }}
                  errorMessage={dataFineRinnovoErrors}
                />
              </FormSubmit>
            ) : (
              <FormSubmit title="Conferma modifica" buttonLabel="Modifica" alertMessage={editMessage} status={editStatus} onSubmit={onSubmit}>
                <Label>Per modificare la rivista cliccare "Modifica".</Label>
              </FormSubmit>
            )}
            {forms}
            <LinkStyled>
              <Link to={pathToElenco}>Torna alla lista</Link>
            </LinkStyled>
          </>
        ) : (
          <GenericWizard
            steps={steps}
            backButtonText={'PRECEDENTE'}
            nextButtonText={'SUCCESSIVO'}
            cancelButtonText={'Torna alla lista'}
            onClickNext={null}
            onClickCancel={onBack}
            height="auto"
          />
        )}
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </PageSection>
  )
}

export default DettaglioRivista
