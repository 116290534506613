import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getBigIntToConvertToBoolean } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDatiAccessoProfessione extends React.Component<GeneralFormDatiAccessoProfessioneProps, any> {
  render() {
    const { messaggio, iscrizione, soggetto } = this.props
    return (
      <>
        {(iscrizione === '' || soggetto === '') && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idOrdineTerritoriale'}
                    label={'Ordine territoriale'}
                    value={soggetto.denominazioneGruppo}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idSezioneRichiesta'}
                    label={'Sezione richiesta'}
                    value={this.props.sezione}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idTitoloRichiesto'}
                    label={'Titolo richiesto'}
                    value={this.props.titoloRichiesto}
                    isReadOnly
                  />
                </div>
              </div>
              {/*-------------------------------- Campi visibili in alternativa a Decreto riconoscimento Titolo Estero ---------------------------*/}
              <div className="row" hidden={getBigIntToConvertToBoolean(iscrizione.flagAbilitazioneConDecretoEstero)}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idTipoLaurea'} label={'Tipo di laurea'} value={this.props.tipoLaurea} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idClasseLaurea'}
                    label={'Classe di laurea'}
                    value={this.props.classeLaurea}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row" hidden={getBigIntToConvertToBoolean(iscrizione.flagAbilitazioneConDecretoEstero)}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCorsoLaurea'}
                    label={'Corso di laurea'}
                    value={iscrizione.corsoLaureaSpecificato}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idAnnoLaurea'} label={'Anno di laurea'} value={iscrizione.annoDiLaurea} isReadOnly />
                </div>
              </div>
              <div className="row" hidden={getBigIntToConvertToBoolean(iscrizione.flagAbilitazioneConDecretoEstero)}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idUniversita'} label={'Università'} value={iscrizione.universitaLaurea} isReadOnly />
                </div>
              </div>
              {/*---------------------------------------------------------------------------------------------------------------------------------*/}
              {/*-------------------------------------- Campo visibile in alternativa ai dati sulla laurea ---------------------------------------*/}
              <div className="row" hidden={!getBigIntToConvertToBoolean(iscrizione.flagAbilitazioneConDecretoEstero)}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataRiconoscimentoTitoloEstero'}
                    label={'Data riconoscimento titolo estero'}
                    value={iscrizione.dataAbilitazioneDecretoEstero}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              {/*---------------------------------------------------------------------------------------------------------------------------------*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idTipoAbilitazione'}
                    label={'Tipologia di abilitazione'}
                    value={this.props.tipologiaAbilitazioneEstesa}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idAnnoAbilitazione'}
                    label={'Anno di abilitazione'}
                    value={iscrizione.annoAbilitazione}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idAteneoAbilitazione'}
                    label={'Ateneo di abilitazione'}
                    value={iscrizione.ateneoAbilitazione}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idDecretoRiconoscimentoQualifica'}
                    label={'Decreto di riconoscimento della qualifica professionale'}
                    value={iscrizione.decretoRiconoscimentoQualificaProfessionale}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataRiconoscimentoQualifica'}
                    label={'Data di riconoscimento della qualifica professionale'}
                    value={iscrizione.dataRiconoscimentoQualificaProfessionale}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiAccessoProfessioneProps {
  iscrizione?: any
  messaggio?: any
  titoloRichiesto?: any
  tipoLaurea?: any
  classeLaurea?: any
  sezione?: any
  soggetto?: any
  tipologiaAbilitazioneEstesa?: any
}
