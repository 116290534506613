import * as React from 'react'
import { Form } from 'design-react-kit'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericSelect from 'src/frontend/app/components/GenericSelect'
import { getFederazioni } from 'src/processes/Ordini'
import { compilaIscrizioneOOT } from 'src/processes/ProcessoIscrizione'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getSoggettoByCfAndTipoAnagrafeFO } from 'src/processes/Soggetto'
import { inputRequiredValidation, isEmail } from 'src/utilities/utility'

export class FormIscrizioneOperatoreFederazione extends React.Component<GeneralFormIscrizioneFederazioneProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            listaOrdini: []
        };
    }

    componentDidMount() {
        //chiamata al servizio che restituisce tutti gli ordini
        getFederazioni().then((response: any) => {
            var ordiniTerritoriali = [{ label: '', value: '' }];
            if (response) response.data.map((ordine, id) => {

                ordiniTerritoriali[id] = {
                    label: ordine['denominazioneGruppo'],
                    value: ordine['denominazioneGruppo'],
                };
            })
            this.setState({ listaOrdini: ordiniTerritoriali })
        })
    }

    onChangeFederazione = (event) => {
        this.setState({ ordineTerritoriale: event.value })
    }

    handleSubmit = async event => {
         //setto a true il flag submitted
         this.props.submitted(true)
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var requiredFields = [
            { value: this.state.ordineTerritoriale, label: 'Federazione' },
            { value: isEmail(event.target.idEmailOperatoreOrdineTerritoriale.value) ? event.target.idEmailOperatoreOrdineTerritoriale.value : '', label: 'Email di contatto' }
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired) {
            event.preventDefault()
            //setto a false il flag submitted
            this.props.submitted(false)
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*------------------------------------------------------------------------------------------------------------------------------*/
        else {
            event.preventDefault()
            var parametriCompilazioneIscrizioneOOT = {
                ordineTerritoriale: this.state.ordineTerritoriale ? this.state.ordineTerritoriale : '',
                emailOperatoreOrdineTerritoriale: event.target.idEmailOperatoreOrdineTerritoriale.value ? event.target.idEmailOperatoreOrdineTerritoriale.value : ''
            }

            const token = TokenStore.getInstance().getDecodedTokenJWT();
            var cf = token.fiscalNumber
            var tipoAnagrafe = 'PF'

            //Chiamata al servizio 'getSoggettoByCfAndTipoAnagrafeFO' per recuperare l'idSoggetto
            getSoggettoByCfAndTipoAnagrafeFO(cf, tipoAnagrafe).then((response: any) => {
                //Compilazione Iscrizione OOT
                compilaIscrizioneOOT(response.data.idSoggetto, parametriCompilazioneIscrizioneOOT, "FODAF").then((response) => {
                    if (response.status === 200)
                    {
                        this.props.submitted(true)
                        return this.props.messaggio({ messaggio: "Iscrizione avvenuta con successo", type: "success" })
                    }
                }).catch(() => {
                    this.props.submitted(false)
                    return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
                })
            }).catch(() => {
                this.props.submitted(false)
                return this.props.messaggio({ messaggio: "C'è un errore stato durante il recupero dei dati", type: "danger" })
            })
        }
    }

    render() {
        const { listaOrdini } = this.state
        return (
            <div>
                <Form id={'idIscrizioneOperatoreOrdine'} onSubmit={this.handleSubmit}>
                    <div className='row'>
                        <div className='col-6'>
                            <GenericSelect elementId={'idOrdineTerritoriale'} placeholder={"Selezionare la federazione"} label={"Federazione*"} defaultOptions={listaOrdini} onChange={e => this.onChangeFederazione(e)} isSearchable isRequired />
                        </div>
                        <div className='col-6'>
                            <GenericInput type={'email'} id={'idEmailOperatoreOrdineTerritoriale'} placeholder={"Email di contatto"} label={"Email di contatto*"} isRequired />
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export declare interface GeneralFormIscrizioneFederazioneProps {
    messaggio?: any;
    submitted?: any;
}
