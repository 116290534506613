import * as React from 'react'
import { Form } from '@patternfly/react-core'
import GenericTextInput from '../../../components/GenericTextInput'
import GenericSelect from '../../../components/GenericSelect'
import { cancellaIscrittoOrdine } from 'src/processes/ProcessoIscrizione'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { inputRequiredValidation } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormCancellaIscritto extends React.Component<GeneralFormCancellaIscrittoProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      messaggio: '',
      type: '',
    }
  }

  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [
      { value: event.target.idDeliberaCancellazione.value, label: 'Delibera' },
      { value: event.target.idDataDelibera.value, label: 'Data delibera' },
      { value: event.target.idDataCancellazione.value, label: 'Data cancellazione' },
      { value: event.target.idMotivoCancellazione.value, label: 'Motivo cancellazione' },
    ]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*------------------------------------------------------------------------------------------------------------------------------*/
      //chiamata al servizio valutaIscrizione per cancellazione iscritto
      var stato = 'cancellato'
      var idIscrizione = this.props.idIscrizione
      var parametriCancellazione = {
        deliberaCancellazione: event.target.idDeliberaCancellazione.value ? event.target.idDeliberaCancellazione.value : '',
        dataDeliberaCancellazione: event.target.idDataDelibera.value ? event.target.idDataDelibera.value : null,
        dataCancellazione: event.target.idDataCancellazione.value ? event.target.idDataCancellazione.value : null,
        motivoCancellazione: event.target.idMotivoCancellazione.value ? event.target.idMotivoCancellazione.value : '',
      }
      try {
        event.preventDefault()
        await cancellaIscrittoOrdine(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber, idIscrizione, stato, parametriCancellazione)
        return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
      } catch (err: any) {
        return this.props.messaggio({ messaggio: err.message, type: 'danger' })
      }
    }
  }

  render() {
    const { motiviCancellazione } = this.props
    return (
      <div>
        <Form id="idCancellaIscritto" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput type={'text'} className="w-75" label={'Delibera'} id={'idDeliberaCancellazione'} placeholder={'Inserire delibera'} isRequired />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataDelibera'}
                label={'Data delibera'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericTextInput
                type={'date'}
                className="w-75"
                id={'idDataCancellazione'}
                label={'Data cancellazione'}
                placeholder={'Selezionare data'}
                style={{ paddingRight: '25px' }}
                isRequired
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericSelect className="w-75" id={'idMotivoCancellazione'} label={'Motivo cancellazione'} list={motiviCancellazione} isRequired />
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormCancellaIscrittoProps {
  messaggio?: any
  idIscrizione?: any
  motiviCancellazione?: any
}
