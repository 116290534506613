import { useHistory } from 'react-router-dom'
import moment from 'moment'

import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Button } from 'design-react-kit'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { GenericSpinner } from 'src/backoffice/app/components'
import { FormTopbar } from 'src/frontend/app/components/Theme'
import GenericCard from 'src/frontend/app/components/GenericCard'
import FormPOF from './FormPOF'
import PofStatus from './FormPOF/PofStatus'
import SavingFormLoader from 'src/frontend/app/components/SavingFormLoader'

import {
  caricaDatiPianoUtente,
  createEmptyPof,
  getNomePianoFormativo,
  rimuoviIdsFromPof,
  salvaPianoInformativo,
  STATI
} from 'src/model/PianiFormativi'

import {
  PATH_TO_FO_ELENCO_POF,
  PATH_TO_FO_ISCRIZIONE_POF,
  RO_CONS_FORM_FODAF,
  RO_CONS_FORM_ODAF,
  RO_GEST_FORM_FODAF,
  RO_GEST_FORM_ODAF
} from 'src/app/utils/RoutesConstants'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { PageSection, Title } from '@patternfly/react-core'

const IscrizionePOF: React.FunctionComponent = () => {
  const history = useHistory()

  const [data, setData] = useState<any>(null)

  // Tab component state
  const [message, setMessage] = useState('')

  // Submit state
  const [saving, setSaving] = useState(false)
  const [stepSaving, setStepSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [haveSaved, setHaveSaved] = useState<boolean>(false)

  useEffect(() => {
    // Crea un nuovo piano informativo da uno precedente
    const cloneData = async (id) => {
      try {
        setLoading(true)
        const pofData = await caricaDatiPianoUtente(id)
        if (pofData === null) {
          throw new Error('pof not found')
        }
        const pof = rimuoviIdsFromPof(pofData)
        pof.nr_invio = parseInt(pof.nr_invio) + 1
        delete pof.id
        for (const pform of pof.pFormativo2016List) {
          delete pform.id
        }
        const saved = await salvaPianoInformativo(pof, STATI.NON_COMPLETA)
        setData(saved)
        setStepSaving(false)
        setLoading(false)
        history.push(PATH_TO_FO_ISCRIZIONE_POF + '?id=' + saved.id)
      } catch (e) {
        history.push(PATH_TO_FO_ELENCO_POF)
        setLoading(true)
      }
    }

    // Carica dati piano formativo
    const loadData = async (id) => {
      try {
        setLoading(true)
        const pofData = await caricaDatiPianoUtente(id)
        if (pofData === null) {
          throw new Error('pof not found')
        }
        setData(pofData)
        setLoading(false)
      } catch (e) {
        history.push(PATH_TO_FO_ELENCO_POF)
      }
    }
    const searchParams = new URLSearchParams(window.location.search)
    // Carica dati pof già esistente
    const id = searchParams.get('id')
    if (id) {
      loadData(id)
      return
    }
    // crea pof clonando i dati da un'altro
    const fromId = searchParams.get('from')
    if (fromId) {
      cloneData(fromId)
      return
    }
    // crea pof anno passato come parametro
    const year = parseInt(searchParams.get('year') || '')
    if (year) {
      // Inizializza pof con anno corrente se 'year' non appartiene al range
      if (isNaN(year) || year < 2000 || year-1 + '' > moment().format('YYYY')) {
        history.push(PATH_TO_FO_ELENCO_POF)
      } else {
        setData(createEmptyPof(year))
      }
    }
  }, [history])

  const setErrorMessage = (message) => {
    setMessage(message)
    setTimeout(() => {
      setMessage('')
    }, 2000)
  }

  const onNextStep = useCallback(
    async (tab, haveChange) => {
      if (!haveChange) {
        return
      }
      try {
        setStepSaving(true)
        const saved = await salvaPianoInformativo(data, data.stato)
        setData(saved)
      } catch (e) {
        setErrorMessage('Impossibile salvare i dati')
      }
      setStepSaving(false)
    },
    [data]
  )

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        setSaving(true)
        if (TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_FODAF, RO_CONS_FORM_FODAF, RO_GEST_FORM_ODAF, RO_CONS_FORM_ODAF])) {
          await salvaPianoInformativo(data, STATI.ACCETTATA)
        } else {
          await salvaPianoInformativo(data, STATI.PRESENTATA)
        }
        setSaving(false)
        setHaveSaved(true)
      } catch (e) {
        setSaving(false)
        setMessage('Impossibile salvare i dati')
      }
      return null
    },
    [data]
  )

  return (
    <div className="container">
      <GenericBreadCrumb
        paths={[{ label: 'Formazione', link: '/' }, { label: 'Piano formativi', link: PATH_TO_FO_ELENCO_POF }, { label: 'Nuovo piano formativo' }]}
      ></GenericBreadCrumb>
      <FormTopbar>
        <h1>Piano Formativo {data && data.anno && `anno ${data.anno}`}</h1>
        <div className={'actions'}>
          <Button
            onClick={(e) => {
              e.preventDefault()
              onNextStep(0, true)
            }}
          >
            Salva
          </Button>
          <Button onClick={() => history.push(PATH_TO_FO_ELENCO_POF)}>Torna alla lista</Button>
        </div>
      </FormTopbar>

      {loading && <GenericSpinner />}
      {message && <Alert color={'danger'}>{message}</Alert>}
      {haveSaved && (
        <div style={{ padding: 30 }}>
          <Alert color={'success'}>L'iscrizione è stata inviata!</Alert>
        </div>
      )}
      {data != null && data.id && !haveSaved && (
        <GenericCard cardHeader={getNomePianoFormativo(data)}>
          <PofStatus pof={data} />
        </GenericCard>
      )}
      <div className={'position-relative pof-form'}>
        {data != null && !haveSaved && (
          <FormPOF
            saving={saving}
            data={data}
            setData={setData}
            onNextStep={onNextStep}
            setErrorMessage={setErrorMessage}
            onSubmit={onSubmit}
            isDisabled={false}
          />
        )}
        {stepSaving && <SavingFormLoader />}
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </div>
  )
}

export default IscrizionePOF
