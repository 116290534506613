import React from 'react'
import { Alert, Label } from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import GenericUpload from 'src/frontend/app/components/Form/Upload'
import { MOTIVAZIONI_ESONERO } from 'src/model/Esoneri'
import styled from 'styled-components'
import { isInvalidAllegato } from '../formRichiestaEsonero/FormRules'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDevice = isCurrentMobileDevice()

export const UploadRowStyled = styled.div`
  display: flex;
  margin: 50px 0;
  .uploader-label {
    flex: 3;
  }
  .uploader-files {
    flex: 5;
  }
`
const FormRichiestaEsonero: React.FunctionComponent<FormRichiestaEsonero> = ({ title, data, handleInput, onInitInput, errors, isDisabled, currentStep }) => {
  const listError = errors || {}

  return (
    <GenericCard cardHeader={title} className={'form-richiesta-esonero'}>
      <div className="row">
        <div className="col-12">
          <GenericSelect
            isDisabled={isDisabled}
            id="motivazione"
            label="Motivazione *"
            value={data.motivazione}
            defaultValue="---"
            //Info sui value delle label in ..\Esoneri.tsx
            defaultOptions={MOTIVAZIONI_ESONERO}
            onChange={handleInput}
            onInit={onInitInput}
            errorMessage={listError.motivazione}
          />
        </div>
      </div>
      <div className="row">
        <div className={!isMobileDevice ? 'col-6' : 'col-12'}>
          <GenericInput
            id="data_inizio"
            type={'date'}
            placeholder={'Data di inizio esonero'}
            label={'Data inizio esonero *'}
            value={data.data_inizio}
            onChange={handleInput}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError.data_inizio}
          />
        </div>
        <div className={!isMobileDevice ? 'col-6' : 'col-12'}>
          <GenericInput
            id="data_fine"
            type={'date'}
            placeholder={'Data di fine esonero'}
            label={'Data fine esonero *'}
            value={data.data_fine}
            onChange={handleInput}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError.data_fine}
          />
        </div>
      </div>
      {/* <div className="row">
        <div>
          <Label for="allegati">
            <strong>Documentazione richiesta esonero**</strong>
          </Label>
          <GenericUploadFront
            id="allegato"
            isDisabled={isDisabled}
            tipologiaAllegato={'documentazione_allegata_richiesta_esonero'}
            acceptedExtensionFile={['pdf']}
            onChange={(v) => handleInput('allegato', v)}
            onInit={onInitInput}
            currentStep={currentStep}
            isRequired
            value={data.allegato}
            limit={10}
          />
        </div>
      </div> */}
      <UploadRowStyled className={!isMobileDevice ? '' : 'row'}>
        <div className="uploader-label">
          <Label for={'allegato'}>
            {data.motivazione?.value !== '11' ? <strong>Documentazione richiesta esonero *</strong> : <strong>Documentazione richiesta esonero</strong>}
          </Label>
        </div>

        <div className="uploader-files">
          <GenericUpload
            isDisabled={isDisabled}
            isMulti={true}
            id={'allegato'}
            tipologiaAllegato={'documentazione_allegata_richiesta_esonero'}
            extensions={['pdf']}
            accept={['application/pdf']}
            data={data}
            onChange={handleInput}
          />
        </div>
      </UploadRowStyled>
      {isInvalidAllegato(data.allegato, data.motivazione) && <Alert color="info">Inserire tutti gli allegati prima di procedere</Alert>}
      <GenericInput type={'hidden'} id={'check_uploads'} onInit={onInitInput} />
    </GenericCard>
  )
}

interface FormRichiestaEsonero {
  data: any
  errors?: any
  title: string
  handleInput?: any
  onInitInput?: any
  isDisabled?: boolean
  currentStep?: number
}

export default FormRichiestaEsonero
