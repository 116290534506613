import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import { FormRigettaIscrizione } from './formIscrizione/FormRigettaIscrizione'
import { getSoggettoByIdIscrizione } from 'src/processes/Soggetto'
import {
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE
} from 'src/app/utils/RoutesConstants'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { convertUrlParamsToObject } from 'src/utilities/utility'

class RigettaIscrizione extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      typeMessage: "default",
      idIscrizione: ""
    };
  }

  goToElencoIscritti = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    });
  }

  componentDidMount() {
    //recupero gli eventuali parametri dall'url
    var url = window.location.search?.split("?")[1]
    var urlParams = convertUrlParamsToObject(url)

    //recupero l'idIscrizione dall'url
    var idIscrizione = urlParams?.idIscrizione

    this.setState({ idIscrizione: idIscrizione })

    //chiamata al servizio 'getSoggettoByIdIscrizione' che restituisce i dati del soggetto a partire dall'idIscrizione
    getSoggettoByIdIscrizione(idIscrizione).then((response: any) => {
      this.setState({ idSoggetto: response.data.idSoggetto })
    })
  }

  submitMessageResult = (alert: any) => {
    const element = document.getElementById("pageSectionId");
    if (element)
      element.scrollIntoView({ behavior: 'smooth' });
    this.setState({ message: alert.messaggio, typeMessage: alert.type })
    if (alert.type === 'success') {
      setTimeout(async () => {
        this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
      }, 1000);
    }
  }

  render() {
    const { message, typeMessage, idIscrizione, idSoggetto } = this.state
    return (
      <>
        <PageSection id='pageSectionId'>
          <div className='container register-container'>
            <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI }, { label: "Rigetta iscrizione", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE }]} /><br></br>
            <Title headingLevel="h1" size="4xl">Rigetto della domanda di iscrizione</Title><br></br>
            <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
            <GenericCard isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormRigettaIscrizione messaggio={this.submitMessageResult} idIscrizione={idIscrizione} idSoggetto={idSoggetto} /></>} /><br></br>
            <div className='row'>
              <div className='col-1'>
                <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)} />
              </div>
              <div className='col-1'>
                <GenericButton label={"CONFERMA"} form={"idRigettaIscrizione"} type={"submit"} color={"primary"} isDisabled={this.state.typeMessage === 'success'} />
              </div>
            </div>
          </div>
        </PageSection>
        <br></br>
        <PageSection>
          <div className='container register-container'>
            <Title headingLevel='h3'>Legenda</Title>
            <p>* Campo obbligatorio</p>
          </div>
        </PageSection>
      </>
    );
  }
};

export default RigettaIscrizione;
