import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { objSubset } from 'src/app/utils/form-utils'
import moment from 'moment/moment'
import { generateRequestFilter } from '../app/utils/ApiUtils'
import { LABEL_ENTI } from './EnteFormazione'


const REQUEST_HEAD_FIELDS = [
  'nr_iscr_destinatari_pf',
  'analisi_fabb_formativi',
  'strategia_obiettivi',
  'organiz_e_prior_eventi',
  'budget_prev_bilancio',
  'vis_delibera',
  'si_impegna_non_sup_costi_standard',
  'si_impegna_rendere_evidenti_costi_standard',
  'budget_prev_bilancio',
  'nr_prot_ordine',
  'data_prot_ordine',
  'delibera_prot_ordine',
  'anno',
  'stato',
  'data_creazione',
  'nr_invio',
  'integr_descr_gen_pform'
]


export const LABEL_STATI = {
  0: 'In compilazione',
  1: 'In valutazione',
  2: 'Approvato',
  3: 'Rigettato',
  4: 'Richiesta integrazioni',
  98: 'Segnalato',
}

export const STATI = {
  NON_COMPLETA: 0,
  PRESENTATA: 1,
  ACCETTATA: 2,
  RIFIUTATA: 3,
  RICHIESTA_INTEGRAZIONI: 4,
  SEGNALATA_ALT: 5,
  SEGNALATA: 98,
}


export const createEmptyPof = (anno: any) => {
  return {
    anno: anno,
    nr_iscr_destinatari_pf: 0,
    stato: STATI.NON_COMPLETA,
    nr_invio: 1,
    delibera_prot_ordine: '',
    analisi_fabb_formativi: '',
    strategia_obiettivi: '',
    organiz_e_prior_eventi: '',
    budget_prev_bilancio: 0,
    integr_descr_gen_pform: '',
    previCostiAttFormativaList: [],
    pFormativo2016List: []
  }
}


export const getPrevCostiAttività = async () => {
  const { data: attivita } = (await getFormazioneResourceApi().msfoFormazioneTipologieAttivitaGet())
  return attivita
}


export const getFiltersYear = (min) => {
  const today = new Date();
  let year = today.getFullYear();
  const november15 = new Date(year, 10, 15); // Mese 10 rappresenta novembre (0-based index)

  if (today >= november15) {  // dal 15 novembre si potrà creare il POF dell'anno seguente
    year += 1;
  }
  const years = [year];
  while (--year >= min) {
    years.push(year);
  }
  return years;
}

export const convertPofDataToForm = async (data) => {
  const cfpRows = await getPrevCostiAttività()
  const { data: piani } = (await getFormazioneResourceApi().msfoFormazioneSettoriDisciplinariGet())
  const ccfp = data.pFormativo2016List?.map((cp) => {
    const piano = piani.find(p => p.id === cp['id_settore'])
    return {
      ...cp,
      titolo: piano ? piano.titolo : ''
    }
  })
  return {
    ...data,
    ...(data.headPFormativo || {}),
    cfp: cfpRows,
    ccfp: ccfp
  }
}


export const calcolateTotalActivity = (cfp) => {
  if (isNaN(cfp.tot_cfp)) return 0
  if (isNaN(cfp.nr_pratecip_prev)) return 0
  return cfp.tot_cfp * cfp.nr_pratecip_prev * cfp.costo_unit_standard
}


export const aggiornaDatiHeadPiano = async (id, data) => {
  const sendData: any = {
    ...data,
    id: id,
    data_modifica: moment().format('YYYY-MM-DD HH:mm:ss')
  }
  await getFormazioneResourceApi().msfoFormazioneHeadPformativoPatch(sendData)
}

/**
 * Salva il nuovo piano
 * @param data
 */
export const salvaPianoInformativo = async (data, stato) => {
  const now: string = moment().format('YYYY-MM-DD HH:mm:ss')
  const nowdate: string = moment().format('YYYY-MM-DD')
  data['stato'] = stato
  if (!data.id || !data['data_creazione']) {
    data['data_creazione'] = nowdate
  }

  let totCuf = 0
  const boolToInt = (data, fields) => {
    for (const f of fields) {
      data[f] = data[f] ? 1 : 0
    }
    return data
  }

  const addIdRef = (stateData, savedData) => {
    stateData.id = savedData.id
    const cfps = stateData.previCostiAttFormativaList || []
    const ccfps = stateData.pFormativo2016List || []
    // Aggiungo id head
    stateData.previCostiAttFormativaList = cfps.map(d => {
      return {
        ...d,
        id_pformativihead: savedData.id
      }
    })
    stateData.pFormativo2016List = ccfps.map(d => {
      const sp = savedData.pFormativo2016List.find((p => p.id_settore + '' === d.id_settore + ''))
      return {
        ...d,
        id: sp.id || undefined,
        id_pformativihead: savedData.id
      }
    })
    return stateData
  }

  const cfps = data.previCostiAttFormativaList || []
  const ccfps = data.pFormativo2016List || []

  /* PREPARA DATI DA INVIARE */
  const sendData: any = {
    headPFormativo: objSubset(data, REQUEST_HEAD_FIELDS),
    previCostiAttFormativaList: cfps.map(cfp => {
      totCuf += parseFloat(cfp.tot_cfp)
      return {
        tot_cfp: cfp.tot_cfp,
        cod_attivita: cfp.cod_attivita,
        costo_unit_standard: cfp.costo_unit_standard,
        nr_pratecip_prev: cfp.nr_pratecip_prev,
        costo_totale_ta: calcolateTotalActivity(cfp),
        anno_pf: data.anno,
        id_pformativihead: data.id || ''
      }
    }),
    pFormativo2016List: ccfps.map(ccfp => {
      return {
        id: ccfp.id || undefined,
        id_pformativihead: data.id || '',
        id_settore: ccfp.id_settore,
        tot_cfp: ccfp.tot_cfp,
        stato_rich: stato,
        nr_invio: 1,
        anno_pf: data.anno
      }
    })
  }


  sendData.headPFormativo['stato'] = stato
  sendData.headPFormativo['id'] = data.id || undefined
  sendData.headPFormativo['data_modifica'] = now
  sendData.headPFormativo['cus_tot_ta'] = totCuf
  sendData.headPFormativo = boolToInt(sendData.headPFormativo, [
    'si_impegna_non_sup_costi_standard',
    'si_impegna_rendere_evidenti_costi_standard',
    'vis_delibera'
  ])
  if (stato === STATI.PRESENTATA) {
    sendData.headPFormativo['data_di_presentazione'] = moment().format("YYYY-MM-DD")
  }

  if (data.id) {
    const { data: updatePlan } = await getFormazioneResourceApi().msfoFormazioneAggiornaPianoFormativoPatch(sendData, getDefaultAxiosConfig())
    return addIdRef(data, updatePlan)
  } else {
    const { data: newPlan } = await getFormazioneResourceApi().msfoFormazioneInserisciPianoFormativoPost(sendData, getDefaultAxiosConfig())
    return addIdRef(data, newPlan)
  }
}


export const caricaDatiPianoUtente = async (id) => {
  const { data: { items } } = await getFormazioneResourceApi().msfoFormazioneListaPformativiForUserGet(
    generateRequestFilter([{ field: 'id', value: id }]),
    100,
    -1,
    undefined,
    getDefaultAxiosConfig()
  )
  if (items === undefined || items?.length === 0) {
    return null
  }
  return items[0]
}


/**
 * Rimuove tutti gli id dall'oggetto
 * @param data
 */
export const rimuoviIdsFromPof = (data) => {
  data.id = ''
  data.stato = STATI.NON_COMPLETA
  data.pFormativo2016List = data.pFormativo2016List.map(p => {
    p.id = ''
    p.id_pformativihead = ''
    return p
  })
  data.previCostiAttFormativaList = data.previCostiAttFormativaList.map(p => {
    p.id_pformativihead = ''
    return p
  })
  return data
}


export const getNomeEnteFormazione = (piano: any): string => {

  if (piano && piano.enteFormazione) {
    return (piano.enteFormazione.tipo_ag_form === 3 ? 'Ente Esterno ' : LABEL_ENTI[piano.enteFormazione.tipo_ente])+" "+piano.enteFormazione.name;
  }
  return ''
}

export const getNomePianoFormativo = (data: any) => {
  const variante = data.nr_invio > 1 ? `- Variante ${data.nr_invio - 1}` : ''
  return `Piano formativo anno ${data.anno} ${variante} - ${getNomeEnteFormazione(data)}`
}
