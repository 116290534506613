import { Header, HeaderBrand, HeaderContent, HeaderRightZone, HeaderSocialsZone, Icon } from 'design-react-kit'
import React from 'react'
import conafLogo from '../images/conaf-logo.png'
import { getHome, goTo } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const facebook = 'https://www.facebook.com/agronomiforestali/?locale=it_IT'
const instagram = 'https://instagram.com/ordine_agronomi_e_forestali?igshid=MzRlODBiNWFlZA=='
const twitter = 'https://twitter.com/_conaf'
const mailto = 'ufficioprotocollo@conaf.it'

class GenericHeaderCenter extends React.Component<{}, { isMobile: boolean }> {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth <= 1000,
    }
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth <= 1000 })
  }

  render() {
    const { isMobile } = this.state
    return (
      <>
        {!isCurrentMobileDevice() && !isMobile && (
          <div className="header">
            <Header theme="" type="center">
              <HeaderContent>
                <HeaderBrand>
                  <img
                    onClick={() => {
                      goTo(getHome())
                    }}
                    src={conafLogo}
                    alt="conaf-logo"
                  />
                  <div
                    onClick={() => {
                      goTo(getHome())
                    }}
                    className="ml"
                  >
                    <h1>
                      <b>SISTEMA INFORMATIVO</b>
                    </h1>
                    <h3>
                      <b>DEI DOTTORI AGRONOMI E DEI DOTTORI FORESTALI</b>
                    </h3>
                  </div>
                </HeaderBrand>
                <HeaderRightZone>
                  <div>
                    <div className="d-flex">
                      <HeaderSocialsZone label="Seguici su">
                        <ul>
                          <li>
                            <a aria-label="Facebook" href={facebook} target="_blank" rel="noreferrer">
                              <Icon icon="it-facebook" />
                            </a>
                          </li>
                          <li>
                            <a aria-label="Twitter" href={twitter} target="_blank" rel="noreferrer">
                              <Icon icon="it-twitter" />
                            </a>
                          </li>
                          <li>
                            <a aria-label="Instagram" href={instagram} target="_blank" rel="noreferrer">
                              <Icon icon="it-instagram" />
                            </a>
                          </li>
                          <li>
                            <a aria-label="Mail" href={'mailto:' + mailto} target="_blank" rel="noreferrer">
                              <Icon icon="it-mail" />
                            </a>
                          </li>
                        </ul>
                      </HeaderSocialsZone>
                    </div>
                  </div>
                </HeaderRightZone>
              </HeaderContent>
            </Header>
          </div>
        )}
      </>
    )
  }
}

export default GenericHeaderCenter
