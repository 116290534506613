import React from 'react'
import { useHistory } from 'react-router-dom'
import { PageSection, Title } from '@patternfly/react-core'
import { useSdaf, useTipologieAttivita } from 'src/hooks/useReqOptions'
import useFrontOffice from 'src/hooks/useFrontOffice'
import { FILTER_OPERATORS, generateRequestFilter, generateRequestSort } from 'src/app/utils/ApiUtils'
import { GenericAsyncTableData, RequestData } from 'src/backoffice/app/components/GenericAsyncTableData'
import {
  PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE,
  PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI,
  PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE,
  PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI,
  RO_GEST_FORM_CONAF,
  RO_GEST_FORM_FODAF,
  RO_GEST_FORM_ODAF
} from 'src/app/utils/RoutesConstants'
import {
  DETAILS_ACTIONS,
  STATI_EVENTO_NON_A_CATALOGO,
  STATI_EVENTO_NON_A_CATALOGO_OPTIONS,
  STATI_EVENTO_NON_A_CATALOGO_VALUES
} from 'src/formazione'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'

import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'

const Spacer = () => (
  <>
    <br></br>
  </>
)

const basePath = { label: 'Home', link: '/' }
const ElencoEventiCatalogo: React.FunctionComponent = () => {
  const history = useHistory()
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI : PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE : PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE
  const myPath = {
    label: 'Elenco Eventi non a Catalogo o Individuali',
    link: pathToElenco,
  }
  const isOdafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_ODAF])
  const isConafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])
  const isFodafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_FODAF])



  const sdaf = useSdaf()
  const attivita = useTipologieAttivita()

  const loadData = async (request: RequestData) => {
    let customFunc = 'msfoFormazioneListaEventiNonACatalogoForUserGet'
    if (!isFrontOffice) customFunc = 'msfoFormazioneListaEventiNonACatalogoGet'

    const { data } = await getFormazioneResourceApi()[customFunc](
      generateRequestFilter(request.filters),
      request.pageSize,
      request.currentPage,
      generateRequestSort(request.sortColumn, request.sortDirection),
      getDefaultAxiosConfig()
    )

    return {
      items: data.items || [],
      total: data.count || 0,
    }
  }

  const onDetails = (row, modalita) => (e) => {
    history.push({
      pathname: pathToDettaglio,
      search: `?id=${row.id}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }

let filters =[
  { label: 'Titolo', field: 'nome_corso', type: 'input', operator: FILTER_OPERATORS.LIKE },
  { label: 'SDAF', field: 'settore', type: 'select', options: sdaf.map((s) => ({ label: s.titolo, value: s.id })) },
  { label: 'Tipologia', field: 'tipo_corso', type: 'select', options: attivita.map((a) => ({ label: a.titolo, value: a.id })) },
  { label: 'CFP Richiesti', field: 'cpf_richiesti', type: 'input', operator: FILTER_OPERATORS.LIKE },
  { label: 'Stato', field: 'stato', type: 'select',  options: STATI_EVENTO_NON_A_CATALOGO_OPTIONS  },
]

  if(isOdafGest || isConafGest || isFodafGest)
    {  filters = [
                        {label: 'Cognome', field: 'soggetto.cognome', type: 'input', operator: FILTER_OPERATORS.LIKE },
                        {label: 'Nome', field: 'soggetto.nome', type: 'input', operator: FILTER_OPERATORS.LIKE },
                        {label: 'Codice Fiscale', field: 'soggetto.cf', type: 'input', operator: FILTER_OPERATORS.LIKE },
                        ...filters,
    ]
  }


  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />
        <GenericAsyncTableData
          tableKey={'lista.nocatalogo'}
          emptyMessage={'Nessun evento non a catalogo o individuale trovato'}
          filters={filters}
          columns={[
            (isOdafGest || isConafGest || isFodafGest) &&
              {label: 'Cognome', field: 'soggetto.cognome', isSortable: true, render: (row) => row.soggetto?.cognome},
            (isOdafGest || isConafGest || isFodafGest) &&
              {label: 'Nome', field: 'soggetto.nome', isSortable: true, render: (row) => row.soggetto?.nome},
            (isOdafGest || isConafGest || isFodafGest) &&
              {label: 'Codice Fiscale', field: 'soggetto.cf', isSortable: true, render: (row) => row.soggetto?.cf },

            { label: 'Titolo', field: 'nome_corso', isSortable: true },
            { label: 'SDAF', field: 'settore', isSortable: true, render: (row) => sdaf.find((s) => s.id == row.settore)?.value },
            { label: 'Tipologia', field: 'tipo_corso', isSortable: true, render: (row) => attivita.find((a) => a.id == row.tipo_corso)?.titolo },
            { label: 'CFP Richiesti', field: 'cpf_richiesti', isSortable: true },
            { label: 'Stato', field: 'stato', isSortable: true, render: (row) => STATI_EVENTO_NON_A_CATALOGO[row.stato] },
          ]}
          getRows={loadData}
          getRowActions={(row) => {
            const { stato } = row || {}
            let azioni: any = [{ label: 'Dettaglio', onClick: onDetails(row, DETAILS_ACTIONS.DETTAGLIO) }]

            const showApprova = isOdafGest && stato === STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA
            const showRigetta = isOdafGest && stato === STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA
            const showIntegrazione = isOdafGest && stato === STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA
            let showModifica =
              isOdafGest &&
              ![
                STATI_EVENTO_NON_A_CATALOGO_VALUES.ACCREDITATO,
                STATI_EVENTO_NON_A_CATALOGO_VALUES.RIFIUTATO,
                STATI_EVENTO_NON_A_CATALOGO_VALUES.ANNULLATO,
                STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA,
              ].includes(stato)

            /* const showModifica =
              (isOrganizzatore) &&
              ![STATI_EVENTO_CATALOGO_VALUES.ACCREDITATO, STATI_EVENTO_CATALOGO_VALUES.RIFIUTATO, STATI_EVENTO_CATALOGO_VALUES.ANNULLATO, STATI_EVENTO_CATALOGO_VALUES.RICHIESTA].includes(stato) */

            if (!isFrontOffice) {
              azioni = [
                ...azioni,
                showApprova && { label: 'Approva', onClick: onDetails(row, DETAILS_ACTIONS.APPROVA) },
                showRigetta && { label: 'Rigetta', onClick: onDetails(row, DETAILS_ACTIONS.RIGETTA) },
                showIntegrazione && { label: 'Richiedi integrazioni', onClick: onDetails(row, DETAILS_ACTIONS.RICHIESTA_INTEG) },
              ]
            } else {
              if (stato === STATI_EVENTO_NON_A_CATALOGO_VALUES.RICHIESTA_INTEG) {
                showModifica = true
              }

              azioni = [...azioni, showModifica && { label: 'Modifica', onClick: onDetails(row, DETAILS_ACTIONS.MODIFICA) }]
            }
            return azioni?.filter((a) => a)
          }}
        />
      </div>
    </PageSection>
  )
}

export default ElencoEventiCatalogo
