
export const isInvalidAllegato = (allegato:any) => {
  if (!allegato) return true
  if (Array.isArray(allegato)) return allegato?.filter((v) => v)?.length === 0
  return !allegato?.allegatoDTO?.contentBase64
}

export default {
    titolo: {
        'isRequired': true
    },
    nome_cognome: {
        'isRequired': true
    },
    // allegato: {
    //     'isRequired': true,
    //     'isNotEmpty': true
    // }
    check_uploads: {
        _custom: {
          check: (code, rule, data) => {
            return !isInvalidAllegato(data?.allegato)
          },
        },
    }
  }