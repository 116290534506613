import { PageSection, Tooltip } from '@patternfly/react-core'
import * as React from 'react'
import '../../../style/style.scss'
import GenericBreadCrumb from '../../components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericInput from '../../components/GenericInput'
import { PATH_TO_FO_ANAG_CAMBIO_SEZIONE } from 'src/app/utils/RoutesConstants'
import GenericSelect from '../../components/GenericSelect'
import GenericUploadFront from '../../components/GenericUpload'
import GenericCheckbox from '../../components/GenericCheckbox'
import GenericButton from '../../components/GenericButton'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import {
  getCambioSezioneResourceApi,
  getDefaultAxiosConfig,
  getIscrizioneOrdineResourceApi
} from 'src/app/utils/ManagerRestGateway'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import GenericAlert from '../../components/GenericAlert'
import { getClasseLaureaByCodiceFO, getTipoLaureaByCodice, getTitoloByCodiceFO } from 'src/processes/Laurea'
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon'
import { getDatiQuotaIscrizioneByOrdine } from 'src/processes/ProcessoIscrizione'
import GenericTableCompact from '../../components/GenericTableCompact'

class RichiestaCambioSezione extends React.Component<any, any>{
    constructor(props) {
        super(props)
        this.state = {
            messageQuotaMancante: undefined,
            okBollo: false,
            okTassa: false,
            okQuota: false,
            errorNuova: false,
            message: "",
            flagRichiestaInviata: false,
            typeMessage: "",
            isTitoloEstero: false,
            isLoaded: false,
            checkComp: false,
            checkAutoc: false,
            lauree: [],
            inCorso: false,
            idSoggetto: 0,
            laurea: {
                idLaurea: -1,
                idTipologia: -1,
                anno: -1,
                universita: "",
                corsoLaurea: ""
            },
            abilitazione: {
                idTitolo: -1,
                anno: -1,
                ateneo: "",
                decretoRiconoscimentoQualProf: "",
                dataRiconoscimento: null
            },
            titoloEstero: {
                fileName: '',
                descrizione: "",
                base64Body: "",
                codice: ''
            },
            tipoPagamentoTassa: {
                idTipologia: 2,
                allegato: {
                    codice: "tassa_iot",
                    fileName: "",
                    descrizione: "",
                    contentType: "",
                    base64Body: ""
                },
            },
            tipoPagamentoBollo: {
                idTipologia: 1,
                allegato: {
                    codice: "bollo_iot",
                    fileName: "",
                    descrizione: "",
                    contentType: "",
                    base64Body: ""
                },
            },
            tipoPagamentoQuota: {
                idTipologia: 3,
                allegato: {
                    codice: "quota_iscrizione_iot",
                    fileName: "",
                    descrizione: "",
                    contentType: "",
                    base64Body: ""
                },
            },
            allegato: {
                codice: '',
                fileName: '',
                contentType: '',
                descrizione: "",
                base64Body: ""
            },
        };
    };

    uploadError = (e) => {
        if (e.hasError) {


            if (e.id === 'titoloEstero') {
                this.setState({ titoloEstero: { ...this.state.titoloEstero, base64Body: '', fileName: '' } });

            }
            else if (e.id === 'allegato') {
                this.setState({ allegato: { ...this.state.allegato, base64Body: '', fileName: '' } });
            }
            else if (e.id === 'bollo' && this.state.okBollo) {

                this.setState({
                    tipoPagamentoBollo: {
                        idTipologia: this.state.tipoPagamentoBollo.idTipologia,
                        allegato: { codice: this.state.tipoPagamentoBollo.allegato.codice, base64Body: '', fileName: '', contentType: '', descrizione: '' }
                    },
                    okBollo: false
                })
            }
            else if (e.id === 'tassa' && this.state.okTassa) {
                this.setState({
                    tipoPagamentoTassa: {
                        ...this.state.tipoPagamentoTassa,
                        allegato: { ...this.state.tipoPagamentoTassa.allegato, base64Body: '', fileName: '', contentType: '', descrizione: '' }
                    },
                    okTassa: false
                })
            }
            else if (e.id === 'quota' && this.state.okQuota) {
                this.setState({
                    tipoPagamentoQuota: {
                        ...this.state.tipoPagamentoQuota,
                        allegato: { ...this.state.tipoPagamentoQuota.allegato, base64Body: '', fileName: '', contentType: '', descrizione: '' }
                    },
                    okQuota: false
                })
            }
            else return;
        };

    };

    checkL(param: any): any {
        const { isTitoloEstero } = this.state;

        if (isTitoloEstero) {
            if (param.corsoLaurea === '' || param.corsoLaurea === null || param.corsoLaurea === undefined) {
                return { v1: false, v2: 'File titolo estero' }
            }
            if (this.state.dataAbilitazioneDecretoEstero === '' || this.state.dataAbilitazioneDecretoEstero === null || this.state.dataAbilitazioneDecretoEstero === undefined) {
                return { v1: false, v2: 'Data riconoscimento titolo estero' }
            }
        }

        if (param.idLaurea === -1) {
            return { v1: false, v2: 'Tipo laurea' }
        }
        if (param.idTipologia === -1)
            return { v1: false, v2: 'Classe di laurea' }
        if (param.corsoLaurea === '' || param.corsoLaurea === null || param.corsoLaurea === undefined)
            return { v1: false, v2: 'Corso di laurea' }
        if (param.anno === '' || param.anno === null || param.anno === undefined)
            return { v1: false, v2: 'Anno di laurea' }
        if (param.universita === '' || param.universita === null || param.universita === undefined)
            return { v1: false, v2: 'Universita\'' }

        return { v1: true, v2: '' }
    }

    checkForm(param: any): any {

        const { isTitoloEstero, tipoPagamentoTassa, tipoPagamentoBollo, tipoPagamentoQuota } = this.state;
        var mustTrue: any;

        mustTrue = {
            L: !isTitoloEstero ? param.laurea : param.titoloEstero,
            A: param.abilitazione,
            flag1: param.flagAutocertificazione,
            flag2: param.flagComprensione
        }

        if (!isTitoloEstero) {
            let l = this.getLaurea(this.state.tipoLaurea)
            if (!l.value)
                return { v1: false, v2: 'Tipo di laurea non consentito per la sezione A' }

            if (!this.getClass(l).value) {
                return { v1: false, v2: 'Classe di laurea non consentita per la sezione A' }

            }
        }
        for (const i in mustTrue) {
            if (i === 'L') {
                let a = this.checkL(mustTrue[i]);
                if (!a.v1)
                    return a;
            }
            else if (i === 'A') {
                if (!(!!mustTrue[i].anno))
                    return { v1: false, v2: 'Anno abilitazione' };
                else if (!(!!mustTrue[i].ateneo))
                    return { v1: false, v2: 'Ateneo abilitazione' };

            }
            else if (!mustTrue.flag1)
                return { v1: false, v2: 'Autocertificazione' }

            else if (!mustTrue.flag2)
                return { v1: false, v2: 'Adempimenti degli iscritti' }
        }

        if (!tipoPagamentoBollo.allegato.base64Body)
            return { v1: false, v2: 'Bollo' }
        else if (!tipoPagamentoTassa.allegato.base64Body)
            return { v1: false, v2: 'Tassa' }
        else if (!tipoPagamentoQuota.allegato.base64Body)
            return { v1: false, v2: 'Quota di iscrizione' }

        return { v1: true, v2: '' }
    }

    async handleSubmit(e: any) {
        e.preventDefault();
        const { laurea, isTitoloEstero, checkAutoc, checkComp,
            abilitazione, titoloEstero, tipoPagamentoQuota, tipoPagamentoTassa,
            tipoPagamentoBollo, allegato,
            allegatoPag, idIscrizione, idSoggetto } = this.state;

        const param = {
            idSoggetto: idSoggetto,
            idIscrizioneTerritoriale: idIscrizione,
            cambioSezioneDA: 'B',
            cambioSezioneA: 'A',
            laurea: isTitoloEstero ? null : laurea,
            abilitazione: abilitazione,
            titoloEstero: isTitoloEstero ? titoloEstero : null,
            tipoPagamentoTassa: tipoPagamentoTassa,
            tipoPagamentoBollo: tipoPagamentoBollo,
            tipoPagamentoQuota: tipoPagamentoQuota,
            allegato: allegato.base64Body === "" ? null : allegato,
            flagAutocertificazione: checkAutoc,
            flagComprensione: checkComp,
            idTipologiaAbilitazione: abilitazione.idTitolo,
            dataAbilitazioneDecretoEstero: this.state.dataAbilitazioneDecretoEstero
        }

        const val = this.checkForm(param);
        if (!val.v1) {
            this.setState({ message: "Campi obbligatori mancanti: " + val.v2, typeMessage: 'danger' })
        }
        else {
            this.setState({ flagRichiestaInviata: true })
            getCambioSezioneResourceApi().mswfCambioSezioneSalvaPost(param, getDefaultAxiosConfig()).then((response) => {
                if (response.status === 200) {
                    this.setState({ message: "Richiesta inviata con successo", typeMessage: 'success' })
                    setTimeout(async () => { window.location.reload() }, 1000);
                }
            }
            ).catch(
                reason => {
                    this.setState({ message: "Errore durante l'invio delle richiesta " + reason.message, typeMessage: 'danger' })
                    this.setState({ flagRichiestaInviata: false })
                }
            );
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    };

    handleCheck(e) {
        const { checkComp, checkAutoc } = this.state;
        if (e === 'comp')
            this.setState({ checkComp: !checkComp });
        if (e === 'auto')
            this.setState({ checkAutoc: !checkAutoc });
    }

    handleFile = (e, id) => {

        if (e.descrizioneBreve === 'titoloEstero') {
            this.setState({ titoloEstero: { ...this.state.titoloEstero, base64Body: e.contentBase64, contentType: e.contentType, fileName: e.filename, codice: 'decreto_titolo_estero_iot' } });


        }
        else if (e.descrizioneBreve === 'pagamento') {
            if (id === 'bollo') {
                this.setState({
                    tipoPagamentoBollo: { ...this.state.tipoPagamentoBollo, allegato: { ...this.state.tipoPagamentoBollo.allegato, base64Body: e.contentBase64, fileName: e.filename, contentType: e.contentType } },
                    okBollo: true
                });
            }
            else if (id === 'tassa') {
                this.setState({
                    tipoPagamentoTassa: { ...this.state.tipoPagamentoTassa, allegato: { ...this.state.tipoPagamentoTassa.allegato, base64Body: e.contentBase64, fileName: e.filename, contentType: e.contentType } },
                    okTassa: true
                });
            }
            else {
                this.setState({
                    tipoPagamentoQuota: { ...this.state.tipoPagamentoQuota, allegato: { ...this.state.tipoPagamentoQuota.allegato, base64Body: e.contentBase64, fileName: e.filename, contentType: e.contentType } },
                    okQuota: true
                });
            }
        }
        else if (e.descrizioneBreve === 'allegato') {
            this.setState({ allegato: { ...this.state.allegato, base64Body: e.contentBase64, fileName: e.filename, contentType: e.contentType, codice: 'allegato_iot' } });
        }
    }

    handleChange(e: any, type: string, value: string) {
        const { tipoLaureaDTOS } = this.state.lauree;
        const { laurea, abilitazione } = this.state;



        if (type === 'Laurea') {
            if (value === 'tipolaurea') {
                let lauree = [{ label: '', value: '' }];

                let listaLauree = tipoLaureaDTOS.find(l => l.idTipoLaurea == e.value).listaLaureaDTOS;

                listaLauree.map((laurea, id) => {
                    lauree[id] = {
                        label: laurea.descrizione,
                        value: laurea.idLaurea
                    }
                });
                this.setState({ classeLaurea: lauree });
                this.setState({ laurea: { ...laurea, idLaurea: e.value } });


            }
            else if (value === 'classeLaurea') {
                this.setState({ laurea: { ...laurea, idTipologia: e.value } });


            }
            else if (value === 'corso') {
                this.setState({ laurea: { ...laurea, corsoLaurea: e.target.value } });
            }
            else if (value === 'anno') {
                this.setState({ laurea: { ...laurea, anno: e.target.value } });
            }
            else if (value === 'universita')
                this.setState({ laurea: { ...laurea, universita: e.target.value } });
        }

        else if (type === 'Abilitazione') {

            if (value === 'tipoAbilitazione') {
                this.setState({ abilitazione: { ...abilitazione, idTitolo: e.value } });

            }

            else if (value === 'annoAbilitazione') {
                this.setState({ abilitazione: { ...abilitazione, anno: e.target.value } });

            }
            else if (value === 'ateneo') {
                this.setState({ abilitazione: { ...abilitazione, ateneo: e.target.value } });

            }
            else if (value === 'decretoRiconoscimento') {
                this.setState({ abilitazione: { ...abilitazione, decretoRiconoscimentoQualProf: e.target.value } });

            }
            else if (value === 'dataRiconoscimento')
                this.setState({ abilitazione: { ...abilitazione, dataRiconoscimento: e.target.value ? e.target.value : null } });
        }

        else if (type === 'documenti') {
            if (value === 'tipoEstero') {

                this.setState({ titoloEstero: { ...this.state.titoloEstero, codice: e.value } })
            }
            if (value === 'dataEstero')
                this.setState({ dataAbilitazioneDecretoEstero: e.target.value ? e.target.value : null });

            if (value === 'allegato')
                this.setState({ allegato: { ...this.state.allegato, descrizione: e.target.value } });

            if (value === 'pagamento') {
                let x = this.state.tipoPagamenti[e.value - 1].codice;
                this.setState({ tipoPagamento: { idTipologia: e.value }, allegatoPag: { ...this.state.allegatoPag, descrizione: e.label, codice: x } });
            }
        }
    }

    async componentDidMount() {
        this.setState({ isLoaded: false });

        var laur: any;
        var idLaurea = '';

        // Elenco classi di laurea
        await getCambioSezioneResourceApi().mswfCambioSezioneTipologieLaureePost(getDefaultAxiosConfig()).then(
            response => {
                let tipolauree = [{ label: '', value: '' }];
                this.setState({ lauree: response.data });
                laur = response.data;

                response.data.tipoLaureaDTOS?.map((l, id) => {
                    tipolauree[id] = {
                        label: !!l.tipoLaurea ? l.tipoLaurea : '',
                        value: !!l.idTipoLaurea ? l.idTipoLaurea + '' : ''
                    }
                });
                this.setState({ tipoLaurea: tipolauree });
            }
        ).catch(
        );

        try {
            this.setState({ errorNuova: false })
            this.setState({ inCorso: false })
            const { data } = await getCambioSezioneResourceApi().mswfCambioSezioneNuovoPost({ "codiceFiscale": TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber }, getDefaultAxiosConfig())
            if (data.httpStatus === 200 && data.returnedObject) {
                if (data?.returnedObject?.iscrizioneOrdineInfoDTO) {

                    var codiceTipologiaAbilitazione = data.returnedObject.iscrizioneOrdineInfoDTO?.codiceTitoloRichiesto
                    if (codiceTipologiaAbilitazione) {
                        
                        // Chiamata al servizio getTitoloByCodiceFO per recuperare la tipologia di abilitazione inserita in fase di iscrizione
                        getTitoloByCodiceFO(codiceTipologiaAbilitazione).then((response) => {
                            var tipologiaAbilitazioneFromIot = [{ label: response?.data?.titoloEsteso, value: response?.data?.idTitolo }]
                            this.setState({ tipologiaAbilitazioneFromIot })
                        })
                    }

                    var codiceTipoLaurea = data.returnedObject.iscrizioneOrdineInfoDTO?.codiceTipoLaurea
                    if (codiceTipoLaurea) {
                        
                        // Chiamata al servizio getTipoLaureaByCodice per recuperare la tipologia di laurea inserita in fase di iscrizione
                        getTipoLaureaByCodice(codiceTipoLaurea).then((response) => {
                            var tipoLaureaFromIot = [{ value: response?.data?.idTipoLaurea, label: response?.data?.tipoLaurea }]
                            this.setState({ tipoLaureaFromIot })
                        })
                    }

                    var codiceClasseLaurea = data.returnedObject.iscrizioneOrdineInfoDTO?.codiceClasseLaurea
                    if (codiceClasseLaurea) {
                        
                        // Chiamata al servizio getTipoLaureaByCodice per recuperare la classe di laurea inserita in fase di iscrizione
                        await getClasseLaureaByCodiceFO(codiceClasseLaurea).then((response) => {
                            var classeLaureaFromIot = [{ value: response?.data?.idLaurea, label: response?.data?.descrizione }]
                            this.setState({ classeLaureaFromIot })
                        })
                    }

                    var ordineTerritoriale = data.returnedObject.iscrizioneOrdineInfoDTO?.ordineTerritorialeCompetente
                    if (ordineTerritoriale) {
                        await getDatiQuotaIscrizioneByOrdine(ordineTerritoriale).then((response) => {
                            if (response && response.data && response.data.httpStatus === 200) {
                                var quote: any = []
                                response.data.returnedObject.map((item, id) => {
                                    quote[id] = {
                                        anno: item.anno,
                                        categoria: item.categoria,
                                        quota: item.quotaAssociativa
                                    };
                                })
                                this.setState({ datiQuotaIscrizione: quote })
                            }

                            else {
                                this.setState({ messageQuotaMancante: response.data.error, datiQuotaIscrizione: [] })
                            }
                        })

                    }

                    idLaurea = data.returnedObject.iscrizioneOrdineInfoDTO.codiceTipoLaurea + "";
                    this.setState({
                        codiceFiscale: data.returnedObject.codiceFiscale,
                        idIscrizione: data.returnedObject.idIscrizione,
                        idSoggetto: data.returnedObject.idSoggetto,
                        codiceSezione: data.returnedObject.iscrizioneOrdineInfoDTO.codiceSezioneRichiesta,

                        laurea: {
                            idLaurea: data.returnedObject.iscrizioneOrdineInfoDTO.codiceTipoLaurea + "",
                            idTipologia: data.returnedObject.iscrizioneOrdineInfoDTO.codiceClasseLaurea,
                            anno: data.returnedObject.iscrizioneOrdineInfoDTO.annoDiLaurea,
                            universita: data.returnedObject.iscrizioneOrdineInfoDTO.universitaLaurea,
                            corsoLaurea: data.returnedObject.iscrizioneOrdineInfoDTO.corsoLaureaSpecificato
                        },
                        abilitazione: {
                            idTitolo: data.returnedObject.iscrizioneOrdineInfoDTO.codiceTitoloRichiesto,
                            anno: data.returnedObject.iscrizioneOrdineInfoDTO.annoAbilitazione,
                            ateneo: data.returnedObject.iscrizioneOrdineInfoDTO.ateneoAbilitazione,
                            decretoRiconoscimentoQualProf: data.returnedObject.iscrizioneOrdineInfoDTO.decretoRiconoscimentoQualificaProfessionale,
                            dataRiconoscimento: data.returnedObject.iscrizioneOrdineInfoDTO.dataRiconoscimentoQualificaProfessionale
                        },
                        titoloEstero: {
                            fileName: '',
                            descrizione: "",
                            base64Body: "",
                            codice: ''
                        },

                    })
                }
            }

            else if (data.httpStatus === 409) {
                return this.setState({ inCorso: true })
            }

            else {
                throw new Error("Iscrizione non trovata o non valida")
            }

        } catch (e) {
            this.setState({ errorNuova: true })
            this.setState({ message: "C'è stato un errore durante il recupero dei dati: " + e, typeMessage: 'danger' })
        }

        // Elenco tipologia abilitazione
        await getIscrizioneOrdineResourceApi().msgaIotElencoTitoliGet(getDefaultAxiosConfig()).then(
            response => {
                let tit = [{ label: '', value: 0 }];
                // Filtra i titoli per escludere quelli con "sezione": "B"
                const filteredData = response.data.filter(titolo => titolo.sezione !== 'B');
                
                this.setState({ titoli: filteredData });
        
                filteredData.map((titolo, id) => {
                    tit[id] = {
                        label: !!titolo.titoloEsteso ? titolo.titoloEsteso : '',
                        value: !!titolo.idTitolo ? titolo.idTitolo : 0
                    }
                });
        
                this.setState({ selectTitolo: tit });
            }
        ).catch(reason => {
            console.error('Errore durante il recupero dei titoli:', reason);
        
            this.setState({ 
                error: 'Si è verificato un errore durante il caricamento dei dati. Riprova più tardi.',
                titoli: [],
                selectTitolo: [{ label: 'Errore', value: 0 }]
            });
        
            alert('Errore nel caricamento dei dati. Verifica la connessione e riprova.');
        });        

        
        // Elenco tipologia pagamenti
        await getIscrizioneOrdineResourceApi().msgaIotFoElencoTipologiePagamentiPost({ param: 'iot' }, getDefaultAxiosConfig()).then(
            response => {
                this.setState({ tipoPagamenti: response.data });
                let pag = [{ label: '', value: 0 }];

                response.data.map((pagamento, id) => {
                    pag[id] = {
                        label: !!pagamento.descrizione ? pagamento.descrizione : '',
                        value: !!pagamento.id ? pagamento.id : 0
                    };
                })
                this.setState({ selectPagamento: pag });

            }
        ).catch(
            reason => {
            }
        );

        let cf = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;

        let lauree = [{ label: '', value: '' }];
        let lista = laur?.tipoLaureaDTOS.find(l => l.idTipoLaurea == idLaurea)
        let listaLauree = lista ? lista.listaLaureaDTOS : [];

        listaLauree.map((laurea, id) => {
            lauree[id] = {
                label: laurea.descrizione,
                value: laurea.idLaurea
            }
        });
        this.setState({ classeLaurea: lauree });

        this.setState({ isLoaded: true })
    }

    getLaurea(tipoLaurea) {
        let x = tipoLaurea.find(v => v.value == this.state.laurea.idLaurea)
        if (!x) {
            return { label: this.state.nomeOldL, value: '' }
        }
        return x
    }

    getClass(lau) {

        const { tipoLaureaDTOS } = this.state.lauree;
        if (!lau.value) {
            return { label: this.state.nomeOldC, value: '' }
        };

        let x = tipoLaureaDTOS.find(i => i.idTipoLaurea == lau.value).listaLaureaDTOS.find(v => v.idLaurea == this.state.laurea.idTipologia)

        return x ? { label: x.descrizione, value: x.idLaurea + '' } : { label: '', value: '' }
    }

    render(): React.ReactNode {
        const { isTitoloEstero, errorNuova, isLoaded, selectTitolo, tipoLaurea, laurea, messageQuotaMancante, message, typeMessage, datiQuotaIscrizione, tipologiaAbilitazioneFromIot, tipoLaureaFromIot, classeLaureaFromIot } = this.state;
        let lau = {}
        let cls = {}
        if (laurea.idLaurea > 0) {
            lau = this.getLaurea(tipoLaurea)
            cls = this.getClass(lau)
        }

        if (this.state.inCorso) {
            return (
                <PageSection>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={[
                            { label: "Anagrafica", link: "/" },
                            { label: "Cambio sezione", link: PATH_TO_FO_ANAG_CAMBIO_SEZIONE },
                        ]} />
                        <h1>Cambio sezione</h1>
                        <h2>Richiesta in attesa di approvazione</h2>
                    </div>
                </PageSection>
            )
        }
        else {
            return (
                isLoaded ? (
                    this.state.codiceSezione === 1 ? (
                        <PageSection>
                            <div className='container register-container'>
                                <GenericBreadCrumb paths={[
                                    { label: "Anagrafica", link: "/" },
                                    { label: "Cambio sezione", link: PATH_TO_FO_ANAG_CAMBIO_SEZIONE },
                                ]} />
                                <h1>Cambio sezione</h1>
                                <h2>L'utente appartiene già alla sezione A</h2>
                            </div>
                        </PageSection>
                    ) : (errorNuova === false ? (
                        <>
                            <PageSection>
                                <div className='container register-container'>
                                    <GenericBreadCrumb paths={[
                                        { label: "Anagrafica", link: "/" },
                                        { label: "Cambio sezione", link: PATH_TO_FO_ANAG_CAMBIO_SEZIONE },
                                    ]} />
                                    <h1>Cambio sezione</h1>
                                    <GenericAlert label={message} hidden={message === ""} color={typeMessage}></GenericAlert>
                                    <div>
                                        <GenericCard key={'datiCambioSezione'} cardHeader="Compila i seguenti campi" cardBody={
                                            <form key={'datiCambioSezione'} id='datiCambioSezione' onSubmit={(e) => this.handleSubmit(e)}>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <GenericInput placeholder=' ' type={'text'} id='sezioneDestinazione' name={'sezione'} label={'Sezione di destinazione *'} isReadOnly defaultValue={'Sezione A'} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <GenericInput placeholder=' ' type={'text'} id='sezionePartenza' name={'sezione'} label={'Sezione di partenza*'} isDisabled defaultValue={'Sezione B'} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <GenericCheckbox isChecked={isTitoloEstero} onChange={() => this.setState({ isTitoloEstero: !isTitoloEstero })} id='titoloEs' label='Si &egrave; in posseso di un titolo estero?' />
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className='estero' hidden={!isTitoloEstero}>
                                                    <br></br>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <GenericUploadFront errorMessageAlert={this.uploadError} datiAllegato={this.handleFile} descrizioneBreve='titoloEstero' label={<>Decreto riconoscimento titolo estero ** <Tooltip content={'File da allegare: decreto del ministero o attestato del CONAF'}><InfoCircleIcon /></Tooltip></>} id='titoloEstero' acceptedExtensionFile={['pdf', 'jpg', 'png']} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <GenericInput type={"date"} label={'Data riconoscimento titolo estero **'} placeholder=' ' isRequired={isTitoloEstero} onChange={(e) => this.handleChange(e, 'documenti', 'dataEstero')} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row' hidden={isTitoloEstero}>
                                                    <div className='col-6'>
                                                        <GenericSelect elementId={'tipoLaurea'} defaultValue={tipoLaureaFromIot} name={'tipoLaure'} label={'Tipo Laurea *'} placeholder={"Selezionare tipo laurea"} defaultOptions={tipoLaurea} isRequired={!isTitoloEstero} onChange={(e) => this.handleChange(e, 'Laurea', 'tipolaurea')} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <GenericSelect defaultValue={classeLaureaFromIot} elementId={'classeLaurea'} name={'classeLaurea'} label='Classe di Laurea *' placeholder={"Selezionare classe di laurea"} defaultOptions={this.state.classeLaurea} isRequired={!isTitoloEstero} onChange={(e) => this.handleChange(e, 'Laurea', 'classeLaurea')} />
                                                    </div>
                                                </div>
                                                <div className='row' hidden={isTitoloEstero}>
                                                    <div className='col-6'>
                                                        <GenericInput type={'text'} id='corsoLaurea' placeholder='Inserire corso di laurea' defaultValue={this.state.laurea.corsoLaurea} name={'corsoLaurea'} label='Corso di laurea *' isRequired={!isTitoloEstero} onChange={(e) => this.handleChange(e, 'Laurea', 'corso')} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <GenericInput type={'anno'} id='annoLaurea' name={'annoLaurea'} defaultValue={this.state.laurea.anno} label='Anno laurea *' placeholder='Inserire anno di laurea' isRequired={!isTitoloEstero} onChange={(e) => this.handleChange(e, 'Laurea', 'anno')} />
                                                    </div>
                                                </div>
                                                <div className='row' hidden={isTitoloEstero}>
                                                    <div className='col-6'>
                                                        <GenericInput type={'text'} id='universita' placeholder='Inserire università' defaultValue={this.state.laurea.universita} name={'universita'} label='Universit&agrave; *' isRequired={!isTitoloEstero} onChange={(e) => this.handleChange(e, 'Laurea', 'universita')} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <GenericSelect defaultValue={tipologiaAbilitazioneFromIot} isRequired={true} id='tipoAbilitazione' placeholder='Selezionare tipologia abilitazione' label='Tipologia abilitazione *' defaultOptions={selectTitolo} onChange={(e) => this.handleChange(e, 'Abilitazione', 'tipoAbilitazione')} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <GenericInput isRequired={true} id='annoAbilitazione' defaultValue={this.state.abilitazione.anno} placeholder='Inserire anno abiitazione' label='Anno abilitazione *' type={'anno'} onChange={(e) => this.handleChange(e, 'Abilitazione', 'annoAbilitazione')} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <GenericInput isRequired={true} id='ateneoAbilitazione' defaultValue={this.state.abilitazione.ateneo} placeholder='Inserire ateneo abilitazione' label='Ateneo abilitazione *' type={'text'} onChange={(e) => this.handleChange(e, 'Abilitazione', 'ateneo')} />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <GenericInput id='decretoRiconoscimento' placeholder='Inserire decreto qualifica prof.le' defaultValue={this.state.abilitazione.decretoRiconoscimentoQualProf} label={'Decreto di riconoscimento della qualifica prof.le '} type={'text'} onChange={(e) => { this.handleChange(e, 'Abilitazione', 'decretoRiconoscimento') }} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <GenericInput type={"date"} label={'Data di riconoscimento'} placeholder=' ' defaultValue={this.state.abilitazione.dataRiconoscimento} id='dataRiconoscimento ' onChange={(e) => { this.handleChange(e, 'Abilitazione', 'dataRiconoscimento') }}></GenericInput>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <br></br>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <GenericUploadFront errorMessageAlert={this.uploadError} datiAllegato={(e) => this.handleFile(e, 'bollo')} descrizioneBreve='pagamento' label="Bollo *" id="bollo" acceptedExtensionFile={['jpg', 'png', 'pdf', 'jpeg']} isRequired={true} />
                                                    </div>

                                                    <div className='col-6'>
                                                        <GenericUploadFront errorMessageAlert={this.uploadError} datiAllegato={(e) => this.handleFile(e, 'tassa')} descrizioneBreve='pagamento' label="Tassa *" id="tassa" acceptedExtensionFile={['jpg', 'png', 'pdf', 'jpeg']} isRequired={true} />
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <GenericUploadFront errorMessageAlert={this.uploadError} datiAllegato={(e) => this.handleFile(e, 'quota')} descrizioneBreve='pagamento' label=" Quota iscrizione *" id="quota" acceptedExtensionFile={['jpg', 'png', 'pdf', 'jpeg']} isRequired={true} />
                                                    </div>
                                                    {(datiQuotaIscrizione && datiQuotaIscrizione?.length > 0) ? (
                                                        <>
                                                            <div className='col'>
                                                                <GenericTableCompact
                                                                    style={{ border: "1px solid grey" }}
                                                                    columns={['Anno', 'Categoria', 'Quota']}
                                                                    rows={datiQuotaIscrizione?.map((quota) => (
                                                                        <tr>
                                                                            <td>{quota?.anno ? quota.anno : '-'}</td>
                                                                            <td>{quota?.categoria ? quota.categoria : '-'}</td>
                                                                            <td>{quota?.quota ? quota.quota : '-'} €</td>
                                                                        </tr>
                                                                    ))} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        messageQuotaMancante ?
                                                            <div className='col'>
                                                                <GenericAlert label={messageQuotaMancante} color='info' />
                                                            </div> : null
                                                    )}
                                                </div>
                                                <br></br>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <GenericUploadFront errorMessageAlert={this.uploadError} datiAllegato={this.handleFile} descrizioneBreve='allegato' label='Allegato' id='allegato' acceptedExtensionFile={['jpg', 'png', 'jpeg', 'pdf']} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <GenericInput type='text' label='Descrizione' placeholder='Inserire descrizione' id='descrizioneAllegato' onChange={(e) => this.handleChange(e, 'documenti', 'allegato')} />
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <GenericCheckbox isChecked={this.state.checkAutoc} onChange={() => this.handleCheck('auto')} label={'L’utente certifica sotto la sua responsabilità penale che i dati dichiarati corrispondono al vero. *'} id='autocertificazione' isRequired={true} />
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <GenericCheckbox isChecked={this.state.checkComp} onChange={() => this.handleCheck('comp')} label={<p>Dichiara di aver letto e compreso gli <b><a href='http://www.google.it' target='_blank' >adempimenti degli iscritti</a></b> come riportati nel link. *</p>} id='comprensione' isRequired={true} />

                                                    </div>
                                                </div>
                                            </form>} />
                                    </div>
                                </div>
                            </PageSection>
                            <br></br>
                            <PageSection>
                                <div className='container register-container'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <GenericButton type={'submit'} color={'primary'} label={'Invia'} form={'datiCambioSezione'} isDisabled={this.state.flagRichiestaInviata}></GenericButton>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className='row'>
                                        <p> <b>* : campo obbligatorio</b></p>
                                    </div>
                                    <div className='row'>
                                        <p> <b>** : campo obbligatorio solo se non presente laurea italiana</b></p>
                                    </div>
                                    <br />
                                </div>
                            </PageSection>
                        </>) : (
                        <PageSection>
                            <div className='container register-container'>
                                <GenericBreadCrumb paths={[
                                    { label: "Anagrafica", link: "/" },
                                    { label: "Cambio sezione", link: PATH_TO_FO_ANAG_CAMBIO_SEZIONE },
                                ]} />
                                <h1>Cambio sezione</h1>
                                <GenericAlert label={message} hidden={message === ""} color={typeMessage}></GenericAlert>
                            </div>
                        </PageSection>)
                    )) :
                    (<GenericSpinner></GenericSpinner>)
            );
        }
    }
}

export default RichiestaCambioSezione;
