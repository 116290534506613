import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { LinkList } from 'design-react-kit'
import { GROUP_AUTHENTICATED, TokenStore } from 'src/keycloak/jwt/TokenStore'
import { PATH_TO_FO_ANAG_SELEZIONA_PROFILO, PATH_TO_FO_TODO } from 'src/app/utils/RoutesConstants'
import { getDenominazioneByCodiceGruppo } from 'src/processes/SelezioneProfilo'
import GenericAlert from '../../components/GenericAlert'

const title = 'Seleziona profilo'
var breadcrumbOptions = [
    { label: "Seleziona profilo", link: PATH_TO_FO_ANAG_SELEZIONA_PROFILO }
]

class SelezioneProfilo extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default",
            accounts: [],
            selectedAccountName: ""
        };
    }

    componentDidMount() {
        this.buildAccounts();
    }

    /**
     * Costruisce la lista degli account da visualizzare.
     */
    buildAccounts = async () => {
        var myAccounts: any = [];
        TokenStore.getInstance().getCurrentAccountList().map(async (account, id) => {
            var groupName = TokenStore.getInstance().getSpecificGroupName(id);

            await getDenominazioneByCodiceGruppo(groupName).then((response => {

                if (response.data && response.status !== 404) {

                    /**
                     * Nel caso di "Operatore STP" il servizio getDenominazioneByCodiceGruppo
                     * restituisce due elementi:
                     * - [0] Denominazione STP
                     * - [1] Id iscrizione STP
                     *
                     * In tutti gli altri casi, ossia, ODAF, FODAF, CONAF e Iscritto all'ordine
                     * restituisce solo la denominazione.
                     */


                    var denominazione = response?.data?.returnedObject;
                    if(denominazione === null)
                        throw new Error(groupName)

                    else
                    {
                        if (denominazione !== ""  && denominazione[0]?.startsWith("Operatore STP") || denominazione[0]?.startsWith("Operatore formazione") || denominazione[0]?.startsWith("Operatore ente esterno") || denominazione[0]?.startsWith("Operatore rivista") || denominazione[0]?.startsWith("Formatore")) {
                            denominazione = denominazione[0];
                        }
                    }

                    var idAccount = TokenStore.getInstance().getIdOfAccountSpecificGroupName(groupName);

                    var currentAccountId = TokenStore.getInstance().getCurrentAccountId();
                    if (idAccount === currentAccountId) {
                        this.setState({ selectedAccountName: denominazione });
                    }
                    var url = "";
                    if(denominazione.startsWith("Formatore")) {
                        url = PATH_TO_FO_TODO;
                    } else if(denominazione.startsWith("Operatore ente esterno")) {
                        url = PATH_TO_FO_TODO;
                    } else if(denominazione.startsWith("Operatore rivista")) {
                        url = PATH_TO_FO_TODO;
                    } else if(denominazione.startsWith("Operatore formazione")) {
                        url = PATH_TO_FO_TODO;
                    } else if (denominazione.startsWith("Iscritto")) {
                        url = PATH_TO_FO_TODO;
                    } else if(denominazione.startsWith("Operatore STP")){
                        url = PATH_TO_FO_TODO;
                    } else if(denominazione.startsWith("Operatore nazionale") || denominazione === ""){
                        url = PATH_TO_FO_TODO;
                    } else {
                        url = PATH_TO_FO_TODO;
                    }

                    if (groupName !== GROUP_AUTHENTICATED) {
                        myAccounts.push(
                            {
                                "id": idAccount,
                                "denominazione": denominazione,
                                "url": url,
                                "key": Math.random()
                            }
                        )
                        this.setState({ accounts: myAccounts });
                    }
                }
            })).catch((err) => {
                this.setState({message: "C'è stato un errore durante il recupero del profilo: " + err.message, typeMessage: "danger"})
            });
        })
    }

    post_to_url = function (path, params, method) {
        method = method || "post";

        var form = document.createElement("form");

        //Move the submit function to another variable so that it doesn't get overwritten.
        form._submit_function_ = form.submit;

        form.setAttribute("method", method);
        form.setAttribute("action", path);

        for (var key in params) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form._submit_function_(); //Call the renamed function.
    }

    /**
     * Si occupa di fare swith tra gli account.
     * Se il nome dell'account inizia con "Iscritto" vuol dire che si tratta di un account personale e quindi viene fatto il redirect verso la TODO.
     * Altrimenti viene fatto il redirect verso il backoffice.
     * @param event
     */
    setCurrentAccount = (event, account) => {
        // Imposta il current account
        TokenStore.getInstance().setCurrentAccount(account.id);
        this.setState({ selectedAccountName: account.denominazione });
    }

    render() {
        const { message, typeMessage } = this.state;
        return (
            <>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                        {/* <div><h1>{title}</h1></div> */}
                        <Title headingLevel="h1" size="4xl">{title}</Title><br></br>
                        <GenericAlert hidden={message === ''}  label={message} color={typeMessage}></GenericAlert>
                        <h2>Profilo selezionato: <b>{this.state.selectedAccountName}</b></h2>
                        <div className="col-12">
                            <LinkList>
                                {
                                    this.state.accounts.map((account, index) => (
                                        <div key={index}>
                                            <a id={account.id} href={account.url} onClick={(e) => this.setCurrentAccount(e, account)}>{account.denominazione}</a>
                                            <br></br>
                                        </div>
                                    ))}
                            </LinkList>
                        </div>

                    </div>
                </PageSection>
            </>
        );
    }
};

export declare interface GeneralFormSelezioneProfiloProps {
    //messaggio?: any;
    //idSoggetto?: any;
}

export default SelezioneProfilo;
