
export namespace AppCostants{
    export const APP_DEBUG = Boolean(process.env.REACT_APP_DEBUG_PANEL);
    export const PUBLIC_SHARE = String(process.env.REACT_APP_PUBLIC_SHARE);

    export function isDebug(){
        let debug = process.env.REACT_APP_DEBUG_PANEL;
        
        let isDebug;
        if(debug === "true") isDebug = true
        else isDebug = false
        return isDebug;
    }
}
