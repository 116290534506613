import React, { useCallback, useRef, useState } from 'react'
import { Button } from 'design-react-kit'
import { AllegatoDTO } from '@parsec/msfo'
import { getFileNameFromPath, getValueAtPath } from 'src/utilities/utility'
import { ottieniAllegato } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'

import Attach from 'src/frontend/app/images/Iconsattache.png'
import CloseImage from 'src/frontend/app/images/x.png'
import styled from 'styled-components'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const UploadStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .upload-container {
    display: flex;
    align-items: flex-start;
  }
  .upload-action * {
    cursor: pointer;
  }
  .upload-label {
    font-size: 18px;
  }
  .upload-description {
    font-weight: thin;
  }
  .upload-filename {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #0073e6;
    font-size: 18px;
    font-weight: bold;
  }
  .imgNewSize{
    width: 100px
  }
`

const Progressbar = (props: { started?: boolean; finished?: boolean }) =>
  props?.started ? (
    <div className={`progress ${!props?.finished ? 'progress-indeterminate' : ''}`}>
      <div className={`progress-bar ${props?.finished ? 'w-100' : ''}`} role="progressbar" />
    </div>
  ) : null

const CustomInput = ({ extensions, accept, tipologiaAllegato, descrizioneBreve, value, isDisabled, onDownload, onUpload, onDelete }: CustomInputProps) => {

  function isValidFile(file, accept) {
    if(accept){
      return accept.includes(file.type);
    }
    else {

      return true
    }
  }

  const fileInputRef = useRef<any>()
  const [uploadStarted, setUploadStarted] = useState(!!value)

  const { allegatoDTO } = value || {}
  const { filename } = allegatoDTO || {}

  const download = (value) => {
    const { idAllegato, allegatoDTO } = value || {}
    const { contentBase64, contentType, filename } = allegatoDTO || {}
    onDownload?.()

    if (idAllegato) {
      ottieniAllegato(idAllegato)
    } else if (contentBase64) {
      // download file from contentBase64
      const dataUrl = `data:${contentType};base64,${contentBase64}`
      const a = document.createElement('a')
      a.href = dataUrl
      a.download = filename
      a.click()
    }
  }
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (event) => {
    setUploadStarted(true)
    const { target } = event || {}
    const { value, files } = target || {}
    const file = files?.[0]
    if (!file) return

if (isValidFile(file,accept) ) {

    setErrorMessage('')

    const reader = new FileReader()
    reader.onload = (res) => {
      const { target } = res || {}
      const { result } = target || {}
      if (typeof result !== 'string') return

      const allegatoDTO: AllegatoDTO = {
        filename: getFileNameFromPath(value, '\\'),
        contentBase64: result?.split('base64,').pop(),
        tipologiaAllegato,
        contentType: file.type,
        descrizioneBreve,
      }
      onUpload?.(allegatoDTO)
    }
    reader.readAsDataURL(file)
  } else {
    setUploadStarted(false)
    const error = `Il file selezionato non è un formato accettato`;
    setErrorMessage(error);
  }
  }

  return (
    <div className="upload-container">
      <input ref={fileInputRef} hidden={true} type="file" accept={accept?.join(',')} onChange={handleFileChange} />
      <img className="attach-img" src={Attach} alt="attach" />

      <div
        className="upload-action"
        onClick={(e) => {
          if (!value) {
            fileInputRef?.current?.click()
            return
          }
          e.preventDefault()
          download(value)
        }}
      >
        {!!value && <label className="upload-filename">{filename}</label>}
        {!value && !isCurrentMobileDevice() && <label className="upload-label">Clicca qui per eseguire l'upload</label>}
        <Progressbar started={uploadStarted} finished={!!value} />
        <div className="d-flex justify-content-center">
          {!!value && <label className="upload-description">Scarica il file</label>}
          {!value && extensions && <label className="upload-description">Formati accettati: {extensions?.join(',')}</label>}

        </div>
        {errorMessage && <div className="error-message" style= {{color: 'red'}}>{errorMessage}</div>}
      </div>

      {!!value && (
        <Button
          className='imgNewSize'
          disabled={isDisabled}
          onClick={(e) => {
            // TODO alert and delete the file from the server
            // if allegatoDTO?.idAllegato
            onDelete?.(e)
          }}
        >
          <img src={CloseImage} alt="close" />
        </Button>
      )}
    </div>
  )
}

interface CustomInputProps {
  extensions: Array<string>
  accept: Array<string>
  tipologiaAllegato?: string
  descrizioneBreve?: string
  value: any
  isDisabled?: boolean
  onDownload?: Function
  onUpload?: Function
  onDelete?: Function
}
CustomInput.defaultProps = {
  onDownload: () => {},
  onUpload: () => {},
  onDelete: () => {},
}

const Upload = (props: UploadProps) => {
  const { id, isMulti, isDisabled, data, onChange, extensions, accept, tipologiaAllegato } = props || {}
  let values = [getValueAtPath(data, id)].flat()
  // always keep one empty value at the end if no values present or isMulti
  values = values.filter((v) => v)
  if (isMulti || !values?.length) {
    values.push(undefined)
  }

  const onDelete = useCallback(
    (id, index) => {
      if (isMulti) {
        const _values = [...values]
        _values.splice(index, 1)
        onChange(id, _values)
      } else {
        onChange(id, undefined)
      }
    },
    [values, onChange]
  )

  const onUpdate = useCallback(
    (id, value, index) => {
      if (isMulti) {
        let _values = [...values]
        _values[index] = value

        onChange(id, _values)
      } else {
        onChange(id, value)
      }
    },
    [values, onChange]
  )

  return (
    <UploadStyled>
      {values?.map((value, index) => {
        if (isDisabled && !value) return null
        const randomKey = Math.random().toString(36).substring(7)
        const key = value?.key || `${index}-${randomKey}`

        return (
          <CustomInput
            key={key}
            value={value}
            isDisabled={isDisabled}
            extensions={extensions}
            accept={accept}
            tipologiaAllegato={tipologiaAllegato}
            onUpload={(allegatoDTO: AllegatoDTO) => {
              const _allegato: any = {
                allegatoDTO,
                tipologiaAllegato,
                key,
              }
              onUpdate(id, _allegato, index)
            }}
            onDelete={() => {
              onDelete(id, index)
            }}
          />
        )
      })}
    </UploadStyled>
  )
}

interface UploadProps {
  id: string
  data?: any
  accept?: any
  onChange: Function
  tipologiaAllegato?: string
  extensions: Array<string>
  isMulti?: boolean
  isDisabled?: boolean
  [key: string]: any
  errorMessage?: string

}
export default Upload
