import React, { useEffect, useMemo } from 'react'
import { Form } from 'design-react-kit'
import useProvince from 'src/hooks/useProvince'
import { getValueAtPath } from 'src/utilities/utility'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'

const FormDatiSedeLegale: React.FunctionComponent<FormDatiSedeLegaleProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiSedeLegale'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const provincia = getValueAtPath(data, `${objKey}.provincia`)?.value
  const comune = getValueAtPath(data, `${objKey}.comune`)?.value

  const { province, comuni } = useProvince(provincia, comune, () => {
    handleInput(`${objKey}.comune`, '')
  })

  const hasStatoEstero = !!province.find((prov) => {
    return prov.value === 'EEP'
  })

  if (!hasStatoEstero) province.unshift({ value: 'EEP', label: 'Stato Estero' })

  const isComuneStatoEstero = data?.datiSedeLegale?.provincia?.value && data?.datiSedeLegale?.provincia?.value === 'EEP' ? true : false

  useEffect(() => {
    if (provincia && provincia === 'EEP') {
      handleInput(`${objKey}.comune`, { label: 'Stato Estero', value: 'EEP' })
    }
  }, [provincia])
  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'provincia'}
              placeholder="Seleziona Provincia"
              label="Provincia"
              suffix="*"
              defaultOptions={province}
              data={data}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'comune'}
              placeholder="Seleziona Comune"
              label="Comune"
              suffix="*"
              defaultOptions={comuni}
              data={data}
              errors={errors}
              isDisabled={viewOnly || isComuneStatoEstero}
            />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="indirizzo" type="text" label="Indirizzo" suffix="*" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="cap" type="text" label="Cap" suffix="*" data={data} errors={errors} />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiSedeLegaleProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiSedeLegale
