import React, { useMemo, useRef, useState } from 'react'
import { Alert, Button, Form, Icon, Label } from 'design-react-kit'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericButton from 'src/frontend/app/components/GenericButton'
import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import formValidationRules, { isInvalidAllegato } from './ComitatoTecnicoFormRules'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { Title } from '@patternfly/react-core'
import { UploadRowStyled } from 'src/frontend/app/pages/richiestaEsonero/formRichiestaEsonero/FormRichiestaEsonero'
import GenericUpload from 'src/frontend/app/components/Form/Upload'
import close from '../../../../images/x.png'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormDatiComitatoTecnico: React.FunctionComponent<GeneralFormDatiAgenziaProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const refForm = useRef<any>([])
  const [dataForm, setData] = useState<any>({})
  const [errorsForm, setErrors] = useState<any>({})
  const formData = createFormData(dataForm, setData, errorsForm, setErrors)
  const objKey = ''
  const objKeyFullForm = 'datiComitatoTecnico'

  const columns: Column[] = [
    { label: 'Titolo', field: 'titolo', isSortable: false },
    { label: 'Nome e cognome', field: 'nome_cognome', isSortable: false },
    { label: 'Rimuovi', field: '', isSortable: false, hidden: viewOnly ? true : false },
    { label: 'Modifica', field: '', isSortable: false, hidden: viewOnly ? true : false },
  ]

  const handleInputChange = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onInit = (cmp, tab) => {
    refForm.current.push({
      cmp: cmp,
      tab: 0,
    })
  }

  const removefromComitato = (index, type) => {
    const datiSlice = [...data?.datiComitatoTecnico?.comitato].filter((elem, indice) => {
      if (indice === index && type === 1) setData(elem)
      return indice !== index
    })
    handleInput(objKeyFullForm + '.comitato', datiSlice)
  }

  const getColumnValue = (column: any, row: any) => {
    if (column.field && row[column.field] !== undefined) {
      const value = row[column.field]
      if (column.options && column.options[value]) {
        return column.options[value]
      }
      return row[column.field]
    }
    return ''
  }

  const Spacer = () => (
    <>
      <br></br>
    </>
  )

  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInit, onChange: handleInputChange, viewOnly }), [])

  const aggiungiComitato = () => {
    const inputs = getTabActiveFormId(refForm, 0)
    const isValid = validateFormData(formData, formValidationRules, inputs)
    if (!isValid) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      return
    }

    setData({})
    if (data?.datiComitatoTecnico?.comitato) {
      handleInput(objKeyFullForm + '.comitato', [...data?.datiComitatoTecnico?.comitato, dataForm])
    } else {
      handleInput(objKeyFullForm + '.comitato', [dataForm])
    }
  }

  return (
    <GenericCard cardHeader={title}>
      {data?.datiComitatoTecnico?.comitato?.length > 0 && data?.datiComitatoTecnico && (
        <>
          <Title headingLevel="h2" size="lg">
            Comitato tecnico:
          </Title>
          <Spacer></Spacer>
          {data?.datiComitatoTecnico?.comitato?.length < 5 && <Alert color="danger">Inserire un minimo di 5 partecipanti al comitato</Alert>}
          <TableComposable aria-label="Sort table">
            <Thead>
              <Tr>
                {columns.map((column, index) => {
                  return !column.hidden ? (
                    <Th key={`head${index}`} style={column.style || {}}>
                      {column.label}
                    </Th>
                  ) : (
                    <></>
                  )
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data?.datiComitatoTecnico?.comitato.map((row, index) => {
                return (
                  <Tr key={`row${index}`}>
                    {columns.map((column, index) => {
                      if (column.label !== 'Rimuovi' && column.label !== 'Modifica') {
                        return (
                          <Td key={`col${index}`} style={column.style || {}}>
                            {column.render && column.render(row)}
                            {!column.render && getColumnValue(column, row)}
                          </Td>
                        )
                      } else return null
                    })}
                    {!viewOnly && (
                      <>
                        <Td>
                          <Button
                            onClick={() => {
                              removefromComitato(index, 0)
                            }}
                          >
                            <img src={close} alt="" />
                          </Button>
                        </Td>
                        <Td>
                          <Button
                            onClick={() => {
                              removefromComitato(index, 1)
                            }}
                          >
                            <Icon icon="it-pencil" />
                          </Button>
                        </Td>
                      </>
                    )}
                  </Tr>
                )
              })}
            </Tbody>
          </TableComposable>
          <Spacer></Spacer>
        </>
      )}
      {!viewOnly && (
        <Form>
          <Title headingLevel="h2" size="lg">
            Inserisci partecipante comitato tecnico:
          </Title>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput as={GenericInput} id="titolo" type="text" label="Titolo" suffix="*" data={dataForm} errors={errorsForm} />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput as={GenericInput} id="nome_cognome" type="text" label="Nome e cognome" suffix="*" data={dataForm} errors={errorsForm} />
            </div>
            <UploadRowStyled>
              <div className="uploader-label">
                <Label for={'allegato'}>
                  <strong>CV *</strong>
                </Label>
              </div>
              <div className="uploader-files">
                <GenericUpload
                  isMulti={false}
                  id={'allegato'}
                  tipologiaAllegato={'CV_partecipante_comitato'}
                  extensions={['pdf']}
                  accept={['application/pdf']}
                  data={dataForm}
                  onChange={handleInputChange}
                />
              </div>
            </UploadRowStyled>
            {isInvalidAllegato(dataForm.allegato) && <Alert color="info">Inserire tutti gli allegati prima di procedere</Alert>}
            <GenericInput type={'hidden'} id={'check_uploads'} onInit={onInit} />
            <GenericInput type={'hidden'} id={'check_n_comitati'} onInit={onInitInput} />
          </div>

          <div className="row">
            <GenericButton
              label={'Aggiungi'}
              color={'primary'}
              onClickEvent={(e) => {
                e.preventDefault()
                aggiungiComitato()
              }}
            />
          </div>
        </Form>
      )}
    </GenericCard>
  )
}

interface GeneralFormDatiAgenziaProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export declare interface Column {
  label: string
  width?: boolean
  style?: any
  hidden?: boolean
  isSortable?: boolean
  field: string
  render?: any
  options?: any
}

export default FormDatiComitatoTecnico
