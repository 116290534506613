export const isInvalidAllegato = (allegato:any) => {
    if (!allegato) return true
    if (Array.isArray(allegato)) return allegato?.filter((v) => v)?.length === 0
    return !allegato?.allegatoDTO?.contentBase64
}

export default {
  regex: [
    {
      regex: /datiAnagrafici.*(?<!fax)$/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiSedeLegale.*/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiRappresentanteLegale.*/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiAccreditamento.*/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiComitatoTecnico.*/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiDichiarazioni.*/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiAllegati.*/,
      rules: {
        isRequired: true,
      },
    },
  ],
  'datiAnagrafici.codiceFiscale': {
    isFiscalCode: true,
  },
  'datiAnagrafici.piva': {
    isPartitaIva: true,
  },
  'datiAnagrafici.email': {
    isEmail: true,
  },
  'datiAnagrafici.pec': {
    isEmail: true,
  },
  'datiAnagrafici.telefono': {
    isTelefono: true,
  },
  'datiAnagrafici.fax': {
    isFax: true,
  },
  'datiSedeLegale.cap': {
    isCap: true,
  },
  'datiRappresentanteLegale.cap': {
    isCap: true,
  },
  'datiResponsabileAF.codiceFiscale': {
    isFiscalCodePhysicalPerson: true,
  },
  'datiResponsabileAF.cap': {
    isCap: true,
  },
  'datiResponsabileAF.email': {
    isEmail: true,
  },
  'datiDichiarazioni.pianoEditoriale': {
    isRequired: true,
  },
  'datiDichiarazioni.sostenibilita': {
    isRequired: true,
  },
  'datiDichiarazioni.spazioRivista"': {
    isRequired: true,
  },
  check_uploads: {
    _custom: {
      check: (code, rule, data) => {
        return !isInvalidAllegato(data?.datiComitatoTecnico?.comitato?.allegato)
      },
    },
  },
  check_uploads_moduloAccreditamento: {
    _custom: {
      check: (code, rule, data) => {
        return !isInvalidAllegato(data?.datiAllegati?.moduloAccreditamento)
      },
    },
  },
  check_n_comitati: {
    _custom: {
        check: (code, rule, data) => {
            return data?.datiComitatoTecnico?.comitato?.length >= 5
        }
    }
  }
}
