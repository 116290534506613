import React from 'react'
import GenericCard from '../../../components/GenericCard'
import GenericDataPicker from '../../../components/GenericDatePicker'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getPolizzaById, getPolizzaStoricaById } from 'src/processes/assicurazioni/Assicurazione'
import { conversioneIntToSiNo, isEmptyValue } from 'src/utilities/utility'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { AppUtils as U } from 'src/app/utils/FormUtils'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const yes_no_options = [
    { label: "Si", value: "Si"},
    { label: "No", value: "No"}
]

export default class InserimentoDatiPolizza extends React.Component<InserimentoDatiPolizzaProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      tabClick: props.tabClick,
      prevData: props.prevData,
      polizza: props.polizza,
      fields: props.data,
      polizzaInsInd:props.polizzaInsInd,
      visualizzaFlag:props.visualizzaFlag,
      modificaDatiFlag:props.modificaDatiFlag,
      errors: {},
      isLoaded : false,
      studioAssociatoFlag: true,
      congedoMaternitaPaternita: false,
    };
  }


  async componentDidMount(): Promise<void> {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    if(this.props.data) {
      ;
      this.setState({fields: this.props.data})
      let idPolizza;
      if(this.props.data.id) idPolizza = this.props.data.id;
      if(this.props.data.idPolizza) idPolizza = this.props.data.idPolizza;
      if(!this.props.storicoFlag) {
          await getPolizzaById(idPolizza).then(async (response: any)=> {
              let fields = this.state.fields;
              fields = response;
              fields.codiceFiscale = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;

              //casting da numerale a dropdown
              fields.coperturaPostuma = U.dn(response.coperturaPostuma);
              fields.danniPatrimoniali = U.dn(response.danniPatrimoniali);
              fields.retroattivitaIllimitata  = U.dn(response.retroattivitaIllimitata);
              fields.operaStudioAssociato  = U.dn(response.operaStudioAssociato);
              fields.altrePolizze  = U.dn(response.altrePolizze);
              fields.maternita  = U.dn(response.maternita);
              fields.paternita = U.dn(response.paternita);

              fields.idCategoriaDue = {label: (response.categoria === 2) ? 'Si' : 'No', value: (response.categoria === 2) ? 'Si' : 'No'};
              fields.privacy = (response.privacy === 1) ? true : false;
              fields.haLettoCircostanza = (response.haLettoCircostanza === 1) ? true : false;
              fields.premioAltri = response.premioAltri.toFixed(2).toString().replace('.', ',');
              fields.valoreRischioAltri = !isEmptyValue(response.valoreRischioAltri) ? parseFloat(response.valoreRischioAltri).toFixed(2).toString().replace('.', ',').toString() : '';
              fields.massimaleAltri = parseFloat(response.massimaleAltri).toFixed(2).toString().replace('.', ',');

              fields.periodoValDaConaf = U.data_normalize(response.periodoValDaConaf);
              fields.periodoValAConaf  = U.data_normalize(response.periodoValAConaf);
              fields.periodoValDaAltri = U.data_normalize(response.periodoValDaAltri);
              fields.periodoValAAltri = U.data_normalize(response.periodoValAAltri);

              fields.massimaleMax = response.vopConaf;
              fields.rischioMax = response.vrcConaf;

              this.setState({polizza: response, fields: fields, isLoaded: true});

          }).catch((err) => {
              alert(err);
                      setTimeout(() => {
                          this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                      }, 3000);
            })



      } else {
        await getPolizzaStoricaById(idPolizza).then(async (response: any)=> {

          let fields = this.state.fields;
          fields = response;
          fields.codiceFiscale = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;
          fields.coperturaPostuma = {label: conversioneIntToSiNo(response.coperturaPostuma), value: conversioneIntToSiNo(response.coperturaPostuma)};
          fields.danniPatrimoniali = {label: conversioneIntToSiNo(response.danniPatrimoniali), value: conversioneIntToSiNo(response.danniPatrimoniali)};
          fields.retroattivitaIllimitata = {label: conversioneIntToSiNo(response.retroattivitaIllimitata), value: conversioneIntToSiNo(response.retroattivitaIllimitata)};
          fields.operaStudioAssociato = {label: (response.operaStudioAssociato) ? 'Si' : 'No', value: (response.operaStudioAssociato) ? 'Si' : 'No'};
          fields.altrePolizze = {label: conversioneIntToSiNo(response.numeroPolizaAltri), value: conversioneIntToSiNo(response.numeroPolizaAltri)};
          fields.idCategoriaDue = {label: (response.categoria === 2) ? 'Si' : 'No', value: (response.categoria === 2) ? 'Si' : 'No'};
          fields.maternita = {label: conversioneIntToSiNo(response.maternita), value: conversioneIntToSiNo(response.maternita)};
          fields.paternita = {label: conversioneIntToSiNo(response.paternita), value: conversioneIntToSiNo(response.paternita)};
          fields.privacy = (response.privacy === 1) ? true : false;
          fields.haLettoCircostanza = (response.haLettoCircostanza === 1) ? true : false;
          fields.periodoValDaAltri = U.data_normalize(response.periodoValiditaInizio);
          fields.periodoValAAltri  = U.data_normalize(response.periodoValiditaFine);
          fields.premioAltri = response.premioAltri.toFixed(2).toString().replace('.', ',');
          fields.valoreRischioAltri = !isEmptyValue(response.valoreRischioAltri) ? parseFloat(response.valoreRischioAltri).toFixed(2).toString().replace('.', ',').toString() : '';
          fields.massimaleAltri = response.massimaleAltri.toFixed(2).toString().replace('.', ',');

          fields.massimaleMax = response.vopConaf;
          fields.rischioMax = response.vrcConaf;
          this.setState({polizza: response, fields: fields, isLoaded: true});
          this.props.getSelectedValue(this.state.fields);
          }).catch(() => {
                    setTimeout(() => {
                        this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                    }, 3000);
          })
      }

    }
  }

  render() {
    const { tabNumber } = this.props;

    /** Funzione per aggiornare i campi del form */
    const setDataItem = (e:any, type: any, field:any, index?: any) => {
        let fields = this.state.fields;
        if(type === 'select') {
          if(field === 'operaStudioAssociato' && e.label === 'Si')
              this.setState({studioAssociatoFlag: true})
          else this.setState({studioAssociatoFlag: false})

          if((field === 'maternita' || field === 'paternita' ) && e.label === 'Si')
              this.setState({congedoMaternitaPaternita: true})
          else this.setState({congedoMaternitaPaternita: false})

          fields[field] = {label: e.label, value: e.label};
        }
        else if(type==='checkbox') {
          fields[field] = e.target.checked;
        }
        else if(type==='text' && (field==='codiceStudioAssociato' || field==='ragioneSocialeStudioAssociato') && index !== undefined) {
        }
        else fields[field] = e.target.value;
        this.setState({ fields });
        this.props.getSelectedValue(fields, "polizza");

    }

    /********************************* Form InserimentoDati ************************************/
    const formInserimentoDati = [
        <div key={"formInserimentoDati"} id="formInserimentoDati" hidden={(!this.state.fields)}>
            <GenericCard key={'formInserimentoDati'} cardHeader="Inserimento Dati Polizza Individuale" cardBody={
                  <div>
                        <div className="row">
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericSelect key={'danniPatrimoniali'} elementId={'danniPatrimoniali'} name={'danniPatrimoniali'} onChange={(e)=>setDataItem(e, 'select','danniPatrimoniali')} label='Copre i danni patrimoniali e non ? *' placeholder='Si / No'
                                defaultOptions={yes_no_options} errorMessageAlert={this.props.message} currentStep={tabNumber} isRequired={this.state.fields['danniPatrimoniali'] ? false : true}
                                defaultValue={(this.state.fields['danniPatrimoniali']) ? this.state.fields['danniPatrimoniali'] : null}
                                />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericSelect key={'retroattivitaIllimitata'} elementId={"retroattivitaIllimitata"} name={"retroattivitaIllimitata"} onChange={(e)=>setDataItem(e, 'select','retroattivitaIllimitata')} label='Ha una retroattività illimitata? *' placeholder='Si / No'
                                defaultOptions={yes_no_options} errorMessageAlert={this.props.message} currentStep={tabNumber} isRequired={this.state.fields['retroattivitaIllimitata'] ? false : true}
                                defaultValue={(this.state.fields['retroattivitaIllimitata']) ? this.state.fields['retroattivitaIllimitata'] : null}
                                />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericSelect key={'coperturaPostuma'} elementId={"coperturaPostuma"} name={"coperturaPostuma"} onChange={(e)=>setDataItem(e, 'select','coperturaPostuma')} label='Prevede copertura postuma decennale causa cessazione? *' placeholder='Si / No'
                                defaultOptions={yes_no_options} errorMessageAlert={this.props.message} currentStep={tabNumber} isRequired={this.state.fields['coperturaPostuma'] ? false : true}
                                defaultValue={(this.state.fields['coperturaPostuma']) ? this.state.fields['coperturaPostuma'] : null}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericInput key="nomeCompagniaAltri" id="nomeCompagniaAltri" name={'nomeCompagniaAltri'} defaultValue={(this.state.fields['nomeCompagniaAltri']) ? this.state.fields['nomeCompagniaAltri'] : null}
                                  onChange={(e)=>setDataItem(e,'text','nomeCompagniaAltri')}  label='Compagnia Assicurativa *' placeholder='Inserire compagnia assicurativa' type='text' isRequired isHidden={false} errorMessageAlert={this.props.message} currentStep={tabNumber}/>
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericInput key={'valoreRischioAltri'} id="valoreRischioAltri" name={'valoreRischioAltri'}  errorMessageAlert={this.props.message} currentStep={tabNumber} onChange={(e)=>setDataItem(e, 'input','valoreRischioAltri')} label={isCurrentMobileDevice() ?  "Valore del rischio (€) * (Superiore a " + this.state.fields.rischioMax + ")" : "Valore del rischio (€) * (Deve essere superiore a " + this.state.fields.rischioMax + ")"} placeholder='Inserire valore (€)'
                                defaultValue={(this.state.fields['valoreRischioAltri']) ? this.state.fields['valoreRischioAltri'] : null} type='numeric' isRequired={this.state.fields['valoreRischioAltri'] === ""} />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericInput key={'massimaleAltri'} id="massimaleAltri" name={'massimaleAltri'} errorMessageAlert={this.props.message} currentStep={tabNumber} onChange={(e)=>setDataItem(e, 'input','massimaleAltri')} label={isCurrentMobileDevice() ? "Massimale * (Superiore a " + this.state.fields.massimaleMax + ")" : "Massimale * (Deve essere superiore a " + this.state.fields.massimaleMax + ")"} placeholder='Inserire massimale'
                                defaultValue={(this.state.fields['massimaleAltri']) ? this.state.fields['massimaleAltri'] : null} type='numeric' isRequired={!this.state.fields['massimaleAltri']}/>
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericInput key={'premioAltri'} id="premioAltri" name={'premioAltri'} errorMessageAlert={this.props.message} currentStep={tabNumber} onChange={(e)=>setDataItem(e, 'input','premioAltri')} label="Premio (€) *" placeholder='Inserire premio (es. 10,00)'
                                defaultValue={(this.state.fields['premioAltri']) ? this.state.fields['premioAltri'] : null} type='numeric' isRequired={!this.state.fields['premioAltri']}
                                />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericDataPicker key={'periodoValDaAltri'} id="periodoValDaAltri" errorMessageAlert={this.props.message} currentStep={tabNumber} onChange={(e)=>setDataItem(e, 'text','periodoValDaAltri')} label="Data inizio validità *" placeholder='Inserire data'
                                defaultValue={(this.state.fields['periodoValDaAltri']) ? this.state.fields['periodoValDaAltri'] : null} isRequired={!this.state.fields['periodoValDaAltri']}
                                />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericDataPicker key={'periodoValAAltri'} id="periodoValAAltri" errorMessageAlert={this.props.message} currentStep={tabNumber} onChange={(e)=>setDataItem(e, 'text','periodoValAAltri')} label="Data fine validità *" placeholder='Inserire data'
                                defaultValue={(this.state.fields['periodoValAAltri']) ? this.state.fields['periodoValAAltri'] : null} isRequired={!this.state.fields['periodoValAAltri']}
                                />
                            </div>
                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                <GenericInput key={'numeroPolizaAltri'} id="numeroPolizaAltri" name={'numeroPolizaAltri'} errorMessageAlert={this.props.message} currentStep={tabNumber} onChange={(e)=>setDataItem(e, 'input','numeroPolizaAltri')} label="Numero Polizza *" placeholder='Inserire numero polizza'
                                defaultValue={(this.state.fields['numeroPolizaAltri']) ? this.state.fields['numeroPolizaAltri'] : null} type='text' isRequired={!this.state.fields['numeroPolizaAltri']}
                                />
                            </div>
                        </div>
                  </div>

            }></GenericCard>
        </div>

    ];

    const formVisualizzaInserimentoDati = [
      <div key={"formInserimentoDati"} id="formInserimentoDati" hidden={(!this.state.fields)}>
          <GenericCard key={'formInserimentoDati'} cardHeader="Inserimento Dati Polizza Individuale" cardBody={
                <div>
                      <div className="row">
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled label='Copre i danni patrimoniali e non ? *' placeholder='Si / No'
                            defaultValue={(this.state.fields['danniPatrimoniali']) ? this.state.fields['danniPatrimoniali'].label : 'No'}
                            />
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled label='Ha una retroattività illimitata? *' placeholder='Si / No'
                            defaultValue={(this.state.fields['retroattivitaIllimitata']) ? this.state.fields['retroattivitaIllimitata'].label : 'No'}
                            />
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled label='Prevede copertura postuma decennale causa cessazione? *' placeholder='Si / No'
                            defaultValue={(this.state.fields['coperturaPostuma']) ? this.state.fields['coperturaPostuma'].label : 'No'}
                            />
                          </div>
                      </div>
                      <div className="row">
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled key="nomeCompagniaAltri" id="nomeCompagniaAltri" name={'nomeCompagniaAltri'} isRequired={!this.state.fields['nomeCompagniaAltri']} defaultValue={(this.state.fields['nomeCompagniaAltri']) ? this.state.fields['nomeCompagniaAltri'] : "-"} label='Compagnia Assicurativa *' placeholder='Inserire compagnia assicurativa' />
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled key={'valoreRischioAltri'} id="valoreRischioAltri" name={'valoreRischioAltri'} isRequired={!this.state.fields['valoreRischioAltri']} label="Valore del rischio (€) *" placeholder='Inserire valore (€)'
                              defaultValue={(this.state.fields['valoreRischioAltri']) ? this.state.fields['valoreRischioAltri'] : "-"}  />
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled key={'massimaleAltri'} id="massimaleAltri" name={'massimaleAltri'} isRequired={!this.state.fields['massimaleAltri']} label="Massimale *" placeholder='Inserire massimale'
                              defaultValue={(this.state.fields['massimaleAltri']) ? this.state.fields['massimaleAltri'] : "-"} />
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled key={'premioAltri'} id="premioAltri" name={'premioAltri'} isRequired={!this.state.fields['premioAltri']} label="Premio (€ es. 10,00) *" placeholder='Inserire premio (es. € 10,00)'
                              defaultValue={(this.state.fields['premioAltri']) ? this.state.fields['premioAltri'] : "-"}
                              />
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericDataPicker isDisabled label='Data di inizio validità *' placeholder='Selezionare data' key="periodoValDaAltri" isRequired={!this.state.fields['periodoValDaAltri']}
                              defaultValue={(this.state.fields['periodoValDaAltri']) ? this.state.fields['periodoValDaAltri'] : null} />
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericDataPicker isDisabled label='Data di fine validità *' placeholder='Selezionare data' key="periodoValAAltri" isRequired={!this.state.fields['periodoValAAltri']}
                              defaultValue={(this.state.fields['periodoValAAltri']) ? this.state.fields['periodoValAAltri'] : null}/>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : "col-6"}>
                              <GenericInput isDisabled key={'numeroPolizaAltri'} id="numeroPolizaAltri" name={'numeroPolizaAltri'} isRequired={!this.state.fields['numeroPolizaAltri']} label="Numero Polizza *" placeholder='Inserire numero polizza'
                              defaultValue={(this.state.fields['numeroPolizaAltri']) ? this.state.fields['numeroPolizaAltri'] : "-"}
                              />
                          </div>
                      </div>
                </div>

          }></GenericCard>
      </div>

    ];

    return(
        <>
            {
              (this.state.isLoaded) ?
                  <div>
                      {(this.props.modificaDatiFlag)
                        ? (formInserimentoDati)
                      : (this.props.visualizzaFlag) ? formVisualizzaInserimentoDati : formInserimentoDati}
                  </div>
              : <GenericSpinner></GenericSpinner>
            }
        </>
    )
  }
}
export declare interface InserimentoDatiPolizzaProps {
  data?: any;
  polizza?: any;
  activeTab?: any;
  tabClick?: any;
  visualizzaFlag?: any;
  polizzaInsInd?:any;
  modificaDatiFlag?: any;
  prevData?:any;
  getSelectedValue?:any;
  tabNumber?: any;
  message?: any;
  tabellaUno?: any;
  storicoFlag?: any;

}

