import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericWizard from '../../components/GenericWizard'
import GenericCard from '../../components/GenericCard'
import {
  getCodiceAtecoByIdBo,
  getFilesByIdIscrizioneBo,
  getFormaGiuridicaByIdBo,
  getIscrizioneByIdBO,
  getTipologiaSocietaByIdBo
} from 'src/processes/ProcessoIscrizione'
import { getProvinciaBySiglaBO } from 'src/processes/Province'
import GenericChips from 'src/frontend/app/components/GenericChips'
import { getRappresentanteLegaleByIdSocietaBO, getSociByIdSocietaBO } from 'src/processes/Societa'
import {
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP
} from 'src/app/utils/RoutesConstants'
import { FormDatiIscrizioneStpBO } from './formIscrizioneSTP/FormDatiIscrizioneStpBO'
import { FormDatiProfessionaliStpBO } from './formIscrizioneSTP/FormDatiProfessionaliStpBO'
import { FormDatiRappresentanteLegaleStpBO } from './formIscrizioneSTP/FormDatiRappresentanteLegaleStpBO'
import { FormDipendentiIscrittiAlboStpBO } from './formIscrizioneSTP/FormDipendentiIscrittiAlboStpBO'
import { FormElencoSociIscrittiAlboStpBO } from './formIscrizioneSTP/FormElencoSociIscrittiAlboStpBO'
import { FormDatiGeneraliStpBO } from './formIscrizioneSTP/FormDatiGeneraliStpBO'
import { FormUbicazioneContattiStpBO } from './formIscrizioneSTP/FormUbicazioneContattiStpBO'
import { FormElencoSociIscrittiAltriOrdiniStpBO } from './formIscrizioneSTP/FormElencoSociIscrittiAltriOrdiniStpBO'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { convertUrlParamsToObject } from 'src/utilities/utility'
import { FormFile } from '../iscrizione/formIscrizione/FormFile'

export class VisualizzaDatiStpBO extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            soggetto: '',
            iscrizione: '',
            datiRappresentanteLegale: '',
            message: '',
            typeMessage: "default",
            listaComposizioneSocietaria: [],
            listaFiles: []
        }
    }

    goToElencoIscritti = (redirectLink: any) => {
        this.props.history.push({
            pathname: redirectLink,
        });
    }

    async componentDidMount() {
        //recupero gli eventuali parametri dall'url
        var url = window.location.search?.split("?")[1]
        var urlParams = convertUrlParamsToObject(url)

        //recupero l'idIscrizione dall'url
        var idIscrizione = urlParams?.idIscrizione

        this.setState({ idIscrizione: idIscrizione })

        const tipologiaIscrizione = 'iscritto_stp'

        //chiamata al servizio  'getIscrizioneByIdBO' che restituisce i dati dell'iscrizione a partire dall'idIscrizione e tipologia_iscrizione
        getIscrizioneByIdBO(idIscrizione, tipologiaIscrizione).then((response: any) => {
            this.setState({ iscrizione: response.data.iscrizioneOrdineTerritorialeDTO, soggetto: response.data.soggettoDTO })

            var sedeLegale = response.data.soggettoDTO.elencoSedi.filter(sede => sede.idIscrizione.toString() === idIscrizione)[0]
            this.setState({sedeLegale})

            //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia della sede legale dello studio a partire dalla Sigla
            var siglaProvinciaSedeLegale = sedeLegale.slProvinciaStudio
            if (siglaProvinciaSedeLegale)
                getProvinciaBySiglaBO(siglaProvinciaSedeLegale).then((response: any) => {
                    this.setState({ provinciaSedeLegale: response.data.denominazioneUnitaTerritorialeSovracomunale })
                })

            //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia della sede operativa dello studio a partire dalla Sigla
            var siglaProvinciaSedeOperativa = sedeLegale.sopProvinciaStudio
            if (siglaProvinciaSedeOperativa)
                getProvinciaBySiglaBO(siglaProvinciaSedeOperativa).then((response: any) => {
                    this.setState({ provinciaSedeOperativa: response.data.denominazioneUnitaTerritorialeSovracomunale })
                })

            var idSocieta = response.data.soggettoDTO.idSoggetto
            if (idSocieta) {
                //chiamata al servizio 'getSociByIdSocieta' che restituisce la composizione societaria a partire dall'idSocieta (idSoggetto PG)
                getSociByIdSocietaBO(idSocieta).then((response: any) => {
                    this.setState({ listaComposizioneSocietaria: response.data })
                })

                //chiamata al servizio 'getRappresentanteLegaleByIdSocieta' che restituisce i dati del rappr. legale di una società
                getRappresentanteLegaleByIdSocietaBO(idSocieta).then((response: any) => {
                    this.setState({ datiRappresentanteLegale: response.data })
                })
            }

            var idFormaGiuridica = response.data.soggettoDTO.formaGiuridica
            if (idFormaGiuridica){
                //chiamata al servizio che restituisce la forma giuridica per id
                getFormaGiuridicaByIdBo(idFormaGiuridica).then((response: any) => {
                    var formaGiuridica = response.data.descrizione
                this.setState({ formaGiuridica })
                }).catch((e) => {
                })
            }

            var idCodiceAteco = response.data.soggettoDTO.codAteco
            if (idCodiceAteco){
                //chiamata al servizio che restituisce il codice ateco per id
                getCodiceAtecoByIdBo(idCodiceAteco).then((response: any) => {
                    var codiceAteco = response.data.cod + " (" + response.data.descr + ")"
                this.setState({ codiceAteco })
                }).catch((e) => {
                })
            }

            var idTipologiaSocieta = response.data.soggettoDTO.tipolSoc
            if (idTipologiaSocieta){
                //chiamata al servizio che restituisce la tipologia società per id
                getTipologiaSocietaByIdBo(idTipologiaSocieta).then((response: any) => {
                    var tipologiaSocieta = response.data.descrizione
                this.setState({ tipologiaSocieta })
                }).catch((e) => {
                })
            }
        }).catch(() => {
            setTimeout(() => {
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
            }, 1000);
        })

        //chiamata al servizio 'getFilesByIdIscrizione' che restituisce tutti i file di un'iscrizione
        if (idIscrizione) {
            getFilesByIdIscrizioneBo(idIscrizione).then((response) => {
                this.setState({ listaFiles: response.data })
            })
        }
    }

    render() {
        const { soggetto, message, typeMessage, tipologiaSocieta, codiceAteco, formaGiuridica, provinciaSedeLegale, provinciaSedeOperativa, iscrizione, sedeLegale, datiRappresentanteLegale, listaComposizioneSocietaria, listaFiles } = this.state

        const steps = [
            {
                name: 'Dati generali',
                component:
                    <>
                        <GenericCard isExpandibleCard={true} isExpanded={true} header={'Dati sul rappresentante legale'} body={<><hr></hr><br></br><FormDatiRappresentanteLegaleStpBO soggetto={soggetto} listaFiles={listaFiles} datiRappresentanteLegale={datiRappresentanteLegale} messaggio={message} /></>} />
                        <GenericCard isExpandibleCard={true} isExpanded={true} header={'Ubicazione e contatti'} body={<><hr></hr><br></br><FormUbicazioneContattiStpBO sedeLegale={sedeLegale} messaggio={message} provinciaSedeLegale={provinciaSedeLegale} provinciaSedeOperativa={provinciaSedeOperativa} /></>} />
                        <GenericCard isExpandibleCard={true} isExpanded={true} header={'Dati generali'} body={<><hr></hr><br></br><FormDatiGeneraliStpBO soggetto={soggetto} messaggio={message} codiceAteco={codiceAteco} formaGiuridica={formaGiuridica}/></>} />
                        <GenericCard isExpandibleCard={true} isExpanded={true} header={'Dati professionali'} body={<><hr></hr><br></br><FormDatiProfessionaliStpBO soggetto={soggetto} messaggio={message} tipologiaSocieta={tipologiaSocieta}/></>} />
                    </>
            },
            {
                name: 'Soci',
                component:
                    <>
                        <GenericCard isExpandibleCard={true} isExpanded={true} header={'Elenco soci iscritti all’Albo dei Dottori Agronomi e dei Dottori Forestali'} body={<><hr></hr><br></br><FormElencoSociIscrittiAlboStpBO composizioneSocietaria={listaComposizioneSocietaria} /></>} />
                        <GenericCard isExpandibleCard={true} isExpanded={true} header={'Elenco soci iscritti ad altri ordini, albi e collegi professionali o in possesso del titolo di studio abilitante'} body={<><hr></hr><br></br><FormElencoSociIscrittiAltriOrdiniStpBO composizioneSocietaria={listaComposizioneSocietaria} /></>} />
                        <GenericCard isExpandibleCard={true} isExpanded={true} header={'Dipendenti iscritti all’Albo dei Dottori Agronomi e dei Dottori Forestali'} body={<><hr></hr><br></br><FormDipendentiIscrittiAlboStpBO composizioneSocietaria={listaComposizioneSocietaria} /></>} />
                    </>
            },
            {
                name: 'Dati iscrizione',
                component: <GenericCard isExpandibleCard={true} isExpanded={true} header={'Dati di iscrizione'} body={<><hr></hr><br></br><FormDatiIscrizioneStpBO iscrizione={iscrizione} messaggio={message} /></>} />
            },
            {
                name: 'File',
                component: <GenericCard isExpandibleCard={true} isExpanded={true} header={'File'} body={<><FormFile listaFiles={listaFiles} messaggio={this.state.message} /></>} />
            },
        ];

        return (
            <>
                <PageSection>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Elenco iscritti", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI }, { label: "Visualizza dati STP", link: PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP }]} />
                        <Title headingLevel="h1" size="4xl">Visualizza dati STP</Title>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <GenericChips color={'default'} label={'Stato iscrizione: ' + (iscrizione.codificaStati ? iscrizione.codificaStati?.decoCode.toUpperCase() : "-")} />
                        </div>
                        <GenericAlert label={this.state.message} hidden={message === ""} color={typeMessage}></GenericAlert>
                        <GenericWizard steps={steps} backButtonText={'PRECEDENTE'} nextButtonText={'SUCCESSIVO'} cancelButtonText={'Annulla'} onClickCancel={() => this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)} height='auto' /><br></br>
                    </div>
                </PageSection>
            </>
        );
    }
};

export default VisualizzaDatiStpBO;
