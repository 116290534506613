import React, { useCallback, useRef, useState } from 'react'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { STATI_EVENTO_NON_A_CATALOGO_VALUES } from 'src/formazione'
import GenericInput from 'src/frontend/app/components/Form/Input'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import { createFormData, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import moment from 'moment'
import { getTipologieAttivita } from 'src/hooks/useReqOptions'
import { useHistory } from 'react-router-dom'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getDenominazioneByCodiceGruppo } from 'src/processes/SelezioneProfilo'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormApprovazione: React.FunctionComponent<FormApprovazioneProps> = ({ id, data: eventoDaApprovare }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    creditiRiconosciuti: {
      isRequired: true,
      min: {
        check: 0,
        error: 'Devi inserire un numero maggiore di 0',
      },
      transform: (value) => {
        return value.replace(',', '.')
      },
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const maxCFPeventoDaApprovare = eventoDaApprovare?.attivitaFormativaExt?.cpf_richiesti

  const submit = useCallback(
    async (e) => {
      if (parseFloat(data?.creditiRiconosciuti) <= parseFloat(maxCFPeventoDaApprovare)) {
        try {
          const operatoreCF = TokenStore.getInstance().getSpidData().codiceFiscale // codice fiscale operatore che esegue assegnazione crediti
          const specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
          const specificGroupId = TokenStore.getInstance().getCurrentAccount().accounts[specificGroup].specificGroup

          var denominazioneAgenzia = await getDenominazioneByCodiceGruppo(specificGroupId).then(async (response) => {
            if (response.data && response.status !== 404) {
              var denominazione = response.data.returnedObject
              if (
                denominazione !== undefined &&
                denominazione[0] !== undefined &&
                (denominazione[0].startsWith('Operatore STP') ||
                  denominazione[0].startsWith('Operatore formazione') ||
                  denominazione[0].startsWith('Operatore rivista') ||
                  denominazione[0].startsWith('Formatore') ||
                  denominazione[0].startsWith('Operatore ente esterno'))
              ) {
                denominazione = denominazione[0]
              }
              return denominazione
            }
          }) //restituisce la denominazione dell'azienda dell'operatore che sta assegnando i cfp

          const inputs = ['creditiRiconosciuti']
          const valid = validateFormData(formData, rules, inputs)
          const data = formData?.data
          if (!valid) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            return
          }
          setStatus(STATUS.LOADING)

          const evento = eventoDaApprovare?.attivitaFormativaExt
          if (!evento) {
            return
          }

          const iscritto = evento.soggetto
          const cf_iscritto = iscritto.cf.toUpperCase()

          const result = await getFormazioneResourceApi().msfoFormazioneRiepilogoCreditiFormativiGet(cf_iscritto, getDefaultAxiosConfig())

          const dataRes = result.data as any

          const tipologieAttivita = await getTipologieAttivita()

          const tipologieMap = {}

          for (const tipologia of tipologieAttivita) {
            tipologieMap[tipologia.id] = tipologia
          }

          const anno = evento?.data_fine === undefined || evento?.data_fine === null ? moment().get('year') : moment(evento.data_fine).get('year')

          const MAX_CFP_NON_A_CATALOGO = {}
          const MAXcreditiIndividualiPerAnnoPerTipologia = {}

          for (const ogg of dataRes) {
            if (ogg.attivitaFormativaExt && ogg.attivitaFormativaExt.anno_calc_cfp) {
              const tipologia = tipologieMap[parseInt(ogg.attivitaFormativaExt.tipo_corso)]
              const triennioAttivita = Math.floor((ogg.attivitaFormativaExt.anno_calc_cfp - 1) / 3)
              if (ogg.attivitaFormativaExt.tipologia_evento == 0 || !ogg.attivitaFormativaExt.tipologia_evento) {
                //NON A CATALOGO

                if (MAX_CFP_NON_A_CATALOGO[triennioAttivita] === undefined || MAX_CFP_NON_A_CATALOGO[triennioAttivita] === null) {
                  if (triennioAttivita === 672 || triennioAttivita === 673) {
                    MAX_CFP_NON_A_CATALOGO[triennioAttivita] = 2
                  } else {
                    MAX_CFP_NON_A_CATALOGO[triennioAttivita] = 3
                  }
                }

                MAX_CFP_NON_A_CATALOGO[triennioAttivita] = Math.max(
                  0,
                  MAX_CFP_NON_A_CATALOGO[triennioAttivita] - parseFloat(ogg.attivitaFormativaExt.cfp_validi)
                )
              } else {
                //INDIVIDUALE
                const annoMovimento = ogg.attivitaFormativaExt.anno_calc_cfp

                if (!MAXcreditiIndividualiPerAnnoPerTipologia[annoMovimento]) {
                  MAXcreditiIndividualiPerAnnoPerTipologia[annoMovimento] = {}
                }

                if (!MAXcreditiIndividualiPerAnnoPerTipologia[annoMovimento][tipologia?.cod_macro_area]) {
                  MAXcreditiIndividualiPerAnnoPerTipologia[annoMovimento][tipologia?.cod_macro_area] = tipologia?.max_cfp_anno
                }
                MAXcreditiIndividualiPerAnnoPerTipologia[annoMovimento][tipologia?.cod_macro_area] = Math.max(
                  0,
                  MAXcreditiIndividualiPerAnnoPerTipologia[annoMovimento][tipologia?.cod_macro_area] - parseFloat(ogg.attivitaFormativaExt.cfp_validi)
                )
              }
            }
          }

          let cfp_validi: any
          let max_cfp_validi: any
          let lim_non_a_cat: any

          if (eventoDaApprovare?.tipologiaAttivita?.um == 'E') {
            max_cfp_validi = eventoDaApprovare?.tipologiaAttivita?.max_cfp_evt
          } else if (eventoDaApprovare?.tipologiaAttivita?.cod_macro_area === 'MREL') {
            const totOre = eventoDaApprovare?.attivitaFormativaExt?.durata
            if (totOre && totOre.indexOf(':') > 0) {
              const ore = parseInt(totOre.split(':')[0])
              const minuti = parseInt(totOre.split(':')[1])
              if (minuti == 0) {
                max_cfp_validi = ore * eventoDaApprovare?.tipologiaAttivita?.ore_evt_battute * eventoDaApprovare?.tipologiaAttivita?.max_cfp_evt
              } else {
                max_cfp_validi = (ore + 1) * eventoDaApprovare?.tipologiaAttivita?.ore_evt_battute * eventoDaApprovare?.tipologiaAttivita?.max_cfp_evt
              }
              if (max_cfp_validi > eventoDaApprovare?.tipologiaAttivita?.max_cfp_anno) {
                max_cfp_validi = eventoDaApprovare?.tipologiaAttivita?.max_cfp_anno
              }
            }
          } else {
            max_cfp_validi = eventoDaApprovare?.attivitaFormativaExt?.cpf_richiesti
          }

          if (eventoDaApprovare?.attivitaFormativaExt?.tipologia_evento == 1) {
            // EVENTO DA APPROVARE = INDIVIDUALE
            if (
              !MAXcreditiIndividualiPerAnnoPerTipologia[anno] ||
              MAXcreditiIndividualiPerAnnoPerTipologia[anno][eventoDaApprovare?.tipologiaAttivita?.cod_macro_area] != 0
            ) {
              if (parseFloat(data?.creditiRiconosciuti) <= max_cfp_validi) {
                cfp_validi = parseFloat(data?.creditiRiconosciuti)
              } else {
                cfp_validi = max_cfp_validi
              }
            } else {
              if (MAXcreditiIndividualiPerAnnoPerTipologia[anno][eventoDaApprovare?.tipologiaAttivita?.cod_macro_area] >= max_cfp_validi) {
                if (parseFloat(data?.creditiRiconosciuti) <= max_cfp_validi) {
                  cfp_validi = parseFloat(data?.creditiRiconosciuti)
                } else {
                  cfp_validi = max_cfp_validi
                }
              } else {
                if (
                  MAXcreditiIndividualiPerAnnoPerTipologia[anno][eventoDaApprovare?.tipologiaAttivita?.cod_macro_area] >= parseFloat(data?.creditiRiconosciuti)
                ) {
                  cfp_validi = parseFloat(data?.creditiRiconosciuti)
                } else {
                  cfp_validi = MAXcreditiIndividualiPerAnnoPerTipologia[anno][eventoDaApprovare?.tipologiaAttivita?.cod_macro_area]
                }
              }
            }
          } else {
            // EVENTO DA APPROVARE = NON A CATALOGO
            const triennioRif = Math.floor((anno - 1) / 3)
            if (triennioRif === 672 || triennioRif === 673) {
              lim_non_a_cat = 2
            } else {
              lim_non_a_cat = 3
            }

            if (!MAX_CFP_NON_A_CATALOGO[triennioRif] && MAX_CFP_NON_A_CATALOGO[triennioRif] != 0) {
              if (parseFloat(data?.creditiRiconosciuti) <= lim_non_a_cat) {
                if (parseFloat(data?.creditiRiconosciuti) <= max_cfp_validi) {
                  cfp_validi = parseFloat(data?.creditiRiconosciuti)
                } else {
                  cfp_validi = max_cfp_validi
                }
              } else {
                cfp_validi = lim_non_a_cat
              }
            } else {
              if (MAX_CFP_NON_A_CATALOGO[triennioRif] >= parseFloat(data?.creditiRiconosciuti)) {
                if (parseFloat(data?.creditiRiconosciuti) <= max_cfp_validi) {
                  cfp_validi = parseFloat(data?.creditiRiconosciuti)
                } else {
                  cfp_validi = max_cfp_validi
                }
              } else {
                cfp_validi = MAX_CFP_NON_A_CATALOGO[triennioRif]
              }
            }
          }

          await getFormazioneResourceApi().msfoFormazioneAggiornaEventoNonACatalogoPatch(
            {
              id: id,
              stato: STATI_EVENTO_NON_A_CATALOGO_VALUES.ACCREDITATO,
              cpf_riconosciuti: data?.creditiRiconosciuti,
              cfp_validi: cfp_validi,
              anno_calc_cfp: anno,
              chi: operatoreCF + ' - ' + denominazioneAgenzia,
            } as any,
            getDefaultAxiosConfig()
          )
          setStatus(STATUS.SUCCESS)
          setAlertMessage('Dati salvati con successo')
          setTimeout(() => {
            history.goBack()
          }, 500)
        } catch (e) {
          setStatus(STATUS.FAILURE)
          setAlertMessage('Impossibile salvare i dati')
        }
      } else {
        setStatus(STATUS.FAILURE)
        setAlertMessage('I CFP riconosciuti superano il limite massimo')
      }
    },
    [id, formData]
  )

  return (
    <FormSubmit title="Approvazione evento" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <div className="row">
        <div className={isCurrentMobileDevice() ? null : 'col-3'}>
          <GenericInput
            id="creditiRiconosciuti"
            type={'number'}
            placeholder={'CFP riconosciuti'}
            label={'CFP riconosciuti*'}
            value={data?.creditiRiconosciuti}
            errorMessage={errors?.creditiRiconosciuti}
            onInit={onInitInput(0)}
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="row">
        <div className={isCurrentMobileDevice() ? null : 'col-3'}>
          <p>CFP Riconosciuti dall'evento: {maxCFPeventoDaApprovare}</p>
        </div>
      </div>
    </FormSubmit>
  )
}

interface FormApprovazioneProps {
  id: any
  data: any
}

export default FormApprovazione
