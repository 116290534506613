import * as React from 'react'
import { Form, FormGroup } from '@patternfly/react-core'
import { AxiosRequestConfig } from 'axios'
import GenericInput from 'src/frontend/app/components/GenericInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericCard from 'src/backoffice/app/components/GenericCard'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export const AXIOS_CONFIG_CUSTOM: AxiosRequestConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json"
  }
}

export class FormDatiPolizzaIndividuale extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: props.activeTab,
            tabClick: props.tabClick,
            prevData: props.prevData,
            prevClicked: props.prevClicked,
            polizza: props.data,
            polizzaInsInd:props.dataInsInd,
            visualizzaFlag:props.visualizzaFlag,
            modificaDatiFlag:props.modificaDatiFlag,
            fields: (this.props.prevData) ? this.props.prevData : {},
            errors: {},
            isLoaded : false,
            studioAssociatoFlag: true,
            congedoMaternitaPaternita: false,
            getSelectedValue:props.getSelectedValue
          };
    }


    async componentDidMount(): Promise<void> {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        if(this.props.data) {
                //Dati provenienti dal servizio
                let obj: any = this.props.data;
                obj.retroattivitaIllimitata = {label: (obj.retroattivitaIllimitata) ? 'Si' : 'No', value: (obj.retroattivitaIllimitata) ? 'Si' : 'No'};
                obj.danniPatrimoniali = {label: (obj.danniPatrimoniali) ? 'Si' : 'No', value: (obj.danniPatrimoniali) ? 'Si' : 'No'};
                obj.coperturaPostuma = {label: (obj.coperturaPostuma) ? 'Si' : 'No', value: (obj.coperturaPostuma) ? 'Si' : 'No'};
                if(this.props.storicoFlag) {

                    obj.periodoValDaAltri = obj?.periodoValiditaInizio.split('T')[0] ? obj.periodoValiditaInizio.split('T')[0] : '-';
                    obj.periodoValAAltri = obj?.periodoValiditaFine.split('T')[0] ? obj?.periodoValiditaFine.split('T')[0] : '-';
                }


                await this.setState({fields : obj})

                await this.setState({isLoaded: true})
        }



    }


    render() {

        return (

            <>
                {this.state.isLoaded ?
                 <div>
                 <Form id="formInserimentoDati" className="row" hidden={(!this.state.fields)}>
                    <h2 className='stepTitle'>Dati Polizza Individuale</h2>
                    <GenericCard className="cardStyle" key={'formInserimentoDati'} header="Inserimento Dati Polizza Individuale" body={
                        <div className='pt-3'>
                                <div className="row">
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled label='Copre i danni patrimoniali e non ? ' placeholder='Si / No'
                                        value={(this.state.fields['danniPatrimoniali']) ? this.state.fields['danniPatrimoniali'].label : '-'}
                                    />
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled label='Ha una retroattività illimitata? ' placeholder='Si / No'
                                        value={(this.state.fields['retroattivitaIllimitata']) ? this.state.fields['retroattivitaIllimitata'].label : '-'}
                                    />
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled label='Prevede copertura postuma decennale causa cessazione? ' placeholder='Si / No'
                                        value={(this.state.fields['coperturaPostuma']) ? this.state.fields['coperturaPostuma'].label : '-'}
                                    />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled key="nomeCompagniaAltri" id="nomeCompagniaAltri" name={'nomeCompagniaAltri'} value={(this.state.fields['nomeCompagniaAltri']) ? this.state.fields['nomeCompagniaAltri'] : "-"}
                                                      label='Compagnia Assicurativa ' placeholder='Inserire compagnia assicurativa'  />
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled key={'valoreRischioAltri'} id="valoreRischioAltri" name={'valoreRischioAltri'} label="Valore del rischio"
                                        value={(this.state.fields['valoreRischioAltri']) ? this.state.fields['valoreRischioAltri'] : "-"}   />
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled key={'massimaleAltri'} id="massimaleAltri" name={'massimaleAltri'} label="Massimale"
                                        value={(this.state.fields['massimaleAltri']) ? this.state.fields['massimaleAltri'] : "-"}   />
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled key={'premioAltri'} id="premioAltri" name={'premioAltri'} label="Premio (€ es. 10,00)"
                                        value={(this.state.fields['premioAltri']) ? this.state.fields['premioAltri'] : "-"}
                                        />
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup>
                                        <GenericInput isDisabled label='Data di inizio validità' placeholder='Selezionare data' key="periodoValDaAltri"
                                         value={(this.state.fields['periodoValDaAltri']) ? this.state.fields['periodoValDaAltri'].split('T')[0] : null} />
                                    </FormGroup>
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}><FormGroup>
                                        <GenericInput isDisabled label='Data di fine validità' placeholder='Selezionare data' key="periodoValAAltri"
                                         value={(this.state.fields['periodoValAAltri']) ? this.state.fields['periodoValAAltri'].split('T')[0] : null}/>
                                    </FormGroup>
                                    </div>
                                    <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                        <GenericInput isDisabled key={'numeroPolizaAltri'} id="numeroPolizaAltri" name={'numeroPolizaAltri'} label="Numero Polizza " placeholder='Inserire numero polizza'
                                        value={(this.state.fields['numeroPolizaAltri']) ? this.state.fields['numeroPolizaAltri'] : "-"}
                                        />
                                    </div>
                                </div>
                        </div>

                    }></GenericCard>
                </Form>
            </div> : <GenericSpinner/>}
            </>
        )
    }
}

export declare interface GeneralFormDatiPolizzaIndividualeProps {
    data?: any;
    polizza?: any;
    activeTab?: any;
    tabClick?: any;
    visualizzaFlag?: any;
    polizzaInsInd?:any;
    modificaDatiFlag?: any;
    prevData?:any;
    getSelectedValue?:any;
    tabNumber?: any;
    message?: any;
    tabellaUno?: any;
}
