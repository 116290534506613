import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { PATH_TO_FO_FEEDBACK_ACCESSIBILITA } from 'src/app/utils/RoutesConstants'
import { AppCostants } from 'src/app/utils/AppConstant'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const title = 'Meccanismo di feedback accessibilità'

var breadcrumbOptions = [
    { label: "Feedback Accessibilità", link: PATH_TO_FO_FEEDBACK_ACCESSIBILITA }
]

class FeedbackAccessibilita extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default"
        };
    }

    componentDidMount() {}

    render() {
        return (
            <div className='row'>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                        <Title headingLevel='h1'>{title}</Title>
                        <div>
                            <p>
                                <img src={AppCostants.PUBLIC_SHARE + "/pec/Conaf_mark.jpg"} alt="Logo Conaf" width="300" height="150"/>
                            </p>
                            <p>
                                <h2>Informazioni sul meccanismo di feedback di accessibilità del sito web https://www.sidafonline.it</h2>
                                <p>Il CONAF si impegna a rendere i propri siti web accessibili, conformemente alle "LINEE GUIDA SULL’ACCESSIBILITÀ DEGLI STRUMENTI INFORMATICI".</p>
                                <br></br>
                                <p>Per inviare segnalazioni su casi di mancata conformità ai requisiti di accessibilità scrivere alla casella di posta elettronica <b>barbara.bruni@conaf.it</b>.</p>
                                <br></br>
                                <p>Nella e-mail è necessario indicare come oggetto <b>"Segnalazione mancata conformità ai requisiti di accessibilità dei siti web del CONAF"</b>. </p>
                                <p>Inserire inoltre: </p>
                                <p>
                                        <li>Nome e cognome</li>
                                        <li>Indirizzo e-mail</li>
                                        <li>Url della pagina web oggetto della segnalazione</li>
                                        <li>Descrizione chiara e sintetica del problema riscontrato</li>
                                        <li>Strumenti utilizzati (Sistema operativo, browser, tecnologie assistive)</li>
                                </p>
                            </p>

                            <br></br>
                        </div>
                    </div>
                </PageSection>
            </div>
        );
    }
};

export default FeedbackAccessibilita;
