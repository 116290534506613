import { getDefaultAxiosConfig, getStudioAssociatoResourceApi } from 'src/app/utils/ManagerRestGateway'
import { ErrorStore } from 'src/errorBoundary/ErrorStore'

/**
 * Post - recupero lista studi associati by id soggetto
 * @returns
 * @param provincia
 */
export async function getStudiAssociatiByIdSoggetto(idSoggetto: any){
  try {

    let response = await (await getStudioAssociatoResourceApi().msgaAnagrafeStudiAssociatiRecuperaCollaborazioniPost(idSoggetto, getDefaultAxiosConfig()));

    return ErrorStore.getInstance().checkResponse(response);
  } catch (err: any) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err.message, 500)
    console.error("Errore durante la chiamata getStudiAssociatiByIdSoggetto" + err);
    throw new Error(errorMessage.message);
  }
}

/**
 * Post - recupero studio associato by CF / Piva
 * @returns
 * @param provincia
 */
export async function getStudioAssociatoByPIva(cfPiva: any){
  try {
    let param: any = {
      "param": cfPiva
    }
    let response = await (await getStudioAssociatoResourceApi().msgaAnagrafeStudiAssociatiRecuperaStudioAssociatoByCfPivaPost(param, getDefaultAxiosConfig()));
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err: any) {
    throw new Error(err.message);
  }
}

/**
 * POST Recupero studio associato by Id
 * @returns
 * @param idStudioAssociato
 */
export async function getStudioAssociatoById(idStudioAssociato: any){
  try {
    let param = {
      "param": idStudioAssociato
    }
    let response = await getStudioAssociatoResourceApi().msgaAnagrafeStudiAssociatiGetStudioAssociatoByIdPost(param,getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err: any) {
    console.error("Errore durante la chiamata getStudioAssociatoById" + err);
    throw new Error(err.message);
  }
}


/**
 * POST Creazione nuovo studio associato
 * @returns
 * @param provincia
 */
export async function creaNuovoStudioAssociato(idSoggetto: any, studioAssociato: any){
  try {
    const bodyRequest  = {
      "idSoggetto": idSoggetto,
      "studioAssociatoDTO": {
        "cfPiva": studioAssociato.cfPiva ? studioAssociato.cfPiva : "",
        "ragioneSociale": studioAssociato.ragioneSociale ? studioAssociato.ragioneSociale : "",
        "provincia": studioAssociato.provincia ? studioAssociato.provincia : "",
        "comune": studioAssociato.comune ? studioAssociato.comune : "",
        "indirizzo": studioAssociato.indirizzo ? studioAssociato.indirizzo : "",
        "cap": studioAssociato.cap ? studioAssociato.cap : "",

      }
    }

    let response = await (await getStudioAssociatoResourceApi().msgaAnagrafeStudiAssociatiNuovaCollaborazionePost(bodyRequest, getDefaultAxiosConfig()));

    return ErrorStore.getInstance().checkResponse(response);
  } catch (err: any) {

    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, err.response?.status ? err.response.status : 400)
    console.error("Errore durante la chiamata getStudiAssociatiByIdSoggetto" + err);

    throw new Error(errorMessage.message);
  }
}

/**
 * Post - Eliminazione di uno studio associato ad un soggetto
 * @returns
 * @param provincia
 */
export async function cancellaStudioAssociatoAlSoggetto(idSoggetto: any, idStudioAssociato: any){
  try {
    const bodyRequest: any = {
      idSoggetto: idSoggetto,
      studioAssociatoDTO: {
        "idStudioAssociato": idStudioAssociato,
      }
    }
    let response = await (await getStudioAssociatoResourceApi().msgaAnagrafeStudiAssociatiCancellaCollaborazionePost(bodyRequest, getDefaultAxiosConfig()));

    return response.data.returnedObject;
    // return ErrorStore.getInstance().checkResponse(response);
  } catch (err: any) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err.message, 500)
    console.error("Errore durante la chiamata getStudiAssociatiByIdSoggetto" + err);
    throw new Error(errorMessage.message);
  }
}
