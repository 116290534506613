import { getValueAtPath } from 'src/utilities/utility'

const initStates = (VALUES, LABELS) => Object.assign({}, ...Object.keys(VALUES).map((key) => ({ [VALUES[key]]: LABELS[key] })))
const initStatesOptions = (VALUES, LABELS) => Object.keys(VALUES).map((key) => ({ value: VALUES[key], label: LABELS[key] }))
const initStateOptionFromValues = (VALUES, LABELS) =>
  Object.assign({}, ...Object.keys(VALUES).map((key) => ({ [VALUES[key]]: { label: LABELS[key], value: VALUES[key] } })))

export const DETAILS_ACTIONS = {
  DETTAGLIO: 'dettaglio',
  MODIFICA: 'modifica',
  CLONA: 'clona',
  RICHIESTA_INTEG: 'integrazioni',
  APPROVA: 'approvazione',
  APPROVAZIONE_FORMALE: 'approvazione-formale',
  APPROVAZIONE_MERITO: 'approvazione-merito',
  APPROVAZIONE_MINISTERIALE: 'approvazione-ministeriale',
  RIGETTA: 'rigetto',
  ANNULLA: 'annullamento',
  ISCRIVI: 'iscrizione',
  CANCELLA_ISCRIZIONE: 'cancella-iscrizione',
  PROROGA: 'proroga',
}

export const STATI_ENTI_LABELS = {
  ACCREDITATA: 'Accreditata',
  CANCELLATA: 'Cancellata',
  SCADUTA: 'Scaduta',
}
export const STATI_ENTI_VALUES = {
  ACCREDITATA: 6, // accreditata (approvazione ministeriale)
  CANCELLATA: 10, // cancellata
  SCADUTA: 98, // scaduta
}

export const STATI_ENTI = initStates(STATI_ENTI_VALUES, STATI_ENTI_LABELS)
export const STATI_ENTI_OPTIONS = initStatesOptions(STATI_ENTI_VALUES, STATI_ENTI_LABELS)
export const STATI_ENTI_OPTION_FROM_VALUES = initStateOptionFromValues(STATI_ENTI_VALUES, STATI_ENTI_LABELS)

export const TIPI_TITOLO_DI_STUDIO = [
  {
    label: 'Master',
    value: 0,
  },
  {
    label: 'Dottorato',
    value: 1,
  },
  {
    label: 'Specializzazione',
    value: 2,
  },
]

/* export const TIPO_ALLEGATO_RIVISTE = {

} */

export const STATI_AGENZIE_LABELS = {
  IN_COMPILAZIONE: 'In fase di compilazione',
  ISTRUTTORIA: 'In valutazione Formale',
  FORMALE: 'In valutazione di Merito',
  MERITO: 'In valutazione Ministeriale',
  INTEGRAZIONE: 'Richiesta integrazioni',
  // MINISTERIALE: 'Parere positivo del Min. di Giustizia',
  // NO_MINISTERIALE: 'Parere negativo del Min. di Giustizia',
  ACCREDITATA: 'Accreditata',
  NO_ACCREDITATA: 'Rigettata',
  // NO_MERITO: 'Parere negativo CONAF trasmesso al Min. di Giustizia',
  REVOCATA: 'Revocata',
  SCADUTA: 'Scaduta',
}
export const STATI_AGENZIE_VALUES = {
  IN_COMPILAZIONE: 0, // in patch
  ISTRUTTORIA: 1, // salvataggio
  FORMALE: 9, // approvazione formale
  MERITO: 2, // approvazione merito
  INTEGRAZIONE: 3,
  // MINISTERIALE: 4, // approvazione ministeriale
  // NO_MINISTERIALE: 5, // rigetto in stato MERITO (2)
  ACCREDITATA: 6, // accreditata (approvazione ministeriale) 
  NO_ACCREDITATA: 7, // rigetto in stato ISTRUTTORIA (1)
  // NO_MERITO: 8, // rigetto in stato FORMALE (9)
  REVOCATA: 10,
  SCADUTA: 11, // agenzia la quale ha l'accreditamento scaduto
}
export const STATI_AGENZIE = initStates(STATI_AGENZIE_VALUES, STATI_AGENZIE_LABELS)
export const STATI_AGENZIE_OPTIONS = initStatesOptions(STATI_AGENZIE_VALUES, STATI_AGENZIE_LABELS)
export const STATI_AGENZIE_OPTION_FROM_VALUES = initStateOptionFromValues(STATI_AGENZIE_VALUES, STATI_AGENZIE_LABELS)

export const STATI_EVENTO_CATALOGO_LABELS = {
  RICHIESTA: 'In valutazione',
  ACCREDITATO: 'Approvato',
  RIFIUTATO: 'Rigettato',
  RICHIESTA_INTEG: 'Richiesta Integrazioni',
  ANNULLATO: 'Annullato',
  // DUPLICATA: 'Duplicato',
}
export const STATI_EVENTO_CATALOGO_VALUES = {
  RICHIESTA: 0,
  ACCREDITATO: 1,
  RIFIUTATO: 3,
  RICHIESTA_INTEG: 4,
  ANNULLATO: 5,
  // DUPLICATA: 99,
}
export const STATI_EVENTO_CATALOGO = initStates(STATI_EVENTO_CATALOGO_VALUES, STATI_EVENTO_CATALOGO_LABELS)
export const STATI_EVENTO_CATALOGO_OPTIONS = initStatesOptions(STATI_EVENTO_CATALOGO_VALUES, STATI_EVENTO_CATALOGO_LABELS)
export const STATI_EVENTO_CATALOGO_OPTION_FROM_VALUES = initStateOptionFromValues(STATI_EVENTO_CATALOGO_VALUES, STATI_EVENTO_CATALOGO_LABELS)

export const STATI_EVENTO_NON_A_CATALOGO_LABELS = {
  RICHIESTA: 'In valutazione',
  ACCREDITATO: 'Approvato',
  RICHIESTA_INTEG: 'Richiesta Integrazioni',
  RIFIUTATO: 'Rigettato',
  ANNULLATO: 'Annullato',
  NON_COMPLETA: 'In compilazione',
  // DUPLICATA: 'Duplicato',
}
export const STATI_EVENTO_NON_A_CATALOGO_VALUES = {
  NON_COMPLETA: 0,
  RICHIESTA: 1,
  ACCREDITATO: 2,
  RIFIUTATO: 3,
  RICHIESTA_INTEG: 4,
  ANNULLATO: 5,
  // DUPLICATA: 99,
}
export const STATI_EVENTO_NON_A_CATALOGO = initStates(STATI_EVENTO_NON_A_CATALOGO_VALUES, STATI_EVENTO_NON_A_CATALOGO_LABELS)
export const STATI_EVENTO_NON_A_CATALOGO_OPTIONS = initStatesOptions(STATI_EVENTO_NON_A_CATALOGO_VALUES, STATI_EVENTO_NON_A_CATALOGO_LABELS)
export const STATI_EVENTO_NON_A_CATALOGO_OPTION_FROM_VALUES = initStateOptionFromValues(STATI_EVENTO_NON_A_CATALOGO_VALUES, STATI_EVENTO_NON_A_CATALOGO_LABELS)

export const MODALITA_DISPONIBILI_LABELS = {
  IN_SITU: 'In Situ',
  FAD_SINCRONO: 'FAD sincrono',
  FAD_ASINCRONO: 'FAD asincrono',
  MISTA: 'Mista',
}
export const MODALITA_DISPONIBILI_VALUES = {
  IN_SITU: 'IN_SITU',
  FAD_SINCRONO: 'FAD_SINCRONO',
  FAD_ASINCRONO: 'FAD_ASINCRONO',
  MISTA: 'MISTA',
}
export const MODALITA_DISPONIBILI = initStates(MODALITA_DISPONIBILI_VALUES, MODALITA_DISPONIBILI_LABELS)
export const MODALITA_DISPONIBILI_OPTIONS = initStatesOptions(MODALITA_DISPONIBILI_VALUES, MODALITA_DISPONIBILI_LABELS)
export const MODALITA_DISPONIBILI_OPTION_FROM_VALUES = initStateOptionFromValues(MODALITA_DISPONIBILI_VALUES, MODALITA_DISPONIBILI_LABELS)

export const TIPOLOGIA_EVENTO_LABELS = {
  NON_CATALOGO: 'Non a catalogo',
  INDIVIDUALE: 'Individuale',
}
export const TIPOLOGIA_EVENTO_VALUES = {
  NON_CATALOGO: 0,
  INDIVIDUALE: 1,
}
export const TIPOLOGIA_EVENTO = initStates(TIPOLOGIA_EVENTO_VALUES, TIPOLOGIA_EVENTO_LABELS)
export const TIPOLOGIA_EVENTO_OPTIONS = initStatesOptions(TIPOLOGIA_EVENTO_VALUES, TIPOLOGIA_EVENTO_LABELS)
export const TIPOLOGIA_EVENTO_OPTION_FROM_VALUES = initStateOptionFromValues(TIPOLOGIA_EVENTO_VALUES, TIPOLOGIA_EVENTO_LABELS)

export const TIPOLOGIE_ACCREDITAMENTO_LABELS = {
  TOTALE: 'Totale',
  COMPLESSO: 'Complesso',
  SEMPLICE: 'Semplice',
}
export const TIPOLOGIE_ACCREDITAMENTO_VALUES = {
  TOTALE: 1,
  COMPLESSO: 2,
  SEMPLICE: 3,
}
export const TIPOLOGIE_ACCREDITAMENTO = initStates(TIPOLOGIE_ACCREDITAMENTO_VALUES, TIPOLOGIE_ACCREDITAMENTO_LABELS)
export const TIPOLOGIE_ACCREDITAMENTO_OPTIONS = initStatesOptions(TIPOLOGIE_ACCREDITAMENTO_VALUES, TIPOLOGIE_ACCREDITAMENTO_LABELS)
export const TIPOLOGIE_ACCREDITAMENTO_OPTION_FROM_VALUES = initStateOptionFromValues(TIPOLOGIE_ACCREDITAMENTO_VALUES, TIPOLOGIE_ACCREDITAMENTO_LABELS)

export const TIPOLOGIE_FORMAZIONE_LABELS = {
  INIZIALE: 'Iniziale',
  CONTINUATIVA: 'Continuativa',
  REGIONALE: 'Regionale',
  LOCALE: 'Locale',
}
export const TIPOLOGIE_FORMAZIONE_VALUES = {
  INIZIALE: 0,
  CONTINUATIVA: 1,
  REGIONALE: 2,
  LOCALE: 3,
}
export const TIPOLOGIE_FORMAZIONE = initStates(TIPOLOGIE_FORMAZIONE_VALUES, TIPOLOGIE_FORMAZIONE_LABELS)
export const TIPOLOGIE_FORMAZIONE_OPTIONS = initStatesOptions(TIPOLOGIE_FORMAZIONE_VALUES, TIPOLOGIE_FORMAZIONE_LABELS)
export const TIPOLOGIE_FORMAZIONE_OPTION_FROM_VALUES = initStateOptionFromValues(TIPOLOGIE_FORMAZIONE_VALUES, TIPOLOGIE_FORMAZIONE_LABELS)

export const TIPOLOGIE_ISCRIZIONI_LABELS = {
  UNIVERSITARI: 'Formatori universitari e della ricerca ',
  ALBI: 'Formatori iscritti ad albi professionali',
  ALTRO: 'Formatori appartenenti ad altre categorie',
}
export const TIPOLOGIE_ISCRIZIONI_VALUES = {
  UNIVERSITARI: 0,
  ALBI: 1,
  ALTRO: 2,
}
export const TIPOLOGIE_ISCRIZIONI = initStates(TIPOLOGIE_ISCRIZIONI_VALUES, TIPOLOGIE_ISCRIZIONI_LABELS)
export const TIPOLOGIE_ISCRIZIONI_OPTIONS = initStatesOptions(TIPOLOGIE_ISCRIZIONI_VALUES, TIPOLOGIE_ISCRIZIONI_LABELS)
export const TIPOLOGIE_ISCRIZIONI_OPTION_FROM_VALUES = initStateOptionFromValues(TIPOLOGIE_ISCRIZIONI_VALUES, TIPOLOGIE_ISCRIZIONI_LABELS)

export const AMBITI_TERRITORIALI_LABELS = {
  NAZIONALE: 'Nazionale',
  INTERREGIONALE: 'Interregionale',
  REGIONALE: 'Regionale',
  LOCALE: 'Locale',
  INTERNAZIONALE: 'Internazionale',
}
export const AMBITI_TERRITORIALI_VALUES = {
  NAZIONALE: 0,
  INTERREGIONALE: 1,
  REGIONALE: 2,
  LOCALE: 3,
  INTERNAZIONALE: 4,
}
export const AMBITI_TERRITORIALI = initStates(AMBITI_TERRITORIALI_VALUES, AMBITI_TERRITORIALI_LABELS)
export const AMBITI_TERRITORIALI_OPTIONS = initStatesOptions(AMBITI_TERRITORIALI_VALUES, AMBITI_TERRITORIALI_LABELS)
export const AMBITI_TERRITORIALI_OPTION_FROM_VALUES = initStateOptionFromValues(AMBITI_TERRITORIALI_VALUES, AMBITI_TERRITORIALI_LABELS)

export const AMBITI_PUBBLICAZIONE_RIVISTE_LABELS = {
  REGIONALE: 'Regionale',
  NAZIONALE: 'Nazionale',
  INTERNAZIONALE: 'Internazionale',
}
export const AMBITI_PUBBLICAZIONE_RIVISTE_VALUES = {
  REGIONALE: 0,
  NAZIONALE: 1,
  INTERNAZIONALE: 2,
}
export const AMBITI_PUBBLICAZIONE_RIVISTE = initStates(AMBITI_PUBBLICAZIONE_RIVISTE_VALUES, AMBITI_PUBBLICAZIONE_RIVISTE_LABELS)
export const AMBITI_PUBBLICAZIONE_RIVISTE_OPTIONS = initStatesOptions(AMBITI_PUBBLICAZIONE_RIVISTE_VALUES, AMBITI_PUBBLICAZIONE_RIVISTE_LABELS)
export const AMBITI_PUBBLICAZIONE_RIVISTE_OPTION_FROM_VALUES = initStateOptionFromValues(
  AMBITI_PUBBLICAZIONE_RIVISTE_VALUES,
  AMBITI_PUBBLICAZIONE_RIVISTE_LABELS
)
export const AMBITI_PUBBLICAZIONE_LABELS = {
  REGIONALE: 'Regionale',
  NAZIONALE: 'Nazionale',
  INTERREGIONALE: 'Interregionale',
}
export const AMBITI_PUBBLICAZIONE_VALUES = {
  REGIONALE: 0,
  NAZIONALE: 1,
  INTERREGIONALE: 2,
}
export const AMBITI_PUBBLICAZIONE = initStates(AMBITI_PUBBLICAZIONE_VALUES, AMBITI_PUBBLICAZIONE_LABELS)
export const AMBITI_PUBBLICAZIONE_OPTIONS = initStatesOptions(AMBITI_PUBBLICAZIONE_VALUES, AMBITI_PUBBLICAZIONE_LABELS)
export const AMBITI_PUBBLICAZIONE_OPTION_FROM_VALUES = initStateOptionFromValues(AMBITI_PUBBLICAZIONE_VALUES, AMBITI_PUBBLICAZIONE_LABELS)

export const TIPOLOGIE_RIVISTA_LABELS = {
  CARTACEA: 'Cartacea',
  ELETTRONICA: 'Elettronica',
  MISTA: 'Cartacea ed Elettronica',
}
export const TIPOLOGIE_RIVISTA_VALUES = {
  CARTACEA: 0,
  ELETTRONICA: 1,
  MISTA: 2,
}
export const TIPOLOGIE_RIVISTA = initStates(TIPOLOGIE_RIVISTA_VALUES, TIPOLOGIE_RIVISTA_LABELS)
export const TIPOLOGIE_RIVISTA_OPTIONS = initStatesOptions(TIPOLOGIE_RIVISTA_VALUES, TIPOLOGIE_RIVISTA_LABELS)
export const TIPOLOGIE_RIVISTA_OPTION_FROM_VALUES = initStateOptionFromValues(TIPOLOGIE_RIVISTA_VALUES, TIPOLOGIE_RIVISTA_LABELS)

export const isInvalidAllegati = (allegati: any, data: any) =>
  allegati?.some((a) => {
    const value = getValueAtPath(data, a?.id)
    if (!value) return true
    if (Array.isArray(value)) return value?.filter((v) => v)?.length === 0
    return !value?.allegatoDTO?.contentBase64
  })

export const ALLEGATI_AGENZIA_FILTERED = (data, objKey = '', isIntegrazioni?: boolean) => {
  if (objKey) objKey = `${objKey}.`

  let allegati: any = []

  if (isIntegrazioni) {
    allegati = [
      {
        id: `${objKey}statutoAgenzia`,
        label: 'Statuto dell’Agenzia *',
        type: 'allegato_statuto',
        accept: ['application/pdf'],
        extensions: ['pdf'],
      },
      {
        id: `${objKey}bilanci`,
        label: 'Ultimi tre bilanci *',
        type: 'allegato_bilanci',
        isMulti: true,
      },
      {
        id: `${objKey}ricevutaPagamento`,
        label: 'Ricevuta di pagamento *',
        type: 'ricevuta_pagamento',
        accept: ['application/pdf'],
        extensions: ['pdf'],
      },
      // {
      //   id: `${objKey}fad`,
      //   label: 'Descrizione piattaforma FAD *',
      //   type: 'descrizione_piattaforma_fad',
      //   accept: ['pdf'],
      //   extensions: ['pdf'],
      // },
    ]
  } else {
    allegati = [
      {
        id: `${objKey}statutoAgenzia`,
        label: 'Statuto dell’Agenzia *',
        type: 'allegato_statuto',
        accept: ['application/pdf'],
        extensions: ['pdf'],
      },
      {
        id: `${objKey}bilanci`,
        label: 'Ultimi tre bilanci *',
        type: 'allegato_bilanci',
        isMulti: true,
      },
      {
        id: `${objKey}cdg`,
        label: 'Moduli C, D e G  *',
        type: 'moduli_cdg',
        accept: ['application/pdf'],
        extensions: ['pdf'],
        isMulti: true,
      },
      {
        id: `${objKey}modulo_e`,
        label: 'Modulo E *',
        type: 'moduli_e',
        accept: ['application/pdf'],
        extensions: ['pdf'],
      },
      {
        id: `${objKey}ff1f2`,
        label: 'Moduli F, F1 e F2 *',
        type: 'moduli_f',
        accept: ['application/pdf'],
        extensions: ['pdf'],
        isMulti: true,
      },
      {
        id: `${objKey}adesione`,
        label: 'Richiesta di adesione *',
        type: 'richiesta_adesione',
        accept: ['application/pdf'],
        extensions: ['pdf'],
      },
      {
        id: `${objKey}ricevutaPagamento`,
        label: 'Ricevuta di pagamento *',
        type: 'ricevuta_pagamento',
        accept: ['application/pdf'],
        extensions: ['pdf'],
      },
      // {
      //   id: `${objKey}fad`,
      //   label: 'Descrizione piattaforma FAD *',
      //   type: 'descrizione_piattaforma_fad',
      //   accept: ['pdf'],
      //   extensions: ['pdf'],
      // },
    ]
  }

  const modalita = [getValueAtPath(data, 'datiAccreditamento.modalita')]?.flat()
  const tipo = getValueAtPath(data, 'datiAccreditamento.tipoAccreditamento')
  if (
    modalita?.find((x) =>
      [MODALITA_DISPONIBILI_VALUES.FAD_ASINCRONO, MODALITA_DISPONIBILI_VALUES.FAD_SINCRONO, MODALITA_DISPONIBILI_VALUES.MISTA].includes(x?.value)
    ) ||
    tipo?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.TOTALE
  ) {
    allegati.splice(2, 0, {
      id: `${objKey}fad`,
      label: 'Descrizione piattaforma FAD *',
      type: 'descrizione_piattaforma_fad',
    })
  }
  return allegati
}

export const ALLEGATI_AGENZIA_PROROGA = (data, objKey = '') => {
  if (objKey) objKey = `${objKey}.`

  let allegati: any = []

    allegati = [
      {
        id: `${objKey}allegatoProroga`,
        label: 'allegato Proroga',
        type: 'documentazione_proroga_agenzia',
        accept: ['application/pdf'],
        extensions: ['pdf'],
      },
    ]
    return allegati
  } 

export const attivitaDisponibili = [
  { label: 'AFC - Attività formativa caratterizzante', value: 'AFC' },
  { label: 'AFM - Attività formativa metaprofessionale', value: 'AFM' },
]
