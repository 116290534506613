import { FormGroup, Input } from 'design-react-kit'
import React from 'react'
import {
  capValidation,
  checkAnno,
  fiscalCodeValidation,
  ibanValidation,
  isEmail,
  isEmpty,
  isOnlyNumbers,
  partitaIvaValidation,
  telefonoValidation
} from 'src/utilities/utility'

import { AppUtils } from 'src/app/utils/FormUtils'

class GenericInput extends React.Component<GeneralInputProps, any>{
    constructor(props) {
        super(props);
        this.state = {
            isInvalid: undefined,
            errorMessage: "",
            elementType: props.type
        }
    }

    messageAlertContentError = {
        hasError: true,
        id: this.props.id,
        isReadonly: this.props.isReadOnly,
        isDisabled: this.props.isDisabled,
        stepTab: this.props.currentStep,
        value: this.props.defaultValue,
        label: this.props.label
    }

    messageAlertContentSuccess = {
        hasError: false,
        id: this.props.id,
        isReadonly: this.props.isReadOnly,
        isDisabled: this.props.isDisabled,
        stepTab: this.props.currentStep,
        value: this.props.defaultValue,
        label: this.props.label
    }

    componentDidMount(): void {
        if (this.props.errorMessageAlert !== undefined) {
            if (this.props.isRequired && (this.state.event === undefined || this.state.event === "")) {
                this.props.errorMessageAlert(this.messageAlertContentError)
            } else {
                this.props.errorMessageAlert(this.messageAlertContentSuccess)
            }
            if (this.props.value || this.props.defaultValue)
                this.props.errorMessageAlert(this.messageAlertContentSuccess)
        }

        switch (this.props.type) {
            case "email":
                this.setState({ elementType: "text" })
                break;
            case "cf":
                this.setState({ elementType: "text" })
                break;
            case "pIva":
                this.setState({ elementType: "text" })
                break;
            case "numeric":
                this.setState({ elementType: "text" })
                break;
            case "tel":
                this.setState({ elementType: "text" })
                break;
            case "cap":
                this.setState({ elementType: "text" })
                break;
            case "iban":
                this.setState({ elementType: "text" })
                break;
            case "fax":
                this.setState({ elementType: "text" })
                break;
            case "anno":
                this.setState({ elementType: "text" })
                break;
        }
    }

    componentDidUpdate(prevProps: Readonly<GeneralInputProps>): void {
        if (this.props.itemIdDeleted !== prevProps.itemIdDeleted)
            this.props.errorMessageAlert({ hasError: false, id: this.props.itemIdDeleted, isReadonly: false, isDisabled: false, stepTab: this.props.currentStep, value: this.props.defaultValue, label: this.props.label })

        if (this.props.isRequired !== prevProps.isRequired)
            this.componentDidMount()
    }

    validateFields = (errorMessage, isValidated) => {

        if (isValidated) {
            if (this.props.errorMessageAlert !== undefined)
                this.props.errorMessageAlert(this.messageAlertContentError)
            this.setState({ isInvalid: true, errorMessage: errorMessage })
            return false
        }
        return true
    }

    componentWillUnmount(): void {
        this.componentDidMount()
        if(this.props.errorMessageAlert)
            this.props.errorMessageAlert({ hasError: false, id: this.props.id, isReadonly: false, isDisabled: false, stepTab: this.props.currentStep, value: this.props.defaultValue, label: this.props.label })
    }

    validationForm = (event): void => {

        let validated;
        this.messageAlertContentError['value'] = event.target.value
        this.messageAlertContentSuccess['value'] = event.target.value
        this.setState({ isInvalid: false, errorMessage: "", event: event.target.value })
        if (isEmpty(event.target) && this.props.isRequired) {
            this.setState({ isInvalid: true, errorMessage: "Campo obbligatorio"})
            if (this.props.errorMessageAlert !== undefined) {
                this.props.errorMessageAlert(this.messageAlertContentError)
            }
        }

        else if(event.target.value > this.props.max){
            this.setState({ isInvalid: true, errorMessage: "Campo invalido"})
            if (this.props.errorMessageAlert !== undefined) {
                this.props.errorMessageAlert(this.messageAlertContentError)
            }
        }
        else if(event.target.value < this.props.min){
            this.setState({ isInvalid: true, errorMessage: "Campo invalido"})
            if (this.props.errorMessageAlert !== undefined) {
                this.props.errorMessageAlert(this.messageAlertContentError)
            }
        }

        else {

            switch (this.props.type) {
                    case 'email':
                        validated = this.validateFields("Email non valida", !isEmail(event.target.value))
                        break;
                    case 'tel':
                        validated = this.validateFields("Numero di telefono non valido", !telefonoValidation(event.target.value))
                        break;
                    case 'fax':
                        validated = this.validateFields("Fax non valido", !telefonoValidation(event.target.value))
                        break;
                    case 'cf':
                        validated = this.validateFields("Codice fiscale non valido", !fiscalCodeValidation(event.target.value))
                        break;
                    case 'pIva':
                        validated = this.validateFields("Codice Fiscale o Partita IVA non validi", !partitaIvaValidation(event.target.value))
                        break;
                    case 'cap':
                        validated = this.validateFields("Cap non valido", !capValidation(event.target.value))
                        break;
                    case 'iban':
                        validated = this.validateFields("Iban non valido", !ibanValidation(event.target.value))
                        break;
                    case 'numeric':
                        validated = this.validateFields("Inserire solo numeri", !isOnlyNumbers(event.target.value))
                        break;
                    case 'decimal-comma':
                        validated = this.validateFields("Inserire un valore valido. Usare il separatore ,", !AppUtils.isDecimalWithComma(event.target.value))
                        break;
                    case 'anno':
                        validated = this.validateFields("Inserire un anno valido", !checkAnno(event.target.value))
                        break;
                    default: validated = true;
            }

            if (this.props.errorMessageAlert !== undefined && validated) {
                this.setState({ isInvalid: false })
                this.props.errorMessageAlert(this.messageAlertContentSuccess)
            }
        }
    }

    showLabel = (event, flag): void => {

        this.setState({showLabel: flag})
    }

    render() {
        const { isInvalid, errorMessage, elementType } = this.state
        return (
            <>
                <FormGroup key={this.props.id} onFocus={(e)=>this.showLabel(e, true)} onBlur={(e)=>this.showLabel(e, false)} onChange={this.validationForm} validated={this.props.validated} hidden={this.props.isHidden}>
                    <small hidden={!isInvalid} className={"text-danger"}>{errorMessage}</small>
                    <Input
                        checked={this.props.checked}
                        hidden={this.props.isHidden}
                        type={elementType}
                        defaultValue={this.props.defaultValue}
                        defaultChecked={this.props.defaultChecked}
                        placeholder={this.props.placeholder}
                        id={this.props.id}
                        name={this.props.name}
                        className={this.props.className}
                        disabled={this.props.isDisabled}
                        label={this.props.label}
                        onChange={this.props.onChange}
                        normalized={this.props.normalized}
                        style={this.props.style}
                        min={this.props.min}
                        max={this.props.max}
                        readOnly={this.props.isReadOnly}
                        invalid={isInvalid}
                        value={this.props.value}
                    />
                    <small hidden={!this.state.showLabel}>{this.props.helpTextLabel}</small>

                </FormGroup>
            </>
        );
    }
}

export declare interface GeneralInputProps {
    /**
     * @description Type accettati: classici type html | "cf" | "pIva" | "cap" | "iban"
     */
    type?: any;
    placeholder?: string;
    id?: string;
    name?: string;
    className?: string;
    label?: any;
    isDisabled?: boolean;
    isHidden?: boolean;
    value?: any;
    defaultValue?: any;
    defaultChecked?: any;
    onChange?: any;
    normalized?: any;
    checked?: boolean;
    style?: any;
    isRequired?: boolean;
    validated?: any;
    min?: any;
    max?: any;
    isReadOnly?: boolean;
    errorMessageAlert?: any;
    currentStep?: number;
    /**
    * Contiene l'id dell'elemento da rendere non obbligatorio in fase di validazione una volta eliminato
    * Inserire l'id dell'elemento seguito dall'id dell'elemento selezionato
    * Es. se l'id elemento è uguale a {pippo + id} --> scrivere itemIdDeleted={"pippo" + idElementoEliminato}
    */
    itemIdDeleted?: string;
    modificaDatiFlag?: boolean;
    showName?: boolean;         //Questa props consente di visualizzare il nome della label nel messaggio di errore di validazione
    helpTextLabel?: any;         //Questa props rappresenta la label da visualizzare per aiutare l'utente nella compilazione
}

export default GenericInput;
