import React from 'react'

const createWrpInput = ({ objKey, onInit, onChange, viewOnly, realId = null }) => {
  const WrpInput = ({ as: Component, id, label, suffix, data, errors, disableOverride, ...props }: WrpInput) => {
    const _id = objKey ? `${objKey}.${id}` : id
    let _value = objKey ? data?.[objKey]?.[id] : data?.[id]
    const _error = errors?.[_id]

    if(realId) {
      _value = data?.[realId]
    }

    return (
      <Component
        id={_id}
        isDisabled={viewOnly || disableOverride}
        placeholder={label}
        label={`${label}${suffix || ''}`}
        value={_value}
        checked={_value}
        errorMessage={_error}
        onInit={onInit}
        onChange={onChange}
        {...props}
      />
    )
  }
  return WrpInput
}

interface WrpInput {
  as: any
  id: any
  label: any
  suffix?: any
  data: any
  errors: any
  disableOverride?: boolean
  [key: string]: any
}

export default createWrpInput
