import { listaOrganiDisciplinari, listaTipoProvvedimentiDisciplinari } from './CostantiDisciplinari'

export namespace DisciplinariUtils{

    /**
     * Questa funzione fa la decodifica dell'organo
     *  prende il tag proveniente da db e restituisce la label da visualizzare
     * Se non trova nulla restituisce l'opzione "Altro"
     * @param organo valore proveniente da db
     */
    export function decodificaOrgano (organo){
        let obj = listaOrganiDisciplinari.find(x => x.value === organo)

        if(obj === undefined) return listaOrganiDisciplinari.find(x => x.value === '-')
        return obj;

    }

     /**
     * Questa funzione fa la decodifica del tipo provvedimento
     *  prende il tag proveniente da db e restituisce la label da visualizzare
     * Se non trova nulla restituisce undefined
     * @param provvedimento valore proveniente da db
     */
    export function decodificaTipoProvvedimento(provvedimento){
        
        let obj = listaTipoProvvedimentiDisciplinari.find(x => x.value === provvedimento)

        if(obj === undefined) return listaTipoProvvedimentiDisciplinari.find(x => x.value === '-')
        else return obj;
    }
}
