import React from 'react'
import {
  PATH_TO_FO_ANAG_ISCRIZIONE_STP,
  PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA,
  PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO
} from 'src/app/utils/RoutesConstants'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import {
  compilaIscrizioneSTP,
  getIntegrazioniRichieste,
  getIscrizioneByCodiceFiscaleResponsabileStp,
  getIscrizioneByIdFO,
  inviaIscrizioneStp
} from 'src/processes/ProcessoIscrizione'
import {
  cleanUploadedFilesBeforeSend,
  compareDate,
  convertArrayToObject,
  deepJsonCopy,
  getHome,
  getStatoById,
  inputRequiredValidation,
  replaceStartWordAndLowerCaseFirstLetter,
  returnMaxDateFromArray
} from 'src/utilities/utility'
import { getComposizioneSocietariaByIdSocietaFO, getRappresentanteLegaleByIdSocietaFO } from 'src/processes/Societa'
import GenericBreadCrumb from '../../components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericTab from '../../components/GenericTab'
import { FormAllegatiDichiarazioniStpFO } from './formIscrizioneSTP/FormAllegatiDichiarazioniStpFO'
import { FormDatiGeneraliStpFO } from './formIscrizioneSTP/FormDatiGeneraliStpFO'
import { FormDatiProfessionaliStpFO } from './formIscrizioneSTP/FormDatiProfessionaliStpFO'
import { FormDatiRappresentanteLegaleStpFO } from './formIscrizioneSTP/FormDatiRappresentanteLegaleStpFO'
import { FormElencoDipendentiIscrittiAlboStpFO } from './formIscrizioneSTP/FormElencoDipendentiIscrittiAlboStpFO'
import { FormElencoSociIscrittiAlboStpFO } from './formIscrizioneSTP/FormElencoSociIscrittiAlboStpFO'
import { FormElencoSociIscrittiAltriOrdiniStpFO } from './formIscrizioneSTP/FormElencoSociIscrittiAltriOrdiniStpFO'
import { FormUbicazioneContattiStpFO } from './formIscrizioneSTP/FormUbicazioneContattiStpFO'
import { getProvinciaBySiglaFO } from 'src/processes/Province'
import { getCodiciAteco } from 'src/processes/CodiciAteco'
import GenericAlert from '../../components/GenericAlert'
import GenericChips from '../../components/GenericChips'
import { findSTPByPIva } from 'src/processes/Soggetto'
import { FormDocumentiIscrizioneStpFO } from './formIscrizioneSTP/FormDocumentiIscrizioneStpFO'
import Invio from '../iscrizioneAlbo/form/Invio'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import { logout } from '../../components/GenericHeaderSlim'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
var title = ''

const linkTab = [
    { id: 1, label: 'Dati Generali' },
    { id: 2, label: 'Soci' },
    { id: 3, label: 'Documenti' },
    { id: 4, label: 'Invio' },
]

var breadcrumbOptions: any = []
/* -------------------------------------------------------------------------------------------------- */

class IscrizioneSTP extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            typeMessage: 'default',
            isSubmitted: false,
            activeTab: 1,
            errors: [],
            stepTab: 1,
            provinciaSedeLegale: '',
            formData: [],
            composizioneSocietaria: [],
            sociIscrittiAlbo: [],
            dipendentiIscrittiAlbo: [],
            sociIscrittiAltriOrdini: [],
            files: [],
            dataLoaded: false,
            isDescrizione: false,
            previusActive: false,
        }
    }

    async componentDidMount(): Promise<any> {
        const tipologiaIscrizione = 'iscritto_stp'
        var idIscrizione
        var currentFiscalCode = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber
        var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
        var pIva = specificGroup.split('_')[1]
        var codiceFiscaleRappresentanteSTP
        var dateFineValidita: any = []
        var maxDataFineValidita

        /**
         * Se sono il mio account stp non è stato ancora confermato,
         * quindi sono semplicemente autenticato al portale,
         * invoco il servizio che mi recupera l'iscrizione PG by codice fiscale
         */
        if (TokenStore.getInstance().isCurrentAccountAuthenticated() || (await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP())) {
            codiceFiscaleRappresentanteSTP = currentFiscalCode
        } else if (TokenStore.getInstance().isCurrentAccountSTP() && TokenStore.getInstance().isOperatoreResponsabileSTP()) {
            /**
             * Recupero il codice fiscale del rappresentante legale dell'STP
             * specificGroup.startsWith("STP_") --> controllo aggiunto per sicurezza
             */
            if (specificGroup.startsWith('STP_')) {
                var soggettoSTP = await findSTPByPIva(pIva)
                if (soggettoSTP?.data) {
                    codiceFiscaleRappresentanteSTP = soggettoSTP.data.codiceFiscaleResponsabileStp
                }
            }
        }

        await getIscrizioneByCodiceFiscaleResponsabileStp(codiceFiscaleRappresentanteSTP)
            .then(async (response: any) => {
                if (response && response.data && response.data.httpStatus !== 200) {
                    throw new Error(response.data.error)
                } else {

                    //Nel caso in cui è stata modificata la partita iva (codiceGruppo !== piva) il sistema mostrerà all'utente un alert
                    // if (
                    //     response?.data?.returnedObject?.partitaIva !== null &&
                    //     response?.data?.returnedObject?.partitaIva !== undefined &&
                    //     response?.data?.returnedObject?.partitaIva !== '' &&
                    //     response?.data?.returnedObject?.codiceGruppo !== null &&
                    //     response?.data?.returnedObject?.codiceGruppo !== undefined &&
                    //     response?.data?.returnedObject?.codiceGruppo !== '' &&
                    //     response?.data?.returnedObject?.codiceGruppo?.substring(4) !== response?.data?.returnedObject?.partitaIva
                    // ) {
                    //     this.setState({
                    //         message: "Attenzione: è stata modificata la partita iva. La modifica sarà resa effettiva una volta inviata l'iscrizione.",
                    //         typeMessage: 'warning',
                    //     })
                    // }
                    /**
                     * Recupero le date fine validità e le inserisco in un array
                     */
                    response?.data?.returnedObject?.elencoIscrizioni.map((iscrizione) => {
                        dateFineValidita.push(new Date(iscrizione.dataFineValidita))
                    })
                    maxDataFineValidita = returnMaxDateFromArray(dateFineValidita)
                    var iscrizioneDataMax = response?.data?.returnedObject?.elencoIscrizioni.filter((iscrizione) =>
                        compareDate(iscrizione.dataFineValidita, maxDataFineValidita, 'eq')
                    )
                    idIscrizione = iscrizioneDataMax[0]?.idIscrizione
                    var allegati = iscrizioneDataMax[0]?.elencoAllegatiIscrizione
                    this.setState({ files: allegati?.filter((e) => e.dataCancellazione === null), idIscrizione, allegati })
                }
            })
            .catch((error) => {
                setTimeout(() => {
                    this.setState({ message: "C'è stato un errore durante il recupero dei dati: " + error, typeMessage: 'danger' })
                }, 1000)
            })

        if (idIscrizione) {
            await getIscrizioneByIdFO(idIscrizione, tipologiaIscrizione)
                .then(async (response: any) => {
                    var statoIscrizione = response.data.iscrizioneOrdineTerritorialeDTO.statoIscrizione
                    var flagReiscrizione = response.data.iscrizioneOrdineTerritorialeDTO.flagReiscrizione
                    this.setState({ statoIscrizione, flagReiscrizione })
                    if (this.state.previusActive !== undefined && !this.state.previusActive) {
                        // Se sono in compilazione (1) gestisco la pagina come Iscrizione
                        if (statoIscrizione === 1) {
                            title = 'Iscrizione STP: '
                            breadcrumbOptions.push({ label: 'Richiedi nuovo profilo', link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO })
                            breadcrumbOptions.push({ label: 'Iscrizione STP', link: PATH_TO_FO_ANAG_ISCRIZIONE_STP })
                            breadcrumbOptions.push({ label: 'Dati generali', link: PATH_TO_FO_ANAG_ISCRIZIONE_STP })
                        }

                        // Altrimenti gestisco la pagina come Modifica
                        else {
                            title = 'Modifica dati STP: '
                            breadcrumbOptions.push({ label: 'Richiedi nuovo profilo', link: PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO })
                            breadcrumbOptions.push({ label: 'Modifica dati STP', link: PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA })
                            breadcrumbOptions.push({ label: 'Dati generali', link: PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA })

                            // Controllo se lo stato dell'iscrizione è in "richiesta integrazioni"
                            if (statoIscrizione === 3) {
                                //recupera il messaggio delle integrazioni richieste dall'operatore
                                getIntegrazioniRichieste(idIscrizione)
                                    .then((response) => {
                                        if (response && response.data && response.data.httpStatus !== 200) {
                                            throw new Error(response.data.error)
                                        }
                                        this.setState({ isRichiestaIntegrazioni: true, integrazioni: response.data.returnedObject.testoRichiestaIntegrazione })
                                    })
                                    .catch((error) => {
                                        throw new Error(error)
                                    })
                            }
                        }
                    }

                    var elencoSedi = response.data.soggettoDTO.elencoSedi.find((sede) => sede.idIscrizione === idIscrizione)

                    //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia della sede legale dello studio a partire dalla Sigla
                    var provinciaSedeLegale = elencoSedi?.slProvinciaStudio
                    if (provinciaSedeLegale)
                        await getProvinciaBySiglaFO(provinciaSedeLegale).then((response: any) => {
                            this.setState({ provinciaSedeLegale: { label: response.data.denominazioneUnitaTerritorialeSovracomunale, value: provinciaSedeLegale } })
                        })

                    //chiamata al servizio 'getProvinciaBySigla' che restituisce la provincia della sede operativa dello studio a partire dalla Sigla
                    var siglaProvinciaSedeOperativa = elencoSedi?.sopProvinciaStudio
                    if (siglaProvinciaSedeOperativa)
                        await getProvinciaBySiglaFO(siglaProvinciaSedeOperativa).then((response: any) => {
                            this.setState({ provinciaSedeOperativa: { label: response.data.denominazioneUnitaTerritorialeSovracomunale, value: siglaProvinciaSedeOperativa } })
                        })

                    //setto l'ordine territoriale
                    this.setState({
                        ordineTerritoriale: {
                            label: response.data.soggettoDTO.denominazioneGruppo,
                            value: response.data.iscrizioneOrdineTerritorialeDTO.ordineTerritorialeCompetente,
                        },
                    })

                    var idSocieta = response.data.soggettoDTO.idSoggetto
                    if (idSocieta) {
                        //chiamata al servizio 'getSociByIdSocieta' che restituisce la composizione societaria a partire dall'idSocieta (idSoggetto PG)
                        await getComposizioneSocietariaByIdSocietaFO(idSocieta)
                            .then((response: any) => {
                                var compSoc = deepJsonCopy(response.data)

                                var dipendentiIscritti: any = []
                                compSoc.map((dipendente) => {
                                    if (
                                        dipendente.isDipendenteIscritto !== undefined &&
                                        dipendente.isDipendenteIscritto !== null &&
                                        dipendente.isDipendenteIscritto === 1 &&
                                        dipendente.idSoggettoPF !== null
                                    ) {
                                        var newDipendente = deepJsonCopy(dipendente)
                                        newDipendente.isSocio = 0
                                        dipendentiIscritti.push(newDipendente)
                                    }
                                })
                                this.setState({ dipendentiIscritti: dipendentiIscritti })

                                var sociIscritti: any = []
                                compSoc.map((socio) => {
                                    if (socio.isSocio !== undefined && socio.isSocio !== null && socio.isSocio === 1 && socio.idSoggettoPF !== null) {
                                        var newSocio = deepJsonCopy(socio)
                                        newSocio.isDipendenteIscritto = 0
                                        sociIscritti.push(newSocio)
                                    }
                                })
                                this.setState({ sociIscritti: sociIscritti })

                                var sociNonIscritti: any = []
                                compSoc.map((socioNonIscritto) => {
                                    if (socioNonIscritto.isSocio === 1 && socioNonIscritto.idSoggettoPF === null) {
                                        var newSocioNonIscritto = deepJsonCopy(socioNonIscritto)
                                        newSocioNonIscritto.isDipendenteIscritto = null
                                        sociNonIscritti.push(newSocioNonIscritto)
                                    }
                                })
                                this.setState({ sociNonIscritti: sociNonIscritti })
                            })
                            .catch((err) => {
                                setTimeout(() => {
                                    this.setState({ message: err.message, typeMessage: 'danger' })
                                }, 1000)
                            })

                        //chiamata al servizio 'getRappresentanteLegaleByIdSocieta' che restituisce i dati del rappr. legale di una società
                        await getRappresentanteLegaleByIdSocietaFO(idSocieta).then((response: any) => {
                            this.setState({ datiRappresentanteLegale: response.data })
                        })
                    }

                    /**
                     * Chiamata che recupera tutti i codici ateco
                     */
                    await getCodiciAteco().then((atecoResponse: any) => {
                        var codiciAteco = [{ label: '', value: '' }]

                        if (atecoResponse)
                            atecoResponse.data.map((codiceAteco, id) => {
                                return (codiciAteco[id] = {
                                    label: codiceAteco.cod + ' (' + codiceAteco.descr + ')',
                                    value: codiceAteco.cod,
                                })
                            })
                        var codiceAtecoSelected = codiciAteco.find((e) => e.value === response.data?.soggettoDTO?.codAteco)
                        this.setState({ codiceAtecoSelected: codiceAtecoSelected })
                    })

                    /**
                     * Spacchetto il dto che mi arriva dalla chiamata e lo trasformo in {id: id, value: value}
                     */
                    const dtoFromResponse = Object.assign(
                        {},
                        response.data?.iscrizioneOrdineTerritorialeDTO,
                        response.data?.soggettoDTO,
                        response.data?.tipologiaIscrizioneDTO,
                        elencoSedi
                    )
                    const initialFormData = Object.entries(dtoFromResponse).map(([id, value]) => ({ id, value }))
                    this.setState({ formData: initialFormData })
                    this.setState({ datiIscrizione: response.data, dataLoaded: true })
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.setState({ message: "C'è stato un errore durante il recupero dei dati: " + error, typeMessage: 'danger' })
                    }, 1000)
                })
        }
    }

    //Procedi allo step successivo
    nextStep = async () => {
        var dateFineValidita: any = []
        var currentFiscalCode = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber
        var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
        var pIva = specificGroup.split('_')[1]
        var dto
        this.setState({ dataLoaded: true, previusActive: false })

        if (this.state.activeTab + 1 <= linkTab.length) {
            //conversione dell'array 'formData' in un oggetto 'dto'
            dto = convertArrayToObject(this.state.formData.map((e) => ({ id: replaceStartWordAndLowerCaseFirstLetter('id', e.id), value: e.value })))
            this.setState({ dto })

            /**
             * Controllo se esiste l'allegato e lo recupero
             */
            var allegato = this.state.files.find(
                (e) => (e.tipologiaAllegato ? e.tipologiaAllegato : e.codiceTipoAllegato) === 'allegato_stp' && (e.cancellato === undefined || e.cancellato === false)
            )

            /**
             * Recupero l'indice dell'allegato nell'array
             */
            var index = this.state.files.indexOf(allegato)

            /**
             * Sostituisco la descrizione breve dell'allegato con quella precedente nell'array alla posizione indicata
             */
            const { files } = this.state
            if (dto['descrizioneAllegato']?.id === 'descrizioneAllegato') {
                if (dto['descrizioneAllegato']?.value !== null && dto['descrizioneAllegato']?.value !== undefined) {
                    const updatedItems = files.slice() // Clona l'array esistente
                    updatedItems[index].descrizioneBreve = dto['descrizioneAllegato']?.value // Modifica l'attributo
                    this.setState({ files: updatedItems })
                }
            }

            if (TokenStore.getInstance().isCurrentAccountAuthenticated() || (await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP()))
                var codiceFiscaleRappresentanteSTP = currentFiscalCode
            else if (TokenStore.getInstance().isCurrentAccountSTP() && TokenStore.getInstance().isOperatoreResponsabileSTP()) {
                if (specificGroup.startsWith('STP_')) {
                    var soggettoSTP = await findSTPByPIva(pIva)
                    if (soggettoSTP?.data) {
                        codiceFiscaleRappresentanteSTP = soggettoSTP.data.codiceFiscaleResponsabileStp
                    }
                }
            }
            //recupera l'iscrizione tramite il CF del responsabile STP
            await getIscrizioneByCodiceFiscaleResponsabileStp(codiceFiscaleRappresentanteSTP)
                .then(async (response: any) => {
                    if (response && response.data && response.data.httpStatus !== 200) {
                        throw new Error(response.data.error)
                    } else {
                        //Nel caso in cui è stata modificata la partita iva (codiceGruppo !== piva) il sistema mostrerà all'utente un alert
                        // if (
                        //     this.state.dto.partitaIva.value !== null &&
                        //     this.state.dto.partitaIva.value !== undefined &&
                        //     this.state.dto.partitaIva.value !== '' &&
                        //     response?.data?.returnedObject?.codiceGruppo !== null &&
                        //     response?.data?.returnedObject?.codiceGruppo !== undefined &&
                        //     response?.data?.returnedObject?.codiceGruppo !== '' &&
                        //     response?.data?.returnedObject?.codiceGruppo?.substring(4) !== this.state.dto.partitaIva.value
                        // ) {
                        //     this.setState({
                        //         message: "Attenzione: è stata modificata la partita iva. La modifica sarà resa effettiva una volta inviata l'iscrizione.",
                        //         typeMessage: 'warning',
                        //     })
                        // }


                        // Ci deve essere almeno un socio
                        var countValidSoci = 0
                        this.state.sociIscrittiAlbo.forEach((sogg) => {
                            if (sogg.cancellato === undefined || sogg.cancellato === false) countValidSoci++
                        })
                        if (this.state.activeTab === 2 && countValidSoci === 0) {
                            throw new Error("È necessario inserire almeno un socio iscritto all'albo")
                        }

                        //si compone un array 'composizioneSocietaria' con all'interno soci iscritti, soci non iscritti e dipendenti.
                        var composizioneSocietaria = [...this.state.dipendentiIscrittiAlbo, ...this.state.sociIscrittiAlbo, ...this.state.sociIscrittiAltriOrdini]
                        this.setState({ composizioneSocietaria, idSoggetto: response.data.returnedObject.idSoggetto })

                        //Pulisce la lista dei file
                        var filesList = cleanUploadedFilesBeforeSend(files)
                        this.setState({ files: filesList })

                        /**
                         * Recupero le date fine validità e le inserisco in un array
                         */
                        response?.data?.returnedObject?.elencoIscrizioni.map((iscrizione) => {
                            dateFineValidita.push(new Date(iscrizione.dataFineValidita))
                        })
                        const maxDataFineValidita = returnMaxDateFromArray(dateFineValidita)
                        var iscrizioneDataMax = response?.data?.returnedObject?.elencoIscrizioni.filter((iscrizione) =>
                            compareDate(iscrizione.dataFineValidita, maxDataFineValidita, 'eq')
                        )
                        var idIscrizione = iscrizioneDataMax[0]?.idIscrizione

                        //invia i dati di compilazione realativi ai singoli step (PUT)
                        await compilaIscrizioneSTP(this.state.activeTab, response.data.returnedObject.idSoggetto, idIscrizione, dto, composizioneSocietaria, filesList)
                            .then(async (responseCompilaIscrizioneSTP: any) => {

                                if (responseCompilaIscrizioneSTP && responseCompilaIscrizioneSTP.data && responseCompilaIscrizioneSTP.data.httpStatus !== 200) {
                                    throw new Error(responseCompilaIscrizioneSTP.data.error)
                                }

                                filesList?.map((uploadFile, id) => (uploadFile.idAttachment = responseCompilaIscrizioneSTP.data.returnedObject?.allegatoDTOS[id].idAttachment))
                                breadcrumbOptions.push({
                                    label: linkTab[this.state.activeTab].label,
                                    link: this.state.statoIscrizione === 1 ? PATH_TO_FO_ANAG_ISCRIZIONE_STP : PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA,
                                })
                                //Nel caso in cui la partita iva è stata modificata (incongruenza con lo specificGroup del token) viene visualizzato un modal che informa l'utente di effettuare nuovamente il login.
                                if (specificGroup.startsWith("STP_") && this.state.dto.partitaIva.value !== specificGroup.substring(4)) {
                                    setTimeout(async () => {
                                        this.showModal(true, "Attenzione", "E' stata modificata la partita iva. Per rendere effettiva la modifica è necessario ripetere il login.");
                                    }, 1000)
                                }
                                else {
                                    this.setState({ activeTab: this.state.activeTab + 1, dataLoaded: true })
                                }
                            })
                            .catch((error) => {
                                this.setState({ message: error.message, typeMessage: 'danger' })
                                this.setState({ dataLoaded: true })
                            })

                    }

                })
                .catch((error) => {
                    this.setState({ message: '' + error, typeMessage: 'danger' })
                    this.setState({ dataLoaded: true })
                })

        }
    }

    //Procedi allo step precedente
    previousStep = async () => {
        breadcrumbOptions.pop()
        this.setState({ dataLoaded: false })
        this.componentDidMount()
        await this.setState({ activeTab: this.state.activeTab - 1, previusActive: true })
    }

    //metodo invocato da FormInviaRichiesta per completare l'iscrizione
    inviaIscrizione = async () => {
        var dateFineValidita: any = []
        var currentFiscalCode = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber
        var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
        var pIva = specificGroup.split('_')[1]

        if (this.state.activeTab === linkTab.length) {
            if (TokenStore.getInstance().isCurrentAccountAuthenticated() || (await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP()))
                var codiceFiscaleRappresentanteSTP = currentFiscalCode
            else if (TokenStore.getInstance().isCurrentAccountSTP() && TokenStore.getInstance().isOperatoreResponsabileSTP()) {
                if (specificGroup.startsWith('STP_')) {
                    var soggettoSTP = await findSTPByPIva(pIva)
                    if (soggettoSTP?.data) {
                        codiceFiscaleRappresentanteSTP = soggettoSTP.data.codiceFiscaleResponsabileStp
                    }
                }
            }

            //recupera l'iscrizione tramite il CF del responsabile STP
            await getIscrizioneByCodiceFiscaleResponsabileStp(codiceFiscaleRappresentanteSTP)
                .then(async (response: any) => {
                    if (response && response.data && response.data.httpStatus !== 200) {
                        throw new Error(response.data.error)
                    } else {
                        /**
                         * Recupero le date fine validità e le inserisco in un array
                         */
                        response?.data?.returnedObject?.elencoIscrizioni.map((iscrizione) => {
                            dateFineValidita.push(new Date(iscrizione.dataFineValidita))
                        })
                        const maxDataFineValidita = returnMaxDateFromArray(dateFineValidita)
                        var iscrizioneDataMax = response?.data?.returnedObject?.elencoIscrizioni.filter((iscrizione) =>
                            compareDate(iscrizione.dataFineValidita, maxDataFineValidita, 'eq')
                        )
                        var idIscrizione = iscrizioneDataMax[0]?.idIscrizione
                        var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]

                        /************************** Chiamata per l'invio finale dell'iscrizione ******************************/
                        await inviaIscrizioneStp(response.data.returnedObject.idSoggetto, idIscrizione, this.state.dto, this.state.composizioneSocietaria, this.state.files)
                            .then((response) => {
                                this.setState({
                                    activeTab: this.state.activeTab,
                                    message: 'La tua richiesta è stata inviata con successo!',
                                    typeMessage: 'success',
                                })
                                // //Nel caso in cui la partita iva è stata modificata (incongruenza con lo specificGroup del token) viene visualizzato un modal che informa l'utente di effettuare nuovamente il login.
                                // if (specificGroup.startsWith("STP_") && this.state.dto.partitaIva.value !== specificGroup.substring(4))
                                //     setTimeout(async () => {
                                //         this.showModal(true, "Attenzione", "E' stata modificata la partita iva. Per rendere effettiva la modifica è necessario ripetere il login.");
                                //     }, 1000)

                                // else {
                                /**Redirect alla Home */
                                setTimeout(async () => {
                                    window.location.href = getHome()
                                }, 1000)
                                // }
                            })
                            .catch((err) => {
                                this.setState({ message: "C'è stato un errore nell'invio della richiesta. " + err.message, typeMessage: 'danger' })
                            })
                    }
                })
                .catch((err) => {
                    console.error("C'è stato un errore durante il recupero dei dati: ", err)
                    this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                })
        }
    }

    onSubmit = async (event) => {
        event.preventDefault()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        var errorRequired: any = inputRequiredValidation(
            this.state.errors.filter((e) => !e.isDisabled && !e.isHidden && e.stepTab === this.state.activeTab) || this.state.errors.length === 0
        )
        if (errorRequired) {
            event.preventDefault()
            return this.setState({ message: errorRequired.messaggio, typeMessage: errorRequired.type })
        } else {
            this.nextStep()
            this.setState({ message: '' })
        }
    }

    //Inserisce i feedback che arrivano dai componenti generici in un'array e se esistono già li sostituisce
    hasFormErrors = (alert) => {
        var findItem = this.state.errors.find((a) => a.id === alert.id)
        var errorID = this.state.errors.indexOf(findItem)
        if (findItem) {
            if (alert.isHidden)
                this.state.errors[errorID] = {
                    id: alert.id,
                    isDisabled: alert.isDisabled,
                    isHidden: alert.isHidden,
                    stepTab: alert.stepTab,
                    value: alert.value,
                    label: alert.label,
                }
            else
                this.state.errors[errorID] = {
                    error: alert.hasError,
                    id: alert.id,
                    isDisabled: alert.isDisabled,
                    isHidden: alert.isHidden,
                    stepTab: alert.stepTab,
                    value: alert.value,
                    label: alert.label,
                }
        } else {
            this.state.errors.push({
                error: alert.hasError,
                id: alert.id,
                isDisabled: alert.isDisabled,
                isHidden: alert.isHidden,
                stepTab: alert.stepTab,
                value: alert.value,
                label: alert.label,
            })
        }
    }

    //metodo che recupera i dati dai form
    getFormData = (data) => {
        this.setState((prevState) => {
            const formData = prevState.formData.slice()
            const index = formData.findIndex((item) => item.id === replaceStartWordAndLowerCaseFirstLetter('id', data.id))
            if (index !== -1) {
                formData[index] = data
            } else {
                formData.push(data)
            }
            this.setState({ formData })
        })
    }

    //metodo che recupera l'oggetto con i dati di ciascun file e compone la lista files con la stessa struttura del DTO (AllegatoDTOS)
    getFileData = (file) => {
        this.state.files.push(file)
        this.state.files.map((e, id) =>
            (e.tipologiaAllegato ? e.tipologiaAllegato : e.codiceTipoAllegato) === file.tipologiaAllegato &&
                (e.idattachment !== null || e.idAttachment !== null) &&
                id < this.state.files.length - 1
                ? (e.cancellato = true)
                : e
        )
    }

    //metodo che recupera la lista dei soci iscritti all'albo
    getListaSociIscrittiAlbo = (soci) => {
        this.setState({ sociIscrittiAlbo: soci.filter((socio) => socio.cf !== '' && socio.cf !== null && socio.cf !== undefined) })
    }

    //metodo che recupera la lista dei dipendenti all'albo
    getListaDipendentiIscrittiAlbo = (dipendenti) => {
        this.setState({ dipendentiIscrittiAlbo: dipendenti.filter((dipendente) => dipendente.cf !== '' && dipendente.cf !== null && dipendente.cf !== undefined) })
    }

    //metodo che recupera la lista dei soci non iscritti all'albo
    getListaSociIscrittiAltriOrdini = (soci) => {
        this.setState({ sociIscrittiAltriOrdini: soci.filter((socio) => socio.nome !== '' && socio.nome !== null && socio.nome !== undefined) })
    }

    //metodo che recupera la provincia della sedeLegale
    getProvinciaSedeLegale = async (provinciaSedeLegale: any) => {
        this.setState({ provinciaSedeLegale: provinciaSedeLegale })
    }

    showModal = async (booleanValue, title, text) => {
        await this.setState({
            modalOpen: booleanValue,
            modalTitle: title,
            modalText: text,
            isLoaded: true,
        });
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
        logout();
    }

    render() {
        const {
            datiIscrizione,
            flagReiscrizione,
            provinciaSedeLegale,
            provinciaSedeOperativa,
            codiceAtecoSelected,
            ordineTerritoriale,
            files,
            sociIscritti,
            sociNonIscritti,
            dipendentiIscritti,
            statoIscrizione,
        } = this.state
        const listBodyTab =
            /** Lista del contenuto dei Tab per l'iscrizione */
            [
                {
                    id: 1,
                    body: (
                        <GenericCard
                            cardHeader={'Dati responsabile legale'}
                            cardBody={
                                <FormDatiRappresentanteLegaleStpFO
                                    tabNumber={1}
                                    message={this.hasFormErrors}
                                    formData={this.getFormData}
                                    fileMandato={this.getFileData}
                                    datiIscrizione={datiIscrizione}
                                    listaFiles={files}
                                    statoIscrizione={statoIscrizione}
                                    flagReiscrizione={flagReiscrizione}
                                />
                            }
                        />
                    ),
                },
                {
                    id: 1,
                    body: (
                        <GenericCard
                            cardHeader={'Ubicazione e contatti'}
                            cardBody={
                                <FormUbicazioneContattiStpFO
                                    getProvinciaSedeLegale={this.getProvinciaSedeLegale}
                                    tabNumber={1}
                                    message={this.hasFormErrors}
                                    formData={this.getFormData}
                                    datiIscrizione={datiIscrizione}
                                    provinciaSedeLegale={provinciaSedeLegale}
                                    provinciaSedeOperativa={provinciaSedeOperativa}
                                />
                            }
                        />
                    ),
                },
                {
                    id: 1,
                    body: (
                        <GenericCard
                            cardHeader={'Dati generali'}
                            cardBody={
                                <FormDatiGeneraliStpFO
                                    provinciaSedeLegale={this.state?.provinciaSedeLegale}
                                    tabNumber={1}
                                    message={this.hasFormErrors}
                                    formData={this.getFormData}
                                    datiIscrizione={datiIscrizione}
                                    codiceAtecoSelected={codiceAtecoSelected}
                                    ordineTerritoriale={ordineTerritoriale}
                                    flagReiscrizione={flagReiscrizione}
                                />
                            }
                            cardFooter={<small>(¹) La denominazione sociale, in qualunque modo formata, deve contenere l'indicazione di società tra professionisti.</small>}
                        />
                    ),
                },
                {
                    id: 1,
                    body: (
                        <GenericCard
                            cardHeader={'Dati professionali'}
                            cardBody={<FormDatiProfessionaliStpFO tabNumber={1} message={this.hasFormErrors} formData={this.getFormData} datiIscrizione={datiIscrizione} flagReiscrizione={flagReiscrizione} />}
                        />
                    ),
                },
                {
                    id: 2,
                    body: (
                        <GenericCard
                            cardHeader={'Elenco soci iscritti all’Albo dei Dottori Agronomi e dei Dottori Forestali'}
                            cardBody={
                                <FormElencoSociIscrittiAlboStpFO
                                    tabNumber={2}
                                    message={this.hasFormErrors}
                                    soci={this.getListaSociIscrittiAlbo}
                                    idSocieta={this.state.idSoggetto}
                                    datiIscrizione={datiIscrizione}
                                    composizioneSocietaria={sociIscritti}
                                />
                            }
                        />
                    ),
                },
                {
                    id: 2,
                    body: (
                        <GenericCard
                            cardHeader={'Elenco soci iscritti ad altri ordini, albi e collegi professionali o in possesso del titolo di studio abilitante'}
                            cardBody={
                                <FormElencoSociIscrittiAltriOrdiniStpFO
                                    tabNumber={2}
                                    message={this.hasFormErrors}
                                    soci={this.getListaSociIscrittiAltriOrdini}
                                    idSocieta={this.state.idSoggetto}
                                    datiIscrizione={datiIscrizione}
                                    composizioneSocietaria={sociNonIscritti}
                                />
                            }
                        />
                    ),
                },
                {
                    id: 2,
                    body: (
                        <GenericCard
                            cardHeader={'Dipendenti iscritti all’Albo dei Dottori Agronomi e dei Dottori Forestali'}
                            cardBody={
                                <FormElencoDipendentiIscrittiAlboStpFO
                                    tabNumber={2}
                                    dipendenti={this.getListaDipendentiIscrittiAlbo}
                                    idSocieta={this.state.idSoggetto}
                                    datiIscrizione={datiIscrizione}
                                    composizioneSocietaria={dipendentiIscritti}
                                />
                            }
                        />
                    ),
                },
                {
                    id: 3,
                    body: (
                        <GenericCard
                            cardHeader={'Documenti iscrizione'}
                            cardBody={
                                <FormDocumentiIscrizioneStpFO
                                    tabNumber={3}
                                    message={this.hasFormErrors}
                                    ordineTerritoriale={this.state?.provinciaSedeLegale}
                                    formData={this.getFormData}
                                    fileQuotaIscrizione={this.getFileData}
                                    fileBollo={this.getFileData}
                                    fileTassa={this.getFileData}
                                    datiIscrizione={datiIscrizione}
                                    listaFiles={files}
                                    statoIscrizione={statoIscrizione}
                                />
                            }
                        />
                    ),
                },
                {
                    id: 3,
                    body: (
                        <GenericCard
                            cardHeader={'Allegati e dichiarazioni'}
                            cardBody={
                                <FormAllegatiDichiarazioniStpFO
                                    tabNumber={3}
                                    message={this.hasFormErrors}
                                    formData={this.getFormData}
                                    fileCertificatoRegistroImprese={this.getFileData}
                                    fileAllegatoStp={this.getFileData}
                                    datiIscrizione={datiIscrizione}
                                    listaFiles={files}
                                    statoIscrizione={statoIscrizione}
                                />
                            }
                        />
                    ),
                },
                {
                    id: 4,
                    body: <Invio inviaIscrizione={this.inviaIscrizione} iscrizioneGiaInviata={this.state.statoIscrizione === 3 || this.state.statoIscrizione === 4} />,
                },
            ]

        const { message, typeMessage, isSubmitted, activeTab, dataLoaded } = this.state
        return (
            <>
                <div className="container register-container">
                    <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                    <h1>
                        {title + ' Step '}
                        {activeTab}
                    </h1>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <GenericChips color={'default'} label={'Stato iscrizione: ' + getStatoById(statoIscrizione).toUpperCase()} />
                    </div>
                    <GenericAlert
                        key="alertIntegrazioni"
                        hidden={!this.state.isRichiestaIntegrazioni}
                        label={
                            <>
                                <b>Integrazioni richieste: </b>
                                {this.state.integrazioni}
                            </>
                        }
                        color={'info'}
                    />
                    <div className="justify-content-center">
                        <GenericTab
                            hiddenAlert={message === ''}
                            alertTypeMessage={typeMessage}
                            labelAlert={message}
                            isSubmitted={isSubmitted}
                            onSubmit={this.onSubmit}
                            listNavLink={linkTab}
                            listBodyTab={dataLoaded && listBodyTab}
                            activeTab={activeTab}
                            previousStep={this.previousStep}
                        ></GenericTab>
                    </div>
                    <GenericModal
                        title={this.state.modalTitle}
                        text={this.state.modalText}
                        modalOpen={this.state.modalOpen}
                        closeButton={this.closeModal}
                    />
                </div>
            </>
        )
    }
}
export default IscrizioneSTP
