import React from 'react'
import { FormGroup } from '@patternfly/react-core'
import {
  aggiornaPolizza,
  recuperaAllegato,
  recuperaPagamentoPolizzaByIdPagamento
} from 'src/processes/assicurazioni/Assicurazione'
import { Icon, Label } from 'design-react-kit'
import GenericInput from 'src/frontend/app/components/GenericInput'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class ListaAllegatiAssicurazioni extends React.Component<ListaAllegatiAssicurazioniProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            nessunAllegatoFlag: false,
            pagamento: {},
            visualizzaFormPagamento: false,
        };
    }

    async componentDidMount() {

            switch(this.props.nomeAllegato) {
                case "copiaPagamentoPolizza":
                    if(((!this.props.polizza["copiaPagamentoPolizza"] || this.props.polizza["copiaPagamentoPolizza"] === undefined
                        )
                        && (!this.props.polizza?.assicurazioneAllegati || this.props.polizza?.assicurazioneAllegati === undefined
                        || this.props.polizza?.assicurazioneAllegati.filter(
                        allegato =>
                            allegato.cancellato === 0 && allegato.idPolizza === this.props.polizza['id'] && allegato.idIscrizione === this.props.polizza['idIscrizione'] &&
                            this.props.tipologiaAllegato.includes(allegato.tipologiaAllegato)
                        ).length === 0)))
                        {
                            this.setState({nessunAllegatoFlag: true})
                        }
                        else this.setState({nessunAllegatoFlag: false})
                        this.setState({nomeAllegato: this.props.nomeAllegato})
                    break;
                case "fileMaternitaPaternita":
                    if(((!this.props.polizza["fileMaternitaPaternita"] || this.props.polizza["fileMaternitaPaternita"] === undefined
                        )
                        && (!this.props.polizza?.assicurazioneAllegati || this.props.polizza?.assicurazioneAllegati === undefined
                        || this.props.polizza?.assicurazioneAllegati.filter(
                        allegato =>
                            allegato.cancellato === 0 && allegato.idPolizza === this.props.polizza['id'] && allegato.idIscrizione === this.props.polizza['idIscrizione'] &&
                            allegato.tipologiaAllegato === this.props.tipologiaAllegato
                        ).length === 0)))
                        {

                            this.setState({nessunAllegatoFlag: true})
                        }
                        else this.setState({nessunAllegatoFlag: false})

                        break;
                    }

            this.setState({nomeAllegato: this.props.nomeAllegato})
            if(this.props.datiPagamento) {

                if(this.props.polizza.assicurazioneAllegati.filter(
                    allegato =>
                        allegato.idPoPagamenti !== null && allegato.idPolizza === this.props.polizza['id'] && allegato.idIscrizione === this.props.polizza['idIscrizione'] &&
                        allegato.tipologiaAllegato === "polizze_copia_pagamento"
                    )[0]) {
                      let pagamentoFound = this.props.polizza.assicurazioneAllegati.filter(
                        allegato =>
                          allegato.idPoPagamenti !== null &&
                          allegato.cancellato === 0 && allegato.idPolizza === this.props.polizza['id'] && allegato.idIscrizione === this.props.polizza['idIscrizione'] &&
                          allegato.tipologiaAllegato === "polizze_copia_pagamento"
                        )[0].idPoPagamenti;
                        await recuperaPagamentoPolizzaByIdPagamento(pagamentoFound).then(async (response: any)=> {
                            let indexPagamentoFound = this.props.polizza.assicurazioneAllegati.findIndex(object => {
                              return object.idPoPagamenti === pagamentoFound})
                            if(indexPagamentoFound !== -1) {
                                this.props.polizza.assicurazioneAllegati[indexPagamentoFound].poPagamenti = response.returnedObject;
                                this.setState({pagamento: response.returnedObject})
                            }

                            }).catch(() => {
                              setTimeout(() => {
                                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                              }, 3000);
                            })
                    }
                else {
                    //Se il form è vuoto
                    this.setState({pagamento: {
                        "importo": this.props.polizza.premioConaf,
                        "intestazione": "Consiglio dell'Ordine Nazionale dei dottori agronomi e dei dottori forestali",
                        "causale": "Adesione polizza RC Professionale collettiva CONAF Fascia " + this.props.polizza.fasciaPremioConaf,
                        "banca": "BANCO DI SARDEGNA Spa",
                        "ibanCode": "IT46N 01015 03200 000070423591"

                    }})
                }
            }
            this.setState({isLoaded: true})
    }


    scaricaFileLocale = async(idPolizza?: any, allegato?: any) => {

        let linkSource = "data:application/pdf;base64,"+allegato["contentBase64"];
        const downloadLink = document.createElement("a");
        const fileName =allegato.filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        let pdfAsDataUri :any = "data:application/pdf;base64,"+allegato["contentBase64"];
        window.open(pdfAsDataUri,"_blank");

    }

    ottieniAllegato = async(idPolizza?: any, idAllegato?: any) => {

        await recuperaAllegato(idPolizza, idAllegato).then(response => {

                if(response["content"] === undefined || response["content"] === null || response["content"] === "") {
                }
                else {
                    let linkSource = "data:application/pdf;base64,"+response["content"];
                    const downloadLink = document.createElement("a");
                    const fileName =response.name+".pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
                let pdfAsDataUri :any = "data:application/pdf;base64,"+response["data"]["content"];
                window.open(pdfAsDataUri,"_blank");

            }).catch(() => {
                    setTimeout(() => {
                        this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
                }, 3000);
            });
    }

    resetAllegato = async() => {
        let polizza = this.props.polizza
        this.setState({isLoaded: false})
        this.props.resetFile(polizza);
        this.setState({pagamento: {
            "importo": this.props.polizza.premioConaf,
            "intestazione": "Consiglio dell'Ordine Nazionale dei dottori agronomi e dei dottori forestali",
            "causale": "Adesione polizza RC Professionale collettiva CONAF Fascia " + this.props.polizza.fasciaPremioConaf,
            "banca": "BANCO DI SARDEGNA Spa",
            "ibanCode": "IT46N 01015 03200 000070423591"
        },isLoaded: true})

    }

    rimuoviAllegato = async(idAllegato?:any) => {
        let polizza = this.props.polizza ;
        this.setState({isLoaded: false})

        polizza.assicurazioneAllegati
            .filter(a => a.idAllegato === idAllegato)
            .map(a => a.cancellato = 1);
        this.setState({polizza: polizza})
        await aggiornaPolizza(this.state.codiceFiscale, polizza)
            .then(async (response: any) => {
                this.setState({polizza: response})
                this.props.getSelectedValue(polizza, "polizza")
            }).catch((err) => {
                this.setState({ message: err.message, typeMessage: 'danger' });
        })
        this.setState({pagamento: {
            "importo": this.props.polizza.premioConaf,
            "intestazione": "Consiglio dell'Ordine Nazionale dei dottori agronomi e dei dottori forestali",
            "causale": "Adesione polizza RC Professionale collettiva CONAF Fascia " + this.props.polizza.fasciaPremioConaf,
            "banca": "BANCO DI SARDEGNA Spa",
            "ibanCode": "IT46N 01015 03200 000070423591"
        },isLoaded: true})
    }

    render() {

        /** Funzione per aggiornare il campo note */
        const setDataItem = (e:any, type: any, field:any, index?: any) => {

            let allegatoFound: any = this.props.polizza?.assicurazioneAllegati.filter(
                allegato =>
                    allegato.cancellato === 0 &&
                    allegato.tipologiaAllegato.includes(this.props.tipologiaAllegato)
                )[0]
            if(allegatoFound === undefined){
                allegatoFound = {};
            }
            if(this.props.polizza.copiaPagamentoPolizza) {
                allegatoFound = this.props.polizza.copiaPagamentoPolizza[0]
            }

            let polizza = this.props.polizza;

            if(field==='notePagamento') {
                polizza['notePagamento'] = e.target.value;
                polizza.assicurazioneAllegati
                .filter(a => a.idAllegato === allegatoFound.idAllegato)
                .map(a => a.descrizioneBreve = e.target.value);
            }
            else if(field==='copiaPagamentoPolizza') {
                polizza['copiaPagamentoPolizza'][0]['descrizioneBreve'] = e.target.value;

            }

            this.props.getSelectedValue(polizza, 'polizza')
            this.setState({ polizza });

        }

        return (
            <>
                {
                    this.state.isLoaded &&
                    <div key={"key"+this.props.polizza.id}>
                                {this.props.datiPagamento
                                &&
                                <>
                                    <br></br>
                                    <div className="row">
                                        <div className={isCurrentMobileDevice() ? null : "col-12"}>
                                            <FormGroup >
                                                <GenericInput key={'intestazione'} id="intestazione" name={'intestazione'} onChange={(e)=>setDataItem(e, 'text','intestazione')} label="Intestazione" placeholder='Inserisci Intestazione '
                                                    defaultValue={(this.state.pagamento['intestazione']) ? this.state.pagamento['intestazione'] : null} isDisabled
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                            <FormGroup >
                                                <GenericInput key={'banca'} id="banca" name={'banca'} onChange={(e)=>setDataItem(e, 'text','banca')} label="Banca " placeholder='Banca '
                                                    defaultValue={(this.state.pagamento['banca']) ? this.state.pagamento['banca'] : this.props.visualizzaFlag ? " " : null} isDisabled
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                            <FormGroup >
                                                <GenericInput key={'importo'} id="importo" name={'importo'} onChange={(e)=>setDataItem(e, 'text','importo')} label="Importo (€)" placeholder='Importo'
                                                    defaultValue={(this.state.pagamento['importo']) ? this.state.pagamento['importo'] : " "} isDisabled
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className={isCurrentMobileDevice() ? null : "col-12"}>
                                            <FormGroup >
                                                <GenericInput key={'ibanCode'} id="ibanCode" name={'ibanCode'} onChange={(e)=>setDataItem(e, 'text','ibanCode')} label="IBAN " placeholder='IBAN '
                                                    defaultValue={(this.state.pagamento['ibanCode']) ? this.state.pagamento['ibanCode'] : this.props.visualizzaFlag ? " " : null} isDisabled
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className={isCurrentMobileDevice() ? null : "col-12"}>
                                            <FormGroup >
                                                <GenericInput key={'causale'} id="causale" name={'causale'} onChange={(e)=>setDataItem(e, 'text','causale')} label="Causale" placeholder='Inserisci Causale '
                                                    defaultValue={(this.state.pagamento['causale']) ? this.state.pagamento['causale'] : null} isDisabled
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <br></br>
                                </>

                                }
                                {
                                    this.state.nomeAllegato === "copiaPagamentoPolizza" && this.props.polizza?.fileMaternitaPaternita && this.props.polizza?.fileMaternitaPaternita.length > 0 &&
                                    <div className="row p-2 ms-4">
                                        <div className={isCurrentMobileDevice() ? null : 'col-4 mt-2'} onClick={() => this.scaricaFileLocale(this.props.polizza?.id, this.props.polizza?.fileMaternitaPaternita[0])}>
                                            <a href="#!">
                                                <Icon icon="it-file"  /> {this.props.polizza?.fileMaternitaPaternita[0]?.filename}
                                            </a>
                                        </div>
                                        <div className={isCurrentMobileDevice() ? null : 'col-2 mt-2'}>
                                                <span className="pe-3" onClick={() => this.resetAllegato} >
                                                    <a href="#!">
                                                                <Icon icon="it-delete" />
                                                    </a>
                                                </span>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.nomeAllegato === "copiaPagamentoPolizza" && this.props.polizza?.copiaPagamentoPolizza && this.props.polizza?.copiaPagamentoPolizza.length > 0 &&
                                    <div className="row p-2 ms-4">
                                        <div className={isCurrentMobileDevice() ? null : 'col-4 mt-3'} onClick={() => this.scaricaFileLocale(this.props.polizza?.id, this.props.polizza?.copiaPagamentoPolizza[0])}>
                                            <a href="#!">
                                                <Icon icon="it-file" /> {this.props.polizza?.copiaPagamentoPolizza[0]?.filename}
                                            </a>
                                        </div>
                                        <div className={isCurrentMobileDevice() ? null : 'col-2 mt-3'}  >
                                                <span className="pe-3" onClick={()=>{this.componentDidMount();this.props.resetFile(this.props.polizza)}}>
                                                    <a href="#!">
                                                                <Icon icon="it-delete" />
                                                    </a>
                                                </span>
                                        </div>
                                        <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                                {this.props.note === true &&
                                                    <>
                                                            <span ><FormGroup label={"Nota"} >
                                                                <GenericInput isDisabled={this.props.visualizzaFlag} key={'Nota'} id="notePagamento" name={'notePagamento'}
                                                                        defaultValue={(this.props.polizza?.notePagamento) ? this.props.polizza?.notePagamento : ''}
                                                                        placeholder='Inserisci nota (facoltativo)'
                                                                        onChange={(e)=>setDataItem(e, 'text','copiaPagamentoPolizza')}
                                                                />  </FormGroup>
                                                            </span>
                                                    </>
                                                }
                                        </div>
                                    </div>
                                }

                        {/*allegati DB*/}
                        {
                            this.props.polizza?.assicurazioneAllegati ? this.props.polizza?.assicurazioneAllegati.filter(
                                allegato =>
                                ( (this.props.tipologiaAllegato.includes(allegato.tipologiaAllegato)) && (
                                allegato.cancellato === 0 ) && (allegato.idPolizza === this.props.polizza['id'] && allegato.idIscrizione === this.props.polizza['idIscrizione'] )
                                )).map(
                                allegato => (
                                    <>
                                    <div className="row p-2 ms-4">
                                            <div className={isCurrentMobileDevice() ? null : 'col-4 mt-3'} onClick={() => this.ottieniAllegato(this.props.polizza?.id, allegato.idAllegato)} >
                                                <a href="#!">
                                                    <Icon icon="it-file" /> {allegato.titoloDocumento}
                                                </a>
                                            </div>
                                            <div className={isCurrentMobileDevice() ? null : 'col-2 mt-3'} >
                                                {this.props.visualizzaFlag  === false &&
                                                    <>
                                                        <span className="pe-3" onClick={() => this.rimuoviAllegato(allegato.idAllegato)} >
                                                            <a href="#!">
                                                                <Icon icon="it-delete" />
                                                            </a>
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                            <div className={isCurrentMobileDevice() ? null : "col-6"}>
                                                {this.props.note === true &&
                                                    <>
                                                            <span ><FormGroup label={"Nota"} >
                                                                <GenericInput isDisabled={this.props.visualizzaFlag} key={'Nota'} id="notePagamento" name={'notePagamento'}
                                                                        defaultValue={(allegato.descrizioneBreve) ? allegato.descrizioneBreve : ' '}
                                                                        placeholder='Inserisci nota (facoltativo)'
                                                                        onChange={(e)=>setDataItem(e, 'text','notePagamento')}
                                                                />  </FormGroup>
                                                            </span>
                                                    </>
                                                }
                                            </div>
                                    </div>
                                    </>
                                )
                            )
                            :  <div className="row p-4"><Label>Al momento non è possibile recuperare la lista degli allegati di questa polizza storica</Label></div>

                        }
                        {
                            this.state.nessunAllegatoFlag && this.props.visualizzaFlag && <div className="row p-4"><Label>Nessun allegato caricato</Label></div>
                        }
                    </div>
                }
            </>
        );
  }
}

export declare interface ListaAllegatiAssicurazioniProps {
  polizza?: any,
  tipologiaAllegato?: any,
  resetFile?: any,
  visualizzaFlag?: any,
  note?: any;
  nomeAllegato?: any,
  getSelectedValue?:any;
  datiPagamento?: any;
}

export default ListaAllegatiAssicurazioni;
