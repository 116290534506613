import { validateValue } from './FormValidation'

/**
 * Interfaccia
 */
export interface FormData {
  data: any
  errors: any
  set: any
  setError: any
}

export const createFormData = (data, setData, errors, setError): FormData => {
  return {
    data: data,
    set: setData,
    errors: errors,
    setError: setError,
  }
}

/**
 * Check if formData has error
 * @param dataRef
 */
export const haveFormError = (dataRef) => {
  if (dataRef?.errors) {
    for (const e of dataRef.errors) {
      if (e.hasError) {
        return true
      }
    }
  }
  return false
}

export const mockLoad = (data, time = 500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, time)
  })
}

const setValue = (obj: any, index, value) => {
  if (Array.isArray(obj)) {
    obj[index] = value
    return [...obj]
  }
  return {
    ...obj,
    [index]: value,
  }
}

/**
 * Imposta il valore di un attributo dell'oggetto/array.
 * L'attributo è indicato attraverso un array che ne identifica il path
 * es: Per settare il valore di attr dell'oggetto obj
 * obj: {
 *   attr1:{
 *     attr2:{
 *       attr:3
 *     }
 *   }
 * }
 * path:[attr1,attr2,attr]
 * value:2
 *
 * return: {
 *   attr1:{
 *     attr2:{
 *       attr:2
 *     }
 *   }
 * }
 *
 * @param obj
 * @param path
 * @param value
 */
export const addObjectValue = (obj, path, value) => {
  if (path.length === 1) {
    return setValue(obj, path[0], value)
  } else {
    const first = path.shift()
    const attrValue = obj[first] ? obj[first] : {}
    return setValue(obj, first, addObjectValue(attrValue, path, value))
  }
}

export const getObjectValue = (obj, path) => {
  const first = path.shift()
  if (obj[first]) {
    if (path.length > 0) {
      return getObjectValue(obj[first], path)
    } else {
      return obj[first]
    }
  }
  return ''
}

export const findValidationRule = (code, rules) => {
  if (rules[code]) {
    return rules[code]
  }
  if (rules.regex) {
    for (const rule of rules.regex) {
      if (code.match(rule.regex)) {
        return rule.rules
      }
    }
  }
  return null
}

/**
 * Aggiorna il valore di un attributo in un form
 * @param formData riferimenti del form
 * @param code codice input da cambiare
 * @param value valore da cambiare
 * @param formRules regole validazione form
 */
export const handleChangeFormData = (formData: FormData, code: string, value: any, formRules: any) => {
  let updatedFormData = null
  // always update using the previous state, in case the state is updated asynchronously
  formData.set((prevData) => {
    updatedFormData = addObjectValue(prevData, code.split('.'), value)
    return updatedFormData
  })
  const rules = findValidationRule(code, formRules)
  if (rules) {
    formData.setError((prevErrors) => ({
      ...prevErrors,
      [code]: validateValue(rules, value, updatedFormData),
    }))
  }
}

/**
 * Valida i dati di un form con le regole passate come parametro
 * @param formData
 * @param formRules
 */
export const validateFormData = (formData: FormData, formRules: any, inputs: any) => {
  const errors = {}
  for (const code of inputs) {
    const value = getObjectValue(formData.data, code.split('.'))
    const rules = findValidationRule(code, formRules)
    if (rules) {
      const error = validateValue(rules, value, formData.data)
      if (error) {
        errors[code] = error
      }
    }
  }
  formData.setError(errors)
  return Object.keys(errors).length === 0
}

export const getTabActiveFormId = (refForm, tab) => {
  return refForm.current
    .filter((data) => {
      return data.tab === tab && data.cmp.isMount === true
    })
    .map((data) => {
      return data.cmp.props.id
    })
}
export const getActiveFormId = (refForm) => {
  return refForm.current
    .filter((data) => {
      return data.cmp.isMount === true
    })
    .map((data) => {
      return data.cmp.props.id
    })
}

export const objSubset = (obj, attributes) => {
  return Object.keys(obj)
    .filter((key) => attributes.indexOf(key) >= 0)
    .reduce((obj2, key) => ((obj2[key] = obj[key]), obj2), {})
}
