export function accessibleRouteChangeHandler() {
  return window.setTimeout(() => {
    const mainContainer = document.getElementById('primary-app-container')
    if (mainContainer) {
      mainContainer.focus()
    }
  }, 50)
}

export function formatDays(days: number): string {
  const years = Math.floor(days / 365)
  const months = Math.floor((days % 365) / 30)
  const remainingDays = (days % 365) % 30
  return `${years} Anni, ${months} Mesi, ${remainingDays} Giorni`
}
