export default {
    docenza: {
        'isRequired': true
    },
    sdaf: {
        'isRequired': true
    },
    da: {
        'isRequired': true
    },
    al: {
        'isRequired': true
    },
    committente: {
        'isRequired': true
    },
    desc_attivita: {
        'isRequired': true
    },
}
