import React, { useMemo } from 'react'
import { Form } from 'design-react-kit'
import { useSdaf } from 'src/hooks/useReqOptions'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import {
  AMBITI_PUBBLICAZIONE_RIVISTE_OPTIONS,
  TIPOLOGIE_ACCREDITAMENTO_VALUES,
  TIPOLOGIE_RIVISTA_OPTIONS
} from 'src/formazione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormDatiAccreditamento: React.FunctionComponent<FormDatiAccreditamentoProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = 'datiAccreditamento'
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])

  const sdaf = useSdaf()
  const ambiti = AMBITI_PUBBLICAZIONE_RIVISTE_OPTIONS
  const tipologie = TIPOLOGIE_RIVISTA_OPTIONS

  const showFields = [TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO, TIPOLOGIE_ACCREDITAMENTO_VALUES.SEMPLICE].includes(data?.[objKey]?.tipoAccreditamento?.value)
  const isTotal = data?.[objKey]?.tipo_accr?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.TOTALE
  const isMulti = data?.[objKey]?.tipo_accr?.value === TIPOLOGIE_ACCREDITAMENTO_VALUES.COMPLESSO

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
            <WrpInput
              isMulti={isMulti}
              as={GenericSelect}
              id={'settoriDisciplinari'}
              placeholder="Selezione settore disciplinare"
              label="Settore Disciplinare"
              suffix="*"
              defaultOptions={sdaf}
              data={data}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
            <WrpInput
              isMulti={isMulti}
              as={GenericSelect}
              id={'ambitoPubblicazione'}
              placeholder="Selezione ambito di pubblicazione"
              label="Ambito di pubblicazione"
              suffix="*"
              defaultOptions={ambiti}
              data={data}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
            <WrpInput
              isMulti={isMulti}
              as={GenericSelect}
              id={'tipologiaRivista'}
              placeholder="Selezione tipologia rivista"
              label="Tipologia rivista"
              suffix="*"
              defaultOptions={tipologie}
              data={data}
              errors={errors}
            />
          </div>
        </div>
      </Form>
    </GenericCard>
  )
}

interface FormDatiAccreditamentoProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiAccreditamento
