export const wait = (mm)=>{
  return new Promise<void>((resolve)=>{
    setTimeout(()=>{
      resolve();
    },mm);
  })
}


export const formatEur = (value:number|string)=>{
    const v = value || "0";
    return v.toLocaleString('it-IT', {
      style: 'currency',
      currency: 'EUR'
    });


}