import React, { useCallback, useRef, useState } from 'react'
import { Form } from 'design-react-kit'
import FormSubmit, { STATUS } from '../../../../../frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { STATI_AGENZIE_VALUES } from 'src/formazione'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import { createFormData, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { useHistory } from 'react-router-dom'

const FormRigetto: React.FunctionComponent<FormRigettoProps> = ({ id, stato, tipo }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    note_acc_rifiuto: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp) => {
    refForm.current.push({
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const isRevoca = stato === STATI_AGENZIE_VALUES.ACCREDITATA && tipo === 2
  const isCancella = stato === STATI_AGENZIE_VALUES.ACCREDITATA && tipo === 3


  const submit = useCallback(
    async (e) => {
      try {
        // TODO AGENZIA API check
        const inputs = ['note_acc_rifiuto']
        const valid = validateFormData(formData, rules, inputs)
        const data = formData?.data

        let _stato = STATI_AGENZIE_VALUES.NO_ACCREDITATA
        // if (stato === STATI_AGENZIE_VALUES.FORMALE) _stato = 8
        // if (stato === STATI_AGENZIE_VALUES.MERITO) _stato = 5
        if (stato === STATI_AGENZIE_VALUES.ACCREDITATA) _stato = 10

        if (!valid) {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return
        }

        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneAccAgenziaFormativaPatch(
          {
            ...data,
            id,
            // TODO check stato
            stato: _stato,
          },
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id, stato, data]
  )

  let title = 'Rigetto richiesta'
  let label = 'Motivo di rigetto'

  if(isRevoca) {
    title = 'Revoca richiesta'
    label = 'Motivo di revoca'
  }
  if(isCancella) {
    title = 'Cancella richiesta'
    label = 'Motivo di cancellazione'
  }

  return (
    <FormSubmit title={title} buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Form>
        <TextArea
          labelBottom
          id="note_acc_rifiuto"
          placeholder={label}
          label={label + '*'}
          value={data?.note_acc_rifiuto}
          errorMessage={errors?.note_acc_rifiuto}
          onInit={onInitInput(0)}
          onChange={handleInput}
        />
      </Form>
    </FormSubmit>
  )
}

interface FormRigettoProps {
  id: any
  stato: any
  tipo?:number
}

export default FormRigetto
