import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import useFrontOffice from 'src/hooks/useFrontOffice'
import { PageSection, Title } from '@patternfly/react-core'
import { Label } from 'design-react-kit'
import styled from 'styled-components'

import {
  PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO,
  PATH_TO_BO_ELENCO_ENTI_ESTERNI,
  PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO,
  PATH_TO_FO_ELENCO_ENTI_ESTERNI
} from 'src/app/utils/RoutesConstants'
import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import formValidationRules from 'src/backoffice/app/pages/formazione/formEntiEsterni/FormRules'
import { requestMapperAgenzia, responseMapperAgenzia } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import { DETAILS_ACTIONS, STATI_AGENZIE_OPTIONS } from 'src/formazione'
import GenericWizard from 'src/backoffice/app/components/GenericWizard'

// Form components
import FormDatiAgenzia from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiAgenzia'
import FormDatiSedeLegale from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiSedeLegale'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'

// Form azioni
import FormIntegrazione from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormIntegrazione'
import FormApprovazioneFormale from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneFormale'
import FormApprovazioneMerito from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneMerito'
import FormApprovazioneMinisteriale
  from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormApprovazioneMinisteriale'
import FormRigetto from 'src/backoffice/app/pages/formazione/formIscrizioneEF/FormRigetto'

import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import FormDateInizioFine from './formEntiEsterni/FormDateInizioFine'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`
const Spacer = () => (
  <>
    <br></br>
  </>
)

const DettaglioEnteEsterno: React.FunctionComponent = (props) => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_ENTI_ESTERNI : PATH_TO_BO_ELENCO_ENTI_ESTERNI
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO : PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO
  const basePath = { label: 'Elenco Enti Esterni', link: pathToElenco }
  const myPath = {
    label: 'Dettaglio Ente Esterno',
    link: pathToDettaglio,
  }

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [stato, setStato] = useState<any>(-1)
  const modalita = searchParams.get('modalita')
  const viewOnly = modalita !== DETAILS_ACTIONS.MODIFICA
  // Submit state
  const [editStatus, setEditStatus] = useState<any>()
  const [editMessage, setEditMessage] = useState<any>()

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  useEffect(() => {
    const doRequest = async (id) => {
      const { data } = await getFormazioneResourceApi().msfoFormazioneDettaglioAccAgenziaFormativaGet(id, getDefaultAxiosConfig())
      const obj = await responseMapperAgenzia(data)
      setData(obj)
      setStato(data?.datiAccreditamento?.stato)
    }
    doRequest(id)
  }, [id])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({ tab, cmp })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onBack = () => {
    history.replace(pathToElenco)
  }

  const isValid = useCallback(
    (toIndex) => {
      for (let index = 0; index <= toIndex; index++) {
        const inputs = getTabActiveFormId(refForm, index)
        const isValid = validateFormData(formData, formValidationRules, inputs)
        if (!isValid) return false
      }
      return true
    },
    [formData]
  )

  const onSubmit = useCallback(
    async (e) => {
      try {
        // TODO change to include allegati
        if (!isValid(5)) {
          setEditStatus(STATUS.FAILURE)
          setEditMessage('Controllare i dati inseriti')
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return null
        }
        setEditStatus(STATUS.LOADING)

        const _data = await requestMapperAgenzia(formData.data, 3)
        _data.datiAccreditamento.id = id
        _data.datiAccreditamento.tipo_ag_form = 3
        _data.datiAccreditamento.stato = 6
        // TODO REMOVE
        delete _data.allegati
        await getFormazioneResourceApi().msfoFormazioneAggiornaAgenziaFormativaPost(_data, getDefaultAxiosConfig())

        setEditStatus(STATUS.SUCCESS)
        setEditMessage('Dati salvati con successo')
        setTimeout(() => {
          onBack()
        }, 500)
      } catch (e) {
        setEditStatus(STATUS.FAILURE)
        setEditMessage('Impossibile salvare i dati')
      }
      return null
    },
    [formData, id]
  )

  const forms = [
    <FormDatiAgenzia title="Dati Anagrafici" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDatiSedeLegale title="Sede Legale" data={data} errors={errors} onInitInput={onInitInput(1)} handleInput={handleInput} viewOnly={viewOnly} />,
    <FormDateInizioFine title="Date convenzione" data={data} errors={errors} onInitInput={onInitInput(2)} handleInput={handleInput} viewOnly={viewOnly} />,
  ]
  const steps = [
    {
      id: 0,
      name: 'Dati Anagrafici',
      component: forms[0],
    },
    {
      id: 1,
      name: 'Sede Legale',
      component: forms[1],
    },
    {
      id: 2,
      name: 'Date convenzione',
      component: forms[2],
    },
  ]
  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>

        <Spacer />

        {stato !== -1 && stato !== 10 && <p>Stato : {STATI_AGENZIE_OPTIONS.find((opt) => opt.value === stato)?.label}</p>}
        {stato !== -1 && stato === 10 && <p>Stato : Cancellato</p>}

        <Spacer />

        {modalita === DETAILS_ACTIONS.RICHIESTA_INTEG && <FormIntegrazione id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_FORMALE && <FormApprovazioneFormale id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_MERITO && <FormApprovazioneMerito id={id} />}
        {modalita === DETAILS_ACTIONS.APPROVAZIONE_MINISTERIALE && <FormApprovazioneMinisteriale id={id} />}
        {modalita === DETAILS_ACTIONS.RIGETTA && <FormRigetto id={id} stato={stato} tipo={3}/>}
        {modalita === DETAILS_ACTIONS.MODIFICA ? (
          <>
            <FormSubmit title="Conferma modifica" buttonLabel="Modifica" alertMessage={editMessage} status={editStatus} onSubmit={onSubmit}>
              <Label>Per modificare l'ente cliccare "Modifica".</Label>
            </FormSubmit>
            {forms}
            <LinkStyled>
              <Link to={pathToElenco}>Torna alla lista</Link>
            </LinkStyled>
          </>
        ) : (
          <GenericWizard
            steps={steps}
            backButtonText={'PRECEDENTE'}
            nextButtonText={'SUCCESSIVO'}
            cancelButtonText={'Torna alla lista'}
            onClickNext={null}
            onClickCancel={onBack}
            height="auto"
          />
        )}
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </PageSection>
  )
}

export default DettaglioEnteEsterno
