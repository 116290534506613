'use strict';

var utils = require('../../../utils/utils');
var BaseXform = require('../base-xform');

var WorksheetPropertiesXform = module.exports = function() {
};

utils.inherits(WorksheetPropertiesXform, BaseXform, {

  render: function(xmlStream, model) {
    xmlStream.leafNode('workbookPr', {
      date1904: model.date1904 ? 1 : undefined,
      defaultThemeVersion: 164011,
      filterPrivacy: 1
    });
  },

  parseOpen: function(node) {
    if (node.name === 'workbookPr') {
      this.model = {
        date1904: node.attributes.date1904 === '1'
      };
      return true;
    }
    return false;
  },
  parseText: function() {
  },
  parseClose: function() {
    return false;
  }
});
