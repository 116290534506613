export default {
    cap_res: {
        isRequired: true
    },
    civico_res: {
        isRequired: true
    },
    cod_fisc: {
        isRequired: true
    },
    cognome: {
        isRequired: true
    },
    comune_res: {
        isRequired: true
    },
    data_nasc: {
        isRequired: true
    },
    dati_iscr_ordine: {
        isRequired: true
    },
    indirizzo_res: {
        isRequired: true
    },
    luogo_nasc: {
        isRequired: true
    },
    nazione_nasc: {
        isRequired: true
    },
    nazione_res: {
        isRequired: true
    },
    nome: {
        isRequired: true
    },
    provincia_nasc: {
        isRequired: true
    },
    provincia_res: {
        isRequired: true
    },
    tipologia_iscrizione: {
        isRequired: true
    },
    tipo_diploma: {
        isRequired: true
    },
    tipo_laurea: {
        isRequired: true
    },
    tipo_laurea_magistrale: {
        isRequired: true
    },
  }