import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import useFrontOffice from 'src/hooks/useFrontOffice'
import { Button, PageSection, Title } from '@patternfly/react-core'
import { Label } from 'design-react-kit'
import styled from 'styled-components'

import {
  PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO,
  PATH_TO_BO_ELENCO_EVENTI_CATALOGO,
  PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO,
  PATH_TO_FO_ELENCO_EVENTI_CATALOGO,
  RO_GEST_FORM_CONAF
} from 'src/app/utils/RoutesConstants'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'

import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { responseMapperEvento } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import formValidationRules from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormRules'
import { convertBooleans } from 'src/utilities/utility'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { DETAILS_ACTIONS, STATI_EVENTO_CATALOGO_OPTIONS, STATI_EVENTO_CATALOGO_VALUES } from 'src/formazione'
import GenericWizard from 'src/backoffice/app/components/GenericWizard'

// Form components
import FormDatiEvento from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormDatiEvento'
import FormDatiAttivita from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormDatiAttivita'
import FormDatiInterventi from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormDatiInterventi'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import GenericCard from 'src/frontend/app/components/GenericCard'

// Form azioni
import FormIntegrazione from 'src/backoffice/app/pages/formazione/formEventiCatalogo/FormIntegrazione'
import FormApprovazione from 'src/backoffice/app/pages/formazione/formEventiCatalogo/FormApprovazione'
import FormRigetto from 'src/backoffice/app/pages/formazione/formEventiCatalogo/FormRigetto'
import FormAnnullamento from 'src/backoffice/app/pages/formazione/formEventiCatalogo/FormAnnullamento'
import FormIscrizione from 'src/backoffice/app/pages/formazione/formEventiCatalogo/FormIscrizione'
import FormCancellazione from 'src/backoffice/app/pages/formazione/formEventiCatalogo/FormCancellazione'
import { ottieniAllegato } from './formEntiEsterni/formUtils'
import { FlexDiv } from './DettaglioRichiestaEsonero'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { GenericSpinner } from '../../components'
import moment from 'moment'

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: end;
`

const Spacer = () => (
  <>
    <br></br>
  </>
)

const DettaglioEventoCatalogo: React.FunctionComponent = (props) => {
  const isFrontOffice = useFrontOffice()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_EVENTI_CATALOGO : PATH_TO_BO_ELENCO_EVENTI_CATALOGO
  const pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO : PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO
  const basePath = { label: 'Elenco Eventi a Catalogo', link: pathToElenco }
  const myPath = {
    label: 'Evento a Catalogo',
    link: pathToDettaglio,
  }

  const MyDiv = FlexDiv

  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const modalita = searchParams.get('modalita')
  const viewOnly = modalita !== DETAILS_ACTIONS.MODIFICA && modalita !== DETAILS_ACTIONS.CLONA

  // Submit state
  const [editStatus, setEditStatus] = useState<any>()
  const [editMessage, setEditMessage] = useState<any>()
  const [stato, setStato] = useState<any>(-1)

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  useEffect(() => {
    const doRequest = async (id) => {
      setLoading(true)
      try {
        const { data } = await getFormazioneResourceApi().msfoFormazioneEventoACatalogoGet(id, getDefaultAxiosConfig())
        const _data = await responseMapperEvento(data, modalita === DETAILS_ACTIONS.CLONA)
        if (viewOnly) {
          let max = null
          if (_data.evento && _data.evento.data_inizio && _data.evento.data_fine) {
            const diffInDays = moment(_data.evento.data_fine).diff(moment(_data.evento.data_inizio), 'days')
            max = Math.floor(diffInDays)
          }

          if (!_data.tipologiaAttivita) {
            _data.crediti_riconosciuti = 0
          } else {
            if (_data.tipologiaAttivita.um === 'H') {
              const totOre = _data.totOreCFU
              if (totOre && totOre.indexOf(':') > 0) {
                const ore = parseInt(totOre.split(':')[0])
                const minuti = parseInt(totOre.split(':')[1])
                _data.crediti_riconosciuti = ((ore + minuti / 60) / _data.tipologiaAttivita.ore_evt_battute) * _data.tipologiaAttivita.max_cfp_evt
              } else {
                _data.crediti_riconosciuti = _data.tipologiaAttivita.max_cfp_evt
              }

              if (max) {
                _data.crediti_riconosciuti = _data.crediti_riconosciuti > max ? max : _data.crediti_riconosciuti
              }
            } else {
              if (_data.tipologiaAttivita.cod === 'AO' || _data.tipologiaAttivita.cod === 'AF') {
                _data.crediti_riconosciuti = 0.25
              } else {
                _data.crediti_riconosciuti = _data.evento.crediti ? _data.evento.crediti : _data.tipologiaAttivita.max_cfp_evt
              }
            }
          }
          _data.crediti_riconosciuti = _data.evento && _data.evento.crediti ? _data.evento.crediti : _data.crediti_riconosciuti
        }
        setData(_data)
        setStato(_data.evento.accreditato)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    doRequest(id)
  }, [id])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({ tab, cmp })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onBack = () => {
    history.replace(pathToElenco)
  }

  const isValid = useCallback(
    (toIndex) => {
      for (let index = 0; index <= toIndex; index++) {
        const inputs = getTabActiveFormId(refForm, index)
        formData.data.__IS_EDIT_MODE = modalita === DETAILS_ACTIONS.MODIFICA
        const isValid = validateFormData(formData, formValidationRules, inputs)
        if (!isValid) return false
      }
      return true
    },
    [formData]
  )

  const onSubmit = useCallback(
    async (e) => {
      try {
        if (!isValid(2)) {
          setEditStatus(STATUS.FAILURE)
          setEditMessage('Controllare i dati inseriti')
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          return null
        }
        setEditStatus(STATUS.LOADING)

        if (formData && formData.data && formData.data.interventi) {
          for (const intervento of formData.data.interventi) {
            if (!intervento?.dalle || intervento?.dalle === '') {
              intervento.dalle = null
            }
            if (!intervento?.alle || intervento?.alle === '') {
              intervento.alle = null
            }
          }
        }
        if (modalita === DETAILS_ACTIONS.MODIFICA && isConafGest ){
          if (!formData.data.tipologiaAttivita) {
            formData.data.crediti = 0
          } else {
            if (formData.data.tipologiaAttivita.um === 'H') {
              const totOreCFU = formData.data.totOreCFU
              if (totOreCFU && totOreCFU.indexOf(':') > 0) {
                const ore = parseInt(totOreCFU.split(':')[0])
                const minuti = parseInt(totOreCFU.split(':')[1])
                formData.data.crediti = ((ore + minuti / 60) / formData.data.tipologiaAttivita.ore_evt_battute) * formData.data.tipologiaAttivita.max_cfp_evt
              } else {
                formData.data.crediti = formData.data.tipologiaAttivita.max_cfp_evt
              }

              if (formData.data.max) {
                formData.data.crediti = formData.data.crediti > formData.data.max ? formData.data.max : formData.data.crediti
              }
            } else {
              if (formData.data.tipologiaAttivita.cod === 'AO' || formData.data.tipologiaAttivita.cod === 'AF') {
                formData.data.crediti = 0.25
              }
            }
          }

          formData.data.crediti = Math.round(formData.data.crediti * 1000) / 1000
        }
        if (modalita === DETAILS_ACTIONS.CLONA) {
          if (!formData.data.tipologiaAttivita) {
            formData.data.crediti = 0
          } else {
            if (formData.data.tipologiaAttivita.um === 'H') {
              const totOreCFU = formData.data.totOreCFU
              if (totOreCFU && totOreCFU.indexOf(':') > 0) {
                const ore = parseInt(totOreCFU.split(':')[0])
                const minuti = parseInt(totOreCFU.split(':')[1])
                formData.data.crediti = ((ore + minuti / 60) / formData.data.tipologiaAttivita.ore_evt_battute) * formData.data.tipologiaAttivita.max_cfp_evt
              } else {
                formData.data.crediti = formData.data.tipologiaAttivita.max_cfp_evt
              }

              if (formData.data.max) {
                formData.data.crediti = formData.data.crediti > formData.data.max ? formData.data.max : formData.data.crediti
              }
            } else {
              if (formData.data.tipologiaAttivita.cod === 'AO' || formData.data.tipologiaAttivita.cod === 'AF') {
                formData.data.crediti = 0.25
              }
            }
          }

          formData.data.crediti = Math.round(formData.data.crediti * 1000) / 1000

          await getFormazioneResourceApi().msfoFormazioneInserisciEventoACatalogoPost(convertBooleans({ ...formData.data }), getDefaultAxiosConfig())
        } else {
          await getFormazioneResourceApi().msfoFormazioneAggiornaEventoACatalogoCompletePost(
            convertBooleans({ ...formData.data, idCorso: id }),
            getDefaultAxiosConfig()
          )
        }
        setEditStatus(STATUS.SUCCESS)
        setEditMessage('Dati salvati con successo')
        setTimeout(() => {
          onBack()
        }, 500)
      } catch (e) {
        setEditStatus(STATUS.FAILURE)
        setEditMessage('Impossibile salvare i dati')
      }
      return null
    },
    [formData]
  )

  const isConafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])
  const disableOverride = !viewOnly && !isConafGest && modalita === DETAILS_ACTIONS.MODIFICA && stato === 1
  const clona = modalita === DETAILS_ACTIONS.CLONA

  const forms = [
    <FormDatiEvento
      title="Dati Evento"
      data={data}
      errors={errors}
      onInitInput={onInitInput(0)}
      handleInput={handleInput}
      viewOnly={viewOnly}
      disableOverride={disableOverride}
      clona={clona}
    />,
    <FormDatiAttivita
      title="Dati Attivita"
      data={data}
      errors={errors}
      onInitInput={onInitInput(1)}
      handleInput={handleInput}
      viewOnly={viewOnly}
      disableOverride={disableOverride}
    />,
    <FormDatiInterventi
      title="Dati Interventi"
      data={data}
      errors={errors}
      onInitInput={onInitInput(2)}
      handleInput={handleInput}
      viewOnly={TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF]) ? viewOnly : stato !== 4 && modalita !== DETAILS_ACTIONS.CLONA}
      stato={stato}
      modalita={modalita}
    />,
  ]

  const steps = [
    {
      id: 0,
      name: 'Dati Evento',
      component: forms[0],
    },
    {
      id: 1,
      name: 'Dati Attivita',
      component: forms[1],
    },
    {
      id: 2,
      name: 'Dati Interventi',
      enableNext: false,
      component: forms[2],
    },
  ]

  if (loading) {
    return <GenericSpinner size={'xl'} />
  }

  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>

        <MyDiv>
          {data && data.logoAllegato && data.logoAllegato.idAllegato && (
            <Button
              variant="primary"
              onClick={() => {
                let idAllegato: string | null = data.logoAllegato.idAllegato

                if (idAllegato) {
                  ottieniAllegato(idAllegato)
                } else {
                  console.error('IDALLEGATO MISSING')
                }
              }}
            >
              Scarica logo
            </Button>
          )}

          {stato !== -1 && <p>Stato : {STATI_EVENTO_CATALOGO_OPTIONS.find((opt) => opt.value === stato)?.label}</p>}
        </MyDiv>

        <Spacer />

        {modalita === DETAILS_ACTIONS.DETTAGLIO && stato === STATI_EVENTO_CATALOGO_VALUES.RICHIESTA_INTEG ? (
          <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
            <Title headingLevel="h3">{data?.evento?.motivi_non_autor}</Title>
          </GenericCard>) : null }

        {modalita === DETAILS_ACTIONS.APPROVA && <FormApprovazione id={id} />}
        {modalita === DETAILS_ACTIONS.RICHIESTA_INTEG && <FormIntegrazione id={id} />}
        {modalita === DETAILS_ACTIONS.RIGETTA && <FormRigetto id={id} />}
        {modalita === DETAILS_ACTIONS.ANNULLA && <FormAnnullamento id={id} />}
        {modalita === DETAILS_ACTIONS.ISCRIVI && <FormIscrizione id={id} propsData={data} />}
        {modalita === DETAILS_ACTIONS.CANCELLA_ISCRIZIONE && <FormCancellazione id={id} />}

        {modalita === DETAILS_ACTIONS.MODIFICA && stato === STATI_EVENTO_CATALOGO_VALUES.RICHIESTA_INTEG ? (
          <GenericCard cardHeader={<Title headingLevel="h2">Sono richieste integrazioni. Motivo:</Title>}>
            <Title headingLevel="h3">{data?.evento?.motivi_non_autor}</Title>
          </GenericCard>
        ) : null}

        {modalita === DETAILS_ACTIONS.MODIFICA && (
          <>
            <FormSubmit
              title="Conferma modifica"
              buttonLabel={
                !TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF]) && stato === STATI_EVENTO_CATALOGO_VALUES.RICHIESTA_INTEG
                  ? 'Modifica ed Invia'
                  : 'Modifica'
              }
              alertMessage={editMessage}
              status={editStatus}
              onSubmit={(e) => {
                onSubmit(e)
              }}
            >
              <Label>Per modificare l'evento cliccare "Modifica".</Label>
            </FormSubmit>
            {forms}
            <LinkStyled>
              <Link to={pathToElenco}>Torna alla lista</Link>
            </LinkStyled>
          </>
        )}

        {modalita === DETAILS_ACTIONS.CLONA && (
          <>
            <FormSubmit
              title="Conferma clonazione"
              buttonLabel="Clona"
              alertMessage={editMessage}
              status={editStatus}
              onSubmit={(e) => {
                onSubmit(e)
              }}
            >
              <Label>Per clonare l'evento cliccare "Clona".</Label>
            </FormSubmit>
            {forms}
            <LinkStyled>
              <Link to={pathToElenco}>Torna alla lista</Link>
            </LinkStyled>
          </>
        )}

        {modalita !== DETAILS_ACTIONS.MODIFICA && modalita !== DETAILS_ACTIONS.CLONA && (
          <GenericWizard
            steps={steps}
            backButtonText={'PRECEDENTE'}
            nextButtonText={'SUCCESSIVO'}
            cancelButtonText={'Torna alla lista'}
            onClickNext={null}
            onClickCancel={onBack}
            height="auto"
          />
        )}
      </div>
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </PageSection>
  )
}

export default DettaglioEventoCatalogo
