import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './app/App'
import { initAdminClient } from './keycloak/context/auth/AdminClient'
import { initI18n } from './keycloak/context/auth/i18n'
import './index.css'
import { getSessionItem } from './app/utils/Storage'
import AppWithoutLogin from './app/AppWithoutLogin'

export async function initialize() {
  const { keycloak, adminClient } = await initAdminClient();
  let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  if (getSessionItem("isLoginButtonClicked") === true) {
    await initI18n(adminClient);
    root.render(
      <App keycloak={keycloak} adminClient={adminClient} />
    );
  } else {
    root.render(
      <AppWithoutLogin />
    );
  }
}

initialize();
export default App;
