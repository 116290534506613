import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { PageSection, Title } from '@patternfly/react-core'

import {
  PATH_TO_BO_ELENCO_POF,
  PATH_TO_FO_ISCRIZIONE_POF,
  PATH_TO_FO_VISUALIZZA_POF
} from 'src/app/utils/RoutesConstants'

import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { generateRequestFilter, generateRequestSort } from 'src/app/utils/ApiUtils'
import GenericSelect from 'src/frontend/app/components/Form/Select'

import { getFiltersYear, STATI } from 'src/model/PianiFormativi'
import { GenericSpinner } from 'src/backoffice/app/components'
import { Alert, Button } from 'design-react-kit'

import { StyledPage } from './styles'
import GenericCard from 'src/frontend/app/components/GenericCard'

const Index: React.FunctionComponent = () => {
  const history = useHistory()
  const [year, setYear] = useState<any>({
    value: moment().format('YYYY'),
    label: moment().format('YYYY'),
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [listPof, setListPof] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const {
          data: { items },
        } = await getFormazioneResourceApi().msfoFormazioneListaPformativiForUserGet(
          generateRequestFilter([{ field: 'anno', value: year.value }]),
          100,
          -1,
          generateRequestSort('nr_invio', 'DESC'),
          getDefaultAxiosConfig()
        )
        setListPof(items)
        setLoading(false)
      } catch (e) {
        console.error('LOAD PDATA', e)
        setLoading(false)
        setError("Ops! C'è stato un errore nel caricamento dei piani formativi.")
      }
    })()
  }, [year])

  const onCreatePof = useCallback(() => {
    history.push(PATH_TO_FO_ISCRIZIONE_POF + '?year=' + year.value)
  }, [year])

  const onUpdatePof = useCallback((pof) => {
    history.push(PATH_TO_FO_ISCRIZIONE_POF + '?id=' + pof.id)
  }, [])

  const onShowDetailPof = useCallback((pof) => {
    history.push(PATH_TO_FO_VISUALIZZA_POF + '?id=' + pof.id)
  }, [])

  const onCreateVariantPof = useCallback(() => {
    let max_nr_invio = 0
    let pofidtosend = ''
    for (const element of listPof) {
      if (element.nr_invio >= max_nr_invio) {
        max_nr_invio = element.nr_invio
        pofidtosend = element.id
      }
    }
    history.push(PATH_TO_FO_ISCRIZIONE_POF + '?from=' + pofidtosend)
  }, [listPof])

  const actions: any = []
  if (!loading && listPof != null) {
    // nessun piano già definito
    if (listPof.length == 0) {
      actions.push(
        <Button color={'primary'} onClick={onCreatePof}>
          Crea nuovo piano formativo
        </Button>
      )
    } else {
      // variante solo di piani approvati/rifiutati
      let canCreateVariant = true
      listPof.forEach((v) => {
        if (v.stato !== STATI.ACCETTATA && v.stato !== STATI.RIFIUTATA && v.stato !== STATI.SEGNALATA && v.stato !== STATI.SEGNALATA_ALT) {
          canCreateVariant = false
        }
      })
      if (canCreateVariant) {
        actions.push(
          <Button color={'primary'} onClick={onCreateVariantPof}>
            Crea variante piano formativo
          </Button>
        )
      }
    }
  }

  return (
    <StyledPage>
      <PageSection>
        <div className="container">
          <GenericBreadCrumb
            paths={[
              {
                label: 'Piani Formativi',
                link: PATH_TO_BO_ELENCO_POF,
              },
            ]}
          />
          <Title headingLevel="h1" size="lg">
            Piani Formativi {year.value}
          </Title>
          <div className={'topbar-selector'}>
            <div className={'year-selector'}>
              Seleziona un anno
              <GenericSelect
                id={'year'}
                defaultOptions={getFiltersYear(2011).map((y) => {
                  return { value: y, label: y }
                })}
                value={year}
                onChange={(c, v) => setYear(v)}
              />
            </div>
            <div className={'actions'}>{actions}</div>
          </div>
          {loading && <GenericSpinner label={''} />}
          <div className={'list-pof'}>
            {listPof != null &&
              listPof.map((pof, i) => {
                const title = i == listPof.length - 1 ? `Piano formativo ${year.value}` : `Variante ${listPof.length - i - 1} Piano formativo ${year.value} `
                return (
                  <GenericCard cardHeader={title} key={i} className={'pof-detail'}>
                    <div className={'status'}>
                      {pof.stato === STATI.ACCETTATA && <span className={'text-success'}>Piano approvato il {moment(pof.data_prot).format('DD/MM/YYYY')}</span>}
                      {pof.stato === STATI.RIFIUTATA && <span className={'text-danger'}>Piano rifiutato il {moment(pof.data_prot).format('DD/MM/YYYY')}</span>}
                      {pof.stato === STATI.PRESENTATA && `Piano presentato il ${moment(pof.data_modifica).format('DD/MM/YYYY HH:mm')}`}
                      {pof.stato === STATI.RICHIESTA_INTEGRAZIONI && <span className={'text-warning'}>Richiesta integrazioni per questo piano</span>}
                      {(pof.stato === STATI.SEGNALATA || pof.stato === STATI.SEGNALATA_ALT) && (
                        <span className={'text-warning'}>Il piano formativo è stato segnalato: {pof?.integr_descr_gen_pform}</span>
                      )}
                      {pof.stato === STATI.NON_COMPLETA && 'Da completare'}
                    </div>
                    <div className={'actions'}>
                      {(pof.stato === STATI.NON_COMPLETA || pof.stato === STATI.RICHIESTA_INTEGRAZIONI || (pof.stato === STATI.SEGNALATA || pof.stato === STATI.SEGNALATA_ALT)) && (
                        <Button onClick={() => onUpdatePof(pof)}>Modifica</Button>
                      )}
                      {pof.stato !== STATI.NON_COMPLETA && pof.stato !== STATI.RICHIESTA_INTEGRAZIONI && (
                        <Button onClick={() => onShowDetailPof(pof)}>Visualizza</Button>
                      )}
                    </div>
                  </GenericCard>
                )
              })}
            {listPof != null && listPof.length === 0 && !loading && !error && (
              <Alert color={'info'}>Non hai ancora presentato un piano formativo per l'anno {year.value}</Alert>
            )}
            {error && <Alert color={'danger'}>{error}</Alert>}
          </div>
          <div style={{ height: 100 }}></div>
        </div>
      </PageSection>
    </StyledPage>
  )
}

export default Index
