import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericSpinner from '../components/GenericSpinner'
import {
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RIFIUTA,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA,
  RO_GEST_UTEN_ODAF
} from 'src/app/utils/RoutesConstants'
import { GenericAsyncTableData } from '../components/GenericAsyncTableData'
import { generateRequestSort } from 'src/app/utils/ApiUtils'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getCambioSezioneResourceApi, getDefaultAxiosConfig } from 'src/app/utils/ManagerRestGateway'

const STATI = {
  1: 'INVIATA',
  2: "CHIUSA",
  3: "RESPINTA"
}
// Dati della GenericTable
const columnsNames = [
  { label: 'Codice fiscale', field: 'codiceFiscale', isSortable: true },
  { label: 'Data', field: 'dataCambioSezione', isSortable: true },
  { label: 'Cognome', field: 'cognome', isSortable: true },
  { label: 'Nome', field: 'nome', isSortable: true },
  { label: 'Stato', field: 'stato', isSortable: true, render: (row) => STATI[row.stato] },
  { label: 'Da', field: 'cambioSezioneDa', isSortable: true },
  { label: 'A', field: 'cambioSezioneA', isSortable: true },

];

const filters = [
  { label: "Cognome", field: "cognome", type: 'input', },
  { label: "Nome", field: "nome", type: 'input', },
  { label: "Codice Fiscale", field: "codiceFiscale", type: 'input' },
]
class RichCambioSezione extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedItem: {},

      rowsNames: [],
      itemsPageCount: 10,
      filterAndPaginationData: "",
      //isConcediNullaOsta: false,
      history: props.history
    };

  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    /*setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);*/


    this.setState({ isLoading: false });
  }


  loadingFunction = () => {
    this.componentDidMount()
  };

  onSelect = (event) => {
    this.setState({ selectedItem: event.data });
  };

  generateRequestFilter = (filters: any) => {
    if (filters.length === 0) return undefined;
    const f = filters.map(f => {
      const v = (typeof f.value === "object" && f.value['value']) ? f.value['value'] : f.value
      return f.field + '::' + 'LIKE' + '::' + v;
    });
    return f;
  }


  getAction = (row) => {
    let out: any = [];
    var userODAFHasGestAnagRoles = TokenStore.getInstance().isCurrentAccountODAF() && TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_UTEN_ODAF])
    var isCurrentAccountODAF = TokenStore.getInstance().isCurrentAccountODAF()

    out.push({ label: 'Visualizza', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA + '?idCambioSezione=' + row.idCambioSezione + '&idIscrizione=' + row.idIscrizione),  hidden: !isCurrentAccountODAF })

    if (STATI[row.stato] === "INVIATA") {

      out.push({ label: 'Rifiuta cambio sezione', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RIFIUTA + '?idCambioSezione=' + row.idCambioSezione + '&idIscrizione=' + row.idIscrizione), hidden: !userODAFHasGestAnagRoles })
      out.push({ label: 'Accetta cambio sezione', onClick: () => this.props.history.push(PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA + '?idCambioSezione=' + row.idCambioSezione + '&idIscrizione=' + row.idIscrizione), hidden: !userODAFHasGestAnagRoles })

    }

    return out
  }
  rows = async (o: any) => {

    let filtersStr = "";
    let sorts = generateRequestSort(o.sortColumn, o.sortDirection);

    let filters = this.generateRequestFilter(o.filters);
    if (filters)
      filters.map((f, index) => { filtersStr = filtersStr + f + ',' });

    const param = {
      "page": o.currentPage,
      "items": o.pageSize,
      "filters": filtersStr.slice(0, -1),
      "sorts": sorts ? sorts[0] : "",
      "requestObject": {
        "codiceFiscale": TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber,
        "codiceGruppo": Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
      }
    }
    const { data } = await getCambioSezioneResourceApi().mswfCambioSezioneFindAllByVisibilityPagedPost(param, getDefaultAxiosConfig())
    //const { data } = await axios.post('http://localhost:8080/mswf/cambioSezione/findAllPaged', param, getDefaultAxiosConfig())

    return {
      items: data.items || [],
      total: data.count || 0,
    }

  }

  render() {

    const { isLoading, selectedItem } = this.state;

    return (
      <PageSection>
        <div className="container register-container">
          <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "/" }, { label: "Lista richieste di cambio sezione", link: PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA }]} /><br></br>
          <Title headingLevel="h1" size="4xl">Lista richieste di cambio sezione </Title><br></br>
          {(isLoading) ?

            (

              <GenericSpinner></GenericSpinner>
            )
            : (
              <div>
                <GenericAsyncTableData

                  columns={columnsNames}
                  // getRows={this.state.rowsNames}

                  getRows={this.rows}
                  filters={filters}
                  //getRowActions={[{ label: "Aggiorna", function: this.loadingFunction }]}
                  getRowActions={this.getAction}
                />
              </div>
            )
          }
        </div>
      </PageSection>
    );
  }
}
export default RichCambioSezione;
