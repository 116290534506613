import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericWizard from '../../components/GenericWizard'
import GenericCard from '../../components/GenericCard'
import { FormUbicazione } from './FormDatiOrdiniFederazioni/FormUbicazione'
import { FormDatiGeneraliOrdineFederazione } from './FormDatiOrdiniFederazioni/FormDatiGeneraliOrdineFederazione'
import { FormContatti } from './FormDatiOrdiniFederazioni/FormContatti'
import { FormConsiglioInCarica } from './FormDatiOrdiniFederazioni/FormConsiglioInCarica'
import { FormConsiglioDisciplinaInCarica } from './FormDatiOrdiniFederazioni/FormConsiglioDisciplinaInCarica'
import { FormReferenti } from './FormDatiOrdiniFederazioni/FormReferenti'
import { FormRiferimentiExport } from './FormDatiOrdiniFederazioni/FormRiferimentiExport'
import { getDatiOrdineFederazioneByCodiceGruppo } from 'src/processes/Soggetto'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { goTo } from 'src/utilities/utility'
import {
  PATH_TO_BO_ANAG_CONTATTI_ORDINI,
  PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI
} from 'src/app/utils/RoutesConstants'
import { GenericSpinner } from '../../components'

class VisualizzaDettagliOrdiniFederazioni extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            typeMessage: "default",
            message: "",
            returnedObject: [],
            presidente: [],
            consigliere: [],
            responsabileTrasparenza: [],
            responsabileAnticorruzione: [],
            responsabilePrivacy: [],
            referenteComunicazione: [],
            dpo: [],
            referenteFormazione: [],
            referenteDigitalizzazione: [],
            dipartimento: [],
            vicepresidente: [],
            segretario: [],
            tesoriere: [],
            presidenteDisciplina: [],
            consigliereDisciplina: [],
            segretarioDisciplina: [],
            isLoaded: false
        }
    }

    async componentDidMount() {
        // Controllo per gestire il refresh della pagina.
        // In tal caso i dati passati dalla riga si perderanno e l'utente verrà reindirizzato all'elenco degli ordini e federazioni
        if (this.props.history.location.state?.codiceGruppo === undefined)
            goTo(PATH_TO_BO_ANAG_CONTATTI_ORDINI)

        var codiceGruppo = this.props.history.location.state.codiceGruppo //recupero il valore del codice gruppo
        var provincia = this.props.history.location.state.provincia
        this.setState({ codiceGruppo, provincia })

        await getDatiOrdineFederazioneByCodiceGruppo(codiceGruppo).then((response) => {

            if (response.data.httpStatus === 200)
            {

                // Presidente (ag_presidente)
                let presidente = response.data.returnedObject.filter(elemento => elemento.tipologia === 'presidente');
                let presidenteDisciplina = response.data.returnedObject.filter(elemento => elemento.tipologia === 'presidente-disciplina');

                // Consiglio in carica (ag_consiglio)
                let vicepresidente = response.data.returnedObject.filter(elemento => elemento.tipologia === 'vicepresidente');
                let segretario = response.data.returnedObject.filter(elemento => elemento.tipologia === 'segretario');
                let tesoriere = response.data.returnedObject.filter(elemento => elemento.tipologia === 'tesoriere');
                let consigliere = response.data.returnedObject.filter(elemento => elemento.tipologia === 'consigliere');

                // Consiglio di disciplina in carico (ag_consiglio_disciplina)
                let consigliereDisciplina = response.data.returnedObject.filter(elemento => elemento.tipologia === 'consigliere-disciplina');
                let segretarioDisciplina = response.data.returnedObject.filter(elemento => elemento.tipologia === 'segretario-disciplina');

                // Referenti (ag_referente)
                let dpo = response.data.returnedObject.filter(elemento => elemento.tipologia === 'dpo');
                let referenteComunicazione = response.data.returnedObject.filter(elemento => elemento.tipologia === 'referente-comunicazione');
                let referenteFormazione = response.data.returnedObject.filter(elemento => elemento.tipologia === 'referente-formazione');
                let referenteDigitalizzazione = response.data.returnedObject.filter(elemento => elemento.tipologia === 'referente-digitalizzazione');
                let responsabileTrasparenza = response.data.returnedObject.filter(elemento => elemento.tipologia === 'responsabile-trasparenza');
                let responsabileAnticorruzione = response.data.returnedObject.filter(elemento => elemento.tipologia === 'responsabile-anticorruzione');
                let responsabilePrivacy = response.data.returnedObject.filter(elemento => elemento.tipologia === 'responsabile-privacy');
                let dipartimento = response.data.returnedObject.filter(elemento => elemento.tipologia === 'dipartimento');

                this.setState({
                    presidente : presidente,
                    consigliere : consigliere,
                    responsabileTrasparenza : responsabileTrasparenza,
                    responsabileAnticorruzione : responsabileAnticorruzione,
                    responsabilePrivacy : responsabilePrivacy,
                    dpo : dpo,
                    referenteComunicazione : referenteComunicazione,
                    referenteFormazione : referenteFormazione,
                    referenteDigitalizzazione : referenteDigitalizzazione,
                    dipartimento : dipartimento,
                    vicepresidente : vicepresidente,
                    segretario : segretario,
                    tesoriere : tesoriere,
                    presidenteDisciplina,
                    consigliereDisciplina,
                    segretarioDisciplina,
                })
                const stepsODAF = [
                    { name: 'Ubicazione', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Ubicazione'} body={<FormUbicazione returnedObject={response.data.returnedObject} messaggio={this.state.message} provincia={provincia} />} /> },
                    { name: 'Contatti', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Contatti'} body={<FormContatti returnedObject={response.data.returnedObject} messaggio={this.state.message} />} /> },
                    { name: 'Consiglio in carica', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Consiglio in carica'} body={<FormConsiglioInCarica returnedObject={response.data.returnedObject} presidente = {presidente} consigliere = {consigliere} vicepresidente = {vicepresidente} segretario = {segretario} tesoriere = {tesoriere} messaggio={this.state.message} />} /> },
                    { name: 'Consiglio di disciplina in carica', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Consiglio di disciplina in carica'} body={<FormConsiglioDisciplinaInCarica returnedObject={response.data.returnedObject} presidenteDisciplina = {presidenteDisciplina} consigliereDisciplina = {consigliereDisciplina} segretarioDisciplina = {segretarioDisciplina} messaggio={this.state.message} />} /> },
                    { name: 'Referenti', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Referenti'} body={<FormReferenti returnedObject={response.data.returnedObject} responsabileTrasparenza = {responsabileTrasparenza} responsabileAnticorruzione = {responsabileAnticorruzione} referenteComunicazione = {referenteComunicazione} referenteFormazione = {referenteFormazione} referenteDigitalizzazione = {referenteDigitalizzazione} dpo = {dpo} responsabilePrivacy = {responsabilePrivacy} dipartimento = {dipartimento} messaggio={this.state.message} />} /> },
                    { name: 'Riferimenti per export', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Riferimenti per export'} body={<FormRiferimentiExport returnedObject={response.data.returnedObject} messaggio={this.state.message} />} /> },
                ];

                const stepsFODAF = [
                    { name: 'Ubicazione', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Ubicazione'} body={<FormUbicazione returnedObject={response.data.returnedObject} messaggio={this.state.message} provincia={provincia} />} /> },
                    { name: 'Contatti', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Contatti'} body={<FormContatti returnedObject={response.data.returnedObject} messaggio={this.state.message} />} /> },
                    { name: 'Consiglio in carica', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Consiglio in carica'} body={<FormConsiglioInCarica returnedObject={response.data.returnedObject} presidente = {presidente} consigliere = {consigliere} messaggio={this.state.message} />} /> },
                    { name: 'Referenti', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Referenti'} body={<FormReferenti returnedObject={response.data.returnedObject} responsabileTrasparenza = {responsabileTrasparenza} responsabileAnticorruzione = {responsabileAnticorruzione} referenteComunicazione = {referenteComunicazione} referenteFormazione = {referenteFormazione} referenteDigitalizzazione = {referenteDigitalizzazione} dpo = {dpo} responsabilePrivacy = {responsabilePrivacy} dipartimento = {dipartimento} messaggio={this.state.message} />} /> },
                    { name: 'Riferimenti per export', component: <GenericCard isExpandibleCard={false} isExpanded={true} header={'Riferimenti per export'} body={<FormRiferimentiExport returnedObject={response.data.returnedObject} messaggio={this.state.message} />} /> },
                ];
                this.setState({ returnedObject: response.data.returnedObject, stepsFODAF, stepsODAF, isLoaded : true })
            }
            else{
                throw new Error(response.data.error);
            }
        }).catch((error) => {
            console.error(error)
            setTimeout(() => {
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
            }, 1000);
        })
    }

    buildTitlePage = (tipo, denominazioneGruppo) => {
        var title = ""
        if (tipo === 'ODAF')
            title = 'Ordine di ' + this.state.provincia

        else if (tipo === 'FODAF')
            title = 'Federazione ' + denominazioneGruppo

        return title
    }

    render() {
        const { returnedObject,
            message,
            typeMessage,
            stepsFODAF,
            stepsODAF,
            isLoaded } = this.state

        if (isLoaded){
            return (
                <>
                    <PageSection>
                        <div className='container register-container'>
                            <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Contatti ordini", link: PATH_TO_BO_ANAG_CONTATTI_ORDINI }, { label: "Dettagli", link: PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI }]} /><br></br>
                            <Title headingLevel="h1" size="4xl">{this.buildTitlePage(returnedObject?.tipo, returnedObject?.denominazioneGruppo)}</Title>
                            <GenericAlert label={this.state.message} hidden={message === ""} color={typeMessage}></GenericAlert>
                            <GenericCard isExpandibleCard={true} isExpanded={false} header={'Dati generali'} body={<FormDatiGeneraliOrdineFederazione returnedObject={returnedObject} messaggio={message} />} />
                            <GenericWizard steps={returnedObject[0]?.tipo?.startsWith('ODAF') ? stepsODAF : stepsFODAF} backButtonText={'PRECEDENTE'} nextButtonText={'SUCCESSIVO'} cancelButtonText={false} height='auto' /><br></br>
                        </div>
                    </PageSection>
                </>
            );
        }
        else
        {
            return (<GenericSpinner></GenericSpinner>)
        }
    }
};

export default VisualizzaDettagliOrdiniFederazioni;
