import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { GenericSpinner, GenericTable } from 'src/backoffice/app/components'
import {
  PATH_TO_BO_ANAG_CONTATTI_ORDINI,
  PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI
} from 'src/app/utils/RoutesConstants'
import { getListaFederazioniOrdini } from 'src/processes/Soggetto'

var columns = ["Denominazione", "Indirizzo", "Email", "Telefono", "codiceGruppo", "provincia"]
let columnsToHide = [4,5]
class ElencoOrdiniFederazioni extends React.Component<GeneralElencoOrdiniFederazioniProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            isLoaded: false
        };
    }

    async componentDidMount() {
        this.setState({ isLoaded: false })

        await getListaFederazioniOrdini().then((response) => {

            var columnsToShow: any = []

            if (response.data.httpStatus === 200) {
                response.data.returnedObject.map((item, id) => (
                    columnsToShow.push({
                        data: [
                            item.tipo.toLowerCase() === 'ordine' ? "Ordine di " + item.denominazioneGruppo : "Federazione " + item.denominazioneGruppo,
                            item.indirizzoResidenza,
                            item.emailOrdinaria,
                            'tel. ' + item.telefono,
                            item.codiceGruppo,
                            item.provincia
                        ]
                    }
                    )
                ));

                this.setState({ rows: columnsToShow })
            }
            else
                throw new Error(response.data.error);

        }).catch(() => {
            setTimeout(() => {
                this.setState({ message: "C'è stato un errore durante il recupero dei dati", typeMessage: 'danger' })
            }, 1000);
        })

        this.setState({ isLoaded: true })
    }

    onSelectAction = (event, link) => {
        let codiceGruppoIndex = columns.toString().toUpperCase().split(',').indexOf('CODICEGRUPPO')
        let provinciaIndex = columns.toString().toUpperCase().split(',').indexOf('PROVINCIA')
        let codiceGruppo = this.state.selectedRow.data[codiceGruppoIndex]
        let provincia = this.state.selectedRow.data[provinciaIndex]

        var state = { row: this.state.selectedRow, column: columns, codiceGruppo: codiceGruppo, provincia: provincia }

        this.props.history.push(
            {
                pathname: link,
                state: state,
            }
        )
    }

    onSelectRow = async (row: any) => {
        await this.setState({ selectedRow: row })
    }

    render() {
        const { rows } = this.state

        const dropdownActionList = [
            {
                title: 'Dettagli',
                onClick: (e) => this.onSelectAction(e, PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI),
                itemKey: 'dettagli',
            },
        ]

        return (
            <PageSection>
                <div className='container register-container'>
                    <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Contatti ordini", link: PATH_TO_BO_ANAG_CONTATTI_ORDINI }]} /><br></br>
                    <Title headingLevel="h1">Contatti ordini</Title>
                    {this.state.isLoaded ?
                        <div>
                            <GenericTable
                                styleRows={{ backgroundColor: 'var(--pf-global--palette--blue-50)' }}
                                columns={columns}
                                rows={rows}
                                dropdownList={dropdownActionList}
                                hideCsv
                                hideFilters
                                hidePagination
                                autoWidthColumns
                                onSelect={(e) => this.onSelectRow(e)}
                                columnsToHide={columnsToHide}
                            />

                        </div>
                        : <GenericSpinner></GenericSpinner>}
                </div>
            </PageSection>
        );
    }
}
export default ElencoOrdiniFederazioni;

export declare interface GeneralElencoOrdiniFederazioniProps {
    location?: any;
    history?: any;
}
