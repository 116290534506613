/**
 * Rappresenta una clausola di una query graphql.
 */
export function Clause(field, content) {
    this.field = field;
    this.content = content;

    /**
     * Restituisce la clausola LIKE in formato string.
     * @returns 
     */
    this.like = function() {
        return "{"+field+": {LIKE_: \"%"+content+"%\"}}"
    }

    /**
     * Restituisce la clausola EQ in formato string.
     * @returns 
     */
    this.eq = function() {
        if (content === null)
            return "{"+field+": {EQ: null}}" 
        return "{"+field+": {EQ: \""+content+"\"}}"
    }

    /**
     * Restituisce la clausola IS_NULL in formato string.
     * inserire true o false
     * @returns 
     */
    this.isNull = function() {
        return "{"+field+": {IS_NULL: "+content+"}}"
    }

    /**
     * Restituisce la clausola EQ in formato string.
     * @returns 
     */
    this.eqWithoutBrace = function() {
        if (content === null)
            return field+": {EQ: null}" 
        return field+": {EQ: \""+content+"\"}"
    }

    /**
     * Restituisce la clausola IN in formato string.
     * @returns 
     */
    this.inWithoutBrace = function() {
        return field+": {IN: ["+content+"]}"
    }

    /**
     * Restituisce la clausola IN in formato string.
     * @returns 
     */
    this.in = function() {
        return "{"+field+": {IN: ["+content+"]}}"
    }

    /**
     * Restituisce la clausola NOT IN in formato string.
     * @returns 
    */
       this.notIn = function() {
        return "{"+field+": {NOT IN: ["+content+"]}}"
    }

    /**
     * Restituisce la clausola complessa in formato string.
     * Una clausola è complessa quando il contenuto di un field è a sua volta una clausola.
     * @returns 
     */
    this.complex = function() {
        return field+": {"+content+"}"
    }

    this.complexBrace = function() {
        return "{" + field+": "+content + "}"
    }

    /**
     * Restituisce la clausola LT 
     * @returns 
    */
    this.lt = function() {
        if (content === null)
            return "{"+field+": null}}" 
        return "{"+field+": {LT: \""+content+"\"}}"
    }
    
    /**
     * Restituisce la clausola GT 
     * @returns 
    */
    this.gt = function() {
        if (content === null)
            return "{"+field+": null}}" 
        return "{"+field+": {GT: \""+content+"\"}}"
    }
    return this;

}

/**
 * Questo builder si occupa della costruzione di una clausola di una query graphql.
 */
export function ClauseBuilder() {
    this.clause = null;

    /**
     * Costruisce la clausola LIKE.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.like = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).like();
    }

    /**
     * Costruisce la clausola EQ.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.eq = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).eq();
    }

    /**
     * Costruisce la clausola LT.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
    */
    this.lt = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).lt();
    }

    
    /**
     * Costruisce la clausola GT.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
    */
    this.gt = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).gt();
    }

    /**
     * Costruisce la clausola IS_NULL.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {boolean} content 
     * @returns 
     */
    this.isNull = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).isNull();
    }

    /**
     * Costruisce la clausola EQ.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.eqWithoutBrace = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).eqWithoutBrace();
    }

    /**
     * Costruisce la clausola IN.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.inWithoutBrace = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).inWithoutBrace();
    }

    /**
     * Costruisce la clausola IN a partire da una lista di elementi.
     * Se arrayContent è undefined, null oppure array vuoto 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.inWithoutBraceFromArray = function(field, arrayContent) {
        if (arrayContent === undefined || arrayContent === null || arrayContent.length === 0)
            return null;
        var arrayContentAsString = "";
        arrayContent.forEach((e) => {
            arrayContentAsString = arrayContentAsString + "\"" + e + "\",";
        });
        arrayContentAsString = arrayContentAsString.substring(0, arrayContentAsString.length - 1);
        arrayContentAsString = arrayContentAsString + "";
        return new Clause(field, arrayContentAsString).inWithoutBrace();
    }

    /**
     * Costruisce la clausola IN.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.in = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).in();
    }

    /**
     * Costruisce la clausola complessa.
     * Una clausola è complessa quando il contenuto di un field è a sua volta una clausola.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.complex = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).complex();
    }

    /**
     * Costruisce la clausola complessa con parentesi graffe.
     * Una clausola è complessa quando il contenuto di un field è a sua volta una clausola.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.complexBrace = function(field, content) {
        if (content === undefined || content === null || content === '')
            return null;
        return new Clause(field, content).complexBrace();
    }
}

/**
 * Questo builder si occupa della costruzione di un gruppo di clausole di una query graphql.
 */
export function GraphqlGroupBuilder() {
    this.clauses = [];

    /**
     * Appende una clausola LIKE al gruppo di clausole.
     * Se content è null non aggiunge la clausosa al builder.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.like = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        if (content !== null && content !== undefined && (isNaN(content) && content.includes(" "))) {
            var contents = content.split(" ");
            contents.forEach(content => {
                this.clauses.push(new Clause(field, content).like());
            });
        } else {
            this.clauses.push(new Clause(field, content).like());
        }
        return this;
    }
    
    /**
     * Appende una clausola LT al gruppo di clausole.
     * Se content è null non aggiunge la clausosa al builder.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
    */
    this.lt = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        if (content !== null && content !== undefined && (isNaN(content) && content.includes(" "))) {
            var contents = content.split(" ");
            contents.forEach(content => {
                this.clauses.push(new Clause(field, content).lt());
            });
        } else {
            this.clauses.push(new Clause(field, content).lt());
        }
        return this;
    }

    
    /**
     * Appende una clausola GT al gruppo di clausole.
     * Se content è null non aggiunge la clausosa al builder.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
    */
    this.gt = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        if (content !== null && content !== undefined && (isNaN(content) && content.includes(" "))) {
            var contents = content.split(" ");
            contents.forEach(content => {
                this.clauses.push(new Clause(field, content).gt());
            });
        } else {
            this.clauses.push(new Clause(field, content).gt());
        }
        return this;
    }

    /**
     * Appende una clausola EQ al gruppo di clausole.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.eq = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new Clause(field, content).eq());
        return this;
    }

     /**
     * Appende una clausola NOT IN al gruppo di clausole.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
     this.notIn = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new Clause(field, content).notIn());
        return this;
    }

    /**
     * Appende una clausola EQ al gruppo di clausole.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {boolean} content 
     * @returns 
     */
    this.isNull = function(field, content) {
        this.clauses.push(new Clause(field, content).isNull());
        return this;
    }
    
    /**
     * Appende una clausola EQ al gruppo di clausole.
     * Verifica che il field in input sia null.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.eqNull = function(field) {
        this.clauses.push(new Clause(field, null).eq());
        return this;
    }
    
    /**
     * Appende una clausola IN al gruppo di clausole.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.in = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new Clause(field, content).in());
        return this;
    }
    
    /**
     * Appende una clausola complessa al gruppo di clausole.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.complex = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new Clause(field, content).complex());
        return this;
    }
    
    /**
     * Appende una clausola complessa con parentesi graffe al gruppo di clausole.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.complexBrace = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new Clause(field, content).complexBrace());
        return this;
    }
    
    /**
     * Appende una lista di clausole in OR tra parentesti graffe.
     * {OR: [C1, C2, ..., CN]}
     * Se clauses è undefined, null oppure vuota
     * non aggiunge la clausosa al builder.
     * Se le clausole nella lista sono undefined, null o stringhe vuote
     * non le aggiunge.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.or = function(clauses) {
        if (clauses === undefined || clauses === null || clauses.length === 0)
            return this;
        var output = "";
        clauses.forEach(clause => {
            if (clause !== undefined && clause !== null && clause !== '')
                output += clause + ",";
        });
        if (output !== "") {
            output = "{OR: [" + output + "]}";
            this.clauses.push(output);
        }
        return this;
    }

    /**
     * Effettua una build OR del gruppo di clausole.
     * @returns 
     */
    this.buildOR = function() {
        if (this.clauses === null || this.clauses.length === 0)
            return "";
        var output = "OR: [";
        this.clauses.forEach(clause => {
            output += clause + ",";
        });
        return output+"]";
    }

    /**
     * Effettua una build OR del gruppo di clausole con parentesi.
     * @returns 
     */
    this.buildBraceOR = function() {
        if (this.clauses === null || this.clauses.length === 0)
            return "";
        var output = "{OR: [";
        this.clauses.forEach(clause => {
            output += clause + ",";
        });
        return output+"]}";
    }

    /**
     * Effettua una build AND del gruppo di clausole.
     * @returns 
     */
    this.buildAND = function() {
        var output = "AND: [";
        this.clauses.forEach(clause => {
            output += clause + ",";
        });
        return output+"]";
    }

    /**
     * Effettua una build AND del gruppo di clausole.
     * @returns 
     */
    this.buildBraceAND = function() {
        var output = "{AND: [";
        this.clauses.forEach(clause => {
            output += clause + ",";
        });
        return output+"]}";
    }
}

/**
 * Questo builder si occupa della costruzione di una query graphql.
 */
export function GraphqlBuilder() {
    this.clauses = [];

    /**
     * Appende un gruppo di clausole alla lista di clausole della query.
     * @param {*} clausesGroup 
     * @returns 
     */
    this.group = function(clausesGroup) {
        this.clauses.push(clausesGroup);
        return this;
    }

    /**
     * Appende una clausola LIKE alla lista di clausole della query.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.like = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new ClauseBuilder().like(field, content));
        return this;
    }

    /**
     * Appende una clausola EQ alla lista di clausole della query.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.eq = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new ClauseBuilder().eq(field, content));
        return this;
    }

    /**
     * Appende una clausola EQ alla lista di clausole della query.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.eqWithoutBrace = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new ClauseBuilder().eqWithoutBrace(field, content));
        return this;
    }

    /**
     * Appende una clausola IN alla lista di clausole della query.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.in = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new ClauseBuilder().in(field, content));
        return this;
    }

    /**
     * Appende una clausola complessa alla lista di clausole della query.
     * Una clausola è complessa quando il contenuto di un field è a sua volta una clausola.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.complex = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new ClauseBuilder().complex(field, content));
        return this;
    }

    /**
     * Appende una clausola complessa con parentesi graffe alla lista di clausole della query.
     * Una clausola è complessa quando il contenuto di un field è a sua volta una clausola.
     * Se content è undefined, null oppure stringa vuota 
     * non aggiunge la clausosa al builder.
     * @param {*} field 
     * @param {*} content 
     * @returns 
     */
    this.complexBrace = function(field, content) {
        if (content === undefined || content === null || content === '')
            return this;
        this.clauses.push(new ClauseBuilder().complexBrace(field, content));
        return this;
    }

    /**
     * Effettua una build della query graphql.
     * @returns 
     */
    this.build = function() {
        if (this.clauses === undefined || this.clauses.length === 0)
            return "{}";
        var query = "{";
        this.clauses.forEach(clause => {
            query += clause + ",";
        });
        return query+"}";
    } 
}