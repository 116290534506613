import React, { useCallback, useState } from 'react'

import { Label } from 'design-react-kit'
import FormSubmit, { STATUS } from '../../../../../frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { useHistory } from 'react-router-dom'

const FormCancellazione: React.FunctionComponent<FormCancellazioneProps> = ({ id }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()
  const history = useHistory()

  const submit = useCallback(
    async (e) => {
      try {
        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneEliminaIscrittoDelete(id, getDefaultAxiosConfig())
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id]
  )
  return (
    <FormSubmit title="Richiesta cancellazione iscrizione" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Label>Per cancellare l'iscrizione dall'evento cliccare "Conferma".</Label>
    </FormSubmit>
  )
}

interface FormCancellazioneProps {
  id: any
}

export default FormCancellazione
