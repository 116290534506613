import { OrdineBySiglaAutomobilisticaRequest, OrdineCompetenteBySpecificGroupDTO, RicercaOrdineDTO } from '@parsec/msga'
import {
  getAnagrafeResourcesApi,
  getDefaultAxiosConfig,
  getIscrizioneOrdineResourceApi,
  getPublicResourcesApi
} from 'src/app/utils/ManagerRestGateway'

/**
 * GET degli Ordini territoriali
 * @returns
 */
export async function getOrdini() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoElencoOrdiniTerritorialiGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeElencoOrdiniTerritorialiGet - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeElencoOrdiniTerritorialiGet - " + err);
  }
}




/**
 * GET deglle federazioni
 * @returns
 */
export async function getFederazioni() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoElencoFederazioniGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeFoElencoFederazioniGet - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFoElencoFederazioniGet - " + err);
  }
}

/**
 * GET degli Ordini territoriali (PermitAll)
 * @returns
 */
export async function getOrdiniPermitAll() {
  try {
    const response = await getPublicResourcesApi().msgaPublicFoElencoOrdiniTerritorialiPermitAllGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaPublicFoElencoOrdiniTerritorialiPermitAllGet - " + err);
    throw new Error("Errore durante la chiamata msgaPublicFoElencoOrdiniTerritorialiPermitAllGet - " + err);
  }
}

/**
// * GET dell'ordine territoriale by sigla  (DA USARE NEL FRONTOFFICE)
//  * @returns
//  * @param flagSedeLegaleDiversoDaProvinciaResidenza
//  * @param codiceProvinciaSedeLegale
//  * @param codiceProvinciaResidenza
//  * @param tipoSoggetto
*/
export async function getOrdiniBySigla(flagSedeLegaleDiversoDaProvinciaResidenza: number, codiceProvinciaSedeLegale: string, codiceProvinciaResidenza: string, tipoSoggetto: string) {
  try {
    var ordine: RicercaOrdineDTO = {
      "flagSedeLegale": flagSedeLegaleDiversoDaProvinciaResidenza,
      "provinciaResidenza": codiceProvinciaResidenza,
      "provinciaSedeLegale": codiceProvinciaSedeLegale,
      "tipoSoggetto": tipoSoggetto
    }
    const response = await getIscrizioneOrdineResourceApi().msgaIotElencoOrdiniBySiglaAutomobilisticaPost(ordine, getDefaultAxiosConfig())
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaIotElencoOrdiniBySiglaAutomobilisticaPost - " + err);
    throw new Error("Errore durante la chiamata msgaIotElencoOrdiniBySiglaAutomobilisticaPost - " + err);
  }
}

/**
 * GET dell'ordine territoriale by sigla della provincia
 * @returns
 * @param siglaProvincia
 */
export async function getOrdineBySiglaProvinciaFO(siglaProvincia: string) {
  try {
    var ordineBySigla: OrdineBySiglaAutomobilisticaRequest = {
      "siglaProvincia": siglaProvincia
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoOrdineBySiglaAutomobilisticaProvinciaPost(ordineBySigla, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    //TODO - Gestire meglio l'errore
    // let errorMessage: any = ErrorStore.getInstance().returnMessageError(err.message, 500)
    console.error("Errore durante la chiamata per il recupero dell'ordine. " + err);
    throw new Error("Errore durante la chiamata per il recupero dell' ordine. " + err);
  }
}

/**
 * GET dell'ordine territoriale by sigla della provincia
 * @returns
 * @param siglaProvincia
 */
export async function getOrdineBySiglaProvinciaBO(siglaProvincia: string) {
  try {
    var ordineBySigla: OrdineBySiglaAutomobilisticaRequest = {
      "siglaProvincia": siglaProvincia
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoOrdineBySiglaAutomobilisticaProvinciaPost(ordineBySigla, getDefaultAxiosConfig())
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err: any) {
    //let errorMessage: any = ErrorStore.getInstance().showAlert("Errore durante la chiamata per il recupero dell'ordine")
    //console.error(errorMessage.title + ". Errore durante la chiamata per il recupero dell'ordine. " + errorMessage.message);
    //throw new Error(errorMessage.title + ". Errore durante la chiamata per il recupero dell' ordine. " + errorMessage.message);
    throw new Error(err);
  }
}

/**
 * POST della sigla dell'ordine by specificGroup
 * @returns
 * @param  specificGroup
 */
export async function getOrdineCompetenteOperatorePOST(codiceOrdine: string) {
  try {
    var ordineCompetenteOperatorePOST: OrdineCompetenteBySpecificGroupDTO = {
      specificGroup: codiceOrdine
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoGetOrdineCompetenteBySpecificGroupPost(ordineCompetenteOperatorePOST, getDefaultAxiosConfig())
    return response;
  } catch (err: any) {
    //TODO - Gestire meglio l'errore
    // let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err.message, 500)
    // throw await new Error(errorMessage.title + errorMessage.message);

    console.error("Errore durante la chiamata msgaAnagrafeGetOrdineCompetenteBySpecificGroupPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeGetOrdineCompetenteBySpecificGroupPost - " + err);
  }
}
