import React from 'react'
import '../../style/style.scss'
import dots from '../../images/moreItems.png'
import { DropdownToggle, LinkList, LinkListItem, UncontrolledDropdown } from 'design-react-kit'
import { DropdownMenu } from 'reactstrap'
import { GenericCard, GenericSpinner } from '../components'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTable from 'src/backoffice/app/components/GenericTable'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import GenericTitle from 'src/frontend/app/components/GenericTitle'
import {
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
  PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA,
  PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA,
  PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA,
  PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA,
} from 'src/app/utils/RoutesConstants'
import { getDisciplinariByCf } from 'src/processes/disciplinari/Disciplinari'
import { breadcrumbOptionsDisciplinari, columnsDisciplinari } from 'src/processes/disciplinari/CostantiDisciplinari'
import { convertUrlParamsToObject } from 'src/utilities/utility'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { PageSection, Title } from '@patternfly/react-core'
import { DisciplinariUtils } from 'src/processes/disciplinari/DisciplinariUtils'
import { getSoggettoByCf } from 'src/processes/Soggetto'

const SOSPENSIONE_MOROSITA = 'sospensione-morosita'
const RADIAZIONE = 'radiazione'

export default class attiDisciplinari extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      dropdownList: [],
      selectedRow: {},
      currentPage: 0,
      nome: '',
      cognome: '',
    }
  }
  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    })
  }

  goToElencoIscritti = (redirectLink: any) => {
    this.props.history.push({
      pathname: redirectLink,
    })
  }
  async componentDidMount(): Promise<void> {
    var url = window.location.search?.split('?')[1]
    var urlParams = convertUrlParamsToObject(url)

    if (!urlParams?.idIscrizione) {
      this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
    } else if (!urlParams?.tipologiaIscrizione) {
      this.goToElencoIscritti(PATH_TO_BO_ANAG_ELENCO_ISCRITTI)
    } else {
      //recupero l'idIscrizione dall'url
      let idIscrizione = urlParams?.idIscrizione
      let tipologiaIscrizione = urlParams?.tipologiaIscrizione
      let cf = urlParams?.cf
      this.setState({ idIscrizione: idIscrizione, tipologiaIscrizione: tipologiaIscrizione, cf: cf })

      await getDisciplinariByCf(cf)
        .then(async (response: any) => {
          await this.setState({ disciplinari: response, isLoaded: true })

          let listaDisciplinari: any = []

          if (response.length > 0) {
            response.map(async (disciplinareAttiva) => {
              let disciplinareLabel = DisciplinariUtils.decodificaTipoProvvedimento(disciplinareAttiva.provvedimento)?.label
              let obj = {
                id: disciplinareAttiva.id,
                data: [
                  disciplinareLabel ? disciplinareLabel : '-',
                  disciplinareAttiva.dataProvvedimento ? disciplinareAttiva.dataProvvedimento.split('T').join(' ').split('.')[0] : '-',
                  disciplinareAttiva.deliberaNr ? disciplinareAttiva.deliberaNr : '-',
                  disciplinareAttiva.provvedimentoDal ? disciplinareAttiva.provvedimentoDal.split('T').join(' ').split('.')[0] : '-',
                  disciplinareAttiva.provvedimentoAl &&
                  disciplinareAttiva.provvedimento !== RADIAZIONE &&
                  disciplinareAttiva.provvedimento !== SOSPENSIONE_MOROSITA
                    ? disciplinareAttiva.provvedimentoAl.split('T').join(' ').split('.')[0]
                    : '-',
                  (disciplinareAttiva.createdByOrdine === null ? 1 : disciplinareAttiva.createdByOrdine) ? disciplinareAttiva.createdByOrdine : 0,
                ],
              }
              await listaDisciplinari.push(obj)
            })
            await this.setState({ rowsNames: listaDisciplinari })
          }

          await this.setState({ rowsNames: listaDisciplinari })
        })
        .catch((err) => {
          this.showModal(true, 'Errore', err.message, false)
        })

      getSoggettoByCf(cf).then((response: any) => {
        const { nome, cognome } = response.data[0]
        this.setState({ nome: nome, cognome: cognome })
      })
    }
  }

  /** funzione del redirect */
  onSelectAction = (redirectLink: any) => {
    this.props.history.push(
      redirectLink +
        '?cf=' +
        this.state.cf +
        '&idIscrizione=' +
        this.state.idIscrizione +
        '&tipologiaIscrizione=' +
        this.state.tipologiaIscrizione +
        '&id=' +
        this.state.selectedRow.id
    )
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  showModal = async (booleanValue, title, text, makeChoice) => {
    //Il campo makeChoice consente di inserire nel modal i pulsanti Si/No se è settato a true
    //se è false viene visualizzato solo il pulsante OK
    if (makeChoice === true) {
      text = text + ' Sicuro di voler procedere?'
    }

    await this.setState({
      modalOpen: booleanValue,
      modalTitle: title,
      modalText: text,
      isLoaded: true,
      makeChoice: makeChoice,
    })
  }

  /**
   * Gestisce l'abilitazione o la disabilitazione del tasto modifica
   * @returns true or false
   */
  manageIsModificaDisabled = () => {
    if (TokenStore.getInstance().isCurrentAccountCONAF()) {
      //Se è un utente CONAF può effettuare la modifica
      return false
    } else {
      //Se è un utente ODAF può effettuare la modifica solo se il flag createdByOrdine è attivo (uguale a 1 o NULL)
      //Cioè solo se il disciplinare è stato creato dall'Odaf
      if (this.state?.selectedRow?.data && (this.state?.selectedRow?.data[5] === 1 || this.state?.selectedRow?.data[5] === null)) {
        return false
      } else {
        return true
      }
    }
    // if (
    //   categoria.value === 'operatore_conaf_nazionali' ||
    //   categoria.value === 'operatore_enti_formazione' ||
    //   categoria.value === 'operatore_riviste' ||
    //   categoria.value === 'operatore_enti_esterni' ||
    //   categoria.value === 'operatore_formatore'
    // ) {
    //   return true
    // } else if ((categoria.value === "iscritto_all_ordine" || categoria.value === "iscritto_stp") &&
    //   this.state.listaIscrizioniAttive?.find(elem => elem.statoIscrizione === 5 ||
    //     elem.statoIscrizione === 8 || elem.statoIscrizione === 2)) {
    //   return false
    // }
    // return false
  }

  hideStateOptions = (tableRows) => {}

  onSelectRow = async (row: any) => {
    if (TokenStore.getInstance().isCurrentAccountCONAF()) {
      //Se è un utente CONAF può effettuare la modifica
      this.setState({
        hideModifica: false,
        hideCancella: false,
      })
    } else {
      //Se è un utente ODAF può effettuare la modifica solo se il flag createdByOrdine è attivo (uguale a 1 o NULL)
      //Cioè solo se il disciplinare è stato creato dall'Odaf
      if (row?.data && (row?.data[5] === 1 || row?.data[5] === null)) {
        this.setState({
          hideModifica: false,
          hideCancella: false,
        })
      } else {
        this.setState({
          hideModifica: true,
          hideCancella: true,
        })
      }
    }

    await this.setState({ selectedRow: row })
  }
  render() {
    var title = 'Atti disciplinari'
    const cardHeader = 'Disciplinari' + ' - ' + this.state.nome + ' ' + this.state.cognome
    const dropdownActionList = [
      {
        title: 'Visualizza',
        onClick: (e) => this.onSelectAction(PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA),
        itemKey: 'visualizza',
      },
      {
        title: 'Modifica',
        onClick: (e) => this.onSelectAction(PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA),
        itemKey: 'modifica',
        hidden: this.state.hideModifica,
      },
      {
        title: 'Cancella',
        onClick: (e) => this.onSelectAction(PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA),
        itemKey: 'cancella',
        hidden: this.state.hideCancella,
      },
    ]

    return (
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-10 offset-1">
            <GenericBreadCrumb key="genericTab" paths={breadcrumbOptionsDisciplinari} />
            <GenericTitle Size="h1" title={title}></GenericTitle>
            <div className="Card">
              <GenericCard
                header={
                  <div className="justify-content-between d-flex">
                    <h5 className="card-title">{cardHeader}</h5>
                    <UncontrolledDropdown nav tag="div">
                      <DropdownToggle caret nav>
                        <button className="dots-btn">
                          <img src={dots} alt="" />
                        </button>
                      </DropdownToggle>
                      <div className="docs-show-dropdown-open">
                        <DropdownMenu style={{ width: '300px' }} className="full-width">
                          <LinkList>
                            <LinkListItem
                              onClick={() => {
                                this.onSelectAction(PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA)
                              }}
                            >
                              <span>Nuova sanzione disciplinare</span>
                            </LinkListItem>
                          </LinkList>
                        </DropdownMenu>
                      </div>
                    </UncontrolledDropdown>
                  </div>
                }
                body={
                  <div>
                    {this.state.isLoaded ? (
                      <>
                        <GenericTable
                          hideCsv={true}
                          hideSearch={true}
                          hideDropdown={true}
                          hideFilters={true}
                          autoWidthColumns={true}
                          isEditableTable={false}
                          columns={columnsDisciplinari?.map((column) => column.name)}
                          rows={this.state.rowsNames}
                          tableHeadFontSize="1em"
                          tableContentFontSize="0.8em"
                          perPageNumber={10}
                          hidePagination={true}
                          hideActionColumn={false}
                          onSelect={(e) => this.onSelectRow(e)}
                          actions={[{ label: 'Aggiorna', function: '' }]}
                          columnsToHide={[5]}
                          dropdownList={dropdownActionList}
                        />
                      </>
                    ) : (
                      <GenericSpinner></GenericSpinner>
                    )}
                  </div>
                }
              />
              <GenericModal
                title={this.state.modalTitle}
                text={this.state.modalText}
                modalOpen={this.state.modalOpen}
                closeButton={this.closeModal}
                makeChoice={this.state.makeChoice}
                // choiceAction={()=>this.creaDisciplinari(PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA)}
              />
              <PageSection>
                <div hidden={TokenStore.getInstance().isCurrentAccountCONAF()}>
                  <Title headingLevel="h3">Nota:</Title>
                  <p>Si sottolinea che l’ordine non può modificare provvedimenti inseriti dal CONAF.</p>
                </div>
              </PageSection>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export declare interface attiDisciplinariProps {
  visualizzaFlag?: any
  modificaDatiFlag?: any
}
