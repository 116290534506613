import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import { getBigIntToConvertToBoolean, getBigIntToConvertToStringSiNo } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormSedeLegale extends React.Component<GeneralFormSedeLegaleProps, any> {
  render() {
    const { messaggio, soggetto, iscrizione } = this.props
    return (
      <>
        {(soggetto === '' || iscrizione === '') && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idSedeLegale'}
                    label={'Sede legale studio diversa da residenza?'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagSedeLegaleDiversoDaProvinciaResidenza)}
                    isReadOnly
                  />
                </div>
              </div>
              {/*------------------------- Campi visibili solo se l'indirizzo della sede legale studio è diverso dall'indirizzo di residenza -----------------------*/}
              <div
                className="row"
                hidden={!getBigIntToConvertToBoolean(iscrizione.flagSedeLegaleDiversoDaProvinciaResidenza) && !soggetto.elencoSedi[0]?.slIndirizzoStudio}
              >
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idIndirizzoStudio'}
                    label={'Indirizzo (via)'}
                    value={soggetto.elencoSedi[0]?.slIndirizzoStudio}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCivicoStudio'}
                    label={'Numero civico'}
                    value={soggetto.elencoSedi[0]?.slNumeroCivicoStudio}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'}>
                  <GenericTextInput type={'text'} className="w-50" id={'idCapStudio'} label={'CAP'} value={soggetto.elencoSedi[0]?.slCapStudio} isReadOnly />
                </div>
              </div>
              <div
                className="row"
                hidden={!getBigIntToConvertToBoolean(iscrizione.flagSedeLegaleDiversoDaProvinciaResidenza) && !soggetto.elencoSedi[0]?.slIndirizzoStudio}
              >
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCittaStudio'}
                    label={'Città'}
                    value={soggetto.elencoSedi[0]?.slCittaStudio}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idProvinciaStudio'}
                    label={'Provincia dello studio'}
                    value={this.props.provinciaSedeLegale}
                    isReadOnly
                  />
                </div>
              </div>
              {/*------------------------------------------------------------------------------------------------------------------------------------------------------*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'tel'}
                    className="w-75"
                    id={'idTel'}
                    label={'Numero di telefono studio'}
                    value={soggetto.elencoSedi[0]?.slTelefonoStudio}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'tel'} className="w-75" id={'idFax'} label={'Fax studio'} value={soggetto.elencoSedi[0]?.slFaxStudio} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'email'}
                    className="w-75"
                    id={'idEmail'}
                    label={'Mail studio'}
                    value={soggetto.elencoSedi[0]?.slMailStudio}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'email'} className="w-75" id={'idPec'} label={'PEC studio'} value={soggetto.elencoSedi[0]?.slPecStudio} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idSito'}
                    label={'Sito internet'}
                    value={soggetto.elencoSedi[0]?.sitoInternet}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idFacebook'} label={'Facebook'} value={soggetto.elencoSedi[0]?.facebook} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idTwitter'} label={'Twitter'} value={soggetto.elencoSedi[0]?.twitter} isReadOnly />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormSedeLegaleProps {
  soggetto?: any
  provinciaSedeLegale?: any
  messaggio?: any
  iscrizione?: any
}
