import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit'
import React from 'react'

const SelezionaRelatore: React.FunctionComponent<SelezionaRelatoreInterface> = ({ selecting, formatori, onClose }) => {
  return (
    <Modal fade centered scrollable isOpen={!!selecting} toggle={onClose}>
      <ModalHeader id="assegnaCFU">Seleziona relatore</ModalHeader>
      <ModalBody>
        <div className="row">
          {formatori.map((form, index) => (
            <div className="col-12">
              <Button
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
                onClick={() => {
                  onClose(form)
                }}
                color="primary"
                key={`${form.id}_${index}`}
              >
                {form.formatore.nome} {form.formatore.cognome} {form.formatore.cod_fisc} {form.formatore.email}
              </Button>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            onClose(null)
          }}
        >
          Annulla
        </Button>
      </ModalFooter>
    </Modal>
  )
}

interface SelezionaRelatoreInterface {
  selecting: boolean
  formatori: any
  onClose: (form: any) => void
}

export default SelezionaRelatore
