import { StatiPolizza, TipoRichiestaPolizza } from 'src/processes/assicurazioni/CostantiPolizze'

export namespace AssicurazioneUtils{

    export function decodificaTipoRichiesta (tipoRichiesta){
        if(tipoRichiesta === "A"){
            return TipoRichiestaPolizza.A;
        }
        if(tipoRichiesta === "R"){
            return TipoRichiestaPolizza.R;
        }
        if(tipoRichiesta === "P"){
            return TipoRichiestaPolizza.P;
        }
        if(tipoRichiesta === "C"){
            return TipoRichiestaPolizza.C;
        }
        if(tipoRichiesta === "N"){
            return TipoRichiestaPolizza.N;
        }
        else{
            return "Non disponibile"
        }
    }

    export function decodificaStatiPolizza(stato){
        if(stato === 0){
            return StatiPolizza._0
        }
        else if(stato === 1){
            return StatiPolizza._1
        }
        else if(stato === 2){
            return StatiPolizza._2
        }
        else if(stato === 3){
            return StatiPolizza._3
        }
        else if(stato === 4){
            return StatiPolizza._4
        }
        else if(stato === 5){
            return StatiPolizza._5
        }
        else if(stato === 6){
            return StatiPolizza._6
        }
        else{
            return StatiPolizza._
        }
    }
}
