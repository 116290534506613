import * as React from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericCard from '../../components/GenericCard'
import GenericButton from '../../components/GenericButton'
import GenericAlert from 'src/frontend/app/components/GenericAlert'
import { FormComunicazioneQuoteIscrizione } from './formQuoteIscrizione/FormComunicazioneQuoteIscrizione'
import { goTo } from 'src/utilities/utility'
import { PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE, PATH_TO_FO_TODO } from 'src/app/utils/RoutesConstants'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice();

class ComunicazioneQuoteIscrizione extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            typeMessage: "default",
        };
    }

    submitMessageResult = (alert: any) => {
        const element = document.getElementById("pageSectionId");
        if (element)
            element.scrollIntoView({ behavior: 'smooth' });
        this.setState({ message: alert.messaggio, typeMessage: alert.type })
        if (alert.type === 'success') {
            setTimeout(async () => {
                goTo(PATH_TO_FO_TODO)
            }, 1000);
        }
    }

    render() {
        const { message, typeMessage } = this.state
        return (
            <>
                <PageSection id='pageSectionId'>
                    <div className='container register-container'>
                        <GenericBreadCrumb paths={[{ label: "Anagrafica", link: "#" }, { label: "Comunicazione quote di iscrizione", link: PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE }]} /><br></br>
                        <Title headingLevel="h1" size="4xl">Comunicazione quote di iscrizione</Title><br></br>
                        <GenericAlert hidden={message === ''} label={message} color={typeMessage}></GenericAlert>
                        <GenericCard isExpandibleCard={false} header={'Compila i seguenti campi'} body={<><FormComunicazioneQuoteIscrizione messaggio={this.submitMessageResult} /></>} /><br></br>
                        <div className='row'>
                        <div className={!isMobileDivice? 'col-1' : 'col-4' }>
                                <GenericButton label={"INDIETRO"} color={"tertiary"} onClick={() => goTo(PATH_TO_FO_TODO)} />
                            </div>
                            <div className={!isMobileDivice? 'col-1' : 'col-4' }>
                                <GenericButton form={"idComunicazioneQuoteIscrizione"} type={"submit"} label={"CONFERMA"} color={"primary"} isDisabled={typeMessage === 'success'} />
                            </div>
                        </div>
                    </div>
                </PageSection>
                <br></br>
                <PageSection>
                    <div className='container register-container'>
                        <Title headingLevel='h3'>Legenda</Title>
                        <p>* Campo obbligatorio</p>
                    </div>
                </PageSection>
            </>
        );
    }
};

export default ComunicazioneQuoteIscrizione;
