import * as React from 'react'
import { Form } from '@patternfly/react-core'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDipendentiIscrittiAlboStpBO extends React.Component<GeneralFormDipendentiIscrittiAlboStpBOProps, any> {
  render() {
    return (
      <div>
        <Form>
          {/*Cicla la lista dei soci/dipendenti e visualizza i dipendenti iscritti (isDipendenteIscritto === 1 e idSogettoPF !== null) */}
          {this.props.composizioneSocietaria?.length > 0 ? (
            this.props.composizioneSocietaria.map((dipendente) => (
              <div className="row" hidden={!(dipendente.isDipendenteIscritto === 1 && dipendente.idSogettoPF !== null)}>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCodiceFiscaleSocio'}
                    label={'Codice fiscale del dipendente'}
                    value={dipendente.cf}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idNomeCognomeSocio'}
                    label={'Nome e cognome dipendente'}
                    value={dipendente.nome + ' ' + dipendente.cognome}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idTitolo'}
                    label={'Titolo del dipendente'}
                    value={dipendente.titProf ? dipendente.titProf : '-'}
                    isReadOnly
                  />
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">Nessun dipendente presente</p>
          )}
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormDipendentiIscrittiAlboStpBOProps {
  messaggio?: any
  composizioneSocietaria?: any
}
