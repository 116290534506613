import React from 'react'
import { Form } from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'

const FormDatiIscritto: React.FunctionComponent<FormDatiIscrittoProps> = ({
  title,
  data,
}) => {

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-6">
            <GenericInput  type="text" label="Codice Fiscale**" value={data?.attivitaFormativaExt?.soggetto?.cf}  isReadOnly/>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <GenericInput type="text" label="Nome**" value={data?.attivitaFormativaExt?.soggetto?.nome} isReadOnly />
          </div>
          <div className="col-6">
            <GenericInput type="text" label="Cognome**"  value={data?.attivitaFormativaExt?.soggetto?.cognome} isReadOnly  />
          </div>
        </div>
      </Form>
    </GenericCard>
  );
};

interface FormDatiIscrittoProps {
  data: any;
  title: string;
}

export default FormDatiIscritto;
