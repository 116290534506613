import React from 'react'
import { Checkbox, FormGroup } from '@patternfly/react-core'

class GenericCheckbox extends React.Component<GeneralCheckboxProps, any>{
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            label: props.label,
            isDisabled: props.isDisabled,
            id: props.id,
            isChecked: props.isChecked,
            onChange: props.onChange,
            onClick: props.onClick,
            isReadOnly: props.isReadOnly
        };
    }

    render() {
        const { label, isDisabled, id, onChange, onClick, value, isReadOnly, isChecked } = this.state;
        return (
            <FormGroup>
                <Checkbox value={value} label={label} id={id} isDisabled={isDisabled} isChecked={isChecked} onClick={onClick} onChange={onChange} readOnly={isReadOnly} />
            </FormGroup>
        );
    }
}

export declare interface GeneralCheckboxProps {
    value?: any;
    label?: any;
    isDisabled?: boolean;
    id?: any;
    isChecked?: boolean;
    onChange?: any;
    onClick?: any;
    isReadOnly?: boolean;
}

export default GenericCheckbox;
