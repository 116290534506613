import { Form, FormGroup, Label } from 'design-react-kit'
import React from 'react'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getFilesByIdIscrizioneFo } from 'src/processes/ProcessoIscrizione'
import GenericCard from '../../../components/GenericCard'
import GenericInput from '../../../components/GenericInput'
import GenericSelect from '../../../components/GenericSelect'
import GenericUploadFront from '../../../components/GenericUpload'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'


/*---------------------- VARIABILI E COSTANTI -------------------------*/
const yes_no_options = [
  { label: "Si", value: "Si" },
  { label: "No", value: "No" }
]

const ordinamentoAmministrazione_options = [
  { label: "Si, secondo l’ordinamento dell’ente di appartenenza", value: "0" },
  { label: "Si, alcune volte (con autorizzazione)", value: "1" },
  { label: "No, salvo specifiche autorizzazioni (annotato a margine)", value: "2" }
]

/*----------------------------------------------------------------------*/

export default class Professione extends React.Component<ProfessioneProps, any>{
  constructor(props) {
    super(props);
    this.state = {
      prevData: props.prevData,
      prevClicked: props.prevClicked,
      fields: (props.prevData && props.prevClicked) ? props.prevData[0] : {},
      isLoaded: false,
      message: '',
      filesIscrizione: []
    };
  }

  /*********************** Azioni iniziali ****************************************/
  async componentDidMount(): Promise<void> {

    if (!this.props.modificaDatiFlag && !this.props.visualizzaFlag && !this.props.reiscrizioneFlag) {
      if (this.props.data) {
        let data = this.props.data;
        this.setState({ fields: data })
      }
    }
    if (this.props.modificaDatiFlag && this.props.data !== undefined) {
      this.setState({ fields: this.props.data })
      var filesIscrizione = await getFilesByIdIscrizioneFo(this.state.fields['idIscrizione'] ? this.state.fields['idIscrizione'] : this.props.data.idIscrizione)
      if (filesIscrizione?.status === 200) {
        if (filesIscrizione?.data[0]?.idAttachment && this.state.fields.uploadFiles) {
          this.state.fields.uploadFiles["idAttachment"] = filesIscrizione?.data[0]?.idAttachment
          await this.setState({ filesIscrizione: filesIscrizione.data })
        }
      }
    }
    else if (this.props.visualizzaFlag) {
      if (this.props.data)
        this.setState({ fields: this.props.data })
    }
    await this.setState({ isLoaded: true })
  }

  render() {
    const { tabNumber } = this.props;
    const { fields } = this.state;

    const isDipendenteSocioSTPHidden = this.state.fields?.dipendenteStp?.label === "Si" || this.state.fields?.socioStp?.label === "Si"

    /********************* Function per aggiornare i campi nel form **********************/
    const setDataItem = (e: any, type: any, field: any, index: any) => {
      let fields = this.state.fields;

      if (type === 'select') {
        if (field === 'tipoPagamento' && index !== undefined) {
          fields[field][index] = { key: index, label: e.label, value: e.label, uploaded: false }
        }
        else fields[field] = { label: e.label, value: e.label };

      }
      else if (type === 'text') {
        if (field === 'allegati' && index !== undefined)
          fields[field][index] = { key: index, label: e.target.value, value: e.target.value, uploaded: false }
        else fields[field] = e.target.value;
      }

      else if (type === 'checkbox') {
        fields[field] = e.target.checked;
      }
      else {
        fields[field] = e.target.value;
      }
      this.setState({ fields: fields });
      this.props.getSelectedValue(fields)
    }

    const getDatiAllegato = (fileData) => {
      fields["uploadFiles"]?.push(fileData);
      fields["uploadFiles"]?.map((e, id) => {
        if((e.tipologiaAllegato === fileData.tipologiaAllegato || e.codiceTipoAllegato === fileData.tipologiaAllegato)){
          if(e.idAttachment && !e.cancellato){
            e["cancellato"] = true
          }else if(e.idAttachment && e.cancellato){
            if(id < fields["uploadFiles"].length-1){
              e["cancellato"] = true
            }
          }else if(!e.idAttachment && id < fields["uploadFiles"].length-1){ //Se id attachment non presente ma non è cancellato
            e["cancellato"] = true
          }
        }else{
          return e
        }
      })
      this.props.getSelectedValue(fields)
    }

    /********************************* Form Iscrizione *************************/
    const fileName = this.state.fields?.uploadFiles?.filter(e => e.codiceTipoAllegato === "statuto_ente_iot")[0]?.filename
    const idAttachment = this.state.fields?.uploadFiles?.filter(e => e.codiceTipoAllegato === "statuto_ente_iot")[0]?.idAttachment
    const formIscrizione = [
      <Form key="formIscrizioneProfessione" id="formIscrizioneProfessione">
        <GenericCard key={'formIscrizioneProfessione'} cardHeader="Professione" cardBody={

          <div key="professioneContrainer">
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"dipendenteStatoOAltro"} >
                  <GenericSelect elementId="dipendenteStatoOAltro" name={'dipendenteStatoOAltro'} onChange={(e) => setDataItem(e, 'select', 'dipendenteStatoOAltro', null)} label='Dipendente dello Stato o di altra pubblica amministrazione *' placeholder='Si / No'
                    defaultOptions={yes_no_options} maxLabelLength={20}
                    defaultValue={(this.state.fields?.dipendenteStatoOAltro) ? this.state.fields?.dipendenteStatoOAltro : null} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <FormGroup label={"ordinamentoAmministrazione"} hidden={!(this.state.fields['dipendenteStatoOAltro'] && this.state.fields['dipendenteStatoOAltro'].label !== 'No')}>
                    <GenericSelect
                      elementId="ordinamentoAmministrazione"
                      name={'ordinamentoAmministrazione'}
                      onChange={(e) => setDataItem(e, 'select', 'ordinamentoAmministrazione', null)}
                      label={"L'ordinamento della amministrazione di appartenenza consente / prevede l'esercizio della libera professione *"}
                      placeholder="Selezionare un'opzione"
                      defaultOptions={ordinamentoAmministrazione_options} maxLabelLength = {30}
                      defaultValue={(this.state.fields['ordinamentoAmministrazione']?.label) ? this.state.fields['ordinamentoAmministrazione'] : null}
                      errorMessageAlert={this.props.message}
                      currentStep={tabNumber}
                      isRequired={(this.state.fields['dipendenteStatoOAltro'] && this.state.fields['dipendenteStatoOAltro'].label !== 'No')}
                      isHidden={!(this.state.fields['dipendenteStatoOAltro'] && this.state.fields['dipendenteStatoOAltro'].label !== 'No')}/>
                  </FormGroup>
              </div>
            </div>
            <br></br>
            <div hidden={!(this.state.fields['dipendenteStatoOAltro'] && this.state.fields['dipendenteStatoOAltro'].label !== 'No' && this.state.fields['ordinamentoAmministrazione'] && this.state.fields['ordinamentoAmministrazione'].label !== ordinamentoAmministrazione_options[2].label)}>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <Label>Autorizzazione alla libera professione</Label>
                </div>
                <div className={isCurrentMobileDevice() ? null : "col-6"}>
                  <GenericUploadFront
                    name={"Statuto Ente che comprovi la possibilità di libera professione"}
                    value={this.state.fields?.uploadFiles?.filter(e => e.codiceTipoAllegato === "statuto_ente_iot")[0]?.filename}
                    datiAllegato={getDatiAllegato}
                    tipologiaAllegato="statuto_ente_iot"
                    acceptedExtensionFile={["pdf"]}
                    id={"statuto"}
                    key="statuto"
                    idAttachment={idAttachment}
                    errorMessageAlert={this.props.message}
                    currentStep={tabNumber}
                    // isRequired={(this.state.fields['dipendenteStatoOAltro'] && this.state.fields['dipendenteStatoOAltro'].label !== 'No' && this.state.fields['ordinamentoAmministrazione'] && this.state.fields['ordinamentoAmministrazione'].label !== ordinamentoAmministrazione_options[2].label)}
                  />
                </div>
              </div>
              <br></br>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"liberoProfessionista"} >
                  <GenericSelect elementId="liberoProfessionista" name={'liberoProfessionista'} onChange={(e) => setDataItem(e, 'select', 'liberoProfessionista', null)} label='Libero Professionista *' placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields['liberoProfessionista']) ? this.state.fields['liberoProfessionista'] : null} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"professionistaFormaAssociata"} >
                  <GenericSelect elementId="professionistaFormaAssociata" name={'professionistaFormaAssociata'} onChange={(e) => setDataItem(e, 'select', 'professionistaFormaAssociata', null)} label="Professionista in forma associata *" placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields['professionistaFormaAssociata']) ? this.state.fields['professionistaFormaAssociata'] : null} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"socioStp"} >
                  <GenericSelect elementId="socioStp" name={'socioStp'} onChange={(e) => setDataItem(e, 'select', 'socioStp', null)} label='Socio STP *' placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields['socioStp']) ? this.state.fields['socioStp'] : null} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"dipendenteStp"} >
                  <GenericSelect elementId="dipendenteStp" name={'dipendenteStp'} onChange={(e) => setDataItem(e, 'select', 'dipendenteStp', null)} label="Dipendente STP *" placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields['dipendenteStp']) ? this.state.fields['dipendenteStp'] : null} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={!isDipendenteSocioSTPHidden}>
                <FormGroup>
                  <GenericSelect
                    elementId="idSocioDipendenteSTP"
                    name={'socioDipendenteSTP'}
                    onChange={(e) => setDataItem(e, 'select', 'flagSocioDipendenteStpPrivato', null)}
                    label="Se socio o dipendente di STP opera anche privatamente *"
                    placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={
                      (this.state.fields['flagSocioDipendenteStpPrivato']) ?
                        this.state.fields['flagSocioDipendenteStpPrivato']
                        : null
                    }
                    errorMessageAlert={this.props.message}
                    isRequired
                    currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"dipendentePrivato"} >
                  <GenericSelect elementId="dipendentePrivato" name={'dipendentePrivato'} onChange={(e) => setDataItem(e, 'select', 'dipendentePrivato', null)} label="Dipendente Privato *" placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields['dipendentePrivato']) ? this.state.fields['dipendentePrivato'] : null} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} >
                <FormGroup label={"nonEsercenteAttivita"} >
                  <GenericSelect elementId="nonEsercenteAttivita" name={'nonEsercenteAttivita'} onChange={(e) => setDataItem(e, 'select', 'nonEsercenteAttivita', null)} label="Non esercente l'attività professionale di cui all'art 2 dell'ordinamento *" placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={(this.state.fields['nonEsercenteAttivita']) ? this.state.fields['nonEsercenteAttivita'] : null} errorMessageAlert={this.props.message} isRequired currentStep={tabNumber} />
                </FormGroup>
              </div>
            </div>
          </div>
        } cardFooter={<div>
          <br></br><small>(i) Ai i sensi dell’art. 3, comma 4, l. n. 3/1976, “I dottori agronomi ed i dottori forestali dipendenti dello Stato o di altra
            pubblica amministrazione possono, a loro richiesta, essere iscritti all’albo. Nei casi in cui, secondo gli ordinamenti loro
            applicabili, è vietato di norma l’esercizio della libera professione, l’iscrizione avviene con annotazione a margine
            attestante il loro stato giuridico-professionale. Questi iscritti non possono esercitare la libera professione, salvi i casi
            previsti dagli ordinamenti loro applicabili</small>
        </div>}></GenericCard>

      </Form>
    ];

    /********************************* Form Visualizza ************************************/
    const formVisualizza = [
      <GenericCard key={'professione'} cardHeader="Professione" cardBody={
        <>
          <div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="dipendenteStatoOAltro" name={'dipendenteStatoOAltro'}
                  defaultValue={(this.state.fields['dipendenteStatoOAltro']) ? this.state.fields['dipendenteStatoOAltro'].label : '-'}
                  label="Dipendente dello Stato o di altra pubblica amministrazione (i)"
                  placeholder=' ' />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                {(this.state.fields['dipendenteStatoOAltro'] && this.state.fields['dipendenteStatoOAltro'].label !== 'No') ?
                  <GenericInput type={'text'} isDisabled id="ordinamentoAmministrazione" name={'ordinamentoAmministrazione'} defaultValue={(this.state.fields['ordinamentoAmministrazione']) ? this.state.fields['ordinamentoAmministrazione'].label : '-'}
                    label="L'ordinamento della amministrazione di appartenenza consente / prevede l'esercizio della libera professione"
                    placeholder=' ' />
                  : null
                }
              </div>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="liberoProfessionista" name={'liberoProfessionista'} defaultValue={(this.state.fields['liberoProfessionista']) ? this.state.fields['liberoProfessionista'].label : 'No'}
                  label='Libero professionista' placeholder='Si / No' />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="professionistaFormaAssociata" name={'professionistaFormaAssociata'} defaultValue={(this.state.fields['professionistaFormaAssociata']) ? this.state.fields['professionistaFormaAssociata'].label : 'No'}
                  label='Professionista in forma associata' placeholder='Si / No' />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="socioStp" name={'socioStp'} defaultValue={(this.state.fields['socioStp']) ? this.state.fields['socioStp'].label : 'No'}
                  label='Socio STP' placeholder='Si / No' />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="dipendenteStp" name={'dipendenteStp'} defaultValue={(this.state.fields['dipendenteStp']) ? this.state.fields['dipendenteStp'].label : 'No'}
                  label='Dipendente STP' placeholder='Si / No' />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"} hidden={!isDipendenteSocioSTPHidden}>
                <FormGroup>
                  <GenericSelect
                    elementId="idSocioDipendenteSTP"
                    name={'socioDipendenteSTP'}
                    onChange={(e) => setDataItem(e, 'select', 'flagSocioDipendenteStpPrivato', null)}
                    label="Se socio o dipendente di STP opera anche privatamente *"
                    placeholder='Si / No'
                    defaultOptions={yes_no_options}
                    defaultValue={
                      (this.state.fields['flagSocioDipendenteStpPrivato']) ?
                        this.state.fields['flagSocioDipendenteStpPrivato']
                        : null
                    }
                    errorMessageAlert={this.props.message}
                    isRequired
                    currentStep={tabNumber}
                    isDisabled />
                </FormGroup>
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="dipendentePrivato" name={'dipendentePrivato'} defaultValue={(this.state.fields['dipendentePrivato']) ? this.state.fields['dipendentePrivato'].label : 'No'}
                  label='Dipendente privato' placeholder='Si / No' />
              </div>
              <div className={isCurrentMobileDevice() ? null : "col-6"}>
                <GenericInput type={'text'} isDisabled id="nonEsercenteAttivita" name={'nonEsercenteAttivita'} defaultValue={(this.state.fields['nonEsercenteAttivita']) ? this.state.fields['nonEsercenteAttivita'].label : 'No'}
                  label="Non esercente l'attività professionale di cui all'art 2
                                    dell'ordinamento" placeholder='Si / No' />
              </div>
            </div>
          </div>
        </>
      } cardFooter={<div>
        <br></br><small>(i) Ai i sensi dell’art. 3, comma 4, l. n. 3/1976, “I dottori agronomi ed i dottori forestali dipendenti dello Stato o di altra
          pubblica amministrazione possono, a loro richiesta, essere iscritti all’albo. Nei casi in cui, secondo gli ordinamenti loro
          applicabili, è vietato di norma l’esercizio della libera professione, l’iscrizione avviene con annotazione a margine
          attestante il loro stato giuridico-professionale. Questi iscritti non possono esercitare la libera professione, salvi i casi
          previsti dagli ordinamenti loro applicabili</small>
      </div>}></GenericCard >
    ]

    if (this.state.isLoaded)
      return (
        <>{(this.props.visualizzaFlag) ? formVisualizza : formIscrizione}
        </>
      )
    else return (<><GenericSpinner /></>)
  }
}
export declare interface ProfessioneProps {
  activeTab?: any;
  tabClick?: any;
  data?: any;
  prevData?: any;
  prevClicked?: any;
  processoIscrizione?: any;
  modificaDatiFlag?: any;
  reiscrizioneFlag?: any;
  visualizzaFlag?: any;
  message?: any;
  getSelectedValue?: any;
  tabNumber?: number;
}
