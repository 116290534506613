import { AppUtils } from 'src/app/utils/FormUtils'
import { saveAs } from 'file-saver'
import moment from 'moment'

import { getAssicurazioneBOResourceApi, getDefaultAxiosConfig } from 'src/app/utils/ManagerRestGateway'
import { ClauseBuilder, GraphqlBuilder, GraphqlGroupBuilder } from 'src/utilities/graphqlUtilities'
import {
  CodiciColonnaFiltrabiliPolizza,
  dbListaStati,
  filterableColums,
  listaTipologiaUtenteVisualizzata
} from 'src/processes/assicurazioni/CostantiPolizze'
import { isEmptyValue, tipoAnagrafeEstesoPerPolizze } from 'src/utilities/utility'
import { getOrdineCompetenteOperatorePOST } from 'src/processes/Ordini'

let queryResponse;

/**
 * Se array = [LE] ==> [\"LE\"]
 * Se array = [LE,MI] ==> [\"LE\",\"MI\",]
 */
function inClauseFromArray(arrayContent) {
  var array = "";
  arrayContent?.map((e) => {
    array = array + "" + e + ",";
  });
  array = array.substring(0, array.length - 1);
  array = array + "";
  return array;
}

function sortField(sortDirection) {
  if (sortDirection) return "(orderBy: " + sortDirection?.toUpperCase() + ")";
  else return "";
}

export async function elencoPolizzeListCsv(
  codiceProvinciaResidenza,
  globalSearchParam,
  listaStati,
  filterParams,
  sortParam
  ) {

    let dataRichiesta
    let denominazione
    let tipologiaUtente
    let fascia
    let premio
    let massimale
    let validitaDal
    let validitaAl
    let stato
    let id
    let dataCreazione
    let dataConferma
    let idIscrizione
    let pivacf
    let progressivoConaf
    let cf
    let nome
    let cognome
    let dataInvioRichiesta
    let tipologiaPolizza
    let siglaProvincia = codiceProvinciaResidenza;
    //costruzione del filtro
    filterParams.forEach(function(filter) {
        if(filter?.filterParamValue &&
          filter?.filterParamValue !== undefined &&
          filter?.filterParamValue !== '' &&
          filter?.filterParamValue.length>0
        ){
          let filterState = filterableColums.filter(function (el) {
            return el.columnIndex === filter.columnIndex
          });

          if (filterState && filterState.length> 0){
            ;

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._tipologia_polizza){
              tipologiaPolizza = filter?.filterParamValue;
            }

            //data invio della richiesta è la data di prima richiesta
            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._data_invio_richiesta){
              dataInvioRichiesta = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._sigla_provincia){
              siglaProvincia = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._nominativo){
              denominazione = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._tipo_utente){
              tipologiaUtente = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._premio){
              premio = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._fascia){
              massimale = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._massimale){
              massimale = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._validita_dal){
              validitaDal = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._validita_al){
              validitaAl = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._stato){
              stato = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._id_polizza){
              id = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._data_creazione){
              dataCreazione = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._data_conferma){
              dataConferma = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._id_iscrizione){
              idIscrizione = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._pivacf){
              pivacf = filter?.filterParamValue;
            }

            if(filterState[0].columnCode === CodiciColonnaFiltrabiliPolizza._progressivo){
              progressivoConaf = filter?.filterParamValue;
            }
          }
        }
    })

    const listaStatiDb = dbListaStati;
    let filterStatiDb = [];
    if(stato){
      let filterState = listaStatiDb.filter(function (el) {
        return el.descrizioneStato === stato
      });

      if (filterState && filterState.length> 0){
        filterStatiDb.push(parseInt(filterState[0]["idStato"]))
      }
    }

    let filterTipologiaUtenteDb = [];
    //filtro per tipologia di utente
    if(tipologiaUtente){

      //filtro i campi gli elementi selezionati
      let filterTipologiaUtente = listaTipologiaUtenteVisualizzata.filter(function(el){
        return el.descrizione === tipologiaUtente
      });

      if(filterTipologiaUtente && filterTipologiaUtente.length > 0){
        filterTipologiaUtenteDb.push(filterTipologiaUtente[0].id)
      }
    }
    let filterDbOrdineTerritorialeCompetente = [];
    if (siglaProvincia ){
      if(typeof(siglaProvincia) === 'string') {
        if(siglaProvincia.length > 2) //non è una sigla ma il codice ordine competente
        {
          await getOrdineCompetenteOperatorePOST(siglaProvincia).then( (responseOrdine) => {
            if(responseOrdine.data) {
                filterDbOrdineTerritorialeCompetente = responseOrdine.data;
              }
          })
        }
        filterDbOrdineTerritorialeCompetente.push(siglaProvincia);
      } else {
        if(typeof(siglaProvincia) === 'object') {
          filterDbOrdineTerritorialeCompetente = siglaProvincia;
        }
      }
    }

    let filterAssociatoConAltriDB
    let filterAssociatoConConafDB
    let filtertipoRichiestaDB = [];

    if (tipologiaPolizza){
      if ("collettiva".includes(tipologiaPolizza.toLowerCase())) {
        filterAssociatoConConafDB = 1
        filterAssociatoConAltriDB = 0
      }
      else if ("individuale".includes(tipologiaPolizza.toLowerCase())) {
        filterAssociatoConConafDB = 0
        filterAssociatoConAltriDB = 1

      }
      else if ("aum. massimale".includes(tipologiaPolizza.toLowerCase())) {
        filtertipoRichiestaDB.push('A')
      }
    }

    const filterCsv = {
       "id" : id? id : undefined,
       "dataConferma" : dataConferma? dataConferma : undefined,
       "dataCreazione" : dataCreazione? dataCreazione : dataInvioRichiesta? dataInvioRichiesta : undefined,
       "premio" : premio? premio : undefined,
       "massimale" : massimale? massimale : undefined,
       "idIscrizione" : idIscrizione? idIscrizione : undefined,
       "stato" : filterStatiDb? filterStatiDb : undefined,
       "fascia" : fascia? fascia : undefined,
       "validitaDal" : validitaDal? validitaDal : undefined,
       "validitaAl" : validitaAl? validitaAl : undefined,
       "nome" : nome? nome : undefined,
       "cf" : cf? cf : undefined,
       "cognome" : cognome? cognome : undefined,
       "denominazione" : denominazione? denominazione : undefined,
       "pivacf" : pivacf? pivacf : undefined,
       "progressivoConaf" : progressivoConaf? progressivoConaf : undefined,
       "ordineTerritorialeCompetente": filterDbOrdineTerritorialeCompetente? filterDbOrdineTerritorialeCompetente : undefined,
       "tipologiaUtente" : filterTipologiaUtenteDb? filterTipologiaUtenteDb: undefined,
       "associatoConAltri" : filterAssociatoConAltriDB? filterAssociatoConAltriDB: undefined,
       "associatoConConaf" : filterAssociatoConConafDB? filterAssociatoConConafDB: undefined,
       "tipoRichiesta" : filtertipoRichiestaDB? filtertipoRichiestaDB: undefined,
    };

    let csvResponse = await getAssicurazioneBOResourceApi().mspoBoCsvElencoPolizzeBOPost(filterCsv,getDefaultAxiosConfig())

    const blob = window.URL.createObjectURL(
      new Blob( [csvResponse.data], { type: "text/csv" } )
    );

    const fileNameCSV = "estrazione_polizze_" + moment(Date.now()).format("YYYYMMDDHHmmss") + ".csv";
    saveAs(blob, fileNameCSV);
    return true
}


export async function elencoPolizzeList(
  codiceProvinciaResidenza,
  globalSearchParam,
  itemPageCount,
  listaStati,
  currentPage,
  filterParams,
  sortParam
  ) {

  let index = currentPage;
  let limit = itemPageCount;
  let filterCodiceOrdineCompetente = codiceProvinciaResidenza;
  if (filterCodiceOrdineCompetente === undefined) filterCodiceOrdineCompetente = [];
  if (filterParams === undefined || filterParams.length === 0)
    filterParams = [
      { filterParamValue: "" },
      { filterParamValue: "" },
      { filterParamValue: "" },
      { filterParamValue: "" },
      { filterParamValue: "" },
      { filterParamValue: "" },
      { filterParamValue: "" },
    ];
    let tipologiaPolizza = filterParams[0]?.filterParamValue;
    var dataRichiesta = filterParams[1]?.filterParamValue;
    let siglaOrdine = filterParams[2]?.filterParamValue;
    let denominazione = filterParams[3]?.filterParamValue;
    let tipologiaUtente = filterParams[4]?.filterParamValue;
    // let premio = filterParams[6]?.filterParamValue;
    // let massimale = filterParams[7]?.filterParamValue;
    let stato = filterParams[10]?.filterParamValue;
    // let id = filterParams[11]?.filterParamValue;
    // let idIscrizione = filterParams[14]?.filterParamValue;
    // let cf = "";
    // let pivacf = filterParams[15]?.filterParamValue;
    // let progressivoConaf = filterParams[16]?.filterParamValue;
    let sortEmpty = true;
    
    if (Object.keys(sortParam).length > 0) {
      // L'oggetto contiene almeno una proprietà
      // Esegui le operazioni quando è presente un ordinamento
      sortEmpty = false;
    } else {
      // L'oggetto è vuoto, nessun ordinamento è impostato
    }
  //campi su cui fare un query jpa in graph join
  const selectQueryAssicurazioniAttive = `
    {
        id,
        idIscrizione,
        associatoConAltri,
        associatoConConaf,
        valoreRischioAltri,
        massimaleAltri${sortField(sortParam?.massimale)},
        premioAltri${sortField(sortParam?.contributoAssicurativo)},
        vrcConaf,
        vopConaf,
        fasciaPremioConaf${sortField(sortParam?.fascia)},
        valoreRischioConaf,
        massimaleConaf${sortField(sortParam?.massimale)},
        premioConaf${sortField(sortParam?.contributoAssicurativo)},,
        periodoValDaConaf${sortField(sortParam?.validitDal)}, 
        periodoValAConaf${sortField(sortParam?.validitAl)},
        periodoValDaAltri${sortField(sortParam?.validitDal)},
        periodoValAAltri${sortField(sortParam?.validitAl)},
        stato,
        dataCreazioneRichiesta,
        dataRichiesta${sortEmpty ? sortField('DESC') : sortParam?.dataInvioRichiesta ? sortField(sortParam?.dataInvioRichiesta) : ""},
        tipoRichiesta,
        tipoUtente${sortField(sortParam?.tipologiaIscritto)},
        cognome,
        nome,
        denominazione${sortEmpty ? sortField('ASC') : sortParam?.nomeECognomeORagioneSociale ? sortField(sortParam?.nomeECognomeORagioneSociale) : ""},
        ordineTerritorialeCompetente${sortField(sortParam?.ordine)},
        flagPagamento, 
        flagCertificato
      }`;
      
      // const selectQueryAssicurazioniAttive = `
      // {
      //     id${sortField(sortParam?.id)},
      //     idIscrizione${sortField(sortParam?.nIscrizione)},
      //     associatoConAltri,
      //     valoreRischioAltri,
      //     massimaleAltri${sortField(sortParam?.massimale)},
      //     premioAltri${sortField(sortParam?.premio)},
      //     numeroPolizaAltri,
      //     associatoConConaf,
      //     vrcConaf,
      //     vopConaf,
      //     categoria,
      //     categoriaCalculated,
      //     maternita,
      //     paternita,
      //     privacy,
      //     idPolizzaAumMassimale,
      //     fasciaPremioConaf,
      //     valoreRischioConaf,
      //     massimaleConaf${sortField(sortParam?.massimale)},
      //     premioConaf${sortField(sortParam?.premio)},
      //     periodoValDaConaf${sortField(sortParam?.validitDal)}, 
      //     periodoValAConaf${sortField(sortParam?.validitAl)},
      //     periodoValDaAltri${sortField(sortParam?.validitDal)},
      //     periodoValAAltri${sortField(sortParam?.validitAl)},
      //     stato${sortField(sortParam?.stato)},
      //     noteRifiuto,
      //     progressivoConaf${sortField(sortParam?.prog)},
      //     dataConferma${sortField(sortParam?.dataConferma)},
      //     dataCreazioneRichiesta${sortField(sortParam?.dataCreazioneRichiesta)},
      //     dataRichiesta${sortParam?.dataInvioRichiesta ? sortField(sortParam?.dataInvioRichiesta) : sortField('DESC')},
      //     tipoRichiesta,
      //     threadStorico,
      //     tipoUtente,
      //     iscrizioneSoggettoEntityView{
      //         cf,
      //         codiceFiscaleAzienda,
      //         cognome,
      //         idIscrizione${sortField(sortParam?.idIscrizione)},
      //         idSoggetto,
      //         nome,
      //         partitaIva,
      //         ragioneSociale,
      //         tipoAnagrafe,
      //         ordineTerritorialeCompetente${sortField(sortParam?.ordine)},
      //         denominazione${sortField(sortParam?.nomeECognomeORagioneSociale)},
      //         pivacf
      //     }
      //   }`;

  //where condition su cui andare a fare la join (bisogna capire come gestisce le on perchè attualmente sta facendo il prodotto cartesiano
  //e poi la where, va verificato spero di no che sia ottimizzato perché in caso di molti record occorre capire come reagisce
  //ma se i dati in input sono filtrati bene non ci dovrebbero essere grossi problemi

  // TEST GRAPHQL BUILDER INIT
  var searchQuery;


  let currentOrdini;

  if(!isEmptyValue(filterCodiceOrdineCompetente)) {
    currentOrdini = filterCodiceOrdineCompetente?.map(function (f) {
      return f.toString();
    });

  }
  if(!isEmptyValue(siglaOrdine)) {
    
      await getOrdineCompetenteOperatorePOST(siglaOrdine).then( (responseOrdine) => {
        if(responseOrdine.data) {
            currentOrdini = responseOrdine.data?.map(function (f) {
              return f.toString();
            });
          }
      })
  }

  let filterGroupQuery = new GraphqlGroupBuilder();
  let clauseOrFilter = [];

  if (dataRichiesta !== "") {
    const newDate = new Date(dataRichiesta);
    // Sottrarre un giorno dalla data
    newDate.setDate(newDate.getDate() - 1);
    // Aggiornare lo stato con la nuova data
    dataRichiesta = newDate.toISOString().split("T")[0];
    let currentClauseBuilder = new ClauseBuilder().gt("dataRichiesta",dataRichiesta);
    clauseOrFilter.push(currentClauseBuilder);
    filterGroupQuery = filterGroupQuery.gt("dataRichiesta", dataRichiesta);
    clauseOrFilter.push("true");
  } 

  if (!isEmptyValue(siglaOrdine) || (!isEmptyValue(filterCodiceOrdineCompetente) && filterCodiceOrdineCompetente.size > 0)) {
    
        if(!isEmptyValue(siglaOrdine)) {
            filterCodiceOrdineCompetente = siglaOrdine
        }

        let currentClauseBuilder = new ClauseBuilder().in("ordineTerritorialeCompetente",
          (filterCodiceOrdineCompetente !== undefined && filterCodiceOrdineCompetente !== "") ?
          currentOrdini.map((o) => {
            return '"' + o + '"'
          })
        : null)
        clauseOrFilter.push(currentClauseBuilder);
        filterGroupQuery = filterGroupQuery.in("ordineTerritorialeCompetente",
          (filterCodiceOrdineCompetente !== undefined && filterCodiceOrdineCompetente !== "") ?
          currentOrdini.map((o) => {
            return '"' + o + '"'
          })
        : null)
    }

  if(stato.includes('TUTTI')) stato = ""
  if (stato !== "") {
    
    //filtra lo stato con la descrizione selezionata
    let statiFiltrati = listaStati?.filter(function (s) {

      if (s.descrizioneStato === stato) {
        return true;
      } else {
        return false;
      }
    });

    //ESCLUDO GLI STATI IN COMPILAZIONE
    statiFiltrati = statiFiltrati?.filter(a => a.idStato !== '0')

    //estraggo dalla lista l'array di identificativi di stato relativi alla lista
    //perchè li devo passare come in clouse
    let currentStates = statiFiltrati?.map(function (f) {
      return f.idStato;
    });
    let currentInClause = inClauseFromArray(currentStates);
    clauseOrFilter.push("true");
    filterGroupQuery = filterGroupQuery.in("stato", currentInClause);
  } else {

    //estraggo dalla lista l'array di identificativi di stato relativi alla lista
    //perchè li devo passare come in clouse - ESCLUDO GLI STATI IN COMPILAZIONE
    //filtra lo stato con la descrizione selezionata
    let statiFiltrati = listaStati?.filter(a => a.idStato !== '0')
    let currentStates = statiFiltrati?.map(function (f) {
      return f.idStato;
    });

    let currentInClause = inClauseFromArray(currentStates);
    clauseOrFilter.push("true");
    filterGroupQuery = filterGroupQuery.in("stato", currentInClause);
  }

  if (tipologiaUtente !== "") {
    let currentClauseBuilder = new ClauseBuilder().like("tipoUtente",tipoAnagrafeEstesoPerPolizze(tipologiaUtente));
    clauseOrFilter.push(currentClauseBuilder);
    filterGroupQuery = filterGroupQuery.eq("tipoUtente",tipoAnagrafeEstesoPerPolizze(tipologiaUtente));
  }

  if (denominazione !== "" && denominazione !== undefined) {
    let currentClauseBuilder = new ClauseBuilder().like("denominazione",denominazione);
    clauseOrFilter.push(currentClauseBuilder);
    filterGroupQuery = filterGroupQuery.like("denominazione",denominazione);
  }

  if (!isEmptyValue(tipologiaPolizza)) {
    if ("collettiva".includes(tipologiaPolizza.toLowerCase())) {
        let currentClauseBuilder = new ClauseBuilder().in("associatoConConaf", 1);
        clauseOrFilter.push(currentClauseBuilder);
        filterGroupQuery = filterGroupQuery.in("associatoConConaf", 1);

    }
    else if ("individuale".includes(tipologiaPolizza.toLowerCase())) {
      let currentClauseBuilder = new ClauseBuilder().in("associatoConAltri", 1);
      clauseOrFilter.push(currentClauseBuilder);
      filterGroupQuery = filterGroupQuery.in("associatoConAltri", 1);
      let currentClauseBuilder2 = new ClauseBuilder().in("associatoConConaf", 0);
      clauseOrFilter.push(currentClauseBuilder2);
      filterGroupQuery = filterGroupQuery.in("associatoConConaf", 0);
    }
    else if ("aum. massimale".includes(tipologiaPolizza.toLowerCase())) {
      let currentClauseBuilder = new ClauseBuilder().like("tipoRichiesta",'A');
      clauseOrFilter.push(currentClauseBuilder);
      clauseOrFilter.push("true");

      filterGroupQuery = filterGroupQuery.like("tipoRichiesta",'A');
    }
  }
  if (
    ( codiceProvinciaResidenza !== "" &&
    codiceProvinciaResidenza !== undefined ) ||
    !isEmptyValue(siglaOrdine)
  ) {

    if(!isEmptyValue(siglaOrdine)) {

        let currentClauseBuilder = new ClauseBuilder().in("ordineTerritorialeCompetente",
          (filterCodiceOrdineCompetente !== undefined && filterCodiceOrdineCompetente !== "") ?
          currentOrdini.map((o) => {
            return '"' + o + '"'
          })
        : null)
        clauseOrFilter.push(currentClauseBuilder);
        filterGroupQuery = filterGroupQuery.in("ordineTerritorialeCompetente",
          (filterCodiceOrdineCompetente !== undefined && filterCodiceOrdineCompetente !== "") ?
          currentOrdini.map((o) => {
            return '"' + o + '"'
          })
        : null)
    } else {

      let currentClauseBuilder = new ClauseBuilder().in("ordineTerritorialeCompetente",
        (filterCodiceOrdineCompetente !== undefined && filterCodiceOrdineCompetente !== "") ?
        currentOrdini.map((o) => {
          return '"' + o + '"'
        })
      : null)
      clauseOrFilter.push(currentClauseBuilder);
      filterGroupQuery = filterGroupQuery.in("ordineTerritorialeCompetente",
        (filterCodiceOrdineCompetente !== undefined && filterCodiceOrdineCompetente !== "") ?
        currentOrdini.map((o) => {
          return '"' + o + '"'
        })
      : null)
    }
    clauseOrFilter.push("true");
  }

  var isFilterParamNotEmpty = filterParams
    .map((a) => a.filterParamValue !== "")
    .includes(true);

    
  let Filter = new GraphqlBuilder().group(filterGroupQuery.buildAND()).build();
  let whereCondition =
    globalSearchParam !== "" && globalSearchParam !== undefined
      ? `${searchQuery}`
      : isFilterParamNotEmpty && filterParams !== undefined
      ? `${Filter}`
      : `${searchQuery}`;

  /**
   * Sono richiamate due query
   * La prima recupera tutte le polizze mentre la seconda recupera tutte le polizze cancellate
   */
  let jpaQuery = `{
            PoAssicurazioneGraphs (
                page:{start:${index}, limit: ${limit}}
                where: ${whereCondition}
            )
            {
                pages
                total
                select ${selectQueryAssicurazioniAttive}
            }
            PoAssicurazioneCancellataGraphs (
              page:{start:${index}, limit: ${limit}}
              where: ${whereCondition}
            ) 
            {
                pages
                total
                select ${selectQueryAssicurazioniAttive}
            }
           
    }`; 

 
    let queryGraph = `query elencoPolizzeBO ($pageRequest: PagedRequestDtoInput) {
            elencoPolizzeBO(pageRequest: $pageRequest){
            index
            size
            totalCount
            pageCount
            content ${selectQueryAssicurazioniAttive}
    }`;

  const json = {
    operationName: "elencoPolizzeBO",
    query: queryGraph,
    variables: {
      pageRequest: {
        index: index,
        size: limit,
        jpsQuery: jpaQuery,
      },
    },
  };

  let rowsNames;

  var rows = undefined;

  rows = await getAssicurazioneBOResourceApi().mspoBoElencoPolizzeBOPost(
    json.variables.pageRequest,
    getDefaultAxiosConfig()
  )
    
   
  var responseData = rows.data.returnedObject;

  if (responseData !== null) {
    rowsNames = [];
    await responseData.content?.map((field) => {
      let massimale = "";
      let periodoValiditaDa = "";
      let periodoValiditaA = "";
      let periodoValiditaADate = "";
      let premio = "";
      let stato;
      let denominazione = "";
      let tipologiaPolizza = "Non disponibile";
      let ordineTerritorialeCompetente = "";
      let tipoUtente = "";
      let fasciaPremioConaf = "";

      if (field.ordineTerritorialeCompetente) {
          ordineTerritorialeCompetente =
          field.ordineTerritorialeCompetente;
      }

      if (field.denominazione) {
          denominazione =
          field.denominazione;
      }

      if (field.associatoConConaf && field.associatoConConaf === 1) {
          tipologiaPolizza = "Collettiva";
          try {
            periodoValiditaADate = field.periodoValAConaf;
            periodoValiditaDa = field.periodoValDaConaf.split("T")[0];
            periodoValiditaA = field.periodoValAConaf.split("T")[0];
            periodoValiditaDa = AppUtils.itDate(periodoValiditaDa);
            periodoValiditaA = AppUtils.itDate(periodoValiditaA);
          } catch (e) {
            periodoValiditaDa = field.periodoValDaConaf;
            periodoValiditaA = field.periodoValAConaf;
          }
      } else if (field.associatoConAltri && field.associatoConAltri === 1) {
          tipologiaPolizza = "Individuale";
          if (field.associatoConAltri === 1) {
            try {
              periodoValiditaADate = field.periodoValAAltri;
              periodoValiditaDa = field.periodoValDaAltri.split("T")[0];
              periodoValiditaA = field.periodoValAAltri.split("T")[0];
              periodoValiditaDa = AppUtils.itDate(periodoValiditaDa);
              periodoValiditaA = AppUtils.itDate(periodoValiditaA);
            } catch (e) {
              periodoValiditaDa = field.periodoValDaAltri;
              periodoValiditaA = field.periodoValAAltri;

            }
          }
      } else if (
        field.associatoConAltri &&
        field.associatoConAltri === 1 &&
        field.associatoConConaf &&
        field.associatoConConaf === 1
      ) {
        try {
          periodoValiditaADate = field.periodoValAConaf;
          periodoValiditaDa = field.periodoValDaConaf.split("T")[0];
          periodoValiditaA = field.periodoValAConaf.split("T")[0];
          periodoValiditaDa = AppUtils.itDate(periodoValiditaDa);
          periodoValiditaA = AppUtils.itDate(periodoValiditaA);

        } catch (e) {
          periodoValiditaDa = field.periodoValDaConaf;
          periodoValiditaDa = AppUtils.itDate(periodoValiditaDa);
          periodoValiditaA = field.periodoValAConaf;
          periodoValiditaA = AppUtils.itDate(periodoValiditaA);
        }
      } else {
        periodoValiditaDa = field.periodoValDaConaf;
        periodoValiditaA = field.periodoValAConaf;
        periodoValiditaDa = AppUtils.itDate(periodoValiditaDa);
        periodoValiditaA = AppUtils.itDate(periodoValiditaA);
      }

      if (field.associatoConConaf === 1 && field.associatoConAltri === 1) {
        massimale = field.massimaleConaf;
        massimale = AppUtils.currency(massimale);
        premio = field.premioConaf;
        premio = AppUtils.currency(premio);
      }
      else if (field.associatoConAltri === 1) {
        massimale = field.massimaleAltri;
        massimale = AppUtils.currency(massimale);
        premio = field.premioAltri;
        premio = AppUtils.currency(premio);
      }
      else if (field.associatoConConaf === 1) {
        massimale = field.massimaleConaf;
        massimale = AppUtils.currency(massimale);
        premio = field.premioConaf;
        premio = AppUtils.currency(premio);
      }

      else {
        massimale = '-';
        premio = '-';
      }

        if (field.stato === 0) stato = "COMPILAZIONE";
        if (field.stato === 1) stato = "DA VALUTARE";
        if (field.stato === 2) {
          stato = "CONFERMATA";
          let now = new Date()
          periodoValiditaADate = new Date(periodoValiditaADate)
          if(periodoValiditaADate < now) {
            stato = stato + " (Scaduta)";
          }

        }
        if (field.stato === 3) stato = "RIFIUTATA";
        if (field.stato === 4) stato = "RICHIESTA INTEGRAZIONE";
        if (field.stato === 5) stato = "ARCHIVIATA"; 
        if (field.stato === 6) stato = "CANCELLATA";
        if (field.tipoRichiesta === "A" && field.stato !== 6) stato = stato + " (Aum. Massimale)";

      if (field.tipoUtente === "PF") tipoUtente =  "Persona Fisica";
      else if (field.tipoUtente === "SC") tipoUtente =  "Persona Giuridica";
      else tipoUtente =  "Non disponibile";

      let dataCreazioneRichiesta = "-";
      if(field.dataCreazioneRichiesta !== null){
        let date = field.dataCreazioneRichiesta.split("T")[0];
        date = AppUtils.itDate(date);
        dataCreazioneRichiesta = date;
      }

      let dataRichiesta = "-";
      if(field.dataRichiesta !== null){
        let date = field.dataRichiesta;
        date = AppUtils.itDate(date);
        dataRichiesta = date;
      }

      if (field.fasciaPremioConaf) {
        fasciaPremioConaf = field.fasciaPremioConaf;
      }

      let response = {
        certificatoIsEmpty: field.flagCertificato === 1 ? false : true,
        pagamentoIsEmpty: field.flagPagamento === 1 ? false : true,
        data: [
          tipologiaPolizza,
          field.dataRichiesta !== null ? dataRichiesta : "",
          ordineTerritorialeCompetente,
          denominazione,
          tipoUtente,
          fasciaPremioConaf,
          premio,
          massimale,
          periodoValiditaDa,
          periodoValiditaA,
          stato,
          field.id
        
        ],
      };
      rowsNames.push(response);
    });

    queryResponse = JSON.stringify({
      index: responseData.index,
      pageCount: responseData.pageCount,
      size: responseData.size,
      totalCount: responseData.totalCount,
      content: rowsNames,
    });

    return queryResponse;
  }
}
