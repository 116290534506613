import React from 'react'

class GenericProgressBar extends React.Component<GeneralProgressBarProps, any>{

    render() {
        return (
            <div className="progress progress-indeterminate" style={this.props.style} hidden={this.props.isHidden}>
            <span className="visually-hidden">In elaborazione...</span>
            <div className="progress-bar" role="progressbar"></div>
          </div>
        );
      }
    }

    export declare interface GeneralProgressBarProps {
        isHidden?: boolean;
        style?: any;
    }

export default GenericProgressBar;
