import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import { getBigIntToConvertToStringSiNo } from 'src/utilities/utility'
import { formatDays } from 'src/backoffice/app/utils/utils'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

/* ----------------------------------- VARIABILI E COSTANTI ----------------------------------------- */
const age_professional = 50
const age_senior = 75
/* -------------------------------------------------------------------------------------------------- */

export class FormDatiIscrizione extends React.Component<GeneralFormDatiIscrizioneProps, any> {
  render() {
    const { messaggio, iscrizione, soggetto } = this.props
    const anzianitaSezioneA = formatDays(soggetto.anzianitaSezioneA)
    const anzianitaSezioneB = formatDays(soggetto.anzianitaSezioneB)
    const anzianitaTot = formatDays(soggetto.anzianitaSezioneA + soggetto.anzianitaSezioneB)

    return (
      <>
        {(soggetto === '' || iscrizione === '') && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idNumeroIscrizione'}
                    label={'Numero iscrizione'}
                    value={iscrizione.numeroIscrizioneOrdine}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataIscrizione'}
                    label={'Data iscrizione'}
                    value={iscrizione.dataIscrizioneOrdine}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idDeliberaIscrizione'}
                    label={'Delibera iscrizione'}
                    value={iscrizione.deliberaIscrizione}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idNumeroTimbro'} label={'Numero timbro'} value={iscrizione.numeroTimbro} isReadOnly />
                </div>
              </div>
              {/*---------------------------------------- Campi visibili solo se presente il numero timbro -------------------------------------*/}
              <div className="row" hidden={!iscrizione.numeroTimbro}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataIniValiTimbro'}
                    label={'Data inizio validità timbro'}
                    value={iscrizione.dataInizioValiTimbro}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataFineValiTimbro'}
                    label={'Data fine validità timbro'}
                    value={iscrizione.dataFineValiTimbro === '9999-12-31' ? '' : iscrizione.dataFineValiTimbro}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              {/*--------------------------------------------------------------------------------------------------------------------------------*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idNumeroMartello'}
                    label={'Numero martello'}
                    value={iscrizione.numeroMartello}
                    isReadOnly
                  />
                </div>
              </div>
              {/*---------------------------------------- Campi visibili solo se presente il numero martello -------------------------------------*/}
              <div className="row" hidden={!iscrizione.numeroMartello}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataIniValiMartello'}
                    label={'Data inizio validità martello'}
                    value={iscrizione.dataInizioValiMartello}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-75"
                    id={'idDataFineValiMartello'}
                    label={'Data fine validità martello'}
                    value={iscrizione.dataFineValiMartello === '9999-12-31' ? '' : iscrizione.dataFineValiMartello}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              {/*--------------------------------------------------------------------------------------------------------------------------------*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'text'} className="w-75" id={'idAnzianita'} label={'Anzianità (anni)'} value={anzianitaTot} isReadOnly />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'} hidden={iscrizione.codiceSezioneRichiesta === 2}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'anzianitaSezioneA'}
                    label={'Anzianità sezione A (anni)'}
                    value={anzianitaSezioneA}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-3'} hidden={iscrizione.codiceSezioneRichiesta === 1}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'anzianitaSezioneB'}
                    label={'Anzianità sezione B (anni)'}
                    value={anzianitaSezioneB}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                {/*----------------------------- Campo visibile solo per iscritti con anzianità superiore ai 50 anni -----------------------------*/}
                <div className={isCurrentMobileDevice() ? null : 'col-6'} hidden={!(soggetto.anniAnzianita > age_professional)}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCategoriaB'}
                    label={'Categoria B - Professional'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.categoriaAnzianitaB)}
                    isReadOnly
                  />
                </div>
                {/*--------------------------------------------------------------------------------------------------------------------------------*/}
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCategoriaC'}
                    label={'Categoria C - Emeriti'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.categoriaAnzianitaC)}
                    isReadOnly
                  />
                </div>
              </div>
              {/*----------------------------- Campo visibile solo per iscritti con anzianità superiore ai 75 anni -----------------------------*/}
              <div className="row" hidden={!(soggetto.anniAnzianita > age_senior)}>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idCategoriaD'}
                    label={'Categoria D - Senior'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.categoriaAnzianitaD)}
                    isReadOnly
                  />
                </div>
              </div>
              {/*--------------------------------------------------------------------------------------------------------------------------------*/}
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idEsoneroQuotaOrdine'}
                    label={'Esonerato da pagamento della quota all’ordine'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagEsoneratoPagamentoQuotaIscrizione)}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idEsoneroQuotaConaf'}
                    label={'Esonerato da pagamento della quota CONAF'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagEsoneratoPagamentoQuotaConaf)}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idIscrittoPeritoTribunalePenale'}
                    label={'Iscritto perito tribunale penale'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagIscrittoPeritoTribunalePenale)}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-75"
                    id={'idIscrittoCTUTribunaleCivile'}
                    label={'Iscritto CTU tribunale civile'}
                    value={getBigIntToConvertToStringSiNo(iscrizione.flagIscrittoCtuTribunaleCivile)}
                    isReadOnly
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiIscrizioneProps {
  soggetto?: any
  messaggio?: any
  iscrizione?: any
}
