import * as React from 'react'
import { GenericTextArea } from '../../../components/GenericTextArea'
import { valutaIscrizioneOrdine } from 'src/processes/ProcessoIscrizione'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import { Label } from 'design-react-kit'
import { Form } from '@patternfly/react-core'
import { inputRequiredValidation } from 'src/utilities/utility'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormRigettaIscrizione extends React.Component<GeneralFormRigettaIscrizioneProps, any> {
  handleSubmit = async (event) => {
    /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
    var requiredFields = [{ value: event.target.idMotivoRigetto.value, label: 'Motivo del rigetto' }]

    var errorRequired: any = inputRequiredValidation(requiredFields)
    if (errorRequired !== undefined && errorRequired !== '') {
      event.preventDefault()
      return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
    } else {
    /*----------------------------------------------------------------------------------------------------------------------------*/
      //chiamata al servizio valutaIscrizione per rigetta iscrizione
      var esitoValutazione = 'rigettato'
      var idIscrizione = this.props.idIscrizione
      var idSoggetto = this.props.idSoggetto
      var fileDeliberaRigettoIot = this.state?.fileDeliberaRigettoIot ? [this.state.fileDeliberaRigettoIot] : []
      var parametriRigetta = {
        motivoRigetto: event.target.idMotivoRigetto.value ? event.target.idMotivoRigetto.value : '',
      }
      try {
        event.preventDefault()
        await valutaIscrizioneOrdine(esitoValutazione, idIscrizione, idSoggetto, parametriRigetta, fileDeliberaRigettoIot)
        return this.props.messaggio({ messaggio: 'Dati salvati con successo', type: 'success' })
      } catch (err) {
        return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: 'danger' })
      }
    }
  }

  render() {
    const getDatiDeliberaRigettoIot = (fileData) => {
      this.setState({ fileDeliberaRigettoIot: fileData })
    }

    return (
      <div>
        <Form id="idRigettaIscrizione" onSubmit={this.handleSubmit}>
          <div>
            <GenericTextArea
              className="w-50"
              label={'Motivo del rigetto'}
              id={'idMotivoRigetto'}
              ariaLabel={'textarea-motivoRigetto'}
              placeholder={'Inserire motivo'}
              isRequired
            />
          </div>
          <div>
            <div className="row">
              <Label>
                <strong>Delibera rigetto</strong>
              </Label>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <GenericUploadFront
                  id={'idDeliberaRigettoIot'}
                  datiAllegato={getDatiDeliberaRigettoIot}
                  tipologiaAllegato={'delibera_rigetto_iot'}
                  acceptedExtensionFile={['pdf']}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormRigettaIscrizioneProps {
  messaggio?: any
  idIscrizione?: any
  idSoggetto?: any
  fileDeliberaRigettoIot?: any
}
