import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

/* ---------- VARIABILI E COSTANTI ---------- */
const check_tipologia_societa = 'pluridisciplinare'
/* ------------------------------------------ */

export class FormDatiProfessionaliStpBO extends React.Component<GeneralFormDatiProfessionaliStpBOProps, any> {
  render() {
    const { soggetto, messaggio, tipologiaSocieta } = this.props
    return (
      <>
        {soggetto === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idTipologiaSocieta'}
                    label={'Tipologia società'}
                    value={tipologiaSocieta ? tipologiaSocieta : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idOggettoProfessionalePrevalente'}
                    label={'Oggetto professionale prevalente'}
                    value={soggetto.socAttivitaProfessionalePrevalente ? soggetto.socAttivitaProfessionalePrevalente : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={isCurrentMobileDevice() ? null : 'col-6'}
                  hidden={tipologiaSocieta && tipologiaSocieta.toLowerCase() !== check_tipologia_societa}
                >
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idOggettoProfessionaleSecondaria'}
                    label={'Oggetto professionale secondaria'}
                    value={soggetto.socAttivitaProfessionaleSecondaria ? soggetto.socAttivitaProfessionaleSecondaria : '-'}
                    isReadOnly
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiProfessionaliStpBOProps {
  messaggio?: any
  soggetto?: any
  tipologiaSocieta?: any
}
