/* eslint-disable array-callback-return */
import React from 'react'
import { SearchInput } from '@patternfly/react-core'

class GenericSearchInput extends React.Component<GeneralSearchInputProps, any>{
    constructor(props) {
        super(props);
        this.state = {
            placeholder: props.placeholder,
            value: props.value,
            idDisabled: props.isDisabled,
            isHidden: props.isHidden,
            onChange: props.onChange,
            onClick: props.onClick,
            onKeyUp: props.onKeyUp,
            style: props.style
        };
    }

    render() {
        const { placeholder, isDisabled, onChange, isHidden, onClick, onKeyUp, style } = this.state;

        return (
            <SearchInput style={style} disabled={isDisabled} placeholder={placeholder} value={this.props.value} onKeyUp={onKeyUp} onClick={onClick} onChange={onChange} onClear={() => this.setState({value: ""})} hidden={isHidden} />
        );
    }
}

export declare interface GeneralSearchInputProps {
    placeholder?: any;
    value?: any;
    isHidden?: boolean;
    isDisabled?: boolean;
    onChange?: any;
    onClick?: any;
    onKeyUp?: any;
    style?: any;
}

export default GenericSearchInput;
