import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormUbicazioneContattiStpBO extends React.Component<GeneralFormUbicazioneContattiStpBOProps, any> {
  render() {
    const { messaggio, provinciaSedeLegale, provinciaSedeOperativa, sedeLegale } = this.props
    return (
      <>
        {sedeLegale === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idIndirizzoSedeLegale'}
                    label={'Indirizzo sede legale'}
                    value={sedeLegale?.slIndirizzoStudio ? sedeLegale?.slIndirizzoStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idProvinciaSedeLegale'}
                    label={'Provincia sede legale'}
                    value={provinciaSedeLegale ? provinciaSedeLegale : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCittaSedeLegale'}
                    label={'Città sede legale'}
                    value={sedeLegale?.slCittaStudio ? sedeLegale?.slCittaStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCapSedeLegale'}
                    label={'CAP sede legale'}
                    value={sedeLegale?.slCapStudio ? sedeLegale?.slCapStudio : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'tel'}
                    className="w-100"
                    id={'idTelSedeLegale'}
                    label={'Telefono sede legale'}
                    value={sedeLegale?.slTelefonoStudio ? sedeLegale?.slTelefonoStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'tel'}
                    className="w-100"
                    id={'idFaxSedeLegale'}
                    label={'Fax sede legale'}
                    value={sedeLegale?.slFaxStudio ? sedeLegale?.slFaxStudio : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'email'}
                    className="w-100"
                    id={'idEmailSedeLegale'}
                    label={'Mail sede legale'}
                    value={sedeLegale?.slMailStudio ? sedeLegale?.slMailStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'email'}
                    className="w-100"
                    id={'idPecSedeLegale'}
                    label={'PEC sede legale'}
                    value={sedeLegale?.slPecStudio ? sedeLegale?.slPecStudio : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idIndirizzoSedeOperativa'}
                    label={'Indirizzo sede operativa'}
                    value={sedeLegale?.sopIndirizzoStudio ? sedeLegale?.sopIndirizzoStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idProvinciaSedeOperativa'}
                    label={'Provincia sede operativa'}
                    value={provinciaSedeOperativa ? provinciaSedeOperativa : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCittaSedeOperativa'}
                    label={'Città sede operativa'}
                    value={sedeLegale?.sopCittaStudio ? sedeLegale?.sopCittaStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCapSedeOperativa'}
                    label={'CAP sede operativa'}
                    value={sedeLegale?.sopCapStudio ? sedeLegale?.sopCapStudio : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'tel'}
                    className="w-100"
                    id={'idTelSedeOperativa'}
                    label={'Telefono sede operativa'}
                    value={sedeLegale?.sopTelefonoStudio ? sedeLegale?.sopTelefonoStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'tel'}
                    className="w-100"
                    id={'idFaxSedeOperativa'}
                    label={'Fax sede operativa'}
                    value={sedeLegale?.sopFaxStudio ? sedeLegale?.sopFaxStudio : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'email'}
                    className="w-100"
                    id={'idEmailSedeOperativa'}
                    label={'Mail sede operativa'}
                    value={sedeLegale?.sopMailStudio ? sedeLegale?.sopMailStudio : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'email'}
                    className="w-100"
                    id={'idPecSedeOperativa'}
                    label={'PEC sede operativa'}
                    value={sedeLegale?.sopPecStudio ? sedeLegale?.sopPecStudio : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idSito'}
                    label={'Sito internet'}
                    value={sedeLegale?.sitoInternet ? sedeLegale?.sitoInternet : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idFacebook'}
                    label={'Facebook'}
                    value={sedeLegale?.facebook ? sedeLegale?.facebook : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idTwitter'}
                    label={'Twitter'}
                    value={sedeLegale?.twitter ? sedeLegale?.twitter : '-'}
                    isReadOnly
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormUbicazioneContattiStpBOProps {
  messaggio?: any
  sedeLegale?: any
  provinciaSedeLegale?: any
  provinciaSedeOperativa?: any
}
