import { getAnagrafeResourcesApi, getDefaultAxiosConfig } from 'src/app/utils/ManagerRestGateway'
import { PATH_TO_ERROR } from 'src/app/utils/RoutesConstants'
import { ErrorStore } from 'src/errorBoundary/ErrorStore'

/**
 * POST della denominazione by codice gruppo
 * @param {string} codiceGruppo
 * @returns denominazione
 */
 export async function getDenominazioneByCodiceGruppo(codiceGruppo: string) {
  try {
    var param = {
        "param": codiceGruppo
      }
    const response = await  getAnagrafeResourcesApi().msgaAnagrafeFoGetDenominazioneByCodiceGruppoPost(param, getDefaultAxiosConfig())
    return response;

  } catch (err: any) {

    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(PATH_TO_ERROR, err)
    let msg = "Errore durante la chiamata msgaAnagrafeGetDenominazioneByCodiceGruppoPost. ";
    throw new Error(msg);
  }
}
