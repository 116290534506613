import { AppCostants } from './AppConstant'

// FO - HOME
export const PATH_TO_HOME = '/'

// SEZIONE ROOT
export const PATH_TO_FO = PATH_TO_HOME + 'fo'
export const PATH_TO_BO = PATH_TO_HOME + 'bo'
export const PATH_TO_ERROR = PATH_TO_HOME + 'errore'

/**
 * SEZIONE SENZA LOGIN
 */

// ALBO UNICO
export const PATH_TO_RICERCA_ALBO_UNICO = PATH_TO_HOME + 'ricerca-albo-unico'
export const PATH_TO_RISULTATI_RICERCA_ALBO_UNICO = PATH_TO_RICERCA_ALBO_UNICO + 'risultati-ricerca-albo-unico'

/**
 * SEZIONE FRONT OFFICE
 */

// FO
export const PATH_TO_FO_STUDI_ASSOCIATI_ELENCO = PATH_TO_FO + '/studi-associati'
export const PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO = PATH_TO_FO_STUDI_ASSOCIATI_ELENCO + '/nuovo-studio-associato'
export const PATH_TO_FO_TODO = PATH_TO_FO + '/da-fare'
export const PATH_TO_FO_PRINT_DATA = PATH_TO_FO + '/printData'

// FO - POLICY
export const PATH_TO_FO_PRIVACY = '/privacy'
export const PATH_TO_FO_COOKIE = '/cookie'
export const PATH_TO_FO_FEEDBACK_ACCESSIBILITA = '/meccanismo-di-feedback-accessibilita'

// FO - ANAGRAFICA
export const PATH_TO_FO_ANAG_SELEZIONA_PROFILO = PATH_TO_FO + '/anagrafica/seleziona-profilo'
export const PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO = PATH_TO_FO + '/anagrafica/richiedi-nuovo-profilo'
export const PATH_TO_FO_ANAG_CAMBIO_SEZIONE = PATH_TO_FO + '/anagrafica/cambio-sezione'
export const PATH_TO_FO_ATTI_DISCIPLINARI = PATH_TO_FO + '/atti-disciplinari'

// FO - ANAGRAFICA ISCRIZIONE ORDINE TERRITORIALE
export const PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE = PATH_TO_FO + '/anagrafica/iscrizione-ordine'
export const PATH_TO_FO_ANAG_REISCRIZIONE_ORDINE = PATH_TO_FO + '/anagrafica/reiscrizione-ordine'
export const PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA = PATH_TO_FO + '/anagrafica/modifica-iscrizione-ordine'
export const PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA = PATH_TO_FO + '/anagrafica/visualizza-iscrizione-ordine'
export const PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE = PATH_TO_FO + '/anagrafica/trasferimento-ordine'

// FO - ANAGRAFICA ISCRIZIONE STP
export const PATH_TO_FO_ANAG_ISCRIZIONE_STP = PATH_TO_FO + '/anagrafica/iscrizione-stp'
export const PATH_TO_FO_ANAG_REISCRIZIONE_STP = PATH_TO_FO + '/anagrafica/reiscrizione-stp'
export const PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA = PATH_TO_FO + '/anagrafica/modifica-iscrizione-stp'
export const PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA = PATH_TO_FO + '/anagrafica/visualizza-iscrizione-stp'

export const PATH_TO_FO_ISCRIZIONE_FORMATORE = PATH_TO_FO + '/anagrafica/iscrizione-formatore'
export const PATH_TO_BO_ELENCO_FORMATORI = PATH_TO_BO + '/anagrafica/elenco-formatori'
export const PATH_TO_FO_ELENCO_FORMATORI = PATH_TO_FO + '/anagrafica/elenco-formatori'
export const PATH_TO_BO_DETTAGLIO_FORMATORE = PATH_TO_BO + '/anagrafica/dettaglio-formatore'
export const PATH_TO_FO_DETTAGLIO_FORMATORE = PATH_TO_FO + '/anagrafica/dettaglio-formatore'

// FO - ANAGRAFICA OPERATORI
export const PATH_TO_FO_ANAG_ISCRIZIONE_OOT = PATH_TO_FO + '/anagrafica/iscrizione-operatore-ordine-territoriale'
export const PATH_TO_FO_ANAG_ISCRIZIONE_OSTP = PATH_TO_FO + '/anagrafica/iscrizione-operatore-stp'
export const PATH_TO_FO_ANAG_ISCRIZIONE_OCONAF = PATH_TO_FO + '/anagrafica/iscrizione-conaf-nazionale'
export const PATH_TO_FO_ANAG_ISCRIZIONE_OEF = PATH_TO_FO + '/anagrafica/iscrizione-operatore-oef'
export const PATH_TO_FO_ANAG_ISCRIZIONE_OEE = PATH_TO_FO + '/anagrafica/iscrizione-operatore-oee'
export const PATH_TO_FO_ANAG_ISCRIZIONE_OER = PATH_TO_FO + '/anagrafica/iscrizione-operatore-oer'
export const PATH_TO_FO_ANAG_ISCRIZIONE_OF = PATH_TO_FO + '/anagrafica/iscrizione-operatore-federazione'


// FO - ASSICURAZIONI
export const PATH_TO_FO_ASSICURAZIONI = PATH_TO_FO + '/assicurazioni'
export const PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA = PATH_TO_FO_ASSICURAZIONI + '/visualizza-polizza'
export const PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA = PATH_TO_FO_ASSICURAZIONI + '/aumenta-massimale-polizza'
export const PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA = PATH_TO_FO_ASSICURAZIONI + '/modifica-polizza'
export const PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA = PATH_TO_FO_ASSICURAZIONI + '/cancella-polizza'

// FO - FORMAZIONE
export const PATH_TO_FO_FORMAZIONE = PATH_TO_FO + '/formazione'
export const PATH_TO_FO_FORMAZIONE_ISCRIZIONE_AF = PATH_TO_FO_FORMAZIONE + '/iscrizione-agenzia-formazione'
export const PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_CATALOGO = PATH_TO_FO_FORMAZIONE + '/creazione-evento-catalogo'
export const PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_INDIVIDUALE = PATH_TO_FO_FORMAZIONE + '/creazione-evento-individuale'
export const PATH_TO_FO_ISCRIZIONE_POF = PATH_TO_FO + '/iscrizione-piani-formativi'
export const PATH_TO_FO_VISUALIZZA_POF = PATH_TO_FO + '/visualizza-piano-formativo'

export const PATH_TO_FO_ELENCO_POF = PATH_TO_FO + '/piani-formativi'
export const PATH_TO_FO_RICHIESTA_ESONERO = PATH_TO_FO_FORMAZIONE + '/richiesta-esonero'
export const PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO = PATH_TO_FO_FORMAZIONE + '/dettaglio-richiesta-esonero'

export const PATH_TO_FO_ELENCO_AGENZIE = PATH_TO_FO_FORMAZIONE + '/elenco-agenzie'
export const PATH_TO_FO_ELENCO_ENTI_ESTERNI = PATH_TO_FO_FORMAZIONE + '/elenco-enti-esterni'
export const PATH_TO_FO_DETTAGLIO_AGENZIA = PATH_TO_FO_FORMAZIONE + '/dettaglio-agenzia'
export const PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO = PATH_TO_FO_FORMAZIONE + '/dettaglio-ente-esterno'
export const PATH_TO_FO_DETTAGLIO_RIVISTA = PATH_TO_FO_FORMAZIONE + '/dettaglio-rivista'
export const PATH_TO_FO_ELENCO_EVENTI_CATALOGO = PATH_TO_FO_FORMAZIONE + '/elenco-eventi-catalogo'
export const PATH_TO_FO_ISCRITTI_EVENTO_CATALOGO = PATH_TO_FO_FORMAZIONE + '/iscritti-evento-catalogo'
export const PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO = PATH_TO_FO_FORMAZIONE + '/dettaglio-evento-catalogo'
export const PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI = PATH_TO_FO_FORMAZIONE + '/elenco-eventi-individuali'
export const PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE = PATH_TO_FO_FORMAZIONE + '/dettaglio-evento-individuale'
export const PATH_TO_FO_ELENCO_RIVISTE = PATH_TO_FO_FORMAZIONE + '/elenco-riviste'

export const PATH_TO_FO_ELENCO_RICHIESTE_ESONERO = PATH_TO_FO_FORMAZIONE + '/elenco-richieste-esonero'
export const PATH_TO_FO_CREDITI_FORMATIVI = PATH_TO_FO_FORMAZIONE + '/crediti-formativi'

// FO - Manualistica
export const PATH_DOC_MANUALISTICA_OPERATORI_CONAF = AppCostants.PUBLIC_SHARE + "/manuali/Manuale Back-office Operatori_CONAF_Nazionale.docx.pdf"
export const PATH_DOC_MANUALISTICA_OPERATORI_ODAF_FODAF = AppCostants.PUBLIC_SHARE + "/manuali/Manuale Back-office Ordine Territoriale_e_Federazione.pdf"
export const PATH_DOC_MANUALISTICA_ISCRITTI = AppCostants.PUBLIC_SHARE + "/manuali/Manuale Front-office.pdf"

/**
 * SEZIONE BACK OFFICE
 */

export const PATH_TO_BO_DASHBOARD = PATH_TO_BO + '/dashboard'
export const PATH_TO_BO_CONTATTI = PATH_TO_BO + '/contatti'
export const PATH_TO_BO_ANAG_CAMBIO_SEZIONE = PATH_TO_BO + '/anagrafica/cambio-sezione'
export const PATH_TO_BO_FORMAZIONE_PROFESSIONALE = PATH_TO_BO + '/formazione-professionale'

// BO - ANAGRAFICA
export const PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA = PATH_TO_BO + '/anagrafica/richiesta-cambio-sezione'
export const PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA = PATH_TO_BO + '/anagrafica/richiesta-cambio-sezione/accetta'
export const PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RIFIUTA = PATH_TO_BO + '/anagrafica/richiesta-cambio-sezione/rifiuta'
export const PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA = PATH_TO_BO + '/anagrafica/richiesta-cambio-sezione/visualizza'
export const PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA = PATH_TO_BO + '/anagrafica/richiesta-trasferimento-ordine'
export const PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA = PATH_TO_BO + '/anagrafica/richiesta-trasferimento-ordine/accetta'
export const PATH_TO_BO_ANAG_TRASF_ORDINE_RIFIUTA = PATH_TO_BO + '/anagrafica/richiesta-trasferimento-ordine/rifiuta'
export const PATH_TO_BO_ANAG_TRASF_ORDINE_CONCEDI_NULLA_OSTA = PATH_TO_BO + '/anagrafica/richiesta-trasferimento-ordine/concedi-nulla-osta'
export const PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA = PATH_TO_BO + '/anagrafica/richiesta-trasferimento-ordine/visualizza'

// BO - ANAGRAFICA
export const PATH_TO_BO_ANAG = PATH_TO_BO + '/anagrafica'

// BO - ANAGRAFICA ELENCO ISCRITTI - ISCRIZIONE ORDINE
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI = PATH_TO_BO_ANAG + '/iscritti'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/visualizza-iscrizione-ordine'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_MODIFICA_ISCRIZIONE = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/modifica-iscrizione-ordine'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/conferma-iscrizione-ordine'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/rigetta-iscrizione-ordine'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/cancella-iscrizione-ordine'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/richiedi-integrazioni-iscrizione-ordine'

// BO - ANAGRAFICA CARATTERISTICHE ORDINI E FEDERAZIONI
export const PATH_TO_BO_ANAG_CONTATTI_ORDINI = PATH_TO_BO_ANAG + '/contatti-ordini'
export const PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI = PATH_TO_BO_ANAG_CONTATTI_ORDINI + '/dettagli'

// BO - ANAGRAFICA ELENCO ISCRITTI - DISCIPLINARI
export const PATH_TO_BO_ATTI_DISCIPLINARI = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/atti-disciplinari'
export const PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA = PATH_TO_BO_ATTI_DISCIPLINARI + '/creazione-disciplinari'
export const PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA = PATH_TO_BO_ATTI_DISCIPLINARI + '/modifica-disciplinari'
export const PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA = PATH_TO_BO_ATTI_DISCIPLINARI + '/visualizza-disciplinari'
export const PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA = PATH_TO_BO_ATTI_DISCIPLINARI + '/cancella-disciplinari'

//BO - ANAGRAFICA - QUOTE ISCRIZIONE
export const PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE = PATH_TO_BO_ANAG + '/comunicazione-quote-iscrizione'

// BO - ANAGRAFICA ELENCO ISCRITTI - ISCRIZIONE STP
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/visualizza-iscrizione-stp'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO_STP = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/conferma-iscrizione-stp'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE_STP = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/rigetta-iscrizione-stp'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO_STP = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/cancella-iscrizione-stp'
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/richiedi-integrazioni-iscrizione-stp'

// BO - ANAGRAFICA ELENCO ISCRITTI - REGISTRAZIONE PAGAMENTO
export const PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO = PATH_TO_BO_ANAG_ELENCO_ISCRITTI + '/registra-pagamento'

// BO - ANAGRAFICA ELENCO OPERATORI
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI = PATH_TO_BO + '/anagrafica/operatori'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_VISUALIZZA_DATI_ISCRITTO = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/visualizza-dati-iscritto'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_VISUALIZZA_DATI_STP = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/visualizza-dati-stp'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_CONFERMA_ISCRITTO = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/conferma-iscritto'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_CONFERMA_ISCRITTO_STP = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/conferma-iscrizione-stp'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_RIGETTA_ISCRIZIONE = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/rigetta-iscrizione'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_RIGETTA_ISCRIZIONE_STP = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/rigetta-iscrizione-stp'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/richiedi-integrazioni-iscrizione'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/richiedi-integrazioni-iscrizione-stp'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_CANCELLA_ISCRITTO = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/cancella-iscritto'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_CANCELLA_ISCRITTO_STP = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/cancellazione-stp'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_REGISTRAZIONE_PAGAMENTO = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/registrazione-pagamento'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_MODIFICA_ISCRIZIONE = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/modifica-iscrizione'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/gestione-utenti'
export const PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_ORDINI = PATH_TO_BO_ANAG_ELENCO_OPERATORI + '/gestione-ordini'

// BO - POLIZZE
export const PATH_TO_BO_POLIZZE = PATH_TO_BO + '/polizze'
export const PATH_TO_BO_POLIZZE_ELENCO = PATH_TO_BO_POLIZZE + '/elenco'
export const PATH_TO_BO_POLIZZE_ELENCO_CONFERMA = PATH_TO_BO_POLIZZE_ELENCO + '/conferma-polizza'
export const PATH_TO_BO_POLIZZE_ELENCO_RIGETTA = PATH_TO_BO_POLIZZE_ELENCO + '/rigetta-polizza'
export const PATH_TO_BO_POLIZZE_ELENCO_VISUALIZZA = PATH_TO_BO_POLIZZE_ELENCO + '/visualizza-polizza'
export const PATH_TO_BO_POLIZZE_ELENCO_RIAPRI = PATH_TO_BO_POLIZZE_ELENCO + '/riapri-polizza'
export const PATH_TO_BO_POLIZZE_ELENCO_MODIFICA = PATH_TO_BO_POLIZZE_ELENCO + '/modifica-polizza'
export const PATH_TO_BO_POLIZZE_ELENCO_CANCELLA = PATH_TO_BO_POLIZZE_ELENCO + '/cancella-polizza'
export const PATH_TO_BO_POLIZZE_ELENCO_RIGETTA_RIAPERTURA = PATH_TO_BO_POLIZZE_ELENCO + '/rigetta-riapertura'
export const PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI = PATH_TO_BO_POLIZZE_ELENCO + '/richiedi-integrazioni'

// BO - FORMAZIONE
export const PATH_TO_BO_FORMAZIONE = PATH_TO_BO + '/formazione'
export const PATH_TO_BO_ELENCO_AGENZIE = PATH_TO_BO_FORMAZIONE + '/elenco-agenzie'
export const PATH_TO_BO_ELENCO_ENTI_ESTERNI = PATH_TO_BO_FORMAZIONE + '/elenco-enti-esterni'
export const PATH_TO_BO_DETTAGLIO_AGENZIA = PATH_TO_BO_FORMAZIONE + '/dettaglio-agenzia'
export const PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO = PATH_TO_BO_FORMAZIONE + '/dettaglio-ente-esterno'
export const PATH_TO_BO_DETTAGLIO_RIVISTA = PATH_TO_BO_FORMAZIONE + '/dettaglio-rivista'
export const PATH_TO_BO_ELENCO_EVENTI_CATALOGO = PATH_TO_BO_FORMAZIONE + '/elenco-eventi-catalogo'
export const PATH_TO_BO_CONCEDI_DEROGA = PATH_TO_BO_FORMAZIONE + '/concedi-deroga'
export const PATH_TO_BO_LISTA_ISCRITTI_ORDINE = PATH_TO_BO_FORMAZIONE + '/lista-iscritti-ordine'
export const PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP = PATH_TO_BO_FORMAZIONE + '/assegnazione-automatica'
export const PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP = PATH_TO_BO_FORMAZIONE + '/assegnazione-manuale'
export const PATH_TO_BO_ELENCO_RIVISTE = PATH_TO_BO_FORMAZIONE + '/elenco-riviste'
export const PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO = PATH_TO_BO_FORMAZIONE + '/iscritti-evento-catalogo'
export const PATH_TO_BO_CFP_EVENTO_CATALOGO = PATH_TO_BO_FORMAZIONE + '/assegnazione-crediti'
export const PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO = PATH_TO_BO_FORMAZIONE + '/dettaglio-evento-catalogo'
export const PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI = PATH_TO_BO_FORMAZIONE + '/elenco-eventi-individuali'
export const PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE = PATH_TO_BO_FORMAZIONE + '/dettaglio-evento-individuale'
export const PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO = PATH_TO_BO_FORMAZIONE + '/dettaglio-richiesta-esonero'
export const PATH_TO_BO_ELENCO_RICHIESTE_ESONERO = PATH_TO_BO_FORMAZIONE + '/elenco-richieste-esonero'
export const PATH_TO_BO_CREDITI_FORMATIVI = PATH_TO_BO_FORMAZIONE + '/crediti-formativi'
export const PATH_TO_BO_ENTE_ESTERNO = PATH_TO_BO_FORMAZIONE + '/ente-esterno'
export const PATH_TO_BO_CREAZIONE_RIVISTA = PATH_TO_BO_FORMAZIONE + '/creazione-rivista'

export const PATH_TO_BO_ELENCO_POF = PATH_TO_BO_FORMAZIONE + '/elenco-piani-formativi'
export const PATH_TO_BO_DETTAGLIO_POF = PATH_TO_BO_FORMAZIONE + '/dettaglio-piano-formativo'

// BO - STATISTICHE
export const PATH_TO_BO_STATS = PATH_TO_BO + '/statistiche'
export const PATH_TO_BO_STATS_ANAG = PATH_TO_BO_STATS + '/anagrafica'
export const PATH_TO_BO_STATS_POLI = PATH_TO_BO_STATS + '/polizze'
export const PATH_TO_BO_STATS_FOR = PATH_TO_BO_STATS + '/formazione'
export const PATH_TO_BO_STATS_DISC = PATH_TO_BO_STATS + '/disciplinare'
export const PATH_TO_BO_STATS_INI_PEC = PATH_TO_BO_STATS + '/ini-pec'
export const PATH_TO_BO_STATS_REG_INDE = PATH_TO_BO_STATS + '/reg-inde'

/**
 * Ruoli
 */
export const RO_AUTHENTICATED = 'ROLE_AUTHENTICATED'
export const RO_CONS_PROV_DISC = 'RO_CONS_PROV_DISC'
export const RO_CONS_ANAG_CONAF = 'RO_CONS_ANAG_CONAF'
export const RO_CONS_ANAG_FODAF = 'RO_CONS_ANAG_FODAF'
export const RO_ISCR_ORDI = 'RO_ISCR_ORDI'
export const RO_CONS_ANAG_ODAF = 'RO_CONS_ANAG_ODAF'
export const RO_CONS_FORM_FODAF = 'RO_CONS_FORM_FODAF'
export const RO_GEST_UTEN_STP = 'RO_GEST_UTEN_STP'
export const RO_GEST_UTEN_CONAF = 'RO_GEST_UTEN_CONAF'
export const RO_GEST_UTEN_ODAF = 'RO_GEST_UTEN_ODAF'
export const RO_GEST_UTEN_FODAF = 'RO_GEST_UTEN_FODAF'
export const RO_GEST_FORM_CONAF = 'RO_GEST_FORM_CONAF'
export const RO_GEST_FORM_FODAF = 'RO_GEST_FORM_FODAF'
export const RO_GEST_FORM_ODAF = 'RO_GEST_FORM_ODAF'
export const RO_CONS_FORM_CONAF = 'RO_CONS_FORM_CONAF'
export const RO_CONS_FORM_ODAF = 'RO_CONS_FORM_ODAF'
export const ROLE_AUTHENTICATED = 'ROLE_AUTHENTICATED'
export const RO_GEST_PROC_DISC = 'RO_GEST_PROC_DISC'
export const RO_GEST_ATT_DISC = 'RO_GEST_ATT_DISC'
export const RO_CONS_ATT_DISC = 'RO_CONS_ATT_DISC'
export const RO_GEST_POLI = 'RO_GEST_POLI'
export const RO_CONS_POLI = 'RO_CONS_POLI'
export const RO_GEST_QUOT_ASSO = 'RO_GEST_QUOT_ASSO'
export const RO_CONS_ASSI = 'RO_CONS_ASSI'
export const RO_VISUAL_POLI = 'RO_VISUAL_POLI'
export const RO_OPER_RAO = 'RO_OPER_RAO'
export const RO_RESP_STP = 'RO_RESP_STP'
export const RO_RESP_FO = 'RO_RESP_FO'
export const RO_RESP_RFO = 'RO_RESP_RFO'
export const RO_OPER_FRMF = 'RO_OPER_FRMF'
export const RO_GEST_UTEN_FO = 'RO_GEST_UTEN_FO'
export const RO_CONSIG_FORM = 'RO_CONSIG_FORM'
export const RO_FO_AGE_RESPONSABILE = 'RO_FO_AGE_RESPONSABILE'
export const RO_FO_ENTE_EXT_RESPONSABILE = 'RO_FO_ENTE_EXT_RESPONSABILE'
export const RO_FO_AGE_GEST_OPERATORE = 'RO_FO_AGE_GEST_OPERATORE'
export const RO_FO_AGE_CONS_OPERATORE = 'RO_FO_AGE_CONS_OPERATORE'
export const RO_FO_ENTE_EXT_GEST_OPERATORE = 'RO_FO_ENTE_EXT_GEST_OPERATORE'
export const RO_FO_ENTE_EXT_CONS_OPERATORE = 'RO_FO_ENTE_EXT_CONS_OPERATORE'

/**
 * Questa lista contiene tutti i ruoli specificati prima.
 * Se si aggiunge un nuovo ruolo deve essere censito in questa lista.
 */
export var ROLE_LIST = []
ROLE_LIST.push(RO_AUTHENTICATED)
ROLE_LIST.push(RO_CONS_PROV_DISC)
ROLE_LIST.push(RO_CONS_ANAG_CONAF)
ROLE_LIST.push(RO_CONS_ANAG_FODAF)
ROLE_LIST.push(RO_ISCR_ORDI)
ROLE_LIST.push(RO_CONS_ANAG_ODAF)
ROLE_LIST.push(RO_CONS_FORM_FODAF)
ROLE_LIST.push(RO_GEST_UTEN_STP)
ROLE_LIST.push(RO_GEST_UTEN_CONAF)
ROLE_LIST.push(RO_GEST_UTEN_ODAF)
ROLE_LIST.push(RO_GEST_UTEN_FODAF)
ROLE_LIST.push(RO_GEST_FORM_CONAF)
ROLE_LIST.push(RO_GEST_FORM_FODAF)
ROLE_LIST.push(RO_GEST_FORM_ODAF)
ROLE_LIST.push(RO_CONS_FORM_CONAF)
ROLE_LIST.push(RO_CONS_FORM_ODAF)
ROLE_LIST.push(ROLE_AUTHENTICATED)
ROLE_LIST.push(RO_GEST_PROC_DISC)
ROLE_LIST.push(RO_GEST_ATT_DISC)
ROLE_LIST.push(RO_CONS_ATT_DISC)
ROLE_LIST.push(RO_GEST_POLI)
ROLE_LIST.push(RO_CONS_POLI)
ROLE_LIST.push(RO_GEST_QUOT_ASSO)
ROLE_LIST.push(RO_CONS_ASSI)
ROLE_LIST.push(RO_VISUAL_POLI)
ROLE_LIST.push(RO_OPER_RAO)
ROLE_LIST.push(RO_RESP_STP)
ROLE_LIST.push(RO_RESP_FO)
ROLE_LIST.push(RO_RESP_RFO)
ROLE_LIST.push(RO_CONSIG_FORM)
ROLE_LIST.push(RO_FO_AGE_RESPONSABILE)
ROLE_LIST.push(RO_FO_ENTE_EXT_RESPONSABILE)
ROLE_LIST.push(RO_FO_AGE_GEST_OPERATORE)
ROLE_LIST.push(RO_FO_AGE_CONS_OPERATORE)
ROLE_LIST.push(RO_FO_ENTE_EXT_GEST_OPERATORE)
ROLE_LIST.push(RO_FO_ENTE_EXT_CONS_OPERATORE)
ROLE_LIST.push(RO_OPER_FRMF)
ROLE_LIST.push(RO_GEST_UTEN_FO)

export const FO_ROLE_LIST = [
  RO_RESP_FO,
  RO_FO_AGE_RESPONSABILE,
  RO_FO_ENTE_EXT_RESPONSABILE,
  RO_FO_AGE_GEST_OPERATORE,
  RO_FO_AGE_CONS_OPERATORE,
  RO_FO_ENTE_EXT_GEST_OPERATORE,
  RO_FO_ENTE_EXT_CONS_OPERATORE,
  RO_OPER_FRMF,
]

export const FO_EXTRA_LIST = [RO_CONS_FORM_FODAF, RO_GEST_FORM_FODAF, RO_CONS_FORM_CONAF, RO_GEST_FORM_CONAF, RO_CONS_FORM_ODAF, RO_GEST_FORM_ODAF]

export const CONAF_ROLE_LIST = [RO_GEST_UTEN_CONAF, RO_GEST_FORM_CONAF, RO_CONS_FORM_CONAF, RO_CONS_ANAG_CONAF]

export const FO_COMPLETE_LIST = [...FO_ROLE_LIST, ...FO_EXTRA_LIST]
export const FO_USER_LIST = [...FO_COMPLETE_LIST, RO_ISCR_ORDI]
export const FO_ORDTERR_LIST = [RO_CONS_ANAG_ODAF, RO_CONS_FORM_ODAF, RO_GEST_FORM_ODAF, RO_GEST_UTEN_ODAF]
export const STATS_ROLE_LIST = [RO_CONS_ANAG_ODAF, RO_CONS_ANAG_FODAF, RO_CONS_ANAG_CONAF, RO_GEST_UTEN_ODAF, RO_GEST_UTEN_FODAF, RO_GEST_UTEN_CONAF]

/**
 * Questa lista contiene tutti i path delle pagine del portale.
 * Se si aggiunge un nuovo path deve essere censito in questa lista.
 */
export const PATH_ROLES = new Map([
  [PATH_TO_FO, ROLE_LIST],
  [PATH_TO_BO, ROLE_LIST],
  [PATH_TO_ERROR, ROLE_LIST],
  [PATH_TO_RICERCA_ALBO_UNICO, ROLE_LIST],
  [PATH_TO_RISULTATI_RICERCA_ALBO_UNICO, ROLE_LIST],
  [PATH_TO_FO_ANAG_SELEZIONA_PROFILO, ROLE_LIST],
  [
    PATH_TO_FO_TODO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_FO_ENTE_EXT_GEST_OPERATORE,
      RO_FO_ENTE_EXT_CONS_OPERATORE,
      ...FO_ROLE_LIST,
      RO_CONS_FORM_ODAF,
      RO_GEST_FORM_ODAF,
      RO_RESP_RFO,
      RO_CONS_ASSI,
      RO_VISUAL_POLI,
      RO_CONS_POLI,
      RO_GEST_POLI,
      RO_CONS_FORM_CONAF,
      RO_GEST_FORM_CONAF,
      RO_CONS_FORM_FODAF,
      RO_GEST_FORM_FODAF
    ],
  ],
  [
    PATH_TO_FO_STUDI_ASSOCIATI_ELENCO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP
    ],
  ],
  [
    PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP
    ],
  ],
  [PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA, [RO_ISCR_ORDI, RO_AUTHENTICATED]],
  [PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA, [RO_ISCR_ORDI, RO_AUTHENTICATED]],
  [PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE, [RO_ISCR_ORDI, RO_AUTHENTICATED]],
  [PATH_TO_FO_ANAG_CAMBIO_SEZIONE, [RO_ISCR_ORDI, RO_AUTHENTICATED]],
  [PATH_TO_FO_ATTI_DISCIPLINARI, [RO_ISCR_ORDI, RO_AUTHENTICATED]],
  [
    PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_RESP_RFO,
    ],
  ],
  [PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA, [RO_ISCR_ORDI, RO_AUTHENTICATED, RO_RESP_STP, RO_GEST_UTEN_STP]],
  [PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA, [RO_ISCR_ORDI, RO_AUTHENTICATED, RO_RESP_STP, RO_GEST_UTEN_STP]],
  [PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO, ROLE_LIST],
  [PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA, [RO_RESP_STP, RO_GEST_UTEN_STP, RO_AUTHENTICATED]],
  [PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA, [RO_RESP_STP, RO_GEST_UTEN_STP, RO_AUTHENTICATED]],
  [PATH_TO_BO_LISTA_ISCRITTI_ORDINE, [RO_GEST_FORM_ODAF]],
  [PATH_TO_BO_CONCEDI_DEROGA, [RO_GEST_FORM_ODAF]],
  [PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP, [RO_GEST_FORM_ODAF]],
  [PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP, [RO_GEST_FORM_ODAF]],
  [PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO, [RO_GEST_FORM_CONAF]],
  [
    PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_FO_ANAG_REISCRIZIONE_ORDINE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_FO_ANAG_ISCRIZIONE_OOT,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],

  [
    PATH_TO_FO_ANAG_ISCRIZIONE_OF,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],

  [
    PATH_TO_FO_ANAG_ISCRIZIONE_OSTP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_FO_ANAG_ISCRIZIONE_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_FO_ANAG_REISCRIZIONE_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_FO_ANAG_ISCRIZIONE_OCONAF,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
    //Ruoli necessari:
    //Operatore Ordine territoriale – Gestione anagrafiche
    //Operatore Ordine territoriale – Consultazione anagrafiche
    //Operatore CONAF Nazionale– Consultazione anagrafiche
    //Operatore Federazione – Consultazione Anagrafiche
    [
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO_STP,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE_STP,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO_STP,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO,
    [
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_ISCRITTI_MODIFICA_ISCRIZIONE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_VISUALIZZA_DATI_ISCRITTO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_VISUALIZZA_DATI_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_CONFERMA_ISCRITTO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_CONFERMA_ISCRITTO_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_RIGETTA_ISCRIZIONE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_RIGETTA_ISCRIZIONE_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_CANCELLA_ISCRITTO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_CANCELLA_ISCRITTO_STP,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_REGISTRAZIONE_PAGAMENTO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_MODIFICA_ISCRIZIONE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_CAMBIO_SEZIONE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_DASHBOARD,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [PATH_TO_FO_ASSICURAZIONI, [RO_ISCR_ORDI, RO_AUTHENTICATED, RO_RESP_STP, RO_GEST_UTEN_STP]],
  [PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA, [RO_ISCR_ORDI, RO_AUTHENTICATED, RO_RESP_STP, RO_GEST_UTEN_STP]],
  [PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA, [RO_ISCR_ORDI, RO_AUTHENTICATED, RO_RESP_STP, RO_GEST_UTEN_STP]],
  [PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA, [RO_ISCR_ORDI, RO_AUTHENTICATED, RO_RESP_STP, RO_GEST_UTEN_STP]],
  [PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA, [RO_ISCR_ORDI, RO_AUTHENTICATED, RO_RESP_STP, RO_GEST_UTEN_STP]],
  [PATH_TO_FO_PRIVACY, ROLE_LIST],
  [PATH_TO_FO_COOKIE, ROLE_LIST],
  [PATH_TO_FO_FEEDBACK_ACCESSIBILITA, ROLE_LIST],
  [
    PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA,
    [
      RO_CONS_ANAG_ODAF,
      RO_GEST_UTEN_ODAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RIFIUTA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA,
    [
      RO_GEST_UTEN_ODAF,
      RO_CONS_ANAG_ODAF
    ],
  ],
  [
    PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_TRASF_ORDINE_RIFIUTA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_TRASF_ORDINE_CONCEDI_NULLA_OSTA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_CONTATTI,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_ORDINI,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ATTI_DISCIPLINARI,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_FORMAZIONE_PROFESSIONALE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
    ],
  ],
  [
    PATH_TO_BO_POLIZZE,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_CONS_ASSI,
      RO_VISUAL_POLI,
      RO_CONS_POLI,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_CONS_ASSI,
      RO_VISUAL_POLI,
      RO_CONS_POLI,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_CONFERMA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_RIGETTA_RIAPERTURA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_RIGETTA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_VISUALIZZA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_CONS_ASSI,
      RO_VISUAL_POLI,
      RO_CONS_POLI,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_RIAPRI,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_MODIFICA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_GEST_POLI
    ],
  ],
  [
    PATH_TO_BO_POLIZZE_ELENCO_CANCELLA,
    [
      RO_ISCR_ORDI,
      RO_AUTHENTICATED,
      RO_RESP_STP,
      RO_GEST_UTEN_STP,
      RO_CONS_ANAG_ODAF,
      RO_CONS_ANAG_FODAF,
      RO_CONS_ANAG_CONAF,
      RO_GEST_UTEN_ODAF,
      RO_GEST_UTEN_FODAF,
      RO_GEST_UTEN_CONAF,
      RO_GEST_POLI
    ],
  ],

  // [PATH_TO_FO_FORMAZIONE, [RO_ISCR_ORDI, RO_AUTHENTICATED]],
  [PATH_TO_FO_FORMAZIONE_ISCRIZIONE_AF, ROLE_LIST],
  [PATH_TO_FO_ISCRIZIONE_POF, FO_COMPLETE_LIST],
  [PATH_TO_FO_ELENCO_POF, FO_COMPLETE_LIST],
  [PATH_TO_FO_VISUALIZZA_POF, FO_COMPLETE_LIST],
  [PATH_TO_BO_ELENCO_POF, FO_COMPLETE_LIST],

  [PATH_TO_BO_DETTAGLIO_POF, FO_COMPLETE_LIST],

  [PATH_TO_BO_ELENCO_AGENZIE, [RO_GEST_FORM_FODAF, RO_GEST_FORM_ODAF, RO_GEST_FORM_CONAF, RO_CONS_FORM_CONAF]],
  [PATH_TO_BO_DETTAGLIO_AGENZIA, [RO_GEST_FORM_FODAF, RO_GEST_FORM_ODAF, RO_GEST_FORM_CONAF, RO_CONS_FORM_CONAF]],
  [PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO, [RO_GEST_FORM_FODAF, RO_GEST_FORM_ODAF, RO_GEST_FORM_CONAF, RO_CONS_FORM_CONAF]],
  [PATH_TO_BO_DETTAGLIO_RIVISTA, [RO_GEST_FORM_FODAF, RO_GEST_FORM_ODAF, RO_GEST_FORM_CONAF, RO_CONS_FORM_CONAF]],

  [PATH_TO_BO_ELENCO_ENTI_ESTERNI, [RO_GEST_FORM_CONAF, RO_CONS_FORM_CONAF]],
  [PATH_TO_BO_ELENCO_RIVISTE, [RO_GEST_FORM_CONAF, RO_GEST_FORM_FODAF, RO_GEST_FORM_ODAF]],

  [PATH_TO_FO_ELENCO_AGENZIE, FO_ROLE_LIST],
  [PATH_TO_FO_DETTAGLIO_AGENZIA, FO_ROLE_LIST],

  [PATH_TO_FO_ELENCO_ENTI_ESTERNI, FO_ROLE_LIST],
  [PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO, FO_ROLE_LIST],

  [PATH_TO_FO_ELENCO_RIVISTE, [RO_RESP_RFO, ...FO_ROLE_LIST]],
  [PATH_TO_FO_DETTAGLIO_RIVISTA, [RO_RESP_RFO, ...FO_ROLE_LIST]],

  [PATH_TO_BO_ELENCO_EVENTI_CATALOGO, FO_COMPLETE_LIST],
  [PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO, FO_COMPLETE_LIST],
  [PATH_TO_BO_CFP_EVENTO_CATALOGO, FO_COMPLETE_LIST],
  [PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO, FO_COMPLETE_LIST],
  [PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_CATALOGO, FO_COMPLETE_LIST],

  [PATH_TO_FO_ELENCO_EVENTI_CATALOGO, []],
  [PATH_TO_FO_ISCRITTI_EVENTO_CATALOGO, FO_USER_LIST],
  [PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO, []],

  [PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI, FO_USER_LIST],
  [PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI, FO_COMPLETE_LIST],
  [PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE, FO_COMPLETE_LIST],
  [PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE, FO_USER_LIST],
  [PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_INDIVIDUALE, FO_USER_LIST],

  [PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO, [...FO_ORDTERR_LIST, ...CONAF_ROLE_LIST]],
  [PATH_TO_BO_ELENCO_RICHIESTE_ESONERO, [...FO_ORDTERR_LIST, ...CONAF_ROLE_LIST]],
  [PATH_TO_FO_ELENCO_RICHIESTE_ESONERO, FO_USER_LIST],
  [PATH_TO_FO_RICHIESTA_ESONERO, FO_USER_LIST],
  [PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO, FO_USER_LIST],
  [PATH_TO_FO_ISCRIZIONE_FORMATORE, []],
  [PATH_TO_FO_ELENCO_FORMATORI, []],
  [PATH_TO_BO_ELENCO_FORMATORI, []],
  [PATH_TO_BO_DETTAGLIO_FORMATORE, []],
  [PATH_TO_FO_DETTAGLIO_FORMATORE, []],

  [PATH_TO_BO_CREDITI_FORMATIVI, [...FO_ORDTERR_LIST, ...CONAF_ROLE_LIST]],
  [PATH_TO_FO_CREDITI_FORMATIVI, FO_USER_LIST],

  [PATH_TO_BO_ENTE_ESTERNO, [...CONAF_ROLE_LIST]],
  [PATH_TO_BO_CREAZIONE_RIVISTA, []],

  [
    PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI,
    [RO_ISCR_ORDI, RO_CONS_ANAG_CONAF, RO_GEST_UTEN_CONAF, RO_CONS_ANAG_ODAF, RO_GEST_UTEN_ODAF, RO_CONS_ANAG_FODAF, RO_GEST_UTEN_FODAF],
  ],
  [
    PATH_TO_BO_ANAG_CONTATTI_ORDINI,
    [RO_ISCR_ORDI, RO_CONS_ANAG_CONAF, RO_GEST_UTEN_CONAF, RO_CONS_ANAG_ODAF, RO_GEST_UTEN_ODAF, RO_CONS_ANAG_FODAF, RO_GEST_UTEN_FODAF],
  ],
  [
    PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE,
    [RO_GEST_QUOT_ASSO],
  ],

  //STATS
  [PATH_TO_BO_STATS, STATS_ROLE_LIST], //DASH_STATS
  [PATH_TO_BO_STATS_ANAG, STATS_ROLE_LIST], //ANAG_STATS
  [PATH_TO_BO_STATS_POLI, STATS_ROLE_LIST], //POLI_STATS
  [PATH_TO_BO_STATS_FOR, STATS_ROLE_LIST],
  [PATH_TO_BO_STATS_ANAG, STATS_ROLE_LIST], //FOR_STATS
  [PATH_TO_BO_STATS_DISC, STATS_ROLE_LIST],
  [PATH_TO_BO_STATS_ANAG, STATS_ROLE_LIST], //DISC_STATS
  [PATH_TO_BO_STATS_INI_PEC, STATS_ROLE_LIST],
  [PATH_TO_BO_STATS_ANAG, STATS_ROLE_LIST], //INI_PEC_STATS
  [PATH_TO_BO_STATS_REG_INDE, STATS_ROLE_LIST],
  [PATH_TO_BO_STATS_ANAG, STATS_ROLE_LIST], //REG_INDE_STATS
])

/**
 * Restituisce i ruoli relativi al path specificato in input.
 */
export function getRoleListBy(path) {
  if (path === undefined || path === null || path === '') throw new Error('Percorso assente, impossibile cercare i ruoli.')
  var roleList = PATH_ROLES.get(path)
  if (roleList === undefined || roleList === null || roleList.length === 0) throw new Error('Non sono stati trovati ruoli per il percorso ', path)
  return roleList
}
