export default {
    titolo: {
        'isRequired': true
    },
    sdaf: {
        'isRequired': true
    },
    tipo: {
        'isRequired': true
    },
    conseg_da: {
        'isRequired': true
    },
    conseg_a: {
        'isRequired': true
    },
}
