import React, { useEffect, useState } from 'react'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { GenericSpinner } from 'src/backoffice/app/components'
import { FormTopbar, Separator } from 'src/frontend/app/components/Theme'


import { convertPofDataToForm, getNomeEnteFormazione } from 'src/model/PianiFormativi'
import FormPOF from './FormPOF'
import { useHistory } from 'react-router-dom'
import { PATH_TO_FO_ELENCO_POF } from 'src/app/utils/RoutesConstants'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { generateRequestFilter } from 'src/app/utils/ApiUtils'
import { Button } from 'design-react-kit'
import GenericCard from 'src/frontend/app/components/GenericCard'
import StatusPof from './FormPOF/PofStatus'


const VisualizzaPOF: React.FunctionComponent = () => {

  const history = useHistory()

  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    // Carica dati piani
    const loadData = async (id) => {
      try {
        setLoading(true)
        const { data: { items } } = await getFormazioneResourceApi().msfoFormazioneListaPformativiForUserGet(
          generateRequestFilter([{ field: 'id', value: id }]),
          100,
          -1,
          undefined,
          getDefaultAxiosConfig()
        )
        if (items === undefined || items?.length === 0) {
          history.push(PATH_TO_FO_ELENCO_POF)
          return
        }
        setData(await convertPofDataToForm(items[0]))
        setLoading(false)
      } catch (e) {
        history.push(PATH_TO_FO_ELENCO_POF)
      }
    }
    const searchParams = new URLSearchParams(window.location.search)
    const id = searchParams.get('id')
    loadData(id)
  }, [])

  return (
    <div className='container register-container'>
      <GenericBreadCrumb
        paths={[
          { label: 'Formazione', link: '/' },
          { label: 'Piano formativi', link: PATH_TO_FO_ELENCO_POF },
          { label: 'Visualizza piano formativo' }
        ]}>

      </GenericBreadCrumb>
      <FormTopbar>
        <h1>Piano Formativo</h1>
        <div className={'actions'}>
          <Button onClick={() => history.push(PATH_TO_FO_ELENCO_POF)}>Torna alla lista</Button>
        </div>
      </FormTopbar>

      {loading && <GenericSpinner />}
      {data != null && (
        <>
          <GenericCard
            cardHeader={`${data && data.nr_invio > 1 ? `Variante piano ` : `Piano`} formativo anno ${data.anno} - ${getNomeEnteFormazione(data)}`}
          >
            <StatusPof pof={data} />
          </GenericCard>

          <Separator />
          <FormPOF
            saving={false}
            data={data}
            setData={setData}
            isDisabled={true}
          />
        </>
      )}


    </div>

  )
}

export default VisualizzaPOF
