import {
  capValidation,
  faxValidation,
  fiscalCodePhysicalPersonValidation,
  fiscalCodeValidation,
  getValueAtPath,
  isEmail,
  isEmptyValue,
  partitaIvaValidation,
  telefonoValidation
} from 'src/utilities/utility'
import moment from 'moment'

export const RULES = {
  _custom: true,
  isRequired: (v) => {
    return !isEmptyValue(v)
  },
  inclusion: (v, rule, allData) => {
    const { field, values } = rule || {}
    const _value = getValueAtPath(allData, field)
    const value = _value?.value || _value
    return values?.includes(value) ? !isEmptyValue(v) : true
  },
  exclusion: (v, rule, allData) => {
    const { field, values } = rule || {}
    const _value = getValueAtPath(allData, field)
    const value = _value?.value || _value
    return !values?.includes(value) ? !isEmptyValue(v) : true
  },
  isNumber: (v) => {
    return !isNaN(v)
  },
  min: (v, rule) => {
    const cv = typeof rule === 'object' ? rule.check : rule
    return v >= cv
  },
  isValidDate: (v) => {
    return !isNaN(Date.parse(v))
  },
  isNotEmpty: (v) => {
    return v.length === 0 ? false : true
  },
  isEmail,
  isFiscalCodePhysicalPerson: fiscalCodePhysicalPersonValidation,
  isFiscalCode: fiscalCodeValidation,
  isPartitaIva: partitaIvaValidation,
  isTelefono: telefonoValidation,
  isFax: faxValidation,
  isCap: capValidation,
  isDate: (v, rule) => {
    const { format } = rule || {}
    return moment(v, format, true).isValid()
  },
}

const VALIDATION_DEFAULT_ERRORS = {
  default: 'Il valore inserito non è valido',
  isRequired: 'Il campo è richiesto',
  inclusion: 'Il campo è richiesto',
  exclusion: 'Il campo è richiesto',
  isOnlyNumbers: 'Il valore inserito non è un numero',
  min: 'Il valore inserito è troppo piccolo',
  isValidDate: 'La data inserita non è valida',
  isNotEmpty: 'Il campo è richiesto'
}

const getErrorMessage = (ruleCode, ruleData) => {
  if (typeof ruleData === 'string') {
    return ruleData
  }
  if (typeof ruleData === 'object' && ruleData.error) {
    return ruleData.error
  }
  if (VALIDATION_DEFAULT_ERRORS[ruleCode]) {
    return VALIDATION_DEFAULT_ERRORS[ruleCode]
  }
  return VALIDATION_DEFAULT_ERRORS.default
}

/**e
 * Ritorna il messaggio di errore nel caso in cui il valore non è valido
 * false altrimenti
 * @param value
 * @param rules
 */
export const validateValue = (rules, value, allData) => {
  if (rules) {
    for (const code in rules) {
      if (!RULES[code]) {
        continue
      }
      if (code === '_custom' && rules[code].check && typeof rules[code].check === 'function') {
        if (!rules[code].check(value, rules[code], allData)) {
          return getErrorMessage(code, rules[code])
        }
        continue
      }
      if (!RULES[code](value, rules[code], allData)) {
        return getErrorMessage(code, rules[code])
      }
    }
  }
  return false
}
