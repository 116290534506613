import * as React from 'react'
import { PageSection } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import GenericTitle from '../../components/GenericTitle'
import GenericCard from '../../components/GenericCard'
import { goTo } from 'src/utilities/utility'
import { GenericTable } from 'src/backoffice/app/components'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import { PATH_TO_FO_ATTI_DISCIPLINARI, PATH_TO_FO_TODO } from 'src/app/utils/RoutesConstants'
import { getDisciplinariByCf } from 'src/processes/disciplinari/Disciplinari'
import { columnsDisciplinari } from 'src/processes/disciplinari/CostantiDisciplinari'

// Constanti e utilities per la pagina
const title = 'Disciplinari'
const cardHeader = "Atti disciplinari"
const cardHeaderNoDisciplinari = "Nessun atto disciplinare"
var breadcrumbOptions = [
    { label: "Disciplinari", link: PATH_TO_FO_ATTI_DISCIPLINARI },
    { label: "Atti disciplinari", link: PATH_TO_FO_ATTI_DISCIPLINARI }
]

class AttiDisciplinari extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            message: "",
            typeMessage: "default",
            isLoaded: false,
            listaDisciplinari: [],
        };
    }

    async componentDidMount() {
        this.setState({ isLoaded: false })
        let fiscalNumber = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber.toUpperCase();

        // Recupera gli atti disciplinari in funzione del codice fiscale
        await getDisciplinariByCf(fiscalNumber).then(async (response: any) => {
            this.setState({ isLoaded: true })
            let listaDisciplinari: any = [];
            if (response.length > 0) {
                response.map(async disciplinareAttiva => {
                    let obj = {
                        id: disciplinareAttiva.id,
                        data: [
                            (disciplinareAttiva.provvedimento) ? disciplinareAttiva.provvedimento : '-',
                            (disciplinareAttiva.dataProvvedimento) ? disciplinareAttiva.dataProvvedimento.split("T").join(" ").split(".")[0] : '-',
                            (disciplinareAttiva.deliberaNr) ? disciplinareAttiva.deliberaNr : '-',
                            (disciplinareAttiva.provvedimentoDal) ? disciplinareAttiva.provvedimentoDal.split("T").join(" ").split(".")[0] : '-',
                            (disciplinareAttiva.provvedimentoAl && disciplinareAttiva.provvedimento !== 'RADIAZIONE') ? disciplinareAttiva.provvedimentoAl.split("T").join(" ").split(".")[0] : '-',
                        ]
                    }
                    await listaDisciplinari.push(obj)
                })
                this.setState({ listaDisciplinari: listaDisciplinari })
            }
        }).catch((err) => {
            this.showModal(true, "Errore", "Errore durante il caricamento degli atti disciplinari per " + fiscalNumber);
        })
    }

    showModal = async (booleanValue, title, text) => {
        this.setState({
            modalOpen: booleanValue,
            modalTitle: title,
            modalText: text,
            isLoaded: true,
        });
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
        goTo(PATH_TO_FO_TODO)
    }

    render() {
        const { isLoaded, listaDisciplinari } = this.state;
        const onSelectRow = (row: any) => {
            this.setState({ selectedRow: row })
        }

        return (
            <div className="container-fluid">
                {isLoaded ?
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-10 offset-1'>
                                <PageSection id='pageSectionId' className="container-fluid">
                                    <div className='container-fluid'>
                                        <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                                        <GenericTitle Size="h1" title={title}></GenericTitle>

                                        {
                                            (this.state.isLoaded)
                                            ?
                                                (listaDisciplinari.length === 0)
                                                ?
                                                    <div className='justify-content-between d-flex'>
                                                        <h5 className='card-title'>{cardHeaderNoDisciplinari}</h5>
                                                    </div>
                                                :
                                                    <><div className="Card">
                                                        <GenericCard
                                                            cardHeader={
                                                                <div className='justify-content-between d-flex'>
                                                                    <h5 className='card-title'>{cardHeader}</h5>
                                                                </div>
                                                            }
                                                            cardBody={
                                                                <div>
                                                                    <GenericTable
                                                                        hideCsv hideSearch hideDropdown hideFilters hideCertificateColumn
                                                                        autoWidthColumns isEditableTable={false}
                                                                        columns={columnsDisciplinari?.map((column) => column.name)}
                                                                        onSelect={onSelectRow}
                                                                        rows={listaDisciplinari}
                                                                        tableHeadFontSize="0.8em"
                                                                        tableContentFontSize="0.8em"
                                                                        hidePagination={true}
                                                                        hideActionColumn={true}
                                                                        actions={[{ label: "Aggiorna", function: '' }]}
                                                                    />
                                                                </div>
                                                            }
                                                        />
                                                    </div></>
                                            : <div><GenericSpinner></GenericSpinner></div>
                                        }
                                    </div>
                                    <GenericModal
                                        title={this.state.modalTitle}
                                        text={this.state.modalText}
                                        modalOpen={this.state.modalOpen}
                                        closeButton={this.closeModal}
                                    />
                                </PageSection>
                            </div>
                        </div>
                        <br></br>
                    </div>
                    :
                    <GenericSpinner></GenericSpinner>
                }

            </div>
        );
    }
};


export default AttiDisciplinari;
