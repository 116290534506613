import * as React from 'react'
import { Form } from '@patternfly/react-core'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormElencoSociIscrittiAlboStpBO extends React.Component<GeneralFormElencoSociIscrittiAlboStpBOProps, any> {
  render() {
    return (
      <div>
        <Form>
          {/*Cicla la lista composizioneSocietaria e visualizza i soci iscritti (isSocio === 1 e idSogettoPF !== null) */}
          {this.props.composizioneSocietaria?.length > 0 ? (
            this.props.composizioneSocietaria.map((socio) => (
              <div className="row" hidden={!(socio.isSocio === 1 && socio.idSoggettoPF !== null)}>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCodiceFiscaleSocio'}
                    label={'Codice fiscale del socio'}
                    value={socio.cf}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idNomeCognomeSocio'}
                    label={'Nome e cognome del socio'}
                    value={socio.nome + ' ' + socio.cognome}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-4'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idTitolo'}
                    label={'Titolo del socio'}
                    value={socio.titProf ? socio.titProf : '-'}
                    isReadOnly
                  />
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">Nessun socio presente</p>
          )}
        </Form>
      </div>
    )
  }
}

export declare interface GeneralFormElencoSociIscrittiAlboStpBOProps {
  messaggio?: any
  composizioneSocietaria?: any
}
