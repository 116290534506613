import {
  Col,
  Collapse,
  Header,
  HeaderContent,
  HeaderToggler,
  Icon,
  Label,
  LinkList,
  LinkListItem,
  Megamenu,
  MegamenuItem,
  Nav,
  Row,
  Sidebar
} from 'design-react-kit'
import React from 'react'
import {
  FO_COMPLETE_LIST,
  FO_EXTRA_LIST,
  FO_ROLE_LIST,
  PATH_DOC_MANUALISTICA_ISCRITTI,
  PATH_DOC_MANUALISTICA_OPERATORI_CONAF,
  PATH_DOC_MANUALISTICA_OPERATORI_ODAF_FODAF,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA,
  PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE,
  PATH_TO_BO_ANAG_CONTATTI_ORDINI,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO,
  PATH_TO_BO_ANAG_ELENCO_OPERATORI,
  PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA,
  PATH_TO_BO_CREDITI_FORMATIVI,
  PATH_TO_BO_ELENCO_AGENZIE,
  PATH_TO_BO_ELENCO_ENTI_ESTERNI,
  PATH_TO_BO_ELENCO_EVENTI_CATALOGO,
  PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI,
  PATH_TO_BO_ELENCO_FORMATORI,
  PATH_TO_BO_ELENCO_POF,
  PATH_TO_BO_ELENCO_RICHIESTE_ESONERO,
  PATH_TO_BO_ELENCO_RIVISTE,
  PATH_TO_BO_ENTE_ESTERNO,
  PATH_TO_BO_LISTA_ISCRITTI_ORDINE,
  PATH_TO_BO_POLIZZE_ELENCO,
  PATH_TO_BO_STATS,
  PATH_TO_BO_STATS_FOR,
  PATH_TO_BO_STATS_INI_PEC,
  PATH_TO_BO_STATS_POLI,
  PATH_TO_BO_STATS_REG_INDE,
  PATH_TO_FO_ANAG_CAMBIO_SEZIONE,
  PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA,
  PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA,
  PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA,
  PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA,
  PATH_TO_FO_ANAG_REISCRIZIONE_ORDINE,
  PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO,
  PATH_TO_FO_ANAG_SELEZIONA_PROFILO,
  PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE,
  PATH_TO_FO_ASSICURAZIONI,
  PATH_TO_FO_ATTI_DISCIPLINARI,
  PATH_TO_FO_COOKIE,
  PATH_TO_FO_CREDITI_FORMATIVI,
  PATH_TO_FO_ELENCO_AGENZIE,
  PATH_TO_FO_ELENCO_ENTI_ESTERNI,
  PATH_TO_FO_ELENCO_EVENTI_CATALOGO,
  PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI,
  PATH_TO_FO_ELENCO_FORMATORI,
  PATH_TO_FO_ELENCO_POF,
  PATH_TO_FO_ELENCO_RICHIESTE_ESONERO,
  PATH_TO_FO_ELENCO_RIVISTE,
  PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_CATALOGO,
  PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_INDIVIDUALE,
  PATH_TO_FO_PRIVACY,
  PATH_TO_FO_RICHIESTA_ESONERO,
  PATH_TO_FO_STUDI_ASSOCIATI_ELENCO,
  PATH_TO_FO_TODO,
  PATH_TO_RICERCA_ALBO_UNICO,
  RO_CONS_ANAG_CONAF,
  RO_CONS_ANAG_FODAF,
  RO_CONS_ANAG_ODAF,
  RO_CONS_ASSI,
  RO_CONS_FORM_CONAF,
  RO_CONS_POLI,
  RO_FO_AGE_CONS_OPERATORE,
  RO_FO_AGE_GEST_OPERATORE,
  RO_FO_AGE_RESPONSABILE,
  RO_FO_ENTE_EXT_CONS_OPERATORE,
  RO_FO_ENTE_EXT_GEST_OPERATORE,
  RO_FO_ENTE_EXT_RESPONSABILE,
  RO_GEST_FORM_CONAF,
  RO_GEST_FORM_FODAF,
  RO_GEST_FORM_ODAF,
  RO_GEST_POLI,
  RO_GEST_QUOT_ASSO,
  RO_GEST_UTEN_CONAF,
  RO_GEST_UTEN_FODAF,
  RO_GEST_UTEN_ODAF,
  RO_ISCR_ORDI,
  RO_OPER_FRMF,
  RO_RESP_FO,
  RO_RESP_RFO,
  RO_VISUAL_POLI,
  STATS_ROLE_LIST
} from 'src/app/utils/RoutesConstants'

import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import {
  getIscrizioneByCodiceFiscaleResponsabileStp,
  isEnabledReiscrizioneOrdine,
  isEnabledReiscrizioneSTP,
  nuovaIscrizioneOrdine,
  nuovaIscrizioneSTP
} from 'src/processes/ProcessoIscrizione'
import { goTo } from 'src/utilities/utility'

import './style.scss'
import { findSTPByPIva, getSoggettoByCf } from 'src/processes/Soggetto'
import { getAgenziaFormativa } from 'src/hooks/useReqOptions'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'
import { GenericSpinner } from 'src/backoffice/app/components'
import moment from 'moment'
import { STATI_AGENZIE_VALUES } from 'src/formazione'

const facebook = 'https://www.facebook.com/agronomiforestali/?locale=it_IT'
const instagram = 'https://instagram.com/ordine_agronomi_e_forestali?igshid=MzRlODBiNWFlZA=='
const twitter = 'https://twitter.com/_conaf'
const mailto = 'ufficioprotocollo@conaf.it'
class GenericHeaderNav extends React.Component<GeneralHeaderNavProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      userLogged: props.userLogged,
      isEnabledReiscrizioneOrdineMenuItem: false,
      isEnabledReiscrizioneSTPMenuItem: false,
      menu: null,
      statoAgenzia: null,
      openNav: false,
      profilo: '',
      isMobile: window.innerWidth <= 1000,
    }
    this.handleResize = this.handleResize.bind(this)
  }

  async componentDidMount(): Promise<void> {
    window.addEventListener('resize', this.handleResize)
    if (TokenStore.getInstance().isLoggedIn()) {
      if (TokenStore.getInstance().getKeycloak() && TokenStore.getInstance().getKeycloak() !== '' && TokenStore.getInstance().getKeycloak().authenticated) {
        this.setState({
          profilo: await TokenStore.getInstance().getDenominazioneOfCurrentAccount(),
          isLoading: false,
        })
      }
      getAgenziaFormativa()
        .then((data) => {
          if (data) {
            if (data?.dataFineProroga && moment(data?.dataFineProroga).isSameOrAfter(moment())) {
              this.setState({ statoAgenzia: STATI_AGENZIE_VALUES.ACCREDITATA })
            } else {
              this.setState({ statoAgenzia: data.stato })
            }
          }
        })
        .catch(() => {})

      let fiscalNumber = TokenStore.getInstance().getDecodedTokenJWT().spid_fiscalNumber
      const spidData = TokenStore.getInstance().getSpidData()
      const isRappresentanteLegale = await TokenStore.getInstance().isOperatoreResponsabilePropriaSTP()
      var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
      var pIva = specificGroup.split('_')[1]

      //chiamata al servizio 'getSoggettoByCf' per recuperare le tipologie di iscrizione effettuate dall'utente loggato
      var iscrizioniAttive: any = []
      await getSoggettoByCf(spidData.codiceFiscale ? spidData.codiceFiscale : fiscalNumber)
        .then(async (response: any) => {
          response.data.map((soggetto) => {
            soggetto?.elencoIscrizioni?.map((iscrizione) => {
              return iscrizioniAttive.push({
                codiceTipologiaIscrizioni: iscrizione.tipologiaIscrizioni.codice,
                statoIscrizione: iscrizione.statoIscrizione,
              })
            })
          })
          this.setState({ listaIscrizioniAttive: [...new Set(iscrizioniAttive)] })
        })
        .catch((err) => {
          throw new Error(err)
        })

      /**
       * se è presente un'STP recupero il cf del rappresentante legale e lo sostituisco al cf corrente
       */
      if (TokenStore.getInstance().isCurrentAccountSTP() && TokenStore.getInstance().isOperatoreResponsabileSTP()) {
        var soggettoSTP = await findSTPByPIva(pIva)
        if (soggettoSTP?.data) {
          fiscalNumber = soggettoSTP.data.codiceFiscaleResponsabileStp
        }
      }
      if (TokenStore.getInstance().isCurrentAccountSTP()) {
        var isSTPAttiva = await TokenStore.getInstance().isCurrentAccountSTPAttivo()
        this.setState({ stpAttiva: isSTPAttiva })
      } else this.setState({ stpAttiva: false })

      var iscrizioniAttiveStp: any = []
      await getIscrizioneByCodiceFiscaleResponsabileStp(fiscalNumber)
        .then(async (response: any) => {
          response?.data?.returnedObject?.elencoIscrizioni.map((iscrizione) => {
            return iscrizioniAttiveStp.push({
              codiceTipologiaIscrizioni: iscrizione.tipologiaIscrizioni.codice,
              statoIscrizione: iscrizione.statoIscrizione,
            })
          })
          //salvataggio nello stato dell'array di iscrizioni attive stp senza duplicati
          this.setState({ listaIscrizioniAttiveStp: [...new Set(iscrizioniAttiveStp)] })
        })
        .catch((err) => {
          throw new Error(err)
        })

      this.setState({ isEnabledReiscrizioneOrdineMenuItem: isEnabledReiscrizioneOrdineFunction() })
      this.setState({ isEnabledReiscrizioneSTPMenuItem: isEnabledReiscrizioneSTPFunction() })
      var isEnableModificaOrdineMenuItem = isEnabledModificaOrdineFunction(iscrizioniAttive)
      var isEnableVisualizzaOrdineMenuItem = isEnabledVisualizzaOrdineFunction(iscrizioniAttive)
      var isEnableModificaSTPMenuItem = isEnabledModificaSTPFunction(iscrizioniAttiveStp)
      var isEnabledVisualizzaSTPMenuItem = isEnabledVisualizzaSTPFunction(iscrizioniAttiveStp, isRappresentanteLegale)
      this.setState({
        menu: this.buildMenu(isEnableModificaOrdineMenuItem, isEnableModificaSTPMenuItem, isEnabledVisualizzaSTPMenuItem, isEnableVisualizzaOrdineMenuItem),
      })
    } else {
      this.setState({ menu: this.buildMenuWithoutAutentication() })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth <= 1000 })
  }

  buildMenu(isEnableModificaOrdineMenuItem, isEnableModificaSTPMenuItem, isEnabledVisualizzaSTPMenuItem, isEnableVisualizzaOrdineMenuItem) {
    const store = TokenStore.getInstance()
    return (
      <>
        <MegamenuItem itemName="Da fare" key="Todo">
          <Row>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem href={PATH_TO_FO_TODO}>Da fare</LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>
        <MegamenuItem itemName="Anagrafica" key="Anagrafica">
          <Row>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem
                  hidden={
                    !(store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_UTEN_ODAF, RO_CONS_ANAG_ODAF])) &&
                    !(store.isCurrentAccountCONAF() && store.currentAccountHasRolesOR([RO_CONS_ANAG_CONAF])) &&
                    !(store.isCurrentAccountFODAF() && store.currentAccountHasRolesOR([RO_CONS_ANAG_FODAF]))
                  }
                  href={PATH_TO_BO_ANAG_ELENCO_ISCRITTI}
                >
                  Elenco iscritti
                </LinkListItem>
                <LinkListItem
                  hidden={
                    !(
                      store.isBackOffice() &&
                      store.currentAccountHasRolesOR([
                        RO_CONS_ANAG_ODAF,
                        RO_CONS_ANAG_FODAF,
                        RO_CONS_ANAG_CONAF,
                        RO_GEST_UTEN_ODAF,
                        RO_GEST_UTEN_FODAF,
                        RO_GEST_UTEN_CONAF,
                      ])
                    )
                  }
                  href={PATH_TO_BO_ANAG_ELENCO_OPERATORI}
                >
                  Elenco operatori
                </LinkListItem>
                <LinkListItem
                  hidden={
                    !store.currentAccountHasRolesOR([
                      RO_ISCR_ORDI,
                      RO_CONS_ANAG_CONAF,
                      RO_GEST_UTEN_CONAF,
                      RO_CONS_ANAG_ODAF,
                      RO_GEST_UTEN_ODAF,
                      RO_CONS_ANAG_FODAF,
                      RO_GEST_UTEN_FODAF,
                    ])
                  }
                  href={PATH_TO_BO_ANAG_CONTATTI_ORDINI}
                >
                  Contatti ordini
                </LinkListItem>
                <LinkListItem
                  hidden={!(store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_QUOT_ASSO]))}
                  href={PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE}
                >
                  Comunicazione quote
                </LinkListItem>
                <LinkListItem
                  hidden={!(store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_UTEN_ODAF]))}
                  href={PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO}
                >
                  Registra pagamento
                </LinkListItem>
                <LinkListItem hidden={!store.isCurrentAccountODAF()} href={PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA}>
                  Lista trasf. ordine
                </LinkListItem>
                <LinkListItem hidden={!store.isCurrentAccountODAF()} href={PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA}>
                  Lista cambio sezione{' '}
                </LinkListItem>
                <LinkListItem
                  hidden={!isEnableVisualizzaOrdineMenuItem}
                  onClick={() => (!store.isIscrittoOrdine() ? store.setCurrentAccountAsAuthenticated() : store.setCurrentAccountAsIscrittoOrdine())}
                  href={PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA}
                >
                  Visualizza dati
                </LinkListItem>
                <LinkListItem
                  hidden={!isEnableModificaOrdineMenuItem || (!store.isCurrentAccountIscrittoOrdine() && !store.isCurrentAccountAuthenticated())}
                  onClick={() => (!store.isIscrittoOrdine() ? store.setCurrentAccountAsAuthenticated() : store.setCurrentAccountAsIscrittoOrdine())}
                  href={PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA}
                >
                  Modifica dati
                </LinkListItem>
                <LinkListItem hidden={!store.isCurrentAccountIscrittoOrdine()} href={PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE}>
                  Nuovo trasf. ordine
                </LinkListItem>
                <LinkListItem hidden={!store.isCurrentAccountIscrittoOrdine()} href={PATH_TO_FO_ANAG_CAMBIO_SEZIONE}>
                  Nuovo cambio sezione
                </LinkListItem>
                <LinkListItem
                  hidden={!this.state.isEnabledReiscrizioneOrdineMenuItem}
                  onClick={() => gestisciIscrizioneOrdineMenuItem(PATH_TO_FO_ANAG_REISCRIZIONE_ORDINE)}
                >
                  Reiscrizione all’ordine
                </LinkListItem>
                <LinkListItem
                  hidden={!isEnabledVisualizzaSTPMenuItem}
                  onClick={() => (!store.isOperatoreResponsabileSTP() ? store.setCurrentAccountAsAuthenticated() : store.setCurrentAccountAsSTP())}
                  href={PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA}
                >
                  Visualizza dati STP
                </LinkListItem>
                <LinkListItem
                  hidden={!isEnableModificaSTPMenuItem}
                  onClick={() => (!store.isOperatoreResponsabileSTP() ? store.setCurrentAccountAsAuthenticated() : store.setCurrentAccountAsSTP())}
                  href={PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA}
                >
                  Modifica dati STP
                </LinkListItem>
                <LinkListItem hidden={!store.isCurrentAccountIscrittoOrdine()} href={PATH_TO_FO_STUDI_ASSOCIATI_ELENCO}>
                  Elenco studi associati
                </LinkListItem>
                <LinkListItem hidden={!store.isCurrentAccountIscrittoOrdine()} href={PATH_TO_FO_ATTI_DISCIPLINARI}>
                  Atti disciplinari
                </LinkListItem>
                <LinkListItem href={PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO}>Richiedi nuovo profilo</LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>
        <MegamenuItem
          itemName="Assicurazione"
          key="Assicurazione"
          hidden={
            store.isCurrentAccountFODAF() ||
            (!store.isCurrentAccountIscrittoOrdine() && !store.isCurrentAccountCONAF() && !store.isCurrentAccountODAF() && !this.state.stpAttiva) ||
            store.isCurrentAccountAuthenticated()
          }
        >
          <Row aria-setsize={10}>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem
                  hidden={!store.currentAccountHasRolesOR([RO_GEST_POLI, RO_CONS_POLI, RO_CONS_ASSI, RO_VISUAL_POLI])}
                  href={PATH_TO_BO_POLIZZE_ELENCO}
                >
                  Elenco assicurazioni
                </LinkListItem>
                <LinkListItem hidden={!store.isCurrentAccountIscrittoOrdine() && !this.state.stpAttiva} href={PATH_TO_FO_ASSICURAZIONI}>
                  Elenco assicurazioni
                </LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>

        {!store.currentAccountHasRolesOR(FO_COMPLETE_LIST) && store.currentAccountHasRolesOR([RO_ISCR_ORDI]) && (
          <MegamenuItem itemName="Formazione">
            <LinkList>
              <LinkListItem>
                <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Esoneri">
                  <Row aria-setsize={10}>
                    <Col xs="25" lg="25">
                      <LinkList>
                        {store.currentAccountHasRolesOR([RO_ISCR_ORDI]) && <LinkListItem href={PATH_TO_FO_RICHIESTA_ESONERO}>Richiesta esonero</LinkListItem>}
                        <LinkListItem href={store.isBackOffice() ? PATH_TO_BO_ELENCO_RICHIESTE_ESONERO : PATH_TO_FO_ELENCO_RICHIESTE_ESONERO}>
                          Elenco richieste esonero
                        </LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </MegamenuItem>
              </LinkListItem>
              <LinkListItem>
                <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Eventi a catalogo">
                  <Row aria-setsize={10}>
                    <Col xs="25" lg="25">
                      <LinkList>
                        <LinkListItem href={PATH_TO_FO_ELENCO_EVENTI_CATALOGO}>Elenco eventi a catalogo</LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </MegamenuItem>
              </LinkListItem>
              <LinkListItem>
                <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Eventi non a catalogo/individuali">
                  <Row aria-setsize={10}>
                    <Col xs="25" lg="25">
                      <LinkList>
                        <LinkListItem href={PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_INDIVIDUALE + '?id=evno' + Math.random()}>Creazione evento</LinkListItem>
                        <LinkListItem href={PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI}>Elenco eventi</LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </MegamenuItem>
              </LinkListItem>
              <LinkListItem>
                <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Piani Formativi">
                  <Row aria-setsize={10}>
                    <Col xs="25" lg="25">
                      <LinkList>
                        <LinkListItem href={store.isCurrentAccountCONAF() ? PATH_TO_BO_ELENCO_POF : PATH_TO_FO_ELENCO_POF}>Elenco piani formativi</LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </MegamenuItem>
              </LinkListItem>
              <LinkListItem>
                <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Crediti formativi">
                  <Row aria-setsize={10}>
                    <Col xs="25" lg="25">
                      <LinkList>
                        <LinkListItem
                          href={
                            (store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_ODAF])) ||
                            (store.isCurrentAccountCONAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_CONAF]))
                              ? PATH_TO_BO_CREDITI_FORMATIVI
                              : PATH_TO_FO_CREDITI_FORMATIVI
                          }
                        >
                          Riepilogo crediti formativi
                        </LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </MegamenuItem>
              </LinkListItem>
            </LinkList>
          </MegamenuItem>
        )}

        {(store.isCurrentAccountFODAF() || store.isCurrentAccountCONAF() || store.isCurrentAccountOperatoreRivista) &&
          store.currentAccountHasRolesOR([RO_GEST_FORM_CONAF, RO_RESP_RFO, RO_GEST_FORM_FODAF]) && (
            <LinkListItem>
              {' '}
              <MegamenuItem itemName="Riviste">
                <LinkList>
                  {store.currentAccountHasRolesOR([RO_GEST_FORM_CONAF, RO_RESP_RFO, RO_GEST_FORM_FODAF]) && (
                    <LinkListItem>
                      <MegamenuItem className="inner-menu" itemName="Riviste" key="Riviste">
                        <LinkList>
                          <LinkListItem href={store.isBackOffice() ? PATH_TO_BO_ELENCO_RIVISTE : PATH_TO_FO_ELENCO_RIVISTE} key={'ElencoRiviste'}>
                            Elenco riviste
                          </LinkListItem>
                        </LinkList>
                      </MegamenuItem>
                    </LinkListItem>
                  )}
                </LinkList>
              </MegamenuItem>
            </LinkListItem>
          )}

        {store.currentAccountHasRolesOR([
          RO_GEST_FORM_ODAF,
          RO_GEST_FORM_CONAF,
          RO_CONS_FORM_CONAF,
          RO_OPER_FRMF,
          RO_RESP_FO,
          RO_FO_AGE_CONS_OPERATORE,
          RO_FO_AGE_GEST_OPERATORE,
          RO_FO_AGE_RESPONSABILE,
          RO_GEST_FORM_FODAF,
          RO_FO_ENTE_EXT_RESPONSABILE,
          RO_FO_ENTE_EXT_CONS_OPERATORE,
          RO_FO_ENTE_EXT_GEST_OPERATORE,
        ]) && (
          <LinkListItem>
            {' '}
            <MegamenuItem itemName="Formatori">
              <LinkList>
                {store.currentAccountHasRolesOR([
                  RO_GEST_FORM_ODAF,
                  RO_GEST_FORM_CONAF,
                  RO_CONS_FORM_CONAF,
                  RO_OPER_FRMF,
                  RO_RESP_FO,
                  RO_FO_AGE_CONS_OPERATORE,
                  RO_FO_AGE_GEST_OPERATORE,
                  RO_FO_AGE_RESPONSABILE,
                  RO_GEST_FORM_FODAF,
                  RO_FO_ENTE_EXT_RESPONSABILE,
                  RO_FO_ENTE_EXT_CONS_OPERATORE,
                  RO_FO_ENTE_EXT_GEST_OPERATORE,
                ]) && (
                  <LinkListItem>
                    {' '}
                    <MegamenuItem className="inner-menu" itemName="Formatori">
                      <LinkList>
                        <LinkListItem href={store.isBackOffice() ? PATH_TO_BO_ELENCO_FORMATORI : PATH_TO_FO_ELENCO_FORMATORI}>Elenco formatori</LinkListItem>
                      </LinkList>
                    </MegamenuItem>
                  </LinkListItem>
                )}
              </LinkList>
            </MegamenuItem>
          </LinkListItem>
        )}

        {store.currentAccountHasRolesOR(FO_COMPLETE_LIST) && (
          <MegamenuItem itemName="Formazione">
            <LinkList>
              {store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_ODAF]) && (
                <LinkListItem>
                  {' '}
                  <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Esoneri">
                    <Row aria-setsize={10}>
                      <Col xs="25" lg="25">
                        <LinkList>
                          {store.currentAccountHasRolesOR([RO_ISCR_ORDI]) && <LinkListItem href={PATH_TO_FO_RICHIESTA_ESONERO}>Richiesta esonero</LinkListItem>}
                          <LinkListItem href={store.isBackOffice() ? PATH_TO_BO_ELENCO_RICHIESTE_ESONERO : PATH_TO_FO_ELENCO_RICHIESTE_ESONERO}>
                            Elenco richieste esonero
                          </LinkListItem>
                        </LinkList>
                      </Col>
                    </Row>
                  </MegamenuItem>
                </LinkListItem>
              )}
              {store.currentAccountHasRolesOR([
                RO_GEST_FORM_CONAF,
                RO_CONS_FORM_CONAF,
                RO_RESP_FO,
                RO_FO_AGE_CONS_OPERATORE,
                RO_FO_AGE_GEST_OPERATORE,
                RO_FO_AGE_RESPONSABILE,
              ]) && (
                <LinkListItem>
                  {' '}
                  <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Agenzie formative">
                    <Row aria-setsize={10}>
                      <Col xs="25" lg="25">
                        <LinkList>
                          <LinkListItem href={store.isBackOffice() ? PATH_TO_BO_ELENCO_AGENZIE : PATH_TO_FO_ELENCO_AGENZIE}>Elenco agenzie</LinkListItem>
                        </LinkList>
                      </Col>
                    </Row>
                  </MegamenuItem>
                </LinkListItem>
              )}
              {store.currentAccountHasRolesOR([
                RO_FO_ENTE_EXT_RESPONSABILE,
                RO_FO_ENTE_EXT_CONS_OPERATORE,
                RO_FO_ENTE_EXT_GEST_OPERATORE,
                RO_GEST_FORM_CONAF,
              ]) && (
                <LinkListItem>
                  <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Enti esterni">
                    <Row aria-setsize={10}>
                      <Col xs="25" lg="25">
                        <LinkList>
                          <LinkListItem href={store.isBackOffice() ? PATH_TO_BO_ELENCO_ENTI_ESTERNI : PATH_TO_FO_ELENCO_ENTI_ESTERNI}>
                            Elenco enti esterni
                          </LinkListItem>
                          {store.isOperatoreCONAF() && <LinkListItem href={PATH_TO_BO_ENTE_ESTERNO}>Crea ente esterno</LinkListItem>}
                        </LinkList>
                      </Col>
                    </Row>
                  </MegamenuItem>
                </LinkListItem>
              )}
              <LinkListItem>
                <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Eventi a catalogo">
                  <Row aria-setsize={10}>
                    <Col xs="25" lg="25">
                      <LinkList>
                        {/* TODO remove rand id */}
                        {((store.isCurrentAccountCONAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_CONAF])) ||
                          (store.isCurrentAccountEnteFormazione() && this.state.statoAgenzia === 6) ||
                          store.isCurrentAccountEnteEsterno() ||
                          (store.isCurrentAccountFODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_FODAF])) ||
                          (store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_ODAF]))) && (
                          <LinkListItem href={PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_CATALOGO + '?id=ev' + Math.random()}>Creazione evento</LinkListItem>
                        )}
                        <LinkListItem
                          href={
                            store.isBackOffice() || store.currentAccountHasRolesOR(FO_ROLE_LIST)
                              ? PATH_TO_BO_ELENCO_EVENTI_CATALOGO
                              : PATH_TO_FO_ELENCO_EVENTI_CATALOGO
                          }
                        >
                          Elenco eventi a catalogo
                        </LinkListItem>
                      </LinkList>
                    </Col>
                  </Row>
                </MegamenuItem>
              </LinkListItem>
              {store.currentAccountHasRolesOR([...FO_EXTRA_LIST, RO_ISCR_ORDI]) && (
                <LinkListItem>
                  <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Eventi non a catalogo/individuali">
                    <Row aria-setsize={10}>
                      <Col xs="25" lg="25">
                        <LinkList>
                          {store.currentAccountHasRolesOR([RO_ISCR_ORDI]) && (
                            <LinkListItem href={PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_INDIVIDUALE + '?id=evno' + Math.random()}>Creazione evento</LinkListItem>
                          )}
                          <LinkListItem
                            href={
                              store.isBackOffice() || store.currentAccountHasRolesOR(FO_ROLE_LIST)
                                ? PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI
                                : PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI
                            }
                          >
                            Elenco eventi
                          </LinkListItem>
                        </LinkList>
                      </Col>
                    </Row>
                  </MegamenuItem>
                </LinkListItem>
              )}

              {((store.isCurrentAccountCONAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_CONAF])) ||
                (store.isCurrentAccountEnteFormazione() && this.state.statoAgenzia === 6) ||
                store.isCurrentAccountEnteEsterno() ||
                (store.isCurrentAccountFODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_FODAF])) ||
                (store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_ODAF]))) && (
                <LinkListItem>
                  {' '}
                  <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Piani formativi">
                    <Row aria-setsize={10}>
                      <Col xs="25" lg="25">
                        <LinkList>
                          <LinkListItem href={store.isCurrentAccountCONAF() ? PATH_TO_BO_ELENCO_POF : PATH_TO_FO_ELENCO_POF}>
                            Elenco piani formativi
                          </LinkListItem>
                        </LinkList>
                      </Col>
                    </Row>
                  </MegamenuItem>
                </LinkListItem>
              )}

              {store.currentAccountHasRolesOR([RO_ISCR_ORDI, RO_GEST_FORM_ODAF, RO_GEST_FORM_CONAF]) && (
                <LinkListItem>
                  {' '}
                  <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Crediti formativi">
                    <Row aria-setsize={10}>
                      <Col xs="25" lg="25">
                        <LinkList>
                          <LinkListItem
                            href={store.isCurrentAccountODAF() || store.isCurrentAccountCONAF() ? PATH_TO_BO_CREDITI_FORMATIVI : PATH_TO_FO_CREDITI_FORMATIVI}
                          >
                            Riepilogo crediti formativi
                          </LinkListItem>
                        </LinkList>
                      </Col>
                    </Row>
                  </MegamenuItem>
                </LinkListItem>
              )}

              {store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_ODAF]) && (
                <LinkListItem>
                  {' '}
                  <MegamenuItem className={!isCurrentMobileDevice() && 'inner-menu'} itemName="Ripartizione CFP e proroghe">
                    <Row aria-setsize={10}>
                      <Col xs="25" lg="25">
                        <LinkList>
                          <LinkListItem
                            href={
                              store.isCurrentAccountODAF() && store.currentAccountHasRolesOR([RO_GEST_FORM_ODAF])
                                ? PATH_TO_BO_LISTA_ISCRITTI_ORDINE
                                : PATH_TO_BO_LISTA_ISCRITTI_ORDINE
                            }
                          >
                            Ripartizione dei CFP e concessione proroghe
                          </LinkListItem>
                        </LinkList>
                      </Col>
                    </Row>
                  </MegamenuItem>
                </LinkListItem>
              )}
            </LinkList>
          </MegamenuItem>
        )}

        {store.currentAccountHasRolesOR(STATS_ROLE_LIST) && (
          <MegamenuItem itemName="Statistiche">
            <Row>
              <Col xs="25" lg="25">
                <LinkList>
                  <LinkListItem hidden={!store.isBackOffice()} href={PATH_TO_BO_STATS}>
                    Anagrafica
                  </LinkListItem>
                  <LinkListItem hidden={!store.isBackOffice()} href={PATH_TO_BO_STATS_POLI}>
                    Polizze
                  </LinkListItem>
                  <LinkListItem hidden={!store.isBackOffice()} href={PATH_TO_BO_STATS_FOR}>
                    Formazione
                  </LinkListItem>
                  {/*<LinkListItem hidden={!store.isBackOffice()} href={PATH_TO_BO_STATS_DISC}>Discipinare</LinkListItem>*/}
                  <LinkListItem hidden={!store.isBackOffice() || store.isCurrentAccountODAF() || store.isCurrentAccountFODAF()} href={PATH_TO_BO_STATS_INI_PEC}>
                    INI-PEC
                  </LinkListItem>
                  <LinkListItem
                    hidden={!store.isBackOffice() || store.isCurrentAccountODAF() || store.isCurrentAccountFODAF()}
                    href={PATH_TO_BO_STATS_REG_INDE}
                  >
                    ReGIndE
                  </LinkListItem>
                </LinkList>
              </Col>
            </Row>
          </MegamenuItem>
        )}

        <MegamenuItem itemName="Policy">
          <Row>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem href={PATH_TO_FO_PRIVACY} key={'Privacy'}>
                  Privacy policy
                </LinkListItem>
                <LinkListItem href={PATH_TO_FO_COOKIE} key={'Cookie'}>
                  Cookie policy
                </LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>

        <MegamenuItem itemName="Documenti" key="Documenti" hidden={store.isCurrentAccountAuthenticated()}>
          <Row>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem hidden={!store.isCurrentAccountCONAF()} target={'_blank'} rel="noopener" href={PATH_DOC_MANUALISTICA_OPERATORI_CONAF}>
                  Manuale CONAF
                </LinkListItem>
                <LinkListItem
                  hidden={!store.isCurrentAccountFODAF() && !store.isCurrentAccountODAF()}
                  target={'_blank'}
                  rel="noopener"
                  href={PATH_DOC_MANUALISTICA_OPERATORI_ODAF_FODAF}
                >
                  Manuale ODAF/FODAF
                </LinkListItem>
                <LinkListItem
                  hidden={!store.isCurrentAccountIscrittoOrdine() && !store.isCurrentAccountSTP() && !store.isCurrentAccountEnteFormazione()}
                  target={'_blank'}
                  rel="noopener"
                  href={PATH_DOC_MANUALISTICA_ISCRITTI}
                >
                  Manuale iscritti
                </LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>
      </>
    )
  }

  buildMenuWithoutAutentication() {
    return (
      <>
        <MegamenuItem itemName="Ricerca albo unico" key="Ricerca-albo-unico">
          <Row>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem href={PATH_TO_RICERCA_ALBO_UNICO} key={PATH_TO_RICERCA_ALBO_UNICO}>
                  Ricerca iscritti all'albo
                </LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>
        <MegamenuItem itemName="Eventi a catalogo" key="Ricerca-albo-unico">
          <Row>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem href={PATH_TO_FO_ELENCO_EVENTI_CATALOGO} key={PATH_TO_FO_ELENCO_EVENTI_CATALOGO}>
                  Lista eventi a catalogo
                </LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>
        <MegamenuItem itemName="Policy" key="Policy2">
          <Row>
            <Col xs="25" lg="25">
              <LinkList>
                <LinkListItem href={PATH_TO_FO_PRIVACY} key={'Policy2'}>
                  Privacy policy
                </LinkListItem>
                <LinkListItem href={PATH_TO_FO_COOKIE} key={'Cookie2'}>
                  Cookie policy
                </LinkListItem>
              </LinkList>
            </Col>
          </Row>
        </MegamenuItem>
      </>
    )
  }
  toggle = () => {
    this.setState({ openNav: !this.state.openNav })
  }

  render() {
    const { isMobile } = this.state
    var menu = this.state.menu
    function NavBar() {
      return menu
    }
    const SocialZone = () => {
      return (
        <>
          <LinkList>
            <LinkListItem divider />
          </LinkList>
          <Row>
            <Col xs="5" lg="5">
              <Label>Seguici su</Label>
            </Col>
            <Col xs="1" lg="1">
              <a aria-label="Facebook" href={facebook} target="_blank" rel="noreferrer">
                <Icon color="primary" icon="it-facebook" />
              </a>
            </Col>
            <Col xs="1" lg="1">
              <a aria-label="Twitter" href={twitter} target="_blank" rel="noreferrer">
                <Icon color="primary" icon="it-twitter" />
              </a>
            </Col>
            <Col xs="1" lg="1">
              <a aria-label="Instagram" href={instagram} target="_blank" rel="noreferrer">
                <Icon color="primary" icon="it-instagram" />
              </a>
            </Col>
            <Col xs="1" lg="1">
              <a aria-label="Mail" href={'mailto:' + mailto} target="_blank" rel="noreferrer">
                <Icon color="primary" icon="it-mail" />
              </a>
            </Col>
          </Row>
        </>
      )
    }

    return (
      <>
        {!isMobile && !isCurrentMobileDevice() ? (
          <Header theme="" type="navbar" className="resp-wrap-it">
            <HeaderContent expand="lg" megamenu>
              <HeaderToggler aria-controls="nav1" aria-expanded="false" aria-label="Toggle navigation">
                <Icon icon="it-burger" onClick={this.toggle} />
              </HeaderToggler>
              <Collapse header navbar isOpen={this.state.openNav} onOverlayClick={this.toggle}>
                <div className="menu-wrapper">
                  <Megamenu>
                    <NavBar />
                  </Megamenu>
                </div>
              </Collapse>
            </HeaderContent>
          </Header>
        ) : (
          <Sidebar className="side-bar-mobile" style={{}}>
            <div className="row">
              <div className="col-4">
                <Icon color="primary" icon="it-burger" onClick={this.toggle} />
              </div>
              <div className="col-7">
                {this.state.profilo === undefined ? (
                  <GenericSpinner />
                ) : (
                  <a href={PATH_TO_FO_ANAG_SELEZIONA_PROFILO}>
                    <span>{this.state.profilo !== '' ? this.state.profilo : ''}</span>
                  </a>
                )}
              </div>
            </div>

            <Collapse isOpen={this.state.openNav} onOverlayClick={this.toggle} className="dropdown-menu-mobile">
              <Nav navbar className="ml-3">
                <NavBar />
                <SocialZone />
              </Nav>
            </Collapse>
          </Sidebar>
        )}
      </>
    )
  }
}
export declare interface GeneralHeaderNavProps {
  userLogged?: any
}
export default GenericHeaderNav

/**
 * Questa funzione gestisce le iscrizioni agli ordini territoriali
 * È utilizzata sia nel caso di prima iscrizione sia nel caso di
 * reiscrizione.
 * La differenza sta nel percorso verso il quale fare il redirect
 * dopo la chiama nuovaIscrizioneOrdine.
 * @param path
 */
export function gestisciIscrizioneOrdineMenuItem(path): void {
  nuovaIscrizioneOrdine(TokenStore.getInstance().getSpidData())
    .then(() => {
      goTo(path)
    })
    .catch((err) => {
      throw new Error(err)
    })
}

/**
 * Questa funzione gestisce le iscrizioni STP.
 * È utilizzata sia nel caso di prima iscrizione sia nel caso di
 * reiscrizione.
 * La differenza sta nel percorso verso il quale fare il redirect
 * dopo la chiama nuovaIscrizioneOrdine.
 * @param path
 */
export function gestisciIscrizioneSTPMenuItem(path): void {
  nuovaIscrizioneSTP(TokenStore.getInstance().getSpidData())
    .then(() => {
      goTo(path)
    })
    .catch((err) => {
      throw new Error(err)
    })
}

/**
 * Restituisce true se l'utente corrente non è iscritto all'ordine
 * ma lo è stato già in passato, quindi ha già una o più iscrizioni
 * all'ordine pregresse.
 * @returns
 */
async function isEnabledReiscrizioneOrdineFunction(): Promise<boolean> {
  var cf = TokenStore.getInstance().getSpidData().codiceFiscale
  await isEnabledReiscrizioneOrdine(cf)
    .then((response) => {
      if (response.data.httpStatus !== 200) throw new Error(response.data.error)
      return response.data.returnedObject
    })
    .catch((err) => {
      throw new Error(err)
    })
  return TokenStore.getInstance().isIscrittoOrdine()
}

/**
 * Restituisce true se l'utente corrente non è iscritto STP
 * ma lo è stato già in passato, quindi ha già una o più iscrizioni
 * all'ordine pregresse.
 * @returns
 */
async function isEnabledReiscrizioneSTPFunction(): Promise<boolean> {
  var cf = TokenStore.getInstance().getSpidData().codiceFiscale
  await isEnabledReiscrizioneSTP(cf)
    .then((response) => {
      if (response.data.httpStatus !== 200) throw new Error(response.data.error)
      return response.data.returnedObject
    })
    .catch((err) => {
      throw new Error(err)
    })
  return TokenStore.getInstance().isIscrittoOrdine()
}

/**
 * Restituisce false se l'utente ha un'iscrizione all'ordine in stato "valutazione", "cancellato" o "radiato".
 * @returns
 */
export function isEnabledModificaOrdineFunction(listaIscrizioniAttive) {
  if (
    listaIscrizioniAttive.length > 0 &&
    listaIscrizioniAttive.length !== undefined &&
    listaIscrizioniAttive.find((iscrizione) => iscrizione.codiceTipologiaIscrizioni === 'iscritto_all_ordine')
  ) {
    if (
      listaIscrizioniAttive.find(
        (iscrizione) => iscrizione.codiceTipologiaIscrizioni === 'iscritto_all_ordine' && (iscrizione.statoIscrizione === 1 || iscrizione.statoIscrizione === 4)
      )
    ) {
      return true
    } else if (
      listaIscrizioniAttive.find(
        (iscrizione) =>
          iscrizione.codiceTipologiaIscrizioni === 'iscritto_all_ordine' &&
          (iscrizione.statoIscrizione === 2 || iscrizione.statoIscrizione === 5 || iscrizione.statoIscrizione === 8)
      )
    ) {
      return false
    } else {
      return true
    }
  }
  return false
}

/**
 * Restituisce false se l'utente ha un'iscrizione STP in stato "valutazione", "cancellato" o "radiato".
 * @returns
 */
export function isEnabledModificaSTPFunction(iscrizioniAttiveStp) {
  if (
    (iscrizioniAttiveStp.length > 0 && iscrizioniAttiveStp.length !== undefined && TokenStore.getInstance().isCurrentAccountAuthenticated()) ||
    (TokenStore.getInstance().isCurrentAccountSTP() && TokenStore.getInstance().isOperatoreResponsabileSTP())
  ) {
    if (
      iscrizioniAttiveStp.find(
        (iscrizione) => iscrizione.codiceTipologiaIscrizioni === 'iscritto_stp' && (iscrizione.statoIscrizione === 1 || iscrizione.statoIscrizione === 4)
      )
    ) {
      return true
    } else if (
      iscrizioniAttiveStp.find(
        (iscrizione) =>
          iscrizione.codiceTipologiaIscrizioni === 'iscritto_stp' &&
          (iscrizione.statoIscrizione === 2 || iscrizione.statoIscrizione === 5 || iscrizione.statoIscrizione === 8)
      )
    ) {
      return false
    } else {
      return true
    }
  }

  return false
}

/**
 * Restituisce true se è presente un'iscrizione STP.
 * @returns
 */
export function isEnabledVisualizzaSTPFunction(iscrizioniAttiveStp, isRappresentanteLegale) {
  if (TokenStore.getInstance().isCurrentAccountSTP() && isRappresentanteLegale) {
    return true
  } else if (TokenStore.getInstance().isCurrentAccountSTP() && TokenStore.getInstance().isOperatoreResponsabileSTP()) {
    return true
  } else if (iscrizioniAttiveStp.length > 0 && iscrizioniAttiveStp.length !== undefined && TokenStore.getInstance().isCurrentAccountAuthenticated()) {
    if (iscrizioniAttiveStp.find((iscrizione) => iscrizione.codiceTipologiaIscrizioni === 'iscritto_stp')) {
      return true
    } else {
      return false
    }
  }
  return false
}

/**
 * Restituisce true se è presente un'iscrizione STP.
 * @returns
 */
export function isEnabledVisualizzaOrdineFunction(iscrizioniAttive) {
  if (TokenStore.getInstance().isCurrentAccountIscrittoOrdine()) {
    return true
  } else if (iscrizioniAttive.length > 0 && iscrizioniAttive.length !== undefined && TokenStore.getInstance().isCurrentAccountAuthenticated()) {
    if (iscrizioniAttive.find((iscrizione) => iscrizione.codiceTipologiaIscrizioni === 'iscritto_all_ordine')) {
      return true
    } else {
      return false
    }
  }
  return false
}
