import styled from 'styled-components'


export const TextStatus = styled.div`
  padding-top:20px;
  padding-bottom: 20px;
`

export const ProtocolloInfo = styled.div`
    display:flex;
    align-items:flex-start;
    padding-top:20px;
    padding-bottom: 20px;
    .form-group{
      margin:0px 10px 0px 0px;
    }
    .fill{
      flex-grow:1;
    }
`

export const StyledCCFPForm = styled.div`

  .topbar {
    padding: 10px 0px;
  }

  .wrap-select {
    flex-grow: 1
  }

  .col-cfp-input {
    width: 150px;
  }

  .total-row {
    display: flex;
    justify-content: end;
    padding: 4px 0px;

    label {
      font-weight: bold;
    }

    span.label {
      display: block;
      width: 150px;
      text-align: right;
    }
  }
`

export const StyleCFPdForm = styled.div`
  .totals {
    padding-top: 30px;
  }
`
