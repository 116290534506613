import React, { useCallback, useRef, useState } from 'react'
import { STATI_AGENZIE_VALUES } from 'src/formazione'
import { Form, Label } from 'design-react-kit'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import { useHistory } from 'react-router-dom'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormApprovazioneMerito: React.FunctionComponent<FormApprovazioneMeritoProps> = ({ id, tipo, submitMerito }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)

  const rules = {
    delibera: {
      isRequired: true,
    },
    data_delibera: {
      isRequired: true,
    },
    data_accreditamento: {
      isRequired: true,
    },
    data_fine_accr: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp, tab) => {
    refForm.current.push({
      tab: 0,
      cmp: cmp,
    })
  }
  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const isValid = useCallback(
    (toIndex) => {
      for (let index = 0; index <= toIndex; index++) {
        const inputs = getTabActiveFormId(refForm, index)
        const isValid = validateFormData(formData, rules, inputs)
        if (!isValid) return false
      }
      return true
    },
    [formData]
  )

  const submit = useCallback(
    async (e) => {
      let dato = {}

      if (!isValid(5)) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Controllare i dati inseriti')
        return null
      }
      setStatus(STATUS.LOADING)

      if (tipo === 2) {
        dato = {
          id,
          stato: STATI_AGENZIE_VALUES.ACCREDITATA,
          dataAccettazione: new Date().toISOString(),
          data_accreditamento: formData.data.data_accreditamento,
          data_fine: formData.data.data_fine_accr,
          data_delibera_conaf: formData.data.data_delibera,
          note_acc_rifiuto: formData.data.delibera,
          note_acc_rifiuto_min: formData.data.delibera,
        }
        try {
          setStatus(STATUS.LOADING)
          await getFormazioneResourceApi().msfoFormazioneAccAgenziaFormativaPatch(dato, getDefaultAxiosConfig())
          setStatus(STATUS.SUCCESS)
          setAlertMessage('Dati salvati con successo')
          setTimeout(() => {
            history.goBack()
          }, 500)
        } catch (e) {
          setStatus(STATUS.FAILURE)
          setAlertMessage('Impossibile salvare i dati')
        }
        return
      } else {
        submitMerito()
          .then((status) => {
            setStatus(status.status)
            setAlertMessage(status.message)
          })
          .catch(() => {
            setStatus(STATUS.FAILURE)
            setAlertMessage('Impossibile salvare i dati')
          })
      }
    },
    [id, tipo, formData]
  )
  return (
    <>
      <FormSubmit title="Approvazione di merito" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
        <Label>Per l'approvazione di merito per l'agenzia cliccare "Conferma".</Label>
      </FormSubmit>
      {tipo === 2 && (
        <GenericCard cardHeader={'Informazioni approvazione'}>
          <Form>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <GenericInput
                  id="delibera"
                  type="text"
                  label="Delibera di approvazione CONAF*"
                  value={data.delibera}
                  onChange={handleInput}
                  onInit={onInitInput}
                  errorMessage={errors?.delibera}
                />
              </div>
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <GenericInput
                  id="data_delibera"
                  type="date"
                  label="Data delibera*"
                  value={data.data_delibera}
                  onChange={handleInput}
                  onInit={onInitInput}
                  errorMessage={errors?.data_delibera}
                />
              </div>
            </div>
            <div className="row">
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <GenericInput
                  id="data_accreditamento"
                  type="date"
                  label="Data accreditamento*"
                  value={data.data_accreditamento}
                  onChange={handleInput}
                  onInit={onInitInput}
                  errorMessage={errors?.data_accreditamento}
                />
              </div>
              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                <GenericInput
                  id="data_fine_accr"
                  type="date"
                  label="Data fine accreditamento*"
                  value={data.data_fine_accr}
                  onChange={handleInput}
                  onInit={onInitInput}
                  errorMessage={errors?.data_fine_accr}
                />
              </div>
            </div>
          </Form>
        </GenericCard>
      )}
    </>
  )
}

interface FormApprovazioneMeritoProps {
  id: any
  tipo?: number
  submitMerito?: any
}

export default FormApprovazioneMerito
