import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form, Title } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice()

export class FormConsiglioInCarica extends React.Component<GeneralFormConsiglioInCaricaProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      presidente: props.presidente,
      consigliere: props.consigliere,
      vicepresidente: props.vicepresidente,
      tesoriere: props.tesoriere,
      segretario: props.segretario,
      messaggio: props.messaggio,
      isLoaded: false,
    }
  }

  componentDidMount(): void {
    this.setState({ isLoaded: true })
  }

  render() {
    const { returnedObject, messaggio } = this.props
    const { presidente, consigliere, vicepresidente, tesoriere, segretario, isLoaded } = this.state

    if (isLoaded) {
      return (
        <>
          {returnedObject === '' && messaggio === '' && presidente && consigliere && vicepresidente && tesoriere && segretario ? (
            <GenericSpinner />
          ) : messaggio !== '' ? (
            <EmptyState variant="full">
              <EmptyStateIcon icon={ExclamationTriangleIcon} />
              <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
            </EmptyState>
          ) : (
            <div>
              <Form>
                <Title headingLevel="h3">Presidente</Title>
                {presidente?.map((presidente, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          key={'nomePresidente' + index}
                          id={'nomePresidente' + index}
                          label={'Nominativo'}
                          value={presidente?.nome && presidente?.cognome ? presidente?.nome + ' ' + presidente?.cognome : '-'}
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={presidente?.email ? presidente?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                {/*---------------------------------------- Campi visibili solo in caso di ordine territoriale ----------------------------------------*/}
                <div hidden={returnedObject[0]?.tipo?.startsWith('FODAF')}>
                  <Title headingLevel="h3">Vicepresidente</Title>
                  {vicepresidente?.map((vicepresidente, index) => {
                    return (
                      <div className="row pt-3">
                        <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                          <GenericTextInput
                            type={'text'}
                            className="w-75"
                            key={'nomeVicePresidente' + index}
                            id={'nomeVicePresidente' + index}
                            label={'Nominativo'}
                            value={vicepresidente?.nome && vicepresidente?.cognome ? vicepresidente?.nome + ' ' + vicepresidente?.cognome : '-'}
                            isReadOnly
                          />
                        </div>
                        <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                          <GenericTextInput
                            type={'text'}
                            className="w-75"
                            id={'email'}
                            label={'Email'}
                            value={vicepresidente?.email ? vicepresidente?.email : '-'}
                            isReadOnly
                          />
                        </div>
                      </div>
                    )
                  })}
                  <br></br>
                  <Title headingLevel="h3">Segretario</Title>
                  {segretario?.map((segretario, index) => {
                    return (
                      <div className="row pt-3">
                        <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                          <GenericTextInput
                            type={'text'}
                            className="w-75"
                            key={'nomeSegretario' + index}
                            id={'nomeSegretario' + index}
                            label={'Nominativo'}
                            value={segretario?.nome && segretario?.cognome ? segretario?.nome + ' ' + segretario?.cognome : '-'}
                            isReadOnly
                          />
                        </div>
                        <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                          <GenericTextInput
                            type={'text'}
                            className="w-75"
                            id={'email'}
                            label={'Email'}
                            value={segretario?.email ? segretario?.email : '-'}
                            isReadOnly
                          />
                        </div>
                      </div>
                    )
                  })}
                  <br></br>
                  <Title headingLevel="h3">Tesoriere</Title>
                  {tesoriere?.map((tesoriere, index) => {
                    return (
                      <div className="row pt-3">
                        <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                          <GenericTextInput
                            type={'text'}
                            className="w-75"
                            key={'nomeTesoriere' + index}
                            id={'nomeTesoriere' + index}
                            label={'Nominativo'}
                            value={tesoriere?.nome && tesoriere?.cognome ? tesoriere?.nome + ' ' + tesoriere?.cognome : '-'}
                            isReadOnly
                          />
                        </div>
                        <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                          <GenericTextInput
                            type={'text'}
                            className="w-75"
                            id={'email'}
                            label={'Email'}
                            value={tesoriere?.email ? tesoriere?.email : '-'}
                            isReadOnly
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/*------------------------------------------------------------------------------------------------------------------------------------*/}
                <Title headingLevel="h3">Consiglieri</Title>
                {consigliere?.map((consigliere, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          key={'nomeConsigliere' + index}
                          id={'nomeConsigliere' + index}
                          label={'Nominativo'}
                          value={consigliere?.nome && consigliere?.cognome ? consigliere?.nome + ' ' + consigliere?.cognome : '-'}
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={consigliere?.email ? consigliere?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
              </Form>
            </div>
          )}
        </>
      )
    } else {
      return <GenericSpinner />
    }
  }
}

export declare interface GeneralFormConsiglioInCaricaProps {
  returnedObject?: any
  messaggio?: string
  presidente?: any
  consigliere?: any
  vicepresidente?: any
  tesoriere?: any
  segretario?: any
}
