import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDatiGeneraliStpBO extends React.Component<GeneralFormDatiGeneraliStpBOProps, any> {
  render() {
    const { soggetto, formaGiuridica, codiceAteco, messaggio } = this.props
    return (
      <>
        {soggetto === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idRagioneSociale'}
                    label={'Ragione sociale¹'}
                    value={soggetto.ragioneSociale ? soggetto.ragioneSociale : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idFormaGiuridica'}
                    label={'Forma giuridica'}
                    value={formaGiuridica ? formaGiuridica : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCodiceFiscale'}
                    label={'Codice fiscale azienda'}
                    value={soggetto.codiceFiscaleAzienda ? soggetto.codiceFiscaleAzienda : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idPartitaIVA'}
                    label={'Partita IVA'}
                    value={soggetto.partitaIva ? soggetto.partitaIva : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCodiceATECO'}
                    label={'Codice ATECO'}
                    value={codiceAteco ? codiceAteco : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idNumeroRea'}
                    label={'Numero REA'}
                    value={soggetto.numeroRea ? soggetto.numeroRea : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCodiceSezioneSpeciale'}
                    label={'Codice sezione speciale professionisti'}
                    value={soggetto.codiceSezioneSpecialeProfessionisti ? soggetto.codiceSezioneSpecialeProfessionisti : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-100"
                    id={'idDataIscrizioneCCIAA'}
                    label={'Data iscrizione CCIAA'}
                    value={soggetto.dataIscrCciaa ? soggetto.dataIscrCciaa : '-'}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCittaCCIAA'}
                    label={'Città CCIAA'}
                    value={soggetto.comuCciaa ? soggetto.comuCciaa : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idOrdineTerritorialeRichiesto'}
                    label={'Ordine territoriale richiesto'}
                    value={soggetto.denominazioneGruppo ? soggetto.denominazioneGruppo : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div>
                <br></br>
                <small>(¹) La denominazione sociale, in qualunque modo formata, deve contenere l'indicazione di società tra professionisti.</small>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiGeneraliStpBOProps {
  messaggio?: any
  soggetto?: any
  codiceAteco?: any
  formaGiuridica?: any
}
