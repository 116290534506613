import React from 'react'
import { MenuToggle } from '@patternfly/react-core'
import FilterIcon from '@patternfly/react-icons/dist/esm/icons/filter-icon'


class GenericMenuToogle extends React.Component<GeneralMenuToogle, any> {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            onClick: props.onClick
        };
    }

    render() {
        const { label } = this.state
        return (
            <MenuToggle icon={<FilterIcon />} variant="typeahead" onClick={this.props.onClick}>{label}</MenuToggle>
        );
    }
}

export declare interface GeneralMenuToogle {
    label?: any;
    onClick?: any;
}

export default GenericMenuToogle;
