import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form, FormGroup } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericCheckbox from 'src/backoffice/app/components/GenericCheckbox'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { getBigIntToConvertToBoolean } from 'src/utilities/utility'
import { getFileByIdAttachmentBO, getFileByIdAttachmentFO } from 'src/processes/ProcessoIscrizione'
import { Icon } from 'design-react-kit'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDatiRappresentanteLegaleStpBO extends React.Component<GeneralFormDatiRappresentanteLegaleStpBOProps, any> {
  getFile = (idAttachment, filename) => {
    try {
      let dataUri: any = ''
      //se la pagina di visualizzazione è frontoffice faccio la chiamata per ilò frontoffice
      if (
        TokenStore.getInstance().isCurrentAccountSTP() ||
        TokenStore.getInstance().isCurrentAccountIscrittoOrdine() ||
        TokenStore.getInstance().isCurrentAccountAuthenticated()
      ) {
        getFileByIdAttachmentFO(idAttachment).then((response) => {
          if (response === undefined) {
            alert('File non presente')
          }

          dataUri = 'data:' + response.data.contentType + ';base64,' + response.data.content
          const link = document.createElement('a')
          link.download = filename
          link.href = dataUri
          link.click()
        })
      }
      //altrimenti faccio la chiamata per il backoffice
      else {
        getFileByIdAttachmentBO(idAttachment).then((response) => {
          if (response === undefined) {
            alert('File non presente')
          }

          dataUri = 'data:' + response.data.contentType + ';base64,' + response.data.content
          const link = document.createElement('a')
          link.download = filename
          link.href = dataUri
          link.click()
        })
      }
    } catch (err) {
      alert('errore nel caricamento del file')
    }
  }

  render() {
    const { soggetto, messaggio, listaFiles, datiRappresentanteLegale } = this.props
    return (
      <>
        {soggetto === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idNome'}
                    label={'Nome'}
                    value={datiRappresentanteLegale.nome ? datiRappresentanteLegale.nome : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCognome'}
                    label={'Cognome'}
                    value={datiRappresentanteLegale.cognome ? datiRappresentanteLegale.cognome : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idCodiceFiscale'}
                    label={'Codice fiscale'}
                    value={datiRappresentanteLegale.cf ? datiRappresentanteLegale.cf : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    id={'idSesso'}
                    label={'Sesso'}
                    value={datiRappresentanteLegale.sesso ? datiRappresentanteLegale.sesso : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-100"
                    id={'idDataNascita'}
                    label={'Data di nascita'}
                    value={datiRappresentanteLegale.dataNascita ? datiRappresentanteLegale.dataNascita : '-'}
                    style={{ paddingRight: '25px' }}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'email'}
                    className="w-100"
                    id={'idEmail'}
                    label={'Email di contatto'}
                    value={soggetto.emailOrdinaria ? soggetto.emailOrdinaria : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput type={'email'} className="w-100" id={'idPec'} label={'PEC'} value={soggetto.pec ? soggetto.pec : '-'} isReadOnly />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <FormGroup label={'Mandato'}>
                    {listaFiles.map((file) =>
                      file.codiceTipoAllegato === 'mandato_stp' ? (
                        <div>
                          <Icon icon={'it-download'} size="sm" />
                          <a onClick={() => this.getFile(file.idAttachment, file.filename)}>{file.filename}</a>
                        </div>
                      ) : null
                    )}
                  </FormGroup>
                </div>
              </div>
              <div>
                <GenericCheckbox
                  id={'idPrivacy'}
                  label={
                    '* Dichiara di essere informato, ai sensi e per gli effetti dell’art. 13 del D.Lgs. 196/2003 e all’art. 13 GDPR 679/16, che i dati personali raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa.'
                  }
                  isChecked={getBigIntToConvertToBoolean(soggetto.consensoPrivacy)}
                  isDisabled
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiRappresentanteLegaleStpBOProps {
  messaggio?: any
  soggetto?: any
  datiRappresentanteLegale?: any
  listaFiles?: any
}
