export const NETWORK_ERROR = "Network Error";
export const CONSTRAINT_VIOLATION = "Network Error";
export const FORMATO_NON_GESTITO = "Formato della risposta non gestito";

/**
 * Enum che consente di classificare i vari status code delle chiamate http
 * Sono stati censiti i più comuni
 */
export enum CodiciErrore{
    _100 = "Il server ha ricevuto l'header della richiesta e il client deve procedere ad inviare il corpo della richiesta. ",
    _200 = "Richiesta inviata con successo. ",
    _204 = "Alcun contenuto trovato. ",
    _300 = "Servizio non disponibile. Una nuova risorsa ha sostituito la risorsa richiesta. ",
    _400 = "Attenzione. ",
    _401 = "Si è verificato un problema. Autorizzazione necessaria. ",
    _403 = "Si è verificato un problema. Si sta tentando di accedere a qualcosa per cui non si ha il permesso di visualizzare. I contenuti sono protetti. ",
    _404 = "Si è verificato un problema. La risorsa non è stata trovata. ",
    _500 = "Si è verificato un problema che ha impedito il completamento della richiesta. ",
    _ = "Si è verificato un problema. Ci scusiamo per il disagio.",
    
}

export enum MessaggiErroreUtente {
    recupero_dati_message = "C'è stato un errore durante il recupero dei dati. ",
    errore_di_rete_message = "Errore di rete. ",
    constraint_violation_message = "Errore durante la compilazione del form. ", 
    default_error_message = "C'è stato un errore durante il recupero dei dati. Ci scusiamo per il disagio. ",
    errore_chiamata_recupero_polizza = "Errore durante la chiamata per il recupero della polizza. ",
    errore_caricamento_file = "Si è verificato un errore durante il caricamento del file. ",
    errore_downlaod_file = "Si è verificato un errore durante il downlaod del file. ",
}




