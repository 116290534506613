import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

export class FormDatiIscrizioneStpBO extends React.Component<GeneralFormDatiIscrizioneStpBOProps, any> {
  render() {
    const { iscrizione, messaggio } = this.props
    return (
      <>
        {iscrizione === '' && messaggio === '' ? (
          <GenericSpinner />
        ) : messaggio !== '' ? (
          <EmptyState variant="full">
            <EmptyStateIcon icon={ExclamationTriangleIcon} />
            <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
          </EmptyState>
        ) : (
          <div>
            <Form>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    label={'Numero iscrizione'}
                    id={'idNumeroIscrizioneSTP'}
                    value={iscrizione.numeroIscrizioneOrdine ? iscrizione.numeroIscrizioneOrdine : '-'}
                    isReadOnly
                  />
                </div>
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'date'}
                    className="w-100"
                    label={'Data iscrizione'}
                    id={'idDataIscrizioneSTP'}
                    value={iscrizione.dataIscrizioneOrdine ? iscrizione.dataIscrizioneOrdine : '-'}
                    isReadOnly
                  />
                </div>
              </div>
              <div className="row">
                <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                  <GenericTextInput
                    type={'text'}
                    className="w-100"
                    label={'Delibera iscrizione'}
                    id={'idDeliberaIscrizioneSTP'}
                    value={iscrizione.deliberaIscrizione ? iscrizione.deliberaIscrizione : '-'}
                    isReadOnly
                  />
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}

export declare interface GeneralFormDatiIscrizioneStpBOProps {
  messaggio?: any
  iscrizione?: any
}
