import React, { MouseEventHandler } from 'react'
import { Button, Modal, ModalVariant } from '@patternfly/react-core'

class GenericModal extends React.Component<GeneralModalToogle, any> {

    render(){

        return (
                <Modal
                    variant={this.props.size ? this.props.size : ModalVariant.large}
                    title={this.props.title? this.props.title: "Titolo non definito"}
                    titleIconVariant={this.props.iconTitleType}
                    isOpen={this.props.modalOpen}
                    onClose={this.props.closeButton}
                    actions={[
                        <Button hidden={this.props.makeChoice} key="confirm" variant="primary" onClick={this.props.closeButton}>
                            Ok
                        </Button>,
                        <Button hidden key="cancel" variant="link" onClick={this.props.closeButton}>
                            Cancel
                        </Button>,
                        <Button hidden={!this.props.makeChoice} key="yes" variant="primary" onClick={this.props.choiceAction}>
                            Si
                        </Button>,
                        <Button hidden={!this.props.makeChoice} key="no" variant="link" onClick={this.props.closeButton}>
                            No
                        </Button>
                    ]}
                > {this.props.text}
                </Modal>

        );

    }
}


export declare interface GeneralModalToogle {
    text?: any;
    title?: any;
    modalOpen?: boolean;
    makeChoice?: boolean;
    closeButton?: any;
    closeHandler?: MouseEventHandler;
    handlerModalToggler?: any;
    handlerModalOpen?: boolean;
    choiceAction?: any;
    size?: any;
    /**
     * @description | "default" | "success" | "danger" | "warning" | "info" | undefined
     */
    iconTitleType?: any;
}


export default GenericModal;
