import React from 'react'
import GenericTable from 'src/backoffice/app/components/GenericTable'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { PageSection, Title } from '@patternfly/react-core'
import GenericSpinner from '../components/GenericSpinner'
import { recuperaCertificato, recuperaCopiaPagamento } from 'src/processes/assicurazioni/Assicurazione'
import { convertStatoToCodiceStato } from 'src/utilities/utility'
import { GROUP_AUTHENTICATED, GROUP_CONAF, GROUP_FODAF, GROUP_ODAF, TokenStore } from 'src/keycloak/jwt/TokenStore'
import { getOrdineCompetenteOperatorePOST, getOrdini } from 'src/processes/Ordini'
import { elencoPolizzeList, elencoPolizzeListCsv } from 'src/Query/ElencoPolizzeFilterQuery'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {
  PATH_TO_BO_POLIZZE_ELENCO,
  PATH_TO_BO_POLIZZE_ELENCO_CANCELLA,
  PATH_TO_BO_POLIZZE_ELENCO_CONFERMA,
  PATH_TO_BO_POLIZZE_ELENCO_MODIFICA,
  PATH_TO_BO_POLIZZE_ELENCO_RIAPRI,
  PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI,
  PATH_TO_BO_POLIZZE_ELENCO_RIGETTA,
  PATH_TO_BO_POLIZZE_ELENCO_VISUALIZZA,
  RO_CONS_ASSI,
  RO_CONS_POLI,
  RO_GEST_POLI,
  RO_VISUAL_POLI
} from 'src/app/utils/RoutesConstants'
import { MessaggiErroreUtente } from 'src/errorBoundary/ErrorConstants'
import { filterableColums } from 'src/processes/assicurazioni/CostantiPolizze'


const breadcrumbOptions = [
  { label: "Polizze ", link: PATH_TO_BO_POLIZZE_ELENCO },
  { label: "Elenco polizze", link: PATH_TO_BO_POLIZZE_ELENCO }
]

const columnsNames = [
    /* 0*/ 'Tipologia',
    /* 1*/ 'Data invio richiesta',
    /* 2*/ 'Ordine',
    /* 3*/ 'Nome e Cognome o Ragione sociale',
    /* 4*/ 'Tipologia iscritto',
    /* 5*/ 'Fascia',
    /* 6*/ 'Contributo assicurativo',
    /* 7*/ 'Massimale',
    /* 8*/ 'Data inizio',
    /* 9*/ 'Data fine',
    /*10*/ 'Stato richiesta',
    /*11*/ 'Polizza',
    /*12*/ 'PDF',                       /*nascosta*/
    /*13*/ 'Data creazione richiesta',  /*nascosta*/
    /*14*/ 'Data conferma',             /*nascosta*/
    /*15*/ 'N. iscrizione',             /*nascosta*/
    /*16*/ 'PIVA/CF',                   /*nascosta*/
    /*17*/ 'Prog.',                     /*nascosta*/
];

const filtersToHide = [5,6,7,8,9,11,12,13,14,15,16,17]
let columnsToHide = [11, 13, 14, 15, 16, 17]
//let filtersToHide = [5, 6, 7, 8 ] //,8,9, 11, 13, 14, 15, 16, 17
let columnsTypes = [
  {
    inputColumnID: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    dropdownColumnID: [1, 3, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17],
    dateColumnID: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
  }
];
const CustomBreadcrumb = () => {
    return (
      <> <GenericBreadCrumb key="genericTab" paths={breadcrumbOptions} /></>
    )
}

let listaStati = [
  {idStato: '1', descrizioneStato: 'DA VALUTARE', label: 'DA VALUTARE', selected: true },
  {idStato: '1', descrizioneStato: 'DA VALUTARE (Aum. Massimale)', label: 'DA VALUTARE', selected: true },
  {idStato: '2', descrizioneStato: 'CONFERMATA', label: 'CONFERMATA', selected: false },
  {idStato: '2', descrizioneStato: 'CONFERMATA (Aum. Massimale)', label: 'CONFERMATA', selected: false },
  {idStato: '3', descrizioneStato: 'RIFIUTATA', label: 'RIFIUTATA', selected: false },
  {idStato: '3', descrizioneStato: 'RIFIUTATA (Aum. Massimale)', label: 'RIFIUTATA', selected: false },
  {idStato: '4', descrizioneStato: 'RICHIESTA INTEGRAZIONE', label: 'RICHIESTA INTEGRAZIONE', selected: false },
  {idStato: '4', descrizioneStato: 'RICHIESTA INTEGRAZIONE (Aum. Massimale)', label: 'RICHIESTA INTEGRAZIONE', selected: false },
  {idStato: '5', descrizioneStato: 'ARCHIVIATA', label: 'ARCHIVIATA', selected: false },
  {idStato: '5', descrizioneStato: 'ARCHIVIATA (Aum. Massimale)', label: 'ARCHIVIATA', selected: false },
  {idStato: '6', descrizioneStato: 'CANCELLATA', label: 'CANCELLATA', selected: false },
  {idStato: '6', descrizioneStato: 'CANCELLATA (Aum. Massimale)', label: 'CANCELLATA', selected: false },
  {idStato: '1,2,3,4,5,6', descrizioneStato: 'TUTTI GLI STATI', selected: false },
]

let listaStati2 = [
  {idStato: '1', descrizioneStato: 'DA VALUTARE', selected: true },
  {idStato: '2', descrizioneStato: 'CONFERMATA', selected: false },
  {idStato: '3', descrizioneStato: 'RIFIUTATA', selected: false },
  {idStato: '4', descrizioneStato: 'RICHIESTA INTEGRAZIONE', selected: false },
  {idStato: '5', descrizioneStato: 'ARCHIVIATA', selected: false },
  {idStato: '6', descrizioneStato: 'CANCELLATA', selected: false },
  {idStato: '1,2,3,4,5,6', descrizioneStato: 'TUTTI GLI STATI', selected: false },
]
export default class ElencoPolizze extends React.Component<any, any>{
  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      selectedRow: {},
      rowsNames: [],
      itemsPageCount: 10,
      filterAndPaginationData: "",
      dropdownList: [],
      certificateLink: [],
      hideModificaPolizza: true,
      hideCancellaPolizza: true,
      hideRiapriPolizza: true,
      hideRigettaPolizza: true,
      hideRichiediIntegrazionePolizza: true,
      hideConfermaPolizza: true,
      modalOpen:false,
      modalText:"testo finestra modale",
      modalTitle:"titolo finestra modale"
    }
  }
  nestedDataInFilter = []

  //elenco dei filtri
  preFilteredParams = filterableColums;

  async componentDidMount() {
    const specificGroup = TokenStore.getInstance().getCurrentAccount().accounts[Object.keys(TokenStore.getInstance().getCurrentAccount().accounts)[0]].specificGroup
    const genericGroup = TokenStore.getInstance().getCurrentAccount().accounts[Object.keys(TokenStore.getInstance().getCurrentAccount().accounts)[0]].genericGroup
    if(TokenStore.getInstance().isCurrentAccountODAF()) {
      columnsTypes = [
        {
          inputColumnID: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
          dropdownColumnID: [1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17],
          dateColumnID: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        }
      ];
    }
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    await getOrdini().then((response: any) => {
        var ordiniTerritoriali = [{ label: [], value: [], selected: false }];
        if (response) response.data.map((ordine, id) => {
            ordiniTerritoriali[id] = {
                label: [ordine['denominazioneGruppo']],
                value: [ordine['codiceGruppo']],
                selected: false,
            };
        })
        ordiniTerritoriali = ordiniTerritoriali.sort((p1, p2) => (p1.label > p2.label) ? 1 : (p1.label < p2.label) ? -1 : 0);

        this.setState({ listaOrdini: ordiniTerritoriali })

        this.nestedDataInFilter = [
          [{ label: ['Collettiva'], selected: false }, { label: ['Individuale'], selected: false }, { label: ['Aum. Massimale'], selected: false }],            //Tipologia polizza
          [], //Data invio richiesta
          ordiniTerritoriali, //Sigla provincia ordine (LE, BA, ..)
          [], //Nominativo
          [{ label: ['Persona Fisica'], selected: false }, { label: ['Persona Giuridica'], selected: false }],  // Tipo utente
          [], //Fascia
          [], //Importo
          [], //Massimale
          [], //Data inizio
          [], //Data fine
          listaStati2.map(stato => ({ label: [stato.descrizioneStato], selected: stato.selected })),               // Stato
        ]
    })

    if(genericGroup !== GROUP_AUTHENTICATED && genericGroup !== GROUP_CONAF) {
       getOrdineCompetenteOperatorePOST(specificGroup).then( (responseOrdine: any) => {
        if(responseOrdine.data) {

              let codiceOrdineCompetente = responseOrdine.data//['LE']
               elencoPolizzeList(codiceOrdineCompetente, "", 10, listaStati, 10, this.preFilteredParams, "").then(async (response: any) => {

                    let elencoPolizzeList;
                    elencoPolizzeList = JSON.parse(response)
                    let filterAndPaginationData = { pageCount: elencoPolizzeList.pageCount, itemsPageCount: elencoPolizzeList.size, totalPageCount: elencoPolizzeList.totalCount,listaStati: listaStati, codiceProvinciaResidenza:codiceOrdineCompetente, nestedDataInFilter: this.nestedDataInFilter }
                    await this.setState({rowsNames: elencoPolizzeList.content,filterAndPaginationData: filterAndPaginationData })
                    await this.setState({isLoaded : true})
              })
              .catch((err:any) => {
                this.showModal(true, "Errore", "Errore durante il caricamento della lista delle polizze. ");
              })
          }
      })
    } else {
       elencoPolizzeList(undefined,"", 10, listaStati, 10, this.preFilteredParams, "").then(async (response: any) => {

         if(response) {
              let elencoPolizzeList;
              elencoPolizzeList = JSON.parse(response)

              let filterAndPaginationData = { pageCount: elencoPolizzeList.pageCount, itemsPageCount: elencoPolizzeList.size, totalPageCount: elencoPolizzeList.totalCount,listaStati: listaStati, codiceProvinciaResidenza:undefined, nestedDataInFilter: this.nestedDataInFilter }
              await this.setState({rowsNames: elencoPolizzeList.content,filterAndPaginationData: filterAndPaginationData })
            }
            await this.setState({isLoaded : true})
      })
      .catch((err:any) => {

        this.showModal(true, "Errore", "Errore durante il caricamento della lista delle polizze. " + err.message);
      })
    }

  }

  loadingFunction = () => {
    this.componentDidMount()
  };


hideStateOptions = (tableRows) => {
  let genericGroup = TokenStore.getInstance().getCurrentAccount().accounts[Object.keys(TokenStore.getInstance().getCurrentAccount().accounts)[0]].genericGroup;
  var columnIndex = columnsNames.indexOf("Stato richiesta")
  var codiceStato = convertStatoToCodiceStato(tableRows.data[columnIndex], listaStati);
  codiceStato = codiceStato ? codiceStato[0] : undefined
  var columnIndexTipologia = columnsNames.indexOf("Tipologia")
  var tipologiaPolizza = tableRows.data[columnIndexTipologia];

  this.setState({
    hideModificaPolizza: true,
    hideCancellaPolizza: true,
    hideRiapriPolizza: true,
    hideRigettaPolizza: true,
    hideRichiediIntegrazionePolizza: true,
    hideConfermaPolizza: true
  })

  if(TokenStore.getInstance().currentAccountHasRolesAND([RO_VISUAL_POLI]) && !TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_POLI, RO_CONS_POLI, RO_CONS_ASSI])) {
    //L'utente può solo visualizzare la lista e non può fare alcuna azione di dettaglio
    this.setState({
      hideVisualizzaPolizza: true
    })
  }
  //il gruppo dell'utente e del fodaf non può fare altro se non visualizzare la polizza
  if(genericGroup === GROUP_ODAF || genericGroup === GROUP_FODAF || (genericGroup === GROUP_CONAF && !TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_POLI]))) {
    this.setState({ //DA VALUTARE
      hideConfermaPolizza: true,
      hideRigettaPolizza: true,
      hideRichiediIntegrazionePolizza: true,
      hideCancellaPolizza: true,
      hideModificaPolizza: true
    });
  }
  //il gruppo conaf può fare questo
  else if(genericGroup === GROUP_CONAF){
        switch (codiceStato) {

          case "0": this.setState({ //IN COMPILAZIONE
            hideConfermaPolizza: true,
            hideRigettaPolizza: true,
            hideRichiediIntegrazionePolizza: true,
            hideCancellaPolizza: false,
            hideModificaPolizza: true
          });
          break;

            case "1": this.setState({ //DA VALUTARE
              hideConfermaPolizza: false,
              hideRigettaPolizza: false,
              hideRichiediIntegrazionePolizza: false,
              hideCancellaPolizza: false,
              hideModificaPolizza: false
            });
            break;
          case "2": this.setState({ //CONFEERMATO
              hideCancellaPolizza: false,
              hideModificaPolizza: false,

            });
            break;
          case "3": this.setState({ //RIGETTATO
              hideCancellaPolizza: false,
            });
            break;
          case "4": this.setState({ //RICHIESTA INTEGRAZIONI
            hideCancellaPolizza: false,


            });
            break;
          case "5": this.setState({
            hideRiapriPolizza: false,
            hideCancellaPolizza: false,
          });
          break;

          default: this.setState({
            hideModificaPolizza: true,
            hideCancellaPolizza: true,
            hideRiapriPolizza: true,
            hideRigettaPolizza: true,
            hideRichiediIntegrazionePolizza: true,
            hideConfermaPolizza: true
        });
          break;
      }

      if(tipologiaPolizza === 'Individuale') {
        this.setState({ hideModificaPolizza: true });
      }
  }
  //accede al backend può solo visualizzare
  else{
    this.setState({ //DA VALUTARE
      hideConfermaPolizza: true,
      hideRigettaPolizza: true,
      hideRichiediIntegrazionePolizza: true,
      hideCancellaPolizza: true,
      hideModificaPolizza: true
    });
  }


}
goToElencoPolizze = (redirectLink: any) => {
  this.props.history.push({
      pathname: redirectLink,
  });
}

downloadAllegato = async (type: any, row?:any) => {
    await this.setState({ selectedRow: row })

    if(type === 'certificato') {
        //*   Recupero del certificato    */
        await recuperaCertificato(this.state.selectedRow.data[11]).then(response => {

            if(response["content"] === undefined || response["content"] === null || response["content"] === ""){
                //alert("File non presente per la polizza selezionata");
              this.showModal(true, "Attenzione","File non presente per la polizza selezionata");
            }
            else {
                let linkSource = "data:application/pdf;base64,"+response["content"];
                const downloadLink = document.createElement("a");
                //const fileName = this.state.selectedRow.data[0]+".pdf";
                const fileName = "certificato_"+response["name"]+".pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
          let pdfAsDataUri :any = "data:application/pdf;base64,"+response["content"];
          window.open(pdfAsDataUri,"_blank");
          })
          .catch(err=>{
            this.showModal(true, "Errore", MessaggiErroreUtente.errore_downlaod_file);

          })
    }
    else if(type === 'copia_pagamento') {
      //*   Recupero della copia del pagamento    */
      await recuperaCopiaPagamento(this.state.selectedRow.data[11]).then(response => {

          if(response["content"] === undefined || response["content"] === null || response["content"] === ""){
              //alert("File non presente per la polizza selezionata");
            this.showModal(true, "Attenzione","File non presente per la polizza selezionata");
          }
          else {
              let linkSource = "data:application/pdf;base64,"+response["content"];
              const downloadLink = document.createElement("a");
              //const fileName = this.state.selectedRow.data[0]+".pdf";
              const fileName = "copia_pagamento_"+response["name"]+".pdf";
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
          }
        let pdfAsDataUri :any = "data:application/pdf;base64,"+response["content"];
        window.open(pdfAsDataUri,"_blank");
        })
        .catch(err=>{
          this.showModal(true, "Errore", MessaggiErroreUtente.errore_downlaod_file);

        })
  }
  }

closeModal = () => {
    this.setState({modalOpen:false});
}

showModal = async (booleanValue, title,text) => {
    await this.setState({
        modalOpen:booleanValue,
        modalTitle: title,
        modalText:text,
        isLoaded: true,
    });
}

  //Funzione che consente di settare l'elemento selezionato all'interno di ogni select dei filtri.
  onSelectOptionItem = async (e: any, index: any) => {
    let selectedValue =  !e.label ? e.target.value : e.label[0];
    let filterOptions = this.state.filterAndPaginationData;
    filterOptions.nestedDataInFilter[index].map(item => item.selected = false) // set della opzione precedente a false
    filterOptions.nestedDataInFilter[index].find(item => item.label[0] === selectedValue).selected = true;
    this.setState({filterAndPaginationData: filterOptions })
  }

render() {
    const { rowsNames, filterAndPaginationData } = this.state;
    var title = "Elenco polizze"

    const onSelectRow = async (row: any) => {
      this.hideStateOptions(row)
      this.setState({ selectedRow: row })
    }

    const getPathnameByItemTitle = (title) => {
      if (title.toLowerCase() === 'visualizza') {
        return PATH_TO_BO_POLIZZE_ELENCO_VISUALIZZA
      }

      if (title.toLowerCase() === 'accetta') {
        return PATH_TO_BO_POLIZZE_ELENCO_CONFERMA
      }

      if (title.toLowerCase() === 'rifiuta') {
        return PATH_TO_BO_POLIZZE_ELENCO_RIGETTA
      }

      if (title.toLowerCase() === 'riapri') {
        return PATH_TO_BO_POLIZZE_ELENCO_RIAPRI
      }

      if (title.toLowerCase() === 'richiedi integrazione') {
        return PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI
      }

      if (title.toLowerCase() === 'cancella') {
        return PATH_TO_BO_POLIZZE_ELENCO_CANCELLA
      }

      if (title.toLowerCase() === 'modifica') {
        return PATH_TO_BO_POLIZZE_ELENCO_MODIFICA
      }
      return null
    }

    const onSelectAction = (event) => {
        const title = event.target.textContent
        this.props.history.push({
          pathname: getPathnameByItemTitle(title),
          state: { action: title, row: this.state.selectedRow, column: columnsNames, idPolizza: this.state.selectedRow.data[11], nome:this.state.selectedRow.data[4], cognome:this.state.selectedRow.data[3] }
        });
    }



   return(
      <div key={"elencoPolizzeBO"} >
      <div className='container-fluid p-5'>
            <PageSection>
              <div>
                <CustomBreadcrumb key="generic"></CustomBreadcrumb>
                <Title headingLevel="h1" size="4xl">{title}</Title><br></br>

                {(
                  (this.state.isLoaded) ?
                  <div>
                      <GenericTable
                            hideCsv={!TokenStore.getInstance().isBackOffice() && !TokenStore.getInstance().currentAccountHasRolesOR(['RO_CONS_POLI', 'RO_GEST_POLI']) }
                            tipoElenco={'assicurazioni'}
                            methodNameCall={elencoPolizzeList}
                            methodNameCallForCsvRaw={elencoPolizzeListCsv}
                            downloadFunction={(type, e) => this.downloadAllegato(type, e)}
                            columnsTypes={columnsTypes}
                            columnsToHide={columnsToHide}
                            filtersToHide={filtersToHide}
                            isEditableTable={false}
                            filterAndPaginationData={filterAndPaginationData}
                            columns={columnsNames}
                            perPageNumber={this.state.itemsPageCount}
                            rows={rowsNames}
                            hideSearch={true}
                            hideDropdown={true}
                            hideActionColumn={false} onSelect={(e) => onSelectRow(e)}
                            actions={[{ label: "Aggiorna", function: '' }]} autoWidthColumns={false}
                            fitContentType={'initial'}
                            tableHeadFontSize="1em"
                            tableContentFontSize="1em"
                            className={'pf-c-table thead pf-c-table pf-m-truncate big-table'}
                            thClassName={'pf-c-table__text big-table'}
                            variant={'compact'}
                            selectOptionItem={(e, index) => this.onSelectOptionItem(e, index)}
                            preFilteredTable={this.preFilteredParams}
                            dropdownList={[
                              { title: 'Visualizza', onClick: (e) => onSelectAction(e), itemKey: 'visualizza', hidden: this.state.hideVisualizzaPolizza},
                              { title: 'Accetta', onClick: (e) => onSelectAction(e), itemKey: 'conferma', hidden: this.state.hideConfermaPolizza },
                              { title: 'Rifiuta', onClick: (e) => onSelectAction(e), itemKey: 'rigetta', hidden: this.state.hideRigettaPolizza },
                              { title: 'Riapri', onClick: (e) => onSelectAction(e), itemKey: 'riapri', hidden: this.state.hideRiapriPolizza },
                              { title: 'Richiedi integrazione', onClick: (e) => onSelectAction(e), itemKey: 'richiediIntegrazione', hidden: this.state.hideRichiediIntegrazionePolizza },
                              { title: 'Cancella', onClick: (e) => onSelectAction(e), itemKey: 'cancella', hidden: this.state.hideCancellaPolizza },
                              { title: 'Modifica', onClick: (e) => onSelectAction(e), itemKey: 'modifica',hidden: this.state.hideModificaPolizza }]}
                        />
                        <GenericModal
                                title={this.state.modalTitle}
                                text={this.state.modalText}
                                modalOpen={this.state.modalOpen}
                                closeButton={this.closeModal}
                        />



                    </div>
                    : <GenericSpinner />
                  )
                }
              </div>
            </PageSection>

      </div>
      </div>
   )
  }
};

export declare interface ElencoPolizzeProps {
  activeTab?: any;
}

