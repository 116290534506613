import { getDefaultAxiosConfig, getTipologiaIscrizioneResourcesApi } from 'src/app/utils/ManagerRestGateway'

export async function getTipologieIscrizione() {
  try {
    const response = await getTipologiaIscrizioneResourcesApi().mswfTipologiaIscrizioneGet(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata mswfTipologiaIscrizioneGet - " + err);
    throw new Error("Errore durante la chiamata mswfTipologiaIscrizioneGet - " + err);
  }
}
