import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'design-react-kit'
import React from 'react'

class GenericCard extends React.Component<GeneralCardProps, any>{
    constructor(props) {
        super(props);
        this.state = {
            hiddenHeader: props.hiddenHeader
        }
    }


    render() {
        const bodyCnt = this.props.children?this.props.children:this.props.cardBody;
        const className = 'card-bg '+(this.props.className?this.props.className:"");
        return (
            <Row>
                <Col>
                    <Card style={{ paddingBottom: '20px' }} spacing className={className}>
                        <CardHeader hidden={this.props.hiddenHeader}>
                            <CardTitle tag='h5'>
                                {this.props.cardHeader}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {bodyCnt}
                        </CardBody>
                        {this.props.cardFooter ?
                            <CardFooter>
                                {this.props.cardFooter}
                            </CardFooter> : null}
                    </Card>
                </Col>
            </Row>
        );
    }
}

export declare interface GeneralCardProps {
    cardHeader?: any;
    cardBody?: any;
    cardFooter?: any;
    hiddenHeader?: boolean;
    className?:string;
}

export default GenericCard;
