import React, { useRef, useState } from 'react'
import { Form, Label } from 'design-react-kit'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { createFormData, handleChangeFormData } from 'src/app/utils/form-utils'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import { useHistory } from 'react-router-dom'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormApprovazione: React.FunctionComponent<FormApprovazioneProps> = ({ id, sdaf_da_approvare }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()

  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const history = useHistory()
  const formData = createFormData(data, setData, errors, setErrors)
  const rules = {
    protocollo: {
      isRequired: true,
    },
  }

  const onInitInput = (cmp, tab) => {
    refForm.current.push({
      tab: 0,
      cmp: cmp,
    })
  }
  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, rules)
  }

  const submit = async (e) => {
    try {
      setStatus(STATUS.LOADING)
      await getFormazioneResourceApi().msfoFormazioneApprovaFormatorePatch(
        {
          idFormatore: id,
          protocollo: data.protocollo,
          sdafDaApprovare: data.sdafDaApprovare.map((sdaf) => sdaf.value),
        },
        getDefaultAxiosConfig()
      )
      setStatus(STATUS.SUCCESS)
      setAlertMessage('Dati salvati con successo')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (e) {
      setStatus(STATUS.FAILURE)
      setAlertMessage('Impossibile salvare i dati')
    }
  }

  return (
    <>
      <FormSubmit title="Approvazione" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
        <Label>Per approvare formalmente l'agenzia cliccare "Conferma".</Label>
      </FormSubmit>
      <GenericCard cardHeader={'Informazioni approvazione'}>
        <Form>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericInput
                id="protocollo"
                type="text"
                label="Protocollo delibera"
                suffix="**"
                value={data.protocollo}
                onChange={handleInput}
                onInit={onInitInput}
                errorMessage={errors?.protocollo}
              />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <GenericSelect
                defaultOptions={sdaf_da_approvare}
                isMulti={true}
                id="sdafDaApprovare"
                label="Sdaf da approvare"
                value={data.sdafDaApprovare}
                onChange={handleInput}
                onInit={onInitInput}
                errorMessage={errors?.sdafDaApprovare}
              />
            </div>
          </div>
        </Form>
      </GenericCard>
    </>
  )
}

interface FormApprovazioneProps {
  id: any
  sdaf_da_approvare: any
}

export default FormApprovazione
