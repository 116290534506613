import React, { useMemo, useRef, useState } from 'react'
import { Button, Form } from 'design-react-kit'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericButton from 'src/frontend/app/components/GenericButton'
import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { Title } from '@patternfly/react-core'
import close from '../../../../images/x.png'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import { useSdaf } from 'src/hooks/useReqOptions'
import formValidationRules from './EspScientificaFormRules'
import GenericTextArea from 'src/frontend/app/components/Form/TextArea'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const FormEsperienzaScientifica: React.FunctionComponent<GeneralFormDatiAgenziaProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const refForm = useRef<any>([])
  const [dataForm, setData] = useState<any>({})
  const [errorsForm, setErrors] = useState<any>({})
  const formData = createFormData(dataForm, setData, errorsForm, setErrors)
  const objKey = ''
  const sdaf = useSdaf()

  const columns: Column[] = [
    {
      label: 'Ricerca/sperimentazione',
      field: 'ricerca',
      isSortable: false,
    },
    {
      label: 'Settore disciplinare',
      field: 'sdaf',
      isSortable: false,
      render: (row) => {
        return row?.sdaf?.cod
      },
    },
    { label: 'Svolta dal', field: 'da', isSortable: false },
    { label: 'al', field: 'al', isSortable: false },
    { label: 'Committente', field: 'committente', isSortable: false },
    { label: 'Descrizione attività', field: 'desc_attivita', isSortable: false },
  ]

  const handleInputChange = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onInit = (cmp, tab) => {
    refForm.current.push({
      cmp: cmp,
      tab: 0,
    })
  }

  const removeFromTitoli = (index) => {
    const datiSlice = [...data?.esp_scientifica].filter((elem, indice) => {
      return indice !== index
    })
    handleInput('esp_scientifica', datiSlice)
  }

  const getColumnValue = (column: any, row: any) => {
    if (column.field && row[column.field] !== undefined) {
      const value = row[column.field]
      if (column.options && column.options[value]) {
        return column.options[value]
      }
      return row[column.field]
    }
    return ''
  }

  const Spacer = () => (
    <>
      <br></br>
    </>
  )

  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInit, onChange: handleInputChange, viewOnly }), [])

  const aggiungiTitolo = () => {
    const inputs = getTabActiveFormId(refForm, 0)
    const isValid = validateFormData(formData, formValidationRules, inputs)
    if (!isValid) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      return
    }

    setData({})
    if (data?.esp_scientifica) {
      handleInput('esp_scientifica', [...data?.esp_scientifica, dataForm])
    } else {
      handleInput('esp_scientifica', [dataForm])
    }
  }

  return (
    <GenericCard cardHeader={title}>
      {data?.esp_scientifica?.length > 0 && data?.esp_scientifica && (
        <>
          <Title headingLevel="h2" size="lg">
            Esperienze :
          </Title>
          <Spacer></Spacer>
          <TableComposable aria-label="Sort table">
            <Thead>
              <Tr>
                {columns.map((column, index) => {
                  return !column.hidden ? (
                    <Th key={`head${index}`} style={column.style || {}}>
                      {column.label}
                    </Th>
                  ) : (
                    <></>
                  )
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data?.esp_scientifica.map((row, index) => {
                return (
                  <Tr key={`row${index}`}>
                    {columns.map((column, index) => {
                      return !column.hidden ? (
                        <Td key={`col${index}`} style={{ ...(column.style || {}), maxWidth: 300 }}>
                          {column.render && column.render(row)}
                          {!column.render && getColumnValue(column, row)}
                        </Td>
                      ) : (
                        <></>
                      )
                    })}
                    {!viewOnly && (
                      <Td>
                        <Button
                          onClick={() => {
                            removeFromTitoli(index)
                          }}
                        >
                          <img src={close} alt="" />
                        </Button>
                      </Td>
                    )}
                  </Tr>
                )
              })}
            </Tbody>
          </TableComposable>
          <Spacer></Spacer>
        </>
      )}
      {!viewOnly && (
        <Form>
          <Title headingLevel="h2" size="lg">
            Inserisci nuova esperienza scientifica:
          </Title>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput as={GenericInput} type={'text'} id="ricerca" label="Ricerca/sperimentazione" suffix="*" data={dataForm} errors={errorsForm} />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput
                as={GenericSelect}
                id={'sdaf'}
                placeholder="Seleziona settore disciplinare"
                label="Settore disciplinare"
                suffix="*"
                defaultOptions={sdaf}
                data={dataForm}
                errors={errorsForm}
              />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput as={GenericInput} id="da" label="Da" suffix="*" data={dataForm} errors={errorsForm} type={'date'} />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput as={GenericInput} id="al" label="Al" suffix="*" data={dataForm} errors={errorsForm} type={'date'} />
            </div>
          </div>
          <div className="row">
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput as={GenericInput} id="committente" label="Committente" suffix="*" data={dataForm} errors={errorsForm} type={'text'} />
            </div>
            <div className={isCurrentMobileDevice() ? null : 'col-6'}>
              <WrpInput as={GenericTextArea} id="desc_attivita" label="Descrizione attività" suffix="*" data={dataForm} errors={errorsForm} />
            </div>
          </div>

          <div className="row">
            <GenericButton
              label={'Aggiungi'}
              color={'primary'}
              onClickEvent={(e) => {
                e.preventDefault()
                aggiungiTitolo()
              }}
            />
          </div>
        </Form>
      )}
    </GenericCard>
  )
}

interface GeneralFormDatiAgenziaProps {
  data: any
  errors: any
  title: string
  handleInput?: any
  onInitInput?: any
  viewOnly?: boolean
}

export declare interface Column {
  label: string
  width?: boolean
  style?: any
  hidden?: boolean
  isSortable?: boolean
  field: string
  render?: any
  options?: any
}

export default FormEsperienzaScientifica
