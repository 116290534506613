import React from 'react'
import GenericInput from './GenericInput'

class GenericDataPicker extends React.Component<GeneralDataPickerProps, any>{
    render() {
        return (
            <>
                <GenericInput
                    isDisabled={this.props.isDisabled}
                    type="date"
                    value={this.props.value}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    defaultValue={this.props.defaultValue}
                    isRequired={this.props.isRequired}
                    isHidden={this.props.isHidden}
                    errorMessageAlert={this.props.errorMessageAlert}
                    currentStep={this.props.currentStep}
                    label={this.props.label}
                />
            </>
        );
    }
}

export declare interface GeneralDataPickerProps {
    label?: any;
    id?: any;
    placeholder?: any;
    value?: any;
    defaultValue?: any;
    onChange?: any;
    isDisabled?: any;
    isRequired?: boolean;
    isHidden?: boolean;
    errorMessageAlert?: any;
    currentStep?: number;
}

export default GenericDataPicker;
