import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { Label } from 'design-react-kit'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from 'src/frontend/app/components/GenericTab'

import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { requestMapperAgenzia, responseMapperAgenzia } from 'src/frontend/app/pages/iscrizioneAlboEF/form-utils'
import { STATI_AGENZIE_VALUES } from 'src/formazione'

// Form components
import formValidationRules from './FormRules'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import FormDatiSedeLegale from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiSedeLegale'
import FormDatiRappresentanteLegale
  from 'src/frontend/app/pages/iscrizioneAlboEF/formIscrizioneEF/FormDatiRappresentanteLegale'
import FormDatiComitatoTecnico from './FormDatiComitatoTecnico'
import FormDatiAccreditamento from './FormDatiAccreditamento'
import FormDatiDichiarazioni from './FormDatiDichiarazioni'
import FormDatiAgenzia from './FormDatiAgenzia'
import FormDatiAllegati from './FormDatiAllegati'
import { PageSection, Title } from '@patternfly/react-core'

const title = 'Creazione rivista'
const listNavLink = [
  { id: 1, label: 'Dati Anagrafici' },
  { id: 2, label: 'Sede Legale' },
  { id: 3, label: 'Rappresentante Legale' },
  { id: 4, label: 'Dati Accreditamento' },
  { id: 5, label: 'Dati sul Comitato Tecnico Scientifico' },
  // { id: 6, label: 'Dati Formatori' },
  { id: 6, label: 'Dichiarazioni' },
  { id: 7, label: 'Allegati' },
  { id: 8, label: 'Conferma' },
]

const breadcrumbOptions = [
  { label: 'Scelta categoria', link: '/' },
  { label: 'creazione Rivista', link: '/' },
]
const FormRiviste: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const tab = searchParams.get('tab')

  // Tab component state
  const [activeTab, setActiveTab] = useState(parseInt(tab || '1'))
  const [message, setMessage] = useState('')

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  // Submit state
  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  // update tab in search params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', `${activeTab}`)
    history.replace({ search: searchParams.toString() })
  }, [activeTab])

  // retrieve data from api
  useEffect(() => {
    const retrieveData = async () => {
      const { data } = await getFormazioneResourceApi().msfoFormazioneDettaglioAccAgenziaFormativaGet(id as any, getDefaultAxiosConfig())
      const obj = await responseMapperAgenzia(data)
      setData(obj)
    }

    // before the api retrieve the data from local storage
    const dataString = localStorage.getItem(`${id}`)
    const _data = dataString ? JSON.parse(dataString) : {}
    const keyData = TokenStore.getInstance().getDecodedTokenJWT()
    _data.datiRappresentanteLegale = {
      ...(_data.datiRappresentanteLegale || {}),
      nome: keyData?.given_name || ' ',
      cognome: keyData?.family_name || ' ',
      codiceFiscale: keyData?.fiscalNumber || keyData?.spid_fiscalNumber || ' ',
      luogoNascita: keyData?.spid_placeOfBirth || ' ',
      dataNascita: keyData?.spid_dateOfBirth || '',
    }

    setData(_data)
    // if (id) retrieveData().catch(console.error)
  }, [id])

  // save data in local storage
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem(`${id}`, JSON.stringify(data))
    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [id, data])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({
      tab: tab,
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onNextStep = useCallback(
    (e) => {
      e.preventDefault()
      const inputs = getTabActiveFormId(refForm, activeTab - 1)
      const isValid = validateFormData(formData, formValidationRules, inputs)
      if (!isValid) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }
      setMessage('')
      setActiveTab((tab) => tab + 1)
    },
    [activeTab, formData]
  )

  const previousStep = () => {
    if (reqStatus === STATUS.LOADING) {
      return
    }
    setReqStatus(null)
    setReqMessage(null)
    setActiveTab((tab) => tab - 1)
  }

  const onSubmit = useCallback(async () => {
    try {
      setReqStatus(STATUS.LOADING)

      const { data } = formData || {}
      data.tipoAgForm = 2
      const _data = await requestMapperAgenzia(data)
      _data.datiAccreditamento.stato = STATI_AGENZIE_VALUES.ISTRUTTORIA
      _data.datiAccreditamento.tipo_ag_form = 2

      await getFormazioneResourceApi().msfoFormazioneAccAgenziaFormativaPost(_data, getDefaultAxiosConfig())
      // localStorage.removeItem(`${id}`)
      setReqStatus(STATUS.SUCCESS)
      setReqMessage('Richiesta inviata con successo')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (e) {
      setReqStatus(STATUS.FAILURE)
      setReqMessage("Errore durante l'invio della richiesta")
    }
    return null
  }, [id, formData])

  return (
    <div className="container register-container">
      <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
      <div>
        <h1>{title}</h1>
      </div>
      <GenericTab
        hiddenAlert={message === ''}
        labelAlert={message}
        isSubmitted={false}
        onSubmit={onNextStep}
        previousStep={previousStep}
        activeTab={activeTab}
        listNavLink={listNavLink}
        listBodyTab={[
          {
            id: 1,
            body: <FormDatiAgenzia title="Dati Anagrafici" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} />,
          },
          {
            id: 2,
            body: <FormDatiSedeLegale title="Sede Legale" data={data} errors={errors} onInitInput={onInitInput(1)} handleInput={handleInput} />,
          },
          {
            id: 3,
            body: (
              <FormDatiRappresentanteLegale title="Rappresentante Legale" data={data} errors={errors} onInitInput={onInitInput(2)} handleInput={handleInput} />
            ),
          },
          {
            id: 4,
            body: <FormDatiAccreditamento title="Dati Accreditamento" data={data} errors={errors} onInitInput={onInitInput(3)} handleInput={handleInput} />,
          },
          {
            id: 5,
            body: <FormDatiComitatoTecnico title="Dati comitato tecnico" data={data} errors={errors} onInitInput={onInitInput(4)} handleInput={handleInput} />,
          },
          {
            id: 6,
            body: <FormDatiDichiarazioni title="Dichiarazioni" data={data} errors={errors} onInitInput={onInitInput(5)} handleInput={handleInput} />,
          },
          {
            id: 7,
            body: <FormDatiAllegati title="Allegati" data={data} errors={errors} onInitInput={onInitInput(6)} handleInput={handleInput} />,
          },
          {
            id: 8,
            body: (
              <FormSubmit title="Invia richiesta" buttonLabel="Invia Richiesta" alertMessage={reqMessage} status={reqStatus} onSubmit={onSubmit}>
                <Label>Per confermare e inviare la domanda cliccare "Invia Richiesta".</Label>
              </FormSubmit>
            ),
          },
        ]}
      />
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
    </div>
  )
}

export default FormRiviste
