import moment from 'moment'

const obj = {
  regex: [
    {
      regex: /datiAnagrafici.*(?<!piva)(?<!fax)$/,
      rules: {
        isRequired: true,
      },
    },
    {
      regex: /datiSedeLegale.*/,
      rules: {
        isRequired: true,
      },
    },
  ],
  'datiAnagrafici.codiceFiscale': {
    isFiscalCode: true,
  },
  'datiAnagrafici.piva': {
    isPartitaIva: true,
  },
  'datiAnagrafici.email': {
    isEmail: true,
  },
  'datiAnagrafici.pec': {
    isEmail: true,
  },
  'datiAnagrafici.telefono': {
    isTelefono: true,
  },
  'datiAnagrafici.fax': {
    isFax: true,
  },
  'datiSedeLegale.cap': {
    isCap: true,
  },
  'datiSedeLegale.codiceFiscaleGestore': {
    isFiscalCodePhysicalPerson: true,
  },
  'datiSedeLegale.dataFine': {
    _custom: {
      check: (code, rule, data) => {
        if(data.datiSedeLegale.dataInizio) {
          return moment(data.datiSedeLegale.dataInizio).diff(data.datiSedeLegale.dataFine) <= 0
        } else {
          return moment().diff(data.datiSedeLegale.dataFine) <= 0
        }
      }
    }
  },
}

export default obj;
