import React from 'react'

class InputGroup extends React.Component<InputGroupProps, any> {
  render() {
    const classWrap = [this.props.classWrap, this.props.type + '-group']
    if (this.props.rows) classWrap.push(' row-' + this.props.rows)
    if (this.props.type !== 'checkbox') classWrap.push('form-group')
    const errorMessage = this.props.hideErrorMessage ? null : this.props.errorMessage
    const classLabel = this.props.classLabel ? this.props.classLabel : 'active'
    const classGroup = this.props.classGroup ? this.props.classGroup : 'input-group'
    return (
      <div hidden={this.props.isHidden} className={classWrap.join(' ')}>
        {errorMessage && <div className={'text-danger'}>{this.props.errorMessage}</div>}
        <div className={classGroup}>
          {this.props.prefix && (
            <div className="input-group-prepend">
              <div className="input-group-text">{this.props.prefix}</div>
            </div>
          )}
          {this.props.children}
          {this.props.label && (
            <label htmlFor={this.props.id} className={classLabel}>
              {this.props.label}
            </label>
          )}
          {this.props.suffix && (
            <div className="input-group-append">
              <div className="input-group-text">{this.props.suffix}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export declare interface InputGroupProps {
  errorMessage?: string
  type?: any
  placeholder?: string
  id?: string
  name?: string
  className?: string
  label?: any
  isHidden?: boolean
  rows?: number

  prefix?: string

  suffix?: string

  hideErrorMessage?: boolean

  noWrapper?: boolean

  classWrap?: string
  classLabel?: string

  classGroup?: string
}

export default InputGroup
