import * as React from 'react'
import { Form } from '@patternfly/react-core'
import { GenericTextArea } from '../../../components/GenericTextArea'
import { valutaIscrizioneSTP } from 'src/processes/ProcessoIscrizione'
import GenericUploadFront from 'src/frontend/app/components/GenericUpload'
import { Label } from 'design-react-kit'
import { inputRequiredValidation } from 'src/utilities/utility'

export class FormRigettaIscrizioneSTP extends React.Component<GeneralFormRigettaIscrizioneSTPProps, any> {

    handleSubmit = async event => {
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var requiredFields = [
            { value: event.target.idMotivoRigettoSTP.value, label: 'Motivo del rigetto' },
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*----------------------------------------------------------------------------------------------------------------------------*/
        else {
            //chiamata al servizio per rigetta iscrizione STP
            var esitoValutazione = "rigettato"
            var idIscrizione = this.props.idIscrizione
            var idSoggetto = this.props.idSoggetto
            var fileDeliberaRigettoStp = this.state?.fileDeliberaRigettoStp ? [this.state.fileDeliberaRigettoStp] : []
            var parametriRigettaSTP = {
                motivoRigetto: event.target.idMotivoRigettoSTP.value ? event.target.idMotivoRigettoSTP.value : "",
            }

            try {
                event.preventDefault();
                await valutaIscrizioneSTP(esitoValutazione, idIscrizione, idSoggetto, parametriRigettaSTP, fileDeliberaRigettoStp)
                return this.props.messaggio({ messaggio: "Dati salvati con successo", type: "success" })
            }
            catch (err) {
                return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati", type: "danger" })
            }
        }
    }

    render() {

        const getDatiDeliberaRigettoStp = (fileData) => {
            this.setState({ fileDeliberaRigettoStp: fileData })
        }

        return (
            <div>
                <Form id='idRigettaIscrizioneSTP' onSubmit={this.handleSubmit}>
                    <div>
                        <GenericTextArea className='w-50' label={"Motivo del rigetto"} id={"idMotivoRigettoSTP"} ariaLabel={'textarea-motivoRigetto'} placeholder={"Inserire motivo"} isRequired />
                    </div>
                    <div>
                        <div className="row">
                            <Label><strong>Delibera rigetto</strong></Label>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <GenericUploadFront id={"idDeliberaRigettoStp"} datiAllegato={getDatiDeliberaRigettoStp} tipologiaAllegato={"delibera_rigetto_stp"} acceptedExtensionFile={['pdf']} />
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export declare interface GeneralFormRigettaIscrizioneSTPProps {
    messaggio?: any;
    idIscrizione?: any;
    idSoggetto?: any;
}
