import {
  CompilazioneAnagraficaIscrittoDTO,
  CompilazioneAnagraficaOConafDTO,
  CompilazioneAnagraficaOotDTO,
  CompilazioneAnagraficaOStpDTO,
  CompilazioneAnagraficaStpDTO,
  RecuperoIscrizioneByIdDTO,
  RecuperoIscrizioneInCompilazioneDTO,
  RichiestaIscrizioneIscrittoDTO,
  RichiestaIscrizioneOConafDTO,
  RichiestaIscrizioneOotDTO,
  RichiestaIscrizioneOStpDTO,
  RichiestaIscrizioneStpDTO,
  StatoIscrittoDTO,
  StatoStpDTO,
  StringParam,
  ValutazioneIscrizioneIscrittoDTO,
  ValutazioneIscrizioneStpDTO
} from '@parsec/mswf'
import {
  conversioneSiNoToInt,
  convertFlagLiberaProfPubblicaAmministrazioneToInt,
  getBooleanToConvertToBigInt
} from 'src/utilities/utility'
import {
  getAllegatoResourcesApi,
  getAnagrafeResourcesApi,
  getDefaultAxiosConfig,
  getIscrizioneCommonResourceApi,
  getIscrizioneOConafResourceApi,
  getIscrizioneOEEResourceApi,
  getIscrizioneOEFResourceApi,
  getIscrizioneOERResourceApi,
  getIscrizioneOOTResourceApi,
  getIscrizioneOrdineResourceApi,
  getIscrizioneOSTPResourceApi,
  getIscrizioneStpResourceApi,
  getProcessoIscrizioneResourceApi
} from 'src/app/utils/ManagerRestGateway'
import {
  CompilazioneAnagraficaOeeDTO,
  CompilazioneAnagraficaOefDTO,
  CompilazioneAnagraficaOerDTO,
  RichiestaIscrizioneOefDTO
} from '@parsec/msfo'
import {
  CodiceAtecoByIdDTO,
  ElencoIscrizioniByCfDTO,
  FormaGiuridicaByIdDTO,
  IscrizioneByCfRespStp,
  QuotaByOrdineDTO,
  RichiestaIntegrazioniByIdIscrizioneDTO,
  TipologiaSocietaByIdDTO
} from '@parsec/msga'
import { ErrorStore } from 'src/errorBoundary/ErrorStore'
import { PATH_TO_ERROR } from 'src/app/utils/RoutesConstants'

/**
 * Avvia il processo di iscrizione all'ordine.
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneOrdine(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneIscrittoDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };

    const response = await getProcessoIscrizioneResourceApi().mswfIscrizioneIscrittoFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {

    var msg = "Errore durante la richiesta di iscrizione all'ordine territoriale";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Avvia il processo di iscrizione STP
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneSTP(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneStpDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };

    const response = await getIscrizioneStpResourceApi().mswfIscrizioneStpFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la richiesta di iscrizione STP";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Avvia il processo di iscrizione dell' operatore ordine territoriale
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneOOT(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneOotDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };
    const response = await getIscrizioneOOTResourceApi().mswfIscrizioneOotFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la richiesta di iscrizione come operatore ordine territoriale";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Avvia il processo di iscrizione dell' operatore STP
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneOSTP(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneOStpDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };

    const response = await getIscrizioneOSTPResourceApi().mswfIscrizioneOstpFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la richiesta di iscrizione come operatore STP";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Avvia il processo di iscrizione dell' operatore OEF
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneOEF(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneOefDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };

    const response = await getIscrizioneOEFResourceApi().msfoIscrizioneOefFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la richiesta di iscrizione come operatore OEF";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Avvia il processo di iscrizione dell' operatore OEF
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneOEE(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneOefDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };

    const response = await getIscrizioneOEEResourceApi().msfoIscrizioneOeeFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la richiesta di iscrizione come operatore OEE";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Avvia il processo di iscrizione dell' operatore OEF
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneOER(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneOefDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };

    const response = await getIscrizioneOERResourceApi().msfoIscrizioneOerFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la richiesta di iscrizione come operatore OER";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Avvia il processo di iscrizione dell' operatore Conaf Nazionale
 * @param spidData
 * @returns
 */
export async function nuovaIscrizioneOConaf(spidData: any) {
  try {
    const richiestaIscrizione: RichiestaIscrizioneOConafDTO = {
      codiceFiscale: spidData.codiceFiscale ? spidData.codiceFiscale : null,
      nome: spidData.nome ? spidData.nome : null,
      cognome: spidData.cognome ? spidData.cognome : null,
      dataNascita: spidData.dataNascita ? spidData.dataNascita : null,
      sesso: spidData.sesso ? spidData.sesso : null
    };

    const response = await getIscrizioneOConafResourceApi().mswfIscrizioneOconafFoNuovaPost(richiestaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la richiesta di iscrizione come operatore CONAF";
    throw new Error(msg + " - " + err);
  }
}

/**
 * GET iscrizione in compilazione da cf e tipologiaIscrizione
 * @param cf
 * @param tipologiaIscrizione
 * @param tipoAnagrafe
 * @returns
 */
export async function recuperoIscrizioneInCompilazioneByCf(cf: any, tipologiaIscrizione: any, tipoAnagrafe: string) {
  try {
    var postObj: RecuperoIscrizioneInCompilazioneDTO = {
      "codiceFiscale": cf,
      "tipologiaIscrizioneDTO": tipologiaIscrizione,
      "tipoAnagrafe": tipoAnagrafe

    }
    const response = await getIscrizioneCommonResourceApi().mswfIscrizioneFoRecuperoIscrizioneInCompilazioneByCfPost(postObj, getDefaultAxiosConfig());
    //return ErrorStore.getInstance().checkResponseReturnResponse(response);
    return response;

  } catch (err: any) {
    var msg = "Errore durante il recupero dell'iscrizione by cf";
    throw new Error(msg + " - " + err);
  }
}

/**
 * GET iscrizione cancellata o radiata da cf e tipologiaIscrizione
 * @param cf
 * @param tipologiaIscrizione
 * @param tipoAnagrafe
 * @returns
 */
export async function recuperoIscrizioneInattivaByCf(cf: any, tipologiaIscrizione: any, tipoAnagrafe: string) {
  try {
    var postObj: RecuperoIscrizioneInCompilazioneDTO = {
      "codiceFiscale": cf,
      "tipologiaIscrizioneDTO": tipologiaIscrizione,
      "tipoAnagrafe": tipoAnagrafe

    }
    const response = await getIscrizioneCommonResourceApi().mswfIscrizioneFoRecuperoIscrizioneInattivaByCfPost(postObj, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante il recupero dell'iscrizione in compilazione per " + cf;
    throw new Error(msg + " - " + err);
  }
}

/**
 * GET iscrizione in compilazione da cf responsabile stp
 * @param cfResponsabileSTP
 * @returns
 */
export async function getIscrizioneByCodiceFiscaleResponsabileStp(cfResponsabileSTP: any) {
  try {
    var param: IscrizioneByCfRespStp = {
      "cf": cfResponsabileSTP,
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetIscrizioneByCodiceFiscaleResponsabileStpPost(param, getDefaultAxiosConfig());
    return response;

  } catch (err: any) {
    let msg = "Errore durante il recupero dell'iscrizione in funzione del codice fiscale responsabile: " + cfResponsabileSTP;
    console.error(msg)
    throw await new Error(msg);
  }
}

/**
 * GET iscrizione by idIscrizione e tipologiaIscrizione
 * @param idIscrizione
 * @param tipologiaIscrizione
 * @returns
 */
export async function getIscrizioneByIdBO(idIscrizione: number, tipologiaIscrizione: any) {
  try {
    var postObj: RecuperoIscrizioneByIdDTO = {
      "idIscrizione": idIscrizione,
      "tipologiaIscrizioneDTO": tipologiaIscrizione,
    }
    const response = await getIscrizioneCommonResourceApi().mswfIscrizioneBoRecuperoIscrizioneByIdPost(postObj, getDefaultAxiosConfig());
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err) {
    var msg = "Errore durante il recupero dell'iscrizione con id: " + idIscrizione;
    console.error(msg)
    throw await new Error(msg);
  }
}

/**
 * GET iscrizione by idIscrizione e tipologiaIscrizione
 * @param idIscrizione
 * @param tipologiaIscrizione
 * @returns
 */
export async function getIscrizioneByIdFO(idIscrizione: number, tipologiaIscrizione: any) {
  try {
    var postObj: RecuperoIscrizioneByIdDTO = {
      "idIscrizione": idIscrizione,
      "tipologiaIscrizioneDTO": tipologiaIscrizione,
    }
    const response = await getIscrizioneCommonResourceApi().mswfIscrizioneFoRecuperoIscrizioneByIdPost(postObj, getDefaultAxiosConfig());
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err) {
    var msg = "Errore durante il recupero dell'iscrizione con id: " + idIscrizione;
    throw await new Error(msg);
  }
}

/**
 * Valutazione richiesta di iscrizione all'ordine.
 * @param esitoValutazione
 * @param idIscrizione
 * @param idSoggetto
 * @param parametriValutazione
 * @param filesList
 * @returns
 */
export async function valutaIscrizioneOrdine(esitoValutazione: string, idIscrizione: number, idSoggetto: number, parametriValutazione: any, filesList: any) {
  try {
    const valutazioneAnagrafica: ValutazioneIscrizioneIscrittoDTO = {
      idIscrizione: idIscrizione,
      esitoValutazione: esitoValutazione,
      richiestaIntegrazioniValutazioneIscrittoDTO: {
        "idIscrizione": idIscrizione,
        "idSoggettoRichiedenteIntegrazioni": idSoggetto, // TODO Pescare l'utente operatore dal token, ossia l'utente che sta facendo la richiesta di integrazione
        "idSoggettoDellIscrizione": idSoggetto,
        "testoRichiestaIntegrazione": parametriValutazione.integrazioniRichieste
      },
      valutazioneIscrittoDTO: {
        "idIscrizione": idIscrizione,
        "idSoggetto": idSoggetto,
        "numeroIscrizioneOrdine": parametriValutazione.numeroIscrizione,
        "dataIscrizioneOrdine": parametriValutazione.dataIscrizione,
        "deliberaIscrizione": parametriValutazione.deliberaIscrizione,
        "flagEsoneratoPagamentoQuotaIscrizione": parametriValutazione.esoneroPagaQuotaOrdine,
        "motivoRigetto": parametriValutazione.motivoRigetto,
        "categoriaAnzianitaB": parametriValutazione.categoriaB,
        "categoriaAnzianitaC": parametriValutazione.categoriaC,
        "categoriaAnzianitaD": parametriValutazione.categoriaD,
        "flagEsoneratoPagamentoQuotaConaf": parametriValutazione.esoneroPagaQuotaConaf,
        "flagIscrittoPeritoTribunalePenale": parametriValutazione.iscrittoPeritoTribunalePenale,
        "flagIscrittoCtuTribunaleCivile": parametriValutazione.iscrittoCTUTribunaleCivile,
        "numeroTimbro": parametriValutazione.numeroTimbro,
        "dataInizioValiTimbro": parametriValutazione.dataInizioValiTimbro,
        "dataFineValiTimbro": parametriValutazione.dataFineValiTimbro,
        "numeroMartello": parametriValutazione.numeroMartello,
        "dataInizioValiMartello": parametriValutazione.dataInizioValiMartello,
        "dataFineValiMartello": parametriValutazione.dataFineValiMartello
      },
      allegatoDTOS: filesList ? filesList : []
    }

    await getProcessoIscrizioneResourceApi().mswfIscrizioneIscrittoBoValutaPut(valutazioneAnagrafica, getDefaultAxiosConfig());
  } catch (err) {
    var msg = "Errore durante la valutazione dell'iscrizione all'ordine territoriale";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Valutazione richiesta di iscrizione STP.
 * @param esitoValutazione
 * @param idIscrizione
 * @param idSoggetto
 * @param parametriValutazioneSTP
 * @param filesList
 * @returns
 */
export async function valutaIscrizioneSTP(esitoValutazione: string, idIscrizione: number, idSoggetto: number, parametriValutazioneSTP: any, filesList: any) {
  try {
    const valutazioneAnagrafica: ValutazioneIscrizioneStpDTO = {
      idIscrizione: idIscrizione,
      esitoValutazione: esitoValutazione,
      richiestaIntegrazioniValutazioneStpDTO: {
        "idIscrizione": idIscrizione,
        "idSoggettoRichiedenteIntegrazioni": idSoggetto, // TODO Pescare l'utente operatore dal token, ossia l'utente che sta facendo la richiesta di integrazione
        "idSoggettoDellIscrizione": idSoggetto,
        "testoRichiestaIntegrazione": parametriValutazioneSTP.integrazioniRichieste
      },
      valutazioneStpDTO: {
        "idIscrizione": idIscrizione,
        "idSoggetto": idSoggetto,
        "numeroIscrizioneOrdine": parametriValutazioneSTP.numeroIscrizione,
        "dataIscrizioneOrdine": parametriValutazioneSTP.dataIscrizione,
        "deliberaIscrizione": parametriValutazioneSTP.deliberaIscrizione,
        "motivoRigetto": parametriValutazioneSTP.motivoRigetto,
      },
      allegatoDTOS: filesList ? filesList : []
    }

    await getIscrizioneStpResourceApi().mswfIscrizioneStpBoValutaPut(valutazioneAnagrafica, getDefaultAxiosConfig());
  } catch (err) {
    var msg = "Errore durante la valutazione dell'iscrizione STP";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Cancella iscritto all'ordine
 * @param idIscrizione
 * @param stato
 * @param parametriCancellazione
 * @returns
 */
export async function cancellaIscrittoOrdine(cfOperatore: string, idIscrizione: number, stato: string, parametriCancellazione: any) {
  try {
    const cancellaIscritto: StatoIscrittoDTO = {
      "idIscrizione": idIscrizione,
      "stato": stato,
      "deliberaCancellazione": parametriCancellazione.deliberaCancellazione,
      "dataDeliberaCancellazione": parametriCancellazione.dataDeliberaCancellazione,
      "dataCancellazione": parametriCancellazione.dataCancellazione,
      "motivoCancellazione": parametriCancellazione.motivoCancellazione,
      "cfOperatore": cfOperatore
    }
    const response = await getProcessoIscrizioneResourceApi().mswfIscrizioneIscrittoBoModificaStatoPut(cancellaIscritto, getDefaultAxiosConfig());
    return ErrorStore.getInstance().checkResponse(response);
  } catch (err:any) {
    let errorMessage: any = await ErrorStore.getInstance().returnMessageError(err, undefined)
    throw await new Error(errorMessage.message);
  }
}

/**
 * Cancella iscritto STP
 * @param idIscrizione
 * @param stato
 * @param parametriCancellazioneSTP
 * @returns
 */
export async function cancellaIscrittoSTP(idIscrizione: number, stato: string, parametriCancellazioneSTP: any) {
  try {
    const cancellaIscritto: StatoStpDTO = {
      "idIscrizione": idIscrizione,
      "stato": stato,
      "deliberaCancellazione": parametriCancellazioneSTP.deliberaCancellazione,
      "dataDeliberaCancellazione": parametriCancellazioneSTP.dataDeliberaCancellazione,
      "dataCancellazione": parametriCancellazioneSTP.dataCancellazione,
      "motivoCancellazione": parametriCancellazioneSTP.motivoCancellazione
    }
    const response = await getIscrizioneStpResourceApi().mswfIscrizioneStpBoModificaStatoPut(cancellaIscritto, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante la cancellazione dell'iscrizione STP";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione all'ordine.
 * @param tabNumber
 * @param fields
 * @param idIscrizione
 * @param idSoggetto
 * @returns
*/
export async function compilaIscrizioneOrdine(tabNumber: number, fields: any, idIscrizione: number, idSoggetto: string) {
  try {

    const compilaIscrizione: CompilazioneAnagraficaIscrittoDTO = {
      step: tabNumber,
      idIscrizione: idIscrizione,
      soggettoCompilazioneIscrittoDTO: {
        idSoggetto: parseInt(idSoggetto),
        nome: (fields['nome']) ? fields['nome'] : "",
        cognome: (fields['cognome']) ? fields['cognome'] : "",
        cf: (fields['codiceFiscale']) ? fields['codiceFiscale'] : "",
        sesso: (fields['genere']) ? fields['genere'] : "",
        codiceProvinciaNascita: (fields['provinciaNascita']) ?
        (fields['provinciaNascita'].label === 'Stato Estero') ?
          'EEP' : fields['provinciaNascita'].value
          :
          "",
        cittaNascita: (fields['cittaNascita'] && fields['cittaNascita'].label) ? fields['cittaNascita'].label : (fields['cittaNascita'] && !fields['cittaNascita'].label) ? fields['cittaNascita'] : "",
        cittadinanza: (fields['cittadinanza']) ? fields['cittadinanza'].label : "",
        scadenzaPermessoSoggiorno: (fields['scadenza_permesso_soggiorno'] && (fields['provinciaNascita']?.label === 'Stato Estero')) ? fields['scadenza_permesso_soggiorno'] : "",
        consensoPrivacy: (fields['privacy']) ? getBooleanToConvertToBigInt(fields['privacy']) : 0,
        indirizzoResidenza: (fields['indirizzo']) ? fields['indirizzo'] : "",
        numeroCivico: (fields['numeroCivico']) ? fields['numeroCivico'] : "",
        capResidenza: (fields['cap']) ? fields['cap'] : "",
        emailOrdinaria: (fields['emailOrdinaria']) ? fields['emailOrdinaria'] : "",
        pec: (fields['pec']) ? fields['pec'] : "",
        telefono: (fields['telefono']) ? fields['telefono'] : "",
        altroTelefono: (fields['altroTelefono']) ? fields['altroTelefono'] : "",
        codiceProvinciaResidenza: (fields['provinciaResidenza']) ?
        (fields['provinciaResidenza'].label === 'Stato Estero') ?
          'EEP' : fields['provinciaResidenza'].value
          :
          "",
        comuneResidenza: (fields['comune']) ? fields['comune'].label : "",
        socFlagAutocertificazioneDati: (fields['autocertificazione']) ? conversioneSiNoToInt(fields['autocertificazione']) : 0,
        comprensioneAdempimenti: (fields['comprensioneAdempimenti']) ? conversioneSiNoToInt(fields['comprensioneAdempimenti']) : 0,
      },
      compilazioneIscrittoDTO: {
        idIscrizione: idIscrizione,
        idSoggetto: parseInt(idSoggetto),
        dirittiCivili: (fields['dirittiCivili']) ? getBooleanToConvertToBigInt(fields['dirittiCivili']) : 0,
        condotta: (fields['condotta']) ? getBooleanToConvertToBigInt(fields['condotta']) : 0,
        condanne: (fields['condanna']) ? getBooleanToConvertToBigInt(fields['condanna']) : 0,
        codiceSezioneRichiesta: (fields['sezioneRichiesta']) ? fields['sezioneRichiesta'].value : null,
        codiceTitoloRichiesto: (fields['titoloRichiesto']) ? fields['titoloRichiesto'].value : 0,
        codiceTipoLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['tipoLaurea']) ? fields['tipoLaurea'].value : null,
        codiceClasseLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['classeLaurea']) ? fields['classeLaurea'].value : null,
        corsoLaureaSpecificato: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['corsoLaurea']) ? fields['corsoLaurea'] : null,
        annoDiLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['annoLaurea']) ? fields['annoLaurea'] : null,
        universitaLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['universita']) ? fields['universita'] : null,
        flagAbilitazioneConDecretoEstero: (fields['decretoRiconoscimentoTitoloEstero'] !== undefined) ? getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) : undefined,
        dataAbilitazioneDecretoEstero: (fields['decretoRiconoscimentoTitoloEstero'] && fields['dataRiconoscimentoTitolo']) ? fields['dataRiconoscimentoTitolo'] : undefined,
        flagSedeLegaleDiversoDaProvinciaResidenza: (fields['sedeLegaleStudio']) ? conversioneSiNoToInt(fields['sedeLegaleStudio'].label) : undefined,
        flagDipendentePubblicaAmministrazione: (fields['dipendenteStatoOAltro']) ? conversioneSiNoToInt(fields['dipendenteStatoOAltro'].label) : undefined,
        flagLiberaProfPubblicaAmministrazione: (fields['ordinamentoAmministrazione'] && fields['dipendenteStatoOAltro'] && fields['dipendenteStatoOAltro']?.label === "Si") ? convertFlagLiberaProfPubblicaAmministrazioneToInt(fields['ordinamentoAmministrazione'].label) : null,
        //fileStatutoEnteAtto: (fields['statuto']) ? fields['statuto'] : "string",
        flagLiberoProfessionista: (fields['liberoProfessionista']) ? conversioneSiNoToInt(fields['liberoProfessionista'].label) : undefined,
        flagLiberoProfessionistaInFormaAssociata: (fields['professionistaFormaAssociata']) ? conversioneSiNoToInt(fields['professionistaFormaAssociata'].label) : 0,
        flagSocioStp: (fields['socioStp']) ? conversioneSiNoToInt(fields['socioStp'].label) : undefined,
        flagDipendenteStp: (fields['dipendenteStp']) ? conversioneSiNoToInt(fields['dipendenteStp'].label) : undefined,
        flagIncompatibilitaEsercenteAttivitaProfessionale: (fields['nonEsercenteAttivita']) ? conversioneSiNoToInt(fields['nonEsercenteAttivita'].label) : undefined,
        flagDipendentePrivato: (fields['dipendentePrivato']) ? conversioneSiNoToInt(fields['dipendentePrivato'].label) : undefined,
        flagSocioDipendenteStpPrivato: (fields?.flagSocioDipendenteStpPrivato && fields.dipendenteStp?.label === "Si" || fields.socioStp?.label === "Si") ? conversioneSiNoToInt(fields['flagSocioDipendenteStpPrivato'].label) : null,
        tipologiaAbilitazione: (fields['tipologiaAbilitazione']) ? fields['tipologiaAbilitazione'].value : null,
        annoAbilitazione: (fields['annoAbilitazione']) ? fields['annoAbilitazione'] : "",
        ateneoAbilitazione: (fields['ateneoAbilitazione']) ? fields['ateneoAbilitazione'] : "",
        decretoRiconoscimentoQualificaProfessionale: (fields['decretoRiconoscimentoQualificaProfessionale']) ? fields['decretoRiconoscimentoQualificaProfessionale'] : "",
        dataRiconoscimentoQualificaProfessionale: (fields['dataRiconoscimento']) ? fields['dataRiconoscimento'] : null,
        flagIscrizioneEpap: (fields['iscrittoEpap']) ? conversioneSiNoToInt(fields['iscrittoEpap'].label) : undefined,
        dataIscrizioneEpap: (fields['dataIscrizioneEpap']) ? fields['dataIscrizioneEpap'] : null,
        matricolaEpap: (fields['matricolaEpap']) ? fields['matricolaEpap'] : "",
        flagRichSmartCard: (fields['flagRichSmartCard']) ? conversioneSiNoToInt(fields['flagRichSmartCard'].label) : undefined,
        flagRichBusinessKey: (fields['flagRichBusinessKey']) ? conversioneSiNoToInt(fields['flagRichBusinessKey'].label) : undefined,
        flagRichPec: (fields['flagRichPec']) ? conversioneSiNoToInt(fields['flagRichPec'].label) : undefined,
        ordineTerritorialeCompetente: (fields['idOrdine']) ? (fields['idOrdine'].value) : "",
      },
      sedeLegaleCompilazioneIscrittoDTO: {
        idIscrizione: idIscrizione,
        idSoggetto: parseInt(idSoggetto),
        slIndirizzoStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['indirizzoSedeLegaleStudio']) ? fields['indirizzoSedeLegaleStudio'] : "",
        slNumeroCivicoStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['numeroCivicoSedeLegaleStudio']) ? fields['numeroCivicoSedeLegaleStudio'] : "",
        slProvinciaStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['provinciaStudio']) ?
          (fields['provinciaStudio'].label === 'Stato Estero') ?
          'EEP' : fields['provinciaStudio'].value
          :
          "",
        slCittaStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['cittaSedeLegaleStudio'] !== undefined) ? fields['cittaSedeLegaleStudio'].label ?? fields['cittaSedeLegaleStudio'] : "",
        slCapStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['capSedeLegaleStudio']) ? fields['capSedeLegaleStudio'] : "",
        slTelefonoStudio: (fields['telefonoSedeLegaleStudio']) ? fields['telefonoSedeLegaleStudio'] : "",
        slFaxStudio: (fields['faxSedeLegaleStudio']) ? fields['faxSedeLegaleStudio'] : "",
        slMailStudio: (fields['mailSedeLegaleStudio']) ? fields['mailSedeLegaleStudio'] : "",
        slPecStudio: (fields['pecSedeLegaleStudio']) ? fields['pecSedeLegaleStudio'] : "",
        sitoInternet: (fields['sitoInternet']) ? fields['sitoInternet'] : "",
        facebook: (fields['facebook']) ? fields['facebook'] : "",
        twitter: (fields['twitter']) ? fields['twitter'] : "",
      },
       soggettoDocumentoDTO: {
        idSoggetto: parseInt(idSoggetto),
        tipoDocumento: fields['tipoDocumento']?.label ? fields['tipoDocumento']?.label : "",
        idTipoDocumento: fields['tipoDocumento']?.value ? fields['tipoDocumento']?.value : null,
        numeroDocumento: fields['numeroDocumento'] ? fields['numeroDocumento'] : "",
        emessoDa: fields['emessoDa'] ? fields['emessoDa'] : "",
        emessoIl: fields['emessoIl'] ? fields['emessoIl'] : null,
        scadenzaIl: fields['scadenzaIl'] ? fields['scadenzaIl'] : null
      },
      //allegatoDTOS: fields["uploadFiles"] ? fields["uploadFiles"] : []
      allegatoDTOS: fields["uploadFiles"] ? fields["uploadFiles"].filter(e => e) : []
    }

    let response = await getProcessoIscrizioneResourceApi().mswfIscrizioneIscrittoFoCompilaPut(compilaIscrizione, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante la compilazione dell'iscrizione all'ordine territoriale";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione STP.
 * @param idIscrizione
 * @param idSoggetto
 * @param formData
 * @param composizioneSocietariaList
 * @param filesList
 * @returns
*/
export async function compilaIscrizioneSTP(step: number, idSoggetto: number, idIscrizione: number, formData: any, composizioneSocietariaList: any, filesList: any) {
  try {
    const compilaIscrizioneSTP: CompilazioneAnagraficaStpDTO = {
      idIscrizione: idIscrizione,
      step: step,
      soggettoCompilazioneStpDTO: {
        idSoggetto: idSoggetto,
        partitaIva: formData.partitaIva ? formData.partitaIva.value : '',
        emailOrdinaria: formData.emailOrdinaria ? formData.emailOrdinaria.value : '',
        pec: formData.pec ? formData.pec.value : '',
        consensoPrivacy: formData.consensoPrivacy ? getBooleanToConvertToBigInt(formData.consensoPrivacy.value) : 0,
        socFlagAutocertificazioneDati: formData.socFlagAutocertificazioneDati ? getBooleanToConvertToBigInt(formData.socFlagAutocertificazioneDati.value) : 0,
        comprensioneAdempimenti: formData.comprensioneAdempimenti ? getBooleanToConvertToBigInt(formData.comprensioneAdempimenti.value) : 0,
        ragioneSociale: formData.ragioneSociale ? formData.ragioneSociale.value : '',
        formaGiuridica: formData.formaGiuridica ? formData.formaGiuridica.value : '',
        codiceFiscaleAzienda: formData.codiceFiscaleAzienda ? formData.codiceFiscaleAzienda.value : '',
        codAteco: formData.codAteco ? formData.codAteco.value : '',
        numeroRea: formData.numeroRea ? formData.numeroRea.value : '',
        dataIscrCciaa: formData.dataIscrCciaa && formData.dataIscrCciaa.value !== '' ? formData.dataIscrCciaa.value : null,
        comuCciaa: formData.comuCciaa ? formData.comuCciaa.value : '',
        tipolSoc: formData.tipolSoc ? formData.tipolSoc.value : '',
        socAttivitaProfessionalePrevalente: formData.socAttivitaProfessionalePrevalente ? formData.socAttivitaProfessionalePrevalente.value : '',
        socAttivitaProfessionaleSecondaria: (( formData?.tipolSoc?.value === 1 || formData?.tipolSoc?.value === "1") && formData?.socAttivitaProfessionaleSecondaria?.value) ? formData.socAttivitaProfessionaleSecondaria.value : '',
        codiceSezioneSpecialeProfessionisti: formData.codiceSezioneSpecialeProfessionisti ? formData.codiceSezioneSpecialeProfessionisti.value : '',
      },
      compilazioneStpDTO: {
        idIscrizione: idIscrizione,
        idSoggetto: idSoggetto,
        ordineTerritorialeCompetente: formData.ordineTerritorialeCompetente ? formData.ordineTerritorialeCompetente.value : '',
        flagRichPec: formData.flagRichPec ? formData.flagRichPec.value : 0,
        flagPercentualeSocietaria: formData.flagPercentualeSocietaria ? getBooleanToConvertToBigInt(formData.flagPercentualeSocietaria.value) : 0,
      },
      sedeLegaleCompilazioneStpDTO: {
        idIscrizione: idIscrizione,
        idSoggetto: idSoggetto,
        slIndirizzoStudio: formData.slIndirizzoStudio ? formData.slIndirizzoStudio.value : '',
        slProvinciaStudio: formData.slProvinciaStudio ? formData.slProvinciaStudio.value : '',
        slCittaStudio: formData.slCittaStudio ? formData.slCittaStudio.value : '',
        slCapStudio: formData.slCapStudio ? formData.slCapStudio.value : '',
        slTelefonoStudio: formData.slTelefonoStudio ? formData.slTelefonoStudio.value : '',
        slFaxStudio: formData.slFaxStudio ? formData.slFaxStudio.value : '',
        slMailStudio: formData.slMailStudio ? formData.slMailStudio.value : '',
        slPecStudio: formData.slPecStudio ? formData.slPecStudio.value : '',
        sopIndirizzoStudio: formData.sopIndirizzoStudio ? formData.sopIndirizzoStudio.value : '',
        sopProvinciaStudio: formData.sopProvinciaStudio ? formData.sopProvinciaStudio.value : '',
        sopCittaStudio: formData.sopCittaStudio ? formData.sopCittaStudio.value : '',
        sopCapStudio: formData.sopCapStudio ? formData.sopCapStudio.value : '',
        sopTelefonoStudio: formData.sopTelefonoStudio ? formData.sopTelefonoStudio.value : '',
        sopFaxStudio: formData.sopFaxStudio ? formData.sopFaxStudio.value : '',
        sopMailStudio: formData.sopMailStudio ? formData.sopMailStudio.value : '',
        sopPecStudio: formData.sopPecStudio ? formData.sopPecStudio.value : '',
        sitoInternet: formData.sitoInternet ? formData.sitoInternet.value : '',
        facebook: formData.facebook ? formData.facebook.value : '',
        twitter: formData.twitter ? formData.twitter.value : '',
      },
      composizioneSocietariaCompilazioneStpDTOS: composizioneSocietariaList ? composizioneSocietariaList : [],
      allegatoDTOS: filesList ? filesList : []
    }
    var response = await getIscrizioneStpResourceApi().mswfIscrizioneStpFoCompilaPut(compilaIscrizioneSTP, getDefaultAxiosConfig());
    // return ErrorStore.getInstance().checkResponseReturnResponse(response);
    return response;

  } catch (err) {
    let errorMessage: any = ErrorStore.getInstance().returnMessageError(PATH_TO_ERROR, err)
    let msg = "Errore durante la compilazione dell'iscrizione STP. ";
    console.error(errorMessage.title + msg + errorMessage.message);
    throw new Error(errorMessage.title + msg + errorMessage.message);

  }
}

/**
 * Invoca l'endpoint per l'invio della richiesta di iscrizione STP.
 * @param idIscrizione
 * @param idSoggetto
 * @param formData
 * @param composizioneSocietariaList
 * @param filesList
 * @returns
*/
export async function inviaIscrizioneStp(idSoggetto: number, idIscrizione: number, formData: any, composizioneSocietariaList: any, filesList: any) {
  try {
    const inviaIscrizioneSTP: CompilazioneAnagraficaStpDTO = {
      idIscrizione: idIscrizione,
      soggettoCompilazioneStpDTO: {
        idSoggetto: idSoggetto,
        partitaIva: formData.partitaIva ? formData.partitaIva.value : '',
        emailOrdinaria: formData.emailOrdinaria ? formData.emailOrdinaria.value : '',
        pec: formData.pec ? formData.pec.value : '',
        consensoPrivacy: formData.consensoPrivacy ? getBooleanToConvertToBigInt(formData.consensoPrivacy.value) : 0,
        socFlagAutocertificazioneDati: formData.socFlagAutocertificazioneDati ? getBooleanToConvertToBigInt(formData.socFlagAutocertificazioneDati.value) : 0,
        comprensioneAdempimenti: formData.comprensioneAdempimenti ? getBooleanToConvertToBigInt(formData.comprensioneAdempimenti.value) : 0,
        ragioneSociale: formData.ragioneSociale ? formData.ragioneSociale.value : '',
        formaGiuridica: formData.formaGiuridica ? formData.formaGiuridica.value : '',
        codiceFiscaleAzienda: formData.codiceFiscaleAzienda ? formData.codiceFiscaleAzienda.value : '',
        codAteco: formData.codAteco ? formData.codAteco.value : '',
        numeroRea: formData.numeroRea ? formData.numeroRea.value : '',
        dataIscrCciaa: formData.dataIscrCciaa && formData.dataIscrCciaa.value !== '' ? formData.dataIscrCciaa.value : null,
        comuCciaa: formData.comuCciaa ? formData.comuCciaa.value : '',
        tipolSoc: formData.tipolSoc ? formData.tipolSoc.value : '',
        socAttivitaProfessionalePrevalente: formData.socAttivitaProfessionalePrevalente ? formData.socAttivitaProfessionalePrevalente.value : '',
        socAttivitaProfessionaleSecondaria: (formData.tipolSoc && (formData.tipolSoc.value) === 1 && formData.socAttivitaProfessionaleSecondaria && formData.socAttivitaProfessionaleSecondaria.value) ? formData.socAttivitaProfessionaleSecondaria.value : '',
        codiceSezioneSpecialeProfessionisti: formData.codiceSezioneSpecialeProfessionisti ? formData.codiceSezioneSpecialeProfessionisti.value : '',
      },
      compilazioneStpDTO: {
        idIscrizione: idIscrizione,
        idSoggetto: idSoggetto,
        ordineTerritorialeCompetente: formData.ordineTerritorialeCompetente ? formData.ordineTerritorialeCompetente.value : '',
        flagRichPec: formData.flagRichPec ? formData.flagRichPec.value : 0,
        flagPercentualeSocietaria: formData.flagPercentualeSocietaria ? getBooleanToConvertToBigInt(formData.flagPercentualeSocietaria.value) : 0,
      },
      sedeLegaleCompilazioneStpDTO: {
        idIscrizione: idIscrizione,
        idSoggetto: idSoggetto,
        slIndirizzoStudio: formData.slIndirizzoStudio ? formData.slIndirizzoStudio.value : '',
        slProvinciaStudio: formData.slProvinciaStudio ? formData.slProvinciaStudio.value : '',
        slCittaStudio: formData.slCittaStudio ? formData.slCittaStudio.value : '',
        slCapStudio: formData.slCapStudio ? formData.slCapStudio.value : '',
        slTelefonoStudio: formData.slTelefonoStudio ? formData.slTelefonoStudio.value : '',
        slFaxStudio: formData.slFaxStudio ? formData.slFaxStudio.value : '',
        slMailStudio: formData.slMailStudio ? formData.slMailStudio.value : '',
        slPecStudio: formData.slPecStudio ? formData.slPecStudio.value : '',
        sopIndirizzoStudio: formData.sopIndirizzoStudio ? formData.sopIndirizzoStudio.value : '',
        sopProvinciaStudio: formData.sopProvinciaStudio ? formData.sopProvinciaStudio.value : '',
        sopCittaStudio: formData.sopCittaStudio ? formData.sopCittaStudio.value : '',
        sopCapStudio: formData.sopCapStudio ? formData.sopCapStudio.value : '',
        sopTelefonoStudio: formData.sopTelefonoStudio ? formData.sopTelefonoStudio.value : '',
        sopFaxStudio: formData.sopFaxStudio ? formData.sopFaxStudio.value : '',
        sopMailStudio: formData.sopMailStudio ? formData.sopMailStudio.value : '',
        sopPecStudio: formData.sopPecStudio ? formData.sopPecStudio.value : '',
        sitoInternet: formData.sitoInternet ? formData.sitoInternet.value : '',
        facebook: formData.facebook ? formData.facebook.value : '',
        twitter: formData.twitter ? formData.twitter.value : '',
      },
      composizioneSocietariaCompilazioneStpDTOS: composizioneSocietariaList ? composizioneSocietariaList : [],
      allegatoDTOS: filesList ? filesList : []
    }
    var response = await getIscrizioneStpResourceApi().mswfIscrizioneStpFoCompilaPost(inviaIscrizioneSTP, getDefaultAxiosConfig());
    //Manca il ResponseBuilder su Backend
    return response;
  } catch (err) {
    let errorMessage: any = ErrorStore.getInstance().returnMessageError(PATH_TO_ERROR, err)
    var msg = "Errore durante l'invio della richiesta di iscrizione STP. ";
    console.error(errorMessage.title + msg + errorMessage.message);
    throw new Error(errorMessage.title + msg + errorMessage.message);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione come operatore ordine territoriale.
 * @param idSoggetto
 * @param parametriCompilazioneIscrizioneOOT
 * @returns
*/
export async function compilaIscrizioneOOT(idSoggetto: number, parametriCompilazioneIscrizioneOOT: any, tipologia: string) {
  try {
    const compilaIscrizioneOOT: CompilazioneAnagraficaOotDTO = {
      soggettoCompilazioneOotDTO: {
        "idSoggetto": idSoggetto
      },
      compilazioneOotDTO: {
        "idSoggetto": idSoggetto,
        "ordineTerritorialeCompetente": parametriCompilazioneIscrizioneOOT.ordineTerritoriale,
        "emailOrdinaria": parametriCompilazioneIscrizioneOOT.emailOperatoreOrdineTerritoriale
      },

      tipologia: tipologia
    }

    let response = await getIscrizioneOOTResourceApi().mswfIscrizioneOotFoCompilaPost(compilaIscrizioneOOT, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante la compilazione dell'iscrizione all'ordine territoriale";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione come operatore ordine territoriale.
 * @param idSoggetto
 * @param parametriCompilazioneIscrizioneOSTP
 * @returns
*/
export async function compilaIscrizioneOSTP(idSoggetto: number, parametriCompilazioneIscrizioneOSTP: any) {
  try {
    const compilaIscrizioneOSTP: CompilazioneAnagraficaOStpDTO = {
      soggettoCompilazioneOStpDTO: {
        "idSoggetto": idSoggetto
      },
      compilazioneOStpDTO: {
        "idSoggetto": idSoggetto,
        "codiceFiscaleStpOperatore": parametriCompilazioneIscrizioneOSTP.codiceFiscaleOperatoreSTP,
        "emailOrdinariaOperatore": parametriCompilazioneIscrizioneOSTP.emailOperatoreSTP
      }
    }

    let response = await getIscrizioneOSTPResourceApi().mswfIscrizioneOstpFoCompilaPost(compilaIscrizioneOSTP, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante la compilazione dell'iscrizione come operatore STP";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione come operatore ente formativo.
 * @param idSoggetto
 * @param parametriCompilazioneIscrizioneOEF
 * @returns
*/
export async function compilaIscrizioneOEF(idSoggetto: number, parametriCompilazioneIscrizioneOEF: any) {
  try {
    const compilaIscrizioneOEF: CompilazioneAnagraficaOefDTO = {
      soggettoCompilazioneOefDTO: {
        "idSoggetto": idSoggetto
      },
      compilazioneOefDTO: {
        "idSoggetto": idSoggetto,
        "emailOrdinaria": parametriCompilazioneIscrizioneOEF.emailOperatoreOrdineTerritoriale
      }
    }

    let response = await getIscrizioneOEFResourceApi().msfoIscrizioneOefFoCompilaPost(compilaIscrizioneOEF, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante la compilazione dell'iscrizione operatore enti formativi";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione come operatore ente esterno.
 * @param idSoggetto
 * @param parametriCompilazioneIscrizioneOEE
 * @returns
*/
export async function compilaIscrizioneOEE(idSoggetto: number, parametriCompilazioneIscrizioneOEE: any) {
  try {
    const compilaIscrizioneOEE: CompilazioneAnagraficaOeeDTO = {
      soggettoCompilazioneOeeDTO: {
        "idSoggetto": idSoggetto
      },
      compilazioneOeeDTO: {
        "idSoggetto": idSoggetto,
        "emailOrdinaria": parametriCompilazioneIscrizioneOEE.emailOperatoreOrdineTerritoriale
      }
    }

    let response = await getIscrizioneOEEResourceApi().msfoIscrizioneOeeFoCompilaPost(compilaIscrizioneOEE, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante la compilazione dell'iscrizione operatore enti esterni";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione come operatore ordine territoriale.
 * @param idSoggetto
 * @param parametriCompilazioneIscrizioneOER
 * @returns
*/
export async function compilaIscrizioneOER(idSoggetto: number, parametriCompilazioneIscrizioneOER: any) {
  try {
    const compilaIscrizioneOER: CompilazioneAnagraficaOerDTO = {
      soggettoCompilazioneOerDTO: {
        "idSoggetto": idSoggetto
      },
      compilazioneOerDTO: {
        "idSoggetto": idSoggetto,
        "emailOrdinaria": parametriCompilazioneIscrizioneOER.emailOperatoreOrdineTerritoriale
      }
    }

    let response = await getIscrizioneOERResourceApi().msfoIscrizioneOerFoCompilaPost(compilaIscrizioneOER, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante la compilazione dell'iscrizione all'ordine territoriale";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per la compilazione della richiesta di iscrizione come operatore ordine territoriale.
 * @param idSoggetto
 * @param emailOperatoreConaf
 * @returns
*/
export async function compilaIscrizioneOConaf(idSoggetto: number, emailOperatoreConaf: any) {
  try {
    const compilaIscrizioneOConaf: CompilazioneAnagraficaOConafDTO = {
      soggettoCompilazioneOConafDTO: {
        "idSoggetto": idSoggetto
      },
      compilazioneOotDTO: {
        "idSoggetto": idSoggetto,
        "emailOrdinaria": emailOperatoreConaf
      }
    }

    let response = await getIscrizioneOConafResourceApi().mswfIscrizioneOconafFoCompilaPost(compilaIscrizioneOConaf, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante la compilazione dell'iscrizione come operatore CONAF";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint per l'invio della richiesta di iscrizione all'ordine.
 * @param idIscrizione
 * @param idSoggetto
 * @param fields
 * @returns
*/
export async function inviaCompilazione(fields: any, idIscrizione: string, idSoggetto: string) {
  try {
    if (fields && fields.data)
      fields = fields.data;
    const inviaIscrizione: CompilazioneAnagraficaIscrittoDTO = {
      idIscrizione: parseInt(idIscrizione),
      soggettoCompilazioneIscrittoDTO: {
        idSoggetto: parseInt(idSoggetto),
        nome: (fields['nome']) ? fields['nome'] : "",
        cognome: (fields['cognome']) ? fields['cognome'] : "",
        cf: (fields['codiceFiscale']) ? fields['codiceFiscale'] : "",
        sesso: (fields['genere']) ? fields['genere'] : "",
        codiceProvinciaNascita: (fields['provinciaNascita']) ?
        (fields['provinciaNascita'].label === 'Stato Estero') ?
          'EEP' : fields['provinciaNascita'].value
          :
          "",
        cittaNascita: (fields['cittaNascita'] && fields['cittaNascita'].label) ? fields['cittaNascita'].label : (fields['cittaNascita'] && !fields['cittaNascita'].label) ? fields['cittaNascita'] : "",
        cittadinanza: (fields['cittadinanza']) ? fields['cittadinanza'].label : "",
        scadenzaPermessoSoggiorno: (fields['scadenza_permesso_soggiorno'] && (fields['provinciaNascita'].label === 'Stato Estero')) ? fields['scadenza_permesso_soggiorno'] : "",
        consensoPrivacy: (fields['privacy']) ? getBooleanToConvertToBigInt(fields['privacy']) : 0,
        indirizzoResidenza: (fields['indirizzo']) ? fields['indirizzo'] : "",
        numeroCivico: (fields['numeroCivico']) ? fields['numeroCivico'] : "",
        capResidenza: (fields['cap']) ? fields['cap'] : "",
        emailOrdinaria: (fields['emailOrdinaria']) ? fields['emailOrdinaria'] : "",
        pec: (fields['pec']) ? fields['pec'] : "",
        telefono: (fields['telefono']) ? fields['telefono'] : "",
        altroTelefono: (fields['altroTelefono']) ? fields['altroTelefono'] : "",
        codiceProvinciaResidenza: (fields['provinciaResidenza']) ?
        (fields['provinciaResidenza'].label === 'Stato Estero') ?
          'EEP' : fields['provinciaResidenza'].value
          :
          "",
        comuneResidenza: (fields['comune']) ? fields['comune'].label : "",
        socFlagAutocertificazioneDati: (fields['autocertificazione']) ? conversioneSiNoToInt(fields['autocertificazione']) : 0,
        comprensioneAdempimenti: (fields['comprensioneAdempimenti']) ? conversioneSiNoToInt(fields['comprensioneAdempimenti']) : 0,
      },
      compilazioneIscrittoDTO: {
        idIscrizione: parseInt(idIscrizione),
        idSoggetto: parseInt(idSoggetto),
        dirittiCivili: (fields['dirittiCivili']) ? getBooleanToConvertToBigInt(fields['dirittiCivili']) : 0,
        condotta: (fields['condotta']) ? getBooleanToConvertToBigInt(fields['condotta']) : 0,
        condanne: (fields['condanna']) ? getBooleanToConvertToBigInt(fields['condanna']) : 0,
        codiceSezioneRichiesta: (fields['sezioneRichiesta']) ? fields['sezioneRichiesta'].value : null,
        codiceTitoloRichiesto: (fields['titoloRichiesto']) ? fields['titoloRichiesto'].value : 0,
        codiceTipoLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['tipoLaurea']) ? fields['tipoLaurea'].value : null,
        codiceClasseLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['classeLaurea']) ? fields['classeLaurea'].value : null,
        corsoLaureaSpecificato: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['corsoLaurea']) ? fields['corsoLaurea'] : "",
        annoDiLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['annoLaurea']) ? fields['annoLaurea'] : "",
        universitaLaurea: (getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) !== 1 && fields['universita']) ? fields['universita'] : "",
        flagAbilitazioneConDecretoEstero: (fields['decretoRiconoscimentoTitoloEstero']) ? getBooleanToConvertToBigInt(fields['decretoRiconoscimentoTitoloEstero']) : 0,
        dataAbilitazioneDecretoEstero: (fields['decretoRiconoscimentoTitoloEstero'] && fields['data']) ? fields['data'] : null,
        flagSedeLegaleDiversoDaProvinciaResidenza: (fields['sedeLegaleStudio']) ? conversioneSiNoToInt(fields['sedeLegaleStudio'].label) : 0,
        flagDipendentePubblicaAmministrazione: (fields['dipendenteStatoOAltro']) ? conversioneSiNoToInt(fields['dipendenteStatoOAltro'].label) : 0,
        flagLiberaProfPubblicaAmministrazione: (fields['ordinamentoAmministrazione'] && fields['dipendenteStatoOAltro'] && fields['dipendenteStatoOAltro']?.label === "Si") ? convertFlagLiberaProfPubblicaAmministrazioneToInt(fields['ordinamentoAmministrazione'].label) : null,
        //fileStatutoEnteAtto: (fields['statuto']) ? fields['statuto'] : "string",
        flagLiberoProfessionista: (fields['liberoProfessionista']) ? conversioneSiNoToInt(fields['liberoProfessionista'].label) : 0,
        flagLiberoProfessionistaInFormaAssociata: (fields['professionistaFormaAssociata']) ? conversioneSiNoToInt(fields['professionistaFormaAssociata'].label) : 0,
        flagSocioStp: (fields['socioStp']) ? conversioneSiNoToInt(fields['socioStp'].label) : 0,
        flagDipendenteStp: (fields['dipendenteStp']) ? conversioneSiNoToInt(fields['dipendenteStp'].label) : 0,
        flagIncompatibilitaEsercenteAttivitaProfessionale: (fields['nonEsercenteAttivita']) ? conversioneSiNoToInt(fields['nonEsercenteAttivita'].label) : 0,
        flagDipendentePrivato: (fields['dipendentePrivato']) ? conversioneSiNoToInt(fields['dipendentePrivato'].label) : 0,
        flagSocioDipendenteStpPrivato: (fields?.flagSocioDipendenteStpPrivato && fields.dipendenteStp?.label === "Si" || fields.socioStp?.label === "Si") ? conversioneSiNoToInt(fields['flagSocioDipendenteStpPrivato'].label) : null,
        tipologiaAbilitazione: (fields['tipologiaAbilitazione']) ? fields['tipologiaAbilitazione'].value : null,
        annoAbilitazione: (fields['annoAbilitazione']) ? fields['annoAbilitazione'] : "",
        ateneoAbilitazione: (fields['ateneoAbilitazione']) ? fields['ateneoAbilitazione'] : "",
        decretoRiconoscimentoQualificaProfessionale: (fields['decretoRiconoscimentoQualificaProfessionale']) ? fields['decretoRiconoscimentoQualificaProfessionale'] : "",
        dataRiconoscimentoQualificaProfessionale: (fields['dataRiconoscimento']) ? fields['dataRiconoscimento'] : null,
        flagIscrizioneEpap: (fields['iscrittoEpap']) ? conversioneSiNoToInt(fields['iscrittoEpap'].label) : undefined,
        dataIscrizioneEpap: (fields['dataIscrizioneEpap']) ? fields['dataIscrizioneEpap'] : null,
        matricolaEpap: (fields['matricolaEpap']) ? fields['matricolaEpap'] : "",
        flagRichSmartCard: (fields['richiestaRilascioSmartcard']) ? conversioneSiNoToInt(fields['richiestaRilascioSmartcard'].label) : 0,
        flagRichBusinessKey: (fields['richiestaRilascioToken']) ? conversioneSiNoToInt(fields['richiestaRilascioToken'].label) : 0,
        flagRichPec: (fields['richiestaAssegnazionePec']) ? conversioneSiNoToInt(fields['richiestaAssegnazionePec'].label) : 0,
        ordineTerritorialeCompetente: (fields['idOrdine']) ? (fields['idOrdine'].value) : "",
      },
      sedeLegaleCompilazioneIscrittoDTO: {
        idIscrizione: parseInt(idIscrizione),
        idSoggetto: parseInt(idSoggetto),
        slIndirizzoStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['indirizzoSedeLegaleStudio']) ? fields['indirizzoSedeLegaleStudio'] : "",
        slNumeroCivicoStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['numeroCivicoSedeLegaleStudio']) ? fields['numeroCivicoSedeLegaleStudio'] : "",
        slProvinciaStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['provinciaStudio']) ?
        (fields['provinciaStudio'].label === 'Stato Estero') ?
        'EEP' : fields['provinciaStudio'].value
        :
        "",
        slCittaStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['cittaSedeLegaleStudio']) ?fields['cittaSedeLegaleStudio'].label ?? fields['cittaSedeLegaleStudio'] : "",
        slCapStudio: (fields['sedeLegaleStudio']?.label === 'Si' && fields['capSedeLegaleStudio']) ? fields['capSedeLegaleStudio'] : "",
        slTelefonoStudio: (fields['telefonoSedeLegaleStudio']) ? fields['telefonoSedeLegaleStudio'] : "",
        slFaxStudio: (fields['faxSedeLegaleStudio']) ? fields['faxSedeLegaleStudio'] : "",
        slMailStudio: (fields['mailSedeLegaleStudio']) ? fields['mailSedeLegaleStudio'] : "",
        slPecStudio: (fields['pecSedeLegaleStudio']) ? fields['pecSedeLegaleStudio'] : "",
        sitoInternet: (fields['sitoInternet']) ? fields['sitoInternet'] : "",
        facebook: (fields['facebook']) ? fields['facebook'] : "",
        twitter: (fields['twitter']) ? fields['twitter'] : "",
      },
      soggettoDocumentoDTO: {
        idSoggetto: parseInt(idSoggetto),
        tipoDocumento: fields['tipoDocumento']?.label ? fields['tipoDocumento']?.label : "",
        idTipoDocumento: fields['tipoDocumento']?.value ? fields['tipoDocumento']?.value : null,
        numeroDocumento: fields['numeroDocumento'] ? fields['numeroDocumento'] : "",
        emessoDa: fields['emessoDa'] ? fields['emessoDa'] : "",
        emessoIl: fields['emessoIl'] ? fields['emessoIl'] : null,
        scadenzaIl: fields['scadenzaIl'] ? fields['scadenzaIl'] : null
      },
      allegatoDTOS: fields["uploadFiles"] ? fields["uploadFiles"] : []
    }
    let response = await getProcessoIscrizioneResourceApi().mswfIscrizioneIscrittoFoCompilaPost(inviaIscrizione, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    var msg = "Errore durante l'invio della richiesta di iscrizione all'ordine territoriale";
    throw new Error(msg + " - " + err);
  }
}

/**
 * Invoca l'endpoint che recupera le integrazioni richieste dall'operatore dato in input l'idIscrizione
 * @param idIscrizione
 * @returns
*/
export async function getIntegrazioniRichieste(idIscrizione: number) {
  try {
    const param: RichiestaIntegrazioniByIdIscrizioneDTO = {
      "idIscrizione": idIscrizione
    }

    let response = await getIscrizioneOrdineResourceApi().msgaIotFoGetRichiesteIntegrazioniByIdIscrizionePost(param, getDefaultAxiosConfig());
    return response;
    // return ErrorStore.getInstance().checkResponseReturnResponse(response);


  } catch (err) {
    var msg = "Errore durante il recupero della richiesta integrazione";
    console.error(msg)
    throw await new Error(msg);
  }
}

/**
 * Invoca l'endpoint che recupera l'allegato dato in input l'idAttachment
 * @param idAttachment
 * @returns
*/
export async function getFileByIdAttachmentFO(idAttachment: string) {
  try {
    const param: StringParam = {
      "param": idAttachment
    }

    let response = await getAllegatoResourcesApi().mswfAllegatoFoGetAllegatoPost(param, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    throw new Error("Errore durante la chiamata mswfAllegatoGetAllegatoPost - " + err);
  }
}

/**
 * Invoca l'endpoint che recupera l'allegato dato in input l'idAttachment
 * @param idAttachment
 * @returns
*/
export async function getFileByIdAttachmentBO(idAttachment: string) {
  try {
    const param: StringParam = {
      "param": idAttachment
    }

    let response = await getAllegatoResourcesApi().mswfAllegatoBoGetAllegatoPost(param, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    throw new Error("Errore durante la chiamata mswfAllegatoGetAllegatoPost - " + err);
  }
}

/**
 * Invoca l'endpoint che recupera l'allegato dato in input l'idIscrizione backoffice
 * @param idIscrizione
 * @returns
*/
export async function getFilesByIdIscrizioneBo(idIscrizioneIn: number) {
  try {
    var idIscrizione = idIscrizioneIn

    let response = await getAllegatoResourcesApi().mswfAllegatoBoGetAllegatiByIdIscrizionePost(idIscrizione, getDefaultAxiosConfig());
    //Manca il ResponseBuilder su Backend
    return response;

  } catch (err: any) {
    var msg = "Errore durante il recupero dei file. ";
    //TODO - Gestire errore
    //let errorMessage: any = await ErrorStore.getInstance().showAlert(err.message)
    throw await new Error(msg);
  }
}

/**
 * Invoca l'endpoint che recupera l'allegato dato in input l'idIscrizione frontend
 * @param idIscrizione
 * @returns
*/
export async function getFilesByIdIscrizioneFo(idIscrizioneIn: number) {
  try {
    var idIscrizione = idIscrizioneIn
    if (idIscrizione === undefined)
      idIscrizione = 0
    let response = await getAllegatoResourcesApi().mswfAllegatoFoGetAllegatiByIdIscrizionePost(idIscrizione, getDefaultAxiosConfig());
    return response;

  } catch (err) {
    throw new Error("Errore durante la chiamata mswfAllegatoFoGetAllegatiByIdIscrizionePost - " + err);
  }
}

export async function elencoIscrizioniByCodiceFiscale(cf: any) {
  try {
    var codiceFiscale: ElencoIscrizioniByCfDTO = {
      "codiceFiscale": cf
    }
    const response = await getIscrizioneOrdineResourceApi().msgaIotElencoIscrizioniByCodiceFiscalePost(codiceFiscale, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    throw new Error("Errore durante la chiamata mswfTipologiaIscrizioneGet - " + err);
  }
}

/**
 * Restituisce true se il codice fiscale in input è alla prima iscrizione all'ordine.
 * @returns
 */
export async function isEnabledReiscrizioneOrdine(cf: string) {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeIsEnabledReiscrizioneOrdinePost(cf, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante il recupero delle iscrizioni per " + cf;
    throw new Error(msg + " - " + err);
  }
}

/**
 * Restituisce true se il codice fiscale in input è alla prima iscrizione all'ordine.
 * @returns
 */
export async function isEnabledReiscrizioneSTP(cf: string) {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeIsEnabledReiscrizioneSTPPost(cf, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante il recupero delle iscrizioni per " + cf;
    throw new Error(msg + " - " + err);
  }
}

/**
 * Restituisce i dati delle quota d'iscrizione di un ordine.
 * @returns
 */
export async function getDatiQuotaIscrizioneByOrdine(ordine: string) {
  try {
    var quotaIscrizione: QuotaByOrdineDTO = {
      siglaProvincia: ordine
    }
    const response =  await getAnagrafeResourcesApi().msgaAnagrafeFoGetQuotaByOrdinePost(quotaIscrizione, getDefaultAxiosConfig());
    return response;
  } catch (err) {
    var msg = "Errore durante il recupero dei dati della quota iscrizione per l'ordine: " + ordine;
    throw new Error(msg + " - " + err);
  }
}

/**
 * GET della tipologia societa by id (FO)
 * @returns
 * @param idTipologiaSocieta
 */
export async function getTipologiaSocietaByIdFo(tipolSoc: number) {
  try {
    var tipologiaSocieta: TipologiaSocietaByIdDTO = {
      "idTipologiaSocieta": tipolSoc
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetTipologiaSocietaByIdPost(tipologiaSocieta, getDefaultAxiosConfig())
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero della tipologia societa. " );
    throw new Error("Errore durante la chiamata per il recupero della tipologia societa. " );

  }
}

/**
 * GET della forma giuridica by id (FO)
 * @returns
 * @param idFormaGiuridica
 */
export async function getFormaGiuridicaByIdFo(formaGiuridica: number) {
  try {
    var formaGiurid: FormaGiuridicaByIdDTO = {
      "idFormaGiuridica": formaGiuridica
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetFormaGiuridicaByIdPost(formaGiurid, getDefaultAxiosConfig())
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero della forma giuridica. " );
    throw new Error("Errore durante la chiamata per il recupero della forma giuridica. " );

  }
}

/**
 * GET del codice ateco by id (FO)
 * @returns
 * @param idCodiceAteco
 */
export async function getCodiceAtecoByIdFo(codAteco: string) {
  try {
    var codiceAteco: CodiceAtecoByIdDTO = {
      "idCodiceAteco": codAteco
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetCodiceAtecoByIdPost(codiceAteco, getDefaultAxiosConfig())
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero del codice ateco. " );
    throw new Error("Errore durante la chiamata per il recupero del codice ateco. " );

  }
}

/**
 * GET della tipologia societa by id (BO)
 * @returns
 * @param idTipologiaSocieta
 */
export async function getTipologiaSocietaByIdBo(tipolSoc: number) {
  try {
    var tipologiaSocieta: TipologiaSocietaByIdDTO = {
      "idTipologiaSocieta": tipolSoc
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoGetTipologiaSocietaByIdPost(tipologiaSocieta, getDefaultAxiosConfig())
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero della tipologia societa. " );
    throw new Error("Errore durante la chiamata per il recupero della tipologia societa. " );

  }
}

/**
 * GET della forma giuridica by id (BO)
 * @returns
 * @param idFormaGiuridica
 */
export async function getFormaGiuridicaByIdBo(formaGiuridica: number) {
  try {
    var formaGiurid: FormaGiuridicaByIdDTO = {
      "idFormaGiuridica": formaGiuridica
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoGetFormaGiuridicaByIdPost(formaGiurid, getDefaultAxiosConfig())
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero della forma giuridica. " );
    throw new Error("Errore durante la chiamata per il recupero della forma giuridica. " );

  }
}

/**
 * GET del codice ateco by id (BO)
 * @returns
 * @param idCodiceAteco
 */
export async function getCodiceAtecoByIdBo(codAteco: string) {
  try {
    var codiceAteco: CodiceAtecoByIdDTO = {
      "idCodiceAteco": codAteco
    }
    const response = await getAnagrafeResourcesApi().msgaAnagrafeBoGetCodiceAtecoByIdPost(codiceAteco, getDefaultAxiosConfig())
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata per il recupero del codice ateco. " );
    throw new Error("Errore durante la chiamata per il recupero del codice ateco. " );

  }
}

/**
 * GET tipologie documento
 * @returns
 */
export async function getTipologieDocumento() {
  try {
    const response = await getAnagrafeResourcesApi().msgaAnagrafeFoGetTipologiaDocumentoPost(getDefaultAxiosConfig());
    return response;
  } catch (err) {
    console.error("Errore durante la chiamata msgaAnagrafeFoGetTipologiaDocumentoPost - " + err);
    throw new Error("Errore durante la chiamata msgaAnagrafeFoGetTipologiaDocumentoPost - " + err);
  }
}

