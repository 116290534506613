import styled from 'styled-components'

export const WrapInfDelibera = styled.div`
  .form-group{
    margin:10px 0px;
  }
  .col-protocol{
    display:flex;
    align-items:center;
    justify-content:start;
    flex-wrap:wrap;
    .fill{
      width:100%;
    }
    .data{
      margin-left:10px;
    }
  }
`;

export const DettagliPofStyled = styled.div`
  .form-cfp{
    
  }

  .actions{
    button{
      margin-left:10px;
    }
  }
  
  .saving-data{
    display:block;
    text-align: center;
    p{
      margin-top:10px;
      width:100%;
    }
    
    .progress-spinner{
      margin:0px auto;
    }
  }
  
  .row-actions{
    text-align:right;
    button{
      margin-left:15px;
    }
  }
  
  .title-form{
    font-weigth:bold;
    font-size:20px;
    margin-bottom:30px;
  }
`;

