//STATS
import {
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RIFIUTA,
  PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA,
  PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE,
  PATH_TO_BO_ANAG_CONTATTI_ORDINI,
  PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO_STP,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO_STP,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_MODIFICA_ISCRIZIONE,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE_STP,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO,
  PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP,
  PATH_TO_BO_ANAG_ELENCO_OPERATORI,
  PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI,
  PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA,
  PATH_TO_BO_ANAG_TRASF_ORDINE_CONCEDI_NULLA_OSTA,
  PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA,
  PATH_TO_BO_ANAG_TRASF_ORDINE_RIFIUTA,
  PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA,
  PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP,
  PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP,
  PATH_TO_BO_ATTI_DISCIPLINARI,
  PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA,
  PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA,
  PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA,
  PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA,
  PATH_TO_BO_CFP_EVENTO_CATALOGO,
  PATH_TO_BO_CONCEDI_DEROGA,
  PATH_TO_BO_CREAZIONE_RIVISTA,
  PATH_TO_BO_CREDITI_FORMATIVI,
  PATH_TO_BO_DASHBOARD,
  PATH_TO_BO_DETTAGLIO_AGENZIA,
  PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO,
  PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO,
  PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE,
  PATH_TO_BO_DETTAGLIO_FORMATORE,
  PATH_TO_BO_DETTAGLIO_POF,
  PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
  PATH_TO_BO_DETTAGLIO_RIVISTA,
  PATH_TO_BO_ELENCO_AGENZIE,
  PATH_TO_BO_ELENCO_ENTI_ESTERNI,
  PATH_TO_BO_ELENCO_EVENTI_CATALOGO,
  PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI,
  PATH_TO_BO_ELENCO_FORMATORI,
  PATH_TO_BO_ELENCO_POF,
  PATH_TO_BO_ELENCO_RICHIESTE_ESONERO,
  PATH_TO_BO_ELENCO_RIVISTE,
  PATH_TO_BO_ENTE_ESTERNO,
  PATH_TO_BO_FORMAZIONE_PROFESSIONALE,
  PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO,
  PATH_TO_BO_LISTA_ISCRITTI_ORDINE,
  PATH_TO_BO_POLIZZE_ELENCO,
  PATH_TO_BO_POLIZZE_ELENCO_CANCELLA,
  PATH_TO_BO_POLIZZE_ELENCO_CONFERMA,
  PATH_TO_BO_POLIZZE_ELENCO_MODIFICA,
  PATH_TO_BO_POLIZZE_ELENCO_RIAPRI,
  PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI,
  PATH_TO_BO_POLIZZE_ELENCO_RIGETTA,
  PATH_TO_BO_POLIZZE_ELENCO_VISUALIZZA,
  PATH_TO_BO_STATS,
  PATH_TO_BO_STATS_DISC,
  PATH_TO_BO_STATS_FOR,
  PATH_TO_BO_STATS_INI_PEC,
  PATH_TO_BO_STATS_POLI,
  PATH_TO_BO_STATS_REG_INDE,
  PATH_TO_ERROR,
  PATH_TO_FO_ANAG_CAMBIO_SEZIONE,
  PATH_TO_FO_ANAG_ISCRIZIONE_OCONAF,
  PATH_TO_FO_ANAG_ISCRIZIONE_OEE,
  PATH_TO_FO_ANAG_ISCRIZIONE_OER,
  PATH_TO_FO_ANAG_ISCRIZIONE_OF,
  PATH_TO_FO_ANAG_ISCRIZIONE_OOT,
  PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE,
  PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA,
  PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA,
  PATH_TO_FO_ANAG_ISCRIZIONE_OSTP,
  PATH_TO_FO_ANAG_ISCRIZIONE_STP,
  PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA,
  PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA,
  PATH_TO_FO_ANAG_REISCRIZIONE_ORDINE,
  PATH_TO_FO_ANAG_REISCRIZIONE_STP,
  PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO,
  PATH_TO_FO_ANAG_SELEZIONA_PROFILO,
  PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE,
  PATH_TO_FO_ASSICURAZIONI,
  PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA,
  PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA,
  PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA,
  PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA,
  PATH_TO_FO_ATTI_DISCIPLINARI,
  PATH_TO_FO_COOKIE,
  PATH_TO_FO_CREDITI_FORMATIVI,
  PATH_TO_FO_DETTAGLIO_AGENZIA,
  PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO,
  PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO,
  PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE,
  PATH_TO_FO_DETTAGLIO_FORMATORE,
  PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO,
  PATH_TO_FO_DETTAGLIO_RIVISTA,
  PATH_TO_FO_ELENCO_AGENZIE,
  PATH_TO_FO_ELENCO_ENTI_ESTERNI,
  PATH_TO_FO_ELENCO_EVENTI_CATALOGO,
  PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI,
  PATH_TO_FO_ELENCO_FORMATORI,
  PATH_TO_FO_ELENCO_POF,
  PATH_TO_FO_ELENCO_RICHIESTE_ESONERO,
  PATH_TO_FO_ELENCO_RIVISTE,
  PATH_TO_FO_FEEDBACK_ACCESSIBILITA,
  PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_CATALOGO,
  PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_INDIVIDUALE,
  PATH_TO_FO_FORMAZIONE_ISCRIZIONE_AF,
  PATH_TO_FO_ISCRITTI_EVENTO_CATALOGO,
  PATH_TO_FO_ISCRIZIONE_FORMATORE,
  PATH_TO_FO_ISCRIZIONE_POF,
  PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO,
  PATH_TO_FO_PRIVACY,
  PATH_TO_FO_RICHIESTA_ESONERO,
  PATH_TO_FO_STUDI_ASSOCIATI_ELENCO,
  PATH_TO_FO_TODO,
  PATH_TO_FO_VISUALIZZA_POF,
  PATH_TO_RICERCA_ALBO_UNICO,
  PATH_TO_RISULTATI_RICERCA_ALBO_UNICO
} from './RoutesConstants'


/**
 * SidafComponents è un array di componenti.
 * Accedendo all'array utilizzando come chiave il percorso alla pagina si ottiene il componente react da visualizzare.
 * I percorsi devono obbligatoriamente essere censiti in RoutesConstants.
 */
export let SidafComponents = {}
SidafComponents[PATH_TO_ERROR] = require('src/errorBoundary/GenericError').default
SidafComponents[PATH_TO_RISULTATI_RICERCA_ALBO_UNICO] = require('src/frontend/app/pages/ricercaAlboUnico/RisultatiRicercaAlboUnico').default
SidafComponents[PATH_TO_RICERCA_ALBO_UNICO] = require('src/frontend/app/pages/RicercaIscrittiAlboUnico').default
SidafComponents[PATH_TO_FO_STUDI_ASSOCIATI_ELENCO] = require('src/frontend/app/pages/studiAssociati/ElencoStudiAssociati').default
SidafComponents[PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO] = require('src/frontend/app/pages/studiAssociati/CreazioneStudioAssociato').default
SidafComponents[PATH_TO_FO_ANAG_RICHIEDI_NUOVO_PROFILO] = require('src/frontend/app/pages/RichiediNuovoProfilo').default
SidafComponents[PATH_TO_FO_ANAG_SELEZIONA_PROFILO] = require('src/frontend/app/pages/selezioneProfilo/SelezioneProfilo').default
SidafComponents[PATH_TO_FO_TODO] = require('src/frontend/app/pages/todo/Todo').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE] = require('src/frontend/app/pages/iscrizioneAlbo/IscrizioneOrdine').default
SidafComponents[PATH_TO_FO_ANAG_REISCRIZIONE_ORDINE] = require('src/frontend/app/pages/iscrizioneAlbo/IscrizioneOrdine').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_MODIFICA] = require('src/frontend/app/pages/iscrizioneAlbo/IscrizioneOrdine').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA] = require('src/frontend/app/pages/iscrizioneAlbo/IscrizioneOrdine').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_OCONAF] = require('src/frontend/app/pages/iscrizioneConafNazionale/IscrizioneConafNazionale').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_OOT] = require('src/frontend/app/pages/iscrizioneOperatoreOrdinteTerritoriale/IscrizioneOperatoreOrdineTerritoriale').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_OF] = require('src/frontend/app/pages/iscrizioneOperatoreFederazione/IscrizioneOperatoreFederazione').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_OSTP] = require('src/frontend/app/pages/iscrizioneOperatoreSTP/IscrizioneOperatoreSTP').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_STP] = require('src/frontend/app/pages/iscrizioneAlboSTP/iscrizioneSTP').default
SidafComponents[PATH_TO_FO_ANAG_TRASFERIMENTO_ORDINE] = require('src/frontend/app/pages/trasferimentoOrdine/RichiestaTrasferimentoOrdine').default
SidafComponents[PATH_TO_FO_ANAG_CAMBIO_SEZIONE] = require('src/frontend/app/pages/cambioSezione/RichiestaCambioSezione').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_STP_MODIFICA] = require('src/frontend/app/pages/iscrizioneAlboSTP/iscrizioneSTP').default
SidafComponents[PATH_TO_BO_CONCEDI_DEROGA] = require('src/backoffice/app/pages/formazione/ConcessioneDeroga').default
SidafComponents[PATH_TO_BO_LISTA_ISCRITTI_ORDINE] = require('src/backoffice/app/pages/formazione/ListaIscrittiOrdine').default
SidafComponents[PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP] = require('src/backoffice/app/pages/formazione/AssegnazioneAutomaticaCFP').default
SidafComponents[PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP] = require('src/backoffice/app/pages/formazione/AssegnazioneManualeCFP').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_STP_VISUALIZZA] = require('src/frontend/app/pages/iscrizioneAlboSTP/VisualizzaDatiStpFO').default
SidafComponents[PATH_TO_FO_ANAG_REISCRIZIONE_STP] = require('src/frontend/app/pages/iscrizioneAlboSTP/iscrizioneSTP').default
SidafComponents[PATH_TO_FO_ASSICURAZIONI] = require('src/frontend/app/pages/assicurazioni/assicurazioni').default
SidafComponents[PATH_TO_FO_ASSICURAZIONI_COMPILA_POLIZZA] = require('src/frontend/app/pages/assicurazioni/compilazionePolizza').default
SidafComponents[PATH_TO_FO_ASSICURAZIONI_CANCELLA_POLIZZA] = require('src/frontend/app/pages/assicurazioni/cancellaPolizza').default
SidafComponents[PATH_TO_FO_ASSICURAZIONI_VISUALIZZA_POLIZZA] = require('src/frontend/app/pages/assicurazioni/visualizzaPolizza').default
SidafComponents[PATH_TO_FO_ASSICURAZIONI_AUMENTA_MASSIMALE_POLIZZA] = require('src/frontend/app/pages/assicurazioni/compilazionePolizza').default
SidafComponents[PATH_TO_FO_ATTI_DISCIPLINARI] = require('src/frontend/app/pages/disciplinari/AttiDisciplinari').default

SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_OEE] = require('src/frontend/app/pages/iscrizioneOperatoreOEE/IscrizioneOperatoreOEE').default
SidafComponents[PATH_TO_FO_ANAG_ISCRIZIONE_OER] = require('src/frontend/app/pages/iscrizioneOperatoreOER/IscrizioneOperatoreOER').default
SidafComponents[PATH_TO_FO_ISCRIZIONE_POF] = require('src/frontend/app/pages/iscrizioneAlboEF/IscrizionePOF').default
SidafComponents[PATH_TO_FO_ELENCO_POF] = require('src/frontend/app/pages/iscrizioneAlboEF/ElencoPOF').default
SidafComponents[PATH_TO_FO_VISUALIZZA_POF] = require('src/frontend/app/pages/iscrizioneAlboEF/VisualizzaPof').default
SidafComponents[PATH_TO_FO_FORMAZIONE_ISCRIZIONE_AF] = require('src/frontend/app/pages/iscrizioneAlboEF/IscrizioneEF').default
SidafComponents[PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_CATALOGO] = require('src/frontend/app/pages/iscrizioneAlboEF/CreazioneEventoFormativo').default
SidafComponents[PATH_TO_FO_FORMAZIONE_CREAZIONE_EVENTO_INDIVIDUALE] = require('src/frontend/app/pages/iscrizioneAlboEF/CreazioneEventoIndividuale').default

SidafComponents[PATH_TO_FO_PRIVACY] = require('src/frontend/app/pages/Privacy').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI] = require('src/backoffice/app/pages/ElencoIscritti').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_OPERATORI] = require('src/backoffice/app/pages/ElencoOperatori').default
SidafComponents[PATH_TO_BO_DASHBOARD] = require('src/backoffice/app/pages/Dashboard').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_ISCRITTO] = require('src/backoffice/app/pages/iscrizione/VisualizzaDatiIscritto').default
SidafComponents[PATH_TO_BO_ANAG_CONTATTI_ORDINI_DETTAGLI] = require('src/backoffice/app/pages/CaratteristicheOrdiniFederazioni/VisualizzaDettagliOrdiniFederazioni').default
SidafComponents[PATH_TO_BO_ANAG_CONTATTI_ORDINI] = require('src/backoffice/app/pages/CaratteristicheOrdiniFederazioni/ElencoOrdiniFederazioni').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO] = require('src/backoffice/app/pages/iscrizione/ConfermaIscritto').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE] = require('src/backoffice/app/pages/iscrizione/RichiediIntegrazioni').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE] = require('src/backoffice/app/pages/iscrizione/RigettaIscrizione').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_MODIFICA_ISCRIZIONE] = require('src/backoffice/app/pages/iscrizione/ModificaIscrizione').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO] = require('src/backoffice/app/pages/iscrizione/CancellaIscritto').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CONFERMA_ISCRITTO_STP] = require('src/backoffice/app/pages/iscrizioneSTP/ConfermaIscrizioneSTP').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RICHIEDI_INTEGRAZIONI_ISCRIZIONE_STP] = require('src/backoffice/app/pages/iscrizioneSTP/RichiediIntegrazioniSTP').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_RIGETTA_ISCRIZIONE_STP] = require('src/backoffice/app/pages/iscrizioneSTP/RigettaIscrizioneSTP').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_CANCELLA_ISCRITTO_STP] = require('src/backoffice/app/pages/iscrizioneSTP/CancellaSTP').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_VISUALIZZA_DATI_STP] = require('src/backoffice/app/pages/iscrizioneSTP/VisualizzaDatiStpBO').default
SidafComponents[PATH_TO_BO_ANAG_TRASF_ORDINE_RICHIESTA] = require('src/backoffice/app/pages/ListaRichiesteTrasferimentoOrdine').default
SidafComponents[PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RIFIUTA] = require('src/backoffice/app/pages/cambioSezione/RifiutaCambioSezione').default
SidafComponents[PATH_TO_BO_ANAG_TRASF_ORDINE_RIFIUTA] = require('src/backoffice/app/pages/trasferimentoOrdine/RifiutaTrasferimentoOrdine').default
SidafComponents[PATH_TO_BO_ANAG_CAMBIO_SEZIONE_RICHIESTA] = require('src/backoffice/app/pages/ListaRichiesteCambioSezione').default
SidafComponents[PATH_TO_BO_ANAG_TRASF_ORDINE_CONCEDI_NULLA_OSTA] = require('src/backoffice/app/pages/trasferimentoOrdine/ConcediNullaOsta').default
SidafComponents[PATH_TO_BO_ANAG_TRASF_ORDINE_VISUALIZZA] = require('src/backoffice/app/pages/trasferimentoOrdine/VisualizzaRichTrasferimentoOrdine').default
SidafComponents[PATH_TO_BO_ANAG_TRASF_ORDINE_ACCETTA] = require('src/backoffice/app/pages/trasferimentoOrdine/AccettaTrasferimentoOrdine').default
SidafComponents[PATH_TO_BO_ANAG_CAMBIO_SEZIONE_ACCETTA] = require('src/backoffice/app/pages/cambioSezione/AccettaCambioSezione').default
SidafComponents[PATH_TO_BO_ANAG_CAMBIO_SEZIONE_VISUALIZZA] = require('src/backoffice/app/pages/cambioSezione/VisualizzaRichCambioSezione').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_OPERATORI_GESTIONE_UTENTI] = require('src/backoffice/app/pages/GestioneUtenti').default
SidafComponents[PATH_TO_BO_ANAG_ELENCO_ISCRITTI_REGISTRAZIONE_PAGAMENTO] = require('src/backoffice/app/pages/RegistrazionePagamentoQuoteAnnuali/RegistrazionePagamento').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO] = require('src/backoffice/app/pages/ElencoPolizze').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO_VISUALIZZA] = require('src/backoffice/app/pages/polizze/VisualizzaPolizza').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO_CONFERMA] = require('src/backoffice/app/pages/polizze/ConfermaPolizza').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO_RIGETTA] = require('src/backoffice/app/pages/polizze/RigettaPolizza').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO_RIAPRI] = require('src/backoffice/app/pages/polizze/RiapriPolizza').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO_MODIFICA] = require('src/backoffice/app/pages/polizze/ModificaPolizza').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO_CANCELLA] = require('src/backoffice/app/pages/polizze/CancellaPolizza').default
SidafComponents[PATH_TO_BO_POLIZZE_ELENCO_RICHIEDI_INTEGRAZIONI] = require('src/backoffice/app/pages/polizze/RichiediIntegrazioniPolizza').default
SidafComponents[PATH_TO_BO_ATTI_DISCIPLINARI] = require('src/backoffice/app/pages/AttiDisciplinari').default
SidafComponents[PATH_TO_BO_ATTI_DISCIPLINARI_NUOVA] = require('src/backoffice/app/pages/Disciplinari/creazioneDisciplinari').default
SidafComponents[PATH_TO_BO_ATTI_DISCIPLINARI_VISUALIZZA] = require('src/backoffice/app/pages/Disciplinari/visualizzaDisciplinari').default
SidafComponents[PATH_TO_BO_ATTI_DISCIPLINARI_MODIFICA] = require('src/backoffice/app/pages/Disciplinari/modificaDisciplinari').default
SidafComponents[PATH_TO_BO_ATTI_DISCIPLINARI_CANCELLA] = require('src/backoffice/app/pages/Disciplinari/cancellaDisciplinare').default
SidafComponents[PATH_TO_BO_ANAG_COMUNICAZIONE_QUOTE_ISCRIZIONE] = require('src/backoffice/app/pages/QuoteIscrizione/ComunicazioneQuoteIscrizione').default

SidafComponents[PATH_TO_BO_FORMAZIONE_PROFESSIONALE] = require('src/backoffice/app/pages/FormazioneProfessionale').default
SidafComponents[PATH_TO_BO_ELENCO_RICHIESTE_ESONERO] = require('src/backoffice/app/pages/formazione/ElencoRichiesteEsonero').default
SidafComponents[PATH_TO_FO_ELENCO_RICHIESTE_ESONERO] = require('src/backoffice/app/pages/formazione/ElencoRichiesteEsonero').default
SidafComponents[PATH_TO_BO_CREDITI_FORMATIVI] = require('src/backoffice/app/pages/formazione/CreditiFormativi').default
SidafComponents[PATH_TO_FO_CREDITI_FORMATIVI] = require('src/backoffice/app/pages/formazione/CreditiFormativi').default
SidafComponents[PATH_TO_FO_RICHIESTA_ESONERO] = require('src/frontend/app/pages/richiestaEsonero/RichiestaEsonero').default
SidafComponents[PATH_TO_FO_ELENCO_AGENZIE] = require('src/backoffice/app/pages/formazione/ElencoAgenzie').default
SidafComponents[PATH_TO_FO_DETTAGLIO_AGENZIA] = require('src/backoffice/app/pages/formazione/DettaglioAgenzia').default
SidafComponents[PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO] = require('src/backoffice/app/pages/formazione/DettaglioEnteEsterno').default
SidafComponents[PATH_TO_FO_DETTAGLIO_RIVISTA] = require('src/backoffice/app/pages/formazione/DettaglioRivista').default
SidafComponents[PATH_TO_FO_ELENCO_EVENTI_CATALOGO] = require('src/backoffice/app/pages/formazione/ElencoEventiCatalogo').default
SidafComponents[PATH_TO_FO_ISCRITTI_EVENTO_CATALOGO] = require('src/backoffice/app/pages/formazione/IscrittiEventoCatalogo').default
SidafComponents[PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO] = require('src/backoffice/app/pages/formazione/DettaglioEventoCatalogo').default
SidafComponents[PATH_TO_FO_ELENCO_EVENTI_INDIVIDUALI] = require('src/backoffice/app/pages/formazione/ElencoEventiIndividuali').default
SidafComponents[PATH_TO_FO_DETTAGLIO_EVENTO_INDIVIDUALE] = require('src/backoffice/app/pages/formazione/DettaglioEventoIndividuale').default

SidafComponents[PATH_TO_FO_ELENCO_ENTI_ESTERNI] = require('src/backoffice/app/pages/formazione/ElencoAgenzie').default
SidafComponents[PATH_TO_FO_ELENCO_RIVISTE] = require('src/backoffice/app/pages/formazione/ElencoAgenzie').default

SidafComponents[PATH_TO_BO_ELENCO_AGENZIE] = require('src/backoffice/app/pages/formazione/ElencoAgenzie').default
SidafComponents[PATH_TO_BO_DETTAGLIO_AGENZIA] = require('src/backoffice/app/pages/formazione/DettaglioAgenzia').default
SidafComponents[PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO] = require('src/backoffice/app/pages/formazione/DettaglioEnteEsterno').default
SidafComponents[PATH_TO_BO_DETTAGLIO_RIVISTA] = require('src/backoffice/app/pages/formazione/DettaglioRivista').default
SidafComponents[PATH_TO_BO_ELENCO_EVENTI_CATALOGO] = require('src/backoffice/app/pages/formazione/ElencoEventiCatalogo').default
SidafComponents[PATH_TO_BO_ISCRITTI_EVENTO_CATALOGO] = require('src/backoffice/app/pages/formazione/IscrittiEventoCatalogo').default
SidafComponents[PATH_TO_BO_CFP_EVENTO_CATALOGO] = require('src/backoffice/app/pages/formazione/IscrittiOrdineCFP').default
SidafComponents[PATH_TO_BO_DETTAGLIO_EVENTO_CATALOGO] = require('src/backoffice/app/pages/formazione/DettaglioEventoCatalogo').default
SidafComponents[PATH_TO_BO_ELENCO_EVENTI_INDIVIDUALI] = require('src/backoffice/app/pages/formazione/ElencoEventiIndividuali').default
SidafComponents[PATH_TO_BO_DETTAGLIO_EVENTO_INDIVIDUALE] = require('src/backoffice/app/pages/formazione/DettaglioEventoIndividuale').default
SidafComponents[PATH_TO_BO_ELENCO_POF] = require('src/backoffice/app/pages/formazione/RichiestePOF').default
SidafComponents[PATH_TO_BO_DETTAGLIO_POF] = require('src/backoffice/app/pages/formazione/DettagliPOF').default
SidafComponents[PATH_TO_BO_DETTAGLIO_POF] = require('src/backoffice/app/pages/formazione/DettagliPOF').default
SidafComponents[PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO] = require('src/backoffice/app/pages/formazione/DettaglioRichiestaEsonero').default
SidafComponents[PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO] = require('src/backoffice/app/pages/formazione/DettaglioRichiestaEsonero').default
SidafComponents[PATH_TO_FO_ISCRIZIONE_FORMATORE] = require('src/backoffice/app/pages/anagrafica/formFormatori/FormFormatori').default
SidafComponents[PATH_TO_FO_ELENCO_FORMATORI] = require('src/backoffice/app/pages/anagrafica/ElencoFormatori/ElencoFormatori').default
SidafComponents[PATH_TO_BO_ELENCO_FORMATORI] = require('src/backoffice/app/pages/anagrafica/ElencoFormatori/ElencoFormatori').default
SidafComponents[PATH_TO_BO_DETTAGLIO_FORMATORE] = require('src/backoffice/app/pages/anagrafica/DettaglioFormatore/DettaglioFormatore').default
SidafComponents[PATH_TO_FO_DETTAGLIO_FORMATORE] = require('src/backoffice/app/pages/anagrafica/DettaglioFormatore/DettaglioFormatore').default

SidafComponents[PATH_TO_BO_ELENCO_ENTI_ESTERNI] = require('src/backoffice/app/pages/formazione/ElencoAgenzie').default
SidafComponents[PATH_TO_BO_ELENCO_RIVISTE] = require('src/backoffice/app/pages/formazione/ElencoAgenzie').default
SidafComponents[PATH_TO_BO_ENTE_ESTERNO] = require('src/backoffice/app/pages/formazione/formEntiEsterni/FormEntiEsterni').default
SidafComponents[PATH_TO_BO_CREAZIONE_RIVISTA] = require('src/backoffice/app/pages/formazione/formRiviste/FormRiviste').default

//STATS
SidafComponents[PATH_TO_BO_STATS] = require('src/backoffice/app/pages/Statistiche').default
SidafComponents[PATH_TO_BO_STATS_POLI] = require('src/backoffice/app/pages/Statistiche').default
SidafComponents[PATH_TO_BO_STATS_FOR] = require('src/backoffice/app/pages/Statistiche').default
SidafComponents[PATH_TO_BO_STATS_DISC] = require('src/backoffice/app/pages/Statistiche').default
SidafComponents[PATH_TO_BO_STATS_INI_PEC] = require('src/backoffice/app/pages/Statistiche').default
SidafComponents[PATH_TO_BO_STATS_REG_INDE] = require('src/backoffice/app/pages/Statistiche').default
SidafComponents[PATH_TO_FO_COOKIE] = require('src/frontend/app/pages/Cookie').default
SidafComponents[PATH_TO_FO_FEEDBACK_ACCESSIBILITA] = require('src/frontend/app/pages/FeedbackAccessibilita').default
