import { getDenominazioneByCodiceGruppo } from 'src/processes/SelezioneProfilo'
import {
  findSTPByPIva,
  GetIscrizioneStpIscrittaByPartitaIvaPost,
  verificaStatoIscrizioneRadiataFO
} from 'src/processes/Soggetto'
import { deepJsonCopy } from 'src/utilities/utility'

export const GROUP_ISCRITTI = "GROUP_ISCRITTI";
export const GROUP_AUTHENTICATED = "GROUP_AUTHENTICATED";
export const GROUP_ODAF = "ODAF";
export const GROUP_FODAF = "FODAF";
export const GROUP_CONAF = "CONAF";
export const GROUP_STP = "STP";
export const GROUP_FO = "FO";
export const GROUP_RFO = "RFO";
export const GROUP_FOR = "FOR";
export const GROUP_FOEE = "FOEE";

/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
export class TokenStore {

    /**
     * Rappresenta l'istanza del TokenStore.
     */
    private static instance: TokenStore;

    /**
     * Rappresenta il token JWT.
     */
    private tokenJWT: any = "";

    /**
     * Rappresenta il current account dell'utente.
     */
    private currentAccount: any = "";

    /**
     * Contiene i current account dei vari profili dell'utente.
     */
    private currentAccountList: Array<any> = [];

    /**
     * Rappresenta l'istanza di admin client del context.
     */
    private adminClient: any = "";

    /**
     * Rappresenta l'istanza di keycloak.
     */
    private keycloak: any = "";

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() { }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): TokenStore {
        if (!TokenStore.instance) {
            TokenStore.instance = new TokenStore();
        }
        return TokenStore.instance;
    }

    /**
     * Imposta il token, l'istanza di keycloak, l'istanza di adminClient e il currentAccount del profilo personale.
     * @param token
     * @param keycloak
     * @param adminClient
     */
    public static initialize(token, keycloak, adminClient) {

        this.getInstance().setTokenJWT(token);
        this.getInstance().setKeycloak(keycloak)
        this.getInstance().setAdminClient(adminClient)

        // Al primo accesso non è stato ancora impostato l'id del profilo selezionato, quindi lo imposto.
        var selectedCurrentAccountSessionStorage = window.sessionStorage.getItem("selectedCurrentAccount");

        var selectedCurrentAccount: string | number;
        if (selectedCurrentAccountSessionStorage != null) {
            selectedCurrentAccount = Number(selectedCurrentAccountSessionStorage);
        } else {
            selectedCurrentAccount = TokenStore.getInstance().getCurrentAccountPersonaleId();
        }

        window.sessionStorage.setItem("selectedCurrentAccount", selectedCurrentAccount+"");
        this.getInstance().setCurrentAccount(selectedCurrentAccount);
    }

    /**
     * Imposta il token JWT corrente.
     * @param tokenJWTFromAuthentication
     */
    public setTokenJWT(tokenJWTFromAuthentication) {
        TokenStore.getInstance().tokenJWT = tokenJWTFromAuthentication;
    }

    /**
     * Restituisce il token JWT corrente.
     * @returns
     */
    public getTokenJWT() {
        return TokenStore.getInstance().tokenJWT;
    }

    /**
     * Restituisce il token JWT decodificato.
     * @returns
     */
    public getDecodedTokenJWT() {
        return TokenStore.getInstance().getDecodedTokenJWTWithoutCheck()
    }

    /**
     *  Definisce una funzione denominata b64DecodeUnicode che accetta un singolo parametro 'str'
     * @returns
     */
    public b64DecodeUnicode(str) {
        // Decode the Base64-encoded string 'str' and then split it into an array of characters
        return decodeURIComponent(
            atob(str).split('').map(function(c) {
                // For each character in the array, convert it to its Unicode code point representation
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')
        );
    }

    /**
     * Restituisce il token JWT decodificato.
     * @returns
     */
    public getDecodedTokenJWTWithoutCheck() {
        try {
            // Get an instance of TokenStore (assuming it's a singleton)
            let instance = TokenStore.getInstance();

            // Extract the second part (payload) of the JWT token
            let toBeDecoded = instance.getTokenJWT().split('.')[1];

            // Decode the payload using the b64DecodeUnicode function
            let decodedData = this.b64DecodeUnicode(toBeDecoded);

            // Parse the decoded payload as JSON to get the JWT payload
            const jwtPayload = JSON.parse(decodedData);

            // Return the decoded JWT payload
            return jwtPayload;
        } catch (err) {
            // If an error occurs during the decoding or parsing, catch it here
            // You might want to handle errors appropriately, such as logging them or returning a default value
            console.error("Error decoding JWT token:", err);
            return null; // Return null or any other default value
        }
    }

    /**
     * Recupera la decode del token, serve a capire perchè vedo il token decodificato
     * ma la getDecodedTokenJWT ritorna errore sul token sulla JSON.parse...
     * @returns
     */
    public decodeToken() {
        //return jwt_decode(TokenStore.instance.tokenJWT);//errore blocca
        return TokenStore.getInstance().getDecodedTokenJWTWithoutCheck();
    }

    /**
     * Stampa il token in formato JSON pretty
     * @returns
     */
    public dumpPrettyToken() {
        return JSON.stringify(TokenStore.getInstance().decodeToken(), null, 4);
    }

    /**
     * Ottiene una copia stringa del token decodificato
     * @returns
     */
    public stringifyToken() {
        try{
            return JSON.stringify(TokenStore.getInstance().decodeToken());
        }catch(e){
            return "";
        }

    }

    /**
     * Provo a parsare il token decodificato come oggetto Javascript
     * ma la getDecodedTokenJWT a volte ritorna errore sul token sulla JSON.parse...
     * @returns
     */
    public parseToken() {
        try{
            return JSON.parse(TokenStore.getInstance().stringifyToken());
        }catch(e){
            return "";
        }
    }

    /**
     * Funzione che verifica se il token è scaduto o meno
     * prendere con le pinze...
     * @returns
     */
    public isTokenExpired() {
        //;
        const base64Url = TokenStore.getInstance().getTokenJWT().split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // TODO FIXME Qui va getHome?
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        const { exp } = JSON.parse(jsonPayload);
        const expired = Date.now() >= exp * 1000
        return expired
    }

    /**
     * Restituisce la lista dei current account. Se la lista è vuota invoca il metodo buildCurrentAccountListFromTokenProfile.
     * @returns
     */
    public getCurrentAccountList() {
        if (TokenStore.getInstance().currentAccountList === undefined ||
            TokenStore.getInstance().currentAccountList === null ||
            TokenStore.getInstance()?.currentAccountList?.length === 0
        )
            TokenStore.getInstance().currentAccountList = TokenStore.getInstance().buildCurrentAccountListFromTokenProfile();
        return TokenStore.getInstance().currentAccountList;
    }

    /**
     * Restituisce l'istanza di keycloak.
     * @returns
     */
    public getKeycloak() {
        return TokenStore.getInstance().keycloak;
    }

    /**
     * Imposta l'istanza di keycloak.
     * @returns
     */
    public setKeycloak(keycloak) {
        TokenStore.getInstance().keycloak = keycloak;
    }

    /**
     * Restituisce l'istanza di adminClient.
     * @returns
     */
    public getAdminClient() {
        return TokenStore.getInstance().adminClient;
    }

    /**
     * Imposta l'istanza di adminClient.
     * @returns
     */
    public setAdminClient(adminClient) {
        TokenStore.getInstance().adminClient = adminClient;
    }

    /**
     * Imposta il current account dell'indice id nella lista currentAccountList.
     */
    public setCurrentAccount(index) {
        if (index < 0 || index > this.getCurrentAccountList().length)
            index = this.getCurrentAccountPersonaleId();
        TokenStore.getInstance().currentAccount = this.getCurrentAccountList()[index];
        window.sessionStorage.setItem("selectedCurrentAccount", index+"");
    }

    /**
     * Forza il current account a GROUP_AUTHENTICATED, se presente.
     */
    public setCurrentAccountAsAuthenticated() {
        if (TokenStore.getInstance().isCurrentAccountAuthenticated())
            return;

        // Cerco il gruppo GROUP_AUTHENTICATED, se lo trovo restituisco l'id di questo account
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup === GROUP_AUTHENTICATED) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });

        // Se non ho trovato il gruppo GROUP_ISCRITTI cerco un altro (qualsiasi)
        if (groupFound) {
            this.setCurrentAccount(index);
        }
    }

    /**
     * Forza il current account a GROUP_STP, se presente.
     */
    public setCurrentAccountAsSTP() {
        if (TokenStore.getInstance().isCurrentAccountSTP())
            return;

        // Cerco il gruppo GROUP_AUTHENTICATED, se lo trovo restituisco l'id di questo account
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_STP)) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });

        // Se non ho trovato il gruppo GROUP_ISCRITTI cerco un altro (qualsiasi)
        if (groupFound) {
            this.setCurrentAccount(index);
        }
    }

    /**
     * Forza il current account a GROUP_ISCRITTI, se presente.
     */
    public setCurrentAccountAsIscrittoOrdine() {
        if (TokenStore.getInstance().isCurrentAccountIscrittoOrdine())
            return;

        // Cerco il gruppo GROUP_ISCRITTI, se lo trovo restituisco l'id di questo account
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup === GROUP_ISCRITTI) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });

        // Se non ho trovato il gruppo GROUP_ISCRITTI cerco un altro (qualsiasi)
        if (groupFound) {
            this.setCurrentAccount(index);
        }
    }

    /**
     * Restituisce il current account.
     * Se il current account è null, imposta il current account personale.
     * @returns
     */
    public getCurrentAccount() {
        if (TokenStore.getInstance() === undefined
            || TokenStore.getInstance() === null
            || TokenStore.getInstance().currentAccount === undefined
            || TokenStore.getInstance().currentAccount === null) {
            TokenStore.getInstance().currentAccount = this.getCurrentAccountPersonale();
        }
        return TokenStore.getInstance().currentAccount;
    }

    /**
     * Recupera l'account dalla lista currentAccountList per l'index specificato.
     * @param {number} index
     * @returns selected currentAccount
     */
    public getCurrentAccountByIndex(index) {
        try {
            if (index < 0 || index > TokenStore.getInstance().currentAccountList.length) {
                index = this.getCurrentAccountPersonaleId();
            }

            if (TokenStore.getInstance().currentAccountList !== undefined && TokenStore.getInstance().currentAccountList.length > 0) {
                return TokenStore.getInstance().currentAccountList[index];
            } else {
                throw new Error("Invalid currentAccountList in getCurrentAccountByIndex")
            }
        } catch (e) {
            throw e;
        }
    }

    /**
     * Costruisce la lista degli headers Current-Account a partire dal profile del token JWT.
     */
    public buildCurrentAccountListFromTokenProfile() {

        // Decodifica il token
        //let decodedTokenJWT = jwt_decode<DecodedJwt>(TokenStore.instance.getTokenJWT());
        let decodedTokenJWT = TokenStore.getInstance().decodeToken();

        // Recupera il profile
        const profile = JSON.parse(decodedTokenJWT.profile);

        // Verifica quanti account ci sono
        const nAccounts = Object.keys(profile.accounts).length;

        // Inizializza la lista dei Current-Account
        TokenStore.getInstance().currentAccountList = Array.from({ length: nAccounts });

        // Costruisce i Current-Account
        Object.keys(profile.accounts).map((specificGroupToLeave, indexAccountToLeave) => {
            TokenStore.getInstance().currentAccountList[indexAccountToLeave] = TokenStore.getInstance().buildCurrentAccountFrom(profile, indexAccountToLeave);
        })

        // Restituisce la lista dei Current-Account
        return TokenStore.getInstance().currentAccountList;
    }

    /**
     * Costruisce un singolo Current-Account a partire dal token profile.
     * Crea una copia del profile ed elimina gli account diversi dall'account
     * con indice indexAccountToLeave.
     *
     * @param {*} profile
     * @param {*} specificGroupToLeave
     * @param {*} indexAccountToLeave
     * @returns
     */
    public buildCurrentAccountFrom(profile, indexAccountToLeave) {
        var currentAccount = deepJsonCopy(profile);

        Object.keys(profile.accounts).map((specificGroupToRemove, indexAccountToRemove) => {
            if (indexAccountToLeave !== indexAccountToRemove) {
                delete currentAccount.accounts[specificGroupToRemove];
            }
        });

        return deepJsonCopy(currentAccount);
    }

    /**
     * Restituisce il currentAccount personale.
     * @returns
     */
    public getCurrentAccountPersonale() {
        return TokenStore.getInstance().getCurrentAccountList()[this.getCurrentAccountPersonaleId()];
    }

    /**
     * Restituisce l'id del currentAccount.
     * @returns
     */
    public getCurrentAccountId() {
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccountCycle => {
            const currentKeysCycle = Object.keys(currentAccountCycle.accounts)[0];
            const currentSpecificGroupNameCycle = currentAccountCycle.accounts[currentKeysCycle].specificGroup;

            const currentAccountKeys = Object.keys(this.getCurrentAccount().accounts)[0];
            const currentAccountSpecificGroupName = this.getCurrentAccount().accounts[currentAccountKeys].specificGroup;

            if (currentSpecificGroupNameCycle === currentAccountSpecificGroupName) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });
        return index;
    }

    /**
     * Restituisce l'id del currentAccount personale nella lista currentAccountList.
     * Es. Se il currentAccount personale si trova nell'indice 0 restituirà 0.
     * @returns
     */
    public getCurrentAccountPersonaleId() {

        // Cerco il gruppo GROUP_ISCRITTI, se lo trovo restituisco l'id di questo account
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup === GROUP_ISCRITTI) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });

        // Se non ho trovato il gruppo GROUP_ISCRITTI cerco un altro (qualsiasi)
        if (groupFound === false) {
            index = 0;
            TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
                const currentKeys = Object.keys(currentAccount.accounts)[0];
                if (currentAccount.accounts[currentKeys].specificGroup !== GROUP_AUTHENTICATED
                    && currentAccount.accounts[currentKeys].specificGroup !== 'FO') {
                    groupFound = true;
                }
                if (!groupFound)
                    index++;
            });
        }

        // Se non ho trovato il gruppo GROUP_ISCRITTI cerco il gruppo GROUP_AUTHENTICATED
        if (groupFound === false) {
            index = 0;
            TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
                const currentKeys = Object.keys(currentAccount.accounts)[0];
                if (currentAccount.accounts[currentKeys].specificGroup === GROUP_AUTHENTICATED) {
                    groupFound = true;
                }
                if (!groupFound)
                    index++;
            });
        }

        // Se non ho trovato neanche GROUP_AUTHENTICATED sollevo un'eccezione
        if (groupFound === false) {
            throw new Error("Errore nei gruppi all'interno del token, contattare l'amministratore")
        }

        return index;
    }

    /**
     * Restituisce true se l'utente ha almeno un profilo, oltre quello GROUP_AUTHENTICATED, false altrimenti.
     * @returns
     */
    public hasAtLeastOneProfile() {
        var nProfiles = this.getCurrentAccountList().length;
        if (nProfiles === 1) {
            var currentAccount = TokenStore.getInstance().getCurrentAccountList()[0];
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup === GROUP_AUTHENTICATED) {
                return false;
            }
        }
        var groupFound = false;
        if (nProfiles > 1) {
            TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
                const currentKeys = Object.keys(currentAccount.accounts)[0];
                if (currentAccount.accounts[currentKeys].specificGroup !== GROUP_AUTHENTICATED) {
                    groupFound = true;
                }
            });
        }
        return groupFound;
    }

    /**
     * Restituisce true se l'utente ha un'iscrizione all'ordine territoriale, false altrimenti.
     * @returns
     */
    public isIscrittoOrdine() {
        // Cerco il gruppo GROUP_ISCRITTI, se lo trovo restituisco true
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup === GROUP_ISCRITTI) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });
        return groupFound;
    }

    /**
    * Restituisce true se l'utente è un operatore responsabile STP, altrimenti restituisce false.
    * @returns
    */
    public isOperatoreResponsabileSTP() {
       var groupFound = false;
       var index = 0;
       TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
           const currentKeys = Object.keys(currentAccount.accounts)[0];
           if (currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_STP) && currentAccount.accounts[currentKeys].roleList.includes("RO_RESP_STP")) {
               groupFound = true;
           }
           if (!groupFound)
               index++;

       });
       return groupFound;
   }

    /**
     * Restituisce true se l'utente è un operatore responsabile della sua STP, altrimenti restituisce false.
     * @returns
     */
    public async isOperatoreResponsabilePropriaSTP() {
        var groupFound = false;
        var index = 0;
        var codiceFiscaleRappresentanteSTP = this.getDecodedTokenJWT().fiscalNumber
        var currentCf = this.getDecodedTokenJWT().fiscalNumber
        var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
        var pIva = specificGroup.split("_")[1]

        if (specificGroup.startsWith("STP_")){
            var soggettoSTP = await findSTPByPIva(pIva)
            if(soggettoSTP?.data){
                codiceFiscaleRappresentanteSTP = soggettoSTP.data.codiceFiscaleResponsabileStp
            }
        }

        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_STP) && currentAccount.accounts[currentKeys].roleList.includes("RO_RESP_STP")) {
                // il codice fiscale dell'STP è uguale a quello dell'utente loggato
                if (codiceFiscaleRappresentanteSTP && currentCf === codiceFiscaleRappresentanteSTP) {
                    groupFound = true;
                } else {
                    groupFound = false;
                }
                // ELSE FALSE
            }
            if (!groupFound)
                index++;

        });
        return groupFound;
    }


    /**
     * Restituisce true se l'utente è un operatore STP, altrimenti restituisce false.
     * @returns
     */
    public isOperatoreSTP() {
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_STP)) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });
        return groupFound;
    }

    /**
     * Restituisce true se l'utente è un operatore CONAF, altrimenti restituisce false.
     * @returns
     */
    public isOperatoreCONAF() {
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_CONAF)) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });
        return groupFound;
    }

    /**
     * Restituisce true se l'utente è un operatore FODAF, altrimenti restituisce false.
     * @returns
     */
    public isOperatoreFODAF() {
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_FODAF)) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });
        return groupFound;
    }

    /**
     * Restituisce true se l'utente è un operatore ODAF, altrimenti restituisce false.
     * @returns
     */
    public isOperatoreODAF() {
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_ODAF)) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });
        return groupFound;
    }

    /**
     * Restituisce true se il current account corrente è GROUP_AUTHENTICATED, false altrimenti.
     * @returns
     */
    public isCurrentAccountAuthenticated() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_AUTHENTICATED);
    }

    /**
     * Restituisce true se il current account corrente è CONAF, false altrimenti.
     * @returns
     */
    public isCurrentAccountCONAF() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_CONAF);
    }

    /**
     * Restituisce true se il current account corrente è FODAF, false altrimenti.
     * @returns
     */
    public isCurrentAccountFODAF() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_FODAF);
    }

    /**
     * Restituisce true se il current account corrente è ODAF, false altrimenti.
     * @returns
     */
    public isCurrentAccountODAF() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_ODAF);
    }

    /**
     * Restituisce true se il current account corrente è STP, false altrimenti.
     * @returns
     */
    public isCurrentAccountSTP() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_STP);
    }

    /**
     * Restituisce true se il current account corrente è STP, false altrimenti.
     * @returns
    */
    public async isCurrentAccountSTPAttivo() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        var specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
        var pIva = specificGroup.split("_")[1]

        var iscrizione = await GetIscrizioneStpIscrittaByPartitaIvaPost(pIva);
        if(iscrizione.data.returnedObject && iscrizione.data.httpStatus === 200 ) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Restituisce true se il current account corrente è iscritto all'ordine, false altrimenti.
     * @returns
     */
    public isCurrentAccountIscrittoOrdine() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_ISCRITTI);
    }

    /**
     * Restituisce true se il current account corrente è Ente di formazione, false altrimenti.
     * Agenzie formative.
     * @returns
     */
    public isCurrentAccountEnteFormazione() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_FO + '_');
    }

    /**
     * Restituisce true se il current account corrente è ente esterno, false altrimenti.
     * @returns
     */
    public isCurrentAccountEnteEsterno() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_FOEE + '_');
    }

    /**
     * Restituisce true se il current account corrente è operatore di rivista, false altrimenti.
     * @returns
     */
    public isCurrentAccountOperatoreRivista() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_RFO + '_');
    }

    /**
     * Restituisce true se il current account corrente è formatore, false altrimenti.
     * @returns
     */
    public isCurrentAccountFormatore() {
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        return currentAccount.accounts[currentKeys].specificGroup.startsWith(GROUP_FOR + '_');
    }

    /**
     * Restituisce true se l'utente è correttamente loggato nel sistema.
     * @returns
     */
    public isLoggedIn() {
        return TokenStore.getInstance().getKeycloak() && TokenStore.getInstance().getKeycloak() !== "" && TokenStore.getInstance().getKeycloak().authenticated;
    }

    /**
     * Restituisce true se il profilo corrente può accedere al frontend e non al backoffice.
     * @returns
     */
    public isFrontOffice() {
        return this.isCurrentAccountAuthenticated() || this.isCurrentAccountIscrittoOrdine() || this.isCurrentAccountSTP();
    }

    /**
     * Restituisce true se il profilo corrente può accedere al frontend e al backoffice.
     * @returns
     */
    public isBackOffice() {
        return this.isCurrentAccountODAF() || this.isCurrentAccountFODAF() || this.isCurrentAccountCONAF() || this.isCurrentAccountSTP();
    }

    /**
     * Restituisce true se il current account ha almeno un ruolo nella lista, false altrimenti.
     * @returns
     */
    public currentAccountHasRolesOR(roles) {
        // Recupero il current group
        var currentAccount = this.getCurrentAccount();
        if(!currentAccount || !currentAccount.accounts) {
            return false;
        }

        const currentKeys = Object.keys(currentAccount.accounts)[0];
        var currentGroup = currentAccount.accounts[currentKeys];

        // Verifico se c'è almeno un ruolo
        var roleToCheckFound = false;
        roles.map((roleToCheck) => {
            currentGroup.roleList.map((currentRole) => {
                if (roleToCheckFound)
                    return
                roleToCheckFound = (roleToCheck === currentRole); // ho trovato il ruolo
            });
            if (roleToCheckFound)
                return
        })
        if(roles.length === 0) {
            roleToCheckFound = true;
        }
        return roleToCheckFound;
    }

    /**
     * Restituisce true se il current account ha tutti i ruoli passati in input, false altrimenti.
     * @returns
     */
    public currentAccountHasRolesAND(roles) {
        // Recupero il current group
        var currentAccount = this.getCurrentAccount();
        const currentKeys = Object.keys(currentAccount.accounts)[0];
        var currentGroup = currentAccount.accounts[currentKeys];

        // Verifico se ci sono tutti i ruoli
        var roleToCheckFound = false;
        roles.map((roleToCheck) => {
            currentGroup.roleList.map((currentRole) => {
                roleToCheckFound = (roleToCheck === currentRole); // ho trovato il ruolo
                if (roleToCheckFound)
                    return
            });
            if (!roleToCheckFound)
                return
        })

        return roleToCheckFound;
    }

    /**
     * Restituisce il nome del gruppo di riferimento.
     * @param id
     * @returns
     */
    public getSpecificGroupName = (id) => {
        return Object.keys(TokenStore.getInstance().getCurrentAccountList()[id].accounts)[0]
    }

    /**
    * Restituisce il nome del gruppo di riferimento del currentAccount.
    * @param id
    * @returns
    */
    public getCurrentAccountSpecificGroupName = () => {
        return Object.keys(TokenStore.getInstance().getCurrentAccount().accounts)[0]
    }

    /**
    * Restituisce la posizione dell'account dato il suo group name.
    * @param id
    * @returns
    */
    public getIdOfAccountSpecificGroupName = (specificGroupName) => {
        // Cerco il gruppo GROUP_ISCRITTI, se lo trovo restituisco true
        var groupFound = false;
        var index = 0;
        TokenStore.getInstance().getCurrentAccountList().forEach(currentAccount => {
            const currentKeys = Object.keys(currentAccount.accounts)[0];
            if (currentAccount.accounts[currentKeys].specificGroup === specificGroupName) {
                groupFound = true;
            }
            if (!groupFound)
                index++;
        });

        if (groupFound)
            return index;
        return -1;
    }

    /**
     * Restituisce:
     * 0 = Case CONAF: cerca senza filtrare per ordine territoriale competente
     * 1 = Case FODAF: ordini territoriali competenti di una federazione
     * 2 = Case ODAF: attiva filtro per uno specifico ordine territoriale
     */
    public getOrdineFilterType() {

        /**
         * Se sono operatore STP devo filtrare per pIva.
         */
        if (TokenStore.getInstance().isCurrentAccountSTP()) {
            return 3;
        }

        /**
         * Se sono operatore dell'ordine territoriale devo filtrare per ordine territoriale competente.
         */
        else if (TokenStore.getInstance().isCurrentAccountODAF()) {
            return 2;
        }

        /**
         * Se sono operatore di una federazione devo filtrare per gli ordini territoriali competenti relativi.
         */
        else if (TokenStore.getInstance().isCurrentAccountFODAF()) {
            return 1;
        }

        /**
         * Se sono operatore nazionale non devo filtrare per ordine territoriale competente.
         */
        else if (TokenStore.getInstance().isCurrentAccountCONAF()) {
            return 0;
        }

        /**
         * Errore non gestito
         */
        else {
            throw new Error("Tipologia di operatore non riconosciuta");
        }
    }

    /**
     * Restituisce i dati spid in formato json.
     * @returns
     */
    public getSpidData() {
        const token = this.getDecodedTokenJWT();
        return {
            codiceFiscale: token.fiscalNumber,
            nome: token.given_name,
            cognome: token.family_name,
            dataNascita: token.spid_dateOfBirth,
            sesso: token.spid_gender
        }
    }

    /**
     * Restituisce l'id del currentAccount personale nella lista currentAccountList.
     * Es. Se il currentAccount personale si trova nell'indice 0 restituirà 0.
     * @returns
     */
    public async getDenominazioneOfCurrentAccount() {
        // Cerco il gruppo GROUP_ISCRITTI, se lo trovo restituisco l'id di questo account
        const currentKeys = Object.keys(this.getCurrentAccount().accounts)[0];
        const groupName = this.getCurrentAccount().accounts[currentKeys].specificGroup;

        return await getDenominazioneByCodiceGruppo(groupName).then((response: any) => {
            if (response.data && response.status !== 404) {

                /**
                 * Nel caso di "Operatore STP" il servizio getDenominazioneByCodiceGruppo
                 * restituisce due elementi:
                 * - [0] Denominazione STP
                 * - [1] Id iscrizione STP
                 *
                 * In tutti gli altri casi, ossia, ODAF, FODAF, CONAF e Iscritto all'ordine
                 * restituisce solo la denominazione.
                 */
                var denominazione = response.data.returnedObject;
                if (denominazione !== undefined && denominazione[0] !== undefined && (denominazione[0].startsWith("Operatore STP") || denominazione[0].startsWith("Operatore formazione") || denominazione[0].startsWith("Operatore rivista") || denominazione[0].startsWith("Formatore") || denominazione[0].startsWith("Operatore ente esterno"))) {
                    denominazione = denominazione[0];
                }

                return denominazione;
               }
            })

    }

     /**
     * Restituisce true se il profilo corrente ha un'iscrizione all'ordine / stp radiata.
     * @returns
     */
     public async isRadiato() {
        var idSoggetto = TokenStore.getInstance().getCurrentAccount().idSoggetto
        var tipologiaIscrizione

        if(TokenStore.getInstance().isCurrentAccountIscrittoOrdine())
            tipologiaIscrizione = 1

        else if(TokenStore.getInstance().isCurrentAccountSTP())
            tipologiaIscrizione = 10

         verificaStatoIscrizioneRadiataFO(idSoggetto, tipologiaIscrizione).then((response) => {
            if (response.data.httpStatus === 401)
                return true
            else
                return false
        }).catch((err) => {
            throw new Error(err)
        })
    }
}
