import React from 'react'
import { Card, CardBody, CardFooter, CardHeaderMain, FormGroup, Title } from '@patternfly/react-core'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { GenericButton, GenericSpinner, GenericTable } from '../components'
import tableToExcel from '@linways/table-to-excel'
import {
  PATH_TO_BO_STATS,
  PATH_TO_BO_STATS_ANAG,
  PATH_TO_BO_STATS_DISC,
  PATH_TO_BO_STATS_FOR,
  PATH_TO_BO_STATS_INI_PEC,
  PATH_TO_BO_STATS_POLI,
  PATH_TO_BO_STATS_REG_INDE
} from 'src/app/utils/RoutesConstants'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import FilteringCheckboxSelectInputWithBadging
  from '../components/Statistiche/Components/FilteringCheckboxSelectInputWithBadging'
import PivotTableUISmartWrapper from '../components/Statistiche/Components/PivotTableUISmartWrapper'
import { ST_ANAG, ST_FOR, ST_INI_PEC, ST_POLI, ST_SEC } from 'src/processes/StatisticheService'
import { convertDataFormat } from 'src/utilities/utility'
import { Row } from 'design-react-kit'
import { elencoPolizzeListCsv } from 'src/Query/ElencoPolizzeFilterQuery'
import { dbListaStati, filterableColumsAll } from 'src/processes/assicurazioni/CostantiPolizze'
import GenericModal from '../components/GenericModal'

namespace ST_LABELS {
  export const NO_RESULT_FOUND_FOR_PERIODO_ORDINE = "La ricerca non ha prodotto alcun risultato per il periodo e per l'ordine selezionato"
  export const NO_RESULT_FOUND_FOR_ORDINE = "La ricerca non ha prodotto alcun risultato per l'ordine selezionato"
}

const Filters = {
  Formazione: function SearchForm(props) {
    return (
      <>
        <FormGroup label="Selezionare il periodo formativo" fieldId="for-periodo-for-search">
          <FilteringCheckboxSelectInputWithBadging
            data={props.elencoPeriodiFormativi}
            dataKey="Periodo"
            dataValue="Periodo"
            selectLabel="Periodo"
            selectKeyLabel="Periodo"
            placeholder="Periodo"
            onChangeObserver={props.pivotfilterSearch}
          />
        </FormGroup>

        {(props.sec.isConaf === true || props.sec.isFodaf === true) && props.elencoOrdiniData.length > 0 && (
          <FormGroup label="Selezionare l'ordine competente" fieldId="for-elenco-ordini-search">
            <FilteringCheckboxSelectInputWithBadging
              data={props.elencoOrdiniData}
              dataKey="CodiceGruppo"
              dataValue="Ordine"
              selectLabel="Ordini"
              selectKeyLabel="ordini"
              placeholder="Ordini"
              onChangeObserver={props.pivotfilterSearch}
              style={{ maxHeight: '350px', overflow: 'auto' }}
            />
          </FormGroup>
        )}
      </>
    )
  },

  Anagrafica: function SearchForm(props) {
    return (
      <>
        <FormGroup label="Selezionare l'ordine competente" fieldId="for-elenco-ordini-search">
          <FilteringCheckboxSelectInputWithBadging
            data={props.elencoOrdiniData}
            dataKey="CodiceGruppo"
            dataValue="Ordine"
            selectLabel="Ordini"
            selectKeyLabel="ordini"
            placeholder="Ordini"
            onChangeObserver={props.pivotfilterSearch}
            style={{ maxHeight: '350px', overflow: 'auto' }}
          />
        </FormGroup>
      </>
    )
  },

  Polizze: function SearchForm(props) {
    return (
      <>
        {props.elencoOrdiniData?.length < 0 && 'Nessun ordine caricato'}
        <FormGroup label="Selezionare l'ordine competente" fieldId="for-elenco-ordini-search">
          <FilteringCheckboxSelectInputWithBadging
            data={props.elencoOrdiniData}
            dataKey="CodiceGruppo"
            dataValue="Ordine"
            selectLabel="Ordini"
            selectKeyLabel="ordini"
            placeholder="Ordini"
            onChangeObserver={props.pivotfilterSearch}
            style={{ maxHeight: '350px', overflow: 'auto' }}
          />
        </FormGroup>

        {props.elencoAnniCopertura?.length > 0 && props.viewSelectAnnoCopertura && (props.sec.isConaf === true || props.sec.isFodaf === true) && (
          <FormGroup label="Selezionare l'anno di copertura" fieldId="for-elenco-anni-copert-search">
            <FilteringCheckboxSelectInputWithBadging
              data={props.elencoAnniCopertura}
              dataKey="AnnoCopertura"
              dataValue="AnnoCopertura"
              selectLabel="AnniCopertura"
              selectKeyLabel="anniCopertura"
              placeholder="Anni Copertura"
              onChangeObserver={props.pivotfilterSearch}
              style={{ maxHeight: '350px', overflow: 'auto' }}
            />
          </FormGroup>
        )}
      </>
    )
  },

  RegIndeIniPec: function SearchForm(props) {
    const columns = ['Nome del report', 'Data di aggiornamento report'] //Colonne della tabella
    var categoria: string = props.service //Categoria di report: inicsv o reginde
    var [filename, setFilename] = React.useState('')
    var [rows, setRows] = React.useState([])
    var [isLoaded, setIsLoaded] = React.useState(false)

    //Restituisce la lista di report in base alla pagina invocata (reginde o inipec (= inicsv)).
    //Restituisce i soli report con stato = 1 e tipologia = "aggiornamento"
    ST_INI_PEC.getReportListByCategoria(categoria).then((response) => {
      try {
        var list: any = []
        response.data.map((item) =>
          list.push({
            data: [
              item.shortFilename, //Nome del report
              convertDataFormat(item.dataReport), //Converte la data nel formato gg/mm/aaaa
              item.filename,
            ],
          })
        )
        setRows(list)
        setIsLoaded(true)
      } catch (err) {
        throw err
      }
    })

    const getFile = (filename) => {
      try {
        let contentType: any
        //Recupera le informazioni del report tramite il filename passato in input
        ST_INI_PEC.getReportByFilename(filename).then((response) => {
          //Ricavo il ContenType dall'estensione del file
          if (response.data.extension === 'xml') contentType = 'application/xml'
          else if (response.data.extension === 'csv') contentType = 'text/csv'
          let content = response.data.fileContent

          //Viene costruito il file da scaricare
          const blob = new Blob([content], { type: contentType })
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = response.data.shortFilename
          link.click()
        })
      } catch (err) {
        throw err
      }
    }

    //Recupero delle informazioni della riga selezionata
    const handleSelect = (e) => {
      //Setta il filename (elemento nascosto nella tabella) per recuperare il file
      setFilename(e.data[2])
    }

    const dropdownActionList = [
      {
        title: 'Scarica',
        onClick: () => getFile(filename),
        itemKey: 'scarica report',
      },
    ]

    return isLoaded === true ? (
      <>
        <GenericTable
          key={'tabellaRisultati'}
          columnsToHide={[2]}
          columns={columns}
          rows={rows}
          hideFilters
          hideCsv
          hideSearch
          hidePagination
          dropdownList={dropdownActionList}
          autoWidthColumns
          onSelect={(e) => handleSelect(e)}
        />
        <br></br>
      </>
    ) : (
      <GenericSpinner />
    )
  },
}

class Statistiche extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      showSpinner: false,
      pivotStateIscritti: ST_ANAG.pivotOptionsIscritti,
      pivotOptionsTotale: ST_ANAG.pivotOptionsTotale,
      pivotOptionsDatiGeneraliPolizze: ST_POLI.pivotPoliDatiGeneraliAnno,
      pivotOptionsDatiGeneraliFormazione: ST_FOR.pivotForDatiGeneraliOrdineAndPeriodo,
      forElencoPeriodi: [],
      isLoadingPivotStateIscritti: true,
      isLoadingdatiGeneraliPolizzeData: true,
      isLoadingdatiGeneraliFormazioneData: true,
      elencoOrdiniData: [],
      filtroOrdiniTerritoriali: [],
      dataRicercaIniPec: undefined,
      filtersPivot: [],
      currentAccount: {},
      IS_CONAF: false,
      IS_ODAF: false,
      IS_FODAF: false,
      modalOpen: false,
      viewSelectAnnoCopertura: false,
    }
  }

  //Avvia la ricerca in base al contesto e imposta un return handler per la logica di frontend
  searchData = () => {
    if (window.location.pathname === PATH_TO_BO_STATS_POLI) {
      let filtersPivot = this.state.filtersPivot
      let currentAccount = this.state.currentAccount

      const isOrdineSelected = filtersPivot.some((oggetto) => oggetto.dataKey === 'CodiceGruppo' && oggetto.state.selected.length > 0)
      const isCoperturaSelected = filtersPivot.some((oggetto) => oggetto.dataKey === 'AnnoCopertura' && oggetto.state.selected.length > 0)
      const ordineSelezionato = filtersPivot.find((oggetto) => oggetto.dataKey === 'CodiceGruppo' && oggetto.state.selected.length > 0)

      if (this.state.IS_ODAF) {
        this.setState({ isLoadingdatiGeneraliPolizzeData: true })
        this.setState({ viewPolizze: true })
        this.setState({ viewSelectAnnoCopertura: false })
        ST_POLI.statisticheGeneraliPolizze(filtersPivot, currentAccount, this, this.updateStateDatiGeneraliPolizzePivot)
      } else if (
        (this.state.IS_CONAF || this.state.IS_FODAF) &&
        isOrdineSelected &&
        !isCoperturaSelected &&
        !ordineSelezionato.state.selected.some((ordine) => ordine === 'ODAF_')
      ) {
        //Ha selezionato un ordine specifico
        this.setState({ viewSelectAnnoCopertura: false })
        this.setState({ viewPolizze: true })
        ST_POLI.statisticheGeneraliPolizze(filtersPivot, currentAccount, this, this.updateStateDatiGeneraliPolizzePivot)
      } else if (
        (this.state.IS_CONAF || this.state.IS_FODAF) &&
        isOrdineSelected &&
        !isCoperturaSelected &&
        ordineSelezionato.state.selected.some((ordine) => ordine === 'ODAF_')
      ) {
        this.showModal(
          true,
          'Avviso',
          "Hai scelto di estrarre le polizze per tutti gli ordini. Tuttavia, per ottenere l'elenco desiderato, è necessario selezionare l'anno di copertura. In alternativa, puoi scaricare il file CSV relativo all'estrazione di tutte le polizze per tutti gli anni di copertura. Per avviare il download del CSV, premi il pulsante di esportazione dedicato. Al termine del download, ti invitiamo a ricaricare la pagina poiché l'elenco contiene una grande quantità di dati, il che potrebbe rallentare le prestazioni del sito durante la navigazione. Grazie per la comprensione."
        )
        this.setState({ viewSelectAnnoCopertura: true })
        this.setState({ viewPolizze: false })
      } else if (isOrdineSelected && isCoperturaSelected && ordineSelezionato.state.selected.some((ordine) => ordine === 'ODAF_')) {
        this.setState({ viewPolizzeAllOrdini: true })
        this.setState({ viewSelectAnnoCopertura: true })
        this.setState({ viewPolizze: true })
        ST_POLI.statisticheGeneraliPolizze(filtersPivot, currentAccount, this, this.updateStateDatiGeneraliPolizzePivot)
      } else {
        this.setState({ viewPolizzeAllOrdini: false })
        this.setState({ viewPolizze: false })
        this.setState({ viewSelectAnnoCopertura: false })
      }
    }

    if (window.location.pathname === PATH_TO_BO_STATS_FOR) {
      let filtersPivot = this.state.filtersPivot
      const isPeriodoSelected = filtersPivot.some((oggetto) => oggetto.dataKey === 'Periodo' && oggetto.state.selected.length > 0)
      const isOrdineSelected = filtersPivot.some((oggetto) => oggetto.dataKey === 'CodiceGruppo' && oggetto.state.selected.length > 0)

      if (isPeriodoSelected === true && isOrdineSelected === true && (this.state.IS_CONAF || this.state.IS_FODAF)) {
        this.setState({ showSpinner: true })
      }

      if (isPeriodoSelected && isOrdineSelected && (this.state.IS_CONAF || this.state.IS_FODAF)) {
        if (this.state.IS_FODAF) {
          for (let i = 0; i > filtersPivot.length; i++) {
            if (Array.isArray(filtersPivot[i]) && filtersPivot[i].some((obj) => obj.member === 'DimFederazione.codiceGruppo')) {
              filtersPivot.splice(i, 1)
              break
            }
          }
        }

        this.setState({ isLoadingdatiGeneraliFormazioneData: true })
        this.setState({ viewFormazione: true })
      } else if (isPeriodoSelected && this.state.IS_ODAF) {
        this.setState({ isLoadingdatiGeneraliFormazioneData: true })
        this.setState({ viewFormazione: true })
      } else {
        this.setState({ viewFormazione: false })
      }
      let currentAccount = this.state.currentAccount
      this.setState({ showSpinner: true })
      ST_FOR.statisticheGeneraliFormazione(filtersPivot, currentAccount, this, this.updateStateDatiGeneraliFormazionePivot)
    }
  }

  /** Estrai Tabella in Excel */
  handleExportClick = (table, name) => () => {
    this.closeModal()
    if (name === 'statistiche_polizze_tutti_gli_ordini') {
      this.showModal(
        true,
        'Avviso',
        'Hai scelto di voler estrarre il CSV delle polizze per tutti gli ordini e per tutti gli anni di copertura. Avvisiamo che per completare il download potrebbe essere necessario qualche secondo in più di attesa.'
      )

      this.setState((nextState) => {
        nextState.showSpinner = true
        nextState.isLoadingCsv = true
        nextState.refreshPage = false
        return nextState
      })

      elencoPolizzeListCsv(undefined, '', dbListaStati, filterableColumsAll, '')
        ?.then((response: any) => {
          if (response && response !== null) {
            this.setState((nextState) => {
              nextState.showSpinner = false
              nextState.isLoadingCsv = false
              nextState.refreshPage = true
              return nextState
            })
          }
          this.showModal(
            true,
            'Avviso',
            "Download completato. Sarà avviato il refresh della pagina per garantire un'esperienza di navigazione ottimale e prevenire eventuali rallentamenti delle prestazioni del sito."
          )
        })
        .catch((err) => {
          this.setState((nextState) => {
            nextState.refreshPage = false
            return nextState
          })
          console.error('Eccezione in genericTable --> ', err)
          alert('Errore durante il download del file.')
        })
    } else {
      var htmlTable
      if (table === 'stats') {
        var htmlDiv = document.getElementById(table)
        if (htmlDiv instanceof Element) {
          htmlTable = htmlDiv.querySelector('.pvtTable').cloneNode(true)
        }
      } else {
        htmlTable = document.querySelector('.pvtTable').cloneNode(true)
      }

      if (htmlTable instanceof Element) {
        const htmlTableHead = htmlTable.querySelector('thead')
        const htmlHeadRows = htmlTableHead.querySelectorAll('tr')
        htmlHeadRows.forEach((headRow) => {
          const htmlHeadCells = headRow.querySelectorAll('th')
          htmlHeadCells.forEach((htmlCell) => {
            const isAxisLabel = htmlCell.classList.contains('pvtAxisLabel')
            const isColLabel = htmlCell.classList.contains('pvtColLabel')
            const isTotalLabel = htmlCell.classList.contains('pvtTotalLabel')
            if (isAxisLabel) {
              htmlCell.setAttribute('data-a-h', 'left')
              htmlCell.setAttribute('data-a-v', 'middle')
            }
            if (isColLabel) {
              htmlCell.setAttribute('data-a-h', 'center')
              htmlCell.setAttribute('data-a-v', 'middle')
            }
            if (isTotalLabel) {
              htmlCell.setAttribute('data-exclude', 'true')
            }
          })
        })

        const htmlTableBody = htmlTable.querySelector('tbody')
        const htmlBodyRows = htmlTableBody.querySelectorAll('tr')
        htmlBodyRows.forEach((bodyRow) => {
          const htmlBodyCells = bodyRow.querySelectorAll('th, td')
          htmlBodyCells.forEach((htmlCell) => {
            const isRowLabel = htmlCell.classList.contains('pvtRowLabel')
            const isValue = htmlCell.classList.contains('pvtVal')
            const isTotal = htmlCell.classList.contains('pvtTotal')
            const isTotalLabel = htmlCell.classList.contains('pvtTotalLabel')
            const isGrandTotal = htmlCell.classList.contains('pvtGrandTotal')
            if (isRowLabel) {
              htmlCell.setAttribute('data-a-h', 'left')
              htmlCell.setAttribute('data-a-v', 'middle')
            }
            if (isValue) {
              htmlCell.setAttribute('data-a-h', 'right')
              htmlCell.setAttribute('data-a-v', 'middle')
              htmlCell.setAttribute('data-t', 'n')
            }
            if (isTotal || isTotalLabel || isGrandTotal) {
              htmlCell.setAttribute('data-exclude', 'true')
            }
          })
        })

        tableToExcel.convert(htmlTable, { name: name + '.xlsx' })
      }
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false })

    if (this.state.refreshPage) {
      window.location.reload()
    }
  }

  showModal = async (booleanValue, title, text) => {
    await this.setState({
      modalOpen: booleanValue,
      modalTitle: title,
      modalText: text,
    })
  }

  //Riceve le informazioni dal filtro di ricerca e le inserisce nello stato
  //dopo aver aggiornato lo stato richiama la funzione searchData per il brute works
  pivotfilterSearch = (filter) => {
    //abbiamo bisogno di clonare l'array altrimenti react non aggiorna lo stato
    const response = filter
    this.setState((nextState) => {
      nextState.filtersPivot.push(response)
      nextState.showSpinner = true
      return nextState
    }, this.searchData)
  }

  /*
   * Funzioni di elaborazione dello stato alla notifica di nuovi dati
   */
  updateStateElencoIscrittiPivot(data) {
    const response = data
    this.setState((nextState) => {
      nextState.pivotStateIscritti.data = response
      nextState.isLoadingPivotStateIscritti = false
      return nextState
    })
  }

  updateElencoOrdiniData(data) {
    const result = Array.isArray(data)
    let parseResponse: any
    if (result === true) {
      parseResponse = data
    } else {
      parseResponse = []
      parseResponse.push(data)
    }
    const response = parseResponse
    if (window.location.pathname === PATH_TO_BO_STATS_POLI) {
      response.splice(0, 0, { Ordine: 'TUTTI GLI ORDINI', CodiceGruppo: 'ODAF_' })
    }
    this.setState((nextState) => {
      nextState.elencoOrdiniData = response
      nextState.isLoadingOptionsTotale = false
      return nextState
    })
  }

  updateElencoAnniCopertura(data) {
    const result = Array.isArray(data)
    let parseResponse: any
    if (result === true) {
      parseResponse = data
    } else {
      parseResponse = []
      parseResponse.push(data)
    }
    const response = parseResponse
    this.setState((nextState) => {
      nextState.elencoAnniCopertura = response
      nextState.isLoadingOptionsTotale = false
      return nextState
    })
  }
  updateStateDatiGeneraliPolizzePivot(data) {
    const response = data
    this.setState((nextState) => {
      nextState.pivotOptionsDatiGeneraliPolizze.data = response
      nextState.isLoadingdatiGeneraliPolizzeData = false
      nextState.isLoading = false
      nextState.showSpinner = false
      return nextState
    })
  }

  updateStateDatiGeneraliFormazionePivot(data) {
    const response = data
    this.setState((nextState) => {
      nextState.pivotOptionsDatiGeneraliFormazione.data = response
      nextState.isLoadingdatiGeneraliFormazioneData = false
      nextState.isLoading = false
      nextState.showSpinner = false
      return nextState
    })
  }

  updateElencoPeriodiFormativi(data) {
    const response = data
    this.setState((nextState) => {
      nextState.forElencoPeriodi = response
      nextState.showSpinner = false
      return nextState
    })
  }

  changedPivotFormazione(pivotFormazione) {
    const response = pivotFormazione
    this.setState((nextState) => {
      nextState.pivotOptionsDatiGeneraliFormazione = response
      return nextState
    })
  }

  changedPivotAnagraficaTotale(pivotTotale) {
    const response = pivotTotale
    this.setState((nextState) => {
      nextState.pivotOptionsTotale = response
      return nextState
    })
  }

  changedPivotAnagraficaIscritti(pivotOptionsIscritti) {
    const response = pivotOptionsIscritti
    this.setState((nextState) => {
      nextState.pivotStateIscritti = response
      return nextState
    })
  }

  changedPivotDatiGeneraliPolizze(data) {
    const response = data
    this.setState((nextState) => {
      nextState.pivotOptionsDatiGeneraliPolizze = response
      return nextState
    })
  }

  async componentDidMount() {
    let currentAccount = await TokenStore.getInstance().getCurrentAccount()
    await this.setState({ currentAccount: currentAccount })
    let isConaf = ST_SEC.isConaf(currentAccount)
    await this.setState({ IS_CONAF: isConaf })
    let isOdaf = ST_SEC.isOdaf(currentAccount)
    await this.setState({ IS_ODAF: isOdaf })
    let isFodaf = ST_SEC.isFodaf(currentAccount)
    await this.setState({ IS_FODAF: isFodaf })

    if (isOdaf === true) {
      let specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
      let filters: any[] = []
      filters.push({
        member: 'DimOrdine.codiceGruppo',
        operator: 'equals',
        values: [specificGroup],
      })
      this.pivotfilterSearch(filters)
    } else if (isFodaf === true) {
      let specificGroup = Object.keys(TokenStore?.getInstance()?.getCurrentAccount()?.accounts)[0]
      let filters: any[] = []
      filters.push({
        member: 'DimFederazione.codiceGruppo',
        operator: 'equals',
        values: [specificGroup],
      })
      this.pivotfilterSearch(filters)
    }

    ST_ANAG.elencoOrdini(currentAccount, this, this.updateElencoOrdiniData)
    //@deprecated ST_FOR.elencoTrienniFormativi(currentAccount, this, this.updateElencoTrienniFormativi)
    ST_FOR.elencoPeriodiFormativi(currentAccount, this, this.updateElencoPeriodiFormativi)

    //load data for anagrafica
    if (window.location.pathname === PATH_TO_BO_STATS) {
      ST_ANAG.statisticheIscritti(currentAccount, this, this.updateStateElencoIscrittiPivot)
      ST_ANAG.statisticheStatoIscrizione(currentAccount, this, this.updateElencoOrdiniData)
    }

    if (window.location.pathname === PATH_TO_BO_STATS_POLI) {
      ST_POLI.elencoAnniCopertura(currentAccount, this, this.updateElencoAnniCopertura)
    }
  }

  render() {
    let bredcrumLabel = ''
    let bredcrumLink = ''
    let titlePage = ''
    let sectionPage = 'Statistiche'
    let containerCss = 'container'

    if (window.location.pathname === PATH_TO_BO_STATS) {
      bredcrumLabel = 'Statistiche Anagrafica'
      bredcrumLink = PATH_TO_BO_STATS_ANAG
      titlePage = bredcrumLabel
    }

    if (window.location.pathname === PATH_TO_BO_STATS_POLI) {
      bredcrumLabel = 'Statistiche Polizze'
      bredcrumLink = PATH_TO_BO_STATS_POLI
      titlePage = bredcrumLabel
      containerCss = 'container-fluid statistiche'
    }

    if (window.location.pathname === PATH_TO_BO_STATS_FOR) {
      bredcrumLabel = 'Statistiche Formazione'
      bredcrumLink = PATH_TO_BO_STATS_FOR
      titlePage = bredcrumLabel
      containerCss = 'container-fluid statistiche'
    }

    if (window.location.pathname === PATH_TO_BO_STATS_DISC) {
      bredcrumLabel = 'Statistiche Disciplinare'
      bredcrumLink = PATH_TO_BO_STATS_DISC
      titlePage = bredcrumLabel
    }

    if (window.location.pathname === PATH_TO_BO_STATS_INI_PEC) {
      bredcrumLabel = 'Report INI-PEC'
      bredcrumLink = PATH_TO_BO_STATS_INI_PEC
      titlePage = bredcrumLabel
      containerCss = 'container statistiche'
    }

    if (window.location.pathname === PATH_TO_BO_STATS_REG_INDE) {
      bredcrumLabel = 'Report ReGindE'
      bredcrumLink = PATH_TO_BO_STATS_REG_INDE
      titlePage = bredcrumLabel
      containerCss = 'container statistiche'
    }

    return (
      <div className={containerCss + ' register-container'}>
        <GenericBreadCrumb
          paths={[
            { label: sectionPage, link: '#' },
            { label: bredcrumLabel, link: bredcrumLink },
          ]}
        />
        <br></br>
        <Title headingLevel="h1" size="4xl">
          <span>{titlePage}</span>
        </Title>

        <Card>
          <CardHeaderMain>{window.location.pathname === PATH_TO_BO_STATS_FOR}</CardHeaderMain>
          <CardBody>
            {/* eventuali filtri di testa */}
            {window.location.pathname === PATH_TO_BO_STATS_FOR && this.state.elencoOrdiniData?.length >= 0 && this.state.forElencoPeriodi?.length > 0 && (
              <>
                <Filters.Formazione
                  sec={{
                    isConaf: this.state.IS_CONAF,
                    isFodaf: this.state.IS_FODAF,
                  }}
                  elencoOrdiniData={this.state.elencoOrdiniData}
                  elencoPeriodiFormativi={this.state.forElencoPeriodi}
                  pivotfilterSearch={this.pivotfilterSearch}
                />
              </>
            )}

            {window.location.pathname === PATH_TO_BO_STATS_POLI &&
              (this.state.IS_CONAF || this.state.IS_FODAF) &&
              this.state.elencoOrdiniData?.length > 0 &&
              this.state.elencoAnniCopertura?.length > 0 && (
                <>
                  <Filters.Polizze
                    sec={{
                      isConaf: this.state.IS_CONAF,
                      isFodaf: this.state.IS_FODAF,
                    }}
                    elencoOrdiniData={this.state.elencoOrdiniData}
                    elencoAnniCopertura={this.state.elencoAnniCopertura}
                    viewSelectAnnoCopertura={this.state.viewSelectAnnoCopertura}
                    pivotfilterSearch={this.pivotfilterSearch}
                  />
                </>
              )}

            <br></br>

            {window.location.pathname === PATH_TO_BO_STATS && this.state.pivotStateIscritti?.data && (
              <>
                <h4>Statistiche per stato di iscrizione</h4>
                <br />
                <div className="pf-c-panel">
                  <div className="pf-c-panel__main">
                    <GenericButton
                      color={'primary'}
                      label={'Export to excel'}
                      onClick={this.handleExportClick('', 'statistiche_stato_iscrizione')}
                    ></GenericButton>
                    <div className="pf-c-panel__main-body">
                      <GenericSpinner hidden={this.state.pivotStateIscritti.data.length > 0}> </GenericSpinner>
                      <PivotTableUISmartWrapper
                        pivotState={this.state.pivotOptionsTotale}
                        pivotData={this.state.pivotStateIscritti.data}
                        isLoading={this.state.isLoadingPivotStateIscritti}
                        onChangePivot={(e) => this.changedPivotAnagraficaTotale(e)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {window.location.pathname === PATH_TO_BO_STATS && this.state.pivotStateIscritti?.data && (
              <>
                <br />
                <h4>Statistiche per dell'ordine e per tipologia di iscritto </h4>
                <br />
                <GenericButton
                  color={'primary'}
                  label={'Export to excel'}
                  onClick={this.handleExportClick('stats', 'statistiche_per_tipologia')}
                ></GenericButton>
                <div id="stats">
                  <GenericSpinner hidden={this.state.pivotStateIscritti.data.length > 0}> </GenericSpinner>
                  <PivotTableUISmartWrapper
                    pivotState={this.state.pivotStateIscritti}
                    pivotData={this.state.pivotStateIscritti.data}
                    isLoading={this.state.isLoadingPivotStateIscritti}
                    onChangePivot={(e) => this.changedPivotAnagraficaIscritti(e)}
                  />
                </div>
              </>
            )}
            {window.location.pathname === PATH_TO_BO_STATS_FOR && this.state.pivotOptionsDatiGeneraliFormazione?.data && (
              <Row className="p-2">
                {this.state.IS_ODAF && !this.state.viewFormazione && <>E' obbligatorio selezionare un periodo per visualizzare il report delle statistiche.</>}
                {this.state.IS_CONAF && !this.state.viewFormazione && (
                  <>E' obbligatorio selezionare un periodo e un ordine per visualizzare il report delle statistiche.</>
                )}
                {window.location.pathname === PATH_TO_BO_STATS_FOR &&
                  this.state.isLoadingdatiGeneraliFormazioneData === false &&
                  typeof this.state.pivotOptionsDatiGeneraliFormazione?.data === 'undefined' && (
                    <>
                      {' '}
                      {/* Nessun risultato di ricerca trovato */}
                      <h4> {ST_LABELS.NO_RESULT_FOUND_FOR_PERIODO_ORDINE} </h4>
                    </>
                  )}

                {/* visualizzo solo se i filtri sono valorizzati */}
                {this.state.pivotOptionsDatiGeneraliFormazione.data.length >= 0 && this.state.viewFormazione && (
                  <>
                    <div>
                      <GenericButton color={'primary'} label={'Export to excel'} onClick={this.handleExportClick('', 'statistiche_formazione')}></GenericButton>
                    </div>
                    {this.state?.filtersPivot?.data?.length > 0 && this.state.showSpinner === true && (
                      <GenericSpinner
                        hidden={this.state.pivotOptionsDatiGeneraliFormazione.data.length > 0 && !this.state.isLoadingdatiGeneraliFormazioneData}
                      ></GenericSpinner>
                    )}
                    <GenericSpinner
                      hidden={this.state.pivotOptionsDatiGeneraliFormazione.data.length > 0 && !this.state.isLoadingdatiGeneraliFormazioneData}
                    ></GenericSpinner>

                    <PivotTableUISmartWrapper
                      pivotState={this.state.pivotOptionsDatiGeneraliFormazione}
                      pivotData={this.state.pivotOptionsDatiGeneraliFormazione.data}
                      isLoading={this.state.isLoadingdatiGeneraliFormazioneData}
                      onChangePivot={(e) => this.changedPivotFormazione(e)}
                    />
                  </>
                )}
              </Row>
            )}
            {/* dati generali delle polizze */}
            {window.location.pathname === PATH_TO_BO_STATS_POLI && this.state.pivotOptionsDatiGeneraliPolizze?.data && (
              <Row className="p-2">
                {(this.state.IS_CONAF || this.state.IS_FODAF) && !this.state.viewPolizze && !this.state.viewPolizzeAllOrdini && (
                  <div>
                    {"E' obbligatorio selezionare un ordine per visualizzare il report delle statistiche oppure "}
                    <a target="_blank" className="link-style" onClick={this.handleExportClick('', 'statistiche_polizze_tutti_gli_ordini')}>
                      Scarica il csv per tutti gli ordini
                    </a>
                    .
                  </div>
                )}
                {window.location.pathname === PATH_TO_BO_STATS_POLI &&
                  this.state.isLoadingdatiGeneraliPolizzeData === false &&
                  typeof this.state.pivotOptionsDatiGeneraliPolizze?.data === 'undefined' && (
                    <>
                      {' '}
                      {/* Nessun risultato di ricerca trovato */}
                      <h4> {ST_LABELS.NO_RESULT_FOUND_FOR_ORDINE} </h4>
                    </>
                  )}
                {/* visualizzo solo se i filtri sono valorizzati */}
                {this.state.IS_CONAF && (
                  <GenericModal title={this.state.modalTitle} text={this.state.modalText} modalOpen={this.state.modalOpen} closeButton={this.closeModal} />
                )}
                {this.state.isLoadingCsv && this.state.showSpinner && <GenericSpinner />}
                {this.state.pivotOptionsDatiGeneraliPolizze.data.length >= 0 && this.state.viewPolizze && (
                  <>
                    <div>
                      <GenericButton color={'primary'} label={'Export to excel'} onClick={this.handleExportClick('', 'statistiche_polizze')}></GenericButton>
                    </div>
                    <GenericSpinner
                      hidden={
                        this.state.pivotOptionsDatiGeneraliPolizze.data.length > 0 && !this.state.isLoadingdatiGeneraliPolizzeData && !this.state.showSpinner
                      }
                    ></GenericSpinner>
                    <PivotTableUISmartWrapper
                      pivotState={this.state.pivotOptionsDatiGeneraliPolizze}
                      pivotData={this.state.pivotOptionsDatiGeneraliPolizze.data}
                      isLoading={this.state.isLoadingdatiGeneraliPolizzeData}
                      onChangePivot={(e) => this.changedPivotDatiGeneraliPolizze(e)}
                    />
                  </>
                )}
              </Row>
            )}
            {window.location.pathname === PATH_TO_BO_STATS_INI_PEC && (
              <>
                <Filters.RegIndeIniPec service="inicsv" />
              </>
            )}
            {window.location.pathname === PATH_TO_BO_STATS_REG_INDE && (
              <>
                <Filters.RegIndeIniPec service="reginde" />
              </>
            )}
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
      </div>
    )
  }
}

export default Statistiche
