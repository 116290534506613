import React from 'react'
import { useHistory } from 'react-router-dom'
import { PageSection, Title } from '@patternfly/react-core'
import useFrontOffice from 'src/hooks/useFrontOffice'
import { AppUtils as U } from 'src/app/utils/FormUtils'

import { FILTER_OPERATORS, generateRequestFilter, generateRequestSort } from 'src/app/utils/ApiUtils'
import { GenericAsyncTableData, RequestData } from 'src/backoffice/app/components/GenericAsyncTableData'
import {
  PATH_TO_BO_DETTAGLIO_AGENZIA,
  PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO,
  PATH_TO_BO_DETTAGLIO_RIVISTA,
  PATH_TO_BO_ELENCO_AGENZIE,
  PATH_TO_FO_DETTAGLIO_AGENZIA,
  PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO,
  PATH_TO_FO_DETTAGLIO_RIVISTA,
  PATH_TO_FO_ELENCO_AGENZIE,
  RO_CONS_FORM_CONAF,
  RO_GEST_FORM_CONAF
} from 'src/app/utils/RoutesConstants'

import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import {
  DETAILS_ACTIONS,
  STATI_AGENZIE,
  STATI_AGENZIE_OPTIONS,
  STATI_AGENZIE_VALUES,
  STATI_ENTI_OPTIONS,
  TIPOLOGIE_ACCREDITAMENTO_OPTIONS,
  TIPOLOGIE_RIVISTA_OPTIONS
} from 'src/formazione'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import useSwitchTipoForm from 'src/hooks/useSwitchTipoForm'
import { useAgenziaFormativa } from 'src/hooks/useReqOptions'

const Spacer = () => (
  <>
    <br></br>
  </>
)

const basePath = { label: 'Home', link: '/' }

const ElencoAgenzie: React.FunctionComponent = () => {
  const agenziaFormativa = useAgenziaFormativa()
  const moment = require('moment');
  const history = useHistory()
  const isFrontOffice = useFrontOffice()
  const tipoForm = useSwitchTipoForm()
  const pathToElenco = isFrontOffice ? PATH_TO_FO_ELENCO_AGENZIE : PATH_TO_BO_ELENCO_AGENZIE
  let pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_AGENZIA : PATH_TO_BO_DETTAGLIO_AGENZIA

  if (tipoForm === 2) {
    pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_RIVISTA : PATH_TO_BO_DETTAGLIO_RIVISTA
  } else if (tipoForm === 3) {
    pathToDettaglio = isFrontOffice ? PATH_TO_FO_DETTAGLIO_ENTE_ESTERNO : PATH_TO_BO_DETTAGLIO_ENTE_ESTERNO
  }

  let pathLabel = 'Elenco agenzie'
  if (tipoForm === 2) {
    pathLabel = 'Elenco riviste'
  } else if (tipoForm === 3) {
    pathLabel = 'Elenco enti esterni'
  }

  const myPath = {
    label: pathLabel,
    link: pathToElenco,
  }

  const loadData = async (request: RequestData) => {
    let customFunc = 'msfoFormazioneAccAgenzieFormativeForUserGet'
    if (!isFrontOffice) customFunc = 'msfoFormazioneAccAgenzieFormativeGet'

    const filters: any = []
    for (const f of request.filters) {
      if (f.field === 'stato' && f.value.value === 98) {
        filters.push({
          field: 'data_fine',
          operator: 'LTE',
          value: moment().format('YYYY-MM-DD'),
        })
      } else {
        if(f.field === 'dataFineProroga')
        {
          switch(f.value){
             case '1':
                    filters.push({
                      field: 'dataFineProroga',
                      operator: FILTER_OPERATORS.GTE,
                      value: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    })
                  break;
            case '2':
                    filters.push({
                      field: 'dataFineProroga',
                      operator: FILTER_OPERATORS.LT,
                      value: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    })
                  break;
          }
        }
        else{
        filters.push(f)
        }
      }
    }
    const filtersToUse = [
      ...filters,
      {
        field: 'tipo_ag_form',
        operator: 'EQUAL',
        value: tipoForm,
      },
    ]
    const { data } = await getFormazioneResourceApi()[customFunc](
      generateRequestFilter(filtersToUse),
      request.pageSize,
      request.currentPage,
      generateRequestSort(request.sortColumn, request.sortDirection),
      getDefaultAxiosConfig()
    )
    return {
      items: data.items || [],
      total: data.count || 0,
    }
  }

  const onDetails = (row, modalita) => (e) => {
    history.push({
      pathname: pathToDettaglio,
      search: `?id=${row.id}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }

  let columns = [
    { label: 'Ragione Sociale', field: 'rag_soc', isSortable: true },
    { label: 'Data Richiesta', field: 'data_prima_rich', isSortable: true },
    { label: 'Data Accreditamento', field: 'data_accettazione', isSortable: true },
    {
      label: 'Stato',
      field: 'stato',
      isSortable: true,
      render: (row) => {
        let stato = STATI_AGENZIE[row.stato]
        if (
          row.data_accettazione &&
          row.tipo_ag_form === 1 &&
          row.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
          moment().diff(row.data_accettazione, 'years', true) >= 3
        ) {
          stato = 'Scaduta'
        }

        if (row.data_fine && row.tipo_ag_form === 2 && row.stato === STATI_AGENZIE_VALUES.ACCREDITATA && moment().diff(row.data_fine) >= 0) {
          stato = 'Scaduta'
        } else if (
          row.data_accettazione &&
          !row.data_fine &&
          row.tipo_ag_form === 2 &&
          row.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
          moment().diff(row.data_accettazione, 'years', true) >= 3
        ) {
          stato = 'Scaduta'
        }

        if (row.data_fine && tipoForm === 3 && row.stato === STATI_AGENZIE_VALUES.ACCREDITATA && moment().diff(row.data_fine) >= 0) {
          stato = 'Scaduto'
        }
        if (tipoForm === 3 && row.stato === STATI_AGENZIE_VALUES.REVOCATA) {
          stato = 'Cancellato'
        }
        return stato
      },
    },
  ]

  let filters = [
    { label: 'Ragione Sociale', field: 'rag_soc', type: 'input', operator: FILTER_OPERATORS.LIKE },
    { label: 'Data Richiesta', field: 'data_prima_rich', type: 'input', inputType: 'date', operator: FILTER_OPERATORS.EQUAL },
    { label: 'Data Accreditamento', field: 'data_accettazione', type: 'input', inputType: 'date', operator: FILTER_OPERATORS.EQUAL },
    { label: 'Tipo accreditamento', field: 'tipo_accr', type: 'select', operator: FILTER_OPERATORS.EQUAL, options: TIPOLOGIE_ACCREDITAMENTO_OPTIONS },
    {
      label: 'Stato',
      field: 'stato',
      type: 'select',
      options: tipoForm !== 3 ? STATI_AGENZIE_OPTIONS : STATI_ENTI_OPTIONS,
    },
  ]

  if (tipoForm === 1) {
    filters = [
      { label: 'Ragione Sociale', field: 'rag_soc', type: 'input', operator: FILTER_OPERATORS.LIKE },
      { label: 'Data Richiesta', field: 'data_prima_rich', type: 'input', inputType: 'date', operator: FILTER_OPERATORS.EQUAL },
      { label: 'Data Accreditamento', field: 'data_accettazione', type: 'input', inputType: 'date', operator: FILTER_OPERATORS.EQUAL },
      { label: 'Tipo Accreditamento', field: 'tipo_accr', type: 'select', operator: FILTER_OPERATORS.EQUAL, options: TIPOLOGIE_ACCREDITAMENTO_OPTIONS },
      {
        label: 'Stato',
        field: 'stato',
        type: 'select',
        options: STATI_AGENZIE_OPTIONS ,
      },
      {
        label: 'Proroga',
        field: 'dataFineProroga',
        type: 'select',
        options: [
        { value: '1'  ,  label: 'Valida' },
        { value: '2'  , label: 'Scaduta' }
      ]
    },
    ]

    columns = [
      { label: 'Ragione Sociale', field: 'rag_soc', isSortable: true },
      { label: 'Data Richiesta', field: 'data_prima_rich', isSortable: true },
      { label: 'Data Accreditamento', field: 'data_accettazione', isSortable: true },
      {
        label: 'Tipo accreditamento',
        field: 'tipo_accr',
        isSortable: true,
        render: (row) => {
          return TIPOLOGIE_ACCREDITAMENTO_OPTIONS.find((opt) => {
            return opt.value === row?.tipo_accr
          })?.label
        },
      },
      {
        label: 'Stato',
        field: 'stato',
        isSortable: true,
        render: (row) => {
          let stato = STATI_AGENZIE[row.stato]

          if (  row.tipo_ag_form === 1 && row?.dataFineProroga && moment(row.dataFineProroga).isSameOrAfter( moment(), 'day') )
          {
            stato += ' (Prorogata)'
          }

          if (row.data_fine && row.tipo_ag_form === 2 && row.stato === STATI_AGENZIE_VALUES.ACCREDITATA && moment().diff(row.data_fine) >= 0) {
            stato = 'Scaduta'
          } else if (
            row.data_accettazione &&
            !row.data_fine &&
            row.tipo_ag_form === 2 &&
            row.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
            moment().diff(row.data_accettazione, 'years', true) >= 3
          ) {
            stato = 'Scaduta'
          }

          if (row.data_fine && row.tipo_ag_form === 3 && row.stato === STATI_AGENZIE_VALUES.ACCREDITATA && moment().diff(row.data_fine) >= 0) {
            stato = 'Scaduto'
          }
          if (row.tipo_ag_form === 3 && row.stato === STATI_AGENZIE_VALUES.REVOCATA) {
            stato = 'Cancellato'
          }
          return stato
        },
      },
      { label: 'Prorogato al', field: 'dataFineProroga', isSortable: true,
      render: (row) => {
        if(row?.dataFineProroga)
        { if( moment(row.dataFineProroga).isSameOrAfter( moment(), 'day') )
            { return U.data_normalize(row.dataFineProroga) }
          else
          {  return U.data_normalize(row.dataFineProroga)+' (Scaduta)' }
        }
      }
      },
    ]
  }


  if (tipoForm === 3) {
    filters = [
      { label: 'Ragione Sociale', field: 'rag_soc', type: 'input', operator: FILTER_OPERATORS.LIKE },
      {
        label: 'Stato',
        field: 'stato',
        type: 'select',
        options: tipoForm !== 3 ? STATI_AGENZIE_OPTIONS : STATI_ENTI_OPTIONS,
      },
      { label: 'Da', field: 'data_inizio', type: 'input', inputType: 'date', operator: 'GTE' },
      { label: 'A', field: 'data_fine', type: 'input', inputType: 'date', operator: 'LTE' },
    ]

    columns = [
      { label: 'Ragione Sociale', field: 'rag_soc', isSortable: true },
      {
        label: 'Stato',
        field: 'stato',
        isSortable: true,
        render: (row) => {
          let stato = STATI_AGENZIE[row.stato]
          if (
            row.data_accettazione &&
            row.tipo_ag_form === 1 &&
            row.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
            moment().diff(row.data_accettazione, 'years', true) >= 3
          ) {
            stato = 'Scaduta'
          }

          if (row.data_fine && row.tipo_ag_form === 2 && row.stato === STATI_AGENZIE_VALUES.ACCREDITATA && moment().diff(row.data_fine) >= 0) {
            stato = 'Scaduta'
          } else if (
            row.data_accettazione &&
            !row.data_fine &&
            row.tipo_ag_form === 2 &&
            row.stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
            moment().diff(row.data_accettazione, 'years', true) >= 3
          ) {
            stato = 'Scaduta'
          }

          if (row.data_fine && row.tipo_ag_form === 3 && row.stato === STATI_AGENZIE_VALUES.ACCREDITATA && moment().diff(row.data_fine) >= 0) {
            stato = 'Scaduto'
          }
          if (row.tipo_ag_form === 3 && row.stato === STATI_AGENZIE_VALUES.REVOCATA) {
            stato = 'Cancellato'
          }
          return stato
        },
      },
      { label: 'Data Inizio Convenzione', field: 'data_inizio', isSortable: true },
      { label: 'Data Fine Convenzione', field: 'data_fine', isSortable: true },
    ]
  }

  if (tipoForm === 2) {
    filters = [
      { label: 'Ragione Sociale', field: 'rag_soc', type: 'input', operator: FILTER_OPERATORS.LIKE },
      { label: 'CF', field: 'cod_fisc', type: 'input', operator: 'LIKE' },
      { label: 'Codice ISSN', field: 'cod_issn', type: 'input', operator: 'LIKE' },
    ]

    columns = [
      { label: 'Ragione Sociale', field: 'rag_soc', isSortable: true },
      { label: 'CF', field: 'cod_fisc', isSortable: true },
      { label: 'Codice ISSN', field: 'cod_issn', isSortable: true },
      {
        label: 'Tipologia Rivista',
        field: 'tipo_accr',
        isSortable: true,
        render: (row) => {
          return TIPOLOGIE_RIVISTA_OPTIONS.find((opt) => {
            return opt.value === row?.tipo_accr
          })?.label
        },
      },
    ]
  }

  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />

        <GenericAsyncTableData
          tableKey={'lista.agenzie'}
          emptyMessage={'Nessun elemento trovato'}
          filters={filters}
          columns={columns}
          getRows={loadData}
          getRowActions={(row) => {
            const { stato } = row || {}
            let azioni: any = [{ label: 'Dettaglio', onClick: onDetails(row, DETAILS_ACTIONS.DETTAGLIO) }]

            const isConafGest = TokenStore.getInstance().currentAccountHasRolesOR([RO_GEST_FORM_CONAF])
            const isConafCons = TokenStore.getInstance().currentAccountHasRolesOR([RO_CONS_FORM_CONAF])

            const showFormale = isConafGest && stato === STATI_AGENZIE_VALUES.ISTRUTTORIA
            const showMerito = isConafCons && stato === STATI_AGENZIE_VALUES.FORMALE
            const showMinisteriale = isConafGest && (stato === STATI_AGENZIE_VALUES.MERITO || stato === 4)
            const showRigetta =
              (isConafCons && [STATI_AGENZIE_VALUES.ISTRUTTORIA].includes(stato)) ||
              (isConafGest && [STATI_AGENZIE_VALUES.ISTRUTTORIA, STATI_AGENZIE_VALUES.MERITO, STATI_AGENZIE_VALUES.FORMALE].includes(stato))

            const showIntegrazione = (isConafGest && stato === STATI_AGENZIE_VALUES.ISTRUTTORIA) || (isConafCons && stato === STATI_AGENZIE_VALUES.ISTRUTTORIA)

            const showModifica =
              ((isConafGest || isConafCons) &&
                ![
                  STATI_AGENZIE_VALUES.IN_COMPILAZIONE,
                  STATI_AGENZIE_VALUES.ACCREDITATA,
                  STATI_AGENZIE_VALUES.FORMALE,
                  STATI_AGENZIE_VALUES.MERITO,
                  STATI_AGENZIE_VALUES.NO_ACCREDITATA,
                  STATI_AGENZIE_VALUES.INTEGRAZIONE,
                ].includes(stato)) ||
              (stato === STATI_AGENZIE_VALUES.INTEGRAZIONE && row.id === agenziaFormativa?.id) ||
              (stato === STATI_AGENZIE_VALUES.IN_COMPILAZIONE && row.id === agenziaFormativa?.id)

            const showModificaBO = STATI_AGENZIE_VALUES.ACCREDITATA && isConafGest && (row.tipo_ag_form === 3 || row.tipo_ag_form === 2)

            const showRinnovo =
              (tipoForm === 1 &&
                agenziaFormativa?.data_accettazione &&
                ( stato === STATI_AGENZIE_VALUES.SCADUTA ) && row.id === agenziaFormativa?.id) ||

              (row.tipo_ag_form === 2 &&
                row.data_fine &&
                stato === STATI_AGENZIE_VALUES.ACCREDITATA &&
                row.id === agenziaFormativa?.id &&
                moment().diff(row.data_fine) >= 0) ||
              (row.tipo_ag_form === 2 && stato === STATI_AGENZIE_VALUES.REVOCATA)

            const showRevoca = tipoForm === 2 && stato === STATI_AGENZIE_VALUES.ACCREDITATA
            const showCancella = tipoForm === 3 && stato === STATI_AGENZIE_VALUES.ACCREDITATA

            const showAccProroga = tipoForm === 1 && isConafGest && !row.dataFineProroga && row.stato !== STATI_AGENZIE_VALUES.REVOCATA && row.stato !== STATI_AGENZIE_VALUES.NO_ACCREDITATA
            const showEditProroga = tipoForm === 1 && isConafGest && row.dataFineProroga

            const isRappLegaleAF = agenziaFormativa?.lr_codice_fiscale === TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber

            if (!isFrontOffice) {
              azioni = [
                ...azioni,
                showFormale && { label: 'Approvazione formale', onClick: onDetails(row, DETAILS_ACTIONS.APPROVAZIONE_FORMALE) },
                showMerito && { label: 'Approvazione di merito', onClick: onDetails(row, DETAILS_ACTIONS.APPROVAZIONE_MERITO) },
                showMinisteriale && { label: 'Approvazione ministeriale', onClick: onDetails(row, DETAILS_ACTIONS.APPROVAZIONE_MINISTERIALE) },
                showRigetta && { label: 'Rigetta', onClick: onDetails(row, DETAILS_ACTIONS.RIGETTA) },
                showIntegrazione && { label: 'Richiedi integrazioni', onClick: onDetails(row, DETAILS_ACTIONS.RICHIESTA_INTEG) },
                showModificaBO && { label: 'Modifica', onClick: onDetails(row, DETAILS_ACTIONS.MODIFICA) },
                showRevoca && { label: 'Revoca', onClick: onDetails(row, DETAILS_ACTIONS.RIGETTA) },
                showCancella && { label: 'Cancella', onClick: onDetails(row, DETAILS_ACTIONS.RIGETTA) },

                showAccProroga && { label: 'Proroga accreditamento', onClick: onDetails(row, DETAILS_ACTIONS.PROROGA) },
                showEditProroga && { label: 'Modifica Proroga', onClick: onDetails(row, DETAILS_ACTIONS.PROROGA) },
              ]
            } else {
              azioni = [
                ...azioni,
                showModifica && { label: 'Modifica', onClick: onDetails(row, DETAILS_ACTIONS.MODIFICA) },
                isRappLegaleAF && showRinnovo && { label: 'Rinnovo', onClick: onDetails(row, DETAILS_ACTIONS.MODIFICA) },
              ]
            }
            return azioni.filter((a) => a)
          }}
        />
      </div>
    </PageSection>
  )
}

export default ElencoAgenzie
