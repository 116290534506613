import * as React from 'react'
import { EmptyState, EmptyStateBody, EmptyStateIcon, Form, Title } from '@patternfly/react-core'
import { ExclamationTriangleIcon } from '@patternfly/react-icons'
import GenericTextInput from 'src/backoffice/app/components/GenericTextInput'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDivice = isCurrentMobileDevice()

export class FormReferenti extends React.Component<GeneralFormReferentiProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      dpo: props.dpo,
      dipartimento: props.dipartimento,
      responsabileTrasparenza: props.responsabileTrasparenza,
      responsabileAnticorruzione: props.responsabileAnticorruzione,
      referenteComunicazione: props.referenteComunicazione,
      referenteFormazione: props.referenteFormazione,
      referenteDigitalizzazione: props.referenteDigitalizzazione,
      responsabilePrivacy: props.responsabilePrivacy,
      messaggio: props.messaggio,
      isLoaded: false,
    }
  }

  componentDidMount(): void {
    this.setState({ isLoaded: true })
  }

  render() {
    const { returnedObject, messaggio } = this.props
    const {
      dpo,
      responsabileTrasparenza,
      responsabileAnticorruzione,
      referenteComunicazione,
      referenteFormazione,
      referenteDigitalizzazione,
      responsabilePrivacy,
      dipartimento,
      isLoaded,
    } = this.state

    if (isLoaded) {
      return (
        <>
          {returnedObject === '' &&
          messaggio === '' &&
          responsabileTrasparenza &&
          responsabileAnticorruzione &&
          referenteComunicazione &&
          referenteFormazione &&
          referenteDigitalizzazione &&
          dpo &&
          responsabilePrivacy &&
          dipartimento ? (
            <GenericSpinner />
          ) : messaggio !== '' ? (
            <EmptyState variant="full">
              <EmptyStateIcon icon={ExclamationTriangleIcon} />
              <EmptyStateBody>Non è stato possibile recuperare i dati. Contattare l'assistenza.</EmptyStateBody>
            </EmptyState>
          ) : (
            <div>
              <Form>
                <Title headingLevel="h3">Dpo</Title>
                {dpo?.map((dpo, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'dpo' + index}
                          label={'Nominativo'}
                          value={dpo?.nome && dpo?.cognome ? dpo?.nome + ' ' + dpo?.cognome : '-'}
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput type={'text'} className="w-75" id={'email'} label={'Email'} value={dpo?.email ? dpo?.email : '-'} isReadOnly />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Referente Comunicazione</Title>
                {referenteComunicazione?.map((referenteComunicazione, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'nomeReferenteComunicazione' + index}
                          label={'Nominativo'}
                          value={
                            referenteComunicazione?.nome && referenteComunicazione?.cognome
                              ? referenteComunicazione?.nome + ' ' + referenteComunicazione?.cognome
                              : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={referenteComunicazione?.email ? referenteComunicazione?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Referente Formazione</Title>
                {referenteFormazione?.map((referenteFormazione, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'nomeReferenteFormazione' + index}
                          label={'Nominativo'}
                          value={
                            referenteFormazione?.nome && referenteFormazione?.cognome ? referenteFormazione?.nome + ' ' + referenteFormazione?.cognome : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={referenteFormazione?.email ? referenteFormazione?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Referente Digitalizzazione</Title>
                {referenteDigitalizzazione?.map((referenteDigitalizzazione, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'nomeReferenteDigitalizzazione' + index}
                          label={'Nominativo'}
                          value={
                            referenteDigitalizzazione?.nome && referenteDigitalizzazione?.cognome
                              ? referenteDigitalizzazione?.nome + ' ' + referenteDigitalizzazione?.cognome
                              : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={referenteDigitalizzazione?.email ? referenteDigitalizzazione?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Responsabile Trasparenza</Title>
                {responsabileTrasparenza?.map((responsabileTrasparenza, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'nomeResponsabileTrasparenza' + index}
                          label={'Nominativo'}
                          value={
                            responsabileTrasparenza?.nome && responsabileTrasparenza?.cognome
                              ? responsabileTrasparenza?.nome + ' ' + responsabileTrasparenza?.cognome
                              : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={responsabileTrasparenza?.email ? responsabileTrasparenza?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Responsabile Anticorruzione</Title>
                {responsabileAnticorruzione?.map((responsabileAnticorruzione, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'nomeResponsabileAnticorruzione' + index}
                          label={'Nominativo'}
                          value={
                            responsabileAnticorruzione?.nome && responsabileAnticorruzione?.cognome
                              ? responsabileAnticorruzione?.nome + ' ' + responsabileAnticorruzione?.cognome
                              : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={responsabileAnticorruzione?.email ? responsabileAnticorruzione?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3">Responsabile Privacy</Title>
                {responsabilePrivacy?.map((responsabilePrivacy, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'nomeResponsabilePrivacy' + index}
                          label={'Nominativo'}
                          value={
                            responsabilePrivacy?.nome && responsabilePrivacy?.cognome ? responsabilePrivacy?.nome + ' ' + responsabilePrivacy?.cognome : '-'
                          }
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={responsabilePrivacy?.email ? responsabilePrivacy?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
                <Title headingLevel="h3" hidden={!dipartimento || dipartimento.length === 0}>
                  Dipartimenti
                </Title>
                {dipartimento?.map((dipartimento, index) => {
                  return (
                    <div className="row">
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'dipartimento' + index}
                          label={'Numero Dipartimento'}
                          value={dipartimento?.dipartimento ? 'DIPARTIMENTO ' + dipartimento?.dipartimento : '-'}
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'descrizione'}
                          label={'Descrizione'}
                          value={dipartimento?.descrizione ? dipartimento?.descrizione : '-'}
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <br></br>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'nomeReferenteDipartimento' + index}
                          label={'Nominativo'}
                          value={dipartimento?.nome && dipartimento?.cognome ? dipartimento?.nome + ' ' + dipartimento?.cognome : '-'}
                          isReadOnly
                        />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                        <br></br>
                        <GenericTextInput
                          type={'text'}
                          className="w-75"
                          id={'email'}
                          label={'Email'}
                          value={dipartimento?.email ? dipartimento?.email : '-'}
                          isReadOnly
                        />
                      </div>
                    </div>
                  )
                })}
              </Form>
            </div>
          )}
        </>
      )
    } else {
      return <GenericSpinner />
    }
  }
}

export declare interface GeneralFormReferentiProps {
  returnedObject?: any
  messaggio?: string
  responsabileTrasparenza?: any
  responsabileAnticorruzione?: any
  referenteComunicazione?: any
  referenteFormazione?: any
  referenteDigitalizzazione?: any
  dpo?: any
  dipartimento?: any
  responsabilePrivacy?: any
}
