import * as React from 'react'
import { PageSection } from '@patternfly/react-core'
import { DropdownMenu } from 'reactstrap'
import { DropdownToggle, LinkList, LinkListItem, UncontrolledDropdown } from 'design-react-kit'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import { PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO, PATH_TO_FO_STUDI_ASSOCIATI_ELENCO } from 'src/app/utils/RoutesConstants'
import GenericTitle from '../../components/GenericTitle'
import GenericCard from '../../components/GenericCard'
import dots from '../../images/moreItems.png'
import { goTo, isEmptyValue } from 'src/utilities/utility'
import { GenericTable } from 'src/backoffice/app/components'
import { cancellaStudioAssociatoAlSoggetto, getStudiAssociatiByIdSoggetto } from 'src/processes/StudioAssociato'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import { getIscrizioneOrdineByCfFO } from 'src/processes/Soggetto'


const title = 'Studi associati'
const cardHeader = "Elenco studi associati"

var breadcrumbOptions = [
    { label: "Studi associati", link: PATH_TO_FO_STUDI_ASSOCIATI_ELENCO },
    { label: "Elenco studi associati", link: PATH_TO_FO_STUDI_ASSOCIATI_ELENCO }
]

const listHeaders = [
    {id:1, name: "CF/P.IVA"},
    {id:2, name: "RAGIONE SOCIALE"},
    {id:3, name: "PROVINCIA"},
    {id:4, name: "COMUNE"},
    {id:5, name: "INDIRIZZO"},
    {id:6, name: "CAP"},
]

class ElencoStudiAssociati extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            message: "",
            typeMessage: "default",
            isLoaded: false,
            listaStudiAssociati: [],
        };
    }

    async componentDidMount() {

        let idSoggetto = TokenStore.getInstance().getCurrentAccount().idSoggetto;
        this.setState({ isLoaded: false, idSoggetto: idSoggetto})

        await getStudiAssociatiByIdSoggetto(idSoggetto).then(async responseStudi => {

            let listaStudi: any = [];
            responseStudi.map(async studio => {
                let obj = {
                        id: studio.idStudioAssociato,
                        data: [
                            (!isEmptyValue(studio.cfPiva)) ? studio.cfPiva : '-',
                            (!isEmptyValue(studio.ragioneSociale)) ? studio.ragioneSociale : '-',
                            (!isEmptyValue(studio.provincia)) ? studio.provincia : '-',
                            (!isEmptyValue(studio.comune)) ? studio.comune : '-',
                            (!isEmptyValue(studio.indirizzo)) ? studio.indirizzo : '-',
                            (!isEmptyValue(studio.cap)) ? studio.cap : '-',
                        ]
                }

                await listaStudi.push(obj)
            })
            await this.setState({ listaStudiAssociati: listaStudi, isLoaded: true})
        }).catch((err => {
            this.setState({ listaStudiAssociati: [], isLoaded: true})
        }))
    }

    showModal = async (booleanValue, title, text) => {
            await this.setState({
                modalOpen: booleanValue,
                modalTitle: title,
                modalText:text,
                isLoaded: true,
            });
    }

    createStudioAssociato = async () => {
        await getIscrizioneOrdineByCfFO(TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber.toUpperCase())
            .then(async (responseSoggetto: any) => {
                if(responseSoggetto.data.returnedObject === null) {
                  this.showModal(true, "Errore",responseSoggetto.data.error);
                }
                else {
                    goTo(PATH_TO_FO_NUOVO_STUDIO_ASSOCIATO)
                }
            }).catch((err: any) => {
              this.showModal(true, "Errore",err);
            })
}

    closeModal = () => {
            this.setState({modalOpen:false});
            goTo(PATH_TO_FO_STUDI_ASSOCIATI_ELENCO)

    }

    /** funzione che elimina l'associazione dello studio associato di un utente */
    cancellaStudioAssociato = () => {
        cancellaStudioAssociatoAlSoggetto(this.state.idSoggetto, this.state.selectedRow.id)
            .then((response: any) => {
                this.showModal(true, "Messaggio", "Studio associato - CF/P.IVA " + this.state.selectedRow.data[0] + " - cancellato con successo!");
            })
            .catch((err) => {
                this.showModal(true, "Errore.","Si è verificato un errore durante l'invio. Riprova in un secondo momento.");
            })

    }
    render() {
        const { isLoaded, listaStudiAssociati} = this.state;
        const onSelectRow = (row: any) => {
            this.setState({selectedRow: row})
        }

        return (
            <div className="container-fluid">
                {isLoaded ?
                    <div className="container-fluid">
                         <div className='row'>
                            <div className='col-10 offset-1'>
                                <PageSection id='pageSectionId' className="container-fluid">
                                <div className='container-fluid'>
                                    <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
                                    <GenericTitle Size="h1" title={title}></GenericTitle>

                                    <div className="Card">
                                        <GenericCard
                                            cardHeader={
                                                <div className='justify-content-between d-flex'>
                                                    <h5 className='card-title'>{cardHeader}</h5>
                                                    <UncontrolledDropdown size='xs' nav tag="div">
                                                        <DropdownToggle caret nav>
                                                        <button className='dots-btn'><img src={dots} alt="" /></button>
                                                        </DropdownToggle>
                                                        <div className='docs-show-dropdown-open'>

                                                        <DropdownMenu style={{width: '230px'}} className='full-width'>
                                                            <LinkList>
                                                            <LinkListItem onClick={()=>this.createStudioAssociato()} size='large'>
                                                                <span>Aggiungi studio associato</span>
                                                            </LinkListItem>
                                                            </LinkList>

                                                        </DropdownMenu>
                                                        </div>
                                                    </UncontrolledDropdown>

                                                </div>
                                            }
                                            cardBody={
                                                <div>
                                                    {
                                                    (this.state.isLoaded) ?
                                                    <GenericTable
                                                        hideCsv hideSearch hideDropdown hideFilters hideCertificateColumn
                                                        autoWidthColumns isEditableTable={false}
                                                        columns={listHeaders?.map((column) => column.name)}
                                                        onSelect={onSelectRow}
                                                        rows={listaStudiAssociati}
                                                        tableHeadFontSize="0.8em"
                                                        tableContentFontSize="0.8em"
                                                        hidePagination={true}
                                                        hideActionColumn={false}
                                                        actions={[{ label: "Aggiorna", function: '' }]}
                                                        dropdownList={[{ title: 'Cancella', onClick: (e) => this.cancellaStudioAssociato(), itemKey: 'cancella' }]}
                                                    />
                                                    :
                                                        <div><GenericSpinner></GenericSpinner></div>
                                                    }
                                                </div>
                                            }
                                        />
                                    </div>

                                </div>
                                <GenericModal
                                    title={this.state.modalTitle}
                                    text={this.state.modalText}
                                    modalOpen={this.state.modalOpen}
                                    closeButton={this.closeModal}
                                />
                                </PageSection>
                            </div>
                        </div>
                        <br></br>
                    </div>
                :
                <GenericSpinner></GenericSpinner>
                }

            </div>
        );
    }
};


export default ElencoStudiAssociati;
