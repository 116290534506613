import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox, PageSection, Title } from '@patternfly/react-core'
import styled from 'styled-components'

import { generateRequestFilter, generateRequestSort } from 'src/app/utils/ApiUtils'
import useFrontOffice from 'src/hooks/useFrontOffice'
import { GenericAsyncTableData, RequestData } from 'src/backoffice/app/components/GenericAsyncTableData'
import {
  PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP,
  PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP
} from 'src/app/utils/RoutesConstants'

import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { getAllEnti, TIPO_ENTE } from 'src/model/EnteFormazione'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import { TRIENNI_OPTIONS } from './ConcessioneDeroga'
import { GenericSpinner } from '../../components'

let lastRequest = null

const LinkStyled = styled.div`
  height: 100px;
  display: flex;
  align-items: top;
  justify-content: end;
`
const Spacer = () => (
  <>
    <br></br>
  </>
)
const IscrittiOrdineCFP: React.FunctionComponent = (props: any) => {
  const isFrontOffice = useFrontOffice()
  const myPath = {
    label: 'Ripartizione automatica dei CFP',
    link: PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP,
  }
  const [enti, setListEnti] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<{ [key: string]: boolean }>({})
  const [triennio, setTriennio] = useState<any>(false)
  const [dirty, setDirty] = useState<boolean>(false)
  const [dirtyAllSelection, setDirtyAllSelection] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<any>({ open: false, message: '' })

  useEffect(() => {
    getAllEnti()
      .then((res) => {
        setListEnti(res)
      })
      .catch(console.error)
  }, [])

  const gettableData = async (request) => {
    try {
      if (triennio) request.filters.push({ field: 'deroghe.triennio', operator: 'EQUAL', value: triennio.value })
      const { data } = await getFormazioneResourceApi().msfoFormazioneListaIscrittiOrdineFilterableGet(
        generateRequestFilter(request.filters),
        request.pageSize,
        request.currentPage,
        generateRequestSort(request.sortColumn, request.sortDirection),
        getDefaultAxiosConfig()
      )
      return {
        items: data.items || [],
        total: data.count || 0,
      }
    } catch (e: any) {
      return {
        items: [],
        total: 0,
      }
    }
  }

  const selezionaTutti = async () => {
    setLoading(true)
    const { data } = await getFormazioneResourceApi().msfoFormazioneListaIscrittiOrdineFilterableGet(
      generateRequestFilter(lastRequest.filters),
      -1,
      -1,
      generateRequestSort(lastRequest.sortColumn, lastRequest.sortDirection),
      getDefaultAxiosConfig()
    )

    for (const item of data.items) {
      selectedUsers[item.cf] = true
    }
    setSelectedUsers(selectedUsers)
    setLoading(false)
  }

  const deselezionaTutti = async () => {
    setLoading(true)
    setSelectedUsers({})

    setTimeout(() => {
      setLoading(false)
    }, 10)
  }

  const loadData = useCallback(
    async (request: RequestData) => {
      lastRequest = request
      return await gettableData(request)
    },
    [triennio]
  )

  const filters: any = [
    { label: 'Cognome', field: 'cognome', type: 'input', operator: 'LIKE' },
    { label: 'Nome', field: 'nome', type: 'input', operator: 'LIKE' },
    { label: 'Codice Fiscale', field: 'cf', type: 'input', operator: 'LIKE' },
  ]

  const assegnaCFP = async () => {
    setLoading(true)

    let messaggio = { head: 'Errore nella ripartizione automatica di crediti.', data: [] }
    let ok = false
    try {
      const cf_list = Object.keys(selectedUsers)
      const { data: res }: any = await getFormazioneResourceApi().msfoFormazioneRiconciliaCreditiAutomaticamentePost(
        { cfList: cf_list, triennio: triennio.value },
        getDefaultAxiosConfig()
      )
      messaggio.head = 'Resoconto ripartizione automatica: '
      res.forEach((ris) => {
        messaggio.data.push(ris.message)
      })
      ok = true
    } catch (e: any) {
    }
    setLoading(false)
    setShowModal({ open: true, message: messaggio, ok: ok })
  }

  const assegnaManualmenteCFP = async (row) => {
    props.history.push(PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP + '?cf=' + row.cf + '&triennio=' + triennio.value)
  }

  const onCloseModal = () => {
    if (showModal.ok) setSelectedUsers({})
    setShowModal({ open: false })
  }

  const renderSelectColumn = (row) => {
    const checked = !!selectedUsers[row.cf]
    return (
      <Checkbox
        id={'select-' + row.id_soggetto}
        onClick={() => {
          selectedUsers[row.cf] = !checked
          setSelectedUsers(selectedUsers)
          setDirtyAllSelection(!dirtyAllSelection)
        }}
        checked={checked}
      />
    )
  }

  if (TokenStore.getInstance().isCurrentAccountCONAF()) {
    filters.push({
      label: 'Ordine di appartenenza',
      field: 'ordine_territoriale_competente',
      type: 'select',
      options: Object.keys(enti)
        .filter((code) => enti[code].tipo === TIPO_ENTE.ORDINE)
        .map((code) => {
          return {
            label: enti[code].nome,
            value: code,
          }
        }),
    })
  }

  let oneSelected = false
  for (const key in selectedUsers) {
    if (selectedUsers[key]) {
      oneSelected = true
      break
    }
  }

  return (
    <PageSection>
      <div className="container  register-container">
        <GenericBreadCrumb paths={[myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />

        <div className="row">
          <div className="col-9">
            <GenericSelect
              id="triennio"
              label="Triennio verso il quale ripartire i CFP"
              defaultOptions={TRIENNI_OPTIONS}
              value={triennio}
              onChange={(cod, val) => {
                setDirty(false)
                setTriennio(val)
                setTimeout(() => {
                  setDirty(true)
                }, 10)
              }}
            />
          </div>
          <div className="col-3">
            {loading ? (
              <GenericSpinner />
            ) : (
              <>
                <Button color="primary" disabled={!oneSelected || !triennio.value} onClick={assegnaCFP}>
                  Ripartizione Automatica dei CFP
                </Button>
                <p>Utenti selezionati: {Object.keys(selectedUsers).length}</p>
              </>
            )}
          </div>
        </div>

        {dirty ? (
          <>
            {!oneSelected && (
              <div className="col-6">
                <Button
                  color="primary"
                  onClick={() => {
                    selezionaTutti()
                  }}
                >
                  Seleziona tutti
                </Button>
              </div>
            )}
            {oneSelected && (
              <div className="col-6">
                <Button
                  color="primary"
                  onClick={() => {
                    deselezionaTutti()
                  }}
                >
                  Deseleziona tutti
                </Button>
              </div>
            )}

            <GenericAsyncTableData
              tableKey={'lista.assegnazione.cfp'}
              emptyMessage={'Nessun iscritto trovato'}
              // TODO check fields for iscritti all'ordine
              filters={filters}
              columns={[
                { label: 'Seleziona', field: '', isSortable: false, render: renderSelectColumn },
                { label: 'Cognome', field: 'cognome', isSortable: true },
                { label: 'Nome', field: 'nome', isSortable: true },
                { label: 'Codice Fiscale', field: 'cf', isSortable: true },
                { label: 'Ordine di appartenenza', field: 'ordine_territoriale_competente', isSortable: true },
              ]}
              getRows={loadData}
              getRowActions={(row) => {
                return [{ label: 'Ripartizione Manuale dei CFP', onClick: () => assegnaManualmenteCFP(row) }] //, { label: 'Concedi Deroga',onClick: (e) => props.history.push(PATH_TO_BO_CONCEDI_DEROGA + "?cf=" + row.cf)}
              }}
            />
          </>
        ) : (
          <p>Seleziona triennio</p>
        )}
      </div>
      {showModal.open && (
        <Modal fade centered isOpen={showModal.open} toggle={onCloseModal}>
          <ModalHeader style={{ fontSize: '25px' }} id="concessioneAutoCFP">
            Ripartizione CFP
          </ModalHeader>
          <ModalBody>
            <p>{showModal.message.head}</p>
            <Spacer />
            {showModal.message.data.map((dato) => (
              <div>
                <p>{dato}</p>
                <Spacer />
              </div>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onCloseModal}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </PageSection>
  )
}

export default IscrittiOrdineCFP
