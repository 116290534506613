import React from 'react'

import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'

const FormDatiDichiarazioni: React.FunctionComponent<FormDatiDichiarazioniProps> = ({
                                                                                      title,
                                                                                      data,
                                                                                      handleInput,
                                                                                      onInitInput,
                                                                                      errors,
                                                                                      isDisabled
                                                                                    }) => {

  const listError = errors || {}
  return (
    <GenericCard cardHeader={title} className={'no-padding form-dichiarazioni'}>

      <div className='row'>
        <div className='col-24'>
          <GenericInput
            type={'checkbox'}
            id='vis_delibera'
            label={' Dichiara di aver preso visione della delibera CONAF n° 113/2014 "Definizione dei costi standard delle attività formative" **'}
            value={data.vis_delibera}
            checked={data.vis_delibera}
            onChange={handleInput}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError['vis_delibera']}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-24'>
          <GenericInput
            type={'checkbox'}
            id='si_impegna_non_sup_costi_standard'
            value={data.si_impegna_non_sup_costi_standard}
            checked={data.si_impegna_non_sup_costi_standard}
            label={' Si impegna a non superare i costi standard per le diverse tipologie di attività formativa. (visualizza tabella costi standard) **'}
            onChange={handleInput}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError['si_impegna_non_sup_costi_standard']}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-24'>
          <GenericInput
            type={'checkbox'}
            id='si_impegna_rendere_evidenti_costi_standard'
            label={'Si impegna a rendere evidenti i costi delle attività formative per gli iscritti come da avvertenze ANAC **'}
            onChange={handleInput}
            value={data.si_impegna_rendere_evidenti_costi_standard}
            checked={data.si_impegna_rendere_evidenti_costi_standard}
            onInit={onInitInput}
            isDisabled={isDisabled}
            errorMessage={listError['si_impegna_rendere_evidenti_costi_standard']}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }} />
        </div>
      </div>
      <div className='row'>
        <div className='col-24'>

        </div>
      </div>

    </GenericCard>
  )
}

interface FormDatiDichiarazioniProps {
  onInitInput?: any;
  handleInput?: any;
  data?: any;
  errors?: any;
  title: string;
  isDisabled?: boolean
}

export default FormDatiDichiarazioni
