import * as React from 'react'
import { Form } from 'design-react-kit'
import GenericInput from 'src/frontend/app/components/GenericInput'
import { compilaIscrizioneOSTP } from 'src/processes/ProcessoIscrizione'
import { getSoggettoByCfAndTipoAnagrafeFO } from 'src/processes/Soggetto'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { inputRequiredValidation, isEmail } from 'src/utilities/utility'

export class FormIscrizioneOperatoreSTP extends React.Component<GeneralFormIscrizioneOperatoreSTPProps, any> {

    handleSubmit = event => {
        //setto a true il flag submitted
        this.props.submitted(true)
        /*--------------------------------------------- Validazione campi obbligatori ------------------------------------------------*/
        var requiredFields = [
            { value: event.target.idCfSTP.value, label: "CF STP" },
            { value: isEmail(event.target.idEmailContattoSTP.value) ? event.target.idEmailContattoSTP.value : '', label: "Email di contatto" }
        ]

        var errorRequired: any = inputRequiredValidation(requiredFields)
        if (errorRequired !== undefined && errorRequired !== "") {
            //setto a false il flag submitted
            this.props.submitted(false)
            event.preventDefault()
            return this.props.messaggio({ messaggio: errorRequired.messaggio, type: errorRequired.type })
        }
        /*----------------------------------------------------------------------------------------------------------------------------*/
        else {
            event.preventDefault()
            var parametriCompilazioneIscrizioneOSTP = {
                codiceFiscaleOperatoreSTP: event.target.idCfSTP.value ? event.target.idCfSTP.value : '',
                emailOperatoreSTP: event.target.idEmailContattoSTP.value ? event.target.idEmailContattoSTP.value : ''
            }

            const token = TokenStore.getInstance().getDecodedTokenJWT();
            var cf = token.fiscalNumber
            var tipoAnagrafe = 'PF'

            //Chiamata al servizio 'getSoggettoByCfAndTipoAnagrafe' per recuperare l'idSoggetto
            getSoggettoByCfAndTipoAnagrafeFO(cf, tipoAnagrafe).then((response: any) => {
                //Compilazione Iscrizione OSTP
                compilaIscrizioneOSTP(response.data.idSoggetto, parametriCompilazioneIscrizioneOSTP).then((response: any) => {
                    if (response.data.httpStatus === 200)
                    {
                        this.props.submitted(true)
                        return this.props.messaggio({ messaggio: "Iscrizione avvenuta con successo", type: "success" })
                    }

                    else
                    {
                        this.props.submitted(false)
                        throw new Error(response.data.error);
                    }

                }).catch((err) => {
                    this.props.submitted(false)
                    return this.props.messaggio({ messaggio: "C'è stato un problema nell'invio dei dati: " + err, type: "danger" })
                })
            }).catch(() => {
                this.props.submitted(false)
                return this.props.messaggio({ messaggio: "C'è stato un problema durante il recupero dei dati", type: "danger" })
            })
        }
    }

    render() {
        return (
            <div>
                <Form id={'idIscrizioneOperatoreSTP'} onSubmit={this.handleSubmit}>
                    <div className='row'>
                        <div className='col-6'>
                            <GenericInput type={'cf'} id={'idCfSTP'} placeholder={"Inserisci il codice fiscale STP"} label={"CF STP*"} isRequired />
                        </div>
                        <div className='col-6'>
                            <GenericInput type={'email'} id={'idEmailContattoSTP'} placeholder={"Email di contatto"} label={"Email di contatto*"} isRequired />
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export declare interface GeneralFormIscrizioneOperatoreSTPProps {
    messaggio?: any;
    submitted?: any;
}
