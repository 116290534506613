import React from 'react'
import { FormGroup } from '@patternfly/react-core'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import {
  getCampagneAssicurative,
  getPolizzaById,
  getPolizzaOriginariaAumentoById
} from 'src/processes/assicurazioni/Assicurazione'
import { AssicurazioneUtils as POU } from 'src/processes/assicurazioni/AssicurazioneUtils'

export class PolizzeData extends React.Component<PolizzeDataProps, any> {

  constructor(props) {
    super(props);
    this.state = {
      polizza: props.polizza,//polizza corrente passata al componente
      polizzaInCompilazione: props.polizzaInCompilazione,//polizza corrente in compilazione passata al componente
      intestatarioPolizza: props.intestatarioPolizza,//intestatarioPolizza --> PF/STP
      comunicantePolizza: props.comunicantePolizza,//colui che invia la polizza per conto proprio (iscritto) o il responsabile dell'STP
      decodeToken: props.decodeToken, //se voglio decodificare la presenza del token o meno
      tokenJWT: TokenStore.getInstance().getTokenJWT(),//token jwt grezzo senza check
      tokenJWT_Parsed: TokenStore.getInstance().getDecodedTokenJWTWithoutCheck(),//token jwt grezzo senza check
      jwtDecode: TokenStore.getInstance().decodeToken(),//jwt decoded
      parsedToken: TokenStore.getInstance().parseToken(),//token parsato
      isTokenExpired: TokenStore.getInstance().isTokenExpired(),//verifico se il token è scaduto o meno
      datiPolizzaDb: {},//dati della polizza come recuperati sul db
      datiPolizzaOriginariaDb: {},//dati della polizza originaria dell'aumento di massimale
      campagneAssicurativeDb: [],//recupera i dati delle campagne assicurative
      elencoFasce: [], //elenco delle fasce selezionate
    };
  }

  //carico i dati necessari per la visualizzazione della polizza
  componentDidMount() {

    //let to set state when data are presents
    getPolizzaById(this.state.polizza.id).then((response: any)=> {

        this.setState({datiPolizzaDb: response })

        //se c'è un aumento di massimale recupero anche la polizza originaria
        if(response.tipoRichiesta === "A"){
            //let to set state when data are presents
            getPolizzaOriginariaAumentoById(response.idPolizzaAumMassimale).then((responsePoOriginaria: any)=> {
                ;
                if(responsePoOriginaria){
                    this.setState({datiPolizzaOriginariaDb: responsePoOriginaria })
                }
            });
        }
    });

    getCampagneAssicurative().then((campagneAssicurativeDb: any) => {
        this.setState({campagneAssicurativeDb: campagneAssicurativeDb.data })
    });

  }

  render() {
    return (
       <FormGroup>
         {   this.state.tokenJWT && this.state.decodeToken === true ?
             <>
                <div>
                        Presenza del token: <b>{this.state.tokenJWT}</b>
                </div>
                <div>
                        isTokenExpired: <b>{this.state.isTokenExpired === false ? "falso": "true"}</b>
                </div>
                <div>
                        Parsing del token: <pre>{JSON.stringify(this.state.tokenJWT_Parsed, null, 4)}</pre>
                </div>
                <div>
                        Decode del token: <pre>{JSON.stringify(this.state.jwtDecode, null, 4)}</pre>
                </div>

                <div>
                        parsedToken: <pre>{JSON.stringify(this.state.parsedToken, null, 4)}</pre>
                </div>
             </>
         : <></>
         }

         {/* TESTO LA PRESENZA DEL TOKEN */}
         {   this.state.tokenJWT ?
             <>
                 {
                     this.state.comunicantePolizza === undefined && this.state.intestatarioPolizza!==undefined &&
                     <>"POLIZZA ISCRITTO"</>
                 }
                 {
                     this.state.comunicantePolizza !== undefined &&
                         "POLIZZA STP" && this.state.intestatarioPolizza.partitaIva &&
                         "<br> COMUNICANTE POLIZZA" && this.state.comunicantePolizza.cf
                 }
             </>
         :
           <>ATTENZIONE TOKEN NON PRESENTE</>
         }
         {
             <>
                 <div>
                 <h4><b>CAMPI DEL FORM</b></h4>
                     <pre>{JSON.stringify(this.props.fields, null, 4)}</pre>
                 </div>
                 <div>
                 <h4><b>PROPRIETA'</b></h4>
                     <pre>{JSON.stringify(this.props, null, 4)}</pre>
                 </div>

                 <div>
                     <h4><b>CAMPAGNA ASSICURATIVA</b></h4>
                     <pre>{/*JSON.stringify(this.state.campagneAssicurativeDb, null, 4)*/}</pre>
                     {

                             this.state.campagneAssicurativeDb.map((campagna,id) => {
                                return(
                                 <>
                                     <div>
                                     ANNO: {campagna.annoRiferimento} <br/>
                                     DATA CHIUSURA CAMPAGNA: {campagna.dataChiusuraIscrizione} <br/>
                                     DATA FINE CAMPAGNA: {campagna.dataFineMaxCompilazioneAdesione} <br/>
                                     DATA FINE MASSIMO RINNOVO: {campagna.dataFineMaxCompilazioneRinnovo} <br/>
                                     DATA FINE VALIDITA POLIZZA: {campagna.dataFineValiditaPolizza} <br/>
                                     DATA INIZIO COMPILAZIONE ADESIONE: {campagna.dataInizioCompilazioneAdesione} <br/>
                                     DATA INIZIO COMPILAZIONE RINNOVO: {campagna.dataInizioCompilazioneRinnovo} <br/>
                                     DATA INIZIO VALIDITA POLIZZA: {campagna.dataInizioValiditaPolizza} <br/>
                                     DESCRIZIONE DELL'ANNO CAMPAGNA: {campagna.descrizioneCampagna} <br/>
                                     </div>
                                 </>
                                )
                             })
                     }
                 </div>

                 { this.state.campagneAssicurativeDb && this.state.campagneAssicurativeDb.size > 0 &&
                     <>
                         {

                             this.state.campagneAssicurativeDb.forEach((campagna) => {
                                 <>
                                     ANNO: {campagna.annoRiferimento}
                                     DATA CHIUSURA CAMPAGNA: {campagna.dataChiusuraIscrizione}
                                     DATA FINE CAMPAGNA: {campagna.dataFineMaxCompilazioneAdesione}
                                     DATA FINE MASSIMO RINNOVO: {campagna.dataFineMaxCompilazioneRinnovo}
                                     DATA FINE VALIDITA POLIZZA: {campagna.dataFineValiditaPolizza}
                                     DATA INIZIO COMPILAZIONE ADESIONE: {campagna.dataInizioCompilazioneAdesione}
                                     DATA INIZIO COMPILAZIONE RINNOVO: {campagna.dataInizioCompilazioneRinnovo}
                                     DATA INIZIO VALIDITA POLIZZA: {campagna.dataInizioValiditaPolizza}
                                     DESCRIZIONE DELL'ANNO CAMPAGNA: {campagna.descrizioneCampagna}
                                 </>
                             })
                         }
                         <b>ELENCO FASCE</b>
                         <pre>{JSON.stringify(this.state.elencoFasce, null, 4)}</pre>
                     </>
                 }
                 <br/><br/><br/>
             </>
         }

         { this.state.polizzaInCompilazione && this.state.polizzaInCompilazione.id ?
             <>
                  <div>
                     <h4><b>DATI DELLA POLIZZA IN COMPILAZIONE</b></h4>
                 </div>
                 <pre>{JSON.stringify(this.state.polizzaInCompilazione, null, 4)}</pre>
             </>
             :
             <></>
         }

         {   this.state.datiPolizzaDb && this.state.datiPolizzaDb.id ?
             <>
                 <div>
                     <h4><b>DATI DELLA POLIZZA</b></h4>
                 </div>
                 <div>
                     IDENTIFICATIVO DELLA POLIZZA: {this.state.datiPolizzaDb.id} <br/>
                     STATO DELLA POLIZZA: {this.state.datiPolizzaDb.stato} <br/>
                     DECODIFICA STATO DELLA POLIZZA: {POU.decodificaStatiPolizza(this.state.datiPolizzaDb.stato)} <br/>
                     TIPOLOGIA DI RICHIESTA: {this.state.datiPolizzaDb.tipoRichiesta} <br/>
                     DECODIFICA TIPOLOGIA DI RICHIESTA: {POU.decodificaTipoRichiesta(this.state.datiPolizzaDb.tipoRichiesta)} <br/>
                     RICHIESTA COLLEGATA: {this.state.datiPolizzaDb.idPolizzaAumMassimale}
                 </div>
                 <br/><br/><br/>
                 <div>
                     <h4><b>DATI DEL RISCHIO DELL'AUMENTO DI MASSIMALE POLIZZA</b></h4>
                 </div>
                 <div>
                     DB - VRC POLIZZA: {this.state.datiPolizzaDb.vrcConaf} <br/>
                     DB - VOP POLIZZA: {this.state.datiPolizzaDb.vopConaf} <br/>
                     DB - MASSIMALE: {this.state.datiPolizzaDb.massimaleConaf} <br/>
                     DB - VALORE RISCHIO:  {this.state.datiPolizzaDb.valoreRischioConaf} <br/>
                     TIPOLOGIA DI RICHIESTA: {this.state.datiPolizzaDb.tipoRichiesta} <br/>
                     DECODIFICA TIPOLOGIA DI RICHIESTA: {POU.decodificaTipoRichiesta(this.state.datiPolizzaDb.tipoRichiesta)} <br/>
                     RICHIESTA COLLEGATA: {this.state.datiPolizzaDb.idPolizzaAumMassimale}
                 </div>
                 <br/><br/><br/>
                 <div>
                     <h4><b>DATI DELLA POLIZZA ORIGINARIA</b></h4>
                 </div>
                 <div>
                     {
                     this.state.datiPolizzaOriginariaDb &&
                         <>
                             DB - VRC POLIZZA: {this.state.datiPolizzaOriginariaDb.vrcConaf} <br/>
                             DB - VOP POLIZZA: {this.state.datiPolizzaOriginariaDb.vopConaf} <br/>
                             DB - MASSIMALE: {this.state.datiPolizzaOriginariaDb.massimaleConaf} <br/>
                             DB - VALORE RISCHIO:  {this.state.datiPolizzaOriginariaDb.valoreRischioConaf} <br/>
                             TIPOLOGIA DI RICHIESTA: {this.state.datiPolizzaOriginariaDb.tipoRichiesta} <br/>
                             DECODIFICA TIPOLOGIA DI RICHIESTA: {POU.decodificaTipoRichiesta(this.state.datiPolizzaOriginariaDb.tipoRichiesta)} <br/>
                             RICHIESTA COLLEGATA: {this.state.datiPolizzaOriginariaDb.idPolizzaAumMassimale}
                             <br/><br/><br/>
                         </>
                     }
                 </div>
                 <br/><br/><br/>
             </>
             :
             <></>
         }
       </FormGroup>
     );
  }
}

export declare interface PolizzeDataProps {
  polizza: any,
  intestatarioPolizza: any,
  comunicantePolizza: any,
  decodeToken: boolean
  polizzaInCompilazione: any;
  fields: any;
}

export default PolizzeData;
