import styled from 'styled-components'


export const WrapCmp = styled.div`
  th {

  }

  .table-data-topbar {
    & > div {
      justify-content: space-between;
      & > div.col-5 {
        margin-left: 0px;
      }
    }
  }

  .table-data {
    position: relative;
    table {
      thead {
        tr {
          th {
            max-width:none;
            width:auto;
            button {
              div {
                display: flex;
                flex-direction: row;
              }
            }
          }
        }
      }
    }

  }

  .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  .filters-toolbar{
    padding-top: 30px;
    padding-bottom: 25px;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    
    
    .filter-item{
      min-width:150px;
      margin:10px;
    }

    .form-group{
      margin:0px;
    }
  }

  .empty-table{
    padding:40px;
    text-align:center;
    font-size:18px;
  }

  .error-notice{
    padding:40px;
  }
`
