import React, { useEffect, useState } from 'react'
import { PageSection, Title } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'

import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit'
import GenericInput from 'src/frontend/app/components/Form/Input'

import styled from 'styled-components'

import {
  PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP,
  PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP
} from 'src/app/utils/RoutesConstants'

import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { GenericSpinner } from '../../components'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { TRIENNI_OPTIONS } from './ConcessioneDeroga'
import { getSdaf } from 'src/hooks/useReqOptions'

const myPath = {
  label: 'Ripartizione manuale CFP',
  link: PATH_TO_BO_ASSEGNAZIONE_MANUALE_CFP,
}

const Wrapper = styled.div`
  width: 100%;
  table {
    tbody {
      tr > *:empty {
        min-width: 200px;
      }
      td {
        min-width: 200px;
      }
    }
    thead {
      tr {
        width: 100%;
        justify-content: space-between;
        th:first-child {
          max-width: 200px;
        }
      }
    }
  }
`

const Spacer = () => (
  <>
    <br></br>
  </>
)
const basePath = [
  { label: 'Formazione', link: '/bo/formazione' },
  { label: 'Assegnazione automatica CFP', link: PATH_TO_BO_ASSEGNAZIONE_AUTOMATICA_CFP },
]
const settoriMap = {}

const AssegnazioneManualeCFP: React.FunctionComponent = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const cf = searchParams.get('cf')
  const triennio = searchParams.get('triennio')

  const [loading, setLoading] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>(false)
  const [showModal, setShowModal] = useState<any>(false)
  const [evento, setEvento] = useState<any>({ err: null, cfp: null, id: null })
  const [assegnaCFP, setAssegnaCFP] = useState<number>(null)
  const [assignedEvents, setAssignedEvents] = useState<string[]>([])
  const [modalRecap, setModalRecap] = React.useState<any>({ open: false, message: '' })

  const getColumnValue = (column: any, row: any) => {
    if (column.field && row[column.field] !== undefined) {
      const value = row[column.field]
      if (column.options && column.options[value]) {
        return column.options[value]
      }
      return row[column.field]
    }
    return ''
  }

  const loadData = async () => {
    setLoading(true)
    const listaSDAF = await getSdaf()

    for (const SDAF of listaSDAF) {
      settoriMap[SDAF.id] = SDAF
    }
    const triennio_succ = TRIENNI_OPTIONS.find((t) => t.value === parseInt(triennio) + 1)

    try {
      const res = await getFormazioneResourceApi().msfoFormazioneRiepilogoCreditiFormativiGet(cf, getDefaultAxiosConfig())
      const _data = res.data.filter((item: any) => {
        return item.movimento
          ? item.movimento?.anno_calc_cfp <= triennio_succ.lastYear && item.movimento?.anno_calc_cfp >= triennio_succ.firstyear
          : item.attivitaFormativaExt.anno_calc_cfp <= triennio_succ.lastYear && item.attivitaFormativaExt.anno_calc_cfp >= triennio_succ.firstyear
      })
      setData(_data)
    } catch (e: any) {}
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const openModalCFP = (event, crediti, tipologia, row) => {
    setShowModal(true)
    setEvento({ id: event, cfp: crediti, tipologia: tipologia, anno_calc: row?.movimento?.anno_calc_cfp || null })
  }

  const onCloseModal = () => {
    setAssegnaCFP(null)
    setShowModal(false)
    setEvento({ err: null, cfp: null, id: null, tipologia: null })
    setModalLoading(false)
  }

  const columns: Column[] = [
    {
      label: 'Tipologie Attività',
      field: 'tipo_corso',
      isSortable: false,
      render: (row) => {
        let setstring = ''
        if (row.evento) {
          setstring = settoriMap[parseInt(row.evento.settore)] ? settoriMap[parseInt(row.evento.settore)].cod : ''
        } else if (row.attivitaFormativaExt) {
          setstring = settoriMap[parseInt(row.attivitaFormativaExt.settore)].cod
        }
        return setstring
      },
    },
    {
      label: 'Titolo Evento',
      field: 'nome_corso',
      isSortable: false,
      render: (row) => {
        return row.evento ? row?.evento?.nome_corso : row.attivitaFormativaExt?.nome_corso
      },
    },
    {
      label: 'Tipo Evento',
      field: 'cod_attivita',
      isSortable: false,
      render: (row) => {
        if (row.evento) {
          return 'A catalogo'
        } else if (row.attivitaFormativaExt) {
          return 'Individuale/Non a catalogo'
        }
        return '';
      },
    },
    {
      label: 'Tipo CFP',
      field: 'cod_attivita',
      isSortable: false,
      render: (row) => {
        return row.evento ? row?.evento?.formazAttivita?.cod : row.attivitaFormativaExt?.cod_attivita
      },
    },
    {
      label: 'CFP Riconosciuti',
      field: 'movimento.crediti',
      isSortable: false,
      render: (row) => {
        return row.movimento ? Math.round(row?.movimento?.crediti * 1000) / 1000 : Math.round(row.attivitaFormativaExt?.cfp_validi * 1000) / 1000
      },
    },
    {
      label: 'Organizzatore',
      field: 'organizzatore',
      isSortable: false,
      render: (row) => {
        return row.evento ? (row?.evento?.enteFormazione?.name ? row?.evento?.enteFormazione?.name : row.organizzatoda) : 'Non a catalogo'
      },
    },
  ]

  const handleInput = (cod, value) => {
    setAssegnaCFP(value)
  }

  const inviaCFP = async () => {
    let error = null

    if (assegnaCFP > evento.cfp) error = 'Assegna un numero di crediti minore o uguale a quelli riconosciuti'

    if (assegnaCFP <= 0) error = 'Inserire un numero valido (N > 0)'

    let messaggio = { head: 'Resoconto ripartizione manuale dei CFP: ', data: [] }

    if (!error) {
      try {
        const { data: res }: any = await getFormazioneResourceApi().msfoFormazioneRiconciliaCreditiManualmentePost(
          { cf: cf, triennio: parseInt(triennio), idEvento: evento.id, cfp: assegnaCFP, tipologia: evento.tipologia, anno_calc_cfp: evento.anno_calc } as any,
          getDefaultAxiosConfig()
        )

        res.forEach((ris) => {
          messaggio.data.push(ris.message)
        })

        setModalRecap({ open: true, message: messaggio, ok: true })

        onCloseModal()
        setAssignedEvents([...assignedEvents, evento.id])

        return
      } catch (e: any) {
        error = "Errore nell'assegnazione dei crediti - " + e.message
        setModalLoading(false)
      }
    }
    setModalLoading(false)
    setEvento({ ...evento, err: error })
  }

  const onCloseModalRecap = () => {
    setModalRecap({ open: false })
  }

  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb paths={[...basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label} - Triennio: {TRIENNI_OPTIONS.find((t) => t.value === parseInt(triennio)).label}
        </Title>

        {loading ? (
          <GenericSpinner />
        ) : (
          <>
            <Wrapper>
              <TableComposable aria-label="Sort table">
                <Thead>
                  <Tr>
                    {columns.map((column, index) => {
                      return (
                        <Th key={`head${index}`} style={column.style || {}}>
                          {column.label}
                        </Th>
                      )
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {data && (
                    <>
                      {data.map((row, index) => {
                        if (row?.movimento) {
                          if (row?.movimento?.crediti <= 0.0001) {
                            return null
                          }
                        } else if (row.attivitaFormativaExt) {
                          if (row.attivitaFormativaExt?.cfp_validi <= 0.0001) {
                            return null
                          }
                        }
                        return (
                          <Tr key={`row${index}`}>
                            {columns.map((column, index) => {
                              return (
                                <Td key={`col${index}`} style={column.style || {}}>
                                  {column.render && column.render(row)}
                                  {!column.render && getColumnValue(column, row)}
                                </Td>
                              )
                            })}
                            <Td>
                              <Button
                                color="primary"
                                disabled={assignedEvents.includes(row?.evento?.idcorso || row?.attivitaFormativaExt?.id)}
                                onClick={() =>
                                  openModalCFP(
                                    row?.evento?.idcorso ? row?.evento?.idcorso : row?.attivitaFormativaExt?.id,
                                    row?.movimento?.crediti ? row?.movimento?.crediti : row?.attivitaFormativaExt?.cfp_validi,
                                    row?.attivitaFormativaExt !== null ? 'A' : 'E',
                                    row
                                  )
                                }
                              >
                                Ripartizione
                              </Button>
                            </Td>
                          </Tr>
                        )
                      })}
                    </>
                  )}
                </Tbody>
              </TableComposable>
            </Wrapper>
            {showModal && (
              <Modal fade centered isOpen={showModal} toggle={onCloseModal}>
                <ModalHeader style={{ fontSize: '25px' }} id="assegnaCFU">
                  Sposta crediti manualmente
                </ModalHeader>
                <ModalBody>
                  {evento.err && (
                    <>
                      <Alert key={'alert-cfp'} color="danger">
                        {evento.err}
                      </Alert>
                      <Spacer />
                    </>
                  )}
                  <GenericInput
                    id={'event-' + evento.id + 'assegna'}
                    type={'number'}
                    placeholder={'Sposta crediti'}
                    label={'Crediti da trasferire*'}
                    value={assegnaCFP}
                    onChange={handleInput}
                  />

                  <GenericInput
                    id={'event-' + evento.id + 'massimo'}
                    type={'number'}
                    placeholder={'Max crediti riconosciuti'}
                    label={'Massimo crediti trasferibili'}
                    value={evento?.cfp}
                    isDisabled={true}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      setModalLoading(true)
                      inviaCFP()
                    }}
                    disabled={modalLoading}
                  >
                    Ripartizione
                  </Button>
                  <Button color="secondary" onClick={onCloseModal}>
                    Chiudi
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {modalRecap.open && (
              <Modal fade centered isOpen={modalRecap.open} toggle={onCloseModalRecap}>
                <ModalHeader style={{ fontSize: '25px' }} id="concessioneAutoCFP">
                  Ripartizione manuale CFP
                </ModalHeader>
                <ModalBody>
                  <p>{modalRecap.message.head}</p>
                  <Spacer />
                  {modalRecap.message.data.map((dato) => (
                    <div>
                      <p>{dato}</p>
                      <Spacer />
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={onCloseModalRecap}>
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </>
        )}
      </div>
    </PageSection>
  )
}

export declare interface Column {
  label: string
  width?: boolean
  style?: any
  hidden?: boolean
  isSortable?: boolean
  field: string
  render?: any
  options?: any
}
export declare interface Riga {
  settore?: string
  tipo_corso?: string
  nome_corso?: string
  cod_attivita?: string
  cfp_riconosciuti?: number
  cfp_validi?: number
  organizzatore?: string
  attestato?: any
  id_corso?: string
  evento?: any
  attivitaFormativaExt?: any
}
export declare interface Option {
  value: number
  label: string
}

export default AssegnazioneManualeCFP
