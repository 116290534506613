import React, { useCallback, useState } from 'react'
import { STATI_AGENZIE_VALUES } from 'src/formazione'
import { Label } from 'design-react-kit'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import { useHistory } from 'react-router-dom'

const FormApprovazioneFormale: React.FunctionComponent<FormApprovazioneFormaleProps> = ({ id }) => {
  const [status, setStatus] = useState<any>()
  const [alertMessage, setAlertMessage] = useState<any>()
  const history = useHistory()

  const submit = useCallback(
    async (e) => {
      try {
        setStatus(STATUS.LOADING)
        await getFormazioneResourceApi().msfoFormazioneAccAgenziaFormativaPatch(
          {
            id,
            stato: STATI_AGENZIE_VALUES.FORMALE,
            dataAccettazione: new Date().toISOString(),
          },
          getDefaultAxiosConfig()
        )
        setStatus(STATUS.SUCCESS)
        setAlertMessage('Dati salvati con successo')
        setTimeout(() => {
          history.goBack()
        }, 500)
      } catch (e) {
        setStatus(STATUS.FAILURE)
        setAlertMessage('Impossibile salvare i dati')
      }
    },
    [id]
  )
  return (
    <FormSubmit title="Approvazione formale" buttonLabel="CONFERMA" alertMessage={alertMessage} status={status} onSubmit={submit}>
      <Label>Per approvare formalmente l'agenzia cliccare "Conferma".</Label>
    </FormSubmit>
  )
}

interface FormApprovazioneFormaleProps {
  id: any
}

export default FormApprovazioneFormale
