import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Form, Label } from 'design-react-kit'
import { useSdaf, useTipologieAttivita } from 'src/hooks/useReqOptions'
import createWrpInput from 'src/frontend/app/components/Form/WrpInput'
import GenericCard from 'src/frontend/app/components/GenericCard'
import GenericInput from 'src/frontend/app/components/Form/Input'
import GenericSelect from 'src/frontend/app/components/Form/Select'
import TextArea from 'src/frontend/app/components/Form/TextArea'
import GenericUpload from 'src/frontend/app/components/Form/Upload'

import { MODALITA_DISPONIBILI_OPTIONS, TIPOLOGIA_EVENTO_OPTIONS } from 'src/formazione'
import { getValueAtPath } from 'src/utilities/utility'

const FormDatiEvento: React.FunctionComponent<FormDatiEventoProps> = ({ title, data, handleInput, onInitInput, errors, viewOnly }) => {
  const objKey = ''
  const WrpInput = useMemo(() => createWrpInput({ objKey, onInit: onInitInput, onChange: handleInput, viewOnly }), [])
  const tipologiaEvento = getValueAtPath(data, 'tipologiaEvento')

  const tipologieAttivita = useTipologieAttivita()
  const listaSDAF = useSdaf()
  const [listaTipologieAttivita, setListaTipologieAttivita] = useState<any>([])
  const [listaSelezionata, setListaSelezionata] = useState<any>(null)

  const tipologiaValue = tipologiaEvento ? tipologiaEvento.value : null

  useEffect(() => {
    if (tipologiaValue !== null && tipologiaValue !== undefined) {
      switch (tipologiaValue) {
        case 0: // Non a catalogo
          if (listaSelezionata !== 0) {
            const nuovalista = []
            for (const tipologia of tipologieAttivita) {
              if (tipologia.cod !== 'AO' && tipologia.cod !== 'AF' && tipologia.cod !== 'CU' && tipologia.ta_1 === 1) {
                nuovalista.push(tipologia)
              }
            }
            setListaTipologieAttivita(nuovalista)
            if (listaSelezionata && !viewOnly) {
              handleInput('tipologiaAttivita', null)
            }
          }
          break
        case 1: // Individuale
          if (listaSelezionata !== 1) {
            const nuovalista = []
            for (const tipologia of tipologieAttivita) {
              if (
                tipologia.cod === 'CU' ||
                tipologia.cod === 'CS' ||
                tipologia.cod === 'MU' ||
                tipologia.cod === 'DR' ||
                tipologia.cod === 'CN' ||
                tipologia.cod === 'CSGL' ||
                tipologia.cod === 'COT' ||
                tipologia.cod === 'CON' ||
                tipologia.cod === 'COI' ||
                tipologia.cod === 'CES' ||
                tipologia.cod === 'CEV' ||
                tipologia.cod === 'RELA' ||
                tipologia.cod === 'RELF' ||
                tipologia.cod === 'RELS' ||
                tipologia.cod === 'RELP' ||
                tipologia.cod === 'RELU' ||
                tipologia.cod === 'RELR' ||
                tipologia.cod === 'RELL' ||
                tipologia.cod === 'RELM' ||
                tipologia.cod === 'RELSE' ||
                tipologia.cod === 'RELC' ||
                tipologia.cod === 'RELVS' ||
                tipologia.cod === 'RELVT' ||
                tipologia.cod === 'RELGS' ||
                tipologia.cod === 'RELCO' ||
                tipologia.cod === 'AS' ||
                tipologia.cod === 'AP' ||
                tipologia.cod === 'LPR' ||
                tipologia.cod === 'MS' ||
                tipologia.cod === 'MP' ||
                tipologia.cod === 'D'
              ) {
                nuovalista.push(tipologia)
              }
            }
            setListaTipologieAttivita(nuovalista)
            if (listaSelezionata && !viewOnly) {
              handleInput('tipologiaAttivita', null)
            }
          }
          break
      }
    }
    setListaSelezionata(tipologiaValue)
  }, [tipologiaValue])

  useEffect(() => {
    if (data?.tipologiaAttivita?.um === 'E') {
      handleInput('crediti', data?.tipologiaAttivita?.max_cfp_evt.toString())
    }
  }, [data?.tipologiaAttivita])

  return (
    <GenericCard cardHeader={title}>
      <Form>
        <div className="row">
          <div className="col-6">
            <WrpInput as={GenericInput} id="nomeCorso" type="text" label="Nome del corso" suffix="**" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'modalita'}
              placeholder="Selezione Modalità"
              label="Modalità"
              suffix="**"
              defaultOptions={MODALITA_DISPONIBILI_OPTIONS}
              data={data}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'tipologiaEvento'}
              placeholder="Selezione Tipologia Evento"
              label="Tipologia Evento"
              suffix="**"
              defaultOptions={TIPOLOGIA_EVENTO_OPTIONS}
              data={data}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'tipologiaAttivita'}
              placeholder="Selezione Tipologia Attività"
              label="Tipologia Attività"
              suffix="**"
              defaultOptions={listaTipologieAttivita}
              data={data}
              errors={errors}
            />
          </div>

          <div className="col-6">
            <WrpInput as={GenericInput} id="soggettoErogante" type="text" label="Soggetto Erogante" suffix="**" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={TextArea} labelBottom id="descrizione" type="text" label="Descrizione Evento" suffix="**" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <WrpInput
              as={GenericSelect}
              id={'settore'}
              placeholder="Selezione SDAF"
              label="SDAF"
              suffix="**"
              defaultOptions={listaSDAF}
              data={data}
              errors={errors}
            />
          </div>

          <div className="col-6">
            <WrpInput as={GenericInput} id="regione" type="text" label="Regione di svolgimento" suffix="**" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="luogo" type="text" label="Località di svolgimento" suffix="**" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="sede" type="text" label="Sede di svolgimento" suffix="**" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="dataInizio" type="date" label="Data Inizio" suffix="**" data={data} errors={errors} />
          </div>
          <div className="col-6">
            <WrpInput as={GenericInput} id="dataFine" type="date" label="Data Fine" suffix="**" data={data} errors={errors} />
          </div>
          {/* solo per le tipologie misurate in ore */}
          {data?.tipologiaAttivita?.um === 'H' && (
            <div className="col-6">
              <WrpInput
                as={GenericInput}
                id="totOre"
                type="text"
                label="Durata evento"
                suffix="** ( es. 43:53 )"
                data={data}
                errors={errors}
                placeholder="Ore : Minuti"
              />
            </div>
          )}
          {/* solo per le tipologie misurate in CFU */}
          {data?.tipologiaAttivita?.um !== 'H' && data?.tipologiaAttivita?.um !== 'E' && (
            <div className="col-6">
              <WrpInput as={GenericInput} id="crediti" type="number" label="CFU" suffix="**" data={data} errors={errors} />
            </div>
          )}
          {/* solo per le commissioni che devono avere come limite massimo 0.25 CFU */}
          {data?.tipologiaAttivita?.um === 'E' && (
            <div className="col-6" hidden>
              <WrpInput as={GenericInput} id="crediti" type="number" label="CFU" suffix="**" data={data} errors={errors} />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <WrpInput as={TextArea} labelBottom id="note" type="text" label="Note" suffix="**" data={data} errors={errors} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Label for="locandina">
              <strong>Locandina</strong>
            </Label>
          </div>
          <div className="col-6">
            <GenericUpload
              isDisabled={viewOnly}
              isMulti={false}
              id={'locandina'}
              tipologiaAllegato={'corsi_ext_locandina'}
              extensions={['pdf']}
              accept={['application/pdf']}
              data={data}
              onChange={handleInput}
            />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-6">
            <Label for="attestato">
              <strong>Attestato</strong>
            </Label>
          </div>
          <div className="col-6">
            <GenericUpload
              isDisabled={viewOnly}
              isMulti={false}
              id={'attestato'}
              tipologiaAllegato={'corsi_attestato'}
              extensions={['pdf']}
              accept={['application/pdf']}
              data={data}
              onChange={handleInput}
            />
          </div>
        </div>
        {!data?.attestato && (
          <div className="pt-4">
            <Alert color="info">Non hai ancora inserito l'attestato</Alert>
          </div>
        )}
      </Form>
      <GenericInput type={'hidden'} id={'check_attestato'} value={data.check_attestato} onInit={onInitInput} />
    </GenericCard>
  )
}

interface FormDatiEventoProps {
  data: any
  errors: any
  title: string
  handleInput: any
  onInitInput?: any
  viewOnly?: boolean
}

export default FormDatiEvento
