/* eslint-disable array-callback-return */
import { AvatarContainer, AvatarIcon, Callout, CalloutText, CalloutTitle, Label } from 'design-react-kit'
import GenericCard from '../../../components/GenericCard'
import GenericSelect from '../../../components/GenericSelect'
import { Title } from '@patternfly/react-core'
import GenericEditableTable from '../../../components/GenericEditableTable'
import GenericSpinner from 'src/backoffice/app/components/GenericSpinner'
import close from '../../../images/x.png'
import add from '../../../images/add.png'
import { getVociPrestazioni } from 'src/processes/assicurazioni/VociPrestazioni'
import { Prestazione } from 'src/model/Prestazione'
import { VocePrestazione } from 'src/model/VocePrestazione'
import {
  aggiornaVociTabellaUno,
  aggiungiVoceTabellaUno,
  cancellaVoceTabellaUno,
  creaTabellaUno,
  getElencoTabelleUnoByCodiceFiscale,
  getTabellaUnoById,
  modificaVoceTabellaUno
} from 'src/processes/assicurazioni/TabellaUno'
import {
  CancellaSingolaVoceDettaglioDTO,
  RicercaElencoTabellaUnoDTO,
  RicercaTabellaUnoByIdDTO,
  TabellaUnoCreateDTO
} from '@parsec/mspo'
import GenericInput from 'src/frontend/app/components/GenericInput'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { AppUtils } from 'src/app/utils/FormUtils'
import { conversioneSiNoToInt, isEmptyOrZero, isOnlyNumbers } from 'src/utilities/utility'
import { getSoggettoByIdIscrizioneFO } from 'src/processes/Soggetto'
import GenericModal from 'src/backoffice/app/components/GenericModal'
import {
  columnsTabellaUno,
  listaAnniRiferimentoTabellaUno,
  listaBaseCalcoloTabellaUno
} from 'src/processes/assicurazioni/CostantiPolizze'
import GenericAccordionCard from 'src/frontend/app/components/GenericAccordionCard'
import { getIscrizioneByIdFO } from 'src/processes/ProcessoIscrizione'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'
import React from 'react'

export default class TabellaUno extends React.Component<TabellaUnoProps, any>{
  constructor(props) {
    super(props);
    this.state = {
        activeTab: 1,
        tabClick: props.tabClick,
        polizza: props.polizza,
        visualizzaFlag : props.visualizzaFlag,
        modificaDatiFlag:props.modificaDatiFlag,
        getSelectValue:props.getSelectValue,
        fields: {},
        errors: {},
        isLoaded : false,
        checkEmpty: false,
        selectionLength:0,
        total:{},
        message: "",
        mySelection: [],
        totalCompenso:[],
        totalPrestazioni:[],
        totalValoreRischio:[],
        totalValoreOpera:[],
        vrcConaf: 0,
        vopConaf: 0,
        sbloccaTabella: 1,
        utenteSTP: false,
        aumentoMassimaleFlag: false,
        editingFlag: false,
        validated: true,
    };
  }



  async componentDidMount(): Promise<void> {

    this.state.fields.iniziali = '';
    if(this.props.polizza?.tipoRichiesta === "A") {
      this.setState({aumentoMassimaleFlag: true})
    }
    //Check utente STP
    if(TokenStore.getInstance().isCurrentAccountSTP() === true) {

        //chiamata al servizio 'getSoggettoByIdIscrizione' che restituisce i dati del soggetto a partire dall'idIscrizione
        await getSoggettoByIdIscrizioneFO(this.props.data.idIscrizione).then((response: any) => {
                this.state.fields.nome = response.data.ragioneSociale
                this.state.fields.cognome ="";
                this.state.fields.codiceFiscale = response.data.partitaIva;
                this.state.fields.iniziali = response.data.ragioneSociale.charAt(0) + response.data.ragioneSociale.charAt(1);
        })
        this.setState({utenteSTP: true})
    }
    else {
      const keyData = TokenStore.getInstance().getDecodedTokenJWT();
      this.state.fields.nome = keyData.given_name;
      this.state.fields.cognome = keyData.family_name;
      this.state.fields.codiceFiscale = keyData.fiscalNumber;
      this.state.fields.iniziali = keyData.given_name.charAt(0)+keyData.family_name.charAt(0);
      this.setState({utenteSTP: false})
    }


    //fix
    this.setState({vrcConaf: this.props.data.vrcConaf, vopConaf: this.props.data.vopConaf});

    //Settaggio tabella vuota
    if(!this.props.visualizzaFlag) this.props.checkEmpty(false);



    if(this.props.storicoFlag && ( this.props.tabellaUno === "" || this.props.tabellaUno === null || this.props.tabellaUno === undefined || (this.props.tabellaUno && this.props.tabellaUno.id === null))) {
        this.setState({tabellaUnoNotPresentFlag: true})

        this.setState({vrcConaf: this.props.data.vrcConaf, vopConaf: this.props.data.vopConaf, fields: this.props.data})
    } else {
        if(this.props.tabellaUno) {
          this.setState({tabellaUnoNotPresentFlag: false})
          let fields = this.state.fields;

          //fields["codiceFiscale"] = TokenStore.getInstance().getDecodedTokenJWT().fiscalNumber;
          if(this.props.tabellaUno.idRichiestaCollegata) fields["idPolizza"] = this.props.tabellaUno.idRichiestaCollegata;
          if(this.props.tabellaUno.idPolizza) fields["idPolizza"] = this.props.tabellaUno.idPolizza;

          if((!this.props.visualizzaFlag && !this.props.modificaDatiFlag)) {
            let idTab;
            if(this.props.tabellaUno.id) idTab =this.props.tabellaUno.id
            if(this.props.tabellaUno.idTabellaUno) idTab =this.props.tabellaUno.idTabellaUno
            //Recupero la tabella in compilazione
            this.recuperaTabellaPreviousStep(idTab)
            fields["annoRiferimento"] = this.props.tabellaUno.annoRiferimento;
            fields["tipoCompilazione"] = this.props.tabellaUno.tipoCompilazione;
            fields["baseCalcolo"] = this.props.tabellaUno.baseCalcolo;
            this.setState({fields});

          } else {

            if(typeof(this.props.tabellaUno.annoRiferimento) !== 'object') fields["annoRiferimento"] = {label: this.props.tabellaUno.annoRiferimento, value: this.props.tabellaUno.annoRiferimento} ;
            else fields["annoRiferimento"] = this.props.tabellaUno.annoRiferimento;
            if(typeof(this.props.tabellaUno.tipoCompilazione) !== 'object') fields["tipoCompilazione"] = {label: this.props.tabellaUno.tipoCompilazione, value: this.props.tabellaUno.tipoCompilazione} ;
            else fields["tipoCompilazione"] = this.props.tabellaUno.tipoCompilazione;
            // fields["tipoCompilazione"] = this.props.tabellaUno.tipoCompilazione;
            fields["idPolizza"] = this.props.tabellaUno.idRichiestaCollegata;

            //Conversione base calcolo - Viene passato un numero: 0->anno precedente, 1->anno corrente, 2->anno successivo
            let stringaBaseCalcolo = "In base all'attività dell'ultimo esercizio " + (new Date().getFullYear() - 1);

            if(typeof(this.props.tabellaUno.baseCalcolo) !== 'object') {
              if(this.props.tabellaUno.baseCalcolo === 1) stringaBaseCalcolo = "In base alla previsione dell'attività dell'anno in corso "+ new Date().getFullYear();
              if(this.props.tabellaUno.baseCalcolo === 2) stringaBaseCalcolo = "In base alla previsione dell'anno successivo " + (new Date().getFullYear() + 1);
              fields["baseCalcolo"] = {label: stringaBaseCalcolo, value: this.props.tabellaUno.baseCalcolo};
            }
            else fields["baseCalcolo"] = this.props.tabellaUno.baseCalcolo;


            this.recuperaTabellaPreviousStep(this.props.tabellaUno.id)

          }
          this.setState({fields});

        }
        else {
          let fields = this.state.fields;
          fields["idPolizza"] = this.props.data.id;
        }
        let fields = this.state.fields;
        fields["id"] = fields["idPolizza"];
        if(this.props.data.idIscrizione) {

          await getIscrizioneByIdFO(this.props.data.idIscrizione, null).then((response: any) => {
            if(response.data.iotDTO.numeroIscrizioneOrdine) {
              this.setState({numeroIscrizione: response.data.iotDTO.numeroIscrizioneOrdine})
            }
          })
        }
        this.setState({fields})

    }

    await this.setState({listaAnniRiferimento: listaAnniRiferimentoTabellaUno})

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});


      let aree: any[] = [];
      await getVociPrestazioni().then(async (response)=> {

              /** Estrarre solo le aree e poi creare un oggetto gerarchico per le prestazioni di ciascuna area */
              await response.map(async (item, index)=> {
                let area: Prestazione = {
                  id: parseInt(item.codicePrestazione.toString().split(".")[0]),
                  title: item.area,
                  isExpanded: true,
                  data: [
                    {
                      "id": "codicePrestazione",
                      "value": parseInt(item.codicePrestazione.toString().split(".")[0]),
                      "expandedValue": parseInt(item.codicePrestazione.toString().split(".")[0]),
                      "type": "label",
                      "disabled": true,
                      "width": 1
                    },
                    {
                      "id": "areaPrestazione",
                      "value": item.area,
                      "expandedValue": item.area,
                      "type": "label",
                      "disabled": true,
                      "width": 100
                    },
                    {
                      "id": "codiceParametri",
                      "value": "",
                      "expandedValue": "Cod. Parametri",
                      "type": "label",
                      "disabled": true,
                      "width": 30
                    },
                    {
                      "id": "codiceCompetenza",
                      "value": "",
                      "expandedValue": "Cod. Competenza",
                      "type": "label",
                      "disabled": true,
                      "width": 30
                    },
                    {
                      "id": "attivitaProf",
                      "label": "Attività di formatore",
                      "value": "",
                      "expandedValue": "Attività di formatore",
                      "type": "label",
                      "disabled": true,
                      "width": 30
                    },
                    {
                      "id": "compenso",
                      "label": "Compenso",
                      "value": "",
                      "expandedValue": "Compenso",
                      "type": "input",
                      "disabled": true,
                      "width": 30,
                      "valid": true
                    },
                    {
                      "id": "nPrestazioni",
                      "label": "N. Prestazioni",
                      "value": "",
                      "expandedValue": "N. Prestazioni",
                      "type": "input-integer",
                      "disabled": true,
                      "width": 30,
                      "valid": true
                    },
                    {
                      "id": "classeRischio",
                      "value": "",
                      "expandedValue": "Classe rischio",
                      "type": "input",
                      "disabled": true,
                      "width": 30
                    },
                    {
                      "id": "valoreRischio",
                      "value": "",
                      "expandedValue": "Valore rischio",
                      "type": "input",
                      "disabled": true,
                      "width": 30
                    },
                    {
                      "id": "valoreOpera",
                      "label": "Valore Opera Prog.",
                      "value": "",
                      "expandedValue": "Valore opera prog.",
                      "type": "input",
                      "disabled": true,
                      "width": 30,
                      "valid": true
                    },
                    {
                      "id": "valoreOperaTotale",
                      "value": "",
                      "expandedValue": "Valore Opera Totale",
                      "type": "input",
                      "disabled": true,
                      "width": 30
                    }
                  ],
                  children: []
                }
                await aree.push(area)
              })

              await aree.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0);
              const tmp = new Map()
              /**  Delete duplicates*/
              aree = await aree.reduce((acc, e) => {
                if(tmp.has(e.title)) {
                  if (!tmp.get(e.title).includes(e.jobid)) {
                    acc.push(e)
                    tmp.set(e.title, [...tmp.get(e.title), e.title])
                  }
                } else {
                  acc.push(e)
                  tmp.set(e.title, [e.jobid])
                }
                return acc
              }, [])

              await aree.map(async (area, index)=> {
                    let childItems: any = [];
                    await response.map(async (responseItem, index)=> {
                          if(responseItem.area === area.title) {
                            let child: VocePrestazione = {
                              id: responseItem.idVocePrestazione,
                              ordinamento: responseItem.ordinamento,
                              data: [
                                {
                                  "id": "codicePrestazione",
                                  "value": responseItem.codicePrestazione,
                                  "type": "label",
                                  "disabled": true,
                                  "width": 1
                                },
                                {
                                  "id": "areaPrestazione",
                                  "value": responseItem.prestazione,
                                  "type": "label",
                                  "disabled": true,
                                  "width": 100
                                },
                                {
                                  "id": "codiceParametri",
                                  "value": responseItem.codiceParametri,
                                  "type": "label",
                                  "disabled": true,
                                  "width": 30
                                },
                                {
                                  "id": "codiceCompetenza",
                                  "value": responseItem.codiceCompetenza,
                                  "type": "label",
                                  "disabled": true,
                                  "width": 30
                                },
                                {
                                  "id": "attivitaProf",
                                  "label": "Attività di formatore",
                                  "value": false,
                                  "type": "checkbox",
                                  "disabled": false,
                                  "width": 30
                                },
                                {
                                  "id": "compenso",
                                  "label": "Compenso",
                                  "value": 0,
                                  "type": "input",
                                  "disabled": false,
                                  "width": 30,
                                  "valid": true,
                                },
                                {
                                  "id": "nPrestazioni",
                                  "label": "N. Prestazioni",
                                  "value": 0,
                                  "type": "input-integer",
                                  "disabled": false,
                                  "width": 30,
                                  "valid": true,
                                },
                                {
                                  "id": "classeRischio",
                                  "value": responseItem.classeRischio,
                                  "type": "label-it-number",
                                  "disabled": true,
                                  "width": 30
                                },
                                {
                                  "id": "valoreRischio",
                                  "value": 0,
                                  "type": "label-it-number",
                                  "disabled": true,
                                  "width": 30
                                },
                                {
                                  "id": "valoreOpera",
                                  "label": "Valore Opera Prog.",
                                  "value": 0,
                                  "type": "input",
                                  "disabled": true,
                                  "width": 80,
                                  "valid": true,
                                },
                                {
                                  "id": "valoreOperaTotale",
                                  "value": 0,
                                  "type": "label-currency",
                                  "disabled": true,
                                  "width": 80
                                }
                              ],
                            }
                            await childItems.push(child)
                          }
                    })
                    await childItems.sort((p1, p2) => (p1.ordinamento > p2.ordinamento) ? 1 : (p1.ordinamento < p2.ordinamento) ? -1 : 0);


                    area.children = childItems;

              })
              await this.setState({idAssicurazione: this.props.data.id, rowsNames: aree})
              let dtoElencoTabelleUno: RicercaElencoTabellaUnoDTO = {
                codiceFiscale: this.state.fields.codiceFiscale,
                idAssicurazione: this.state.idAssicurazione
              }
              await getElencoTabelleUnoByCodiceFiscale(dtoElencoTabelleUno).then(async (response: any)=> {


                await this.state.listaAnniRiferimento.splice(0,this.state.listaAnniRiferimento.length);
                await this.state.listaAnniRiferimento.push({ label: "Nuova compilazione", value: "nuovaCompilazione"})


                await response.map((item)=>{
                  this.state.listaAnniRiferimento.push({label: "Anno " + item.annoRiferimento + " (" + item.dataCompilazione.split("T").join(" ").split(".")[0] + ")", value: item.id})
                })
              }).catch((err) => {
                console.error("Si è verificato un errore durante il recupero delle tabelle uno.",  err)
                this.showModal(true, "Errore.",err.message/*"Si è verificato un errore durante l'invio. Riprova in un secondo momento."*/);
              })
      })
      await this.setState({isLoaded:true})

  }

  recuperaTabellaPreviousStep = async (tabellaId) => {

    let aree: any[] = [];

    /** Richiamare servizio per recuperare una tabella uno by ID*/
    let tabellaUnoByID: RicercaTabellaUnoByIdDTO = {
      idCompilazioneTabellaRischio: tabellaId
    }

    getTabellaUnoById(tabellaUnoByID)
        .then(async (response: any)=> {

            this.state.mySelection.splice(0,this.state.mySelection.length);
            this.setVopVrc(response)
            await this.setState({isLoaded: false})
            await this.setState({tabellaUnoSelezionata: response, sbloccaTabella: response.sbloccaTabella})

            let fields = this.state.fields;
            fields['idTabellaUno'] = tabellaId;

            await this.setState({ fields });
            this.props.getSelectedValue(this.state.fields, "tabellaUno")

            await response.elencoVociPrestazione.map(async (item, index)=> {
                    //Creazione area delle prestazioni
                    let area: Prestazione = {
                      id: parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                      title: item.vocePrestazione.area,
                      isExpanded: true,
                      data: [
                        {
                          "id": "codicePrestazione",
                          "value": parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                          "expandedValue": parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                          "type": "label",
                          "disabled": true,
                          "width": 1
                        },
                        {
                          "id": "areaPrestazione",
                          "value": item.vocePrestazione.area,
                          "expandedValue": item.vocePrestazione.area,
                          "type": "label",
                          "disabled": true,
                          "width": 100
                        },
                        {
                          "id": "codiceParametri",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "codiceCompetenza",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "attivitaProf",
                          "label": "Attività di formatore",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "compenso",
                          "label": "Compenso",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "nPrestazioni",
                          "label": "N. Prestazioni",
                          "value": " ",
                          "type": "input-integer",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "classeRischio",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreRischio",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreOpera",
                          "label": "Valore Opera Prog.",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreOperaTotale",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        }
                      ],
                      children: []
                    }
                    await aree.push(area)
                  })
                  await aree.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0);
                  const tmp = new Map()
                  aree = await aree.reduce((acc, e) => {
                    if(tmp.has(e.id)) {
                      if (!tmp.get(e.id).includes(e.jobid)) {
                        acc.push(e)
                        tmp.set(e.id, [...tmp.get(e.id), e.jobid])
                      }
                    } else {
                      acc.push(e)
                      tmp.set(e.id, [e.jobid])
                    }
                    return acc
                  }, [])

                  aree.map(async (area, index)=> {

                        let childItems: any = [];
                        await response.elencoVociPrestazione.map(async (vocePrestazioneItem, index)=> {
                              if(vocePrestazioneItem.vocePrestazione.area === area.title) {
                                let child: VocePrestazione = {
                                  id: vocePrestazioneItem.idCompilazioneTabellaRischioDettaglio,
                                  ordinamento: vocePrestazioneItem.vocePrestazione.ordinamento,
                                  data: [
                                    {
                                      "id": "codicePrestazione",
                                      "value": vocePrestazioneItem.vocePrestazione.codicePrestazione,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 1
                                    },
                                    {
                                      "id": "areaPrestazione",
                                      "value": vocePrestazioneItem.vocePrestazione.prestazione,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 100
                                    },
                                    {
                                      "id": "codiceParametri",
                                      "value": vocePrestazioneItem.vocePrestazione.codiceParametri,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "codiceCompetenza",
                                      "value": vocePrestazioneItem.vocePrestazione.codiceCompetenza,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "attivitaProf",
                                      "label": "Attività di formatore",
                                      "value": vocePrestazioneItem.attivitaFormativa === 1 ? true : false,
                                      "type": "checkbox",
                                      "disabled": false,
                                      "width": 30
                                    },
                                    {
                                      "id": "compenso",
                                      "label": "Compenso",
                                      "value": (vocePrestazioneItem.compensoPrestazione).toFixed(2),
                                      "type": "label-currency",
                                      "disabled": true,
                                      "width": 30,
                                      "valid": true,
                                    },
                                    {
                                      "id": "nPrestazioni",
                                      "label": "N. Prestazioni",
                                      "value": vocePrestazioneItem.numeroPrestazioni,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30,
                                      "valid": true,
                                    },
                                    {
                                      "id": "classeRischio",
                                      "value": (vocePrestazioneItem.vocePrestazione.classeRischio).toFixed(2),
                                      "type": "label-it-number",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "valoreRischio",
                                      "value": (vocePrestazioneItem.numeroPrestazioni * vocePrestazioneItem.compensoPrestazione * vocePrestazioneItem.vocePrestazione.classeRischio).toFixed(2),
                                      "type": "label-it-number",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "valoreOpera",
                                      "label": "Valore Opera Prog.",
                                      "value": (vocePrestazioneItem.valoreOpera).toFixed(2),
                                      "type": "label-currency",
                                      "disabled": true,
                                      "width": 80,
                                      "valid": true,
                                    },
                                    {
                                      "id": "valoreOperaTotale",
                                      "value": (vocePrestazioneItem.valoreOpera*vocePrestazioneItem.numeroPrestazioni).toFixed(2),
                                      "type": "label-currency",
                                      "disabled": true,
                                      "width": 80
                                    }
                                  ],
                                }
                                await childItems.push(child)
                              }
                        })

                        area.children = childItems;

                        childItems = await childItems.reduce((acc, e) => {
                          if(tmp.has(e.title)) {
                            if (!tmp.get(e.title).includes(e.jobid)) {
                              acc.push(e)
                              tmp.set(e.title, [...tmp.get(e.title), e.title])
                            }
                          } else {
                            acc.push(e)
                            tmp.set(e.title, [e.jobid])
                          }
                          return acc
                        }, [])


                        await this.setVociDaTabella(area);
                        if(childItems.length > 0 && !this.props.visualizzaFlag)
                          this.props.checkEmpty(true)
                  });

                  this.reRender()
                  await this.setState({isLoaded: true})

        }).catch((err) => {
              setTimeout(() => {
                      let errorObject = {
                        message: err.message,
                        typeMessage: "danger"
                      }
                      this.props.getSelectedValue("message", errorObject)
                      this.setState({ message: err.message, typeMessage: 'danger' })
              }, 3000);
        })


  }

  setAnnoRiferimento = async (item) => {

    this.setState({isLoaded: false})
    let tabellaUnoCreate: TabellaUnoCreateDTO  = {
      codiceFiscale: this.state.fields.codiceFiscale,
      idAssicurazione: this.state.idAssicurazione,
      annoRiferimento: new Date().getFullYear(),
      baseCalcolo: this.state.fields['baseCalcolo'].value,
      tipoCompilazione: item.value === 'nuovaCompilazione' ? "Nuova compilazione" : this.state.fields['annoRiferimento'].label

    }
    if(item.value === 'nuovaCompilazione') {
      // tabellaUnoCreate.tipoCompilazione = "Nuova compilazione"
      /** Richiamare servizio per la creazione di una nuova tabella uno */
      this.props.checkEmpty(false);
      creaTabellaUno(tabellaUnoCreate)
      .then(async (response: any)=> {
          await this.setState({isLoaded: false})
          await this.setState({tabellaUnoSelezionata: response})
          let fields = this.state.fields;
          fields['idTabellaUno'] = response.id;
          fields['idPolizza'] = this.state.idAssicurazione;
          await this.setState({ fields });
          this.props.getSelectedValue(this.state.fields, "tabellaUno")

          this.state.mySelection.splice(0,this.state.mySelection.length)

          await this.setVopVrc(response)
          await this.setState({isLoaded: true})

      }).catch((err) => {
          setTimeout(() => {
                  let errorObject = {
                    message: err.message,
                    typeMessage: "danger"
                  }
                  this.props.getSelectedValue("message", errorObject)
                  this.setState({ message: err.message, typeMessage: 'danger' })
          }, 3000);
          //console.error(err)
      })
    }
    else{
      /** Richiamare servizio per recuperare una tabella uno by ID*/
      let tabellaUnoByID: RicercaTabellaUnoByIdDTO = {
        idCompilazioneTabellaRischio: item.value
      }
      let aree: any[] = [];

      getTabellaUnoById(tabellaUnoByID)
        .then(async (response: any)=> {

            this.state.mySelection.splice(0,this.state.mySelection.length);

            this.setVopVrc(response)
            await creaTabellaUno(tabellaUnoCreate)
            .then(async (responseCreaTabella: any)=> {
                await this.setState({isLoaded: false})
                await this.setState({tabellaUnoSelezionata: responseCreaTabella})

                let fields = this.state.fields;

                fields['idTabellaUno'] = responseCreaTabella.id;
                fields['idIscrizione'] = this.props.data.idIscrizione;
                fields['idPolizza'] = this.state.idAssicurazione;
                this.props.getSelectedValue(fields, 'tabellaUno')
                responseCreaTabella.elencoVociPrestazione = response.elencoVociPrestazione; //passo le voci della tabella comparativa alla nuova clonata
                if(!this.props.visualizzaFlag) {
                    if( responseCreaTabella.elencoVociPrestazione.length > 0) this.props.checkEmpty(true)
                    else  this.props.checkEmpty(false)
                }
                //Richiamare servizio per aggiornare le voci
                let aggiornaVociDTO = {
                  "idTabella": responseCreaTabella.id,
                  "listaVoci": response.elencoVociPrestazione
                }
                await aggiornaVociTabellaUno(aggiornaVociDTO).then(async (responseAggiorna: any) => {
                  await this.setState({ fields });
                  this.props.getSelectedValue(this.state.fields, "tabellaUno")

                  await responseAggiorna.elencoVociPrestazione.map(async (item, index)=> {
                    //Creazione area delle prestazioni
                    let area: Prestazione = {
                      id: parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                      title: item.vocePrestazione.area,
                      isExpanded: true,
                      data: [
                        {
                          "id": "codicePrestazione",
                          "value": parseInt(item.vocePrestazione.codicePrestazione.toString().split(".")[0]),
                          "type": "label",
                          "disabled": true,
                          "width": 1
                        },
                        {
                          "id": "areaPrestazione",
                          "value": item.vocePrestazione.area,
                          "type": "label",
                          "disabled": true,
                          "width": 100
                        },
                        {
                          "id": "codiceParametri",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "codiceCompetenza",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "attivitaProf",
                          "label": "Attività di formatore",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "compenso",
                          "label": "Compenso",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "nPrestazioni",
                          "label": "N. Prestazioni",
                          "value": " ",
                          "type": "input-integer",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "classeRischio",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreRischio",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreOpera",
                          "label": "Valore Opera Prog.",
                          "value": " ",
                          "type": "label",
                          "disabled": true,
                          "width": 30
                        },
                        {
                          "id": "valoreOperaTotale",
                          "value": " ",
                          "type": "input",
                          "disabled": true,
                          "width": 30
                        }
                      ],
                      children: []
                    }
                    await aree.push(area)
                  })
                  await aree.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0);
                  const tmp = new Map()
                  aree = await aree.reduce((acc, e) => {
                    if(tmp.has(e.id)) {
                      if (!tmp.get(e.id).includes(e.jobid)) {
                        acc.push(e)
                        tmp.set(e.id, [...tmp.get(e.id), e.jobid])
                      }
                    } else {
                      acc.push(e)
                      tmp.set(e.id, [e.jobid])
                    }
                    return acc
                  }, [])

                  aree.map(async (area, index)=> {

                        let childItems: any = [];
                        await responseAggiorna.elencoVociPrestazione.map(async (vocePrestazioneItem, index)=> {

                              if(vocePrestazioneItem.vocePrestazione.area === area.title) {
                                let child: VocePrestazione = {
                                  id: vocePrestazioneItem.idCompilazioneTabellaRischioDettaglio,
                                  ordinamento: vocePrestazioneItem.vocePrestazione.ordinamento,
                                  data: [
                                    {
                                      "id": "codicePrestazione",
                                      "value": vocePrestazioneItem.vocePrestazione.codicePrestazione,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 1
                                    },
                                    {
                                      "id": "areaPrestazione",
                                      "value": vocePrestazioneItem.vocePrestazione.prestazione,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 100
                                    },
                                    {
                                      "id": "codiceParametri",
                                      "value": vocePrestazioneItem.vocePrestazione.codiceParametri,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "codiceCompetenza",
                                      "value": vocePrestazioneItem.vocePrestazione.codiceCompetenza,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "attivitaProf",
                                      "label": "Attività di formatore",
                                      "value": vocePrestazioneItem.attivitaFormativa === 1 ? true : false,
                                      "type": "checkbox",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "compenso",
                                      "label": "Compenso",
                                      "value": vocePrestazioneItem.compensoPrestazione,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30,
                                      "valid": true,
                                    },
                                    {
                                      "id": "nPrestazioni",
                                      "label": "N. Prestazioni",
                                      "value": vocePrestazioneItem.numeroPrestazioni,
                                      "type": "label",
                                      "disabled": true,
                                      "width": 30,
                                      "valid": true,
                                    },
                                    {
                                      "id": "classeRischio",
                                      "value": vocePrestazioneItem.vocePrestazione.classeRischio,
                                      "type": "label-it-number",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "valoreRischio",
                                      "value": vocePrestazioneItem.numeroPrestazioni * vocePrestazioneItem.compensoPrestazione * vocePrestazioneItem.vocePrestazione.classeRischio,
                                      "type": "label-it-number",
                                      "disabled": true,
                                      "width": 30
                                    },
                                    {
                                      "id": "valoreOpera",
                                      "label": "Valore Opera Prog.",
                                      "value": (vocePrestazioneItem.valoreOpera).toFixed(2),
                                      "type": "label",
                                      "disabled": true,
                                      "width": 80,
                                      "valid": true,
                                    },
                                    {
                                      "id": "valoreOperaTotale",
                                      "value": (vocePrestazioneItem.valoreOpera * vocePrestazioneItem.numeroPrestazioni).toFixed(2),
                                      "type": "label",
                                      "disabled": true,
                                      "width": 80
                                    }
                                  ],
                                }
                                await childItems.push(child)
                              }
                        })

                        area.children = childItems;

                        childItems = await childItems.reduce((acc, e) => {
                            if(tmp.has(e.title)) {
                              if (!tmp.get(e.title).includes(e.jobid)) {
                                acc.push(e)
                                tmp.set(e.title, [...tmp.get(e.title), e.title])
                              }
                            } else {
                              acc.push(e)
                              tmp.set(e.title, [e.jobid])
                            }
                            return acc
                        }, [])

                        await this.setVociDaTabella(area);

                  });

                  await this.setState({isLoaded: true})
                })

            }).catch((err) => {
              console.error(err)
            })

        }).catch((err) => {
            setTimeout(() => {
                    let errorObject = {
                      message: err.message,
                      typeMessage: "danger"
                    }
                    this.props.getSelectedValue("message", errorObject)
                    this.setState({ message: err.message, typeMessage: 'danger' })
            }, 3000);
            //console.error(err)
        })

    }


    await this.setState({IDtabellaUnoSelezionata: item.value, isLoaded: true})


  }

  setVopVrc = async (tabella: any) => {
    this.setState({vopConaf: tabella.vopCalcolato, vrcConaf: tabella.vrcCalcolato})
  }

  reRender = async () => {
    this.setState({isLoaded: true})     //** Serve per il re-rendering della tabella */
    /**Calcolo del totale di ogni area dei selezionati */

    this.state.mySelection.map((item)=>{

        item.data[5].value = 0;
        item.data[6].value = 0;
        item.data[8].value = 0;
        item.data[9].value = " ";
        item.data[10].value = 0;

        item.children?.map((prestazione)=> {
          for(let i=5;i<11;i++) {
              if(i !== 7 && i !== 9 ) item.data[i].value = parseFloat(item.data[i].value) + parseFloat(prestazione.data[i].value);
              item.data[i].value = " ";
              item.data[i].disabled = true;
          }
        })
      })

    };



  enableEditingVoce = async (areaIndex, prestazioneIndex, booleanValue) => {

    let areaSelezionata = await JSON.parse(JSON.stringify(this.state.mySelection[areaIndex]));
    let prestazioneSelezionata = await JSON.parse(JSON.stringify(areaSelezionata['children'][prestazioneIndex]));

      if(!booleanValue ) {
        let erroreCompilazioneVoce = false;

        if((parseInt(prestazioneSelezionata.data.find(item => item.id === 'compenso').value) < 0) || (parseInt(prestazioneSelezionata.data.find(item => item.id === 'nPrestazioni').value) < 0) || (parseInt(prestazioneSelezionata.data.find(item => item.id === 'valoreOpera').value) < 0)) {
          this.showModal(true, "Errore.","Attenzione! Non sono accettati valori negativi.");
          erroreCompilazioneVoce= true;
        }

        else if(parseInt(prestazioneSelezionata.data.find(item => item.id === 'nPrestazioni').value) <= 0) {
          this.showModal(true, "Errore.","Attenzione! Il numero delle prestazioni deve essere maggiore di 1.");
          erroreCompilazioneVoce= true;
        }

        else if(parseInt(prestazioneSelezionata.data.find(item => item.id === 'valoreOpera').value) < parseInt(prestazioneSelezionata.data.find(item => item.id === 'compenso').value))
        {
          this.showModal(true, "Errore.","Attenzione! Il Valore dell'Opera progettata e' inferiore al Compenso della Prestazione professionale. Controllare la correttezza dei dati inseriti.");
          erroreCompilazioneVoce= true;
        }

        if(!erroreCompilazioneVoce) {
            let singolaVoce = {
              voceDettaglio: {
                idCompilazioneTabellaRischioDettaglio: prestazioneSelezionata.id,
                idCompilazioneTabellaRischio: this.state.tabellaUnoSelezionata.id,
                cfUtente: this.state.fields.codiceFiscale,
                compensoPrestazione: prestazioneSelezionata['data'].find(item => item.id === 'compenso').value,
                numeroPrestazioni: prestazioneSelezionata['data'].find(item => item.id === 'nPrestazioni').value,
                valoreOpera: prestazioneSelezionata['data'].find(item => item.id === 'valoreOpera').value,
                vocePrestazione: {
                  idVocePrestazione: areaSelezionata.id,
                  codicePrestazione: areaSelezionata['data'].find(item => item.id === 'codicePrestazione').value,
                  area: areaSelezionata['data'].find(item => item.id === 'areaPrestazione').value,
                  codiceParametri: areaSelezionata['data'].find(item => item.id === 'codiceParametri').value,
                  codiceCompetenza: areaSelezionata['data'].find(item => item.id === 'codiceCompetenza').value,
                  classeRischio: prestazioneSelezionata['data'].find(item => item.id === 'classeRischio').value,
                  prestazione: prestazioneSelezionata['data'].find(item => item.id === 'areaPrestazione').value,
                  ordinamento: prestazioneSelezionata['ordinamento'],
                  dataInizioValidita: "2022-12-06T18:10:01",
                  dataFineValidita: "9999-12-31T23:59:59"
                },
                codicePrestazione: prestazioneSelezionata['data'].find(item => item.id === 'codicePrestazione').value,
                attivitaFormativa: (prestazioneSelezionata['data'].find(item => item.id === 'attivitaProf').value === true) ? 1 : 0 ,
                dataCalcolo: "2023-03-16T11:25:29"
              },
              idTabella: this.state.tabellaUnoSelezionata.id

            }


            await modificaVoceTabellaUno(singolaVoce).then(async (response: any) => {

              await this.setVopVrc(response)
              let currentSelection = this.state.mySelection;

              currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').type = "label";
              currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').type = "label";
              currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').type = "label";

              currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').disabled = true;
              currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').disabled = true;
              currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'attivitaProf').disabled = true;
              currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').disabled = true;
              ;
              await this.setState({mySelection: currentSelection, editingFlag: booleanValue})

              this.recuperaTabellaPreviousStep(this.state.tabellaUnoSelezionata.id)
            }).catch((err) => {
              console.error(err.message)
              this.showModal(true, "Errore.",err.message);
            })
        } else {
          await this.setState({editingFlag: true})
        }

      } else {
        let currentSelection = this.state.mySelection;

        currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').type = "input";
        currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').type = "input";
        currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').type = "input";

        currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').disabled = false;
        currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').disabled = false;
        currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'attivitaProf').disabled = false;
        currentSelection[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').disabled = false;
        await this.setState({mySelection: currentSelection});
        this.props.getSelectedValue(this.state.mySelection, 'mySelection')

        await this.setState({editingFlag: booleanValue})
      }


  };

  setVociDaTabella = async (area: any) => {
        this.setState({isLoaded: false});

        let currentSelection = this.state.mySelection;
        this.props.getSelectedValue()
        currentSelection.push(area);
        ;
        this.setState({mySelection: currentSelection, isLoaded: true});
  }

  closeModal = () => {
    this.setState({modalOpen:false});
  }

  showModal = async (booleanValue, title, text) => {
    await this.setState({
        modalOpen:booleanValue,
        modalTitle: title,
        modalText:text,
        isLoaded: true,
    });
  }
  render() {



    const { mySelection, listaAnniRiferimento } = this.state;
    const { tabNumber } = this.props;

    const onChange = async (areaIndex, prestazioneIndex, campoIndex, e, type) => {

      let valori = {...this.state.rowsNames};
      let validated = this.state.validated;
      if(type === 'checkbox') {
        valori[areaIndex].children[prestazioneIndex].data[campoIndex].value = e;
      }
      else valori[areaIndex].children[prestazioneIndex].data[campoIndex].value = (e.target.value).toString();

      if(valori[areaIndex].children[prestazioneIndex].data[campoIndex].id === "nPrestazioni"){

        if(!isOnlyNumbers(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value)){
          // validated = false;
          valori[areaIndex].children[prestazioneIndex].data[campoIndex].valid = false;
          //this.showModal(true, "Errore.","Attenzione! Il compenso non rispetta il formato atteso.");
        }
        else if(isOnlyNumbers(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value)){
          // validated = true;
          valori[areaIndex].children[prestazioneIndex].data[campoIndex].valid = true;
          //this.showModal(true, "Errore.","Attenzione! Il compenso non rispetta il formato atteso.");
        }
        if(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value % 1 !== 0){
          this.showModal(true, "Errore.","Attenzione! Il numero delle prestazioni deve essere un numero intero.");
          // validated = false;
          valori[areaIndex].children[prestazioneIndex].data[campoIndex].valid = false;

        }
        else if(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value > 199){
          this.showModal(true, "Messsaggio.","Messaggio informativo: Il numero delle prestazioni che si sta inserendo supera il numero 199.");
        }

      }
      if(valori[areaIndex].children[prestazioneIndex].data[campoIndex].id === "compenso"){
        if(!AppUtils.isDecimalWithComma(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value)){
          // validated = false;
          valori[areaIndex].children[prestazioneIndex].data[campoIndex].valid = false;

          //this.showModal(true, "Errore.","Attenzione! Il compenso non rispetta il formato atteso.");
        }
        else if(AppUtils.isDecimalWithComma(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value)){
          // validated = true;
          valori[areaIndex].children[prestazioneIndex].data[campoIndex].valid = true;

          //this.showModal(true, "Errore.","Attenzione! Il compenso non rispetta il formato atteso.");
        }
      }
      if(valori[areaIndex].children[prestazioneIndex].data[campoIndex].id === "valoreOpera"){
        if(!AppUtils.isDecimalWithComma(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value)){
          // validated = false;
          valori[areaIndex].children[prestazioneIndex].data[campoIndex].valid = false;

          //this.showModal(true, "Errore.","Attenzione! Il compenso non rispetta il formato atteso.");
        }
        else if(AppUtils.isDecimalWithComma(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value)){
          validated = true;
          valori[areaIndex].children[prestazioneIndex].data[campoIndex].valid = true;
          //this.showModal(true, "Errore.","Attenzione! Il compenso non rispetta il formato atteso.");
        }
      }

      // let nPrestazioni = valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value;
      let valoreOperaProgettata = valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value;

      if(
          !isEmptyOrZero(valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value) &&
          !isEmptyOrZero(valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value)
        ) {
        //Se i campi compenso, e nPrestazioni non sono vuoti
        // let rischioCalcolato = Math.round(((valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value * valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value *  valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'classeRischio').value) + Number.EPSILON) * 100) / 100;
        let compenso = (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value)
        if(compenso.toString().includes(",")) compenso = (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value).replace(",",".")
        let valoreOperaProgettata = valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value;
        if(valoreOperaProgettata !== 0 && valoreOperaProgettata.toString().includes(",")) valoreOperaProgettata = (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value).replace(",",".")

        let subtotaleCalcolata = Math.round((compenso) * 100) / 100;
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value = subtotaleCalcolata;
        // (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreRischio').value) = rischioCalcolato;
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').disabled = false
        let operaCalcolata = (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value * valoreOperaProgettata).toFixed(2);
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value = valoreOperaProgettata;
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOperaTotale').value = operaCalcolata;
      }
      else {
        //Se i campi compenso, e nPrestazioni sono vuoti
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value =  Number(valoreOperaProgettata).toFixed(2)
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').disabled = true;
        let operaCalcolata = (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value * valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value + Number.EPSILON).toFixed(2);
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOperaTotale').value = operaCalcolata;


      }
      //Se il compenso e le prestazioni sono scritte correttamente e nel formato giusto
      //- compenso : float con due decimali dopo la virgola (Es. 10,00)
      //- nPrestazioni : intero (Es. 10)
      if(valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').valid === true
      && valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').valid === true
      && valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').valid === true
      ) {
        validated = true;
      } else validated = false;

      if(validated) {
          let rischioCalcolato = ((valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value * valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value *  valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'classeRischio').value)).toFixed(2);

          (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreRischio').value) = rischioCalcolato;
      }
      await this.setState({rowsNames: valori, validated: validated})

    };

    const onChangeSelezionati = async (areaIndex, prestazioneIndex, campoIndex, e, type) => {

      let valori = {...this.state.mySelection};
      if(type === 'checkbox') {
        valori[areaIndex].children[prestazioneIndex].data[campoIndex].value = e;
      }
      else valori[areaIndex].children[prestazioneIndex].data[campoIndex].value = e.target.value;

      if(valori[areaIndex].children[prestazioneIndex].data[campoIndex].id === "nPrestazioni"){
        if(valori[areaIndex].children[prestazioneIndex].data[campoIndex].value % 1 !== 0){
          this.showModal(true, "Errore.","Attenzione! Il numero delle prestazioni deve essere un numero intero.");
        }
      }


      let subtotaleCalcolata = (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value).toFixed(2);
      let rischioCalcolato = ((valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value * valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value *  valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'classeRischio').value)).toFixed(2);
      let operaCalcolata = (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value * valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value + Number.EPSILON).toFixed(2);
      (valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreRischio').value) = rischioCalcolato;
      valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOperaTotale').value = operaCalcolata;
      valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value = ((Number(operaCalcolata) / valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value) + Number.EPSILON).toFixed(2) ;
      valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value = subtotaleCalcolata;

      await this.setState({rowsNames: valori})

    };

    /****************** Form VRC, VOP, Anno riferimento e base del calcolo  ********************/
    const formTabellaUno = [
        <div id="formTabellaUno" key={"formTabellaUno"} hidden={(!this.state.fields)}>
            <GenericCard key={'tabellaUno'} hiddenHeader={true}
            cardBody={
              <div >
                  <div className="row">
                        <div className={isCurrentMobileDevice() ? "p-2" : "col-2"}>
                              <AvatarContainer>
                                  <AvatarIcon color='orange' size='xl'>
                                    <p aria-hidden='true'>{this.state.fields.iniziali}</p>
                                  </AvatarIcon>
                              </AvatarContainer>
                        </div>
                        <div className={isCurrentMobileDevice() ? "p-2" : "col-4"}>
                              {this.state.utenteSTP && <div><Label><h3 style={{fontSize:'20px'}}>{this.state.fields.nome}</h3></Label></div>}
                              {!this.state.utenteSTP && <div><Label><h3 style={{fontSize:'20px'}}>{this.state.fields.nome+" "+this.state.fields.cognome}</h3></Label></div>}
                              <div><Label><h6 style={{fontSize:'14px'}}>Iscrizione {(this.state.numeroIscrizione) ? this.state.numeroIscrizione : "-"}</h6></Label></div>
                              <div><Label><h6 style={{fontSize:'14px'}}>Data richiesta: {(this.props.data.dataCreazioneRichiesta) ? this.props.data.dataCreazioneRichiesta.split('T')[0] : "Oggi"}</h6></Label></div>
                              <div><Label><h6 style={{fontSize:'14px'}}>{this.state.fields.codiceFiscale}</h6></Label></div>
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                            <Callout color="note">
                              <CalloutTitle><Title headingLevel={"h4"}>VRC</Title></CalloutTitle>
                              <CalloutText bigText>€ {(this.state.vrcConaf) ? this.state.vrcConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : "0"}</CalloutText>
                            </Callout>
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-3"} style={{marginTop:'-20px'}}>
                            <Callout color="danger">
                              <CalloutTitle><Title headingLevel={"h4"}>VOP</Title></CalloutTitle>
                              <CalloutText bigText>€ {(this.state.vopConaf) ? this.state.vopConaf.toFixed(2).toString().replace(".", ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : "0"}</CalloutText>
                            </Callout>
                        </div>
                  </div>
                {(this.props.visualizzaFlag) ?

                  <div className="row p-2" hidden={this.state.tabellaUnoNotPresentFlag}>
                        <div className={isCurrentMobileDevice() ? null : "col-7"}>
                          <GenericInput isDisabled key={'baseCalcolo'} id="baseCalcolo" label='Base del calcolo *' placeholder='Selezionare'  isRequired={false}
                              currentStep={tabNumber} defaultValue={(this.state.fields['baseCalcolo']) ? this.state.fields['baseCalcolo'].label : '-' }
                              />
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-5"} >
                          <GenericInput isDisabled key={'annoRiferimento'} id="annoRiferimento" label={this.props.visualizzaFlag ? "* Anno riferimento" : "*"} placeholder='Selezionare'  isRequired={false}
                             currentStep={tabNumber} defaultValue={(this.state.fields['annoRiferimento']) ? this.state.fields['annoRiferimento'].label : '-'}
                          />
                        </div>
                  </div>
                :
                (this.state.sbloccaTabella === 0 || this.state.aumentoMassimaleFlag) ?

                  <div className="row p-3" hidden={this.state.tabellaUnoNotPresentFlag}>
                        <div className={isCurrentMobileDevice() ? null : "col-7"}>
                          <GenericInput isDisabled key={'baseCalcolo'} id="baseCalcolo" label='Base del calcolo *' placeholder='Selezionare'  isRequired={false}
                              currentStep={tabNumber} defaultValue={(this.state.fields['baseCalcolo']) ? this.state.fields['baseCalcolo'].label : '-' }
                              />
                        </div>
                        <div className={isCurrentMobileDevice() ? null : "col-5"} >
                          <GenericInput isDisabled key={'annoRiferimento'} id="annoRiferimento" label={"*"} placeholder='Selezionare'  isRequired={false}
                             currentStep={tabNumber} defaultValue={(this.state.fields['tipoCompilazione'] && this.state.fields['tipoCompilazione'].label === "Nuova compilazione") ? this.state.fields['tipoCompilazione'].label : (this.state.fields['annoRiferimento']) ? this.state.fields['annoRiferimento'].label : '-'}
                          />
                        </div>
                  </div>
                  :
                  <div className="row p-3" hidden={this.state.tabellaUnoNotPresentFlag}>
                      <div className={isCurrentMobileDevice() ? null : "col-6 mb-8"} >
                        <GenericSelect key={'baseCalcolo'} elementId="baseCalcolo" onChange={(e)=>setDataItem(e, 'select','baseCalcolo')} label='Base del calcolo *' placeholder='Selezionare'
                            defaultOptions={listaBaseCalcoloTabellaUno} currentStep={tabNumber} isRequired={true} errorMessageAlert={this.props.message}
                            defaultValue={(this.state.fields['baseCalcolo']) ? this.state.fields['baseCalcolo'] : null}
                            />
                      </div>
                      <div className={isCurrentMobileDevice() ? null : "col-5"} hidden={!this.state.fields['baseCalcolo']}>
                        <GenericSelect key={'annoRiferimento'} elementId="annoRiferimento" onChange={(e)=>setDataItem(e, 'select','annoRiferimento')} label={this.props.visualizzaFlag ? "* Anno riferimento" : "*"} placeholder='Selezionare'
                            defaultOptions={listaAnniRiferimento} currentStep={tabNumber}  isRequired={this.state.fields['baseCalcolo']} errorMessageAlert={this.props.message}
                            defaultValue={(this.state.fields['tipoCompilazione'] && this.state.fields['tipoCompilazione'].label === "Nuova compilazione") ? this.state.fields['tipoCompilazione'] : (this.state.fields['annoRiferimento']) ? this.state.fields['annoRiferimento'] : null}
                        />
                      </div>
                  </div>


                }
              </div>

            }></GenericCard>
        </div>
    ];

    const toggle = () =>{
      this.setState({isLoaded: true})
    }


    /** Funzione per aggiornare i campi del form */
    const setDataItem = async (e:any, type: any, field:any) => {
      let fields = this.state.fields;
      if(type === 'select')
        fields[field] = {label: e.label, value: e.value};

      else if(type==='checkbox') {
        fields[field] = e.target.checked;
      }
      else{
        fields[field] = e.target.value;
      }
      this.setState({ fields });
      this.props.getSelectedValue(this.state.fields, "tabellaUno")

      if(field === 'annoRiferimento') {
        await this.setAnnoRiferimento(e);
      }

    }

    /** Funzione per aggiornare VRC e VOP */


    /** Per aggiungere un elemento nei Selezionati viene passata l'area e poi la specifica prestazione selezionata
     *  Nell'area vengono però incluse tutte le righe di quella specifica area e quindi dovrà essere fatta una ricerca
     *  per prelevare dall'area la prestazione passata.
     */
    const aggiungiSelezione = async (areaIndex, prestazioneIndex, type, e, campoIndex) => {
      let erroreCompilazioneVoce = false;
      let areaSelezionata = await JSON.parse(JSON.stringify(this.state.rowsNames[areaIndex]));
      //let areaSelezionata = await Object.assign({}, this.state.rowsNames[areaIndex]);
      let prestazioneSelezionata = await JSON.parse(JSON.stringify(areaSelezionata['children'][prestazioneIndex]));
      if(this.state.validated === false) {
        this.showModal(true, "Errore.","Attenzione! Inserire i valori nel formato indicato.");
        erroreCompilazioneVoce= true;
      }
      /**ATTENZIONE: Il Valore dell'Opera Progettata (VOP) e' inferiore al Compenso della Prestazione professionale.
      Controllare la correttezza dei dati inseriti. */
      else if((parseInt(prestazioneSelezionata.data.find(item => item.id === 'compenso').value) < 0) || (parseInt(prestazioneSelezionata.data.find(item => item.id === 'nPrestazioni').value) < 0) || (parseInt(prestazioneSelezionata.data.find(item => item.id === 'valoreOpera').value) < 0) ||
      (isEmptyOrZero(prestazioneSelezionata.data.find(item => item.id === 'compenso').value)) || (isEmptyOrZero(prestazioneSelezionata.data.find(item => item.id === 'nPrestazioni').value)) || (isEmptyOrZero(prestazioneSelezionata.data.find(item => item.id === 'valoreOpera').value) )

      ) {
        this.showModal(true, "Errore.","Attenzione! Non sono accettati valori negativi.");
        erroreCompilazioneVoce= true;
      }

      else if(parseInt(prestazioneSelezionata.data.find(item => item.id === 'nPrestazioni').value) <= 0) {
        this.showModal(true, "Errore.","Attenzione! Il numero delle prestazioni deve essere maggiore di 1.");
        erroreCompilazioneVoce= true;
      }

      else if(parseInt(prestazioneSelezionata.data.find(item => item.id === 'valoreOpera').value) < parseInt(prestazioneSelezionata.data.find(item => item.id === 'compenso').value))
      {
        this.showModal(true, "Errore.","Attenzione! Il Valore dell'Opera progettata e' inferiore al Compenso della Prestazione professionale. Controllare la correttezza dei dati inseriti.");
        erroreCompilazioneVoce= true;
      }

      if(erroreCompilazioneVoce === true) {
        let valori = {...this.state.rowsNames};
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'nPrestazioni').value = 0
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreRischio').value = 0
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'compenso').value = 0
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOperaTotale').value = 0
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value = 0
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').disabled = true
        valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'attivitaProf').value = false
      }
      else {
            areaSelezionata['children'] = [];
            let singolaVoce = {
              idCompilazioneTabellaRischio: this.state.tabellaUnoSelezionata.id,
              valoreRischio: prestazioneSelezionata.data.find(item => item.id === 'valoreRischio').value.toString(),
              compensoPrestazione: prestazioneSelezionata.data.find(item => item.id === 'compenso').value.toString(),
              numeroPrestazioni: prestazioneSelezionata.data.find(item => item.id === 'nPrestazioni').value.toString(),
              valoreOpera: prestazioneSelezionata.data.find(item => item.id === 'valoreOpera').value.toString(),
              idVocePrestazione: prestazioneSelezionata.id.toString(),
              attivitaFormativa: (prestazioneSelezionata.data.find(item => item.id === 'attivitaProf').value) ? conversioneSiNoToInt(prestazioneSelezionata.data.find(item => item.id === 'attivitaProf').value.toString()) : 0
            }

            await aggiungiVoceTabellaUno(singolaVoce).then(async (response: any) => {
              prestazioneSelezionata.id = response.dettaglio.idCompilazioneTabellaRischioDettaglio;
              prestazioneSelezionata.valoreOperaTotale = prestazioneSelezionata.valoreOpera * response.dettaglio.numeroPrestazioni;
              await this.setVopVrc(response.tabella)
            }).catch((err) => {
              this.showModal(true, "Errore.",err.message);
            })

            areaSelezionata['children'].push(prestazioneSelezionata);
            let areaFounded = this.state.mySelection.find(el => el.id === areaSelezionata.id);
            if(areaFounded) {
                areaFounded.children.push(prestazioneSelezionata)
                areaFounded.children.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0);
            } else {
              this.state.mySelection.push(areaSelezionata)
            }
            this.setState({mySelection: this.state.mySelection.sort((p1, p2) => (p1.id > p2.id) ? 1 : (p1.id < p2.id) ? -1 : 0)});
            let valori = {...this.state.rowsNames};
            valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreRischio').value = 0
            valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOperaTotale').value = 0
            valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').value = 0
            valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'valoreOpera').disabled = true
            valori[areaIndex].children[prestazioneIndex].data.find(item => item.id === 'attivitaProf').value = false

            this.reRender()
            this.setState({isLoaded: true})
            this.props.getSelectedValue(this.state.mySelection, 'mySelection')
            this.recuperaTabellaPreviousStep(this.state.tabellaUnoSelezionata.id)
      }


    };


  const rimuoviSelezione = async (areaIndex, prestazioneIndex) => {
      let areaSelezionata = await JSON.parse(JSON.stringify(this.state.mySelection[areaIndex]));
      let prestazioneSelezionata = await JSON.parse(JSON.stringify(areaSelezionata['children'][prestazioneIndex]));

      let areaFounded = this.state.mySelection.find(el => el.id === areaSelezionata.id);

      let singolaVoce: CancellaSingolaVoceDettaglioDTO = {
        idCompilazioneTabellaRischioDettaglio: prestazioneSelezionata.id,
        idCompilazioneTabellaRischio: this.state.tabellaUnoSelezionata.id
      }

      await cancellaVoceTabellaUno(singolaVoce).then(async (response: any) => {
        if(areaFounded) {
            areaFounded.children.splice(prestazioneIndex, 1)
            if(areaFounded.children.length === 0) {
                this.state.mySelection.splice(areaIndex, 1);
            }
        }
        await this.setVopVrc(response)

      }).catch((err) => {
        this.showModal(true, "Errore.","Si è verificato un errore durante la rimozione. Ricorda che non e' possibile avere 0 voci nei tuoi selezionati." + err.message);
      })


      this.reRender()
      this.props.getSelectedValue(this.state.mySelection, 'mySelection')
      await this.setState({editingFlag: false})

  };




    return(
        <>
        {(this.state.isLoaded) ?
          <div>

              {formTabellaUno}

              <div hidden={!this.state.fields['annoRiferimento'] || !this.state.fields['baseCalcolo'] || this.state.tabellaUnoNotPresentFlag }>

                  {(this.state.mySelection)
                    ?
                    <GenericCard key={'tabellaMySelection'}
                        cardHeader={
                          <div className='justify-content-between d-flex'>
                          <p>{'Selezionati'}</p>
                          </div>
                        }
                        cardBody={
                            <GenericEditableTable
                                editable={this.state.editingFlag}
                                hideActionColumn={this.props.visualizzaFlag || this.state.sbloccaTabella === 0}
                                isBorderless={false}
                                className="policy-table"
                                columns={columnsTabellaUno?.map((column) => column.name)}
                                rows={mySelection}
                                iconButton={close}
                                labelButton={isCurrentMobileDevice() && "Rimuovi"}
                                //editButton={edit}
                                onSelect={rimuoviSelezione}
                                onUpdate={this.enableEditingVoce}
                                onClick={this.setAnnoRiferimento}
                                onToggle={toggle}
                                onChange={onChangeSelezionati}
                                type="button"
                                striped={true}
                                hover={this.props.visualizzaFlag ? false : true}
                                >
                            </GenericEditableTable>
                        }>
                      </GenericCard>
                  : null
                  }
                  {(this.state.rowsNames)
                    ?
                    <div hidden={this.props.visualizzaFlag || this.state.sbloccaTabella === 0 }>

                        <GenericAccordionCard key={'tabellaUnoAll'} open={true}
                            cardHeader={
                                <div className='justify-content-between d-flex'>
                                <p>{'Componi la tua tabella'}</p>
                                </div>
                            }
                            cardBody={
                                <GenericEditableTable
                                  isBorderless={false}
                                  editable={true}
                                  className="policy-table"
                                  columns={columnsTabellaUno?.map((column) => column.name)}
                                  rows={this.state.rowsNames}
                                  hideActionColumn={false}
                                  iconButton={add}
                                  labelButton={isCurrentMobileDevice() && "Aggiungi"}
                                  colorButton={""}
                                  onSelect={aggiungiSelezione}
                                  onToggle={toggle}
                                  onChange={onChange}
                                  type="reset"
                                  striped={true}
                                  hover={this.props.visualizzaFlag ? false : true}

                                  ></GenericEditableTable>}
                                >
                        </GenericAccordionCard>
                    </div>
                  : null
                }
              </div>

              {(this.state.tabellaUnoNotPresentFlag) ?
              <div className="row p-4"><Label>Non è possibile caricare la tabella uno di questa polizza storica</Label></div>
              : null}

              <GenericModal
                    title={this.state.modalTitle}
                    text={this.state.modalText}
                    modalOpen={this.state.modalOpen}
                    closeButton={this.closeModal}
              />
          </div>

          : <GenericSpinner></GenericSpinner>
        }
        </>
    )

    }
}
export declare interface TabellaUnoProps {
  data?: any;
  polizza?: any;
  activeTab?: any;
  tabClick?: any;
  visualizzaFlag?: any;
  aumentoMassimaleFlag?: any;
  modificaDatiFlag?: any;
  prevClicked?:any;
  prevData?: any;
  getSelectedValue?: any;
  tabNumber?: any;
  checkEmpty?: any;
  message?: any;
  getValues?: any;
  vopConaf?: any;
  vrcConaf?: any;
  tabellaUno?: any;
  storicoFlag?: any;
  mySelection?:any;
}

